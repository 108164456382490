import React, { Component } from "react";
import { Link } from "react-router-dom";
import { history } from "../_helpers/history";
import { BASE_URL, LOGO_FULL_URL } from "../_helpers/constants";
import Footer from "../_components/Footer";
import { FooterSection, LandingNav } from "./Pages_landing";

export default class Pages_Aboutus extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="custom-container home-page !h-[100vh] flex-col overflow-auto justify-between !bg-red-400 !pb-3">
       <LandingNav scrolled={true}  bgColor="#fff"/>
        <section className="description-block !pt-28">
          <div className="page-custom-container">
            <div className="custom-row">
              <div>
                <div >
                    <h2 className="heading">About us</h2>
                  <p className="description">We have decades of experience in developing high-quality software products, we are currently building test infrastrature to improve engineering productivity.</p>
                  <p> Oyetest facilitates seamless collaboration among teams through integrated workflows, Our mission is to revolutionize traditional software test engineering tools.</p>
                  
                </div>
                <div className="description">
                    <p> If you are interested to know more about us , please contact us at ,</p>
                    <p>Email:-  support@oyesense.com</p>
                    <p>Phone:-  +916362723848</p>
                    <p>Contact us:- <a id="f_infrm" href="/contactus">
                  {" "}
                  https://www.oyetest.com/contactus
                </a></p>
                </div>
               
              </div>
            </div>
          </div>
        </section>
       <FooterSection />
      </div>
    );
  }
}
