import React, { Component } from "react";
import { FooterSection, LandingNav } from "./Pages_landing";

export default class Pages_Cookie extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="custom-container home-page !pb-3">
        <LandingNav scrolled={true} />
        <section className="description-block !mt-28">
          <div className="page-custom-container">
            <div className="custom-row">
              <div>
                <div>
                  <h2 className="heading">Cookie Policy</h2>
                  <p>
                    This Privacy Policy, together with Terms of Use, describes
                    the policies and procedures of Oyesenses Limited (“Oyetest”/
                    “Oyesense”/ “Company”/ “We”/ “Us”/ “Our”) for collection,
                    storage, use and disclosure of the information provided by
                    our customers, stakeholders, persons who visits our website,
                    (Hereinafter referred to as / “User”/”Users”/ “User’s/
                    “you”/“your”/“customer”). The Company is engaged in the
                    business of selling men’s and women’s Ethnic Wear and
                    accessories, under the brand name and style “Oyetest”
                    through retail stores and eCommerce sites. This privacy
                    policy covers your rights in relation to your information,
                    how we protect & use it and how you can contact us in case
                    you have a complaint.
                  </p>
                  <p>
                    {" "}
                    This privacy policy is an electronic record in the form of
                    an electronic contract formed under the Information
                    Technology Act, 2000 and the rules made thereunder and the
                    amended provisions pertaining to electronic documents /
                    records in various statutes as amended by the information
                    technology act, 2000. This privacy policy does not require
                    any physical, electronic or digital signature.This document
                    is published and shall be construed in accordance with the
                    provisions of the Information Technology(Reasonable Security
                    Practices and Procedures and Sensitive Personal Data of
                    Information) Rules, 2011 under Information Technology Act,
                    2000; that require publishing of the privacy policy for
                    collection, use, storage and transfer of sensitive personal
                    data or information.
                  </p>
                  <p>
                    This privacy policy is a legally binding document between
                    you and Oyesense. You hereby acknowledge and agree that you
                    are voluntarily providing your personal have read and
                    understood this Policy and that we will collect Personal
                    Information from you for the purposes and usage of
                    collection as described below. The terms of this privacy
                    policy will be effective upon your acceptance/consent of the
                    same (directly or indirectly in electronic form, by clicking
                    on the "I Accept" tab or by use or access of the services or
                    by other means) and will govern the relationship between you
                    and Oyesense.
                  </p>
                  <p>
                    Please read this privacy policy carefully. By using the
                    services, accessing the website, forming any contractual,
                    financial or commercial obligation or other services, you
                    indicate that you understand, agree and consent to this
                    privacy policy. If you do not agree with the terms of this
                    privacy policy, please do not use this platform. You hereby
                    provide your unconditional consent or agreements to Oyesense
                    as provided under the applicable provisions of Information
                    Technology Act, 2000.
                  </p>
                </div>
                <div>
                  <h3>WEBSITE COOKIES</h3>
                  <p>
                    This website uses cookies to record log data. Cookies are
                    small text files sent by us to your computer, and from your
                    computer or mobile device to us each time you visit our
                    website. They are unique to you or your web browser.
                    Session-based cookies last only while your browser is open
                    and are automatically deleted when you close your browser
                    session. Persistent cookies last until you or your browser
                    deletes them, or until they expire. By accepting our Cookie
                    Policy, you are consenting to our use of Cookies in
                    accordance with this Cookie Notice and Privacy Policy. Most
                    browsers allow you to control cookies, including whether or
                    not to accept them and how to remove them. In such case, you
                    may set the browsers to notify you if you receive a cookie,
                    or you may choose to block cookies with your browser, but
                    please note that if you choose to erase or block your
                    cookies, you will need to re-enter your original user ID and
                    password to gain access to certain parts of the Platform.
                  </p>
                  <p>
                    We use cookies which are not specific to your account but
                    are unique and allow us to understand how you use our
                    Website, remember choices you make in respect of search
                    parameters such as size, colour or product line. These can
                    then be used to provide you with an experience more
                    appropriate to your selections and to make the visits more
                    tailored and pleasant. You may opt out of these cookies
                    using your browser settings but it may affect the
                    performance of our Website. To opt-out of receiving
                    communications from us on behalf of our partners, and from
                    us in general, after setting up an account, please choose to
                    unsubscribe.
                  </p>
                </div>
                <div>
                  <h3>COOKIES USED FOR ADVERTISING PURPOSE:</h3>
                  <p>
                    Cookies and other ad technology such as beacons, pixels, and
                    tags help us serve relevant ads to you more effectively.
                    These kinds of cookies are typically used to personalise ads
                    on this or other sites based on your use of our site, to
                    measure ad effectiveness (for example, how often you click
                    on or view ads and whether you go on to buy something from
                    the advertiser) and to stop you being shown the same ad
                    repeatedly. For more detail regarding the profiling
                    activities, please see our Privacy Policy. We work with
                    website analytics and advertising partners, including Google
                    Display Network, Facebook, etc. to deliver Oyetest.com
                    advertisements on third party publisher websites - these
                    partners may set cookies on your computer's web browser.
                    These cookies allow our partners to recognize your computer
                    so that the ad server can show you Oyetest.com
                    advertisements elsewhere on the Internet and our analytics
                    software can measure your engagement and interactions, while
                    using Oyetest.com services. This information allows an ad
                    network to deliver targeted advertisements that they believe
                    will be of most interest to you and it allows Oyetest.com to
                    optimize the performance of our advertising campaigns and
                    the usability of our website. In other words, we use
                    analytics data in concert with data about our online ads
                    that have been shown using the Google Display Network,
                    Facebook for Advertisers, or other online advertising
                    networks. By doing so, we can understand how anonymous users
                    interacted with our website after seeing our ads.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <FooterSection />
      </div>
    );
  }
}
