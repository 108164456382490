import React, { useEffect, useRef, useState } from "react";
import { client, urlFor } from "../_helpers/client";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { history } from "../_helpers/history";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/splide/dist/css/splide.min.css";

import {
  aiFeaturesHardcoded,
  communtityJoiningHarcoded,
  integrationsHardcoded,
  leadersHardcoded,
  middleInfoHardcoded,
  partnersLinkHardcoded,
  policiesLinkHardcoded,
  powerfulFeaturesHarcoded,
  salientFeaturesHardcoded,
  socialsHarcoded,
  testimonialsHardcoded,
  usefulLinksHardcoded,
} from "../_helpers/constants";

import "../styles/landing.css";

import { landingNavOptions } from "../_helpers/constants";
import { Hamburger, IntegrationShowcaseMobile } from "../assets/Svgs.jsx";

const LandingPage = () => {
  const [scrolled, setScrolled] = useState(false);
  const observerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setScrolled(!entry.isIntersecting);
      },
      { threshold: 0 } // Trigger when the target is completely out of view
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, []);

  return (
    <div className="pb-12">
      <div ref={observerRef} /> {/*DO NOT REMOVE*/}
      <LandingNav scrolled={scrolled} fixed={false} />
      <HeroSection />
      <PowerfulFeaturesSection />
      <SalientInfoSection />
      <AIFeaturesSection />
      <IntegrationShowcaseSection />
      <TestimonialsSection />
      <CommunityJoinSection />
      <FooterSection />
    </div>
  );
};

const LandingNav = ({
  scrolled,
  bgColor = "#54BE961A",
  fixed = true,
  redirectAfterLogin = false,
}) => {
  const [toggle, setToggle] = useState(false);
  return (
    <nav
      className={`!px-6 lg:!px-28 flex flex-row items-center  justify-between   text-landing-secondary-black w-full transition-all duration-300  top-0 ${
        scrolled && !toggle
          ? "glass-bg shadow-lg !py-2"
          : ` !bg-[${bgColor}] !py-3 lg:!py-6 shadow-none border-none `
      }`}
      style={{
        zIndex: 100,
        position: fixed ? "fixed" : "sticky",
      }}
    >
      <div className="flex flex-row gap-x-2 items-center ">
        {!toggle && (
          <div className="mobile ">
            <Hamburger onClick={() => setToggle(true)} />
          </div>
        )}
        {toggle && (
          <div className="app__nav-menu ">
            <div className="!w-[100vw] !h-[100vh] !bg-[rgba(0,0,0,0.6)]">
              <div className="glass-bg- bg-white !pt-4">
                <HiX onClick={() => setToggle(false)} />
                <ul className="">
                  {[
                    { label: "Home", path: "/" },
                    ...landingNavOptions,
                    {
                      label: "Login",
                      path:
                        "/login" + (redirectAfterLogin ? "?redirect=1" : ""),
                    },
                  ].map((item, i) => (
                    <li key={`${item.label}-${i}`} className="!py-0 !my-2">
                      <a
                        className="font-poppins !text-[18px] "
                        href={item.path}
                        onClick={() => setToggle(false)}
                      >
                        {item.label}
                      </a>
                    </li>
                  ))}
                  <li className=" mobile-button">
                    <a
                      className="w-full capitalize font-poppins text-[1rem] !font-[500] hover:text-primary-green !flex !items-center !justify-center primary_cta_btn gap-x-1 "
                      href="/register"
                      style={{ textDecoration: "none" }}
                    >
                      Sign Up
                      {false && (
                        <svg
                          width="14"
                          height="12"
                          viewBox="0 0 20 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2 8.99999H13M13 8.99999L10.25 7M13 8.99999L10.25 11"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7.33423 5.25C7.34432 3.61873 7.4247 2.73529 8.06501 2.15901C8.79727 1.5 9.97577 1.5 12.3328 1.5H13.1661C15.5231 1.5 16.7017 1.5 17.4339 2.15901C18.1661 2.81802 18.1661 3.87868 18.1661 6V12C18.1661 14.1213 18.1661 15.1819 17.4339 15.841C16.7935 16.4173 15.8119 16.4897 13.9993 16.4987M7.33423 12.75C7.34432 14.3812 7.4247 15.2647 8.06501 15.841C8.59944 16.3219 9.37152 16.4519 10.6659 16.487"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      )}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
        <a
          href="/"
          className="app__nav-logo"
          style={{ textDecoration: "none" }}
        >
          <img
            src="https://www.oyetest.com/assets/images/new_logo_full.png"
            alt="Logo"
          />
        </a>
      </div>
      <ul className="app__nav-links ">
        {landingNavOptions.map((item) => (
          <li
            key={`key-${item.label}`}
            className="app__flex p-text !capitalize"
          >
            <a
              className="capitalize font-poppins text-[1rem] !font-[400] hover:text-primary-green w-[60px] flex items-center justify-center hover:!font-[500]"
              style={{ textDecoration: "none" }}
              href={item.path}
              key={item.label}
            >
              {item.label}
            </a>
          </li>
        ))}
      </ul>
      <div className="flex flex-row items-center gap-x-4">
        <a
          className="capitalize font-poppins text-[1rem] !font-[500] hover:text-primary-green flex items-center justify-center desktop-button "
          href={"/login" + (redirectAfterLogin ? "?redirect=1" : "")}
          style={{ textDecoration: "none" }}
        >
          Login
        </a>
        <a
          className={`desktop-button-   capitalize font-poppins !text-[12px] lg:!text-[1rem] !font-[500] lg:!font-[400] !px-3  lg:!px-6 hover:text-primary-green flex !items-center !justify-center primary_cta_btn gap-x-1  !h-[${
            scrolled ? "26px" : "30px"
          }] lg:!h-[40px]`}
          href="/register"
          style={{ textDecoration: "none", opacity: toggle ? 0 : 1 }}
        >
          Sign Up
          <div className="hidden lg:block">
            <svg
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 8.99999H13M13 8.99999L10.25 7M13 8.99999L10.25 11"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.33423 5.25C7.34432 3.61873 7.4247 2.73529 8.06501 2.15901C8.79727 1.5 9.97577 1.5 12.3328 1.5H13.1661C15.5231 1.5 16.7017 1.5 17.4339 2.15901C18.1661 2.81802 18.1661 3.87868 18.1661 6V12C18.1661 14.1213 18.1661 15.1819 17.4339 15.841C16.7935 16.4173 15.8119 16.4897 13.9993 16.4987M7.33423 12.75C7.34432 14.3812 7.4247 15.2647 8.06501 15.841C8.59944 16.3219 9.37152 16.4519 10.6659 16.487"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </a>
      </div>
    </nav>
  );
};

const HeroSection = () => {
  const [hero, setHero] = useState(null);
  const [platforms, setPlatforms] = useState([]);
  const [leaders, setLeaders] = useState([...leadersHardcoded]);

  useEffect(() => {
    const getHero = () => {
      const query = "*[_type== 'heroSection']  | order(_createdAt asc)";
      client.fetch(query).then((data) => {
        setHero(data[0]);
      });
    };

    const getPlatforms = () => {
      const query = "*[_type=='platforms']  | order(_createdAt asc)";
      client.fetch(query).then((data) => {
        setPlatforms(data);
      });
    };
    const getLeaders = () => {
      const query = "*[_type=='leaders']  | order(_createdAt asc)";
      client.fetch(query).then((data) => {
        if (data.length > 0) setLeaders(data);
      });
    };

    getHero();
    getPlatforms();
    getLeaders();
  }, []);

  const handlePlatformClick = (i) => {
    if (i >= 0 && i < platforms.length && platforms[i].enabled) {
      console.log("trying to push here", platforms[i].link);
      history.push(platforms[i].link);
    }
  };

  return (
    <div
      id="hero"
      className="!bg-[#54BE961A] py-3 "
      style={{ boxShadow: "0 30px 30px -10px #54BE961A" }}
    >
      <div className="relative">
        <div className="glow-div"></div>
        <div className="content-div">
          <div className=" bg-red-500- !py-[2rem]  lg:!pb-[8rem] lg:!py-[5rem] flex flex-col items-center justify-center mt-8">
            <div className=" w-[88%] lg:w-[941px] flex flex-col items-center justify-center">
              <h1
                className="!font-[600] font-poppins !text-[32px] lg:!text-[52px] text-center text-landing-primary-black"
                style={{ lineHeight: 1.5 }}
              >
                <HighlightedSentence
                  sentence={
                    hero?.label ||
                    "Transform Test Management with Our Collaborative AI Platform"
                  }
                  startWordIndex={hero?.highlightedWordStartIndex || 3}
                  endWordIndex={hero?.highlightedWordEndIndex || 4}
                />
              </h1>
              <p className="my-4 !mt-[18px] text-center w-[110%] lg:w-[83%] font-roboto !font-[400] !text-[15px] lg:!text-[20px] text-landing-secondary-black">
                {hero?.description ||
                  "Oyetest removes the complexity of test management . Built for QA, Dev, Product and DevOps. It automatically generates test cases with AI boosting efficiency."}
              </p>
              <div className="flex flex-row items-center justify-center gap-x-3 mt-2">
                <a
                  href={hero?.ctaLink || "/register"}
                  className="primary_btn w-[125px] lg:w-[175px]"
                >
                  {hero?.ctaText || "Get Started"}
                </a>
                <a
                  href={hero?.infoLink || "/support"}
                  className="primary_info_btn w-[125px] lg:w-[175px]"
                >
                  {hero?.infoText || "Request Demo"}
                </a>
              </div>
              <div className="flex flex-row items-center justify-center gap-x-6 mt-6">
                <p className="font-poppins !font-[400] text-[12px] lg:text-[17px] text-[#39414A]">
                  Available on
                </p>
                <div className="flex flex-row gap-x-3">
                  <button
                    className="bg-[#D9FAE7] w-10 h-10 flex items-center justify-center rounded-full"
                    onClick={() => handlePlatformClick(1)}
                  >
                    <svg
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M18.9007 13.65C18.9007 14.2296 18.4305 14.7 17.8511 14.7H3.15624C2.57685 14.7 2.10661 14.2296 2.10661 13.65V3.15C2.10661 2.5704 2.57685 2.1 3.15624 2.1H17.8511C18.4305 2.1 18.9007 2.5704 18.9007 3.15V13.65ZM18.8902 0H2.09611H2.09192C0.933123 0 0 0.96077 0 2.12102L0.00734596 2.14204V14.742L0 14.721C0 15.8813 0.933123 16.8 2.09192 16.8H2.09611H8.38971C8.97016 16.8 9.45404 17.2914 9.45404 17.871V17.8603C9.45404 18.4399 8.97016 18.9 8.38971 18.9H5.24082C4.66142 18.9 4.20588 19.3914 4.20588 19.971V19.9603C4.20588 20.5399 4.66142 21 5.24082 21H15.7371C16.3176 21 16.8015 20.5506 16.8015 19.971V19.9603C16.8015 19.3807 16.3176 18.9 15.7371 18.9H12.5882C12.0088 18.9 11.5533 18.4506 11.5533 17.871V17.8603C11.5533 17.2807 12.0088 16.8 12.5882 16.8H18.8902L18.8976 16.821C20.0543 16.821 21 15.9033 21 14.7451V14.742V2.14204V2.13896C21 0.980815 20.0543 0.0210205 18.8976 0.0210205L18.8902 0Z"
                        fill="#27AE60"
                      />
                    </svg>
                  </button>
                  <button
                    className="bg-[#D9FAE7] w-10 h-10 flex items-center justify-center rounded-full"
                    onClick={() => {
                      window.location.href =
                        "https://play.google.com/store/apps/details?id=com.oyetest";
                    }}
                  >
                    <svg
                      width="21"
                      height="23"
                      viewBox="0 0 21 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.01384 7.58416H16.9861V17.5161C16.9861 18.1062 16.4769 18.5845 15.8497 18.5845H14.5504V21.6093C14.5504 22.3775 13.8983 23 13.0933 23C12.2863 23 11.6352 22.3775 11.6352 21.6093V18.5845H9.36585V21.6093C9.36585 22.3775 8.71246 23 7.90756 23C7.10279 23 6.44946 22.3775 6.44946 21.6093V18.5845H5.15127C4.52389 18.5845 4.0138 18.1062 4.0138 17.5161L4.01384 7.58416ZM1.47174 7.5031C0.658592 7.5031 -6.10352e-05 8.13157 -6.10352e-05 8.9074V14.3958C-6.10352e-05 15.1707 0.658592 15.8002 1.47174 15.8002C2.28503 15.8002 2.94354 15.1707 2.94354 14.3958V8.9074C2.94354 8.13157 2.28503 7.5031 1.47174 7.5031ZM16.9861 6.58332H4.01384C4.17395 4.84694 5.37278 3.3527 7.09858 2.48753L5.8652 0.785977C5.69887 0.556517 5.76163 0.24343 6.00633 0.0872008C6.25074 -0.069028 6.5833 -0.00961516 6.75045 0.219664L8.09472 2.07551C8.84004 1.83335 9.64907 1.69432 10.5 1.69432C11.3519 1.69432 12.1609 1.83335 12.9062 2.07582L14.2505 0.22034C14.4156 -0.00957016 14.7492 -0.068983 14.9936 0.0872458C15.2383 0.243475 15.3011 0.556562 15.1347 0.786022L13.9024 2.48757C15.6281 3.35221 16.8261 4.84644 16.9861 6.58332ZM8.48146 4.22396C8.48146 3.85159 8.16055 3.54958 7.76437 3.54958C7.36718 3.54958 7.04626 3.85159 7.04626 4.22396C7.04626 4.59614 7.36824 4.89802 7.76437 4.89802C8.1605 4.89802 8.48146 4.59614 8.48146 4.22396ZM14.0446 4.22396C14.0446 3.85159 13.7226 3.54958 13.3265 3.54958C12.9293 3.54958 12.6093 3.85159 12.6093 4.22396C12.6093 4.59614 12.9293 4.89802 13.3265 4.89802C13.7226 4.89802 14.0446 4.59614 14.0446 4.22396ZM19.5292 7.50116C18.7169 7.50116 18.0564 8.13058 18.0564 8.90646V14.3967C18.0564 15.1726 18.7169 15.8022 19.5292 15.8022C20.3425 15.8022 20.9999 15.1727 20.9999 14.3967V8.90646C21 8.13058 20.3425 7.50116 19.5292 7.50116Z"
                        fill="#27AE60"
                      />
                    </svg>
                  </button>
                  <button
                    className="bg-[#D9FAE7] w-10 h-10 flex items-center justify-center rounded-full"
                    onClick={() => handlePlatformClick(2)}
                  >
                    <svg
                      width="20"
                      height="22"
                      viewBox="0 0 20 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13.3066 3.44547C14.145 2.53363 14.7102 1.26362 14.5555 0C13.3473 0.0431645 11.8855 0.724243 11.0192 1.635C10.2412 2.44325 9.56209 3.7347 9.74466 4.97351C11.0924 5.06738 12.4681 4.35838 13.3066 3.44547ZM16.3289 11.4656C16.3626 14.732 19.5152 15.8186 19.5501 15.8326C19.5245 15.9092 19.0466 17.3809 17.8895 18.9024C16.8883 20.2167 15.8498 21.5257 14.2136 21.5538C12.6065 21.5808 12.089 20.697 10.2505 20.697C8.41315 20.697 7.83869 21.5256 6.31763 21.5807C4.73844 21.6335 3.53485 20.1587 2.52662 18.8486C0.463663 16.1692 -1.11205 11.2768 1.00441 7.97479C2.05566 6.33564 3.93372 5.29606 5.97342 5.27016C7.52355 5.24318 8.98762 6.20806 9.93538 6.20806C10.8831 6.20806 12.6623 5.04802 14.5323 5.21852C15.3149 5.24766 17.5127 5.50224 18.9233 7.35936C18.8094 7.42303 16.301 8.73546 16.3289 11.4656Z"
                        fill="#27AE60"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center mt-3 lg:mt-0  gap-y-3 lg:gap-y-8">
            <h3 className="font-[600] font-poppins text-[18px] lg:text-[27px] text-center text-landing-primary-black">
              Chosen by{" "}
              <span className="text-primary-green">Industry Innovators</span>
            </h3>
            <div className="flex flex-row justify-center items-center w-full h-full gap-x-3 lg:gap-x-8">
              {leaders.map((leader, i) => (
                <div
                  key={`leader-${i + "-" + leader.name}`}
                  className="w-12 lg:w-40 h-4 lg:h-12 grayscale- hover:grayscale-0"
                >
                  <img
                    className="mix-blend-darken w-full h-full object-contain"
                    src={urlFor(leader.logoUrl)}
                    alt={leader.name}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PowerfulFeaturesSection = () => {
  const [features, setFeatures] = useState([...powerfulFeaturesHarcoded]);

  useEffect(() => {
    const getFeatures = () => {
      const query = "*[_type == 'powerfulFeatures'] | order(_createdAt asc)";
      client.fetch(query).then((data) => {
        if (data.length >= 6) setFeatures(data);
      });
    };

    getFeatures();
  }, []);

  return (
    <div className="flex flex-row  items-center !mt-8 lg:!mt-20 !mb-0 !pb-0 page-section-margin ">
      <div className="flex flex-col items-center justify-center w-full mb-[100px] ">
        {/* label */}
        <div className="flex items-center justify-center lg:justify-between !mx-4 lg:!mx-40] mb-8 ">
          <h3
            className="!font-[700] font-raleway text-[22px] lg:text-[44px]  text-landing-primary-black w-[60%] md:w-[44%] lg:w-[57%]"
            style={{ lineHeight: 1.3 }}
          >
            <HighlightedSentence
              sentence={features[0].label}
              startWordIndex={features[0].highlightedWordStartIndex}
              endWordIndex={features[0].highlightedWordEndIndex}
            />
          </h3>
          <p className="hidden lg:block w-full lg:w-[40%] font-poppins !font-[400] text-[15px] lg:text-[18px] text-landing-secondary-black opacity-80">
            {features[0].description}
          </p>
        </div>

        <div className="flex flex-1 flex-col lg:flex-row gap-y-6 lg:gap-y-0 items-center justify-center  w-full !px-6- lg:!px-40] gap-x-8">
          {/* top left main card */}
          <div
            className="feature-card flex-0 lg:flex-1 flex flex-col gap-y-4 lg:gap-y-8 items-center justify-start  !h-[420px] lg:!h-[541px] "
            style={{
              background:
                " linear-gradient(305.89deg, #F1FAFC 2.17%, #E0F7E8 121.47%)",
            }}
          >
            <div className="flex h-[331px] items-center justify-center ">
              <img
                className="h-full w-full object-contain "
                src={urlFor(features[1].imgUrl)}
                alt="spreadsheet destkop"
              />
            </div>
            <div className="flex flex-col items-start !justify-start mb-3 lg:mb-0">
              <p className="tagphrase">{features[1].tagPhrase}</p>
              <h4
                className="!font-[600] font-poppins text-[18px] !w-full lg:!w-[340px]- lg:text-[28px]  text-landing-primary-black"
                style={{ lineHeight: 1.4 }}
              >
                <HighlightedSentence
                  sentence={features[1].label}
                  startWordIndex={features[1].highlightedWordStartIndex}
                  endWordIndex={features[1].highlightedWordEndIndex}
                />
              </h4>
            </div>
          </div>

          {/* top right card group */}
          <div className="flex-1 w-full flex flex-col items-between justify-between gap-y-6 h-[541px]">
            {/* top right 1 card */}
            <div
              className="feature-card !pt-0 lg:!py-4 w-full !h-[240px] lg:!h-[221px] overflow-hidden flex-0  lg:flex-1  flex flex-row-reverse items-center"
              style={{
                backgroundImage: `url(${urlFor(features[2].bgUrl)})`,
                backgroundSize: "cover",
                backgroundPosition: "right",
                backgroundColor: "#CEF7F2",
              }}
            >
              <div className="flex items-center justify-center ">
                <img
                  className="h-[160px] w-full object-contain opacity-0"
                  src={urlFor(features[2].imgUrl)}
                  alt="spreadsheet destkop"
                />
              </div>
              <div
                className="flex flex-col items-start justify-start  !w-[200%] lg:!w-[80%]"
                style={{ marginTop: -30 }}
              >
                <p className="tagphrase">{features[2].tagPhrase}</p>
                <h4
                  className="!font-[600] font-poppins text-[18px] lg:text-[24px]  text-landing-primary-black"
                  style={{ lineHeight: 1.3 }}
                >
                  <HighlightedSentence
                    sentence={features[2].label}
                    startWordIndex={features[2].highlightedWordStartIndex}
                    endWordIndex={features[2].highlightedWordEndIndex}
                  />
                </h4>
              </div>
            </div>
            {/* top right 2 card */}
            <div className="feature-card w-full !h-[221px] !py-0 lg:!py-10 !pr-0 overflow-hidden  flex-1  flex flex-row-reverse items-center !px-2  ">
              <div
                className="flex  w-[55%] items-center justify-center relative !h-[221px] !min-w-[180px] !max-w-[270px]"
                style={{ marginRight: -30 }}
              >
                {/* {true && ( */}
                {/* <div className="absolute top-0 left-0"> */}
                <div
                  className="flex flex-col gap-y-4 lg:gap-y-[1.75rem]- absolute left-0"
                  style={{ top: -40 }}
                >
                  {features[3].featuresArray?.slice(0, 4).map((tag, index) => (
                    <div
                      key={index}
                      className={`bg-green-100 text-primary-green h-[52px] w-[87px] lg:h-[73px] lg:w-[125px]   rounded-[7px] font-[400] flex items-center justify-center text-[8px] lg:text-[13px] font-poppins`}
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {tag}
                    </div>
                  ))}
                </div>
                <div
                  className="flex flex-col gap-y-4 lg:gap-y-4 absolute right-0 "
                  style={{ marginTop: -30 }}
                >
                  {features[3].featuresArray
                    ?.slice(2, features[3].featuresArray.length)
                    .map((tag, index) => (
                      <div
                        key={index}
                        className={`bg-green-100 h-[52px] w-[87px] lg:h-[73px] lg:w-[125px] text-primary-green  rounded-[7px] font-[400] flex items-center justify-center text-[8px] lg:text-[13px] font-poppins`}
                        style={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {tag}
                      </div>
                    ))}
                </div>
                {/* </div> */}
                {/* )} */}
              </div>

              <div className="flex flex-col items-start justify-center mt-2 w-[50%] lg:w-[45%]">
                <p className="tagphrase">{features[3].tagPhrase}</p>
                <h4
                  className="!font-[600] font-poppins text-[18px] lg:text-[24px]  text-landing-primary-black"
                  style={{ lineHeight: 1.3 }}
                >
                  <HighlightedSentence
                    sentence={features[3].label}
                    startWordIndex={features[3].highlightedWordStartIndex}
                    endWordIndex={features[3].highlightedWordEndIndex}
                  />
                </h4>
              </div>
            </div>
          </div>
        </div>
        {/* bottom  card */}
        <div className="flex flex-col lg:flex-row  items-center !px-6- lg:!px-40]  mt-8 gap-x-8 gap-y-4 w-full ">
          {/* bottom left card */}
          <div
            className="feature-card flex flex-col-reverse !w-full lg:!w-[52%] h-[311px] lg:h-[391px] !pt-12"
            style={{
              background:
                "linear-gradient(103.4deg, #EAFFF7 16.66%, #FFFFFF 81.61%)",
            }}
          >
            <div className="h-full w-full flex items-center justify-center ">
              <img
                className="h-full !w-[110%] object-contain"
                src={urlFor(features[4].imgUrl)}
                alt="spreadsheet destkop"
              />
            </div>
            <div className="flex flex-col items-start !justify-start">
              <p className="tagphrase">{features[4].tagPhrase}</p>
              <h4
                className="!font-[600] font-poppins text-[18px]  lg:text-[24px] w-[80%] lg:w-[80%]  text-landing-primary-black"
                style={{ lineHeight: 1.3 }}
              >
                <HighlightedSentence
                  sentence={features[4].label}
                  startWordIndex={features[4].highlightedWordStartIndex}
                  endWordIndex={features[4].highlightedWordEndIndex}
                />
              </h4>
            </div>
          </div>

          {/* bottom right card */}
          <div
            className="feature-card  flex flex-row-reverse !w-full h-[250px] lg:h-[391px] relative overflow-hidden "
            style={{
              background:
                "linear-gradient(103.4deg, #FBF6FF 16.66%, #F4F9FF 49.14%, #EFF4FF 81.61%)",
            }}
          >
            <div
              className="bg-[#54F79F1C] w-40 h-40 absolute rounded-full "
              style={{ top: -80, left: -40 }}
            />
            <div
              className="w-[50%] lg:w-[43%] bg-red-500-"
              style={{ marginLeft: -20 }}
            >
              <img
                className="h-full w-full object-contain"
                src={urlFor(features[5].imgUrl)}
                alt="ai robot"
              />
            </div>

            <div className="flex flex-col items-start !justify-center w-[60%] flex-0 lg:flex-1 gap-y-6 bg-blue-500-">
              <div>
                <p className="tagphrase">{features[5].tagPhrase}</p>
                <h4
                  className="!font-[600] font-poppins text-[16px]  lg:text-[28px]  text-landing-primary-black"
                  style={{ lineHeight: 1.4 }}
                >
                  <HighlightedSentence
                    sentence={features[5].label}
                    startWordIndex={features[5].highlightedWordStartIndex}
                    endWordIndex={features[5].highlightedWordEndIndex}
                  />
                </h4>
              </div>
              <a
                href="/register"
                className="primary_cta_btn px-3 lg:px-8 !text-[12px] lg:!text-[16px] !h-[42px] lg:!h-[44px]"
                style={{ textDecoration: "none" }}
              >
                Generate Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SalientInfoSection = () => {
  const [salientFeatures, setSalientFeatures] = useState([
    ...salientFeaturesHardcoded,
  ]);
  const [middleInfo, setMiddleInfo] = useState(middleInfoHardcoded);

  useEffect(() => {
    const getSalientFeatures = () => {
      const query = "*[_type == 'salientFeatures'] | order(_createdAt asc)";
      client.fetch(query).then((data) => {
        if (data.length >= 3) setSalientFeatures(data);
      });
    };
    const getMiddleInfo = () => {
      const query = "*[_type == 'middleInfo'] | order(_createdAt asc)";
      client.fetch(query).then((data) => {
        if (data.length >= 1) setMiddleInfo(data[0]);
      });
    };

    getSalientFeatures();
    getMiddleInfo();
  }, []);

  return (
    <div className="flex flex-col-reverse lg:flex-row items-center gap-x-20 gap-y-6 !mx-4 lg:!mx-40 !my-2 !mb-0 !pb-0 relative">
      <div className="flex flex-col w-full hidden lg:block z-10">
        {salientFeatures.map((feature, i) => (
          <div
            key={feature._id}
            className="flex flex-col lg:!flex-row gap-x-6 items-center px-2 pl-4 py-4  rounded-[20px]"
            style={{
              backgroundColor: i === 1 ? "#D9FAE7" : "",
              boxShadow: i === 1 ? "0px 8px 32px 0px #11111A0D" : "",
              boxShadow: i === 1 ? "0px 4px 16px 0px #11111A1A" : "",
            }}
          >
            <div
              className="!h-[22px] !w-[22px] lg:!h-[60px] lg:!w-[60px] rounded-full object-contain"
              style={{ backgroundColor: i === 1 ? "#27AE60" : "#D9FAE7" }}
              dangerouslySetInnerHTML={{ __html: feature.svgIcon }}
              id="target-svg-container"
            >
              {/* <div  /> */}
            </div>
            <div className="flex flex-col gap-y-2">
              <h5 className="font-poppins !font-[500] -text-[] lg:text-[18px] text-[#272D4E]">
                {feature.label}
              </h5>
              <p className="font-roboto !font-[400] text-[16px]  text-landing-secondary-black hidden lg:block">
                {feature.description}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className="flex flex-col w-full bg-red-500- px-2 z-10">
        <h2
          className="!font-[700] font-raleway text-[28px] lg:text-[44px]  text-landing-primary-black"
          style={{ lineHeight: 1.4 }}
        >
          <HighlightedSentence
            sentence={middleInfo.label}
            startWordIndex={middleInfo.highlightedWordStartIndex}
            endWordIndex={middleInfo.highlightedWordEndIndex}
          />
        </h2>
        <p className="my-4 font-roboto !font-[400] text-[14px] lg:text-[18px] text-landing-secondary-black hidden lg:block">
          {middleInfo.description}
        </p>
        <p className="!mt-4 !mb-2 font-roboto !font-[400] text-[14px] lg:text-[18px] text-landing-secondary-black  block lg:hidden">
          Simplify testing with our user friendly platform. Generate test cases
          with AI and share test plans, results effortlessly, saving time and
          keeping everyone on your team aligned.
        </p>

        <div className="flex flex-col block lg:hidden !mt-0 mb-4 !px-0">
          {salientFeatures.map((feature, i) => (
            <div
              key={feature._id}
              className="flex flex-row gap-x-6 items-center !px-0 pl-4 py-2  "
            >
              <div
                className="!h-[22px] !w-[22px] lg:!h-[60px] lg:!w-[60px] rounded-full object-contain items-center"
                style={{ backgroundColor: "#D9FAE7" }}
              >
                {i === 0 && (
                  <svg
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="13.5" cy="13.5" r="13.5" fill="#D8FAE6" />
                    <path
                      d="M17.6781 14.4083C17.4949 14.5732 17.4107 14.8117 17.4524 15.0455L18.0815 18.278C18.1345 18.5519 18.01 18.8292 17.7631 18.9875C17.5211 19.1518 17.1991 19.1715 16.9352 19.0401L13.8015 17.5224C13.6925 17.4685 13.5715 17.4396 13.4477 17.4364H13.256C13.1895 17.4456 13.1244 17.4653 13.0649 17.4955L9.93051 19.0204C9.77556 19.0926 9.60008 19.1183 9.42815 19.0926C9.00928 19.0191 8.7298 18.6485 8.79843 18.2576L9.42815 15.0252C9.4699 14.7893 9.3857 14.5495 9.20244 14.382L6.64749 12.0825C6.43381 11.89 6.35952 11.6009 6.45716 11.3401C6.55197 11.0799 6.79395 10.8901 7.08617 10.8473L10.6027 10.3737C10.8701 10.348 11.105 10.1969 11.2253 9.97354L12.7748 7.02364C12.8116 6.95794 12.859 6.89749 12.9164 6.84625L12.98 6.80026C13.0133 6.76609 13.0515 6.73784 13.094 6.71485L13.1711 6.68857L13.2914 6.64258H13.5892C13.8553 6.6682 14.0895 6.81602 14.2119 7.03678L15.7819 9.97354C15.8951 10.1884 16.1152 10.3375 16.3692 10.3737L19.8857 10.8473C20.1829 10.8868 20.4312 11.0773 20.5296 11.3401C20.6223 11.6035 20.5423 11.8926 20.3244 12.0825L17.6781 14.4083Z"
                      fill="#27AE60"
                    />
                  </svg>
                )}
                {i === 1 && (
                  <svg
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="13.5" cy="13.5" r="13.5" fill="#D8FAE6" />
                    <path
                      d="M18.2666 14.5212C17.5645 14.5212 16.9463 14.8667 16.56 15.392L14.0377 13.9906L16.56 12.5891C16.9463 13.1144 17.564 13.4599 18.2666 13.4599C19.4388 13.4599 20.3892 12.5095 20.3892 11.3373C20.3892 10.165 19.4388 9.21461 18.2666 9.21461C17.0943 9.21461 16.1439 10.165 16.1439 11.3373C16.1439 11.6164 16.2012 11.8817 16.2989 12.1263L13.756 13.5395V10.7801C14.8014 10.6479 15.6133 9.76544 15.6133 8.68395C15.6133 7.51172 14.6629 6.56131 13.4906 6.56131C12.3184 6.56131 11.368 7.51172 11.368 8.68395C11.368 9.76544 12.1799 10.6479 13.2253 10.7801V13.5395L10.6818 12.1263C10.78 11.8817 10.8373 11.6164 10.8373 11.3373C10.8373 10.165 9.88691 9.21461 8.71468 9.21461C7.54245 9.21461 6.59204 10.165 6.59204 11.3373C6.59204 12.5095 7.54245 13.4599 8.71468 13.4599C9.41728 13.4599 10.035 13.1144 10.4213 12.5891L12.9435 13.9906L10.4208 15.392C10.035 14.8667 9.41728 14.5212 8.71468 14.5212C7.54245 14.5212 6.59204 15.4716 6.59204 16.6439C6.59204 17.8161 7.54245 18.7665 8.71468 18.7665C9.88691 18.7665 10.8373 17.8161 10.8373 16.6439C10.8373 16.3647 10.78 16.0994 10.6818 15.8548L13.2253 14.4416V17.2011C12.1799 17.3332 11.368 18.2157 11.368 19.2972C11.368 20.4694 12.3184 21.4198 13.4906 21.4198C14.6629 21.4198 15.6133 20.4694 15.6133 19.2972C15.6133 18.2157 14.8014 17.3332 13.756 17.2011V14.4416L16.2994 15.8548C16.2012 16.0989 16.1439 16.3642 16.1439 16.6439C16.1439 17.8161 17.0943 18.7665 18.2666 18.7665C19.4388 18.7665 20.3892 17.8161 20.3892 16.6439C20.3892 15.4716 19.4388 14.5212 18.2666 14.5212Z"
                      fill="#27AE60"
                    />
                  </svg>
                )}
                {i === 2 && (
                  <svg
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="13.5" cy="13.5" r="13.5" fill="#D8FAE6" />
                    <g clip-path="url(#clip0_464_1431)">
                      <path
                        d="M11.9341 6.30188H15.3303V17.3396H11.9341V6.30188ZM10.236 9.6981H6.83978V17.3396H10.236V9.6981ZM21.2737 19.0378H5.99072V19.9717H21.2737V19.0378ZM20.4247 11.3962H17.0285V17.3396H20.4247V11.3962Z"
                        fill="#27AE60"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_464_1431">
                        <rect
                          width="15.283"
                          height="15.283"
                          fill="white"
                          transform="translate(6.5 8)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                )}
              </div>

              <div className="flex flex-col justify-center items-center">
                <h5 className="font-poppins !font-[500] -text-[] lg:text-[18px] text-[#272D4E]">
                  {feature.label}
                </h5>
              </div>
            </div>
          ))}
        </div>

        <div className="flex flex-row items-center justify-start gap-x-3">
          <a
            href={middleInfo.ctaLink}
            className="primary_btn w-[150px] lg:w-[175px] !h-[42px] lg:!h-[48px]"
          >
            {middleInfo.ctaText}
          </a>
          <a
            href={middleInfo?.infoLink}
            className="primary_info_btn w-[150px] lg:w-[175px] gap-x-2 !h-[42px] lg:!h-[48px]"
          >
            {middleInfo?.infoText}
            <svg
              width="16"
              height="13"
              viewBox="0 0 16 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.01042 6.15213C0.578446 6.15212 0.228253 6.51809 0.228244 6.96956C0.228235 7.42102 0.578414 7.78701 1.01039 7.78702L1.01042 6.15213ZM15.5427 7.54792C15.8481 7.22869 15.8481 6.71111 15.5427 6.39187L10.5651 1.18955C10.2597 0.870311 9.76446 0.8703 9.459 1.18953C9.15354 1.50875 9.15353 2.02633 9.45897 2.34557L13.8835 6.96986L9.45879 11.5939C9.15333 11.9132 9.15332 12.4308 9.45877 12.75C9.76421 13.0692 10.2595 13.0692 10.5649 12.75L15.5427 7.54792ZM1.01039 7.78702L14.9896 7.78733L14.9896 6.15243L1.01042 6.15213L1.01039 7.78702Z"
                fill="#27AE60"
              />
            </svg>
          </a>
        </div>
      </div>

      {/* Floating absolute shapes */}
      <div
        className="absolute  w-[536px] h-[440px]  rounded-[200px] opacity-[86%] box-shadow gradient-green"
        style={{
          top: -20,
          right: -300,
        }}
      />
      <div
        className="absolute  gradient-blue opacity-[39%]"
        style={{
          background:
            "linear-gradient(180deg, rgba(176, 221, 255, 0.77) 0%, rgba(50, 162, 246, 0.77) 100%)",
          left: -300,
          bottom: -100,
        }}
      />
    </div>
  );
};

const AIFeaturesSection = () => {
  const [aiFeatures, setAiFeatures] = useState([...aiFeaturesHardcoded]);

  useEffect(() => {
    const getFeatures = () => {
      const query = "*[_type == 'aiFeatures'] | order(_createdAt asc)";
      client.fetch(query).then((data) => {
        if (data.length >= 2) setAiFeatures(data);
      });
    };

    getFeatures();
  }, []);

  return (
    <div className="w-full !py-24  !pt-32 lg:!pt-24  relative !overflow-visible bg-red-300-">
      <div className="bg-[#AACBFF3B]- w-full !py-[70px] relative overflow-visible curved-edge1 flex flex-col justify-center !px-8 lg:!px-40 pr-0 gap-y-48 lg:gap-y-28 !my-0">
        {/* row1 */}
        {aiFeatures.map((feature, i) => (
          <div
            className="flex flex-col lg:flex-row items-center gap-x-[20%] relative gap-y-8 ai-feature-card px-0 lg:px-12"
            style={{
              justifyContent: i % 2 === 0 ? "start" : "end",
              flexDirection: i % 2 === 0 ? "row" : "row-reverse",
              // marginLeft: i % 2 === 1? 200 : 0,
              boxhadow: "-25px 20px 44px 0px #484A7D66",
            }}
          >
            <div
              className="!p-0 lg:pt-0 w-[242px] h-[226px] lg:h-[311px] lg:w-[275px] bg-red-500- overflow-hidden rounded-[16px] bg-[#f6fffa]- mb-[0] lg:-mb-24"
              style={{
                boxShadow: "0px 16px 56px 0px #11111A1A",
                boxShadow: "0px 8px 24px 0px #11111A1A",
                boxShadow: "0px 4px 16px 0px #11111A1A",
                border: "0.5px solid #E4E4E4F0",
                marginTop: i === 0 ? -200 : "0px", // For mobile, only first card gets marginTop
              }}
              id="first-feature-card-image"
            >
              <img
                className="w-full h-full rounded-[16px] bg-blend-lighten"
                src={urlFor(feature.imgUrl)}
                alt={`mini robot ${i + 1}`}
              />
            </div>
            <div className="w-full  lg:w-[425px] pt-4 lg:pt-0">
              <h2
                className="!font-[600] lg:!font-[500] font-poppins text-[22px] lg:text-[37px] text-landing-primary-black"
                style={{ lineHeight: 1.3, marginTop: i % 2 === 0 ? -31 : 0 }}
              >
                <HighlightedSentence
                  sentence={feature.label}
                  startWordIndex={feature.highlightedWord}
                  endWordIndex={feature.highlightedWord + 1}
                  color={"#316BFF"}
                />
              </h2>
              {feature.description && (
                <p className="font-roboto !font-[400] text-[14px] lg:text-[16px] text-landing-secondary opacity-80 !mt-2 mb-4 ">
                  {feature.description}
                </p>
              )}
              {feature.featuresArray.length > 0 && (
                <div className="font-poppins !font-[400] text-[14px] lg:text-[16px] text-landing-secondary opacity-80 mt-3 mb-4 gap-y-1">
                  {feature.featuresArray.map((fItem) => (
                    <div className="flex flex-row items-center gap-x-4 font-roboto !font-[400]">
                      <svg
                        width="19"
                        height="16"
                        viewBox="0 0 19 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 7.99996L6.95968 14L16.8966 2"
                          stroke="#316BFF"
                          stroke-width="3.75"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      {fItem}
                    </div>
                  ))}
                </div>
              )}

              <a
                href={feature.ctaLink}
                className="primary_cta_btn bg-[#316BFF]  w-full lg:w-[250px] !mt-4 !h-[44px]"
              >
                {feature.ctaText}
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const IntegrationShowcaseSection = () => {
  const [integrations, setIntegrations] = useState([...integrationsHardcoded]);
  useEffect(() => {
    const getIntegrations = () => {
      const query = "*[_type == 'integrations'] | order(_createdAt asc)";
      client.fetch(query).then((data) => {
        if (data.length >= 6) setIntegrations(data);
      });
    };

    getIntegrations();
  }, []);

  return (
    <div className="mt-0 lg:mt-48 px-6 lg:px-6 !mb-3 lg:!mb-48 !pb-0">
      <div className="relative w-full  overflow-hidden">
        <div className="flex flex-col items-center justify-center">
          <h2 className="!font-[700] font-raleway text-[28px] lg:text-[48px] text-center text-landing-primary-black">
            <HighlightedSentence
              sentence={"Seamless Tool Integrations"}
              startWordIndex={2}
              endWordIndex={3}
              color={"#316BFF"}
            />
          </h2>
          <p className="my-4 text-center w-[98%] lg:w-[38%] font-poppins !font-[400] text-[14px] lg:text-[18px] text-landing-secondary-black opacity-85 !mt-2">
            Connect effortlessly with the tools you rely on. Enhance
            productivity and streamline your workflow.
          </p>
          <a className="primary_cta_btn !h-[44px]" href="/register">
            Integrate Now
          </a>
        </div>
        <div className="relative">
          <div className="z-10 !px-6 lg:!px-2 !block top-0 left-0 overflow-hidden ">
            <div
              className="bg-black- hidden lg:block"
              style={{ marginTop: -100 }}
            >
              <svg
                width="100%"
                height="500px"
                viewBox="0 0 1173 562"
                fill="none"
              >
                <path
                  d="M4.5 199.5C4.5 199.5 87 284 96 291.5C105 299 133 293 144 296C155 299 212.5 361.5 212.5 361.5"
                  stroke="#B2BFC8"
                  stroke-opacity="0.93"
                />
                <path
                  d="M245.5 394.5C265.808 416.611 330.5 484 341.5 487.5H530.5"
                  stroke="#B2BFC8"
                  stroke-opacity="0.93"
                />
                <path
                  d="M473 486.998C460.5 483 415 426.498 404 428.998M356 427.5C352.697 431.162 253.732 331.401 250 323.5C246.268 315.599 252.5 286 248 270.5C243.5 255 100.463 115.613 3 21M340.5 486.998C289 488.5 283 486.998 271 486.998C259 486.998 220.5 440.5 210.5 438.5C200.5 436.5 98 440.306 90 437C82 433.694 61.5 412 61.5 412M181.5 537.5C199.618 538.272 206.482 540.759 214.5 536C222.518 531.241 259.5 490 271 487.5"
                  stroke="#B2BFC8"
                  stroke-opacity="0.93"
                />
                <path
                  d="M1172 177.5C1172 177.5 1080.38 272.432 1070.38 280.858C1060.39 289.284 1029.29 282.543 1017.07 285.914C1004.86 289.284 941 359.5 941 359.5"
                  stroke="#B2BFC8"
                  stroke-opacity="0.93"
                />
                <path
                  d="M905 395C884.692 417.111 820 484.5 809 488H620"
                  stroke="#B2BFC8"
                  stroke-opacity="0.93"
                />
                <path
                  d="M677.5 487.498C690 483.5 735.5 426.998 746.5 429.498M794.5 428C797.803 431.662 896.768 331.901 900.5 324C904.232 316.099 898 286.5 902.5 271C907 255.5 1074.54 95.6127 1172 1M810 487.498C861.5 489 867.5 487.498 879.5 487.498C891.5 487.498 930 441 940 439C950 437 1052.5 440.806 1060.5 437.5C1068.5 434.194 1089 412.5 1089 412.5M969 538C950.882 538.772 944.018 541.259 936 536.5C927.982 531.741 891 490.5 879.5 488"
                  stroke="#B2BFC8"
                  stroke-opacity="0.93"
                />
                <path d="M33.5 377.5L1 344.5" stroke="#CED7DD" />
                <path d="M1127 377L1171.5 338" stroke="#BFCAD2" />

                <path
                  d="M1087 395.5C1087 382.797 1097.3 372.5 1110 372.5H1112C1124.7 372.5 1135 382.797 1135 395.5C1135 408.203 1124.7 418.5 1112 418.5H1110C1097.3 418.5 1087 408.203 1087 395.5Z"
                  fill="url(#pattern0_277_13)"
                />
                <g filter="url(#filter0_d_277_13)">
                  <rect
                    x="534"
                    y="443.5"
                    width="88"
                    height="88"
                    rx="20"
                    fill="#ECFFF8"
                    fill-opacity="0.8"
                    shape-rendering="crispEdges"
                  />
                </g>
                <rect
                  x="544"
                  y="455.5"
                  width="68.6377"
                  height="64"
                  fill="url(#pattern1_277_13)"
                />

                <circle
                  cx="48.5"
                  cy="394"
                  r="25.5"
                  fill="white"
                  fillOpacity="0.07"
                />
                <path
                  d="M25 394C25 381.021 35.521 370.5 48.5 370.5C61.479 370.5 72 381.021 72 394C72 406.979 61.479 417.5 48.5 417.5C35.521 417.5 25 406.979 25 394Z"
                  fill="url(#pattern5_277_13)"
                />

                <circle
                  cx="230.5"
                  cy="379"
                  r="25.5"
                  fill="gray"
                  fillOpacity="0.08"
                />
                <path
                  d="M207 379C207 366.021 217.521 355.5 230.5 355.5C243.479 355.5 254 366.021 254 379C254 391.979 243.479 402.5 230.5 402.5C217.521 402.5 207 391.979 207 379Z"
                  fill="url(#pattern6_277_13)"
                />
                <circle
                  cx="380.5"
                  cy="429"
                  r="25.5"
                  fill="gray"
                  fillOpacity="0.08"
                />
                <path
                  d="M357 429C357 416.021 367.521 405.5 380.5 405.5C393.479 405.5 404 416.021 404 429C404 441.979 393.479 452.5 380.5 452.5C367.521 452.5 357 441.979 357 429Z"
                  fill="url(#pattern7_277_13)"
                />
                <circle
                  cx="770.5"
                  cy="429"
                  r="25.5"
                  fill="gray"
                  fillOpacity="0.08"
                />
                <path
                  d="M747 429C747 416.021 757.521 405.5 770.5 405.5C783.479 405.5 794 416.021 794 429C794 441.979 783.479 452.5 770.5 452.5C757.521 452.5 747 441.979 747 429Z"
                  fill="url(#pattern8_277_13)"
                />

                <path
                  d="M135 536C135 523.021 145.521 512.5 158.5 512.5C171.479 512.5 182 523.021 182 536C182 548.979 171.479 559.5 158.5 559.5C145.521 559.5 135 548.979 135 536Z"
                  fill="url(#pattern2_277_13)"
                />
                <circle
                  cx="994.5"
                  cy="536"
                  r="25.5"
                  fill="white"
                  fillOpacity="0.57"
                />
                <path
                  d="M1007.38 537.625C1007.38 524.646 1017.901 514.125 1030.88 514.125C1043.859 514.125 1054.38 524.646 1054.38 537.625C1054.38 550.604 1043.859 561.125 1030.88 561.125C1017.901 561.125 1007.38 550.604 1007.38 537.625Z"
                  fill="url(#pattern3_277_13)"
                  transform="translate(-34.88, -2.6)"
                />
                <circle
                  cx="924.5"
                  cy="376"
                  r="25.5"
                  fill="gray"
                  fillOpacity="0.08"
                />
                <path
                  d="M901 376C901 363.021 911.521 352.5 924.5 352.5C937.479 352.5 948 363.021 948 376C948 388.979 937.479 399.5 924.5 399.5C911.521 399.5 901 388.979 901 376Z"
                  fill="url(#pattern4_277_13)"
                />

                {
                  <defs>
                    <defs>
                      <pattern
                        id="pattern0_277_13"
                        patternContentUnits="objectBoundingBox"
                        width="1"
                        height="1"
                      >
                        <use
                          href="#image0_277_13"
                          transform="matrix(0.00492611 0 0 0.00514029 0 -0.0217391)"
                        />
                      </pattern>
                    </defs>

                    <defs>
                      <filter
                        id="filter0_d_277_13"
                        x="521"
                        y="430.5"
                        width="114"
                        height="114"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feMorphology
                          radius="1"
                          operator="dilate"
                          in="SourceAlpha"
                          result="effect1_dropShadow_277_13"
                        />
                        <feOffset />
                        <feGaussianBlur stdDeviation="6" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0.385833 0 0 0 0 0.672638 0 0 0 0 1 0 0 0 0.75 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_277_13"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_277_13"
                          result="shape"
                        />
                      </filter>
                      <pattern
                        id="pattern1_277_13"
                        patternContentUnits="objectBoundingBox"
                        width="1"
                        height="1"
                      >
                        <use
                          href="#image1_277_13"
                          transform="matrix(0.00492611 0 0 0.00514029 0 -0.0217391)"
                        />
                      </pattern>
                    </defs>
                    <defs>
                      <pattern
                        id="pattern2_277_13"
                        patternContentUnits="objectBoundingBox"
                        width="1"
                        height="1"
                      >
                        <use
                          href="#image2_277_13"
                          transform="matrix(0.00492611 0 0 0.00514029 0 -0.0217391)"
                        />
                      </pattern>
                    </defs>
                    <defs>
                      <pattern
                        id="pattern3_277_13"
                        patternContentUnits="objectBoundingBox"
                        width="1"
                        height="1"
                      >
                        <use
                          href="#image3_277_13"
                          transform="matrix(0.00492611 0 0 0.00514029 0 -0.0217391)"
                        />
                      </pattern>
                    </defs>

                    <pattern
                      id="pattern4_277_13"
                      patternContentUnits="objectBoundingBox"
                      width="1"
                      height="1"
                    >
                      <use
                        href="#image4_277_13"
                        transform="matrix(0.00492611 0 0 0.00514029 0 -0.0217391)"
                      />
                    </pattern>
                    <pattern
                      id="pattern5_277_13"
                      patternContentUnits="objectBoundingBox"
                      width="1"
                      height="1"
                    >
                      <use
                        href="#image5_277_13"
                        transform="matrix(0.00492611 0 0 0.00514029 0 -0.0217391)"
                      />
                    </pattern>
                    <pattern
                      id="pattern6_277_13"
                      patternContentUnits="objectBoundingBox"
                      width="1"
                      height="1"
                    >
                      <use
                        href="#image6_277_13"
                        transform="matrix(0.00492611 0 0 0.00514029 0 -0.0217391)"
                      />
                    </pattern>
                    <pattern
                      id="pattern7_277_13"
                      patternContentUnits="objectBoundingBox"
                      width="1"
                      height="1"
                    >
                      <use
                        href="#image7_277_13"
                        transform="matrix(0.00492611 0 0 0.00514029 0 -0.0217391)"
                      />
                    </pattern>
                    <pattern
                      id="pattern8_277_13"
                      patternContentUnits="objectBoundingBox"
                      width="1"
                      height="1"
                    >
                      <use
                        href="#image8_277_13"
                        transform="matrix(0.00492611 0 0 0.00514029 0 -0.0217391)"
                      />
                    </pattern>

                    <image
                      id="image1_277_13"
                      width="200"
                      height="200"
                      xlinkHref={"./oyetest_trans_logo.png"}
                    />
                    <image
                      id="image0_277_13"
                      width="203"
                      height="203"
                      xlinkHref={urlFor(integrations[0].logoUrl)}
                    />
                    <image
                      id="image2_277_13"
                      width="205"
                      height="205"
                      xlinkHref={urlFor(integrations[1].logoUrl)}
                    />
                    <image
                      id="image3_277_13"
                      width="205"
                      height="205"
                      xlinkHref={urlFor(integrations[2].logoUrl)}
                    />
                    <image
                      id="image4_277_13"
                      width="205"
                      height="205"
                      xlinkHref={urlFor(integrations[3].logoUrl)}
                    />
                    <image
                      id="image5_277_13"
                      width="205"
                      height="205"
                      xlinkHref={urlFor(integrations[4].logoUrl)}
                    />
                    <image
                      id="image6_277_13"
                      width="205"
                      height="205"
                      xlinkHref={urlFor(integrations[5].logoUrl)}
                    />
                    <image
                      id="image7_277_13"
                      width="205"
                      height="205"
                      xlinkHref={urlFor(integrations[6].logoUrl)}
                    />
                    <image
                      id="image8_277_13"
                      width="205"
                      height="205"
                      xlinkHref={urlFor(integrations[7].logoUrl)}
                    />
                  </defs>
                }
              </svg>
            </div>
            <div className="block lg:hidden mb-16" style={{}}>
              <img
                src="./IntegrationShowcaseMobile.svg"
                alt="integrations showcase "
              />
            </div>
          </div>
          {/* allows scrolling even on svg */}
          <div className=" opacity-0 z-20 absolute h-full w-full top-0 left-0" />
        </div>
      </div>
    </div>
  );
};

const TestimonialsSection = () => {
  const [testimonials, setTestimonials] = useState([...testimonialsHardcoded]);
  const [perPage, setPerPage] = useState(1);

  useEffect(() => {
    const updatePerPage = () => {
      const width = window.innerWidth;
      if (width >= 1024) {
        setPerPage(3); // Large devices
      } else {
        setPerPage(1); // Mobile devices
      }
    };

    // Initial call
    updatePerPage();

    // Add event listener
    window.addEventListener("resize", updatePerPage);

    // Clean up event listener
    return () => window.removeEventListener("resize", updatePerPage);
  }, []);

  useEffect(() => {
    const getIntegrations = () => {
      const query = "*[_type == 'testimonials'] | order(_createdAt asc)";
      client.fetch(query).then((data) => {
        if (data.length >= 3) setTestimonials(data);
      });
    };

    getIntegrations();
  }, []);

  return (
    <div className="my-6 mb-2 lg:mb-24 lg:my-24 -[!px-6] -[lg:!px-40] !pb-6 page-section">
      <div className="flex flex-col items-center justify-center w-full !mb-[130px] ">
        {/* label */}
        <div className="flex  flex-col lg:flex-row items-start lg:items-center justify-center lg:justify-between  mb-8 relative">
          <h3
            className="!font-[600] font-poppins text-[28px] lg:text-[44px]  text-landing-primary-black w-[70%] lg:w-[45%] mb-3 lg:mb-0"
            style={{ lineHeight: 1.4 }}
          >
            <HighlightedSentence
              sentence={"What people are saying about us"}
              startWordIndex={4}
              endWordIndex={6}
            />
          </h3>
          <p className=" w-full lg:w-[39%] font-poppins !font-[400] text-[14px] lg:text-[18px] text-landing-secondary-black opacity-80 z-10">
            Curious how Oyetest can transform your testing experience? Discover
            what our users love about our platform!
          </p>
          <div
            className="absolute top-12 right-40 gradient-green opacity-[39%]"
            style={{
              background: "#A2EBCF",
              top: "-1rem",
              right: "23rem",
            }}
          />
          <div
            className="absolute gradient-blue opacity-[39%]"
            style={{
              background:
                "linear-gradient(180deg, rgba(176, 221, 255, 0.77) 0%, rgba(50, 162, 246, 0.87) 100%)",
              top: "-10rem",
              right: "-6rem",
            }}
          />
        </div>
      </div>
      <div
        className="slider-container !px-0"
        style={{ marginTop: perPage >= 3 ? -100 : -110 }}
      >
        <Splide
          options={{
            perPage: perPage,
            autoplay: true,
            interval: 2000, // Set this to a lower value to increase the swiping speed
            speed: 500, // Speed of the slide transition (animation duration)
            rewind: true,
            rewindByDrag: true,
            arrows: false,
            pauseOnHover: true, // Ensures the autoplay pauses on hover
            gap: 40,
            height: 500,
          }}
        >
          {testimonials.map((review, i) => (
            <SplideSlide key={review.id}>
              <div className=" !px-">
                <div
                  className="flex flex-col py-12  gap-y-12 rounded-[20px] !h-[400px] !min-h-[400px]- -!max-h-[600px]- testimonial-card !px-8 hover:!bg-[#F5FFFD] transition-shadow duration-300 ease-in-out"
                  style={{
                    boxShadow: " 0px 2px 4px -1px #0000000F",
                    boxShadow: "0px 4px 6px -1px #0000001A",
                    border: "0.6px solid #D7D7D7D4",
                    // marginTop: i % 2 === 1 && perPage >= 3 ? -40 : 0,
                    backgroundColor: "#F6FAFF",
                   
                  }}
                >
                  <div className="">
                    <svg
                      width="43"
                      height="29"
                      viewBox="0 0 43 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.7984 28.2475L18.9984 0.647461H12.3984L0.398438 28.2475H11.7984ZM35.7984 28.2475L42.9984 0.647461H36.3984L24.3984 28.2475H35.7984Z"
                        fill="#27AE60"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-col !items-between !h-full !justify-between">
                    <p
                      className=" font-roboto !font-[400] text-[18px] lg:text-[18px] text-landing-secondary-black -!min-h-[150px] -!max-h-[200px] !h-[180px]"
                      style={{ lineHeight: 1.7 }}
                    >
                      {review.feedback}
                    </p>

                    <div className="flex flex-row   overflow-hidden gap-x-4 my-4">
                      <img
                        className=" object-contain rounded-full border !w-12 !h-12 bg-gray-300 border-1"
                        src={urlFor(review.imgurl)}
                        style={{ border: "1px solid #000" }}
                        alt=""
                      />
                      <div className="flex flex-col gap-y-0">
                        <p className="text-primary-green !font-[600] font-poppins">
                          {review.name}
                        </p>
                        <p className="text-[#1E3A7F] text-[16px] !font-[400] opacity-50 font-poppins">
                          {review.role}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
};

const CommunityJoinSection = () => {
  const [community, setCommunity] = useState({ ...communtityJoiningHarcoded });

  useEffect(() => {
    const getCommunity = () => {
      const query = "*[_type == 'communityJoining'] | order(_createdAt asc)";
      client.fetch(query).then((data) => {
        if (data.length >= 1) setCommunity(data[0]);
      });
    };

    getCommunity();
  }, []);

  return (
    <div className="bg-none lg:bg-[#F0F5FF] rounded-[20px] my-4 py-[52px]  page-section-margin !mx-6 [lg:mx-40 flex flex-col lg:flex-row mb-16 lg:!mb-28 !px-3 lg:!px-20 gap-x-80 gap-y-8">
      <div className="flex  flex-col  justify-center w-full lg:w-[637px] ">
        <p className="tagphrase mb-2"> Join Our Community</p>
        <h3 className="!font-[600] font-poppins mb-6 text-[28px] lg:text-[38px]  text-landing-primary-black w-[70%] lg:w-full">
          <HighlightedSentence
            sentence={community.label}
            startWordIndex={community.highlightedWordStartIndex}
            endWordIndex={community.highlightedWordEndIndex}
            color={"#316BFF"}
          />
        </h3>
        <p className="  font-poppins !font-[400] !text-[14px] lg:!text-[18px] text-landing-secondary-black opacity-80">
          {community.description}
        </p>
      </div>
      <div className="flex flex-row  items-center justify-end ">
        <a
          className="primary_cta_btn w-full lg:w-auto !min-w-[175px] !h-[46px] lg:!h-[48px] gap-x-2"
          href={community.ctaUrl}
        >
          {community.ctaText}{" "}
          <svg
            width="14"
            height="13"
            viewBox="0 0 14 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.487852 11.3854C0.148431 11.6683 0.102572 12.1727 0.385423 12.5121C0.668274 12.8516 1.17273 12.8974 1.51215 12.6146L0.487852 11.3854ZM13.7967 2.07243C13.8367 1.63242 13.5124 1.24329 13.0724 1.20329L5.902 0.551428C5.46198 0.511427 5.07286 0.835701 5.03285 1.27571C4.99285 1.71573 5.31713 2.10486 5.75714 2.14486L12.1309 2.72429L11.5514 9.098C11.5114 9.53802 11.8357 9.92714 12.2757 9.96715C12.7157 10.0071 13.1049 9.68287 13.1449 9.24286L13.7967 2.07243ZM1.51215 12.6146L13.5121 2.61458L12.4879 1.38542L0.487852 11.3854L1.51215 12.6146Z"
              fill="white"
            />
          </svg>
        </a>
      </div>
    </div>
  );
};

const FooterSection = ({ showLogo = true, hasXpadding = true }) => {
  const [socials, setSocials] = useState([...socialsHarcoded]);

  useEffect(() => {
    const getSocials = () => {
      const query = "*[_type == 'socials'] | order(_createdAt asc)";
      client.fetch(query).then((data) => {
        if (data.length >= 1) setSocials(data);
      });
    };

    getSocials();
  }, []);

  return (
    <div className={`mx-6] [lg:mx-${hasXpadding ? 40 : 0} page-section !mt-0 !bg-transparent`}>
      <div className="flex flex-col lg:flex-row justify-between- gap-x-60 gap-y-6 lg:gap-y-24">
        {showLogo && (
          <div className="flex flex-col justify-center gap-y-6">
            <a
              href="/"
              className=" !w-[190px]"
              style={{ textDecoration: "none" }}
            >
              <img
                src="https://www.oyetest.com/assets/images/new_logo_full.png"
                alt="Logo"
              />
            </a>
            <div className="!hidden lg:!block">
              <a
                className="!flex font-poppins text-[14px]  !items-center !justify-center primary_btn gap-x-1 !font-[500] !w-[140px] !text-white !h-[44px]"
                href="/contactus"
                style={{ textDecoration: "none" }}
              >
                Book a demo
              </a>
            </div>
          </div>
        )}
        <div className="flex flex-wrap justify-between gap-x-24 gap-y-6  w-full ">
          <div>
            <p className="font-[500] text-[16px] lg:text-[18px] font-poppins text-[#272D4E]">
              Useful Links
            </p>
            <div className="flex flex-col mt-3 gap-y-2">
              {usefulLinksHardcoded.map((link, i) => (
                <a
                  className="block !text-[#39414A] !font-[400] text-[12px] lg:text-[16px] font-poppins"
                  href={link.link}
                  key={`useful-link-${i}`}
                  style={{ textDecoration: "none" }}
                >
                  {link.label}
                </a>
              ))}
            </div>
          </div>
          <div className="">
            <p className="font-[500] text-[16px] lg:text-[18px] font-poppins text-[#272D4E]">
              Policy
            </p>
            <div className="flex flex-col mt-3 gap-y-2">
              {policiesLinkHardcoded.map((link, i) => (
                <a
                  className="block !text-[#39414A] !font-[400] text-[12px] lg:text-[16px] font-poppins"
                  href={link.link}
                  key={`useful-link-${i}`}
                  style={{ textDecoration: "none" }}
                >
                  {link.label}
                </a>
              ))}
            </div>
          </div>
          <div>
            <p className="font-[500] text-[16px] lg:text-[18px] font-poppins text-[#272D4E]">
              Partner
            </p>
            <div className="flex flex-col mt-3 gap-y-2">
              {partnersLinkHardcoded.map((link, i) => (
                <a
                  className="block !text-[#39414A] !font-[400] text-[12px] lg:text-[16px] font-poppins"
                  href={link.link}
                  key={`useful-link-${i}`}
                  style={{ textDecoration: "none" }}
                >
                  {link.label}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-[1px] bg-gray-400 my-8" />
      <div className="flex flex-col lg:flex-row justify-start lg:justify-between gap-y-1 items-start lg:items-center">
        <p className="!font-[300] text-[#9C9C9C] !text-[14px] lg:!text-[14px] opacity-80 font-poppins">
          Copyright © {new Date().getFullYear()} Oyesense. All Rights Reserved.
        </p>
        <div className="flex flex-row gap-x-3 justify-between">
          {socials
            .filter((s) => s.enabled)
            .map((social) => (
              <a
                className="bg-[#E5FAF1BA] w-10 lg:w-12 h-10 lg:h-12 rounded-[12px] p-0.5 flex items-center justify-center mix-blend-darken"
                href={social.link}
              >
                <a href={social.link}>
                  <img
                    className="w-6 h-6 lg:w-8 lg:h-8 rounded-full object-cover"
                    alt={social.name}
                    src={urlFor(social.logoUrl)}
                  />
                </a>{" "}
              </a>
            ))}
        </div>
      </div>
    </div>
  );
};

const HighlightedSentence = ({
  sentence,
  startWordIndex,
  endWordIndex,
  color,
}) => {
  // Split the sentence into words
  const words = sentence.split(" ").filter((word) => word.length > 0);

  // console.log("WORDS", words);

  // Ensure the indices are within bounds
  if (startWordIndex < 0) startWordIndex = 0;
  if (endWordIndex > words.length) endWordIndex = words.length;
  if (startWordIndex > endWordIndex) startWordIndex = endWordIndex;

  return (
    <p>
      {words.map((word, index) => {
        // Add space before words after the first one
        const space = index > 0 ? " " : "";

        if (index >= startWordIndex && index < endWordIndex) {
          return (
            <React.Fragment key={index}>
              {space}
              <span className="text-primary-green" style={{ color }}>
                {word}
              </span>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={index}>
              {space}
              {word}
            </React.Fragment>
          );
        }
      })}
    </p>
  );
};

export default LandingPage;

export { FooterSection, LandingNav };
