import React, { useEffect, useState } from "react";
import ProfilePopUp from "./ProfilePopUp";
import { history } from "../../_helpers/history";
import Promises from "../../_helpers/utils";

const Navbar = ({ selectInvite, selectDashboard, fixed = false}) => {
  const [currentSelectedPage, setCurrentSelectedPage] = useState("dashboard");

  return (
    <div
      className="container-fluid- project-list- flex flex-row items-center justify-between w-full !px-[78px] !h-[66px] bg-primary-green z-[500000]"
      style={{
        borderBottom: "1px solid #EAEAEA",
        boxShadow: "0px 2px 0px 0px #0000000D",
        position: fixed ? "fixed" : "",
      }}
    >
      <div
        className="logo- cursor-pointer"
        onClick={() => {
          Promises.setPage("dashboard");
          if (selectDashboard) selectDashboard();
          //   setCurrentSelectedPage("dashboard");
          history.push("/dashboard");
        }}
      >
        <div className="flex flex-row items-center gap-x-2">
          <div className="bg-white rounded-full !h-[38px] !w-[38px] flex items-center justify-center !font-[600] text-[18.2px] capitalize text-primary-green font-roboto">
            {!Promises.getOrgLogo() && (
              <p>{Promises.getOrgName() ? Promises.getOrgName()[0] : "T"}</p>
            )}
            {Promises.getOrgLogo() && (
              <img
                className="rounded-full border"
                src={Promises.getOrgLogo()}
                alt={Promises.getOrgName() ? Promises.getOrgName()[0] : "T"}
              />
            )}
          </div>
          <p className="!font-[600] !text-[17px] font-raleway capitalize text-white">
            {Promises.getOrgName() || "Oyetest"}
          </p>
        </div>
        {console.log(Promises.getLocalIteam("domain"))}
      </div>

      <div className="menu-extras- topbar-custom- flex flex-row items-center !pr-0 !mr-0 gap-x-[41px]">
        <div
          className="nav-items"
          style={
            {
              // display: "flex",
              // alignItems: "center",
              // cursor: "pointer",
              // fontSize: "12px",
              // fontWeight: 300,
              // marginRight: 24,
              // color: Promises.getPage() === "dashboard" ? "#2F64ED" : "white",
            }
          }
          onClick={() => {
            Promises.setPage("dashboard");
            if (selectDashboard) selectDashboard();
            history.push("/dashboard");
          }}
        >
          Dashboard
          {Promises.getPage() === "dashboard" && (
            <div className=" bottom-indicator" />
          )}
        </div>
        <div
          className="nav-items"
          style={
            {
              // display: "flex",
              // alignItems: "center",
              // cursor: "pointer",
              // fontSize: "12px",
              // fontWeight: 300,
              // marginRight: 24,
              // color: Promises.getPage() === "invite" ? "#2F64ED" : "white",
            }
          }
          onClick={() => {
            Promises.setPage("invite");
            selectInvite();
            // setCurrentSelectedPage("invite");
          }}
        >
          Invite team
          {Promises.getPage() === "invite" && (
            <div className=" bottom-indicator" />
          )}
        </div>
        <div
          className="nav-items"
          style={
            {
              // display: "flex",
              // alignItems: "center",
              // cursor: "pointer",
              // fontSize: "12px",
              // fontWeight: 300,
              // marginRight: 24,
              // color: Promises.getPage() === "price" ? "#2F64ED" : "white",
            }
          }
          onClick={() => {
            // setCurrentSelectedPage("price");
            Promises.setPage("price");
            history.push("/price");
          }}
        >
          Payments
          {Promises.getPage() === "price" && (
            <div className=" bottom-indicator" />
          )}
        </div>
        <div
          className="nav-items"
          style={
            {
              // display: "flex",
              // alignItems: "center",
              // cursor: "pointer",
              // fontSize: "12px",
              // fontWeight: 300,
              // marginRight: 24,
              // color: Promises.getPage() === "intigration" ? "#2F64ED" : "white",
            }
          }
          onClick={() => {
            Promises.setPage("intigration");
            history.push("/integration");
          }}
        >
          Integration
          {Promises.getPage() === "intigration" && (
            <div className=" bottom-indicator" />
          )}
        </div>
        <ProfilePopUp />
      </div>
    </div>
  );
};

export default Navbar;
