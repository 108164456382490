import React, { Component } from "react";
import AUX from "../hoc/Aux_";
import { Link } from "react-router-dom";
import Axios from "axios";
import { BASE_URL, LOGO_FULL_URL } from "../_helpers/constants";
import OtpInput from "react-otp-input";
import { history } from "../_helpers/history";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import PendingVerification from "../_components/PendingVerification";
import Promises, { validatePassword } from "../_helpers/utils";
import AuthSidebar from "../_components/AuthSidebar";
import SuccessComponent from "../_components/SuccessComponent";

export default class Pages_recoverpw_2 extends Component {
  state = {
    code: "",
    email: "",
    newPassword: "",
    newPassword2: "",
    submitted: false,
    status: "",
    emailEntered: false,
    otpEntered: false,
    otp: "",
    isHiddenPass: true,
    isHiddenPass2: true,
    generatedLink: "/",
    svgNumber: 0,
  };

  onNext = () => {
    const { email } = this.state;

    if (email) {
      this.setState({ status: "emailEntered", svgNumber: -1 });
    }
  };

  componentDidUpdate(pprops,pstate){
    if(this.state.svgNumber!==pstate.svgNumber){
      console.log("svgNumber changed to " + this.state.svgNumber)
    }
  }
  changePassword = () => {
    if (this.state.newPassword.length < 1) {
      NotificationManager.error("Please enter new password");
    } else if (this.state.newPassword2.length < 1) {
      NotificationManager.error("Please enter confirm password");
    } else if (this.state.newPassword2 !== this.state.newPassword) {
      NotificationManager.error("Please make sure your password match");
    } else {
      this.setState({ submitted: true });
      const { newPassword, email } = this.state;

      const data = {
        password: newPassword,
        email: email,
      };

      const token = Promises.getToken();
      if (!token) {
        NotificationManager.error("Please login to reset password");
        this.setState({ status: "" });
        return;
      }

      Axios.post(`${BASE_URL}auth/reset-password`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then((res) => {
          console.log(res);
          setTimeout(() => {
            NotificationManager.success("Your password reset successfully");
          }, 500);
          console.log("res at pass reset done", res);

          // history.push("/login?redirect=1");
          // const link = `https://${res.data.domain}.oyetest.com/login`;
          const link = `https://${res.data.domain}.joslery.com/login`;
          // const link = `http://${res.data.domain}.localhost:3001/login`;
          this.setState({ generatedLink: link, status: "completed" });

          setTimeout(() => {
            window.location = link;
          }, 3000);
        })
        .catch((err) => {
          // console.log(JSON.stringify(err.response.data.error))
          NotificationManager.error(err.response.data.error);
        });
    }
  };

  resetPassword = () => {
    if (this.state.otp.length < 6) {
      NotificationManager.error("Please enter valid otp");
    } else if (this.state.code != this.state.otp) {
      NotificationManager.error("Please enter valid otp");
    } else {
      // this.setState({ submitted: true });
      this.setState({submitted:true, status: "otpEntered", svgNumber: 1 },()=>{
        console.log("this state",this.state)
      });
    }
  };
  changeStatus = (status) => {
    this.setState({ status: status, svgNumber: 1 });
  };

  render() {
    const { code, email, newPassword, newPassword2, otp, status } = this.state;

    return (
      <div className="custom-container  pl-[78px] w-[100%]">
        <AuthSidebar svgNumber={this.state.svgNumber} />
        <div className="custom-container-2">
          <div className="form-custom-container">
            {status == "" && (
              <>
                <h2
                  className="text-black font-raleway"
                  style={{ fontWeight: 700, fontSize: 30 }}
                >
                  {" "}
                  Forgot Password?
                </h2>
                <p
                  className="heading-description font-roboto subheading"
                  // style={{ fontWeight: 400, fontSize: 16, opacity: 0.6 }}
                >
                  Don't worry! It happens. Please enter the email address linked
                  with your account.
                </p>
                {/* <form> */}
                <div className="form-field">
                  <label
                    className="form-field-label text-primary-description font-roboto !font-[500]"
                    style={{ fontSize: 14 }}
                    htmlFor="emailId"
                  >
                    Email ID
                  </label>
                  <input
                    type="email"
                    id="emailId"
                    name="emailId"
                    value={email}
                    className="px-3"
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </div>

                <div className="login-btn-div">
                  <button
                    className="btn btn-primary !bg-primary-green font-poppins primary_btn"
                    type="button"
                    onClick={this.onNext}
                    style={{
                      opacity: this.state.email ? 1 : 0.6,
                      boxShadow: "0px 1px 2px 0px #1018280D",
                      borderRadius: 8,
                    }}
                  >
                    continue
                  </button>
                </div>

                <p className="heading-description my-2 font-roboto">
                  Remember it?{" "}
                  <Link to="/login" className="hyper-link">
                    <span className="!font-[500] font-poppins">Login</span>
                  </Link>
                </p>
                {/* </form> */}
              </>
            )}
            {status === "emailEntered" && (
              <PendingVerification
                email={email}
                isForgetPass={true}
                defaultResendCall={true}
                changeStatus={this.changeStatus}
                isFinalPage={false}
              />
            )}

            {status === "otpEntered" && (
              <>
                <h2 className="font-raleway !font-[700]">
                  {" "}
                  Create new password
                </h2>
                <p className="heading-description font-roboto !font-[400] !text-[16px]">
                  Your new password must be unique from those previously used.
                </p>

                <div className="form-field no-margin">
                  <div className="flex flex-row justify-between items-center">
                    <label
                      className="form-field-label text-primary-description font-roboto !font-[500]"
                      style={{ fontSize: 14 }}
                      htmlFor="userpassword"
                    >
                      Password
                    </label>
                    <div
                      className="flex cursor-pointer gap-x-1 items-center"
                      onClick={() => {
                        this.setState({
                          isHiddenPass: !this.state.isHiddenPass,
                        });
                      }}
                    >
                      {!this.state.isHiddenPass ? (
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5687 4.06778L15.9554 3.45449C15.7821 3.28117 15.4621 3.30784 15.2621 3.5478L13.1286 5.66777C12.1686 5.25448 11.1153 5.05448 10.0086 5.05448C6.71519 5.06776 3.86204 6.98774 2.48861 9.74791C2.40858 9.92124 2.40858 10.1345 2.48861 10.2812C3.12855 11.5879 4.08861 12.6679 5.28861 13.4812L3.54195 15.2545C3.34195 15.4545 3.31528 15.7745 3.44865 15.9478L4.06193 16.5611C4.23526 16.7344 4.55525 16.7077 4.75525 16.4678L16.4618 4.76121C16.7152 4.56132 16.7419 4.24135 16.5685 4.06801L16.5687 4.06778ZM10.7153 8.09435C10.4886 8.041 10.2486 7.97437 10.022 7.97437C8.88859 7.97437 7.98202 8.88106 7.98202 10.0143C7.98202 10.241 8.03536 10.4809 8.10199 10.7076L7.20856 11.5877C6.94193 11.121 6.79528 10.6009 6.79528 10.0143C6.79528 8.24103 8.22196 6.81435 9.99528 6.81435C10.582 6.81435 11.102 6.961 11.5686 7.22763L10.7153 8.09435Z"
                            fill="#666666"
                            fill-opacity="0.8"
                          />
                          <path
                            d="M17.5287 9.7479C17.0621 8.81453 16.4487 7.97458 15.6888 7.29456L13.2087 9.7479V10.0145C13.2087 11.7878 11.782 13.2145 10.0087 13.2145H9.74208L8.16876 14.7878C8.75548 14.9078 9.36877 14.9878 9.96877 14.9878C13.2622 14.9878 16.1154 13.0679 17.4888 10.2944C17.6088 10.1077 17.6088 9.9211 17.5287 9.74776L17.5287 9.7479Z"
                            fill="#666666"
                            fill-opacity="0.8"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="14"
                          height="9"
                          viewBox="0 0 14 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.868077 5.80351C1.46578 2.92461 3.96439 0.879054 6.775 0.879054C9.58473 0.879054 12.0833 2.92461 12.6819 5.80351C12.7056 5.91775 12.7736 6.01786 12.8709 6.08183C12.9682 6.14579 13.0869 6.16837 13.2008 6.14459C13.3146 6.12081 13.4145 6.05262 13.4782 5.95502C13.542 5.85743 13.5645 5.73842 13.5408 5.62419C12.8598 2.34971 10.0115 0 6.775 0C3.53846 0 0.690168 2.34971 0.00920718 5.62419C-0.0145012 5.73842 0.00800534 5.85743 0.0717757 5.95502C0.135546 6.05262 0.235356 6.12081 0.34925 6.14459C0.463143 6.16837 0.58179 6.14579 0.679089 6.08183C0.776388 6.01786 0.844369 5.91775 0.868077 5.80351ZM6.76624 2.63716C7.57976 2.63716 8.35996 2.96131 8.93521 3.5383C9.51046 4.11529 9.83363 4.89786 9.83363 5.71385C9.83363 6.52984 9.51046 7.31241 8.93521 7.8894C8.35996 8.46639 7.57976 8.79054 6.76624 8.79054C5.95271 8.79054 5.17251 8.46639 4.59726 7.8894C4.02201 7.31241 3.69884 6.52984 3.69884 5.71385C3.69884 4.89786 4.02201 4.11529 4.59726 3.5383C5.17251 2.96131 5.95271 2.63716 6.76624 2.63716Z"
                            fill="#6A707C"
                          />
                        </svg>
                      )}
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: 400,
                          lineHeight: "19.5px",
                          textAlign: "right",
                          opacity: 0.7,
                        }}
                      >
                        {this.state.isHiddenPass ? "Show" : "Hide"}
                      </p>
                    </div>
                  </div>
                  <input
                    type={this.state.isHiddenPass ? "password" : "text"}
                    id="new-password"
                    // className="py-3"
                    name="new-password"
                    value={newPassword}
                    onChange={(e) => {
                      let res = validatePassword(e.target.value);
                      this.setState({
                        newPassword: e.target.value,
                        error: res.status
                          ? e.target.value !== this.state.newPassword2
                            ? "Your passwords don’t match. Please try again."
                            : ""
                          : res.msg,
                      });
                    }}
                  />
                </div>

                <div className="form-field no-margin">
                  <div className="flex flex-row justify-between items-center">
                    <label
                      className="form-field-label text-primary-description font-roboto !font-[500]"
                      style={{ fontSize: 14 }}
                      htmlFor="userpassword"
                    >
                      Confirm Password
                    </label>
                    <div
                      className="flex cursor-pointer gap-x-1 items-center"
                      onClick={() => {
                        this.setState({
                          isHiddenPass2: !this.state.isHiddenPass2,
                        });
                      }}
                    >
                      {!this.state.isHiddenPass2 ? (
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5687 4.06778L15.9554 3.45449C15.7821 3.28117 15.4621 3.30784 15.2621 3.5478L13.1286 5.66777C12.1686 5.25448 11.1153 5.05448 10.0086 5.05448C6.71519 5.06776 3.86204 6.98774 2.48861 9.74791C2.40858 9.92124 2.40858 10.1345 2.48861 10.2812C3.12855 11.5879 4.08861 12.6679 5.28861 13.4812L3.54195 15.2545C3.34195 15.4545 3.31528 15.7745 3.44865 15.9478L4.06193 16.5611C4.23526 16.7344 4.55525 16.7077 4.75525 16.4678L16.4618 4.76121C16.7152 4.56132 16.7419 4.24135 16.5685 4.06801L16.5687 4.06778ZM10.7153 8.09435C10.4886 8.041 10.2486 7.97437 10.022 7.97437C8.88859 7.97437 7.98202 8.88106 7.98202 10.0143C7.98202 10.241 8.03536 10.4809 8.10199 10.7076L7.20856 11.5877C6.94193 11.121 6.79528 10.6009 6.79528 10.0143C6.79528 8.24103 8.22196 6.81435 9.99528 6.81435C10.582 6.81435 11.102 6.961 11.5686 7.22763L10.7153 8.09435Z"
                            fill="#666666"
                            fill-opacity="0.8"
                          />
                          <path
                            d="M17.5287 9.7479C17.0621 8.81453 16.4487 7.97458 15.6888 7.29456L13.2087 9.7479V10.0145C13.2087 11.7878 11.782 13.2145 10.0087 13.2145H9.74208L8.16876 14.7878C8.75548 14.9078 9.36877 14.9878 9.96877 14.9878C13.2622 14.9878 16.1154 13.0679 17.4888 10.2944C17.6088 10.1077 17.6088 9.9211 17.5287 9.74776L17.5287 9.7479Z"
                            fill="#666666"
                            fill-opacity="0.8"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="14"
                          height="9"
                          viewBox="0 0 14 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.868077 5.80351C1.46578 2.92461 3.96439 0.879054 6.775 0.879054C9.58473 0.879054 12.0833 2.92461 12.6819 5.80351C12.7056 5.91775 12.7736 6.01786 12.8709 6.08183C12.9682 6.14579 13.0869 6.16837 13.2008 6.14459C13.3146 6.12081 13.4145 6.05262 13.4782 5.95502C13.542 5.85743 13.5645 5.73842 13.5408 5.62419C12.8598 2.34971 10.0115 0 6.775 0C3.53846 0 0.690168 2.34971 0.00920718 5.62419C-0.0145012 5.73842 0.00800534 5.85743 0.0717757 5.95502C0.135546 6.05262 0.235356 6.12081 0.34925 6.14459C0.463143 6.16837 0.58179 6.14579 0.679089 6.08183C0.776388 6.01786 0.844369 5.91775 0.868077 5.80351ZM6.76624 2.63716C7.57976 2.63716 8.35996 2.96131 8.93521 3.5383C9.51046 4.11529 9.83363 4.89786 9.83363 5.71385C9.83363 6.52984 9.51046 7.31241 8.93521 7.8894C8.35996 8.46639 7.57976 8.79054 6.76624 8.79054C5.95271 8.79054 5.17251 8.46639 4.59726 7.8894C4.02201 7.31241 3.69884 6.52984 3.69884 5.71385C3.69884 4.89786 4.02201 4.11529 4.59726 3.5383C5.17251 2.96131 5.95271 2.63716 6.76624 2.63716Z"
                            fill="#6A707C"
                          />
                        </svg>
                      )}
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: 400,
                          lineHeight: "19.5px",
                          textAlign: "right",
                          opacity: 0.7,
                        }}
                      >
                        {this.state.isHiddenPass2 ? "Show" : "Hide"}
                      </p>
                    </div>
                  </div>
                  <input
                    type={this.state.isHiddenPass2 ? "password" : "text"}
                    id="confirm-password"
                    name="confirm-password"
                    value={newPassword2}
                    onChange={(e) => {
                      this.setState({
                        newPassword2: e.target.value,
                        error:
                          this.state.newPassword !== e.target.value
                            ? "Your passwords don’t match. Please try again."
                            : "",
                      });
                    }}
                  />
                  <div className="mt-2">
                    {(!this.state.error || !this.state.newPassword) && (
                      <div style={{ display: "flex" }}>
                        <svg
                          width="28"
                          height="28"
                          viewBox="0 0 13 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <ellipse
                            cx="6.07325"
                            cy="6"
                            rx="6.07325"
                            ry="6"
                            fill="#316BFF"
                          />
                          <path
                            d="M6.88307 3.538C6.88307 3.748 6.80807 3.928 6.65807 4.078C6.50807 4.228 6.32807 4.303 6.11807 4.303C5.90807 4.303 5.72807 4.228 5.57807 4.078C5.42807 3.928 5.35307 3.748 5.35307 3.538C5.35307 3.328 5.42807 3.148 5.57807 2.998C5.72807 2.848 5.90807 2.773 6.11807 2.773C6.32807 2.773 6.50807 2.848 6.65807 2.998C6.80807 3.148 6.88307 3.328 6.88307 3.538ZM5.47907 10.126V4.852L6.76607 5.005L6.58607 10L5.47907 10.126Z"
                            fill="white"
                          />
                        </svg>
                        <p
                          className="text-sm text-[#316BFF] ml-1"
                          style={{ fontSize: 12, fontWeight: 400 }}
                        >
                          Your password must be at least 8 characters long and
                          include one uppercase letter, number, and a special
                          character.
                        </p>
                      </div>
                    )}
                    {this.state.error && this.state.newPassword && (
                      <p className="text-sm text-primary-error">
                        {this.state.error}
                      </p>
                    )}
                  </div>
                </div>
                <div className="login-btn-div">
                  <button
                    className="btn btn-primary primary_btn"
                    type="button"
                    onClick={this.changePassword}
                    disabled={
                      this.state.error?.length > 0 || !this.state.newPassword
                    }
                  >
                    Reset Password
                  </button>
                </div>
                {/* </form> */}
              </>
            )}
            {status === "completed" && (
              <div>
                <SuccessComponent
                  label="Password changed successfully"
                  description="Your password has been successfully reset. log in with your new password"
                  successText="Login"
                  successAction={() => {
                    console.log("clicked link");
                    window.location = this.state.generatedLink;
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <NotificationContainer />
      </div>

      // </AUX>
    );
  }
}
