import React from "react";
import Skeleton from "react-loading-skeleton";
const TableEntry = () => {
  return (
    <div className="table-entry-skeleton">
      <Skeleton height={30} count={10} width={"100%"} />
    </div>
  );
};

export default TableEntry;
