import React from "react";
import TableEntry from "./TableEntry";
import Skeleton from "react-loading-skeleton";

const ReposRowSkeleton = () => {
  return (
    <div className="w-full">
      <div className="flex flex-row items-center gap-x-[2px]">
        <div>
          <Skeleton circle height={52} width={53} />
        </div>
        <div className="flex flex-col items-center justify-start w-full pl-12">
          <Skeleton height={26} width={310} />
          <Skeleton height={18} width={310} />
        </div>
      </div>
      <div className="w-[400px]">
        <TableEntry count={4} />
      </div>

      <div className="flex flex-row items-center gap-x-4 mt-8">
        <Skeleton height={44} width={200} />
        <Skeleton height={44} width={100} />
      </div>
    </div>
  );
};

export default ReposRowSkeleton;