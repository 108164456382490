/* eslint-disable no-unused-expressions */
/* eslint-disable default-case */
/* eslint-disable react/jsx-no-undef */
import React, { Component } from "react";
import "../styles/Dashboard.css";
import { history } from "../_helpers/history";
import { BASE_URL } from "../_helpers/constants";
import Promises from "../_helpers/utils";
import { Link } from "react-router-dom";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { ColorRing } from "react-loader-spinner";
import Modal from "react-modal";
import ReCAPTCHA from "react-google-recaptcha";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Select from "react-select";
import "react-notifications/lib/notifications.css";
import { Country, State } from "country-state-city";
import Navbar from "../_components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
export default class Pages_price extends Component {
  constructor(props) {
    super(props);
    this.toggleprofile = this.toggleprofile.bind(this);
  }

  state = {
    showDrop: false,
    isPlan: false,
    viewState: 0,
    selectedOption1: null,
    plan_data: [],
    switch1: true,
    movedComponent: false,
    showPricingPlanNotification: true,
    createModalVisible: false,
    createPricePlanModalVisible: false,
    createBillingDetailsModalVisible: false,
    orgPaymentData: [],
    selectedCountry: { value: "", label: "" },
    selectedState: { value: "", label: "" },
    countries: [],
    states: [],
    organizationName: "",
    phoneNumber: "",
    city: "",
    addressLine: "",
    pin: "",
    taxIdNumber: "",
    loadingInvoices: {},
    color: "",
    backgroundColor: "",
  };

  componentDidMount() {
    this.setState({ loading: true }, () => {
      this.getOrgIntigration();
      this.getOrgPaymentData();
      this.setCountries();
    });
    
    const siteUrl = window.location.search;
    const urlParams = new URLSearchParams(siteUrl);
    if (urlParams.has("session_id")) {
      console.log("session_id", urlParams.get("session_id")); // 159
      this.saveSession(urlParams.get("session_id"));
    }
  }

  handler(type = 1) {
    if (type === 1) {
      this.setState({ selectedTab: "test-result" });
    } else {
      this.setState({ selectedTab: "test-case" });
    }
  }
  handlerAdmin() {
    this.setState({ selectedTab: "administrator" });
    this.setState({ viewState: 2 });
  }
  redirectToSettingsHelp() {
    // Navigate to the /settings route with a query parameter
    this.props.history.push("/settings/support");
  }
  onLogout() {
    localStorage.clear();
  }

  toggleprofile() {
    this.setState((prevState) => ({
      dropdownOpenprofile: !prevState.dropdownOpenprofile,
    }));
  }
  
  getOrgIntigration = (type = "1m") => {
    const token = Promises.getToken();
    if (!token) {
      history.push("/login?redirect=1");
    }

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BASE_URL}plan/${type}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        if (result.status) {
          console.log("===================>", result);
          this.setState({
            isPlan: true,
            plan_data: result.data,
          });
        } else {
          this.setState({
            isPlan: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  //Get Payment DATA
  // Helper function to find ISO code by country name
  getCountryISOCode = (countryName) => {
    const country = Country.getAllCountries().find(
      (country) => country.name === countryName
    );
    return country ? country.isoCode : null;
  };

  // Helper function to find ISO code by state name and country ISO code
  getStateISOCode = (stateName, countryISOCode) => {
    const state = State.getStatesOfCountry(countryISOCode).find(
      (state) => state.name === stateName
    );
    return state ? state.isoCode : null;
  };
  getOrgPaymentData = () => {
    console.log("Payment Data");
    const token = Promises.getToken();
    const orgID = Promises.getOrg();

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BASE_URL}plan/webhookLemonSqueezy/${orgID}`, requestOptions)
      .then((response) => response.json())
      .then(async (result) => {
        console.log("Result ===>", result.data);
        const data = result.data;
        const countryISOCode = this.getCountryISOCode(data.country);
        const stateISOCode = this.getStateISOCode(data.state, countryISOCode);

        this.setState({
          orgPaymentData: data,

          city: data.city,
          addressLine: data.address,
          pin: data.pin,
          phoneNumber: data.phone_number,
          taxIdNumber: data.tax_id_number,
          organizationName: data.organization_name,
        });
        if (data.country?.trim()) {
          this.setState({
            selectedCountry: { value: countryISOCode, label: data.country },
          });
        }

        if (data.state?.trim()) {
          this.setState({
            selectedState: { value: stateISOCode, label: data.state },
          });
        }
        await this.getAccountStatusStyles(data.account_status);
        console.log(this.state.color, this.state.backgroundColor);
        // Set states based on the country ISO code
        if (countryISOCode) {
          this.setStates(countryISOCode);
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  ///session/save

  saveSession = (sessionId = "") => {
    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var raw = JSON.stringify({
      session_id: sessionId,
      // description: this.state.description,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: raw,
    };

    fetch(`${BASE_URL}plan/session/save`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          NotificationManager.success("Project Update successfully");
          setTimeout(() => {
            this.props.history.push("/dashboard");
          }, 1000);
        } else {
          NotificationManager.error(
            "Something went wrong with Updating project, Please try again later"
          );
        }
      })
      .catch((error) => {
        // debugger;
        console.log("error", error);
        NotificationManager.error(
          "Something went wrong with Updating project, Please try again later"
        );
      });
  };
  getSessionURL = (id) => {
    const token = Promises.getToken();

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BASE_URL}plan/session/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          console.log("===================>", result);
          if (result.data) {
            window.location.href = result.data;
          }
        } else {
          this.setState({
            isPlan: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  saveBillingInformation = () => {
    this.setState({ createBillingDetailsModalVisible: false });
    console.log("SAVING DATA =============>");
    const {
      phoneNumber,
      city,
      organizationName,
      addressLine,
      pin,
      taxIdNumber,
      selectedCountry,
      selectedState,
    } = this.state;

    if (!isValidPhoneNumber(phoneNumber)) {
      NotificationManager.error("Invalid phone number");
      return;
    }

    const orgid = Promises.getOrg();
    const token = Promises.getToken();
    var myHeaders = new Headers();

    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var raw = {
      phone_number: phoneNumber,
      city: city,
      organization_name: organizationName,
      address_line: addressLine,
      pin: pin,
      tax_id_number: taxIdNumber,
      country: selectedCountry.label,
      state: selectedState.label,
    };
    console.log(raw);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    fetch(`${BASE_URL}auth/update-details/${orgid}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        NotificationManager.info(result.msg);
      })
      .catch((error) => {
        console.log("error send-email", error);
      });
  };

  selectedProject = (val, isImport) => {
    console.log(val.name);
    this.setState({
      selectedTab: "test-case",
      titleName: val.name,
      selectedProjectId: val.id,
    });
    this.setState({ importState: isImport });
    this.setState({ viewState: 1 });
    this.props.history.push(`dashboard/${val.name}`);

    if (this.child.current) {
      this.child.current.changeIndex(val.id);
    }
  };

  handleChange = (type) => {
    console.log("============>", type);
    if (type) {
      this.getOrgIntigration("1m");
    } else {
      this.getOrgIntigration("1y");
    }
  };
  downloadInvoice = async (invoice_id) => {
    console.log(this.state.orgPaymentData.AllBillingData);
    console.log("invoice id:", invoice_id);

    if (!invoice_id?.trim()) {
      console.log("not creating");
      NotificationManager.warning(
        "The payment is under-process or generating the invoice, so try again later."
      );
      return;
    }

    // Set loading state for this invoice
    this.setState((prevState) => ({
      loadingInvoices: {
        ...prevState.loadingInvoices,
        [invoice_id]: true,
      },
    }));

    const token = Promises.getToken();

    const myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${BASE_URL}plan/generateinvoice/${invoice_id}`,
        requestOptions
      );
      console.log(response.status);

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `invoice_${invoice_id}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else if (response.status === 404) {
        NotificationManager.error(
          "Add a billing address first to download your invoice"
        );
      } else if(response.status === 500) {
        NotificationManager.error(
          "Contact Oyetest as there is some problem in generating your invoice"
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      // Unset loading state for this invoice
      this.setState((prevState) => ({
        loadingInvoices: {
          ...prevState.loadingInvoices,
          [invoice_id]: false,
        },
      }));
    }
  };
  onTestSuitCreate = () => {
    const { name, testCasesData, createModalVisible } = this.state;
    console.log(createModalVisible);
    return (
      <Modal
        overlayClassName="OverlayPricingModal"
        className="create-modal pricing-modal test-cases-modal"
        onRequestClose={() => this.setState({ createModalVisible: false })}
        isOpen={createModalVisible}
      >
        <div className="modal-header clearfix">
          <h1>Ask for a better price!</h1>
          <i
            className="mdi mdi-close"
            onClick={() => this.setState({ createModalVisible: false })}
          ></i>
        </div>

        <label id="label" className="mt-30" htmlFor="name">
          Your Full Name
        </label>
        <input
          type="text"
          className="form-control"
          name="title"
          value={this.state.name}
          onChange={(e) => this.handleChangeEmail(e, "name")}
        />
        <span id="err" style={{ color: "red" }}>
          {this.state.name_err}
        </span>
        <label id="label" className="mt-30" htmlFor="name">
          Email
        </label>
        <input
          type="text"
          className="form-control"
          name="email"
          value={this.state.email}
          onChange={(e) => this.handleChangeEmail(e, "email")}
          id="email"
        />

        <span id="err" style={{ color: "red" }}>
          {this.state.email_err}
        </span>
        <label id="label" className="mt-30" htmlFor="name">
          Message
        </label>
        <textarea
          row="5"
          id="contact-msg"
          value={this.state.msg}
          onChange={(e) => this.handleChangeEmail(e, "msg")}
        ></textarea>
        <span id="err" style={{ color: "red" }}>
          {this.state.msg_err}
        </span>
        <div className="form-field ">
          <label htmlFor="phone"> Phone </label>
          <div className="phone-field">
            {/* <select name="phone" id="phone">
                  <option value="+91">+91</option>
                  <option value="+10">+10</option>
                </select> */}
            <input type="text" id="phone" name="phone" />
          </div>
        </div>
        <div className="form-field ">
          <ReCAPTCHA
            style={{ marginTop: "20px" }}
            sitekey="6LcjEAcqAAAAAO59PHC3lVb4mWHPjJykzIh7aQq3"
            onChange={this.onChangeCaptcha}
          />
        </div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={this.sendmail}
          disabled={!this.state.captcha}
        >
          Request price
        </button>
      </Modal>
    );
  };

  render() {
    const { viewState } = this.state;

    switch (viewState) {
      case 0:
        return (
          <div id="main-body">
            {this.renderHeader4()}
            {this.renderPrice()}
            {this.onShowPricePlans()}
            {this.onShowBillingDetails()}
          </div>
        );
    }
  }

  onShowPricePlans = () => {
    const { createPricePlanModalVisible } = this.state;

    return (
      <Modal
        ariaHideApp={false}
        className="create-modal create-modal-pricing create-project-modal !mt-[5vh] h-[90vh] overflow-y-auto"
        onRequestClose={() =>
          this.setState({ createPricePlanModalVisible: false })
        }
        isOpen={createPricePlanModalVisible}
        style={{ background: "rgb(249, 249, 249)" }}
      >
        <div className="modal-header clearfix">
          {/* <h4>Create Project</h4> */}
          <i
            className="mdi mdi-close"
            onClick={() =>
              this.setState({ createPricePlanModalVisible: false })
            }
          ></i>
        </div>
        <div>
          <div
            className={`d-flex  flex-column align-items-center justify-content-center  `}
          >
            <div
              className="card-body noauth-pricing-page"
              style={{
                position: "relative",
                width: 926,
              }}
            >
              <div className="pricing-heading">
                <h2 className="pricing-main-heading">Upgrade plan</h2>
              </div>

              <div className="toggle-new">
                Bill Annualy
                <input
                  type="checkbox"
                  id="switch1"
                  checked={this.state.switch1 ? "checked" : ""}
                  switch="none"
                  onChange={() => {
                    this.setState({ switch1: !this.state.switch1 });
                    this.handleChange(!this.state.switch1);
                  }}
                />
                <label for="switch1" data-on-label="" data-off-label=""></label>
                Bill Monthly
              </div>
            </div>
          </div>
          <section className="pricing-cards-section">
            <div className="pricing-card-main-div container">
              <div className="row" style={{ justifyContent: "center" }}>
                {this.state.plan_data.map((ele, index) => {
                  const temp = JSON.parse(ele.ProductFeatures);
                  // const temp = ["asd", "adasd"];
                  console.log(
                    `${ele.productPlanURL}?checkout[custom][user_id]=${
                      Promises.getUserId() || ""
                    }`
                  );
                  return (
                    <div
                      className="col-lg-4 col-sm-6"
                      style={{ maxWidth: 280, marginRight: 32 }}
                    >
                      <div className="pricing-card">
                        <div className="pricing-main-text">
                          <h3>{ele.productPlanName}</h3>
                          {ele.productPlanName.toLowerCase() !== "basic" && (
                            <div className="pricing-popular-chip">Popular</div>
                          )}
                          {/* <p>15 days trial period</p> */}
                        </div>
                        <div className="pricing-price !mt-[0.3rem]">
                          <h2>
                            <span style={{ color: "#181059" }}>$</span>
                            {ele.productPlanPrice}
                            {this.state.switch1 ? (
                              <span> /month</span>
                            ) : (
                              <span> /annualy</span>
                            )}
                          </h2>
                        </div>
                        <div className="pricing-nav-btn w-100 !mt-[0.7rem]">
                          <a
                            href={`${
                              ele.productPlanURL
                            }?checkout[custom][user_id]=${Promises.getUserId()}&checkout[custom][org_id]=${Promises.getOrg()}`}
                            className={
                              ele.productPlanName.toLowerCase() !== "basic"
                                ? "btn btn-primary w-100 pricing-btn-second"
                                : "btn btn-primary w-100 pricing-btn-first"
                            }
                            // onClick={() => {
                            //   this.getSessionURL(ele.id);
                            // }}
                            rel="noreferrer"
                            target="_blank"
                          >
                            Upgrade Now
                          </a>
                        </div>
                        <div className="pricing-features">
                          <ul>
                            {temp.map((eleChild) => {
                              return (
                                <li className="!flex">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/plain-checkmark.png"
                                    }
                                    alt="cirlce-img"
                                  />{" "}
                                  {eleChild}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        {/* <div className="pricing-nav-btn w-100">
                          <button
                            className="btn btn-primary w-100 pricing-btn-first"
                            onClick={() => {
                              this.getSessionURL(ele.id);
                            }}
                          >
                            Choose Plan
                          </button>
                        </div> */}
                      </div>
                    </div>
                  );
                })}
                {/*   <div className="col-lg-4 col-sm-6">
                  <div className="pricing-card">
                    <div className="pricing-main-text">
                      <h3>Advance</h3>
                      <p>Advanced tools to take your work to the next level.</p>
                    </div>
                    <div className="pricing-price">
                      <h2>
                        $54<span>/Month</span>
                      </h2>
                    </div>
                    <div className="pricing-features">
                      <ul>
                        <li>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/check-circle-1.png"
                            }
                            alt="cirlce-img"
                          />{" "}
                          1 user
                        </li>
                        <li>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/check-circle-1.png"
                            }
                            alt="cirlce-img"
                          />{" "}
                          1 user
                        </li>
                        <li>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/check-circle-1.png"
                            }
                            alt="cirlce-img"
                          />{" "}
                          1 user
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-nav-btn w-100">
                      <button className="btn btn-primary w-100 pricing-btn-first">
                        Choose Plan
                      </button>
                    </div>
                  </div>
                </div>
                */}
                <div className="col-lg-4 col-sm-6" style={{ maxWidth: 280 }}>
                  <div className="pricing-card">
                    <div className="pricing-main-text">
                      <h3>Enterprise</h3>
                    </div>
                    <div className="pricing-price !mt-[0.3rem]">
                      <h2 style={{ fontSize: "17px", fontWeight: "400" }}>
                        Custom
                      </h2>
                    </div>
                    <div className="pricing-nav-btn w-100 !mt-[1rem]">
                      <button
                        className="btn btn-primary w-100 pricing-btn-first"
                        onClick={() =>{
                          this.redirectToSettingsHelp();
                          this.setState({ createModalVisible: true })}
                        }
                      >
                        Contact us
                      </button>
                    </div>
                    <div className="pricing-features">
                      <ul>
                        <li className="d-flex">
                          <span>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/plain-checkmark.png"
                              }
                              alt="cirlce-img"
                            />
                          </span>{" "}
                          Customization as per your requirements
                        </li>
                      </ul>
                    </div>
                    {/* <div className="pricing-nav-btn w-100">
                      <button
                        className="btn btn-primary w-100 pricing-btn-second"
                        onClick={() =>
                          this.setState({ createModalVisible: true })
                        }
                      >
                        Choose Plan
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Modal>
    );
  };
  setCountries = () => {
    const countries = Country.getAllCountries().map((country) => ({
      value: country.isoCode,
      label: country.name,
    }));
    this.setState({ countries });
  };

  setStates = (countryCode) => {
    const states = State.getStatesOfCountry(countryCode).map((state) => ({
      value: state.isoCode,
      label: state.name,
    }));
    this.setState({ states });
  };

  handleCountryChange = (selectedCountry) => {
    this.setState({ selectedCountry, selectedState: null });
    this.setStates(selectedCountry.value);
  };

  handleStateChange = (selectedState) => {
    this.setState({ selectedState });
  };

  handlePhoneNumberChange = (phoneNumber) => {
    this.setState({ phoneNumber });
  };
  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  onShowBillingDetails = () => {
    const {
      createBillingDetailsModalVisible,
      phoneNumber,
      countries,
      states,
      city,
      organizationName,
      addressLine,
      pin,
      taxIdNumber,
      selectedCountry,
      selectedState,
    } = this.state;

    return (
      <>
        <NotificationContainer />
        <style>{`
    
                
    .custom-modal2 {
      background: white;
      border-radius: 8px;
      padding: 20px;
      max-width: 600px;
      width: 100%;
      margin: auto;
      position: relative;
    }
    
    .custom-overlay2 {
      background-color: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(5px);
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .modal-header {
      display: flex;
      justify-content: flex-end;
    }
    
    .modal-body {
      
      padding: 20px;
    }
    
    .form-group {
      margin-bottom: 15px;
    }
    .form-control2 {
      width: 100%;
      padding: 10px;
      font-size: 14px;
      border: 1px solid #ced4da;
      border-radius: 4px;
      
    }
    
    
    
    .react-select__control {
      width: 100%;
      height: 38px;
      font-size: 14px;
      border: 1px solid #ced4da;
      border-radius: 4px;
    }
    .btn {
      margin-right: 10px;
    }
    .two-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
    
    .btn-primary {
      background-color: #28a745;
      border-color: #28a745;
    }
    
    .btn-secondary {
      background-color: #6c757d;
      border-color: #6c757d;
    }
    
              `}</style>
        <Modal
          ariaHideApp={false}
          className="custom-modal2"
          overlayClassName="custom-overlay2"
          onRequestClose={() =>
            this.setState({ createBillingDetailsModalVisible: false })
          }
          isOpen={createBillingDetailsModalVisible}
        >
          <div className="modal-header">
            <i
              className="mdi mdi-close"
              onClick={() =>
                this.setState({ createBillingDetailsModalVisible: false })
              }
            ></i>
          </div>
          <div className="modal-body">
            <h2 style={{ marginBottom: "20px" }}>
              <b>Update Address</b>
            </h2>
            <form onSubmit={this.saveBillingInformation}>
              <div className="form-group">
                <label>Organization Name</label>
                <input
                  type="text"
                  name="organizationName"
                  value={organizationName}
                  onChange={this.handleInputChange}
                  className="form-control2"
                  placeholder="Enter organization here"
                />
              </div>
              <div className="form-group">
                <label>Address Line</label>
                <input
                  type="text"
                  name="addressLine"
                  value={addressLine}
                  onChange={this.handleInputChange}
                  className="form-control2"
                  placeholder="Enter address here"
                  maxLength={80}
                />
              </div>
              <div className="two-grid">
                <div className="form-group">
                  <label>City</label>
                  <input
                    type="text"
                    name="city"
                    value={city}
                    onChange={this.handleInputChange}
                    className="form-control2"
                    placeholder="Add your city"
                    maxLength={30}
                  />
                </div>
                
                <div className="form-group">
                  <label>Country</label>
                  <Select
                    value={selectedCountry}
                    onChange={this.handleCountryChange}
                    options={countries}
                    placeholder="Select"
                    className="react-select-container-"
                    classNamePrefix="react-select"
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: "1px solid var(--pgreen, #00CD70)",
                        boxShadow: "none",
                        display: "flex",
                        alignItems: "center",
                        height: 50, // Set the desired height
                        minHeight: 50, // Ensure it doesn't go below this height
                        padding: "0 8px", // Adjust padding as needed
                      }),
                      // valueContainer: (provided) => ({
                      //   ...provided,
                      //   height: "100%",
                      //   // padding: "0 8px", // Adjust padding as needed
                      //   display: "flex",
                      //   alignItems: "center",
                      // }),
                      input: (provided) => ({
                        ...provided,
                        margin: 0,
                        padding: 0,
                        height: 50, // Ensures input height matches the container
                        display: "flex",
                        alignItems: "center",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: 14, // Adjust font size as needed
                        display: "flex",
                        margin: "10px 0 0 0",
                        alignItems: "center",
                        height: "100%", // Ensures placeholder height matches the container
                      }),
                      option: (base, { isFocused }) => ({
                        ...base,
                        backgroundColor: isFocused ? "#DEFFE9" : "white",
                        display: "flex",
                        alignItems: "center",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        display: "flex",
                        alignItems: "center",
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        display: "flex",
                        alignItems: "center",
                      }),
                      clearIndicator: (provided) => ({
                        ...provided,
                        display: "flex",
                        alignItems: "center",
                      }),
                    }}
                  />
                </div>
                <div className="form-group">
                  <label>PIN/Postal Code</label>
                  <input
                    type="text"
                    name="pin"
                    value={pin}
                    onChange={this.handleInputChange}
                    className="form-control2"
                    placeholder="Enter your pincode"
                    maxLength={12}
                  />
                </div>

                <div className="form-group">
                  <label>State</label>
                  <Select
                    value={selectedState}
                    onChange={this.handleStateChange}
                    options={states}
                    placeholder="Enter your state"
                    className="react-select-container-"
                    classNamePrefix="react-select"
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: "1px solid var(--pgreen, #00CD70)",
                        boxShadow: "none",
                        display: "flex",
                        alignItems: "center",
                        height: 50, // Set the desired height
                        minHeight: 50, // Ensure it doesn't go below this height
                        padding: "0 8px", // Adjust padding as needed
                      }),
                      // valueContainer: (provided) => ({
                      //   ...provided,
                      //   height: "100%",
                      //   // padding: "0 8px", // Adjust padding as needed
                      //   display: "flex",
                      //   alignItems: "center",
                      // }),
                      input: (provided) => ({
                        ...provided,
                        margin: 0,
                        padding: 0,
                        height: 50, // Ensures input height matches the container
                        display: "flex",
                        alignItems: "center",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: 14, // Adjust font size as needed
                        display: "flex",
                        margin: "10px 0 0 0",
                        alignItems: "center",
                        height: "100%", // Ensures placeholder height matches the container
                      }),
                      option: (base, { isFocused }) => ({
                        ...base,
                        backgroundColor: isFocused ? "#DEFFE9" : "white",
                        display: "flex",
                        alignItems: "center",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        display: "flex",
                        alignItems: "center",
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        display: "flex",
                        alignItems: "center",
                      }),
                      clearIndicator: (provided) => ({
                        ...provided,
                        display: "flex",
                        alignItems: "center",
                      }),
                    }}
                  />
                </div>

                <div className="form-group">
                  <label>Phone Number</label>
                  <PhoneInput
                    international
                    defaultCountry="US"
                    value={phoneNumber}
                    onChange={this.handlePhoneNumberChange}
                    placeholder="Enter phone number"
                    isValidPhoneNumber={true}
                  />
                </div>
                <div className="form-group">
                  <label>Tax ID Number</label>
                  <input
                    type="text"
                    name="taxIdNumber"
                    value={taxIdNumber}
                    onChange={this.handleInputChange}
                    className="form-control2"
                    placeholder="Enter your Tax ID number"
                  />
                </div>
              </div>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() =>
                  this.setState({ createBillingDetailsModalVisible: false })
                }
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                Save Changes
              </button>
            </form>
          </div>
        </Modal>
      </>
    );
  };
  getAccountStatusStyles = (accountStatus) => {
    console.log(1);
    switch (accountStatus) {
      case "on_trial":
        this.setState({
          color: "#F6A342",
          backgroundColor: "rgba(250, 172, 80, 0.1)",
        });
        break; // Add break to prevent fall-through
      case "active":
        this.setState({
          color: "#27AE60",
          backgroundColor: "rgba(39, 174, 96, 0.1)",
        });
        break; // Add break to prevent fall-through
      case "expired":
        this.setState({
          color: "#FF2727",
          backgroundColor: "rgba(255, 39, 39, 0.1)",
        });
        break; // Add break to prevent fall-through
      default:
        this.setState({
          color: "#27AE60",
          backgroundColor: "rgba(39, 174, 96, 0.1)",
        });
        break; // Add break to prevent fall-through
    }
  };

  renderPrice() {
    const { plan_data, orgPaymentData } = this.state;
    console.log("Org ==>", orgPaymentData);
    return (
      <>
        <div
          style={{
            padding: "35px 140px",
            height: "100vh",
            overflow: "auto",

            background: "#f9f9f9",
          }}
        >
          {orgPaymentData.account_status === "on_trial" &&
          !orgPaymentData.payment_status === "pending" &&
             (
              <div style={{ marginBottom: 42 }}>
                {/* <h3 className="new-pricing-title">Current plan</h3> */}

                <div className="card new-card-pad">
                  <div className="new-pricing-div">
                    <div
                      className="new-pricing-img"
                      style={{ alignItems: "center" }}
                    >
                      <img src="/assets/images/watch.png" alt="" />
                      <div>
                        <p>
                          <span style={{ fontSize: 42 }}>
                            {orgPaymentData.remaining_days}
                          </span>
                          /days left
                        </p>
                        <p>
                          To keep using this Workspace after the trial ends, set
                          up a subscription.
                        </p>
                      </div>
                    </div>
                    <div
                      className="new-pricing-img"
                      style={{ alignItems: "center", width: 500 }}
                    >
                      <img
                        src="/assets/images/info-icon.png"
                        alt=""
                        style={{ marginRight: "12px" }}
                      />
                      <p>
                        Need seamless and secure sign-in, effortless onboarding,
                        or more flexible payment options? Upgrade to our
                        Business Plan. <a href="#!">Learn More</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

          {orgPaymentData.account_status === "active" &&
            orgPaymentData.payment_status === "failed" && (
              <div style={{ marginBottom: 42 }}>
                <div className="card new-card-pad">
                  <div className="new-pricing-div">
                    <div className="new-pricing-img" style={{ width: 640 }}>
                      <img src="/assets/images/pay-fail-icon.png" alt="" />
                      <div>
                        <h6 style={{ color: "#FF2B2B" }}>
                          Your payment failed
                        </h6>
                        <p>
                          You’ll lose your subscription if we don’t have a
                          working payment method for your account, please update
                          your payment details. We will retry your payment in a
                          few days.
                        </p>
                      </div>
                    </div>
                    <button type="button" className="btn btn-primary">
                      Update Payment
                    </button>
                  </div>
                </div>
              </div>
            )}

          {(orgPaymentData.account_status === null ||
            orgPaymentData.payment_status === "pending") && (
            <div style={{ marginBottom: 42 }}>
              <div className="card new-card-pad">
                <div className="new-pricing-div">
                  <div className="new-pricing-img" style={{ width: 640 }}>
                    <img src="/assets/images/pay-process-icon.png" alt="" />
                    <div>
                      <h6 style={{ color: "#FF971D" }}>
                        Payment under process
                      </h6>
                      <p>
                        We’ve received your payment and it’s under process. We
                        will notify you once it’s done. Thank you!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {orgPaymentData.account_status === "active" &&
            orgPaymentData.payment_status === "paid" && (
              <div style={{ marginBottom: 42 }}>
                <div className="card new-card-pad">
                  <div className="new-pricing-div">
                    <div className="new-pricing-img">
                      <img src="/assets/images/rocket.png" alt="" />
                      <div>
                        <h6 style={{ marginBottom: 7 }}>Upgrade your plan.</h6>

                        <p>
                          Unlock more features and benefits tailored just for
                          you.
                        </p>
                        <p>
                          Enjoy enhanced services and greater flexibility with
                          our upgraded plans.
                        </p>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() =>
                        this.setState({ createPricePlanModalVisible: true })
                      }
                    >
                      Upgrade now
                    </button>
                  </div>
                </div>
              </div>
            )}

          {orgPaymentData.account_status === "on_trial" &&
            orgPaymentData.payment_status === "paid" && (
              <div style={{ marginBottom: 42 }}>
                <div className="card new-card-pad">
                  <div className="new-pricing-div">
                    <div className="new-pricing-img">
                      <img src="/assets/images/rocket.png" alt="" />
                      <div>
                        <h6>Enjoying Oyotest?</h6>
                        <p>
                          To keep using this Workspace after the trial ends, set
                          up a subscription.
                        </p>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() =>
                        this.setState({ createPricePlanModalVisible: true })
                      }
                    >
                      Set up a subscription
                    </button>
                  </div>
                </div>
              </div>
            )}
          {/*  */}
          <div style={{ marginBottom: 42 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h3 className="new-pricing-title">Payment due summary</h3>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src="/assets/images/icon-question.png" alt="" />
                <p className="mb-0">
                  <span style={{ color: "#27AE60", cursor:'pointer' }} onClick={()=>this.redirectToSettingsHelp()}>
                    
                      Contact us
                    
                  </span>{" "}
                  to downgrade your plan
                </p> 
              </div>
            </div>

            <div className="card new-card-pad">
              <div>
                <ul className="new-price-summary">
                  <li>
                    Total payment due
                    <span className="red">
                      $
                      {orgPaymentData.due_payment
                        ? orgPaymentData.due_payment
                        : 0}
                    </span>
                  </li>
                  <li>
                    Due Date
                    <span className="red">{orgPaymentData.renewsAt}</span>
                  </li>

                  <li>
                    Billing Amount
                    <span>${orgPaymentData.price_paid}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/*  */}
          <div style={{ marginBottom: 42 }}>
            <h3 className="new-pricing-title">Current plan</h3>

            <div className="d-flex">
              <div
                className="card new-card-pad"
                style={{ width: "60%", marginRight: "24px" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #EAECEE",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      marginTop: "-10px",
                    }}
                  >
                    <span
                      style={{
                        background: this.state.backgroundColor,
                        borderRadius: "6px",
                        height: "19px",
                        width: "39px",
                        fontWeight: "400",
                        fontSize: "10px",
                        textAlign: "center",
                        color: this.state.color,
                        marginBottom: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",

                        padding: "4px 6px",
                      }}
                    >
                      {orgPaymentData.account_status}
                    </span>

                    <h6
                      style={{
                        fontSize: "16px",
                        fontWeight: 400,
                        color: "#242D35",
                      }}
                    >
                      {orgPaymentData.validity === "1m"
                        ? "Monthly plan"
                        : "Yearly Plan"}
                    </h6>

                    <span
                      style={{
                        background: "#316BFF",
                        borderRadius: "40px",
                        fontWeight: "400",
                        fontSize: "14px",
                        textAlign: "center",
                        height: "25px",
                        color: "#ffffff",
                        textTransform: "capitalize",
                        padding: "5px 17px",
                        marginTop: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      {orgPaymentData.identifier}
                    </span>
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <h5>
                      <span style={{ fontSize: "42px" }}>
                        ${orgPaymentData.product_cost}
                      </span>
                      {orgPaymentData.validity === "1m" ? "/Month" : "/Year"}
                    </h5>
                    <p>
                      {orgPaymentData.pending_users} of{" "}
                      {orgPaymentData.max_license} invites available
                    </p>
                  </div>
                </div>

                <div style={{ marginTop: "24px" }}>
                  <p className="mb-0">
                    {orgPaymentData?.card_brand?.toUpperCase()} XXXX{" "}
                    {orgPaymentData.card_last_four}
                  </p>
                </div>
              </div>
              <div className="card new-card-pad" style={{ width: "40%" }}>
                <p style={{ fontSize: 16, fontWeight: 400, color: "#6B7280" }}>
                  Next Payment
                </p>
                <h5
                  style={{
                    fontSize: 18,
                    fontWeight: 500,
                    color: "#242D35",
                    margin: "5px 0",
                  }}
                >
                  on {orgPaymentData.renewsAt}
                </h5>
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    color: "#111827",
                    marginBottom: 24,
                  }}
                >
                  Your payment will be automatically renewed each{" "}
                  {orgPaymentData.validity === "1m" ? "month" : "year"}.
                </p>
                <button
                  type="button"
                  className="btn"
                  style={{
                    fontSize: 14,
                    fontFamily: "poppins",
                    width: 140,
                    color: "#27AE60",
                    background: "rgba(39, 174, 96, 0.06)",
                  }}
                  onClick={()=>this.redirectToSettingsHelp()}
                >
                  {/* <Link
                    to="/contactus"
                    style={{ color: "inherit", textDecoration: "none" }}
                  > */}
                    Cancel renewal
                  {/* </Link> */}
                </button>
              </div>
            </div>
          </div>
          {/*  */}
          <div style={{ marginBottom: 42 }}>
            <h3 className="new-pricing-title">Invoice</h3>

            <div className="card new-card-pad">
              {orgPaymentData.account_status === "on_trial" && (
                <div
                  style={{
                    height: 370,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src="/assets/images/no-invoice-icon.png" alt="" />
                </div>
              )}
              {orgPaymentData.account_status !== "on_trial" && (
                <div>
                  <ul style={{ listStyleType: "none", marginBottom: 0 }}>
                    {orgPaymentData.AllBillingData &&
                      orgPaymentData.AllBillingData.map((data) => {
                        return (
                          <li
                            style={{
                              width: "100%",
                              borderBottom: "1px solid #EAECEE",
                              paddingBottom: "26px",
                              paddingTop: "26px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <p
                                style={{
                                  marginBottom: 0,
                                  fontSize: 18,
                                  fontWeight: 400,
                                  color: "#111827",
                                }}
                              >
                                {data.createdAt}
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: 18,
                                  color: "#111827",
                                }}
                              >
                                <p style={{ marginBottom: 0 }}>
                                  ${data.price_paid}
                                </p>
                                <span
                                  style={{
                                    margin: "0 25px",
                                    borderRadius: "50px",
                                    padding: "6px 16px",
                                    color: "#316BFF",
                                    background: "#316BFF1A",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {data.payment_status}
                                </span>
                                {this.state.loadingInvoices[
                                  data.subscription_invoice_id
                                ] ? (
                                  <span>
                                    <ColorRing
                                      visible={true}
                                      height="40"
                                      width="106"
                                      ariaLabel="color-ring-loading"
                                      wrapperStyle={{}}
                                      wrapperClass="color-ring-wrapper"
                                      colors={[
                                        "#27AE60",
                                        "#1CAC78",
                                        "#27AE60",
                                        "#1CAC78",
                                        "#27AE60",
                                        "#1CAC78",
                                      ]}
                                    />
                                  </span>
                                ) : (
                                  <button
                                    onClick={() =>
                                      this.downloadInvoice(
                                        data.subscription_invoice_id
                                      )
                                    }
                                    style={{
                                      marginBottom: 0,
                                      color: "#27AE60",
                                      cursor: "pointer",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      src="/assets/images/invoice-icon.png"
                                      alt="invoice icon"
                                    />{" "}
                                    Download
                                  </button>
                                )}
                              </div>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              )}
            </div>
          </div>
          {/*  */}
          <div style={{ marginBottom: 42 }}>
            <h3 className="new-pricing-title">Billing information</h3>

            <div style={{minHeight:'8.7rem'}} className="card new-card-pad">
              <div
                className="new-pricing-div"
                style={{ alignItems: "flex-start", marginBottom: "-20px" }}
              >
                <div
                  style={{
                    borderRight: "1px solid #EAECEE",
                    paddingRight: "62px",
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                      color: "#6B7280",
                      fontSize: 16,
                      paddingBottom: 5,
                    }}
                  >
                    Name
                  </p>
                  <p
                    style={{ marginBottom: 0, color: "#242D35", fontSize: 14 }}
                  >
                    {" "}
                    {orgPaymentData.name}
                  </p>
                </div>
                <div style={{ paddingLeft: "62px" }}>
                  <p
                    style={{
                      marginBottom: 0,
                      color: "#6B7280",
                      fontSize: 16,
                      paddingBottom: 5,
                    }}
                  >
                    Org Name
                  </p>
                  <p
                    style={{ marginBottom: 0, color: "#242D35", fontSize: 14 }}
                  >
                    {this.state.organizationName
                      ? this.state.organizationName
                      : orgPaymentData.organization_name}
                  </p>
                </div>
                <div style={{ paddingLeft: "62px" }}>
                  <p
                    style={{
                      marginBottom: 0,
                      color: "#6B7280",
                      fontSize: 16,
                      paddingBottom: 5,
                    }}
                  >
                    Address
                  </p>
                  <p
                    style={{ marginBottom: 0, color: "#242D35", fontSize: 14 }}
                  >
                    {this.state.address || orgPaymentData.address ? (
                    <>
                      {this.state.address
                        ? `${this.state.address},`
                        : `${orgPaymentData.address},`}
                      <br />
                    </>
                  ) : null}

                  {this.state.city || orgPaymentData.city ? (
                    <>
                      {this.state.city
                        ? `${this.state.city},`
                        : `${orgPaymentData.city},`}
                    </>
                  ) : null}

                  {this.state.selectedState?.label   || orgPaymentData.state ? (
                    <>
                      {this.state.selectedState?.label
                        ? `${this.state.selectedState.label},`
                        : `${orgPaymentData.state},`}
                      <br />
                    </>
                  ) : null}

                  {this.state.selectedCountry?.label || orgPaymentData.country ? (
                    <>
                      {this.state.selectedCountry?.label
                        ? this.state.selectedCountry.label
                        : orgPaymentData.country}
                    </>
                  ) : null}

                  {this.state.pin || orgPaymentData.pin ? (
                    <>
                      {this.state.pin
                        ? `(${this.state.pin})`
                        : `(${orgPaymentData.pin})`}
                    </>
                  ) : null}
                  </p>
                </div>
                <div style={{ paddingLeft: "62px" }}>
                  <p
                    style={{
                      marginBottom: 0,
                      color: "#6B7280",
                      fontSize: 16,
                      paddingBottom: 5,
                    }}
                  >
                    Phone
                  </p>
                  <p
                    style={{ marginBottom: 0, color: "#242D35", fontSize: 14 }}
                  >
                    {this.state.phone
                      ? this.state.phone
                      : orgPaymentData.phone_number}
                  </p>
                </div>
                <div style={{ paddingLeft: "62px" }}>
                  <p
                    style={{
                      marginBottom: 0,
                      color: "#6B7280",
                      fontSize: 16,
                      paddingBottom: 5,
                    }}
                  >
                    Tax Id
                  </p>
                  <p
                    style={{ marginBottom: 0, color: "#242D35", fontSize: 14 }}
                  >
                    {this.state.taxIdNumber
                      ? this.state.taxIdNumber
                      : orgPaymentData.tax_id_number}
                  </p>
                </div>

                <div
                  style={{
                    paddingLeft: "40px",
                    marginLeft: "auto",
                    marginTop: "20px",
                  }}
                >
                  <button
                    style={{
                      fontSize: 14,
                      minWidth: 120,
                      color: "#27AE60",
                      height: "40px",
                      width: "141px",
                      background: "#F1FFF7",
                      border: "0.4px solid #27AE60",
                      borderRadius: "8px",
                    }}
                    onClick={() =>
                      this.setState({ createBillingDetailsModalVisible: true })
                    }
                  >
                    Update address
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* <div>
            <NotificationContainer />
            <div
              className="card-body d-flex  flex-column align-items-center justify-content-center p-relative"
              style={{
                position: "relative",
              }}
            >
              {this.state.showPricingPlanNotification && (
                <div className="current-plan-notification">
                  <div className="current-plan-text">
                    <h5>Basic plan</h5>
                    <p>You are on 15 day trial period run</p>
                  </div>
                  <div
                    className="current-plan-button"
                    onClick={() => {
                      this.setState({
                        showPricingPlanNotification: false,
                        movedComponent: true,
                      });
                    }}
                  >
                    <i className="fa fa-times"></i>
                  </div>
                </div>
              
              )}
            </div>
          </div>  */}

          {this.onTestSuitCreate()}
        </div>
      </>
    );
  }
  renderHeader4 = () => {
    return <Navbar />;

    return (
      <header id="project-nav">
        <div className="topbar-main">
          <div className="container-fluid project-list">
            <div className="logo">
              <div>
                {Promises.getLocalIteam("domain")?.charAt(0).toUpperCase()}
              </div>
              {Promises.getLocalIteam("domain")}
            </div>

            <div className="menu-extras topbar-custom">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  fontSize: "12px",
                  fontWeight: 300,
                  marginRight: 24,
                }}
                onClick={() => {
                  this.setState({ viewState: 0 });
                  this.props.history.push("/dashboard");
                }}
              >
                Dashboard
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  fontSize: "12px",
                  fontWeight: 300,
                  marginRight: 24,
                }}
                // onClick={() => this.handlerAdmin()}
                onClick={() => {
                  this.props.history.push({
                    pathname: "/dashboard",
                    appState: {
                      selectedTab: "administrator",
                      viewState: 2,
                    },
                  });

                  // this.setState({ selectedTab: "administrator" });
                  // this.setState({ viewState: 2 });
                }}
              >
                Invite team
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  fontSize: "12px",
                  fontWeight: 300,
                  marginRight: 24,
                  color: "#2F64ED",
                }}
                onClick={() => {
                  this.props.history.push("/price");
                }}
              >
                Upgrade
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  fontSize: "12px",
                  fontWeight: 300,
                  marginRight: 24,
                }}
                onClick={() => {
                  this.props.history.push("/intigration");
                }}
              >
                Integration
              </div>
              <ul className="list-inline float-right mb-0">
                <li className="list-inline-item dropdown notification-list">
                  <Dropdown
                    isOpen={this.state.dropdownOpenprofile}
                    toggle={this.toggleprofile}
                  >
                    <DropdownToggle
                      className="nav-link dropdown-toggle droptest arrow-none waves-effect nav-user"
                      tag="a"
                    >
                      <img
                        // src={`https://api.dicebear.com/5.x/initials/svg?seed=${Promises.getName()}`}
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/default-img.png"
                        }
                        alt="user"
                        className="rounded-circle"
                      />
                    </DropdownToggle>
                    <DropdownMenu>
                      <div className="dropdown-profile">
                        <img
                          // src={`https://api.dicebear.com/5.x/initials/svg?seed=${Promises.getName()}`}
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/default-img.png"
                          }
                          alt="user"
                          className="rounded-circle"
                        />
                        <p style={{ textTransform: "capitalize" }}>
                          {Promises.getName()}
                        </p>
                        <span>{Promises.getEmail()}</span>
                        <div>
                          <button
                            className="btn"
                            onClick={() => {
                              this.onLogout();
                              history.push("/login?redirect=1");
                            }}
                          >
                            Sign out
                          </button>
                        </div>
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                </li>
                <li className="menu-item list-inline-item">
                  <Link to="#" className="navbar-toggle nav-link">
                    <div className="lines">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    );
  };
}
