import {
  DeleteForever,
  DoneOutline,
  DeleteOutlineOutlined,
  ControlPoint,
  ArrowDropDownCircle,
  PlayCircleFilledRounded,
  AddCircleOutline,
} from "@material-ui/icons";
import { MdArrowRight, MdOutlineArrowDropDown } from "react-icons/md";
import { IoSearchOutline } from "react-icons/io5";
import React, { Component, useEffect, useState } from "react";
import "../styles/component/_testRun.css";
import "../styles/component/_testResult.css";
import update from "immutability-helper";
import { Tooltip } from "@material-ui/core";
import { BASE_URL } from "../_helpers/constants";
import Promises, { formatDate } from "../_helpers/utils";
import Modal from "react-modal";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import AUX from "../hoc/Aux_";
import { Pie } from "react-chartjs-2";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Scrollbars } from "react-custom-scrollbars";
import { MDBDataTable } from "mdbreact";
import { Button } from "reactstrap";
import { history } from "../_helpers/history";
import Select, { components } from "react-select";
import * as _ from "underscore";
import Table from "./Table";
import NotFound from "./NotFound";

class TestResult extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    tableRows: [],
    search: "",
    newList: [],
    curruntTestName: "",
    testCasesData: [],
    fildData: [],
    hiddenSections: [],
    filteredTestcasesData: [],
    projectId: this.props.projectId,
    isTestResultCreated: true,
    name: `Test Run ${moment().format("DD/MM/YYYY HH:mm:ss")}`,
    description: `Test Run ${moment().format("DD/MM/YYYY HH:mm:ss")}`,
    curruntTestrunId: "",
    isTestRunCreated: false,
    TestRundateAndTime: `Test Run ${moment().format("DD/MM/YYYY HH:mm:ss")}`,
    activeid: 0,
    graphCount: "",
    dynamicStyle: [],
    testHistory: [],
    showFilter: false,
    filterProps: [],
    filterParameters: {
      type: [],
      priority: [],
      testType: [],
    },
    latest_key: "",
    intType: "",
    testResultType: "testcase",
    testType: [
      {
        value: "functional",
        label: "Functional",
      },
      {
        value: "unit",
        label: "Unit",
      },
      {
        value: "security",
        label: "Security",
      },
      {
        value: "integration",
        label: "Integration",
      },
      {
        value: "system",
        label: "System",
      },
      {
        value: "acceptance",
        label: "Acceptance",
      },
    ],
  };
  componentDidMount = () => {
    this.setState({ loading: true, projectId: this.props.projectId }, () => {
      this.getTestResult("initial");
      // this.getTestCasesData("initial");
    });
  };
  getTheme = (status) => {
    // console.log("Color ==> " + status);
    switch (status) {
      case "untested":
        return "#FF9A28";
      case "pass":
        return "#00C865";
      case "failed":
        return "#FF4440";
      default:
        return "#FF9A28";
    }
  };
  getUsername = (name) => {
    if (name && name !== "") {
      return name;
    } else {
      return localStorage.getItem("name");
    }
  };
  handleAddFilterOrSearchOnTestCases = () => {
    const { search, testCasesData, filterParameters } = this.state;

    // Apply search filter
    let searchMatchedList = testCasesData.map((ele) => {
      return {
        ...ele,
        lists: ele.lists.filter((el) => {
          const searchMatch = el.subTaskTitle
            ?.toString()
            .toLowerCase()
            .includes(search.toLowerCase());
          return searchMatch;
        }),
      };
    });
    searchMatchedList = searchMatchedList.filter((el) => el.lists.length > 0);

    // Apply additional filter parameters
    let filterMatchedList = searchMatchedList.map((ele) => {
      return {
        ...ele,
        lists: ele.lists.filter((el) => {
          const typeMatch = filterParameters.type?.length
            ? filterParameters.type.includes(
                el.testing.toString().toLowerCase()
              )
            : true;
          const priorityMatch = filterParameters.priority?.length
            ? filterParameters.priority.includes(
                el.priority ? el.priority.toString().toLowerCase() : null
              )
            : true;
          const testTypeMatch = filterParameters.testType?.length
            ? filterParameters.testType.some((item) =>
                el.test_type ? el.test_type.includes(item) : null
              )
            : true;
          return typeMatch && priorityMatch && testTypeMatch;
        }),
      };
    });
    filterMatchedList = filterMatchedList.filter((el) => el.lists.length > 0);

    this.setState({ filteredTestcasesData: filterMatchedList });
  };
  toggleFilterParameters = (key, filteredValue) => {
    console.log("Key", key, "value", filteredValue);

    const found = this.state.filterParameters[key].some(
      (el) => el === filteredValue.toString().toLowerCase()
    );

    if (!found) {
      console.log("not Found ", found);
      this.setState((prevState) => ({
        filterParameters: {
          ...prevState.filterParameters,
          [key]: [
            ...prevState.filterParameters[key],
            filteredValue.toString().toLowerCase(),
          ],
        },
      }));
    } else {
      console.log("Found ", found);

      this.setState({
        filterParameters: {
          ...this.state.filterParameters,
          [key]: this.state.filterParameters[key].filter(
            (item) => item !== filteredValue.toString().toLowerCase()
          ),
        },
      });
    }
  };
  getTestResult = (type) => {
    console.log("FETCHING DATA ========>");
    const token = Promises.getToken();
    const { curruntTestrunId } = this.state;
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    // console.log(requestOptions);

    fetch(`${BASE_URL}projects/result/${this.state.projectId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("FETCHING RESULT ===========>", result);
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        if (!result.status) {
          this.setState({ loading: false });
        } else {
          // console.log(result);
          const data = result.data;
          // if (result.field && result.field.length > 0) {
          //   this.setState({ fildData: result.field });
          // }

          if (data.length === 0) {
            this.setState({ loading: false });
          } else {
            this.setState({ newList: data }, () => {
              console.log("abc");
              if (type === "initial") {
                const { newList } = this.state;
                const newData = newList;
                this.setState({ newList: newData });
                // let sid = newList[0].id;
                let sid = curruntTestrunId || newList[0].id;
                this.getTestCasesData(sid);
                this.setState({
                  testCasesEmpty: false,

                  isTestResultCreated: true,
                });
              }
            });
          }
        }
      })
      .catch((error) => {
        // debugger;
        console.log("error", error);
        this.setState({ testCasesEmpty: true, loading: false });
        localStorage.clear();
        history.push("/login?redirect=1");
      });
  };

  getTestCasesData = (type) => {
    console.log("FETCHING DATA ========>");
    const token = Promises.getToken();
    const { projectId } = this.state;
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    // console.log(requestOptions);

    if (type !== "initial") {
      console.log("Not Initial " + type);

      // this.setState({ testCasesEmpty: true, loading: true });

      fetch(`${BASE_URL}projects/run/${type}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log("FETCHING RESULT ===========>", result);
          if (result.code === 401) {
            this.setState({ loading: false });
            localStorage.clear();
            history.push("/login?redirect=1");
          }
          if (!result.status) {
            this.setState({ testCasesEmpty: true, loading: false });
          } else {
            // console.log(result);

            const data = result.data;

            if (data.length === 0) {
              this.setState({
                testCasesEmpty: true,
                loading: false,
                isTestResultCreated: false,
                testCasesData: data,
              });
            } else {
              this.setState(
                {
                  testCasesEmpty: false,
                  testCasesData: data.map((task) => ({
                    ...task,
                    expanded: true,
                  })),
                  loading: false,
                  curruntTestrunId:
                    type !== "initial" && type ? type : data[0].id,
                  graphCount: result.count,
                },
                () => {
                  // this.getTestResult("initial");
                  // this.setState({ testCasesEmpty: true, loading: false });
                  // if (type == 'initial') {
                  //   const { testCasesData } = this.state;
                  //   const newData = testCasesData;
                  //   this.setState({ testCasesData: newData });
                  // }
                }
              );
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.setState({ testCasesEmpty: true, loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        });
      console.log("Testcase 1 " + JSON.stringify(this.state.testCasesData));
    } else {
      console.log("Initial " + type);

      var requestOptions2 = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${BASE_URL}projects/run/projectid/${projectId}`, requestOptions2)
        .then((response) => response.json())
        .then((result) => {
          console.log("FETCHING RESULT ===========>", result);
          if (result.code === 401) {
            this.setState({ loading: false });
            localStorage.clear();
            history.push("/login?redirect=1");
          }
          if (!result.data.status) {
            this.setState({ testCasesEmpty: true, loading: false });
          } else {
            // console.log(result);

            const data = result.data.data;

            if (data.length === 0 && result.testRunStatus === "INACTIVE") {
              this.setState({
                testCasesEmpty: true,
                loading: false,
                isTestResultCreated: false,
                testCasesData: data,
              });
              this.getTestResult("initial");
            } else {
              this.setState(
                {
                  testCasesEmpty: false,
                  testCasesData: data.map((task) => ({
                    ...task,
                    expanded: true,
                  })),
                  loading: false,
                  // curruntTestrunId: data[0].id,
                  graphCount: result.count,
                },
                () => {
                  this.getTestResult("initial");
                  // console.log(
                  //   this.state.graphCount.pass ? this.state.graphCount.pass : 0
                  // );
                  // if (type == 'initial') {
                  //   const { testCasesData } = this.state;
                  //   const newData = testCasesData;
                  //   this.setState({ testCasesData: newData });
                  // }
                }
              );
            }
            console.log(
              "Testcase 2 " + JSON.stringify(this.state.testCasesData)
            );
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.setState({ testCasesEmpty: true, loading: false });
        });
    }
  };

  onClickHandler = (index, id) => {
    this.setState({ activeid: index, curruntTestrunId: id }, () => {
      this.getTestResult("initial");
      console.log(index + " ** " + id);
      // this.getTestCasesData(id);
    });
  };
  onClickDetail = (i, subTaskId) => {
    console.log("+++>");
    let { dynamicStyle } = this.state;
    dynamicStyle[i] = "40%";
    this.setState({
      dynamicStyle: dynamicStyle,
    });
    this.getTestcaseHistory(subTaskId);
  };

  getTestcaseHistory = (subTaskId) => {
    this.setState({ testHistory: [] });
    console.log(subTaskId);

    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BASE_URL}task/subtask/result/${subTaskId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        result.map((data) => console.log(data));
        this.setState({ testHistory: result }, () => {
          console.log(this.state.testHistory);
        });
        // NotificationManager.success("Field updated successfully");
        // this.getTestCasesData('')
      })
      .catch((error) => {
        // console.log("error", error);
        NotificationManager.error(
          "Something went wrong with saving your data, This may happen due to internal server error, Please try Again"
        );
        // localStorage.clear();
        // history.push("/login?redirect=1");
      });
  };
  getInprogressData = async () => {
    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);
    var raw = {
      pid: this.state.projectId,
    };

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      // body: JSON.stringify(raw),
      redirect: "follow",
    };

    fetch(
      `${BASE_URL}projects/subtasksresult/${this.state.projectId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 404) {
          NotificationManager.error(result.error);
        } else {
          if (result.testRunStatus !== "ACTIVE") {
            NotificationManager.error("There is no Active testRun");
          } else {
            var data = result.data.data;
            let output = [];
            data.map((e) => {
              output.push({
                assignee: e.assignee || "unassigned",
                assignor: e.assignor || "unassigned",
                totaltests: e.total_tests,
                executed: e.executed,
                passed: e.passed,
                failed: e.failed,
                untested: e.untested,
              });
            });
            this.setState({
              curruntTestName: result.data.name,
              tableRows: output,
            });
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
        NotificationManager.error(
          "Something went wrong with saving your data, This may happen due to internal server error, Please try Again"
        );
      });
  };
  pushIntegration = async (
    indexValue,
    isLink = false,
    issueKey = "",
    intType
  ) => {
    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);
    var raw = {
      subtaskResultId: indexValue,
      projectid: this.state.projectId,
      isLink,
      issueKey,
      intType,
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    fetch(`${BASE_URL}task/subtask/integration`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        if (result && result.data) {
          this.setState({
            latest_key: result.data.key || result.data,
          });
        }
      })
      .catch((error) => {
        // console.log("error", error);
        NotificationManager.error(
          "Something went wrong with saving your data, This may happen due to internal server error, Please try Again"
        );
        localStorage.clear();
        history.push("/login?redirect=1");
      });
  };

  getIntegrationData = () => {
    const token = Promises.getToken();

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${BASE_URL}projects/task/integration/${this.state.projectId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (!result.status) {
          this.setState({
            isIntegrationData: false,
            testCasesEmpty: false,
            loading: false,
          });
        } else {
          // console.log(result);
          const data = result.data;
          let tmpIssue = [];
          if (result.type === "asana" || result.type === "trello") {
            result.issues.forEach((element) => {
              tmpIssue.push({
                label: (
                  <Tooltip title="Vanilla" arrow>
                    <span>{element.name}</span>
                  </Tooltip>
                ),
                value: element.gid,
              });
            });
          } else {
            result.issues.forEach((element) => {
              tmpIssue.push({
                label: (
                  <Tooltip title="Vanilla" arrow>
                    <span>{element}</span>
                  </Tooltip>
                ),
                value: element,
              });
            });
          }

          this.setState({
            isIntegrationData: true,
            integrationData: data,
            testCasesEmpty: true,
            loading: false,
            issues: tmpIssue,
            intType: result.type,
          });
          console.log(this.state.intType);
        }
      })
      .catch((error) => {
        // console.log("error", error);
        this.setState({
          isIntegrationData: false,
          testCasesEmpty: true,
          loading: false,
        });
      });
  };

  onClickCloseDetail = (i) => {
    let { dynamicStyle } = this.state;
    dynamicStyle[i] = "0%";
    this.setState({
      dynamicStyle: dynamicStyle,
    });
    this.setState({ testHistory: [] });
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.filterParameters !== this.state.filterParameters) {
      this.handleAddFilterOrSearchOnTestCases();
    }
    if (this.state.testCasesData !== prevState.testCasesData) {
      this.setState({ filteredTestcasesData: this.state.testCasesData });
    }
    
  };

  onFilteredPropsItems = (filteredValue) => {
    console.log(filteredValue);
    const found = this.state.filterProps.some(
      (el) => el === filteredValue.toString().toLowerCase()
    );

    if (!found) {
      // console.log("Found ", found);
      this.setState(
        (prevState) => ({
          filterProps: [
            ...prevState.filterProps,
            filteredValue.toString().toLowerCase(),
          ],
        }),
        () => console.log(this.state.filterProps)
      );
    } else {
      // console.log("Found ", found);

      this.setState(
        {
          filterProps: this.state.filterProps.filter(
            (item) => item !== filteredValue.toString().toLowerCase()
          ),
        },
        () => console.log(this.state.filterProps)
      );
    }
  };

  render() {
    const {
      search,
      testCasesData,
      newList,
      graphCount,
      loading,
      dynamicStyle,
      filterProps,
      latest_key,
      tmpInt,
      intType,
      selectedOptIntegration,
      issues,
      tableRows,
    } = this.state;
    const data = {
      columns: [
        {
          label: "Assignee",
          field: "assignee",
          sort: "asc",
          width: 350,
        },
        {
          label: "Assignor",
          field: "assignor",
          sort: "asc",
          width: 350,
        },
        {
          label: "Total tests",
          field: "totaltests",
          sort: "asc",
          width: 100,
        },
        {
          label: "Executed",
          field: "executed",
          sort: "asc",
          width: 100,
        },
        {
          label: "Passed",
          field: "passed",
          sort: "asc",
          width: 100,
        },
        {
          label: "Failed",
          field: "failed",
          sort: "asc",
          width: 100,
        },
        {
          label: "Untested",
          field: "untested",
          sort: "asc",
          width: 100,
        },
      ],
      rows: tableRows,
    };

    return (
      <>
        <ul className="nav nav-tabs nav-tabs-custom ml-3 !border-none !mb-0 mt-4 bg-[#F2F2F2] w-[300px] rounded-t-[8px]" role="tablist">
          <li className="nav-item gap-x-1 ">
            <a
              className="nav-link active rounded-t-[8px] overflow-clip flex items-center justify-center !bg-red-400"
              data-toggle="tab"
              href="#completed"
              role="tab"
              onClick={() => this.getTestResult("initial")}
            >
              <span className="d-none d-md-block">Completed</span>
              {/* <span className="d-block d-md-none">
                <i className="mdi mdi-account h5"></i>
              </span> */}
            </a>
          </li>
          <li className="nav-item flex items-center justify-center">
            <a
              className="nav-link rounded-t-[8px] overflow-clip flex items-center justify-center"
              data-toggle="tab"
              href="#inprogress"
              role="tab"
              onClick={() => this.getInprogressData()}
            >
              <span className="d-none d-md-block">In Progress</span>
              {/* <span className="d-block d-md-none">
                <i className="mdi mdi-email h5"></i>
              </span> */}
            </a>
          </li>
        </ul>
        <div className="test-case-body" style={{   width:'auto',  height: 'calc(100vh - 185px)',
    overflow: 'hidden auto',
    margin:'0px 16px 16px 16px' }}>
          {loading && (
            <div className="px-3 py-1">
            <SkeletonTheme color="#f3f3f3" highlightColor="#f9f9f9">
              <Skeleton count={7} height={100} />
            </SkeletonTheme>
            </div>
          )}

          <div className="tab-content">
            <div
              className="tab-pane p-3"
              id="inprogress"
              role="tabpanel"
              
            >
              <p>
                Test Run Name: <b>{this.state.curruntTestName}</b>
              </p>
              <div className="row">
                <div className="card-body">
                  <style>{`
    
                .dataTables_wrapper table {
                  border: 1px solid #D9D9D9; 
                  border-radius: 8px; 
                }
                
                .dataTables_wrapper td, .dataTables_wrapper th {
                  border-top: 1px solid #EBEBEB;
                  border-bottom: 1px solid #EBEBEB;
                  border-right: none;
                  border-left: none;
                }
                .dataTables_wrapper th {
                  
                  
                  font-weight: 400;
                  font-size: 12px;
                  
                  height: 40px;
                  text-align: center;
                  color: #4F5B67;
                }
                .dataTables_wrapper td {
                  
                  
                  font-weight: 400;
                  font-size: 16px;
                  height: 40px;
                  text-align: center;
                  color: #000000;
                }
                .dataTables_wrapper thead th {
                  border-top: none;
                }

                
                .dataTables_wrapper tbody tr:last-child td {
                  border-bottom: none;
                }
              `}</style>
                  <MDBDataTable bordered hover noBottomColumns data={data} />
                </div>
              </div>
            </div>

            <div
              className="active tab-pane row "
              id="completed"
              role="tab"
              
            >
              
                {!loading && newList.length === 0 &&
                   (
                    <div
                      className="col-12 mt-3"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        fontSize: 14,
                      }}
                    >
                      <img
                        src="/assets/images/No_Data.png"
                        alt="no data found"
                        style={{ width: 380 }}
                      />
                      No Data Found
                    </div>
                  )}
                {testCasesData.length > 0 && (
                  <div className="test-run-body col-12 !px-1 mt-3" style={{ width:'auto', overflow:'visible'}}>
                    <div className="tab-content">
                      <div
                        className="active tab-pane "
                        id="result"
                        role="tabpanel"
                      >
                        <div className="row !mt-1 !h-[20rem]">
                          <div className="col-lg-8 !h-[20rem] w-[60%]">
                            {this.renderchart()}
                            {/* <input
                      type="text"
                      placeholder="Search test case"
                      className="form-control input-design w-25"
                      value={search}
                      onChange={(e) =>
                        this.setState({ search: e.target.value })
                      }
                    /> */}
                          </div>
                          <div className="col-lg-4 !h-[20rem] w-[40%]">
                            <div className="test-result-float-right !h-[20rem] border-none">
                              <h4 className="m-t-10 primary_heading !text-[20px]">Test Run Results</h4>

                              <div className="m-t-10">
                                <div className="table-responsive !w-[400px] ">
                                  <table
                                    className="table table-vertical "
                                    style={{ width: "100%" }}
                                  >
                                    <tbody className="!border-[0.7px] rounded-md border-[#E5E5E5]  overflow-x-hidden">
                                      <Scrollbars
                                        className="test-run-result-scroll"
                                        style={{
                                          height: "17.6rem",
                                          width: "100%",
                                        }}
                                      >
                                        {newList.map((ele, index) => {
                                          return (
                                            <tr
                                            title={ele.description}
                                            className={`cursor-pointer !w-[400px] ${this.state.activeid === index ? 'active-result' : ''} hover:bg-[#E8F4FF]`}

                                              style={{height: '5.9rem',
                                                borderBottom: 'none',
                                                overflow: 'hidden' // Prevents the background from extending
                                                

                                              }}
                                              onClick={() =>
                                                this.onClickHandler(
                                                  index,
                                                  ele.id
                                                )
                                              }
                                            >
                                              <td
                                                
                                              >
                                                <span
                                                // onClick={() =>
                                                //   this.onClickHandler(index, ele.id)
                                                // }
                                                >
                                                  <div className="media-body chat-user-box">
                                                    <p className="user-title font-roboto text-[13] font-medium m-0">
                                                    {ele.name}
                                                      
                                                    </p>
                                                    <p
                                                      className="font-roboto text-[13] m-0"
                                                      title={ele.description}
                                                      style={{
                                                        
                                                        fontWeight: 300,
                                                      }}
                                                    >
{ele.description && ele.description.length > 65 
    ? `${ele.description.slice(0, 65)}...`
    : ele.description || "No description available"}
                                                    </p>
                                                    <p className="font-roboto font-light text-[13]">
                                                      Executed by{" "}
                                                      {ele.firstname || ""}
                                                      <br />
                                                      Executed date:{" "}
                                                      {ele.executed_at}
                                                    </p>
                                                  </div>
                                                </span>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </Scrollbars>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="w-full flex flex-row items-center gap-x-8 border-b pb-2 pt-6 mb-6 mx-4 bg-blue-500- ">
                          <div
                            class={` relative font-poppins text-[14px] !font-[400] cursor-pointer ${
                              this.state.testResultType === "testcase"
                                ? " custom-border "
                                : ""
                            } !pb-0`}
                            
                            onClick={() => {
                              this.setState({ testResultType: "testcase" });
                            }}
                          >
                            Test Cases
                          </div>
                          <div
                            class={`relative font-poppins text-[14px] !font-[400] cursor-pointer ${
                              this.state.testResultType === "issue"
                                ? " custom-border "
                                : ""
                            } !pb-0`}

                            onClick={() => {
                              this.setState({ testResultType: "issue" });
                            }}
                          >
                            Test run Issues
                          </div>
                        </div>
                        {this.state.testResultType === "testcase" && (
                          <div className="row">
                            <div className="col-lg-12">
                              <div
                                 style={{
                                  position: "sticky",
                                  top: 0,
                                  right: 0,
                                  left: 0,
                                  backgroundColor: "#FFFFFF",
                                  paddingTop: 20,
                                  zIndex:99,
                                  height: 80,
                                  borderBottom: '1px solid #eeeeee',
                                  
                                }}
                              >
                                <div className="flex flex-row items-center justify-between w-full mb-3">
                               <div
                          className="flex items-center !p-0 !m-0"
                          style={{
                            position: "relative",
                          }}
                        >
                          <input
                            autoComplete="off"
                            type="text"
                            className="form-control !h-[40px] !mt-0 !placeholder-[#316BFF]"
                            placeholder="Search"
                            value={search}
                            onChange={(e) =>
                              this.setState({ search: e.target.value }, () => {
                                this.handleAddFilterOrSearchOnTestCases();
                              })
                            }
                            style={{
                              border: "0.3px solid #316BFF",
                              borderRadius: 8,
                              height: 44,
                              fontSize: 12,
                              width: 260,
                              background: "rgba(146, 198, 255, 0.1)",
                            }}
                          />
                          <span
                            style={{
                              position: "absolute",
                              color: "#316BFF",
                              top: 12,
                              right: 12,
                            }}
                          >
                            <IoSearchOutline />
                          </span>
                        </div>
                        <div className="flex flex-row gap-x-2 itmes-center justify-center">
                        <div
                          style={{
                            position: "relative",
                          }}
                        >
                          <button
                            type="button"
                            ref={this.wrapperRef}
                            onClick={() =>
                              this.setState({
                                showFilter: !this.state.showFilter,
                              })
                            }
                            style={{
                              position: "relative",
                              width: 110,
                              background: "rgba(146, 198, 255, 0.1)",
                              borderRadius: 8,
                            }}
                            className="px-3 border rounded-md !flex !flex-row !items-center !justify-center gap-x-3 !text-[#316BFF] !border-[#316BFF] h-[40px] text-[12px] !font-[300]"
                          >
                            <svg
                              width="11"
                              height="10"
                              viewBox="0 0 11 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.15 4.11744C7.56459 4.11737 7.9681 3.97419 8.30054 3.70918C8.63299 3.44417 8.87663 3.07149 8.99525 2.64651H10.45C10.5959 2.64651 10.7358 2.58452 10.8389 2.47418C10.9421 2.36384 11 2.21418 11 2.05814C11 1.90209 10.9421 1.75244 10.8389 1.6421C10.7358 1.53175 10.5959 1.46977 10.45 1.46977H8.99525C8.87643 1.04501 8.63271 0.672578 8.30028 0.407795C7.96785 0.143012 7.56445 0 7.15 0C6.73555 0 6.33215 0.143012 5.99972 0.407795C5.66729 0.672578 5.42357 1.04501 5.30475 1.46977H0.55C0.404131 1.46977 0.264236 1.53175 0.161091 1.6421C0.0579462 1.75244 0 1.90209 0 2.05814C0 2.21418 0.0579462 2.36384 0.161091 2.47418C0.264236 2.58452 0.404131 2.64651 0.55 2.64651H5.30475C5.42337 3.07149 5.66701 3.44417 5.99946 3.70918C6.3319 3.97419 6.73541 4.11737 7.15 4.11744ZM0.55 7.35349C0.404131 7.35349 0.264236 7.41548 0.161091 7.52582C0.0579462 7.63616 0 7.78582 0 7.94186C0 8.09791 0.0579462 8.24756 0.161091 8.35791C0.264236 8.46825 0.404131 8.53023 0.55 8.53023H1.72975C1.84857 8.95499 2.09229 9.32742 2.42472 9.59221C2.75715 9.85699 3.16055 10 3.575 10C3.98945 10 4.39285 9.85699 4.72528 9.59221C5.05771 9.32742 5.30143 8.95499 5.42025 8.53023H10.45C10.5959 8.53023 10.7358 8.46825 10.8389 8.35791C10.9421 8.24756 11 8.09791 11 7.94186C11 7.78582 10.9421 7.63616 10.8389 7.52582C10.7358 7.41548 10.5959 7.35349 10.45 7.35349H5.42025C5.30143 6.92873 5.05771 6.5563 4.72528 6.29152C4.39285 6.02674 3.98945 5.88372 3.575 5.88372C3.16055 5.88372 2.75715 6.02674 2.42472 6.29152C2.09229 6.5563 1.84857 6.92873 1.72975 7.35349H0.55Z"
                                fill="#316BFF"
                              />
                            </svg>
                            Filter
                          </button>
                          <div
                            className="filter-dashboard"
                            style={{
                              display: this.state.showFilter ? "block" : "none",
                            }}
                          >
                            <h5
                        style={{
                          fontSize: 16,
                          fontWeight: 600,
                          padding: 16,
                          borderBottom: "1px solid #ECECEC",
                        }}
                      >
                        Select filter
                      </h5>
                      <div class="filter-div-properties">
                        <div className="filter-properties !border-b-0">
                          <p
                            className="my-2"
                            style={{ fontSize: 14, opacity: 0.7 }}
                          >
                            Type
                          </p>
                          <div className="d-flex flex-column">
                            <label htmlFor="automatedFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="Automated"
                                checked={this.state.filterParameters.type.includes(
                                  "automated"
                                )}
                                id="automatedFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "type",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />
                              Automated
                            </label>

                            <label htmlFor="manualFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="Manual"
                                checked={this.state.filterParameters.type.includes(
                                  "manual"
                                )}
                                id="manualFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "type",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Manual
                            </label>
                          </div>
                        </div>
                        <div className="filter-properties">
                          <p>Priority</p>
                          <div className="d-flex flex-column">
                            <label htmlFor="highFilter">
                              <input
                                className="priorityFilter !w-3 !h-3"
                                type="checkbox"
                                value="High"
                                checked={this.state.filterParameters.priority.includes(
                                  "high"
                                )}
                                id="highFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "priority",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />
                              High
                            </label>
                            <label htmlFor="mediumFilter">
                              <input
                                className="priorityFilter !w-3 !h-3"
                                type="checkbox"
                                value="Medium"
                                checked={this.state.filterParameters.priority.includes(
                                  "medium"
                                )}
                                id="mediumFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "priority",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Medium
                            </label>
                            <label htmlFor="lowFilter">
                              <input
                                className="priorityFilter !w-3 !h-3"
                                type="checkbox"
                                value="Low"
                                checked={this.state.filterParameters.priority.includes(
                                  "low"
                                )}
                                id="lowFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "priority",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Low
                            </label>
                          </div>
                        </div>
                        <div className="filter-properties">
                          <p>Test Type</p>
                          <div className="d-flex flex-column">
                            <label htmlFor="functionalFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="Functional"
                                checked={this.state.filterParameters.testType.includes(
                                  "functional"
                                )}
                                id="functionalFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "testType",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />
                              Functional
                            </label>

                            <label htmlFor="unitFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="Unit"
                                checked={this.state.filterParameters.testType.includes(
                                  "unit"
                                )}
                                id="unitFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "testType",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Unit
                            </label>

                            <label htmlFor="securityFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="Security"
                                checked={this.state.filterParameters.testType.includes(
                                  "security"
                                )}
                                id="securityFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "testType",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Security
                            </label>

                            <label htmlFor="integrationFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="Integration"
                                checked={this.state.filterParameters.testType.includes(
                                  "integration"
                                )}
                                id="integrationFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "testType",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Integration
                            </label>

                            <label htmlFor="systemFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="System"
                                checked={this.state.filterParameters.testType.includes(
                                  "system"
                                )}
                                id="systemFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "testType",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              System
                            </label>

                            <label htmlFor="acceptanceFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="Acceptance"
                                id="acceptanceFilter"
                                checked={this.state.filterParameters.testType.includes(
                                  "acceptance"
                                )}
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "testType",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Acceptance
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-evenly !mt-[16px]">
                      <button
                        type="button"
                        class="filter-btn"
                        onClick={() =>
                          this.setState({
                            filterProps: [],
                            filterParameters: {
                              type: [],
                              priority: [],
                              testType: [],
                            },
                          })
                        }
                      >
                        Clear
                      </button>
                      <button 
                      type="button"
                      onClick={() =>
                        this.setState({ showFilter: !this.state.showFilter })
                      }
                      className="rounded-lg !h-[40px] flex flex-row !w-[80px] !items-center justify-center gap-x-2  px-[16px] py-[8px] !bg-[#E7EAEE] !text-[14px] font-poppins text-[#636463] !font-[600] ">
                        Cancel
                      </button>
                      </div>
                          </div>
                        </div>  
                        </div>
                                </div>
                              </div>
                              <div className="test-case-body h-auto" >
                                <div>
                                {this.state.filteredTestcasesData.length === 0 && (
                      <div className="" style={{ paddingTop: 100 }}>
                        <NotFound
                          subject={"Testcase"}
                          search={search}
                          actionNeeded={false}
                          clear={() => {
                            this.setState({
                              search: "",
                              showFilter: false,
                              filterProps: [],
                              filterParameters: {
                                type: [],
                                priority: [],
                                testType: [],
                              },
                            });
                          }}
                        />
                      </div>
                    )}
                                  {this.state.filteredTestcasesData.length > 0 &&
                      this.state.filteredTestcasesData.map((ele, index) => {
                        if (ele.lists.length === 0) return null;
                        return (
                          <div className="tests">
                            <div
                              className="flex "
                              style={{
                                borderBottom: "0.5px solid #D1D5DB",
                                marginBottom: 3,
                                backgroundColor: "#F8F8F8",
                              }}
                            >
                              <div className="w-0.5 px-0.5 py-3 bg-primary-green" />
                              <div className="model-input  w-full">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flex: 1,
                                  }}
                                >
                                  {ele.expanded ? (
                                    <MdOutlineArrowDropDown
                                      className="text-primary-green"
                                      size={28}
                                      onClick={() => {
                                        ele.expanded = !ele.expanded;
                                        this.setState({
                                          testCasesData: testCasesData,
                                        });
                                      }}
                                    />
                                  ) : (
                                    <MdArrowRight
                                      className="text-black"
                                      size={28}
                                      onClick={() => {
                                        ele.expanded = !ele.expanded;
                                        this.setState({
                                          testCasesData: testCasesData,
                                        });
                                      }}
                                    />
                                  )}

                                  <input
                                    autoComplete="off"
                                    type="text"
                                    placeholder="Add Task Title here"
                                    className="input1 w-full"
                                    name="model"
                                    value={ele.model || ele.taskTitle}
                                    // onBlur={() => this.updateTaskChanges(index)}
                                    // onChange={(e) =>
                                    //   this.onModelUpdate(e, index)
                                    // }
                                    disabled={true}
                                  />
                                </div>
                              </div>
                            </div>

                            {/* Div Goes Here */}
                            {ele.isCollapse ||
                              ele.lists
                                .filter((el) => {
                                  if (filterProps.length <= 0) {
                                    return (
                                      el.subTaskTitle === "" ||
                                      el.subTaskTitle === null ||
                                      el.subTaskTitle
                                        .toString()
                                        .toLowerCase()
                                        .includes(search.toLowerCase())
                                    );
                                  } else {
                                    return (
                                      (el.subTaskTitle === "" ||
                                        el.subTaskTitle === null ||
                                        el.subTaskTitle
                                          .toString()
                                          .toLowerCase()
                                          .includes(search.toLowerCase())) &&
                                      (filterProps.includes(
                                        el.testing.toString().toLowerCase()
                                      ) ||
                                        filterProps.includes(
                                          el.priority
                                            ? el.priority
                                                .toString()
                                                .toLowerCase()
                                            : null
                                        ) ||
                                        filterProps.some((item) =>
                                          el.test_type
                                            ? el.test_type.includes(item)
                                            : null
                                        ))
                                    );
                                  }
                                })

                                .map((item, i, row) => {
                                  // console.log(
                                  // filterProps.includes(
                                  //   item.testing.toString().toLowerCase()

                                  // )

                                  item.testing = item.testing || "manual";
                                  item.integration =
                                    item.integration != null
                                      ? JSON.parse(
                                          JSON.stringify(item.integration)
                                        )
                                      : item.integration;
                                  // console.log(item.integration);
                                  let tmpInt = item.integration
                                    ? JSON.parse(item.integration)
                                    : item.integration;
                                  let cg = tmpInt;
                                  tmpInt = tmpInt
                                    ? _.get(tmpInt, "value")
                                    : tmpInt;

                                  let intTmpType = cg ? _.get(cg, "type") : cg;

                                  // console.log(
                                  //   "item.integration",
                                  //   tmpInt,
                                  //   intTmpType,
                                  //   item.integration
                                  // );
                                  if (intTmpType === "asana") {
                                    let project_key = cg
                                      ? _.get(cg, "project_key")
                                      : cg;
                                    //https://app.asana.com/0/1199525592925725/1203666857435636/f
                                    item.intiURL = `https://app.asana.com/0/${project_key}/${tmpInt}`;
                                  }
                                  if (intTmpType === "jira") {
                                    let domain = cg ? _.get(cg, "domain") : cg;
                                    //https://mkgareja.atlassian.net/browse/OYET-127
                                    item.intiURL = `https://${domain}.atlassian.net/browse/${tmpInt}`;
                                  }
                                  if (intTmpType === "trello") {
                                    item.intiURL = `https://trello.com/c/${tmpInt}`;
                                  }
                                  if (intTmpType === "github") {
                                    let githubUserName = cg
                                      ? _.get(cg, "githubUserName")
                                      : cg;
                                    let project_label = cg
                                      ? _.get(cg, "project_label")
                                      : cg;

                                    item.intiURL = `https://github.com/${githubUserName}/${project_label}/issues/${tmpInt}`;
                                  }

                                  return (
                                    ele.expanded && (
                                      <div
                                        className={`model-list hover:!bg-[#E8F4FF] qq pl-12 pr-4 ${
                                          item.subTaskTitle ? "" : " !bg-white"
                                        }`}
                                        // onMouseEnter={this.mouseEnter.bind(this)}
                                        // onMouseLeave={this.mouseLeave.bind(this)}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            flex: 1,
                                          }}
                                          className={`model-list-testsuite `}
                                        >
                                          <input
                                            autoFocus={
                                              item.subTaskTitle === "" ||
                                              item.subTaskTitle === null
                                                ? true
                                                : false
                                            }
                                            // ref={(el) =>
                                            //   (item.subTaskTitle === "" ||
                                            //     item.subTaskTitle === null) &&
                                            //   el
                                            //     ? el.focus()
                                            //     : null
                                            // }

                                            type="text"
                                            autoComplete="off"
                                            placeholder="Add new Test case here"
                                            className={`list-input !placeholder-primary-green ${
                                              item.classNameDependsOnCondtion
                                            } ${
                                              item.subTaskTitle
                                                ? " bg-transparent"
                                                : ""
                                            }`}
                                            name="list"
                                            value={
                                              item.subTaskTitle !== null
                                                ? item.subTaskTitle
                                                : ""
                                            }
                                            // onChange={(e) =>
                                            //   this.onModelListUpdate(
                                            //     ele.id,
                                            //     item.subtaskId,
                                            //     i,
                                            //     e.target.value
                                            //   )
                                            // }
                                            disabled={true}
                                            onKeyDown={(e) => {
                                              if (e.key === "Enter") {
                                                this.addNewList(
                                                  e.currentTarget.value,
                                                  ele.id
                                                );
                                              }
                                            }}
                                          />
                                          {item.subTaskTitle ? (
                                            <span
                                              className="mr-8 pl-2 flex items-center open-details-button cursor-pointer"
                                              name=""
                                              onClick={() =>
                                                this.onClickDetail(
                                                  index + "-" + i,
                                                  item.subtaskId
                                                )
                                              }
                                            >
                                              <span className="details-button">
                                                Details
                                              </span>

                                              <svg
                                                className="details-svg"
                                                width="13"
                                                height="13"
                                                viewBox="0 0 13 13"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M6.83333 0.875488H6.25C3.35051 0.875488 1 3.22599 1 6.12549C1 9.025 3.35051 11.3755 6.25 11.3755H6.83333M9.16667 3.79215L11.5 6.12549M11.5 6.12549L9.16667 8.45882M11.5 6.12549H4.5"
                                                  stroke="#999999"
                                                  stroke-width="1.5"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                />
                                              </svg>
                                            </span>
                                          ) : (
                                            <span className="pr-[27px]" />
                                          )}
                                          <Tooltip
                                            placement="top"
                                            title="Click to change the status"
                                            arrow
                                          >
                                            <div
                                              className="status-container !w-20 overflow-hidden"
                                              style={{
                                                backgroundColor: this.getTheme(
                                                  item.testStatus
                                                ),
                                                display:
                                                  item.testStatus === ""
                                                    ? "none"
                                                    : "flex",
                                              }}
                                              
                                            >
                                              <p>{item.testStatus}</p>
                                            </div>
                                          </Tooltip>
                                        </div>

                                        <div
                                          ref={this.sidebarRef}
                                          style={{
                                            width:
                                              dynamicStyle[index + "-" + i] ||
                                              dynamicStyle,
                                            zIndex: 5000,
                                          }}
                                          className="sidebar-new"
                                        >
                                          <div className="button-items detail-btn">
                                            <i
                                              className="mdi mdi-close btn-float-left"
                                              onClick={() =>
                                                this.onClickCloseDetail(
                                                  index + "-" + i
                                                )
                                              }
                                            ></i>
                                          </div>
                                          <Scrollbars style={{ height: "85%" }}>
                                            {/* <a onClick={() => this.onClickCloseDetail()} className="closebtn">&times;</a> */}
                                            <div className="row test-row sidebar-nav-body">
                                              <div className="col-12">
                                                <div className="card m-b-20">
                                                  <div className="card-body">
                                                    <form
                                                      onSubmit={(e) =>
                                                        this.onSubmitsubTaskDataInSideDrawer(
                                                          ele.id,
                                                          item.subtaskId,
                                                          e
                                                        )
                                                      }
                                                    >
                                                      <div className="form-group row">
                                                        <div className="col-sm-12 p-0 mr-6 mb-6">
                                                          <input
                                                            className="form-control test-header-title"
                                                            type="text"
                                                            name="subTaskNameInSideDrawer"
                                                            style={{
                                                              marginBottom: 20,
                                                              marginTop: "auto",
                                                              paddingLeft: 0,
                                                            }}
                                                            ref={(el) => {
                                                              if (el) {
                                                                el.style.setProperty(
                                                                  "font-size",
                                                                  "20px",
                                                                  "important"
                                                                );
                                                                el.style.setProperty(
                                                                  "font-family",
                                                                  "'Raleway', sans-serif",
                                                                  "important"
                                                                );
                                                                el.style.setProperty(
                                                                  "font-weight",
                                                                  "700px",
                                                                  "important"
                                                                );
                                                              }
                                                            }}
                                                            
                                                            value={
                                                              item.subTaskTitle
                                                            }
                                                          />
                                                          <div className="flex flex-row items-cente gap-2 ">
                                                            <div className="flex flex-row items-center text-[14px] font-[400] gap-2 .font-roboto color-[#525252]">
                                                              {" "}
                                                              ID:{" "}
                                                              <span className=".font-poppins h-8 flex text-[14px] items-center justify-center text-primary-green bg-[#EBFFF4] px-2 py-1 rounded-md font-[500]">
                                                                {item.subid}
                                                              </span>
                                                            </div>
                                                            <div className="flex flex-row items-center text-[14px] font-[400] gap-2 .font-roboto color-[#525252]">
                                                              {" "}
                                                              TestSuite:{" "}
                                                              <span className=".font-poppins h-8 flex text-[14px] items-center justify-center text-primary-green bg-[#EBFFF4] px-2 py-1 rounded-md font-[500]">
                                                                {ele.model ||
                                                                  ele.taskTitle}
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <hr></hr>
                                                      <div
                                                        className="form-group row"
                                                        style={{
                                                          marginTop: "2rem",
                                                        }}
                                                      >
                                                        <label
                                                          for="example-description-input"
                                                          className="pading-right-0px col-sm-3 col-form-label input-label"
                                                          style={{
                                                            width: "100%",
                                                          }}
                                                        >
                                                          Description
                                                        </label>
                                                        <div className="col-sm-8 ml-3">
                                                          <textarea
                                                            id="textarea"
                                                            className="form-control input-style-detail"
                                                            name="subTaskDescriptionInSideDrawer"
                                                            
                                                            value={
                                                              item.description
                                                            }
                                                            maxlength="225"
                                                            rows="6"
                                                            placeholder="Description and Steps to reproduce"
                                                          ></textarea>
                                                        </div>
                                                      </div>
                                                      <div className="form-group row">
                                                        <label
                                                          for="example-summary-input"
                                                          className="col-sm-3 col-form-label input-label"
                                                        >
                                                          Expected Result
                                                        </label>
                                                        <div className="col-sm-8 ml-3">
                                                          <textarea
                                                            id="textarea"
                                                            className="form-control input-style-detail"
                                                            name="subTaskSummaryInSideDrawer"
                                                            
                                                            
                                                            value={item.summary}
                                                            maxlength="225"
                                                            rows="2"
                                                            placeholder="Summary"
                                                          ></textarea>
                                                        </div>
                                                      </div>
                                                      <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label input-label">
                                                          Browser
                                                        </label>
                                                        <div className="col-sm-8 ml-3">
                                                          <select
                                                            className="form-control input-style-detail"
                                                            name="subTaskBrowserInSideDrawer"
                                                            value={item.browser}
                                                            
                                                            
                                                          >
                                                            
                                                          </select>
                                                        </div>
                                                      </div>

                                                      <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label input-label">
                                                          OS
                                                        </label>
                                                        <div className="col-sm-8 ml-3">
                                                          <select
                                                            name="subTaskOsInSideDrawer"
                                                            value={item.os}
                                                            className="form-control input-style-detail"
                                                            
                                                          >
                                                          
                                                            
                                                          </select>
                                                        </div>
                                                      </div>

                                                      <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label input-label">
                                                          Type
                                                        </label>
                                                        <div className="col-sm-8 ml-3">
                                                          <select
                                                            name="subTaskTestingInSideDrawer"
                                                            value={item.testing}
                                                            className="form-control input-style-detail"
                                                            
                                                            
                                                          >
                                                            
                                                          </select>
                                                        </div>
                                                      </div>
                                                      <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label input-label">
                                                          Priority
                                                        </label>
                                                        <div className="col-sm-8 ml-3">
                                                          <select
                                                            name="subTaskpriorityInSideDrawer"
                                                            value={
                                                              item.priority
                                                            }
                                                            className="form-control input-style-detail"
                                                            
                                                           >
                                                            
                                                          </select>
                                                        </div>
                                                      </div>

                                                      <div className="form-group row">
                                                        <label
                                                          for="example-id-input "
                                                          className="col-sm-3 col-form-label input-label"
                                                        >
                                                          Created By
                                                        </label>
                                                        <div className="col-sm-8 ml-3">
                                                          <input
                                                            name="subTaskCreatedByInSideDrawer"
                                                            className="form-control input-style-detail"
                                                            type="text"
                                                            value={
                                                              (item.username =
                                                                this.getUsername(
                                                                  item.username
                                                                ))
                                                            }
                                                            
                                                            placeholder="Created By"
                                                            disabled
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="form-group row">
                                                        <label
                                                          for="example-id-input "
                                                          className="col-sm-3 col-form-label input-label"
                                                        >
                                                          Test type
                                                        </label>
                                                        <div className="col-sm-8 ml-3">
                                                          <Select
                                                            className=" input-style-detail"
                                                            isMulti
                                                            isSearchable={false}
                                                            value={
                                                              item.test_type
                                                                ? this.state.testType.filter(
                                                                    ({
                                                                      value,
                                                                    }) =>
                                                                      item.test_type.indexOf(
                                                                        value
                                                                      ) !== -1
                                                                  )
                                                                : this.state
                                                                    .testType[0]
                                                            }
                                                           
                                                            
                                                            defaultValue={
                                                              this.state
                                                                .testType[0]
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="form-group row">
                                                        <label
                                                          for="example-description-input "
                                                          className="pading-right-0px col-sm-3 col-form-label input-label"
                                                        >
                                                          Last Executed{" "}
                                                        </label>
                                                        <div
                                                          className="col-sm-8 ml-3"
                                                          style={{
                                                            display: "flex",
                                                            alignItems:
                                                              "center",
                                                            justifyContent:
                                                              "flex-start",
                                                          }}
                                                        >
                                                          {this.state
                                                            .testHistory &&
                                                            this.state.testHistory.map(
                                                              (history) => {
                                                                return (
                                                                  <Tooltip
                                                                    placement="top"
                                                                    title={
                                                                      history.testStatus +
                                                                      " " +
                                                                      formatDate(
                                                                        history.createdAt
                                                                      )
                                                                    }
                                                                    arrow
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        marginRight: 15,
                                                                        display:
                                                                          "flex",
                                                                        alignItems:
                                                                          "center",
                                                                        flexDirection:
                                                                          "column",
                                                                      }}
                                                                    >
                                                                      <div
                                                                        classname="testcase-history"
                                                                        style={{
                                                                          width: 13,
                                                                          height: 13,
                                                                          borderRadius: 50,
                                                                          cursor:
                                                                            "pointer",
                                                                          backgroundColor:
                                                                            "#FFFFFF",
                                                                          color:
                                                                            "white",
                                                                        }}
                                                                      >
                                                                        {history.testStatus ===
                                                                          "untested" && (
                                                                          <>
                                                                            <svg
                                                                              width="13"
                                                                              height="13"
                                                                              viewBox="0 0 13 13"
                                                                              fill="none"
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                              <path
                                                                                d="M6.5 0C2.89545 0 0 2.89545 0 6.5C0 10.1045 2.89545 13 6.5 13C10.1045 13 13 10.1045 13 6.5C13 2.89545 10.1045 0 6.5 0ZM10.4591 7.56364C10.4 7.8 10.1636 7.91818 9.92727 7.85909L6.61818 7.20909C6.5 7.20909 6.38182 7.09091 6.26364 7.03182C6.02727 6.91364 5.90909 6.73636 5.90909 6.5L6.02727 1.77273C6.02727 1.47727 6.26364 1.3 6.5 1.3C6.73636 1.3 6.97273 1.53636 6.97273 1.77273L7.03182 5.85L10.1045 6.91364C10.4 6.97273 10.5182 7.26818 10.4591 7.56364Z"
                                                                                fill="#FF9A28"
                                                                              />
                                                                            </svg>
                                                                          </>
                                                                        )}
                                                                        {history.testStatus ===
                                                                          "pass" && (
                                                                          <>
                                                                            <svg
                                                                              width="13"
                                                                              height="13"
                                                                              viewBox="0 0 13 13"
                                                                              fill="none"
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                              <path
                                                                                fill-rule="evenodd"
                                                                                clip-rule="evenodd"
                                                                                d="M6.5 13C8.22391 13 9.87721 12.3152 11.0962 11.0962C12.3152 9.87721 13 8.22391 13 6.5C13 4.77609 12.3152 3.12279 11.0962 1.90381C9.87721 0.684819 8.22391 0 6.5 0C4.77609 0 3.12279 0.684819 1.90381 1.90381C0.684819 3.12279 0 4.77609 0 6.5C0 8.22391 0.684819 9.87721 1.90381 11.0962C3.12279 12.3152 4.77609 13 6.5 13ZM5.55286 9.165H4.89357L2.78571 7.05714L3.445 6.39786L5.22786 8.18071L9.12786 4.27143L9.78714 4.93071L5.55286 9.165Z"
                                                                                fill="#27AE60"
                                                                              />
                                                                            </svg>
                                                                          </>
                                                                        )}
                                                                        {history.testStatus ===
                                                                          "failed" && (
                                                                          <>
                                                                            <svg
                                                                              width="13"
                                                                              height="13"
                                                                              viewBox="0 0 13 13"
                                                                              fill="none"
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                              <path
                                                                                fill-rule="evenodd"
                                                                                clip-rule="evenodd"
                                                                                d="M8.79815 8.2225C8.95699 8.38093 8.95699 8.64095 8.79815 8.79939C8.63971 8.95783 8.38216 8.95783 8.22331 8.79939L6.50243 7.07689L4.76937 8.81154C4.60931 8.96998 4.35014 8.96998 4.19007 8.81154C4.03042 8.64904 4.03042 8.38907 4.19007 8.23063L5.92313 6.49593L4.20225 4.7775C4.0434 4.61907 4.0434 4.35905 4.20225 4.20061C4.36028 4.04217 4.61784 4.04217 4.77669 4.20061L6.49757 5.92311L8.24362 4.17626C8.40368 4.01782 8.66246 4.01782 8.82252 4.17626C8.98218 4.33876 8.98218 4.59467 8.82252 4.75717L7.07687 6.50407L8.79815 8.2225ZM6.5 0C2.90997 0 0 2.90875 0 6.5C0 10.0913 2.90997 13 6.5 13C10.09 13 13 10.0913 13 6.5C13 2.90875 10.09 0 6.5 0Z"
                                                                                fill="#FF2727"
                                                                              />
                                                                            </svg>
                                                                          </>
                                                                        )}
                                                                      </div>
                                                                    </div>
                                                                  </Tooltip>
                                                                );
                                                              }
                                                            )}
                                                        </div>
                                                      </div>

                                                      {(intType !== "" ||
                                                        intTmpType?.trim()) &&
                                                        !this.state
                                                          .createIssue && (
                                                          <>
                                                            <div
                                                              className="form-group row"
                                                              style={{
                                                                display: "flex",
                                                                alignItems:
                                                                  "center",
                                                              }}
                                                            >
                                                              <label
                                                                htmlFor="example-id-input" // Replace 'for' with 'htmlFor' in JSX
                                                                className="col-sm-3 col-form-label input-label"
                                                              >
                                                                {" "}
                                                                {intTmpType?.trim() ? (
                                                                  `${intTmpType} url`
                                                                ) : (
                                                                  <>
                                                                    {intType ===
                                                                      "jira" &&
                                                                      "Jira Bug"}
                                                                    {intType ===
                                                                      "asana" &&
                                                                      "Asana Bug"}
                                                                    {intType ===
                                                                      "trello" &&
                                                                      "Trello Card"}
                                                                    {intType ===
                                                                      "github" &&
                                                                      "GitHub Issue"}
                                                                  </>
                                                                )}
                                                              </label>
                                                              <div className="col-sm-8 ml-3 d-flex align-items-center">
                                                                {item?.integration && (
                                                                  <div className="row m-0">
                                                                    <div
                                                                      className="col-sm-12 input-style-detail"
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      onClick={() =>
                                                                        window.open(
                                                                          item.intiURL,
                                                                          "_blank"
                                                                        )
                                                                      }
                                                                    >
                                                                      Click here
                                                                      to view
                                                                      <i
                                                                        className="fa fa-external-link ml-2"
                                                                        aria-hidden="true"
                                                                      ></i>
                                                                    </div>
                                                                  </div>
                                                                ) }
                                                              </div>
                                                            </div>
                                                          </>
                                                        )}{item.field !== null &&
                                                          item.field.map(
                                                            (eNew, indexNew) => (
                                                              <div className="form-group row">
                                                                <label
                                                                  key={indexNew}
                                                                  for="example-id-input"
                                                                  className="col-sm-3 col-form-label input-label"
                                                                >
                                                                  {eNew.fileName}
                                                                </label>
                                                                <div className="col-sm-8 ml-3">
                                                                  <input
                                                                    type="hidden"
                                                                    name="subTaskCustomFieldInSideDrawer"
                                                                    value={JSON.stringify(
                                                                      eNew
                                                                    )}
                                                                  />
                                                                  <input
                                                                    key={indexNew}
                                                                    className="form-control input-style-detail"
                                                                    type="text"
                                                                    name="subTaskCustomFieldInSideDrawer123"
                                                                    value={
                                                                      eNew.fileValue
                                                                    }
                                                                    
                                                                    placeholder={
                                                                      eNew.fileName
                                                                    }
                                                                  />
                                                                </div>
                                                              </div>
                                                            )
                                                          )}
                                                        
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Scrollbars>
                                            </div>
                                      </div>
                                    )
                                  );
                                })}
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {this.state.testResultType === "issue" && (
                          <div>
                            <IssuesTab
                              resultId={
                                this.state.curruntTestrunId
                                  ? this.state.curruntTestrunId
                                  : this.state.newList?.length > 0
                                  ? this.state.newList[0].id
                                  : null
                              }
                            />
                          </div>
                        )}
                      </div>
                      <div
                        className="tab-pane p-3"
                        id="summary"
                        role="tabpanel"
                      >
                        <div className="col-9">
                          <div className="card m-b-20">
                            <div className="card-body">
                              <table className="table table-vertical">
                                <tbody>
                                  <tr>
                                    <td>Testrun id</td>
                                    <td>mahesh</td>
                                  </tr>
                                  <tr>
                                    <td>Date</td>
                                    <td>mahesh</td>
                                  </tr>
                                  <tr>
                                    <td>Total testcaes</td>
                                    <td>
                                      {graphCount.pass
                                        ? graphCount.pass
                                        : 0 + graphCount.failed
                                        ? graphCount.failed
                                        : 0 + graphCount.untested
                                        ? graphCount.untested
                                        : 0}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Manual testcase</td>
                                    <td>mahesh</td>
                                  </tr>
                                  <tr>
                                    <td>Automated testcases</td>
                                    <td>mahesh</td>
                                  </tr>
                                  <tr>
                                    <td>Manual execution</td>
                                    <td>mahesh</td>
                                  </tr>
                                  <tr>
                                    <td>Automation execution</td>
                                    <td>mahesh</td>
                                  </tr>
                                  <tr>
                                    <td>Manual sucess rate %</td>
                                    <td>mahesh</td>
                                  </tr>
                                  <tr>
                                    <td>Automation success rate %</td>
                                    <td>mahesh</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        
      </>
    );
  }
  renderchart() {
    const { graphCount } = this.state;
    const data = {
      labels: ["Pass", "Failed", "Untested"],
      datasets: [
        {
          data: [
            graphCount.pass ? graphCount.pass : 0,
            graphCount.failed ? graphCount.failed : 0,
            graphCount.untested ? graphCount.untested : 0,
          ],
          backgroundColor: ["#00C865", "#FF4440", "#FF9A28"],
          hoverBackgroundColor: ["#00C865", "#FF4440", "#FF9A28"],
        },
      ],
    };

    const option = {
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var dataset = data.datasets[tooltipItem.datasetIndex];
            var meta = dataset._meta[Object.keys(dataset._meta)[0]];
            var total = meta.total;
            var currentValue = dataset.data[tooltipItem.index];
            var percentage = parseFloat(
              ((currentValue / total) * 100).toFixed(1)
            );
            return currentValue + " (" + percentage + "%)";
          },
          title: function (tooltipItem, data) {
            return data.labels[tooltipItem[0].index];
          },
        },
      },
    };

    return (
      <div className="card m-b-20 !h-[20rem] " >
        <div className="card-body !py-3 !border-[#E5E5E5]">
          <div className="row">
            <div className="col-sm-10">
              <AUX>
                <div className="test-result-piechart !h-[20rem]">
                  <Pie
                    width="auto"
                    height="auto"
                    data={data}
                    options={option}
                  />
                </div>
              </AUX>
            </div>
            <div className=" flex flex-col justify-start items-center !mt-[7rem] !ml-[-12rem]">
              <ul className="list-inline text-center !text-black">
                <li className="list-inline-item d-block text-left">
                  <p className="!text-black font-roboto !font-medium font-14 d-inline-block mr-2">
                    Total testcases:
                  </p>
                  <h4 className=" !text-black font-roboto !font-medium d-inline-block font-14">
                    {(graphCount.pass ? graphCount.pass : 0) +
                      (graphCount.failed ? graphCount.failed : 0) +
                      (graphCount.untested ? graphCount.untested : 0)}
                  </h4>
                </li>
                <li className="list-inline-item font-roboto !font-normal d-block text-left">
                <svg className="d-inline-block mr-2 " width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.497284" y="0.220642" width="12" height="12" fill="#00C865"/>
</svg>
                  <p className="!text-black font-14 mr-2 d-inline-block">
                    Pass:
                  </p>
                  <h4 className="d-inline-block font-14">
                    {graphCount.pass ? graphCount.pass : 0}
                  </h4>
                </li>
                <li className="list-inline-item font-roboto !font-normal d-block text-left">
                <svg className="d-inline-block mr-2 " width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.497284" y="0.220642" width="12" height="12" fill="#FF4440"/>
</svg>
                  <p className="!text-black font-14 mr-2 d-inline-block">
                    Failed:
                  </p>
                  <h4 className="d-inline-block font-14">
                    {graphCount.failed ? graphCount.failed : 0}
                  </h4>
                </li>
                <li className="list-inline-item font-roboto !font-normal d-block text-left">
                <svg className="d-inline-block mr-2 " width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.497284" y="0.220642" width="12" height="12" fill="#FF9A28"/>
</svg>
                  <p className="!text-black font-14 mr-2 d-inline-block">
                    Untested:
                  </p>
                  <h4 className="d-inline-block font-14">
                    {graphCount.untested ? graphCount.untested : 0}
                  </h4>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const IssuesTab = ({ resultId }) => {
  const wrapperRef = React.createRef();
  const [search, setSearch] = useState("");
  const [issuesData, setIssuesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const columnsStructure = [
    {
      field: "issue_id",
      headerName: "ID",
      width: 120,
      renderCell: (row) => (
        <p classname=" !text-[#64748B] monospace" style={{ color: "#64748B" }}>
          {row.issue_id}
        </p>
      ),
    },
    {
      field: "issue_name",
      headerName: "Issue Name",
      width: 300,
      enableSorting: false,
    },
    {
      field: "sub_id",
      headerName: "Test Case ID",
      width: 150,
      enableSorting: false,
    },

    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (row) => (
        <div className="flex items-center ">
          {(row.status === "OPEN" || row.status === "DONE") && (
            <span className="w-16 h-8 flex items-center justify-center text-primary-green bg-[#EBFFF4] px-2 py-1 rounded-md !font-[500]">
              Open
            </span>
          )}
          {row.status !== "OPEN" && (
            <span className=" bg-gray-100 px-2 py-1 rounded-md text-[12px] font-[400] font-poppins w-16 h-8 flex items-center justify-center opacity-70">
              {row.status ? "Closed" : "Unknown"}
            </span>
          )}
        </div>
      ),
    },
    {
      field: "createdBy",
      headerName: "Created By",
      width: 140,
      renderCell: (row) => (
        <div className="flex flex-row gap-x-2 items-center">
          <img
            // src={`https://api.dicebear.com/5.x/initials/svg?seed=${Promises.getName()}`}
            src={
              row.creator_img_url ||
              process.env.PUBLIC_URL + "/assets/images/default-img.png"
            }
            alt="user"
            className="rounded-circle w-6 h-6"
          />
          <p className="capitalize">{row.created_by_user_name}</p>
        </div>
      ),
    },
    {
      field: "created_at",
      headerName: "Created On",
      enableSorting: true,
      width: 180,
      // renderCell: (row) => {
      //   const date = new Date(row.createdAt);
      //   return formatDate(date);
      // },
    },

    {
      field: "actions",
      headerName: "",
      width: 170,
      renderCell: (row) => (
        <a
          target="_blank"
          rel="noreferrer"
          href={row.link}
          className="text-primary-blue flex items-center gap-x-3"
        >
          View Status{" "}
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 9.68978L9.33333 1.35645M9.33333 1.35645H3M9.33333 1.35645V7.35645"
              stroke="#316BFF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </a>
      ),
    },
  ];

  useEffect(() => {
    getIssues();
  }, [resultId]);

  const getIssues = async () => {
    setLoading(true);
    const token = Promises.getToken();

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    // console.log(requestOptions);

    fetch(
      `${BASE_URL}projects/integration/result/issues/${resultId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("FETCHING RESULT ===========>", result);
        if (result.code === 401) {
          setLoading(false);
          localStorage.clear();
          history.push("/login?redirect=1");
        }

        const data = result.issues;
        setIssuesData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
        setIssuesData([]);
      });
  };

  return (
    <div className=" w-full h-full">
      <div className="pt-2 pb-3 w-full ">
        <p className="primary_heading !text-[20px]">Project Issues</p>
        <div className="flex items-center justify-between mt-3 w-full">
          <div
            className="w-full"
            style={{
              display: "flex",
            }}
          >
            <div className="flex flex-row items-center justify-between !w-full mb-3">
              <div className="flex flex-row items-center gap-x-3">
                <div
                  className="flex items-center !p-0 !m-0"
                  style={{
                    position: "relative",
                  }}
                >
                  <input
                    autoComplete="off"
                    type="text"
                    className="form-control !placeholder-[#316BFF]"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    style={{
                      border: "0.3px solid #316BFF",
                      borderRadius: 8,
                      height: 44,
                      fontSize: 12,
                      width: 260,
                      background: "rgba(146, 198, 255, 0.1)",
                    }}
                  />
                  <span
                    style={{
                      position: "absolute",
                      color: "#316BFF",
                      top: 18,
                      right: 12,
                    }}
                  >
                    <IoSearchOutline />
                  </span>
                </div>
              </div>
              <div className="flex flex-row gap-x-2 itmes-center justify-center">
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <button
                    type="button"
                    ref={wrapperRef}
                    onClick={() => {
                      // this.setState({
                      //   showFilter: !this.state.showFilter,
                      // })
                    }}
                    style={{
                      position: "relative",
                      width: 110,
                      background: "rgba(146, 198, 255, 0.1)",
                      borderRadius: 8,
                      height: 40,
                    }}
                    className="px-3 border rounded-md !flex !flex-row !items-center !justify-center gap-x-3 !text-[#316BFF] !border-[#316BFF] text-[12px] !font-[300] "
                  >
                    <svg
                      width="11"
                      height="10"
                      viewBox="0 0 11 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.15 4.11744C7.56459 4.11737 7.9681 3.97419 8.30054 3.70918C8.63299 3.44417 8.87663 3.07149 8.99525 2.64651H10.45C10.5959 2.64651 10.7358 2.58452 10.8389 2.47418C10.9421 2.36384 11 2.21418 11 2.05814C11 1.90209 10.9421 1.75244 10.8389 1.6421C10.7358 1.53175 10.5959 1.46977 10.45 1.46977H8.99525C8.87643 1.04501 8.63271 0.672578 8.30028 0.407795C7.96785 0.143012 7.56445 0 7.15 0C6.73555 0 6.33215 0.143012 5.99972 0.407795C5.66729 0.672578 5.42357 1.04501 5.30475 1.46977H0.55C0.404131 1.46977 0.264236 1.53175 0.161091 1.6421C0.0579462 1.75244 0 1.90209 0 2.05814C0 2.21418 0.0579462 2.36384 0.161091 2.47418C0.264236 2.58452 0.404131 2.64651 0.55 2.64651H5.30475C5.42337 3.07149 5.66701 3.44417 5.99946 3.70918C6.3319 3.97419 6.73541 4.11737 7.15 4.11744ZM0.55 7.35349C0.404131 7.35349 0.264236 7.41548 0.161091 7.52582C0.0579462 7.63616 0 7.78582 0 7.94186C0 8.09791 0.0579462 8.24756 0.161091 8.35791C0.264236 8.46825 0.404131 8.53023 0.55 8.53023H1.72975C1.84857 8.95499 2.09229 9.32742 2.42472 9.59221C2.75715 9.85699 3.16055 10 3.575 10C3.98945 10 4.39285 9.85699 4.72528 9.59221C5.05771 9.32742 5.30143 8.95499 5.42025 8.53023H10.45C10.5959 8.53023 10.7358 8.46825 10.8389 8.35791C10.9421 8.24756 11 8.09791 11 7.94186C11 7.78582 10.9421 7.63616 10.8389 7.52582C10.7358 7.41548 10.5959 7.35349 10.45 7.35349H5.42025C5.30143 6.92873 5.05771 6.5563 4.72528 6.29152C4.39285 6.02674 3.98945 5.88372 3.575 5.88372C3.16055 5.88372 2.75715 6.02674 2.42472 6.29152C2.09229 6.5563 1.84857 6.92873 1.72975 7.35349H0.55Z"
                        fill="#316BFF"
                      />
                    </svg>
                    Filter
                  </button>
                  {false && (
                    <div className="filter-dashboard">
                      <h5
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          padding: 16,
                          borderBottom: "1px solid #ECECEC",
                        }}
                      >
                        Select filter
                      </h5>
                      <div class="filter-div-properties">
                        <div className="filter-properties">
                          <p>Type</p>
                          <div className="d-flex flex-column">
                            <label htmlFor="automatedFilter">
                              <input
                                className="typeFilter"
                                type="checkbox"
                                value="Automated"
                                // checked={this.state.filterProps.includes(
                                //   "automated"
                                // )}
                                id="automatedFilter"
                                onChange={(e) =>
                                  this.onFilteredPropsItems(e.target.value)
                                }
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />
                              Automated
                            </label>

                            <label htmlFor="manualFilter">
                              <input
                                className="typeFilter"
                                type="checkbox"
                                value="Manual"
                                // checked={this.state.filterProps.includes(
                                //   "manual"
                                // )}
                                id="manualFilter"
                                onChange={(e) =>
                                  this.onFilteredPropsItems(e.target.value)
                                }
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Manual
                            </label>
                          </div>
                        </div>
                        <div className="filter-properties">
                          <p>Priority</p>
                          <div className="d-flex flex-column">
                            <label htmlFor="highFilter">
                              <input
                                className="priorityFilter"
                                type="checkbox"
                                value="High"
                                // checked={this.state.filterProps.includes(
                                //   "high"
                                // )}
                                id="highFilter"
                                // onChange={(e) =>
                                //   this.onFilteredPropsItems(
                                //     e.target.value
                                //   )
                                // }
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />
                              High
                            </label>
                            <label htmlFor="mediumFilter">
                              <input
                                className="priorityFilter"
                                type="checkbox"
                                value="Medium"
                                // checked={this.state.filterProps.includes(
                                //   "medium"
                                // )}
                                id="mediumFilter"
                                // onChange={(e) =>
                                //   this.onFilteredPropsItems(
                                //     e.target.value
                                //   )
                                // }
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Medium
                            </label>
                            <label htmlFor="lowFilter">
                              <input
                                className="priorityFilter"
                                type="checkbox"
                                value="Low"
                                // checked={this.state.filterProps.includes(
                                //   "low"
                                // )}
                                id="lowFilter"
                                // onChange={(e) =>
                                //   this.onFilteredPropsItems(
                                //     e.target.value
                                //   )
                                // }
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Low
                            </label>
                          </div>
                        </div>
                        <div className="filter-properties">
                          <p>Test Type</p>
                          <div className="d-flex flex-column">
                            <label htmlFor="functionalFilter">
                              <input
                                className="typeFilter"
                                type="checkbox"
                                value="Functional"
                                // checked={this.state.filterProps.includes(
                                //   "functional"
                                // )}
                                id="functionalFilter"
                                // onChange={(e) =>
                                //   this.onFilteredPropsItems(
                                //     e.target.value
                                //   )
                                // }
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />
                              Functional
                            </label>

                            <label htmlFor="unitFilter">
                              <input
                                className="typeFilter"
                                type="checkbox"
                                value="Unit"
                                // checked={this.state.filterProps.includes(
                                //   "unit"
                                // )}
                                id="unitFilter"
                                // onChange={(e) =>
                                //   this.onFilteredPropsItems(
                                //     e.target.value
                                //   )
                                // }
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Unit
                            </label>

                            <label htmlFor="securityFilter">
                              <input
                                className="typeFilter"
                                type="checkbox"
                                value="Security"
                                // checked={this.state.filterProps.includes(
                                //   "security"
                                // )}
                                id="securityFilter"
                                // onChange={(e) =>
                                //   this.onFilteredPropsItems(
                                //     e.target.value
                                //   )
                                // }
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Security
                            </label>

                            <label htmlFor="integrationFilter">
                              <input
                                className="typeFilter"
                                type="checkbox"
                                value="Integration"
                                // checked={this.state.filterProps.includes(
                                //   "integration"
                                // )}
                                id="integrationFilter"
                                // onChange={(e) =>
                                //   this.onFilteredPropsItems(
                                //     e.target.value
                                //   )
                                // }
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Integration
                            </label>

                            <label htmlFor="systemFilter">
                              <input
                                className="typeFilter"
                                type="checkbox"
                                value="System"
                                // checked={this.state.filterProps.includes(
                                //   "system"
                                // )}
                                id="systemFilter"
                                // onChange={(e) =>
                                //   this.onFilteredPropsItems(
                                //     e.target.value
                                //   )
                                // }
                                // checked={ele.isChecked}
                                // style={{
                                //   height: 16,
                                //   width: 16,
                                //   marginRight: 8,
                                // }}
                              />{" "}
                              System
                            </label>

                            <label htmlFor="acceptanceFilter">
                              <input
                                className="typeFilter"
                                type="checkbox"
                                value="Acceptance"
                                id="acceptanceFilter"
                                // checked={this.state.filterProps.includes(
                                //   "acceptance"
                                // )}
                                // onChange={(e) =>
                                //   this.onFilteredPropsItems(
                                //     e.target.value
                                //   )
                                // }
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Acceptance
                            </label>
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        class="btn btn-primary filter-btn"
                        // onClick={() =>
                        //   this.setState({ filterProps: [] }, () =>
                        //     console.log(this.state.filterProps)
                        //   )
                        // }
                      >
                        Clear
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Table
        columns={columnsStructure}
        loading={loading}
        search={search.length ? true : false}
        data={
          issuesData
            ? issuesData.filter(
                (item) =>
                  item.issue_id.toLowerCase().includes(search.toLowerCase()) ||
                item.sub_id.toLowerCase().includes(search.toLowerCase()) || item.issue_name
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                  item.created_by_user_name
                    .toLowerCase()
                    .includes(search.toLowerCase())
              )
            : []
        }
      /> 

      {/* <Table
        columns={sampleColumns}
        data={
          sampleData.filter(
            (item) =>
              item.id.toLowerCase().includes(search.toLowerCase()) ||
              item.label.toLowerCase().includes(search.toLowerCase()) ||
              item.createdBy.toLowerCase().includes(search.toLowerCase())
          ) || []
        }
        search={search.length?true:false}
      /> */}
    </div>
  );
};

export default TestResult;