let BASE_URL;
let recaptha_siteKey;
const ENV = process.env.REACT_APP_ENV;
console.log("Started in ", ENV);

if (ENV === "QA") {
  recaptha_siteKey = "6LcjEAcqAAAAAO59PHC3lVb4mWHPjJykzIh7aQq3";
  BASE_URL = "https://api.joslery.com/api/";
} else if (ENV === "LOCAL") {
  BASE_URL = "http://localhost:3000/api/";
  recaptha_siteKey = "6LcjEAcqAAAAAO59PHC3lVb4mWHPjJykzIh7aQq3";
} else {
  recaptha_siteKey = "6Lc5pyIqAAAAANXU5BoB8eZ3IZQ-s1qsSRZuZ7x7";
  BASE_URL = "https://api.oyetest.com/api/";
}
export { BASE_URL, recaptha_siteKey };

export const GOOGLE_CLIENTID =
  "796074674147-bu1oat7714eglvljkdpo4hfmcghd1a8n.apps.googleusercontent.com";
export const LOGO_URL = "/assets/images/new_logo.png";
export const LOGO_FULL_URL = "/assets/images/new_logo_full.png";
export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASS_REGEX = new RegExp(
  "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
);

export const COLORS = [
  "#F17F42",
  "#9055A2",
  "#D81159",
  "#3F4B3B",
  "#49010F",
  "#353866",
  "#7200da",
  "#282c37",
  "#008c9e",
  "#ee2560",
];

export const landingNavOptions = [
  { label: "Pricing", path: "/pricing", local: true },
  { label: "API", path: `https://api.oyetest.com/automation/docs` },
  { label: "Docs", path: `/docs` },
];

export const leadersHardcoded = [
  {
    _createdAt: "2024-08-28T12:59:43Z",
    _rev: "PN6KEjm6FVFefqKajsCUcH",
    _type: "leaders",
    name: "Coinbase",
    _id: "6d97801f-5a38-4637-afec-1800e6799c7b",
    _updatedAt: "2024-08-28T13:01:35Z",
    logoUrl: {
      asset: {
        _ref: "image-87f89eec32e7b9f9e73628c0c58af37a1ac0df04-2560x458-png",
        _type: "reference",
      },
      _type: "image",
    },
  },
  {
    _type: "leaders",
    name: "Evernote",
    _id: "7695dc6e-87a4-4656-b547-d51ca2cdb00a",
    _updatedAt: "2024-08-28T12:58:30Z",
    logoUrl: {
      _type: "image",
      asset: {
        _ref: "image-6ea2416120469891cc7c710dc87c22924213d923-410x120-png",
        _type: "reference",
      },
    },
    _createdAt: "2024-08-28T12:58:04Z",
    _rev: "PN6KEjm6FVFefqKajs9CiL",
  },
  {
    _type: "leaders",
    name: "Flipkart",
    _id: "9e232015-aeee-4815-85f1-ef792f100234",
    _updatedAt: "2024-08-28T13:04:26Z",
    logoUrl: {
      _type: "image",
      asset: {
        _ref: "image-926a175fe0fdb5020016776db3ba20387405f922-297x76-png",
        _type: "reference",
      },
    },
    _createdAt: "2024-08-28T13:04:26Z",
    _rev: "PN6KEjm6FVFefqKajsI70H",
  },
  {
    _createdAt: "2024-08-28T13:02:03Z",
    _rev: "B9fZzXRJz3418ogHO9f1Lo",
    _type: "leaders",
    name: "ebay",
    _id: "bc953b9c-e5e0-4337-bab6-2c5188b88d3a",
    _updatedAt: "2024-08-28T13:02:03Z",
    logoUrl: {
      _type: "image",
      asset: {
        _ref: "image-0c27e709b67758f4878d631610a9d862adf3866e-240x96-png",
        _type: "reference",
      },
    },
  },
  {
    logoUrl: {
      _type: "image",
      asset: {
        _ref: "image-78b7f7478fe514c7b660fdfa526eb17fdf88add8-320x120-png",
        _type: "reference",
      },
    },
    _createdAt: "2024-08-28T12:58:51Z",
    _rev: "PN6KEjm6FVFefqKajs9DbB",
    _type: "leaders",
    name: "AirBnb",
    _id: "c88fbe44-33d4-45f9-8d0c-3a562762e502",
    _updatedAt: "2024-08-28T12:59:21Z",
  },
];

export const powerfulFeaturesHarcoded = [
  {
    description:
      "Want to see our platform in action? Explore our latest features that streamline test management and enhance collaboration.",
    _updatedAt: "2024-08-28T06:55:45Z",
    highlightedWordEndIndex: 2,
    _rev: "rMINBIXpTZF4iiuRpdZ996",
    _type: "powerfulFeatures",
    _id: "33569576-fe92-4422-86bd-8aaedc230a02",
    label: "Powerful Features  at Your Fingertips",
    _createdAt: "2024-08-28T06:55:31Z",
    highlightedWordStartIndex: 1,
  },
  {
    _id: "80f53985-b682-4616-bb4c-69650a73fcbe",
    _createdAt: "2024-08-28T06:57:22Z",
    highlightedWordStartIndex: 4,
    _type: "powerfulFeatures",
    label: "User-friendly & Powerful  Replacement for Spreadsheet",
    tagPhrase: "Easy Interaction",
    _updatedAt: "2024-08-28T06:57:33Z",
    highlightedWordEndIndex: 5,
    imgUrl: {
      _type: "image",
      asset: {
        _type: "reference",
        _ref: "image-d112b7b7f73e1d91b458ba65c73b29941134f8a0-888x856-png",
      },
    },
    _rev: "rMINBIXpTZF4iiuRpdZ9K6",
  },
  {
    tagPhrase: "Collaborative Sharing",
    _updatedAt: "2024-08-28T06:59:48Z",
    highlightedWordEndIndex: 7,
    highlightedWordStartIndex: 6,
    _id: "8cca542b-33d3-4729-8724-52dfdf48bc0b",
    _rev: "B9fZzXRJz3418ogHO6jMEu",
    _type: "powerfulFeatures",
    label: "Share test plans & results with your team effortlessly",
    bgUrl: {
      _type: "image",
      asset: {
        _ref: "image-b984871860f98d1addd420f1afb3c9ca85e6c611-4049x2800-png",
        _type: "reference",
      },
    },
    imgUrl: {
      _type: "image",
      asset: {
        _ref: "image-e7539fda05df69b00d3ceb7d88956c3059fe7729-617x364-png",
        _type: "reference",
      },
    },
    _createdAt: "2024-08-28T06:59:34Z",
  },
  {
    label: "Merge separate test cases and results into one unified project",
    tagPhrase: "Combine & SYNC",
    _type: "powerfulFeatures",
    _id: "7d3b061d-c5a6-45d6-8084-981aec66f121",
    highlightedWordStartIndex: 7,
    description: " ",
    _updatedAt: "2024-08-28T07:03:17Z",
    featuresArray: [
      "Integrate",
      "Unify",
      "Synchronize",
      "Seamless",
      "Cohesive",
      "Efficient",
    ],
    highlightedWordEndIndex: 9,
    _createdAt: "2024-08-28T07:03:04Z",
    _rev: "PN6KEjm6FVFefqKajpcboj",
  },
  {
    label: "Reduce friction  between web & app",
    tagPhrase: "Cross-Platform Efficient",
    _updatedAt: "2024-08-28T07:05:26Z",
    highlightedWordEndIndex: 5,
    imgUrl: {
      _type: "image",
      asset: {
        _ref: "image-2eea8b9785ba860a0c666c24c493a4a5c50e8c78-730x456-png",
        _type: "reference",
      },
    },
    _createdAt: "2024-08-28T07:05:17Z",
    _type: "powerfulFeatures",
    highlightedWordStartIndex: 3,
    _rev: "PN6KEjm6FVFefqKajpcigX",
    _id: "9cb38937-9bc2-4144-a7ec-7e577eed8503",
  },
  {
    _type: "powerfulFeatures",
    highlightedWordStartIndex: 7,
    label: "Instantly Generate  Accurate & Reliable Test  Cases using AI.",
    _updatedAt: "2024-08-28T07:08:05Z",
    highlightedWordEndIndex: 8,
    _createdAt: "2024-08-28T07:07:47Z",
    _rev: "rMINBIXpTZF4iiuRpdb916",
    _id: "62365615-0c83-4dc8-964d-5fc4acb3aad2",
    tagPhrase: "AI-POWERED",
    imgUrl: {
      _type: "image",
      asset: {
        _ref: "image-06abf7d73b714c2008b0fa05a59ebeb9c8234d5b-716x508-png",
        _type: "reference",
      },
    },
  },
];

export const salientFeaturesHardcoded = [
  {
    _rev: "PN6KEjm6FVFefqKajpMJFt",
    _type: "salientFeatures",
    description:
      "Replace spreadsheet with a user-friendly platform to create, manage, and track test cases.",
    _id: "750a6fa9-3d11-4f54-816b-0e329b990c79",
    label: "Simple & User friendly",
    svgIcon:
      '<svg width="33" height="29" viewBox="0 0 33 29" fill="none" xmlns="http://www.w3.org/2000/svg">\n<path d="M25.6696 18.2752C25.2552 18.6481 25.0648 19.1874 25.1592 19.7163L26.5816 27.0259C26.7016 27.6454 26.42 28.2724 25.8616 28.6305C25.3144 29.0019 24.5864 29.0464 23.9896 28.7493L16.9032 25.3174C16.6568 25.1955 16.3832 25.1302 16.1032 25.1228H15.6696C15.5192 25.1436 15.372 25.1881 15.2376 25.2565L8.1496 28.7047C7.7992 28.8682 7.4024 28.9261 7.0136 28.8682C6.0664 28.7018 5.4344 27.8638 5.5896 26.9799L7.0136 19.6703C7.108 19.1369 6.9176 18.5946 6.5032 18.2158L0.725603 13.0159C0.242403 12.5806 0.0744031 11.9269 0.295203 11.3371C0.509603 10.7487 1.0568 10.3194 1.7176 10.2228L9.6696 9.15163C10.2744 9.09369 10.8056 8.75198 11.0776 8.24684L14.5816 1.57611C14.6648 1.42755 14.772 1.29086 14.9016 1.17498L15.0456 1.07098C15.1208 0.993725 15.2072 0.929841 15.3032 0.877842L15.4776 0.818415L15.7496 0.714417H16.4232C17.0248 0.772358 17.5544 1.10664 17.8312 1.60583L21.3816 8.24684C21.6376 8.73266 22.1352 9.06991 22.7096 9.15163L30.6616 10.2228C31.3336 10.3119 31.8952 10.7428 32.1176 11.3371C32.3272 11.9328 32.1464 12.5865 31.6536 13.0159L25.6696 18.2752Z" fill="#27AE60"/>\n</svg>\n',
    _updatedAt: "2024-08-28T05:42:06Z",
    _createdAt: "2024-08-28T05:40:14Z",
  },
  {
    _createdAt: "2024-08-28T05:42:29Z",
    _rev: "PN6KEjm6FVFefqKajpMJSn",
    _type: "salientFeatures",
    description:
      "Share test cases, plans, and results instantly to keep your team in sync.",
    _id: "753da284-baa1-485a-a3a2-658159daadd2",
    label: "Collaborative",
    svgIcon:
      '<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">\n<path d="M27.3438 18.5938C25.8967 18.5938 24.6225 19.3058 23.8263 20.3886L18.6277 17.5L23.8263 14.6114C24.6225 15.6942 25.8956 16.4062 27.3438 16.4062C29.7598 16.4062 31.7188 14.4473 31.7188 12.0312C31.7188 9.61516 29.7598 7.65625 27.3438 7.65625C24.9277 7.65625 22.9688 9.61516 22.9688 12.0312C22.9688 12.6066 23.0869 13.1534 23.2881 13.6577L18.0469 16.5703V10.8828C20.2016 10.6105 21.875 8.79156 21.875 6.5625C21.875 4.14641 19.9161 2.1875 17.5 2.1875C15.0839 2.1875 13.125 4.14641 13.125 6.5625C13.125 8.79156 14.7984 10.6105 16.9531 10.8828V16.5703L11.7108 13.6577C11.9131 13.1534 12.0312 12.6066 12.0312 12.0312C12.0312 9.61516 10.0723 7.65625 7.65625 7.65625C5.24016 7.65625 3.28125 9.61516 3.28125 12.0312C3.28125 14.4473 5.24016 16.4062 7.65625 16.4062C9.10437 16.4062 10.3775 15.6942 11.1737 14.6114L16.3723 17.5L11.1727 20.3886C10.3775 19.3058 9.10437 18.5938 7.65625 18.5938C5.24016 18.5938 3.28125 20.5527 3.28125 22.9688C3.28125 25.3848 5.24016 27.3438 7.65625 27.3438C10.0723 27.3438 12.0312 25.3848 12.0312 22.9688C12.0312 22.3934 11.9131 21.8466 11.7108 21.3423L16.9531 18.4297V24.1172C14.7984 24.3895 13.125 26.2084 13.125 28.4375C13.125 30.8536 15.0839 32.8125 17.5 32.8125C19.9161 32.8125 21.875 30.8536 21.875 28.4375C21.875 26.2084 20.2016 24.3895 18.0469 24.1172V18.4297L23.2892 21.3423C23.0869 21.8455 22.9688 22.3923 22.9688 22.9688C22.9688 25.3848 24.9277 27.3438 27.3438 27.3438C29.7598 27.3438 31.7188 25.3848 31.7188 22.9688C31.7188 20.5527 29.7598 18.5938 27.3438 18.5938Z" fill="#E3F0FA"/>\n</svg>\n',
    _updatedAt: "2024-08-28T05:42:29Z",
  },
  {
    _type: "salientFeatures",
    description:
      "Track test results and progress in real time using our advanced analytics.",
    _id: "ad8d9daa-19d5-4c5b-a590-9af8fe85ffcf",
    label: "Powerful Analytics",
    svgIcon:
      '<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">\n<path d="M11.6667 1.66675H18.3333V23.3334H11.6667V1.66675ZM8.33333 8.3334H1.66667V23.3334H8.33333V8.3334ZM30 26.6668H0V28.5001H30V26.6668ZM28.3333 11.6667H21.6667V23.3334H28.3333V11.6667Z" fill="#27AE60"/>\n</svg>\n',
    _updatedAt: "2024-08-28T05:43:20Z",
    _createdAt: "2024-08-28T05:43:20Z",
    _rev: "rMINBIXpTZF4iiuRpdXS46",
  },
];

export const middleInfoHardcoded = {
  infoLink: "/",
  _rev: "PN6KEjm6FVFefqKajpXU1V",
  label: "You do the coding, we’ll manage the testing",
  highlightedWordStartIndex: 6,
  _updatedAt: "2024-08-28T06:54:23Z",
  _createdAt: "2024-08-28T06:51:57Z",
  _id: "274478fa-17a7-4753-9c96-7f5dea73ff7f",
  ctaLink: "https://oyetest.com/register",
  highlightedWordEndIndex: 8,
  infoText: "Know more",
  ctaText: "Sign up now",
  _type: "middleInfo",
  description:
    "Our platform takes the hassle out of testing with easy-to-use features and smart AI that automatically creates test cases for you. Share test plans and results effortlessly to keep everyone on the same page, and enjoy a smooth, intuitive experience that saves you time.",
};

export const aiFeaturesHardcoded = [
  {
    highlightedWord: 1,
    infoType: true,
    _createdAt: "2024-08-27T14:47:10Z",
    _id: "1dabe2db-1965-4171-92b5-ae6587bb8cfd",
    ctaLink: "https://oyetest.com/register",
    featuresArray: [],
    _type: "aiFeatures",
    description:
      "Let our AI automatically create detailed test cases from your project documents. Save time and ensure thorough testing for quicker, higher-quality software delivery.",
    label: "Introducing AI-powered Testing",
    imgUrl: {
      _type: "image",
      asset: {
        _ref: "image-a9ebe84a74e0652283299d238c274f2dfad22157-389x425-png",
        _type: "reference",
      },
    },
    _updatedAt: "2024-08-27T14:53:28Z",
    ctaText: "Get started",
    _rev: "PN6KEjm6FVFefqKajm8pVt",
  },
  {
    ctaText: "Try Now",
    _type: "aiFeatures",
    label: "Your Partner in Smarter Testing",
    imgUrl: {
      asset: {
        _ref: "image-8f4a7e915b199c9428d73a4f949a2f249f5502e2-651x722-png",
        _type: "reference",
      },
      _type: "image",
    },
    _createdAt: "2024-08-27T14:54:55Z",
    ctaLink: "https://oyetest.com/register",
    _rev: "B9fZzXRJz3418ogHO3BG5D",
    description: "",
    highlightedWord: 3,
    infoType: false,
    _id: "a337f7ea-a6a7-4029-849b-6e85f8ca5706",
    _updatedAt: "2024-08-27T14:55:38Z",
    featuresArray: [
      "Automated Test Case Creation",
      "Enhanced Test Coverage",
      "Time-Saving Testing",
    ],
  },
];

export const integrationsHardcoded = [
  {
    _type: "integrations",
    name: "Jira",
    _id: "efa262e4-3a51-4afc-b6c4-9558f427af56",
    _updatedAt: "2024-08-27T14:19:10Z",
    logoUrl: {
      _type: "image",
      asset: {
        _type: "reference",
        _ref: "image-621c1f2c663c4a5045fd5f19803abec1c52e2458-80x77-png",
      },
    },
    _createdAt: "2024-08-27T14:15:57Z",
    _rev: "PN6KEjm6FVFefqKajm761N",
  },
  {
    _type: "integrations",
    name: "Github",
    _id: "af54009b-814f-4d76-b84a-d5e64762989f",
    _updatedAt: "2024-08-27T14:17:13Z",
    logoUrl: {
      _type: "image",
      asset: {
        _ref: "image-1922e7a7246fceba4eafeeb51c6b6380fb0c8e83-92x93-png",
        _type: "reference",
      },
    },
    _createdAt: "2024-08-27T14:17:13Z",
    _rev: "B9fZzXRJz3418ogHO397yU",
  },
  {
    name: "Atlassian",
    _id: "75e71897-1db7-49c1-adc0-40411e5cca41",
    _updatedAt: "2024-09-02T10:15:11Z",
    logoUrl: {
      asset: {
        _ref: "image-50013e36abe0639d659c248f203339bf7941a73e-179x168-png",
        _type: "reference",
      },
      _type: "image",
    },
    _createdAt: "2024-08-27T14:18:07Z",
    _rev: "GRKtBVn5ea31gvlmINmHOC",
    _type: "integrations",
  },
  {
    name: "Asana",
    _id: "6347176d-9c35-4877-aff2-2ce7aa09b67f",
    _updatedAt: "2024-08-27T14:21:45Z",
    logoUrl: {
      _type: "image",
      asset: {
        _ref: "image-3064e1ce5164df39cb807fe58e6a4763d4ce4ec0-90x91-png",
        _type: "reference",
      },
    },
    _createdAt: "2024-08-27T14:19:22Z",
    _rev: "B9fZzXRJz3418ogHO39L1l",
    _type: "integrations",
  },
  {
    _updatedAt: "2024-08-27T14:20:02Z",
    logoUrl: {
      asset: {
        _ref: "image-587bbb3b5f6e6c971692f95f43db9c2fbd7f91e9-96x93-png",
        _type: "reference",
      },
      _type: "image",
    },
    _createdAt: "2024-08-27T14:20:02Z",
    _rev: "PN6KEjm6FVFefqKajm77zx",
    _type: "integrations",
    name: "Figma",
    _id: "d97dc3d0-2e24-4f42-b73e-c9b31d631c1f",
  },
  {
    _type: "integrations",
    name: "Microsoft",
    _id: "08d7c6e0-0486-4224-9912-a8405135f8ab",
    _updatedAt: "2024-08-27T14:20:29Z",
    logoUrl: {
      _type: "image",
      asset: {
        _ref: "image-89a3a48b961411ea52cabc369e1a8fe57bab5292-72x73-png",
        _type: "reference",
      },
    },
    _createdAt: "2024-08-27T14:20:29Z",
    _rev: "rMINBIXpTZF4iiuRpcVxD6",
  },
  {
    _rev: "PN6KEjm6FVFefqKajm7AEf",
    _type: "integrations",
    name: "Firefox",
    _id: "9050c20e-c8b6-46ce-819a-2820d890e2a3",
    _updatedAt: "2024-08-27T14:20:46Z",
    logoUrl: {
      _type: "image",
      asset: {
        _ref: "image-d379e1c79f08a08749f6dee6e5cabb7f972e9fbf-108x109-png",
        _type: "reference",
      },
    },
    _createdAt: "2024-08-27T14:20:46Z",
  },
  {
    _createdAt: "2024-08-27T14:21:18Z",
    _rev: "rMINBIXpTZF4iiuRpcVxW6",
    _type: "integrations",
    name: "MailChimp",
    _id: "cfed325f-8a42-43ed-9df4-f80612ed2ac5",
    _updatedAt: "2024-08-27T14:21:18Z",
    logoUrl: {
      _type: "image",
      asset: {
        _ref: "image-8ec8621412adcc998fa9821a1ae7e6d3b5905291-112x111-png",
        _type: "reference",
      },
    },
  },
];

export const testimonialsHardcoded = [
  {
    imgurl: {
      _type: "image",
      asset: {
        _ref: "image-2111fc9d5af51b3282cbddd6993d9ff28c893306-640x640-png",
        _type: "reference",
      },
    },
    role: "Founder & Leader",
    name: "Rishi Sunak",
    _updatedAt: "2024-08-27T13:29:22Z",
    feedback:
      "Merging test cases from various projects is now effortless. Oyetest's streamlined approach keeps our testing organized.",
    _createdAt: "2024-08-27T13:25:58Z",
    _rev: "B9fZzXRJz3418ogHO2zTBU",
    _type: "testimonials",
    company: "Atlassian",
    _id: "fc51643b-9c7c-420c-999c-d6de7ab349ce",
  },
  {
    role: "Founder & Leader",
    _type: "testimonials",
    company: "Google",
    feedback:
      "Managing test cases has never been easier. The user-friendly interface is a game-changers for our team.",
    _createdAt: "2024-08-27T13:28:25Z",
    _rev: "PN6KEjm6FVFefqKajmPkQ9",
    name: "John Doe",
    _id: "71da8543-af10-42bf-87e4-4c5a03d41fce",
    _updatedAt: "2024-08-27T16:47:14Z",
    imgurl: {
      _type: "image",
      asset: {
        _ref: "image-0425f3ed72048e1315c5e0507940f71fa14f3e7b-1000x1334-png",
        _type: "reference",
      },
    },
  },
  {
    _rev: "rMINBIXpTZF4iiuRpccdL6",
    _type: "testimonials",
    company: "Tata Motors",
    _id: "52be9fd3-ad30-43a8-bf34-d0f03f519e09",
    feedback:
      "Oyetest’s AI-generated test cases have really reduced our workload. It's an invaluable tool for our QA process.",
    imgurl: {
      _type: "image",
      asset: {
        _type: "reference",
        _ref: "image-7f4cf23b23df51e1788b4bf92cf022631ff3373e-678x452-png",
      },
    },
    role: "Founder & Leader",
    _createdAt: "2024-08-27T13:30:56Z",
    _updatedAt: "2024-08-27T16:44:11Z",
    name: "Ratan Tata",
  },
];

export const communtityJoiningHarcoded = {
  ctaUrl: "https://www.oyetest.com/register",
  _createdAt: "2024-08-27T14:01:00Z",
  description:
    "It’s time to transform your test management and experience seamless testing with Oyetest. Start today to streamline your testing process for better team collaboration.",
  _id: "c96991f5-b2a7-4fd9-a96d-a8b3d5386abf",
  ctaText: "Try For Free",
  _rev: "PN6KEjm6FVFefqKajm4Zfp",
  _type: "communityJoining",
  label: "Let’s try our service now!",
  _updatedAt: "2024-08-27T14:02:38Z",
  highlightedWordStartIndex: 2,
  highlightedWordEndIndex: 4,
};

export const socialsHarcoded = [
  {
    link: "https://oyetest.com",
    name: "Instagram",
    enabled: true,
    _type: "socials",
    _rev: "gt9ML6DnrQ0ZcjbxxaUDEJ",
    _id: "d3d445f0-bdb7-4e14-8063-4c03a41f4999",
    _updatedAt: "2024-09-03T05:38:08Z",
    logoUrl: {
      _type: "image",
      asset: {
        _ref: "image-f220bdc9a8095fcd01cd85ce5b99ca7e7dc4e8e2-626x626-png",
        _type: "reference",
      },
    },
    _createdAt: "2024-08-27T13:42:32Z",
  },
  {
    _updatedAt: "2024-09-03T05:38:01Z",
    enabled: true,
    _rev: "gt9ML6DnrQ0ZcjbxxaUCwb",
    _type: "socials",
    name: "Facebook",
    _id: "c8ec2924-8422-4e27-9412-3a99c5928a77",
    logoUrl: {
      _type: "image",
      asset: {
        _ref: "image-47d9d0d0cbe72e7691e2782a7e8802d8cde412e9-256x256-png",
        _type: "reference",
      },
    },
    _createdAt: "2024-08-27T13:43:18Z",
    link: "https://oyetest.com",
  },
  {
    _updatedAt: "2024-09-03T05:37:43Z",
    enabled: true,
    logoUrl: {
      asset: {
        _ref: "image-d22686f152aa18195da8d247cca396115021245c-360x360-png",
        _type: "reference",
      },
      _type: "image",
    },
    _createdAt: "2024-08-27T13:44:05Z",
    _rev: "gt9ML6DnrQ0ZcjbxxaUCW2",
    _type: "socials",
    link: "https://oyetest.com",
    name: "Github",
    _id: "4c8fa7a9-7d59-45f2-bb13-42eae925006f",
  },
  {
    _rev: "gt9ML6DnrQ0ZcjbxxaUCet",
    link: "https://oyetest.com",
    name: "whatsapp",
    _id: "234d4d91-72d2-4698-a4ce-7eff0236e46a",
    logoUrl: {
      _type: "image",
      asset: {
        _ref: "image-4e5a91ddd5c9a2b4a5812badee0edf62bf6dc546-662x664-png",
        _type: "reference",
      },
    },
    _createdAt: "2024-08-27T13:45:01Z",
    _type: "socials",
    _updatedAt: "2024-09-03T05:37:48Z",
    enabled: true,
  },
  {
    _rev: "gt9ML6DnrQ0ZcjbxxaUCnk",
    _type: "socials",
    link: "https://oyetest.com",
    _id: "e0f4d061-b876-43c3-87f7-2691222e5956",
    logoUrl: {
      _type: "image",
      asset: {
        _ref: "image-b70d37f5002eff63c2df3d0a8327cabaaee81979-320x282-png",
        _type: "reference",
      },
    },
    _createdAt: "2024-08-27T13:45:37Z",
    name: "Linkedin",
    _updatedAt: "2024-09-03T05:37:53Z",
    enabled: true,
  },
];

export const usefulLinksHardcoded = [
  { label: "About us", link: "/aboutus" },
  { label: "Documentation", link: "/docs" },
  { label: "Contact us", link: "/contactus" },
];
export const policiesLinkHardcoded = [
  { label: "GDPR", link: "/gdpr" },
  { label: "Terms and Conditions", link: "/terms" },
  { label: "Cookie Policy", link: "/cookie" },
  { label: "Policy", link: "/policy" },
];
export const partnersLinkHardcoded = [
  { label: "Our Partners", link: "/" },
  { label: "Become a Partner", link: "/register" },
];
