import React, { Component } from "react";
import moment from "moment";
import "../styles/component/_testCase.css";
import "../styles/component/mergeProject.css";
import update from "immutability-helper";
import Promises from "../_helpers/utils";
import { BASE_URL } from "../_helpers/constants";
import { NotificationManager } from "react-notifications";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Scrollbars } from "react-custom-scrollbars";
import Modal from "react-modal";
import { confirmAlert } from "react-confirm-alert";
import Select from "react-select";
import TimeAgo from "timeago-react";
import { history } from "../_helpers/history";
import TwoActionPopup from "./TwoActionPopup";
import {
  IoEyeOutline,
  IoSearchOutline,
  IoReaderOutline,
} from "react-icons/io5";
import {
  MdDeleteOutline,
  MdArrowRight,
  MdOutlineArrowDropDown,
} from "react-icons/md";
import { IoIosLink, IoMdAdd } from "react-icons/io";
export default class MergeProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rejectModal: false,
      mergeResultType: 'open',
      showBtns: false,
      filterProps:[],
      rejectMsg: "",
      mergeData: [],
      isSubmit: false,
      selectedOption1: "",
      search: "",
      mergeState: 0,
      allProject: JSON.parse(localStorage.getItem("projects")),
      projectLen: [],
      newList: "",
      fildData: [],
      testCasesData: [],
      savingStatus: "saved",
      testCasesEmpty: false,
      loading: false,
      projectId: "",
      hover: false,
      dynamicStyle: [],
      createModalVisible: false,
      name: "",
      pname: "",
      pdesc: "",
      testType: [
        {
          value: "functional",
          label: "Functional",
        },
        {
          value: "unit",
          label: "Unit",
        },
        {
          value: "security",
          label: "Security",
        },
        {
          value: "integration",
          label: "Integration",
        },
        {
          value: "system",
          label: "System",
        },
        {
          value: "acceptance",
          label: "Acceptance",
        },
      ],
    };
  }

  /*
  .##........#######...######...####..######...######.
  .##.......##.....##.##....##...##..##....##.##....##
  .##.......##.....##.##.........##..##.......##......
  .##.......##.....##.##...####..##..##........######.
  .##.......##.....##.##....##...##..##.............##
  .##.......##.....##.##....##...##..##....##.##....##
  .########..#######...######...####..######...######.
  */

  componentDidMount = () => {
    this.setState(
      {
        projectNameSort: localStorage.getItem("projectName")
          ? localStorage.getItem("projectName").substring(0, 2).toUpperCase()
          : "",
        loading: true,
        projectId: this.props.projectId,
        projectLen: Promises.getLocalIteam("projectLen"),
      },
      () => {
        this.getTestCasesData("initial");
        this.getMergeData();
        const { allProject } = this.state;
        let pros = [];
        allProject.forEach((element) => {
          if (element.id !== localStorage.getItem("projectId")) {
            pros.push({ value: element.id, label: element.name });
          }
        });
        this.setState({ allProject: pros });
      }
    );
  };

  changeIndex = (projectId) => {
    this.setState({
      projectNameSort: localStorage.getItem("projectName")
        ? localStorage.getItem("projectName").substring(0, 2).toUpperCase()
        : "",
    });
    console.log("REFRESHING PROJECT", this.state.testCasesData);
    this.setState(
      {
        testCasesData: [],
        projectId: projectId,
        testCasesEmpty: false,
        loading: true,
      },
      () => {
        this.getTestCasesData("initial");
      }
    );
  };

  onCreate = async (pname, pdesc) => {
    this.props.parentMethod(pname, pdesc);
  };

  getTestCasesData = (type) => {
    console.log("FETCHING DATA 123 ========>");
    const token = Promises.getToken();

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    console.log(requestOptions);

    fetch(`${BASE_URL}projects/task/${this.state.projectId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("FETCHING RESULT12 ===========>", result);
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        if (!result.status) {
          this.setState({ testCasesEmpty: true, loading: false });
        } else {
          console.log(result);
          const data = result.data;

          if (result.field && result.field.length > 0) {
            this.setState({ fildData: result.field });
          }

          if (data.length == 0) {
            this.setState({ testCasesEmpty: true, loading: false });
          } else {
            this.setState(
              { testCasesEmpty: false, testCasesData: data.map((task) => ({
                ...task,
                expanded: true,
              })), loading: false },
              () => {
                if (type == "initial") {
                  const { testCasesData } = this.state;
                  // const obj = { name: '', type: 'input' };
                  const newData = testCasesData;
                  // newData.map((item, index) => {
                  //   const list = item.lists;
                  //   list.push(obj);
                  //   newData[index].lists = list;
                  // })
                  this.setState({ testCasesData: newData });
                }
              }
            );
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ testCasesEmpty: true, loading: false });
        localStorage.clear();
        history.push("/login?redirect=1");
      });
  };
  showMerge = (mid, flag) => {
    this.setState({ loading: true });
    const token = Promises.getToken();

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    console.log(mid);
    fetch(`${BASE_URL}merge/mid/${mid}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("showMerge ===========>", result);
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        if (!result.status) {
          this.setState({ loading: false });
        } else {
          const data = result.data;
          console.log(data[0].merged_data);
          if (data.length === 0) {
            this.setState({ loading: false });
          } else {
            this.setState({
              mergeDataByIdAll: data[0],
              mergeDataById: data[0].merged_data,
              loading: false,
              showBtns: flag,
            });

            this.setMergeState(2);
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ loading: false });
        localStorage.clear();
        history.push("/login?redirect=1");
      });
    console.log(this.state.mergeDataById);
  };
  getMergeData = (pID) => {
    console.log("getMergeData ========>");
    const token = Promises.getToken();
    let projectID = pID ? pID : localStorage.getItem("projectId");

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    console.log("== Project Id ==> " + projectID);

    fetch(`${BASE_URL}merge/${projectID}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("getMergeData ===========>", result);
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        if (!result.status) {
          this.setState({ mergeDataEmpty: true, loading: false });
        } else {
          console.log("== getMergeData ==> " + JSON.stringify(result));
          const data = result.data;

          if (data.length === 0) {
            this.setState({ mergeDataEmpty: true, loading: false });
          } else {
            this.setState({
              mergeDataEmpty: false,
              mergeData: data,
              loading: false,
            });
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ mergeDataEmpty: true, loading: false });
        localStorage.clear();
        history.push("/login?redirect=1");
      });
  };

  onModelUpdate = (e, index) => {
    const { testCasesData } = this.state;
    let newState = update(testCasesData, {
      [index]: {
        model: { $set: e.target.value },
      },
    });
    this.setState({ testCasesData: newState });
  };

  onModelListUpdate = (index, subIndex, val) => {
    const { testCasesData } = this.state;
    console.log(testCasesData[index].model);
    if (testCasesData[index].model != "") {
      let newState = update(testCasesData, {
        [index]: {
          lists: {
            [subIndex]: {
              name: { $set: val.target.value },
            },
          },
        },
      });
      this.setState({ testCasesData: newState });
    } else {
      NotificationManager.error("Please add model name");
    }
  };
  onModelListTestDetailUpdate = (key, index, subIndex, val) => {
    const { testCasesData } = this.state;
    let newState = update(testCasesData, {
      [index]: {
        lists: {
          [subIndex]: {
            [key]: { $set: val.target.value },
          },
        },
      },
    });
    this.setState({ testCasesData: newState });
  };

  deleteModel = (index) => {
    const { testCasesData } = this.state;
    let newState = update(testCasesData, {
      $splice: [[index, 1]],
    });
    this.setState({ testCasesData: newState }, () => {
      this.saveChanges();
    });
  };

  deleteList = (index, i) => {
    this.onClickCloseDetail(index + "-" + i);
    const { testCasesData } = this.state;
    let newState = update(testCasesData, {
      [index]: {
        lists: {
          $splice: [[i, 1]],
        },
      },
    });
    this.setState({ testCasesData: newState }, () => {
      if (testCasesData.length == 0) {
        this.setState({ testCasesEmpty: true });
      }
      this.saveChanges();
    });
  };

  onAdd = (type, index, e) => {
    const { testCasesData } = this.state;
    this.setState({ testCasesEmpty: false });

    const newElement = {
      model: "",
      lists: [{ name: "", status: "untested" }],
    };

    const newList = { name: "", status: "untested" };

    if (type == "model") {
      this.setState({
        testCasesData: update(testCasesData, {
          $push: [newElement],
        }),
      });
    } else {
      this.setState({
        testCasesData: update(testCasesData, {
          [index]: {
            lists: {
              $push: [newList],
            },
          },
        }),
      });
    }
    if (e !== 0) {
      setTimeout(() => {
        this.onModelUpdate(e, index);
      }, 100);
    }
  };

  addNewList = (index, e, innerIndex) => {
    if (e.code == "Enter" && e.target.value.length > 0) {
      console.log(e.target.className);
      // e.target.className += " active";

      const { testCasesData } = this.state;
      const obj = {
        classNameDependsOnCondtion: "new-input-test",
        name: "",
        type: "input",
        status: "untested",
        autoFocus: true,
      };
      this.setState(
        {
          testCasesData: update(testCasesData, {
            [index]: {
              lists: {
                $push: [obj],
                [innerIndex]: {
                  type: { $set: "" },
                },
              },
            },
          }),
        },
        () => {
          this.saveChanges();
        }
      );
    }
  };

  onTestSuitCreate = () => {
    const { createModalVisible, mergeMainId, rejectMsg } = this.state;
    return (
      <Modal
        className="create-modal test-cases-modal reject-modal"
        onRequestClose={() => this.setState({ createModalVisible: false })}
        isOpen={createModalVisible}
      >
        <div className="modal-header clearfix">
          <i
            className="mdi mdi-close"
            onClick={() => this.setState({ createModalVisible: false })}
          ></i>
        </div>

        <label id="label" htmlFor="name">
          Reject reason
        </label>
        <textarea
          placeholder="Type your reason for rejection"
          onChange={(e) => this.setState({ rejectMsg: e.target.value })}
          value={rejectMsg}
        ></textarea>
        <button
          type="button"
          className="btn btn-primary"
          
        >
          Submit
        </button>
      </Modal>
    );
  };
  handleRejectModal = () => {
    this.setState({rejectModal: false});
  }

  getInputData = (e, mainIndex, innderIndex) => {
    const { testCasesData } = this.state;
    this.setState({
      testCasesData: update(testCasesData, {
        [mainIndex]: {
          lists: {
            [innderIndex]: {
              name: { $set: e.target.value },
            },
          },
        },
      }),
    });
  };

  selectCollapse = (ele, index) => {
    const { mergeDataById, testCasesData } = this.state;
    const newData = testCasesData;
    console.log(ele, index, testCasesData);
    newData[index].isCollapse = !ele.isCollapse;

    console.log(ele);
    this.setState({ testCasesData: newData });
  };

  selectCollapseMerge = (ele, index) => {
    const { mergeDataById, testCasesData } = this.state;
    const newData = mergeDataById;
    console.log(ele, index, mergeDataById);
    newData[index].isCollapse = !ele.isCollapse;

    console.log(ele);
    this.setState({ testCasesData: newData });
  };

  onKeyDownfn = (e) => {
    if (e.keyCode == 0 || e.keyCode == 32) {
      this.saveChanges();
    }
  };
  mouseEnter = (e) => {
    this.setState({ hover: true });
  };
  mouseLeave = (e) => {
    this.setState({ hover: false });
  };
  setMergeState = (state) => {
    this.setState({ mergeState: state });
  };
  onClickDetail = (i) => {
    console.log("+++>");
    let { dynamicStyle } = this.state;
    dynamicStyle[i] = "40%";
    this.setState({
      dynamicStyle: dynamicStyle,
    });
  };
  getUsername = (name) => {
    if (name && name !== "") {
      return name;
    } else {
      return localStorage.getItem("name");
    }
  };
  onClickCloseDetail = (i) => {
    this.saveChanges();
    let { dynamicStyle } = this.state;
    dynamicStyle[i] = "0%";
    this.setState({
      dynamicStyle: dynamicStyle,
    });
  };
  handleChange1 = (selectedOption1) => {
    this.setState({ selectedOption1 }, () => {
      if (selectedOption1 && selectedOption1.value != "") {
        this.setState({ isSubmit: true });
      } else {
        this.setState({ isSubmit: false });
      }
    });
  };
  rejectMergeStatus =(textAreaValue) => {
    console.log("id ==>",this.state.mergeMainId, "status ==>",2, "textAreaValue ==>",textAreaValue)
    
    
      this.setState({ rejectModal: false });
   
    this.setState({ loading: false });
    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);
    
    var raw = {
      id: this.state.mergeMainId,
      status: 2,
      rejectMsg: textAreaValue || "",
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    fetch(`${BASE_URL}merge/update/${this.state.mergeMainId}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        NotificationManager.success(JSON.parse(result).msg);
        this.getMergeData();
        this.setState({ mergeState: 0 });
        
        // this.getTestCasesData('working')
      })
      .catch((error) => {
        console.log("error", error);
        NotificationManager.error(
          "Something went wrong with saving your data, This may happen due to internal server error, Please try Again"
        );
        localStorage.clear();
        history.push("/login?redirect=1");
      });
  };
  changeMergeStatus = (id, status)  =>  {
   
    if (status === 2) {
      this.setState({ rejectModal: false });
    }
    this.setState({ loading: false });
    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);
    
    var raw = {
      id: id,
      status: status,
      // rejectMsg: textAreaValue || "",
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    fetch(`${BASE_URL}merge/update/${id}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        NotificationManager.success(JSON.parse(result).msg);
        this.getMergeData();
        this.setState({ mergeState: 0 });
        if (status === 1) {
          this.props.parentMethod();
        }
        // this.getTestCasesData('working')
      })
      .catch((error) => {
        console.log("error", error);
        NotificationManager.error(
          "Something went wrong with saving your data, This may happen due to internal server error, Please try Again"
        );
        localStorage.clear();
        history.push("/login?redirect=1");
      });
  };
  saveChanges = async () => {
    console.log("SAVING DATA =============>");
    let { testCasesData } = this.state;
    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);
    console.log(JSON.stringify(testCasesData));

    let filteredArray = await testCasesData.filter(
      (element) => element.model && element.model != ""
    );

    var raw = {
      data: filteredArray,
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    fetch(`${BASE_URL}projects/task/${this.state.projectId}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        console.log(JSON.parse(result));
        // this.getTestCasesData('working')
      })
      .catch((error) => {
        console.log("error", error);
        NotificationManager.error(
          "Something went wrong with saving your data, This may happen due to internal server error, Please try Again"
        );
        localStorage.clear();
        history.push("/login?redirect=1");
      });
  };
  saveMergeChanges = async () => {
    console.log("SAVING DATA =============>");
    let { allProject, selectedOption1 } = this.state;
    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    // let filteredArray = await testCasesData.filter((element) =>element.model && element.model !='')

    var raw = {
      orgid: localStorage.getItem("orgId"),
      destination_pid: selectedOption1.value,
      source_pid: localStorage.getItem("projectId"),
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    console.log("== Save Merge Changes ==> " + raw);

    fetch(`${BASE_URL}merge`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (JSON.parse(result).code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        
        if (JSON.parse(result).code === 200) {
          
        
        NotificationManager.success(JSON.parse(result).msg);
        confirmAlert({
          className: "testcase-generation-modal ",
          
          customUI: ({ onClose }) => (
            <div
              className="testcase-generation-modal !h-[17rem]"
              style={{ textAlign: "center" }}
            >
              <div style={{ marginBottom: "20px", marginLeft: -3 }}>
                <svg
                  width="56"
                  height="56"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
                  <rect
                    x="4"
                    y="4"
                    width="48"
                    height="48"
                    rx="24"
                    stroke="#ECFDF3"
                    strokeWidth="8"
                  />
                  <path
                    d="M38 27.0801V28.0001C37.9988 30.1565 37.3005 32.2548 36.0093 33.9819C34.7182 35.7091 32.9033 36.9726 30.8354 37.584C28.7674 38.1954 26.5573 38.122 24.5345 37.3747C22.5117 36.6274 20.7847 35.2462 19.611 33.4372C18.4373 31.6281 17.8798 29.4882 18.0217 27.3364C18.1636 25.1847 18.9972 23.1364 20.3983 21.4972C21.7994 19.8579 23.6928 18.7155 25.7962 18.2403C27.8996 17.765 30.1003 17.9824 32.07 18.8601M38 20.0001L28 30.0101L25 27.0101"
                    stroke="#039855"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
    
              <h2 style={{ fontFamily: "Roboto" }}>
              Merge Request Submitted!
              </h2>
              <p>
              Your merge request has been created successfully! You can either view the merge request in the destination project or continue working in your current project.
              </p>
    
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: 10,
                  marginLeft: 3,
                  marginTop: "20px",
                  marginBottom: "20px"
                }}
              >
                <button
                  className="btn btn-third download-btn h-[40px] mb-3 font-poppins text-[14px] !font-[500] !text-[#3B4147] "
                  onClick={() => {
                    this.setState({ mergeState: 0 });
                    this.getMergeData();
                    onClose(); // Close the alert after downloading
                  }}
                >
                  Go back
                </button>
                <button
                  className="btn btn-primary review-btn h-[40px] !w-[210px] font-poppins text-[14px] !font-[500] "
                  onClick={() => {
                    this.setState({ mergeState: 0 });
                    JSON.parse(localStorage.getItem("projects")).forEach((element) => {
                      if (element.id === selectedOption1.value) {
                        this.props.changeProjectMerge(
                          element.id,
                          element.name,
                          element.role
                        );
                        this.setState({mergeResultType: 'open'});
                        this.getMergeData(selectedOption1.value);
                      }
                    });
                    onClose(); // Close the alert after clicking review
                  }}
                >
                  View Merge Request
                </button>
              </div>
            </div>
          ),
        });
      }else {
          NotificationManager.error(JSON.parse(result).error);
          return;
      }
        
        
        // this.props.changeProjectMerge();
        // localStorage.setItem("projectId", )
      })
      .catch((error) => {
        console.log("error", error);
        NotificationManager.error(
          "Something went wrong with saving your data, This may happen due to internal server error, Please try Again"
        );
        localStorage.clear();
        history.push("/login?redirect=1");
      });
  };

  /*
  ..######...#######..##.....##.########...#######..##....##.########.##....##.########
  .##....##.##.....##.###...###.##.....##.##.....##.###...##.##.......###...##....##...
  .##.......##.....##.####.####.##.....##.##.....##.####..##.##.......####..##....##...
  .##.......##.....##.##.###.##.########..##.....##.##.##.##.######...##.##.##....##...
  .##.......##.....##.##.....##.##........##.....##.##..####.##.......##..####....##...
  .##....##.##.....##.##.....##.##........##.....##.##...###.##.......##...###....##...
  ..######...#######..##.....##.##.........#######..##....##.########.##....##....##...
  */

  render() {
    const {
      allProject,
      mergeState,
      search,
      mergeData,
      isSubmit,
      mergeDataByIdAll,
      mergeDataById,
      showBtns,
      name,
      testCasesData,
      loading,
      dynamicStyle,
      projectNameSort,
      projectLen,
    } = this.state;
    let mergeDataOpen = mergeData.filter(
      (el) =>
        el.status === 0 &&
        el.destination_pid === localStorage.getItem("projectId")
    );
    let mergeDataOpenNew = mergeData.filter(
      (el) =>
        el.status === 0 && el.source_pid === localStorage.getItem("projectId")
    );
    let mergeDataOpenArray = mergeDataOpenNew.map((el) => {
      return el.destination_pid;
    });
    let mergeDataClose = mergeData.filter(
      (el) =>
        (el.status === 1 || el.status === 2) &&
        el.destination_pid === localStorage.getItem("projectId")
    );
    let finalAllProject = allProject.filter(function (item) {
      return mergeDataOpenArray.indexOf(item.value) === -1;
    });
    console.log(mergeDataOpen);
    console.log(testCasesData);
    const showDetailsIcon = "showDetailsIcon";
    return (
      <>
        <div className="main-test-case" style={{marginLeft:'3rem' ,overflowY:mergeState !== 0 ? 'auto':'visible'}}>
          {loading && (
            <SkeletonTheme color="#f3f3f3" highlightColor="#f9f9f9">
              <Skeleton count={5} height={100} />
            </SkeletonTheme>
          )}

          {/* **************          Start of Design Page 1              ************ */}

          {!loading && mergeState === 0 && (
            <div >
              <div className="row" >
                <div className="input-group merge-tab-search mb-3 ">
                <div className="col-lg-12 !px-[0px]" style={{ overflow: "auto" }}>
                      <p
                  className="primary_heading"
                  style={{ marginBottom: 10}}
                >
                  Merge Requests
                </p>
                <p className="sub_para">
                You can manage and track your merge requests here. Use the options below to view open and closed    requests or create new ones.
                </p>
                </div>
                <div className="flex flex-row gap-x-3  justify-between !w-[97%]">
                <div
                  style={{
                    position: "relative",
                  }}
                >
                           <input
                              autoComplete="off"
                              type="text"
                              className="form-control !placeholder-[#316BFF]"
                              placeholder="Search"
                              value={search}
                              onChange={(e) =>
                                this.setState({ search: e.target.value })
                              }
                              style={{
                                border: "0.3px solid #316BFF",
                                borderRadius: 8,
                                height: 44,
                                fontSize: 12,
                                width: 260,
                                background: "rgba(146, 198, 255, 0.1)",
                              }}
                            />
                            <span
                              style={{
                                position: "absolute",
                                color: "#316BFF",
                                top: 18,
                                right: 12,
                              }}
                            >
                              <IoSearchOutline />
                            </span>
                </div>
              
                 
                  <div className="merge-btn ">
                    <button
                    className="flex flex-row !items-center justify-center gap-x-2 primary_btn !h-[40px] px-[15px] py-[6px]  !text-[14px] font-poppins text-white !font-[600] hover:!text-white  "
                    style={{borderRadius:8,
                    backgroundColor:"#00CD70 !important"}}
                    onClick={(e) => this.setMergeState(1)}
                    >
                      <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.17113 2.6587L6.51243 0H5.63652L2.97658 2.6587L3.85249 3.53461L5.20662 2.18172V3.44417H5.18184V4.00911L5.21281 4.01035L5.22024 4.40556C5.2252 4.63723 5.24502 4.8751 5.28219 5.11669C5.31936 5.35828 5.36768 5.59987 5.42714 5.84269C5.48661 6.08428 5.56838 6.34321 5.67492 6.61949C5.86696 7.08903 6.09987 7.51398 6.37491 7.8968C6.64871 8.27962 6.94481 8.63767 7.26073 8.97094C7.57665 9.30544 7.8901 9.63375 8.2023 9.95835C8.51203 10.2829 8.7945 10.6286 9.046 10.9966C9.2975 11.3645 9.50192 11.7659 9.65802 12.202C9.81288 12.6369 9.89093 13.1374 9.89093 13.7048V14.8H11.6489V13.7048C11.6479 13.1247 11.5789 12.5467 11.4433 11.9827C11.3257 11.5078 11.1479 11.0498 10.9143 10.6199C10.6995 10.2273 10.449 9.85526 10.166 9.50862C9.89093 9.17412 9.60475 8.85572 9.30741 8.54971C9.01007 8.24494 8.72388 7.94016 8.44884 7.63539C8.17462 7.33106 7.92444 7.0059 7.70054 6.66285C7.47199 6.31103 7.29157 5.93019 7.16409 5.53049C7.02554 5.077 6.95866 4.60465 6.96587 4.13052V3.44417H6.96463V2.20526L8.29522 3.53584L9.17113 2.6587ZM4.88698 8.97094C5.02741 8.82269 5.16334 8.67023 5.29458 8.51378C4.98794 8.06793 4.73041 7.59024 4.52646 7.08903L4.51778 7.06178C4.47646 6.95393 4.43722 6.8453 4.40009 6.73594C4.18947 7.05311 3.95532 7.35292 3.7001 7.63539C3.42507 7.94016 3.13888 8.24494 2.84154 8.54971C2.5442 8.85572 2.25925 9.17412 1.98297 9.50862C1.70918 9.84313 1.45892 10.2123 1.23467 10.6187C1.0009 11.0494 0.823121 11.5082 0.705659 11.984C0.570188 12.5476 0.501165 13.1251 0.5 13.7048V14.8H2.25925V13.7048C2.25925 13.1386 2.33606 12.6369 2.49217 12.202C2.64703 11.7659 2.85145 11.3645 3.10295 10.9966C3.35445 10.6286 3.63568 10.2829 3.94789 9.95835C4.25885 9.63375 4.5723 9.30544 4.88946 8.97094H4.88698Z" fill="white"/>
</svg>

                      New Merge Request
                    </button>
                  </div>
                  </div>
                </div>
              </div>

              <div id="merge-tab-control" className="row">
                
                  <div
                    class="w-[97%] flex flex-row items-center gap-x-8 border-b pb-2 pt-6 mb-6 pl-0  bg-blue-500- "
                    
                  >
                    <div
                      class={` relative font-poppins text-[14px] !font-[400] cursor-pointer ${
                        this.state.mergeResultType === "open"
                          ? " custom-border "
                          : ""
                      } !pb-0`}
                      onClick={() => {
                        this.setState({ mergeResultType: "open" });
                      }}
                      
                    >
                      Open Merge Requests <span style={{color:this.state.mergeResultType === "open" ? "#316BFF" : "#5B5B5B"}}>({mergeDataOpen.length})</span>
                    </div>
                    <div
                      class={` relative font-poppins text-[14px] !font-[400] cursor-pointer ${
                        this.state.mergeResultType === "close"
                          ? " custom-border "
                          : ""
                      } !pb-0`}
                      onClick={() => {
                        this.setState({ mergeResultType: "close" });
                      }}
                    >
                     Closed Merge Requests <span style={{color:this.state.mergeResultType === "close" ? "#316BFF" : "#5B5B5B"}}>({mergeDataClose.length})</span>
                    </div>
                  </div>
                
                <div className="tab-content !w-[97%] !border-none overflow-y-auto h-[20rem]" >
                {this.state.mergeResultType === "open" && (
                  <div
                    class="flex flex-column gap-4 "
                    
                    
                  > {mergeDataOpen.length <= 0 && (<div
                    style={{
                      borderWidth: '0.8px 0.8px 0.8px 0px',
                      borderStyle: 'solid',
                      borderColor: 'rgba(180, 180, 180, 0.7)',
                      borderRadius: '0px 5px 5px 0px',
                    }}
                      
                      
                      className="flex flex-row !h-[50px] "
                    >
                     <span class="relative w-2 bg-[#779DFF] border-l-[0.8px] border-l-[#779DFF] left-0 top-0"></span>
                     <p class="font-roboto font-[400] text-[15px] text-[#64748B] flex flex-row items-center ml-2">No open merge requests at the moment</p>
                    
                      </div>)}
                    
                      {mergeDataOpen.length > 0 &&
                        mergeDataOpen
                          .filter((el) =>
                            el.merge_no.toString().includes(search) || 
                            (el.createdByFirstname && el.createdByFirstname.toLowerCase().includes(search.toLowerCase())) ||
                            (el.sourceProjectName && el.sourceProjectName.toLowerCase().includes(search.toLowerCase()))
                          )
                          .map((ele, index) => {
                            return (
                              <div
                                
                                key={ele.mergeId}
                                style={{
                                  borderWidth: '0.8px 0.8px 0.8px 0px',
                                  borderStyle: 'solid',
                                  borderColor: 'rgba(180, 180, 180, 0.7)',
                                  borderRadius: '0px 5px 5px 0px',
                                }}
                                onClick={(e) =>
                                  this.showMerge(ele.mergeId, true)
                                } 
                                className="flex flex-row !h-[6.438rem] cursor-pointer"
                              >
                                <span class="relative w-2 bg-[#779DFF] border-l-[0.8px] border-l-[#779DFF] left-0 top-0"></span>
                                <div><svg width="67" height="101" viewBox="0 0 67 101" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M43.7337 40.733L36.7632 34H34.4668L27.493 40.733L29.7895 42.9511L33.3397 39.5251V42.7221H33.2747V44.1528L33.3559 44.1559L33.3754 45.1568C33.3884 45.7435 33.4404 46.3459 33.5378 46.9577C33.6353 47.5695 33.7619 48.1813 33.9178 48.7962C34.0738 49.408 34.2881 50.0638 34.5675 50.7634C35.0709 51.9525 35.6816 53.0286 36.4027 53.9981C37.1205 54.9676 37.8968 55.8743 38.7251 56.7183C39.5534 57.5654 40.3751 58.3968 41.1937 59.2188C42.0057 60.0408 42.7463 60.9162 43.4057 61.848C44.065 62.7798 44.601 63.7964 45.0102 64.9008C45.4163 66.002 45.6209 67.2695 45.6209 68.7065V71.48H50.23V68.7065C50.2273 67.2374 50.0464 65.7738 49.6908 64.3454C49.3825 63.1427 48.9164 61.9829 48.3039 60.8942C47.7409 59.8999 47.0841 58.9578 46.342 58.0799C45.6209 57.2328 44.8706 56.4265 44.091 55.6516C43.3115 54.8797 42.5611 54.1079 41.8401 53.3361C41.1211 52.5654 40.4652 51.742 39.8782 50.8732C39.279 49.9822 38.806 49.0178 38.4717 48.0056C38.1085 46.8572 37.9331 45.661 37.952 44.4603V42.7221H37.9488V39.5847L41.4373 42.9543L43.7337 40.733ZM32.5017 56.7183C32.8699 56.3429 33.2262 55.9568 33.5703 55.5606C32.7664 54.4315 32.0912 53.2218 31.5565 51.9525L31.5337 51.8835C31.4254 51.6104 31.3225 51.3353 31.2251 51.0583C30.673 51.8615 30.0591 52.6208 29.3899 53.3361C28.6689 54.1079 27.9185 54.8797 27.139 55.6516C26.3594 56.4265 25.6124 57.2328 24.888 58.0799C24.1702 58.9271 23.5141 59.862 22.9261 60.8911C22.3132 61.9818 21.8471 63.1437 21.5392 64.3486C21.184 65.7759 21.0031 67.2385 21 68.7065V71.48H25.6124V68.7065C25.6124 67.2727 25.8137 66.002 26.223 64.9008C26.629 63.7964 27.165 62.7798 27.8243 61.848C28.4837 60.9162 29.221 60.0408 30.0396 59.2188C30.8549 58.3968 31.6766 57.5654 32.5082 56.7183H32.5017Z" fill="#316BFF"/>
</svg>
</div>
                                <div className="flex flex-column justify-evenly w-[85%]">
                                  <p class="font-roboto font-[600] text-[17px] text-[#191D23]">Test {ele.merge_no}</p>
                                  <div class="flex flex-row font-poppins font-[500] text-[13px] " style={{color:"#000000", alignItems:'center', gap:'7px'}}>
                                   <p class="flex flex-row items-center gap-[5px]"> Source: <span className="integration-status-text flex flex-row items-center justify-center text-[#00CD70] bg-[#E8FFF2]">{ele.sourceProjectName}</span> </p><svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.66665 12.5001H18.3333M18.3333 12.5001L14.6667 8.3334M18.3333 12.5001L14.6667 16.6667" stroke="#525252" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                                    <p class="flex flex-row items-center gap-[5px]">Destination:  <span className="integration-status-text flex flex-row items-center justify-center text-[#00CD70] bg-[#E8FFF2]">{ele.destinationProjectName}</span></p>
                                  </div>
                                  <p class="font-roboto font-[400] text-[12px] " style={{color:"#7D7D7D"}}>
                                    Opened{" "}
                                    <TimeAgo
                                      datetime={moment
                                        .utc(ele.createdAt)
                                        .local()}
                                    />
                                  </p>
                                </div>
                                <button class="flex flex-row font-poppins font-[500] text-[13px] text-[#316BFF] items-center !border-none gap-[10px]" style={{ cursor: "pointer" }}>Details <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.38889 1H6.75C3.57436 1 1 3.57436 1 6.75C1 9.92566 3.57436 12.5 6.75 12.5H7.38889M9.94444 4.19444L12.5 6.75M12.5 6.75L9.94444 9.30556M12.5 6.75H4.83333" stroke="#316BFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</button>
                              </div>
                            );
                          })}
                    
                  </div>
                  )}
                {this.state.mergeResultType === "close" && (

                  <div
                  class="flex flex-column gap-4 overflow-y-auto" 
                    
                  >   {mergeDataClose.length <= 0 && (<div
                    style={{
                      borderWidth: '0.8px 0.8px 0.8px 0px',
                      borderStyle: 'solid',
                      borderColor: 'rgba(180, 180, 180, 0.7)',
                      borderRadius: '0px 5px 5px 0px',
                    }}
                      
                      
                      className="flex flex-row !h-[50px] "
                    >
                     <span class="relative w-2 bg-[#779DFF] border-l-[0.8px] border-l-[#779DFF] left-0 top-0"></span>
                     <p class="font-roboto font-[400] text-[15px] text-[#64748B] flex flex-row items-center ml-2">No closed merge requests at the moment</p>
                    
                      </div>)}
                    
                      {mergeDataClose.length > 0 &&
                        mergeDataClose
                          .filter((el) =>
                            el.merge_no.toString().includes(search) || 
                            (el.createdByFirstname && el.createdByFirstname.toLowerCase().includes(search.toLowerCase()))||
                            (el.sourceProjectName && el.sourceProjectName.toLowerCase().includes(search.toLowerCase()))
                          )
                          .map((ele, index) => {
                            return (
                              <div
                              title= {ele.status === 1 ? "Merged" : "Rejected"}
                              onClick={(e) =>
                                this.showMerge(ele.mergeId, true)
                              } 
                              style={{
                                borderWidth: '0.8px 0.8px 0.8px 0px',
                                borderStyle: 'solid',
                                borderColor: 'rgba(180, 180, 180, 0.7)',
                                borderRadius: '0px 5px 5px 0px',
                              }}
                                key={ele.mergeId}
                                
                                className="flex flex-row !h-[6.438rem] cursor-pointer "
                              >
                                <span class="relative w-2 bg-[#779DFF] border-l-[0.8px] border-l-[#779DFF] left-0 top-0"></span>
                                <div><svg width="67" height="101" viewBox="0 0 67 101" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M43.7337 40.733L36.7632 34H34.4668L27.493 40.733L29.7895 42.9511L33.3397 39.5251V42.7221H33.2747V44.1528L33.3559 44.1559L33.3754 45.1568C33.3884 45.7435 33.4404 46.3459 33.5378 46.9577C33.6353 47.5695 33.7619 48.1813 33.9178 48.7962C34.0738 49.408 34.2881 50.0638 34.5675 50.7634C35.0709 51.9525 35.6816 53.0286 36.4027 53.9981C37.1205 54.9676 37.8968 55.8743 38.7251 56.7183C39.5534 57.5654 40.3751 58.3968 41.1937 59.2188C42.0057 60.0408 42.7463 60.9162 43.4057 61.848C44.065 62.7798 44.601 63.7964 45.0102 64.9008C45.4163 66.002 45.6209 67.2695 45.6209 68.7065V71.48H50.23V68.7065C50.2273 67.2374 50.0464 65.7738 49.6908 64.3454C49.3825 63.1427 48.9164 61.9829 48.3039 60.8942C47.7409 59.8999 47.0841 58.9578 46.342 58.0799C45.6209 57.2328 44.8706 56.4265 44.091 55.6516C43.3115 54.8797 42.5611 54.1079 41.8401 53.3361C41.1211 52.5654 40.4652 51.742 39.8782 50.8732C39.279 49.9822 38.806 49.0178 38.4717 48.0056C38.1085 46.8572 37.9331 45.661 37.952 44.4603V42.7221H37.9488V39.5847L41.4373 42.9543L43.7337 40.733ZM32.5017 56.7183C32.8699 56.3429 33.2262 55.9568 33.5703 55.5606C32.7664 54.4315 32.0912 53.2218 31.5565 51.9525L31.5337 51.8835C31.4254 51.6104 31.3225 51.3353 31.2251 51.0583C30.673 51.8615 30.0591 52.6208 29.3899 53.3361C28.6689 54.1079 27.9185 54.8797 27.139 55.6516C26.3594 56.4265 25.6124 57.2328 24.888 58.0799C24.1702 58.9271 23.5141 59.862 22.9261 60.8911C22.3132 61.9818 21.8471 63.1437 21.5392 64.3486C21.184 65.7759 21.0031 67.2385 21 68.7065V71.48H25.6124V68.7065C25.6124 67.2727 25.8137 66.002 26.223 64.9008C26.629 63.7964 27.165 62.7798 27.8243 61.848C28.4837 60.9162 29.221 60.0408 30.0396 59.2188C30.8549 58.3968 31.6766 57.5654 32.5082 56.7183H32.5017Z" fill="#316BFF"/>
</svg>
</div>
                                <div className="flex flex-column justify-evenly w-[85%]">
                                  <p class="font-roboto font-[600] text-[17px] text-[#191D23]">Test {ele.merge_no}</p>
                                  <div class="flex flex-row font-poppins font-[500] text-[13px] " style={{color:"#000000", alignItems:'center', gap:'7px'}}>
                                   <p class="flex flex-row items-center gap-[5px]"> Source: <span className="integration-status-text flex flex-row items-center justify-center " style={{color: ele.status === 1 ? '#00CD70' : '#FF3A3A', backgroundColor: ele.status === 1 ? '#E8FFF2' : '#FFEEEE' }}>{ele.sourceProjectName}</span> </p><svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.66665 12.5001H18.3333M18.3333 12.5001L14.6667 8.3334M18.3333 12.5001L14.6667 16.6667" stroke="#525252" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                                    <p class="flex flex-row items-center gap-[5px]">Destination:  <span className="integration-status-text flex flex-row items-center justify-center " style={{color: ele.status === 1 ? '#00CD70' : '#FF3A3A', backgroundColor: ele.status === 1 ? '#E8FFF2' : '#FFEEEE' }}>{ele.destinationProjectName}</span></p>
                                  </div>
                                  <p class="font-roboto font-[400] text-[12px] " style={{color:"#7D7D7D"}}>
                                    Opened{" "}
                                    <TimeAgo
                                      datetime={moment
                                        .utc(ele.createdAt)
                                        .local()}
                                    />
                                  </p>
                                </div>
                                <button class="flex flex-row font-poppins font-[500] text-[13px] text-[#316BFF] items-center !border-none gap-[10px]" style={{ cursor: "pointer" }}>Details <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.38889 1H6.75C3.57436 1 1 3.57436 1 6.75C1 9.92566 3.57436 12.5 6.75 12.5H7.38889M9.94444 4.19444L12.5 6.75M12.5 6.75L9.94444 9.30556M12.5 6.75H4.83333" stroke="#316BFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</button>
                              </div>
                            );
                          })}
                  </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* **************          End of Design Page 1              ************ */}

          {/* **************          Start of Design Page 2              ************ */}
          {mergeState === 1 && (
            <div className="!w-[97%]">
              <div className="row milestone-base-section items-center ">
              <div className="col-lg-12 !px-[0px]" style={{ overflow: "auto" }}>
                      <p
                  className="primary_heading"
                  style={{ marginBottom: 10}}
                >
                  
                  Configure Merge Request
                </p>
                <p className="sub_para">
                You can choose the destination for your merge request from the dropdown below. The source is set by default to the project you are currently in. </p>
                </div>
                <div className="milestone font-roboto !font-[400] !text-[16px] w-[250px] h-[50px]  " style={{color:"#000000 !important"}}>
                  <p className="m-0 !text-[#000000] ">
                    Source : 
                    {localStorage.getItem("projectName")}
                  </p>
                </div>
                <svg width="60" height="50" viewBox="0 0 60 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_167)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M43.6127 32.7771L53 24.9985L43.6127 17.2198L40.9714 19.4196L45.0511 22.8204H41.2441V22.7582H39.5405L39.5367 22.836L38.345 22.8546C37.6463 22.8671 36.929 22.9169 36.2005 23.0102C35.472 23.1036 34.7435 23.2249 34.0112 23.3743C33.2827 23.5236 32.5019 23.729 31.6687 23.9966C30.2528 24.4788 28.9714 25.0638 27.8169 25.7545C26.6625 26.4422 25.5828 27.1858 24.5778 27.9792C23.5691 28.7726 22.5791 29.5598 21.6003 30.3439C20.6214 31.1218 19.5791 31.8312 18.4695 32.4628C17.3599 33.0945 16.1494 33.6078 14.8344 33.9999C13.523 34.3888 12.0137 34.5848 10.3026 34.5848H7V39H10.3026C12.0519 38.9974 13.7948 38.8241 15.4956 38.4835C16.9278 38.1882 18.3089 37.7417 19.6052 37.1549C20.7893 36.6156 21.9111 35.9865 22.9564 35.2756C23.9651 34.5848 24.9253 33.8661 25.8481 33.1193C26.7671 32.3726 27.6862 31.6538 28.6052 30.9631C29.523 30.2744 30.5035 29.6461 31.538 29.0838C32.5989 28.5098 33.7474 28.0567 34.9527 27.7365C36.3202 27.3886 37.7446 27.2206 39.1743 27.2387H41.2441V27.2356H44.9801L40.9676 30.5773L43.6127 32.7771ZM24.5778 22.0177C25.0249 22.3704 25.4846 22.7117 25.9564 23.0413C27.3009 22.2712 28.7414 21.6244 30.2528 21.1122L30.335 21.0905C30.6602 20.9867 30.9878 20.8881 31.3176 20.7949C30.3611 20.2659 29.457 19.6779 28.6052 19.0369C27.6862 18.3461 26.7671 17.6274 25.8481 16.8807C24.9253 16.1339 23.9651 15.4183 22.9564 14.7244C21.9477 14.0368 20.8344 13.4083 19.609 12.8451C18.3102 12.258 16.9266 11.8115 15.4919 11.5165C13.7923 11.1763 12.0507 11.0029 10.3026 11L7 11V15.4183H10.3026C12.01 15.4183 13.523 15.6112 14.8344 16.0032C16.1494 16.3922 17.3599 16.9055 18.4695 17.5372C19.5791 18.1688 20.6214 18.8751 21.6003 19.6592C22.5791 20.4402 23.5691 21.2274 24.5778 22.0239V22.0177Z" fill="#316BFF"/>
</g>
<defs>
<clipPath id="clip0_1_167">
<rect width="50" height="60" fill="white" transform="matrix(0 1 -1 0 60 0)"/>
</clipPath>
</defs>
</svg>

                <div className="font-roboto !font-[400] !text-[16px] ml-2 ">
                  <Select
                    // placeholder="Base"
                    className="text-[#000000]flex flex-row items-center"
                    classNamePrefix="react-select-"
                    defaultValue={{ label: "Select Destination", value: "" }}
                    onChange={this.handleChange1}
                    options={finalAllProject}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: "2px solid #89AAFF",
                        boxShadow: "0px 3px 12px -2px rgba(118, 187, 217, 0.25), 0px 0px 7px -2px rgba(0, 0, 0, 0.3)",
                        display: "flex",
                        borderRadius: '6px',
                        alignItems: "center",
                        height: 50, // Set the desired height
                        minHeight: 50, // Ensure it doesn't go below this height
                        padding: "0 8px", // Adjust padding as needed
                      }),
                      // valueContainer: (provided) => ({
                      //   ...provided,
                      //   height: "100%",
                      //   // padding: "0 8px", // Adjust padding as needed
                      //   display: "flex",
                      //   alignItems: "center",
                      // }),
                      input: (provided) => ({
                        ...provided,
                        margin: 0,
                        padding: 0,
                        height: 50, // Ensures input height matches the container
                        display: "flex",
                        alignItems: "center",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: 16, // Adjust font size as needed
                        display: "flex",
                        margin: "10px 0 0 0",
                        alignItems: "center",
                        height: "100%", // Ensures placeholder height matches the container
                      }),
                      option: (base, { isFocused }) => ({
                        ...base,
                        backgroundColor: isFocused ? "#E8F4FF" : "white",
                        color: '#000000',
                        display: "flex",
                        alignItems: "center",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        display: "flex",
                        alignItems: "center",
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        display: "flex",
                        alignItems: "center",
                      }),
                      clearIndicator: (provided) => ({
                        ...provided,
                        display: "flex",
                        alignItems: "center",
                      }),
                    }}
                  />
                </div>
                {/* <div className="base-disabled">
                  <p className="m-0"><b>Base: </b>IOS</p>
                </div> */}
                <div className="merge-btn">
                  <button
                    disabled={!isSubmit}
                    className="flex flex-row !items-center justify-center gap-x-2 primary_btn !h-[40px] px-[15px] py-[6px]  !text-[14px] font-poppins text-white !font-[600] hover:!text-white  "
                    style={{borderRadius:8,
                    }}
                    onClick={(e) => this.saveMergeChanges()}
                  >
                    Submit Merge Request
                  </button>
                </div>
                <div style={{ marginLeft: "15px" }}>
                  <button
                         className="rounded-lg !h-[40px] flex flex-row w-[130px] !items-center justify-center gap-x-2  px-[15px] py-[6px] !bg-[#E7EAEE] !text-[14px] font-poppins text-[#636463] !font-[600] "
                          
                          
                         style={{borderRadius:8,
                   }}
                    onClick={(e) => this.setMergeState(0)}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.99309 4.97248V7.70623L9.65309 7.71248C14.2375 7.75686 16.8193 9.79561 17.4925 13.9275C14.9743 11.0144 12.0706 11.0131 9.65996 11.0125H8.99309V13.7231L2.81559 9.34748L8.99309 4.97248ZM9.65996 3.68311L1.66309 9.34748L9.65996 15.0119V11.6794C12.8606 11.6806 15.8281 11.8419 18.3375 16.3169C18.3375 13.475 17.8681 7.12561 9.65996 7.04623V3.68311Z"
                        fill="#636463"
                      />
                    </svg>
                    Back
                  </button>
                </div>
              </div>
              <p className="font-roboto !font-[600] !text-[20px] mt-14 mb-3">Current testcases in source project</p>
              {/* <br><br> */}
              <div className="test-case-body">
              
                { this.state.testCasesData.length > 0 &&
                      this.state.testCasesData.map((ele, index) => {
                        return (
                          <div className="tests">
                            <div
                              className="flex "
                              style={{
                                borderBottom: "0.5px solid #D1D5DB",
                                marginBottom: 3,
                                backgroundColor: "#F8F8F8",
                              }}
                            >
                              <div className="w-0.5 px-0.5 py-3 bg-primary-green" />
                              <div className="model-input  w-full">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flex: 1,
                                  }}
                                >
                                  {ele.expanded ? (
                                    <MdOutlineArrowDropDown
                                      className="text-primary-green"
                                      size={28}
                                      onClick={() => {
                                        ele.expanded = !ele.expanded;
                                        this.setState({
                                          testCasesData: testCasesData,
                                        });
                                      }}
                                    />
                                  ) : (
                                    <MdArrowRight
                                      className="text-black"
                                      size={28}
                                      onClick={() => {
                                        ele.expanded = !ele.expanded;
                                        this.setState({
                                          testCasesData: testCasesData,
                                        });
                                      }}
                                    />
                                  )}
                                <input
                                    autoComplete="off"
                                    type="text"
                                    placeholder="Add Task Title here"
                                    className="input1 w-full"
                                    name="model"
                                    value={ele.model || ele.taskTitle}
                                    disabled
                                   
                                  />
                                  
                                </div>
                              </div>
                            </div>

                            {/* Div Goes Here */}
                            {ele.isCollapse ||
                              ele.lists
                                .filter((el) => {
                                  if (this.state.filterProps.length <= 0) {
                                    return (
                                      el.subTaskTitle === "" ||
                                      el.subTaskTitle === null ||
                                      el.subTaskTitle
                                        ?.toString()
                                        .toLowerCase()
                                        .includes(search.toLowerCase())
                                    );
                                  } else {
                                    return (
                                      (el.subTaskTitle === "" ||
                                        el.subTaskTitle === null ||
                                        el.subTaskTitle
                                          ?.toString()
                                          .toLowerCase()
                                          .includes(search.toLowerCase())) &&
                                      (this.state.filterProps.includes(
                                        el.testing?.toString().toLowerCase()
                                      ) ||
                                      this.state.filterProps.includes(
                                          el.priority
                                            ? el.priority
                                                ?.toString()
                                                .toLowerCase()
                                            : null
                                        ) ||
                                        this.state.filterProps.some((item) =>
                                          el.test_type
                                            ? el.test_type.includes(item)
                                            : null
                                        ))
                                    );
                                  }
                                })

                                .map((item, i, row) => {
                                  // console.log(
                                  // filterProps.includes(
                                  //   item.testing.toString().toLowerCase()

                                  // )

                                  item.testing = item.testing || "manual";
                                  
                                  return (
                                    ele.expanded &&
                                    item.subTaskTitle && (
                                      <div
                                        className={`model-list qq pl-12 pr-4  ${
                                          item.subTaskTitle ? "" : " !bg-white"
                                        }`}
                                        // onMouseEnter={this.mouseEnter.bind(this)}
                                        // onMouseLeave={this.mouseLeave.bind(this)}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            flex: 1,
                                          }}
                                          className={`model-list-testsuite `}
                                        >
                                          <input
                                            type="text"
                                            autoComplete="off"
                                            placeholder="Add new Test case here"
                                            className={`list-input !placeholder-primary-green ${
                                              item.classNameDependsOnCondtion
                                            } ${
                                              item.subTaskTitle
                                                ? " bg-transparent"
                                                : ""
                                            }`}
                                            name="list"
                                            value={
                                              item.subTaskTitle !== null
                                                ? item.subTaskTitle
                                                : ""
                                            }
                                            
                                          />
                                          {item.subTaskTitle ? (
                                            <span
                                              className="mr-8 pl-2 flex items-center open-details-button cursor-pointer"
                                              name=""
                                              onClick={() =>
                                                this.onClickDetail(
                                                  index + "-" + i,
                                                  item.subtaskId
                                                )
                                              }
                                            >
                                              <span className="details-button">
                                                Details
                                              </span>

                                              <svg
                                                className="details-svg"
                                                width="13"
                                                height="13"
                                                viewBox="0 0 13 13"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M6.83333 0.875488H6.25C3.35051 0.875488 1 3.22599 1 6.12549C1 9.025 3.35051 11.3755 6.25 11.3755H6.83333M9.16667 3.79215L11.5 6.12549M11.5 6.12549L9.16667 8.45882M11.5 6.12549H4.5"
                                                  stroke="#999999"
                                                  stroke-width="1.5"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                />
                                              </svg>
                                            </span>
                                          ) : (
                                            <span className="pr-[27px]" />
                                          )}
                                        </div>

                                        <div
                                          style={{
                                            width:
                                              dynamicStyle[index + "-" + i] ||
                                              dynamicStyle,
                                            zIndex: 5000,
                                          }}
                                          className="sidebar-new"
                                        >
                                          <div className="button-items detail-btn">
                                            <i
                                              className="mdi mdi-close btn-float-left"
                                              onClick={() =>
                                                this.onClickCloseDetail(
                                                  index + "-" + i
                                                )
                                              }
                                            ></i>
                                          </div>
                                          <Scrollbars style={{ height: "85%" }}>
                                            {/* <a onClick={() => this.onClickCloseDetail()} className="closebtn">&times;</a> */}
                                            <div className="row test-row sidebar-nav-body">
                                              <div className="col-12">
                                                <div className="card m-b-20">
                                                  <div className="card-body">
                                                    <form
                                                      
                                                    >
                                                      <div className="form-group row">
                                                        <div className="col-sm-12 p-0 mr-6 mb-6">
                                                          <input
                                                            className="form-control test-header-title"
                                                            type="text"
                                                            name="subTaskNameInSideDrawer"
                                                            style={{
                                                              marginBottom: 20,
                                                              marginTop: "auto",
                                                              paddingLeft: 0,
                                                            }}
                                                            ref={(el) => {
                                                              if (el) {
                                                                el.style.setProperty(
                                                                  "font-size",
                                                                  "20px",
                                                                  "important"
                                                                );
                                                                el.style.setProperty(
                                                                  "font-family",
                                                                  "'Raleway', sans-serif",
                                                                  "important"
                                                                );
                                                                el.style.setProperty(
                                                                  "font-weight",
                                                                  "700px",
                                                                  "important"
                                                                );
                                                              }
                                                            }}
                                                            
                                                            value={
                                                              item.subTaskTitle
                                                            }
                                                          />
                                                          <div className="flex flex-row items-cente gap-2 ">
                                                            <div className="flex flex-row items-center text-[14px] font-[400] gap-2 .font-roboto color-[#525252]">
                                                              {" "}
                                                              ID:{" "}
                                                              <span className=".font-poppins h-8 flex text-[14px] items-center justify-center text-primary-green bg-[#EBFFF4] px-2 py-1 rounded-md font-[500]">
                                                                {item.subid}
                                                              </span>
                                                            </div>
                                                            <div className="flex flex-row items-center text-[14px] font-[400] gap-2 .font-roboto color-[#525252]">
                                                              {" "}
                                                              TestSuite:{" "}
                                                              <span className=".font-poppins h-8 flex text-[14px] items-center justify-center text-primary-green bg-[#EBFFF4] px-2 py-1 rounded-md font-[500]">
                                                                {ele.model ||
                                                                  ele.taskTitle}
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <hr></hr>
                                                      <div
                                                        className="form-group row"
                                                        style={{
                                                          marginTop: "2rem",
                                                        }}
                                                      >
                                                        <label
                                                          for="example-description-input"
                                                          className="pading-right-0px col-sm-3 col-form-label input-label"
                                                          style={{
                                                            width: "100%",
                                                          }}
                                                        >
                                                          Description
                                                        </label>
                                                        <div className="col-sm-8 ml-3">
                                                          <textarea
                                                            id="textarea"
                                                            className="form-control input-style-detail"
                                                            name="subTaskDescriptionInSideDrawer"
                                                            
                                                            value={
                                                              item.description
                                                            }
                                                            maxlength="225"
                                                            rows="6"
                                                            placeholder="Description and Steps to reproduce"
                                                          ></textarea>
                                                        </div>
                                                      </div>
                                                      <div className="form-group row">
                                                        <label
                                                          for="example-summary-input"
                                                          className="col-sm-3 col-form-label input-label"
                                                        >
                                                          Expected Result
                                                        </label>
                                                        <div className="col-sm-8 ml-3">
                                                          <textarea
                                                            id="textarea"
                                                            className="form-control input-style-detail"
                                                            name="subTaskSummaryInSideDrawer"
                                                            
                                                            value={item.summary}
                                                            maxlength="225"
                                                            rows="2"
                                                            placeholder="Summary"
                                                          ></textarea>
                                                        </div>
                                                      </div>
                                                      <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label input-label">
                                                          Browser
                                                        </label>
                                                        <div className="col-sm-8 ml-3">
                                                          <select
                                                            className="form-control input-style-detail"
                                                            name="subTaskBrowserInSideDrawer"
                                                            value={item.browser}
                                                            
                                                          >
                                                            
                                                          </select>
                                                        </div>
                                                      </div>

                                                      <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label input-label">
                                                          OS
                                                        </label>
                                                        <div className="col-sm-8 ml-3">
                                                          <select
                                                            name="subTaskOsInSideDrawer"
                                                            value={item.os}
                                                            className="form-control input-style-detail"
                                                            
                                                          >
                                                            
                                                          </select>
                                                        </div>
                                                      </div>

                                                      <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label input-label">
                                                          Type
                                                        </label>
                                                        <div className="col-sm-8 ml-3">
                                                          <select
                                                            name="subTaskTestingInSideDrawer"
                                                            value={item.testing}
                                                            className="form-control input-style-detail"
                                                            
                                                          >
                                                            
                                                          </select>
                                                        </div>
                                                      </div>
                                                      <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label input-label">
                                                          Priority
                                                        </label>
                                                        <div className="col-sm-8 ml-3">
                                                          <select
                                                            name="subTaskpriorityInSideDrawer"
                                                            value={
                                                              item.priority
                                                            }
                                                            className="form-control input-style-detail"
                                                            onChange={(e) =>
                                                              this.onModelListTestDetailUpdate(
                                                                "priority",
                                                                index,
                                                                i,
                                                                item.subtaskId,
                                                                e.target.value
                                                              )
                                                            }
                                                            
                                                          >
                                                            
                                                          </select>
                                                        </div>
                                                      </div>

                                                      <div className="form-group row">
                                                        <label
                                                          for="example-id-input "
                                                          className="col-sm-3 col-form-label input-label"
                                                        >
                                                          Created By
                                                        </label>
                                                        <div className="col-sm-8 ml-3">
                                                          <input
                                                            name="subTaskCreatedByInSideDrawer"
                                                            className="form-control input-style-detail"
                                                            type="text"
                                                            value={
                                                              (item.username =
                                                                this.getUsername(
                                                                  item.username
                                                                ))
                                                            }
                                                            
                                                            placeholder="Created By"
                                                            disabled
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="form-group row">
                                                        <label
                                                          for="example-id-input "
                                                          className="col-sm-3 col-form-label input-label"
                                                        >
                                                          Test type
                                                        </label>
                                                        <div className="col-sm-8 ml-3">
                                                          <Select
                                                            className=" input-style-detail"
                                                            isMulti
                                                            isSearchable={false}
                                                            value={
                                                              item.test_type
                                                                ? this.state.testType.filter(
                                                                    ({
                                                                      value,
                                                                    }) =>
                                                                      item.test_type.indexOf(
                                                                        value
                                                                      ) !== -1
                                                                  )
                                                                : this.state
                                                                    .testType[0]
                                                            }
                                                            
                                                          
                                                            defaultValue={
                                                              this.state
                                                                .testType[0]
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                       
                                                          

                                                      {item.field !== null &&
                                                        item.field.map(
                                                          (eNew, indexNew) => (
                                                            <div className="form-group row">
                                                              <label
                                                                key={indexNew}
                                                                for="example-id-input"
                                                                className="col-sm-3 col-form-label input-label"
                                                              >
                                                                {eNew.fileName}
                                                              </label>
                                                              <div className="col-sm-8 ml-3">
                                                                <input
                                                                  type="hidden"
                                                                  name="subTaskCustomFieldInSideDrawer"
                                                                  value={JSON.stringify(
                                                                    eNew
                                                                  )}
                                                                />
                                                                <input
                                                                  key={indexNew}
                                                                  className="form-control input-style-detail"
                                                                  type="text"
                                                                  name="subTaskCustomFieldInSideDrawer123"
                                                                  value={
                                                                    eNew.fileValue
                                                                  }
                                                                  ref={(el) => {
                                                                    if (el) {
                                                                      el.style.setProperty(
                                                                        "font-size",
                                                                        "13px",
                                                                        "important"
                                                                      );
                                                                      el.style.setProperty(
                                                                        "font-family",
                                                                        "'Roboto', sans-serif",
                                                                        "important"
                                                                      );
                                                                      el.style.setProperty(
                                                                        "font-weight",
                                                                        "400px",
                                                                        "important"
                                                                      );
                                                                      el.style.setProperty(
                                                                        "color",
                                                                        "#64748B",
                                                                        "important"
                                                                      );
                                                                    }
                                                                  }}
                                                                 
                                                                  placeholder={
                                                                    eNew.fileName
                                                                  }
                                                                />
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                        
                                                    </form>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Scrollbars>
                     
                                  </div>
                                  
                                </div>
                                )
                              );
                            })}
                      </div>
                    );
                  })}
                  
              </div>
            </div>
          )}
          

          {/* **************          End of Design Page 2              ************ */}

          {/* **************          Start of Design Page 3              ************ */}
          {mergeState === 2 && (
            <div >
              <div className="row merge-project-action mb-3" >
              <div className="col-lg-12 !px-[0px]">
                      <p
                  className="primary_heading"
                  style={{ marginBottom: 6}}
                >{(mergeDataByIdAll.status === 0) && (
                  <button
                          style={{marginLeft:'-1.10rem', paddingRight:'0.25rem'}}
                          onClick={(e) => this.setMergeState(0)}
                       >
                         <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
 <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6675 13.5956L3.33608 7.59942L10.5845 1.52813L9.32297 0L0 7.59942L9.32297 15.1912L10.6675 13.5956Z" fill="#9D9C9C"/>
 </svg>
 
                       </button>)}Merge Requests Details
                </p>
                </div>
                <div class="flex flex-row items-center justify-between w-[97%]">
                <div className="merge-details-text">
                  <div class="flex flex-column gap-2 ">
                  <p class="font-roboto !font-[500] !text-[20px] !text-[#000000] flex flex-row items-center gap-4">
                    ID- #{mergeDataByIdAll.merge_no}{" "}
                    {(mergeDataByIdAll.status === 1 ) && (
                      <span className="integration-status-text !h-[20px] flex ml-1 flex-row items-center justify-center !text-[#00CD70] bg-[#E8FFF2]">Merged <span className=" !text-[#00CD70] flex flex-row items-center justify-center gap-2">{mergeDataByIdAll.sourceProjectName}<svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.492188 4.165H11.9867M11.9867 4.165L9.11304 1M11.9867 4.165L9.11304 7.33" stroke="#525252" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      {mergeDataByIdAll.destinationProjectName}</span></span>
                    )}{" "}
                    {mergeDataByIdAll.status === 0 && (
                      <span className="integration-status-text ml-1 !h-[20px] flex flex-row items-center justify-center !text-[#00CD70] bg-[#E8FFF2]">Open</span>
                    )}
                    {mergeDataByIdAll.status === 2 && (
                      <span className="integration-status-text ml-1 !h-[20px] flex flex-row items-center justify-center !text-[#FF3A3A] bg-[#FFEEEE]">Rejected</span>
                    )}
                  </p>
                  <p class="font-roboto !font-[400] !text-[14px] !text-[#828282]">Created by {mergeDataByIdAll.createdByFirstname}</p>
                  <p class="integration-status-text !rounded-[30px] !w-[125px] !bg-[#F2F2F2] font-roboto !font-[300] !h-[24px] justify-center gap-1 !text-[12px] !text-[#484848] flex flex-row items-center "><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.5002 2.625H9.62516V1.75C9.62516 1.58877 9.49473 1.45833 9.3335 1.45833C9.17226 1.45833 9.04183 1.58877 9.04183 1.75V2.625H4.9585V1.75C4.9585 1.58877 4.82806 1.45833 4.66683 1.45833C4.5056 1.45833 4.37516 1.58877 4.37516 1.75V2.625H3.50016C2.3745 2.625 1.4585 3.54101 1.4585 4.66667V10.5C1.4585 11.6257 2.3745 12.5417 3.50016 12.5417H10.5002C11.6258 12.5417 12.5418 11.6257 12.5418 10.5V4.66667C12.5418 3.54101 11.6258 2.625 10.5002 2.625ZM11.9585 10.5C11.9585 11.3041 11.3042 11.9583 10.5002 11.9583H3.50016C2.6961 11.9583 2.04183 11.3041 2.04183 10.5V6.70833H11.9585V10.5ZM11.9585 6.125H2.04183V4.66667C2.04183 3.8626 2.6961 3.20833 3.50016 3.20833H4.37516V4.08333C4.37516 4.24457 4.5056 4.375 4.66683 4.375C4.82806 4.375 4.9585 4.24457 4.9585 4.08333V3.20833H9.04183V4.08333C9.04183 4.24457 9.17226 4.375 9.3335 4.375C9.49473 4.375 9.62516 4.24457 9.62516 4.08333V3.20833H10.5002C11.3042 3.20833 11.9585 3.8626 11.9585 4.66667V6.125ZM2.91683 8.45833V7.875C2.91683 7.71388 3.04744 7.58333 3.2085 7.58333H3.79183C3.95289 7.58333 4.0835 7.71388 4.0835 7.875V8.45833C4.0835 8.61939 3.95289 8.75 3.79183 8.75H3.2085C3.04744 8.75 2.91683 8.61939 2.91683 8.45833Z" fill="#4F4F4F"/>
</svg>
<span class="font-roboto !font-[300] !text-[12px] !text-[#484848]">{mergeDataByIdAll.createdAt}</span></p>
</div>
                  
                </div>
                <div className="">

                  
                  {showBtns === true &&
                    Promises.getLocalIteam("projectRole") !== 0 && (
                      
                      <div class="flex flex-row items-center gap-3  " >
                        {mergeDataByIdAll.status === 0  ? (<>
                        <button
                          className="rounded-lg flex flex-row !items-center justify-center w-[130px] gap-x-2 !hover:bg-red-300 !h-[40px] px-[15px] py-[6px] !bg-[#FF6262] !text-[14px] font-poppins text-white !font-[600] hover:!text-white  "

                          onClick={() =>
                            this.setState({
                              mergeMainId: mergeDataByIdAll.mergeId,
                              rejectModal: true,
                            })
                          }
                        >
                          Reject
                        </button>
                        <button
                         className="rounded-lg !h-[40px] flex flex-row !items-center justify-center gap-x-2 primary_btn px-[15px] py-[6px] !text-[14px] font-poppins text-white !font-[600] hover:!text-white  "

                          onClick={() =>
                            this.changeMergeStatus(mergeDataByIdAll.mergeId, 1)
                          }
                        >
                          Approve Merge Request
                        </button>
                        
                        </>):(<>
                          <button
                         className="rounded-lg !h-[40px] flex flex-row w-[130px] !items-center justify-center gap-x-2  px-[15px] py-[6px] !bg-[#E7EAEE] !text-[14px] font-poppins text-[#636463] !font-[600] "
                          
                          
                         style={{
                   }}
                    onClick={(e) => this.setMergeState(0)}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.99309 4.97248V7.70623L9.65309 7.71248C14.2375 7.75686 16.8193 9.79561 17.4925 13.9275C14.9743 11.0144 12.0706 11.0131 9.65996 11.0125H8.99309V13.7231L2.81559 9.34748L8.99309 4.97248ZM9.65996 3.68311L1.66309 9.34748L9.65996 15.0119V11.6794C12.8606 11.6806 15.8281 11.8419 18.3375 16.3169C18.3375 13.475 17.8681 7.12561 9.65996 7.04623V3.68311Z"
                        fill="#636463"
                      />
                    </svg>
                    Back
                  </button>
                        </>)}
                      </div>
                    )}
                    </div>
                </div>
              </div>
              {(mergeDataByIdAll.status === 0 || mergeDataByIdAll.status === 2) && (<>
              <div className="flex flex-row items-center justify-around mt-[4vh] mb-[3vh] ml-[-3rem]">
                <div className="flex flex-row items-center justify-center rounded-md w-[450px] h-[50px] gap-2 font-roboto !font-[400] !text-[16px] !bg-[#E8F4FF] !text-[#000000]" style={{
boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)'}}>Source:<span class="!font-[500]">{mergeDataByIdAll.sourceProjectName}</span></div>
                <svg width="60" height="50" viewBox="0 0 60 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_298)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M43.6127 32.7771L53 24.9985L43.6127 17.2198L40.9714 19.4196L45.0511 22.8204H41.2441V22.7582H39.5405L39.5367 22.836L38.345 22.8546C37.6463 22.8671 36.929 22.9169 36.2005 23.0102C35.472 23.1036 34.7435 23.2249 34.0112 23.3743C33.2827 23.5236 32.5019 23.729 31.6687 23.9966C30.2528 24.4788 28.9714 25.0638 27.8169 25.7545C26.6625 26.4422 25.5828 27.1858 24.5778 27.9792C23.5691 28.7726 22.5791 29.5598 21.6003 30.3439C20.6214 31.1218 19.5791 31.8312 18.4695 32.4628C17.3599 33.0945 16.1494 33.6078 14.8344 33.9999C13.523 34.3888 12.0137 34.5848 10.3026 34.5848H7V39H10.3026C12.0519 38.9974 13.7948 38.8241 15.4956 38.4835C16.9278 38.1882 18.3089 37.7417 19.6052 37.1549C20.7893 36.6156 21.9111 35.9865 22.9564 35.2756C23.9651 34.5848 24.9253 33.8661 25.8481 33.1193C26.7671 32.3726 27.6862 31.6538 28.6052 30.9631C29.523 30.2744 30.5035 29.6461 31.538 29.0838C32.5989 28.5098 33.7474 28.0567 34.9527 27.7365C36.3202 27.3886 37.7446 27.2206 39.1743 27.2387H41.2441V27.2356H44.9801L40.9676 30.5773L43.6127 32.7771ZM24.5778 22.0177C25.0249 22.3704 25.4846 22.7117 25.9564 23.0413C27.3009 22.2712 28.7414 21.6244 30.2528 21.1122L30.335 21.0905C30.6602 20.9867 30.9878 20.8881 31.3176 20.7949C30.3611 20.2659 29.457 19.6779 28.6052 19.0369C27.6862 18.3461 26.7671 17.6274 25.8481 16.8807C24.9253 16.1339 23.9651 15.4183 22.9564 14.7244C21.9477 14.0368 20.8344 13.4083 19.609 12.8451C18.3102 12.258 16.9266 11.8115 15.4919 11.5165C13.7923 11.1763 12.0507 11.0029 10.3026 11L7 11V15.4183H10.3026C12.01 15.4183 13.523 15.6112 14.8344 16.0032C16.1494 16.3922 17.3599 16.9055 18.4695 17.5372C19.5791 18.1688 20.6214 18.8751 21.6003 19.6592C22.5791 20.4402 23.5691 21.2274 24.5778 22.0239V22.0177Z" fill="#316BFF"/>
</g>
<defs>
<clipPath id="clip0_1_298"> 
<rect width="50" height="60" fill="white" transform="matrix(0 1 -1 0 60 0)"/>
</clipPath>
</defs>
</svg>

                <div className="flex flex-row items-center justify-center rounded-md w-[450px] h-[50px] gap-2 font-roboto !font-[400] !text-[16px] !bg-[#E8FFF2] !text-[#000000]" style={{
boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)'}}>Destination:<span class="!font-[500]">{mergeDataByIdAll.destinationProjectName}</span></div>
              </div>
              <div className="flex flex-row gap-3 items-center ">
              
                <div className="w-[48%] h-[44vh] overflow-y-auto">
                <div className="border border-[#eeeeee]">
                
                {mergeDataById.length > 0 &&
                  mergeDataById.filter((ele) => ele.type === "SOURCE").map((ele, index) => {
                    return (
                      <div className="tests">
                        <div
                          className="flex "
                          style={{
                            borderBottom: "0.5px solid #D1D5DB",
                            marginBottom: 3,
                            backgroundColor: "#F8F8F8",
                          }}
                        >
                          <div className="w-0.5 px-0.5 py-3 bg-[#89AAFF]" />
                          <div className="model-input  w-full">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flex: 1,
                              }}
                            >
                              {ele.expanded ? (
                                <MdOutlineArrowDropDown
                                  className="text-[#89AAFF]"
                                  size={28}
                                  onClick={() => {
                                    ele.expanded = !ele.expanded;
                                    this.setState({
                                      testCasesData: testCasesData,
                                    });
                                  }}
                                />
                              ) : (
                                <MdArrowRight
                                  className="text-black"
                                  size={28}
                                  onClick={() => {
                                    ele.expanded = !ele.expanded;
                                    this.setState({
                                      testCasesData: testCasesData,
                                    });
                                  }}
                                />
                              )}
                            <input
                                autoComplete="off"
                                type="text"
                                placeholder="Add Task Title here"
                                className="input1 w-full"
                                name="model"
                                value={ele.model || ele.taskTitle}
                                disabled
                               
                              />
                              
                            </div>
                          </div>
                        </div>

                        {/* Div Goes Here */}
                        {ele.isCollapse ||
                          ele.lists
                            .filter((el) => {
                              if (this.state.filterProps.length <= 0) {
                                return (
                                  el.subTaskTitle === "" ||
                                  el.subTaskTitle === null ||
                                  el.subTaskTitle
                                    ?.toString()
                                    .toLowerCase()
                                    .includes(search.toLowerCase())
                                );
                              } else {
                                return (
                                  (el.subTaskTitle === "" ||
                                    el.subTaskTitle === null ||
                                    el.subTaskTitle
                                      ?.toString()
                                      .toLowerCase()
                                      .includes(search.toLowerCase())) &&
                                  (this.state.filterProps.includes(
                                    el.testing?.toString().toLowerCase()
                                  ) ||
                                  this.state.filterProps.includes(
                                      el.priority
                                        ? el.priority
                                            ?.toString()
                                            .toLowerCase()
                                        : null
                                    ) ||
                                    this.state.filterProps.some((item) =>
                                      el.test_type
                                        ? el.test_type.includes(item)
                                        : null
                                    ))
                                );
                              }
                            })

                            .map((item, i, row) => {
                              // console.log(
                              // filterProps.includes(
                              //   item.testing.toString().toLowerCase()

                              // )

                              item.testing = item.testing || "manual";
                              
                              return (
                                ele.expanded &&
                                item.subTaskTitle && (
                                  <div
                                    className={`model-list qq pl-12 pr-4 cursor-pointer hover:!bg-[#E8F4FF] ${
                                      item.subTaskTitle ? "" : " !bg-white"
                                    }`}
                                    // onMouseEnter={this.mouseEnter.bind(this)}
                                    // onMouseLeave={this.mouseLeave.bind(this)}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flex: 1,
                                      }}
                                      className={`model-list-testsuite cursor-pointer `}
                                    >
                                      <input
                                        type="text"
                                        autoComplete="off"
                                        placeholder="Add new Test case here"
                                        className={`list-input !placeholder-primary-green ${
                                          item.classNameDependsOnCondtion
                                        } ${
                                          item.subTaskTitle
                                            ? " bg-transparent"
                                            : ""
                                        }`}
                                        name="list"
                                        value={
                                          item.subTaskTitle !== null
                                            ? item.subTaskTitle
                                            : ""
                                        }
                                        disabled={true}
                                      />
                                      {item.subTaskTitle ? (
                                        <span
                                          className="mr-8 pl-2 flex items-center open-details-button cursor-pointer   "
                                          name=""
                                          onClick={() =>
                                            this.onClickDetail(
                                              index + "-" + i,
                                              item.subtaskId
                                            )
                                          }
                                        >
                                          <span className="details-button">
                                            Details
                                          </span>

                                          <svg
                                            className="details-svg "
                                            width="13"
                                            height="13"
                                            viewBox="0 0 13 13"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M6.83333 0.875488H6.25C3.35051 0.875488 1 3.22599 1 6.12549C1 9.025 3.35051 11.3755 6.25 11.3755H6.83333M9.16667 3.79215L11.5 6.12549M11.5 6.12549L9.16667 8.45882M11.5 6.12549H4.5"
                                              stroke="#999999"
                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        </span>
                                      ) : (
                                        <span className="pr-[27px]" />
                                      )}
                                    </div>

                                    <div
                                      style={{
                                        width:
                                          dynamicStyle[index + "-" + i] ||
                                          dynamicStyle,
                                        zIndex: 5000,
                                      }}
                                      className="sidebar-new"
                                    >
                                      <div className="button-items detail-btn">
                                        <i
                                          className="mdi mdi-close btn-float-left"
                                          onClick={() =>
                                            this.onClickCloseDetail(
                                              index + "-" + i
                                            )
                                          }
                                        ></i>
                                      </div>
                                      <Scrollbars style={{ height: "85%" }}>
                                        {/* <a onClick={() => this.onClickCloseDetail()} className="closebtn">&times;</a> */}
                                        <div className="row test-row sidebar-nav-body">
                                          <div className="col-12">
                                            <div className="card m-b-20">
                                              <div className="card-body">
                                              <form
                                                      
                                                      >
                                                        <div className="form-group row">
                                                          <div className="col-sm-12 p-0 mr-6 mb-6">
                                                            <input
                                                              className="form-control test-header-title"
                                                              type="text"
                                                              name="subTaskNameInSideDrawer"
                                                              style={{
                                                                marginBottom: 20,
                                                                marginTop: "auto",
                                                                paddingLeft: 0,
                                                              }}
                                                              ref={(el) => {
                                                                if (el) {
                                                                  el.style.setProperty(
                                                                    "font-size",
                                                                    "20px",
                                                                    "important"
                                                                  );
                                                                  el.style.setProperty(
                                                                    "font-family",
                                                                    "'Raleway', sans-serif",
                                                                    "important"
                                                                  );
                                                                  el.style.setProperty(
                                                                    "font-weight",
                                                                    "700px",
                                                                    "important"
                                                                  );
                                                                }
                                                              }}
                                                              
                                                              value={
                                                                item.subTaskTitle
                                                              }
                                                            />
                                                            <div className="flex flex-row items-cente gap-2 ">
                                                              <div className="flex flex-row items-center text-[14px] font-[400] gap-2 .font-roboto color-[#525252]">
                                                                {" "}
                                                                ID:{" "}
                                                                <span className=".font-poppins h-8 flex text-[14px] items-center justify-center text-primary-green bg-[#EBFFF4] px-2 py-1 rounded-md font-[500]">
                                                                  {item.subid}
                                                                </span>
                                                              </div>
                                                              <div className="flex flex-row items-center text-[14px] font-[400] gap-2 .font-roboto color-[#525252]">
                                                                {" "}
                                                                TestSuite:{" "}
                                                                <span className=".font-poppins h-8 flex text-[14px] items-center justify-center text-primary-green bg-[#EBFFF4] px-2 py-1 rounded-md font-[500]">
                                                                  {ele.model ||
                                                                    ele.taskTitle}
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <hr></hr>
                                                        <div
                                                          className="form-group row"
                                                          style={{
                                                            marginTop: "2rem",
                                                          }}
                                                        >
                                                          <label
                                                            for="example-description-input"
                                                            className="pading-right-0px col-sm-3 col-form-label input-label"
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            Description
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <textarea
                                                              id="textarea"
                                                              className="form-control input-style-detail"
                                                              name="subTaskDescriptionInSideDrawer"
                                                              
                                                              value={
                                                                item.description
                                                              }
                                                              maxlength="225"
                                                              rows="6"
                                                              placeholder="Description and Steps to reproduce"
                                                            ></textarea>
                                                          </div>
                                                        </div>
                                                        <div className="form-group row">
                                                          <label
                                                            for="example-summary-input"
                                                            className="col-sm-3 col-form-label input-label"
                                                          >
                                                            Expected Result
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <textarea
                                                              id="textarea"
                                                              className="form-control input-style-detail"
                                                              name="subTaskSummaryInSideDrawer"
                                                              
                                                              value={item.summary}
                                                              maxlength="225"
                                                              rows="2"
                                                              placeholder="Summary"
                                                            ></textarea>
                                                          </div>
                                                        </div>
                                                        <div className="form-group row">
                                                          <label className="col-sm-3 col-form-label input-label">
                                                            Browser
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <select
                                                              className="form-control input-style-detail"
                                                              name="subTaskBrowserInSideDrawer"
                                                              value={item.browser}
                                                              
                                                            >
                                                            
                                                            </select>
                                                          </div>
                                                        </div>
  
                                                        <div className="form-group row">
                                                          <label className="col-sm-3 col-form-label input-label">
                                                            OS
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <select
                                                              name="subTaskOsInSideDrawer"
                                                              value={item.os}
                                                              className="form-control input-style-detail"
                                                              
                                                            >
                                                              
                                                            </select>
                                                          </div>
                                                        </div>
  
                                                        <div className="form-group row">
                                                          <label className="col-sm-3 col-form-label input-label">
                                                            Type
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <select
                                                              name="subTaskTestingInSideDrawer"
                                                              value={item.testing}
                                                              className="form-control input-style-detail"
                                                              
                                                            >
                                                             
                                                            </select>
                                                          </div>
                                                        </div>
                                                        <div className="form-group row">
                                                          <label className="col-sm-3 col-form-label input-label">
                                                            Priority
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <select
                                                              name="subTaskpriorityInSideDrawer"
                                                              value={
                                                                item.priority
                                                              }
                                                              className="form-control input-style-detail"
                                                              onChange={(e) =>
                                                                this.onModelListTestDetailUpdate(
                                                                  "priority",
                                                                  index,
                                                                  i,
                                                                  item.subtaskId,
                                                                  e.target.value
                                                                )
                                                              }
                                                              
                                                            >
                                                             
                                                            </select>
                                                          </div>
                                                        </div>
  
                                                        <div className="form-group row">
                                                          <label
                                                            for="example-id-input "
                                                            className="col-sm-3 col-form-label input-label"
                                                          >
                                                            Created By
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <input
                                                              name="subTaskCreatedByInSideDrawer"
                                                              className="form-control input-style-detail"
                                                              type="text"
                                                              value={
                                                                (item.username =
                                                                  this.getUsername(
                                                                    item.username
                                                                  ))
                                                              }
                                                              
                                                              placeholder="Created By"
                                                              disabled
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="form-group row">
                                                          <label
                                                            for="example-id-input "
                                                            className="col-sm-3 col-form-label input-label"
                                                          >
                                                            Test type
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <Select
                                                              className=" input-style-detail"
                                                              isMulti
                                                              isSearchable={false}
                                                              value={
                                                                item.test_type
                                                                  ? this.state.testType.filter(
                                                                      ({
                                                                        value,
                                                                      }) =>
                                                                        item.test_type.indexOf(
                                                                          value
                                                                        ) !== -1
                                                                    )
                                                                  : this.state
                                                                      .testType[0]
                                                              }
                                                              
                                                             
                                                              defaultValue={
                                                                this.state
                                                                  .testType[0]
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                         
                                                            
  
                                                        {item.field !== null &&
                                                          item.field.map(
                                                            (eNew, indexNew) => (
                                                              <div className="form-group row">
                                                                <label
                                                                  key={indexNew}
                                                                  for="example-id-input"
                                                                  className="col-sm-3 col-form-label input-label"
                                                                >
                                                                  {eNew.fileName}
                                                                </label>
                                                                <div className="col-sm-8 ml-3">
                                                                  <input
                                                                    type="hidden"
                                                                    name="subTaskCustomFieldInSideDrawer"
                                                                    value={JSON.stringify(
                                                                      eNew
                                                                    )}
                                                                  />
                                                                  <input
                                                                    key={indexNew}
                                                                    className="form-control input-style-detail"
                                                                    type="text"
                                                                    name="subTaskCustomFieldInSideDrawer123"
                                                                    value={
                                                                      eNew.fileValue
                                                                    }
                                                                    ref={(el) => {
                                                                      if (el) {
                                                                        el.style.setProperty(
                                                                          "font-size",
                                                                          "13px",
                                                                          "important"
                                                                        );
                                                                        el.style.setProperty(
                                                                          "font-family",
                                                                          "'Roboto', sans-serif",
                                                                          "important"
                                                                        );
                                                                        el.style.setProperty(
                                                                          "font-weight",
                                                                          "400px",
                                                                          "important"
                                                                        );
                                                                        el.style.setProperty(
                                                                          "color",
                                                                          "#64748B",
                                                                          "important"
                                                                        );
                                                                      }
                                                                    }}
                                                                   
                                                                    placeholder={
                                                                      eNew.fileName
                                                                    }
                                                                  />
                                                                </div>
                                                              </div>
                                                            )
                                                          )}
                                                          
                                                      </form>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Scrollbars>
                    
                                      </div>
                                  
                                </div>
                                )
                              );
                            })}
                      </div>
                    );
                  })}
                  
                  </div>
                  </div>
                  
                  <div className="w-[48%] h-[44vh]  overflow-y-auto">
                  <div className="border border-[#eeeeee]">
                  
                {mergeDataById.length > 0 &&
                  mergeDataById.filter((ele) => ele.type === "DESTINATION").map((ele, index) => {
                    return (
                      <div className="tests">
                        <div
                          className="flex "
                          style={{
                            borderBottom: "0.5px solid #D1D5DB",
                            marginBottom: 3,
                            backgroundColor: "#F8F8F8",
                          }}
                        >
                          <div className="w-0.5 px-0.5 py-3 bg-primary-green" />
                          <div className="model-input  w-full">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flex: 1,
                              }}
                            >
                              {ele.expanded ? (
                                <MdOutlineArrowDropDown
                                  className="text-primary-green"
                                  size={28}
                                  onClick={() => {
                                    ele.expanded = !ele.expanded;
                                    this.setState({
                                      testCasesData: testCasesData,
                                    });
                                  }}
                                />
                              ) : (
                                <MdArrowRight
                                  className="text-black"
                                  size={28}
                                  onClick={() => {
                                    ele.expanded = !ele.expanded;
                                    this.setState({
                                      testCasesData: testCasesData,
                                    });
                                  }}
                                />
                              )}
                            <input
                                autoComplete="off"
                                type="text"
                                placeholder="Add Task Title here"
                                className="input1 w-full"
                                name="model"
                                value={ele.model || ele.taskTitle}
                                disabled
                               
                              />
                              
                            </div>
                          </div>
                        </div>

                        {/* Div Goes Here */}
                        {ele.isCollapse ||
                          ele.lists
                            .filter((el) => {
                              if (this.state.filterProps.length <= 0) {
                                return (
                                  el.subTaskTitle === "" ||
                                  el.subTaskTitle === null ||
                                  el.subTaskTitle
                                    ?.toString()
                                    .toLowerCase()
                                    .includes(search.toLowerCase())
                                );
                              } else {
                                return (
                                  (el.subTaskTitle === "" ||
                                    el.subTaskTitle === null ||
                                    el.subTaskTitle
                                      ?.toString()
                                      .toLowerCase()
                                      .includes(search.toLowerCase())) &&
                                  (this.state.filterProps.includes(
                                    el.testing?.toString().toLowerCase()
                                  ) ||
                                  this.state.filterProps.includes(
                                      el.priority
                                        ? el.priority
                                            ?.toString()
                                            .toLowerCase()
                                        : null
                                    ) ||
                                    this.state.filterProps.some((item) =>
                                      el.test_type
                                        ? el.test_type.includes(item)
                                        : null
                                    ))
                                );
                              }
                            })

                            .map((item, i, row) => {
                              // console.log(
                              // filterProps.includes(
                              //   item.testing.toString().toLowerCase()

                              // )

                              item.testing = item.testing || "manual";
                              
                              return (
                                ele.expanded &&
                                item.subTaskTitle && (
                                  <div
                                    className={`model-list qq pl-12 pr-4 cursor-pointer hover:!bg-[#E8FFF2] ${
                                      item.subTaskTitle ? "" : " !bg-white"
                                    }`}
                                    // onMouseEnter={this.mouseEnter.bind(this)}
                                    // onMouseLeave={this.mouseLeave.bind(this)}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flex: 1,
                                      }}
                                      className={`model-list-testsuite cursor-pointer`}
                                    >
                                      <input
                                        type="text"
                                        autoComplete="off"
                                        placeholder="Add new Test case here"
                                        className={`list-input !placeholder-primary-green ${
                                          item.classNameDependsOnCondtion
                                        } ${
                                          item.subTaskTitle
                                            ? " bg-transparent"
                                            : ""
                                        }`}
                                        name="list"
                                        value={
                                          item.subTaskTitle !== null
                                            ? item.subTaskTitle
                                            : ""
                                        }
                                        onBlur={
                                          (e) =>
                                            this.saveSubTaskChanges(
                                              e.currentTarget.value,
                                              ele.id,
                                              item,
                                              projectNameSort + index + i,
                                              i,
                                              index
                                            )
                                          // console.log(e.currentTarget.value)
                                        }
                                        
                                      />
                                      {item.subTaskTitle ? (
                                        <span
                                          className="mr-8 pl-2 flex items-center open-details-button2 cursor-pointer hover:!text-[#00CD70] "
                                          name=""
                                          onClick={() =>
                                            this.onClickDetail(
                                              index + "-" + i,
                                              item.subtaskId
                                            )
                                          }
                                        >
                                          <span className="details-button !text-[#00CD70]">
                                            Details
                                          </span>

                                          <svg
                                            className="details-svg"
                                            width="13"
                                            height="13"
                                            viewBox="0 0 13 13"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M6.83333 0.875488H6.25C3.35051 0.875488 1 3.22599 1 6.12549C1 9.025 3.35051 11.3755 6.25 11.3755H6.83333M9.16667 3.79215L11.5 6.12549M11.5 6.12549L9.16667 8.45882M11.5 6.12549H4.5"
                                              stroke="#999999"
                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        </span>
                                      ) : (
                                        <span className="pr-[27px]" />
                                      )}
                                    </div>

                                    <div
                                      style={{
                                        width:
                                          dynamicStyle[index + "-" + i] ||
                                          dynamicStyle,
                                        zIndex: 5000,
                                      }}
                                      className="sidebar-new"
                                    >
                                      <div className="button-items detail-btn">
                                        <i
                                          className="mdi mdi-close btn-float-left"
                                          onClick={() =>
                                            this.onClickCloseDetail(
                                              index + "-" + i
                                            )
                                          }
                                        ></i>
                                      </div>
                                      <Scrollbars style={{ height: "85%" }}>
                                        {/* <a onClick={() => this.onClickCloseDetail()} className="closebtn">&times;</a> */}
                                        <div className="row test-row sidebar-nav-body">
                                          <div className="col-12">
                                            <div className="card m-b-20">
                                              <div className="card-body">
                                              <form
                                                      
                                                      >
                                                        <div className="form-group row">
                                                          <div className="col-sm-12 p-0 mr-6 mb-6">
                                                            <input
                                                              className="form-control test-header-title"
                                                              type="text"
                                                              name="subTaskNameInSideDrawer"
                                                              style={{
                                                                marginBottom: 20,
                                                                marginTop: "auto",
                                                                paddingLeft: 0,
                                                              }}
                                                              ref={(el) => {
                                                                if (el) {
                                                                  el.style.setProperty(
                                                                    "font-size",
                                                                    "20px",
                                                                    "important"
                                                                  );
                                                                  el.style.setProperty(
                                                                    "font-family",
                                                                    "'Raleway', sans-serif",
                                                                    "important"
                                                                  );
                                                                  el.style.setProperty(
                                                                    "font-weight",
                                                                    "700px",
                                                                    "important"
                                                                  );
                                                                }
                                                              }}
                                                              
                                                              value={
                                                                item.subTaskTitle
                                                              }
                                                            />
                                                            <div className="flex flex-row items-cente gap-2 ">
                                                              <div className="flex flex-row items-center text-[14px] font-[400] gap-2 .font-roboto color-[#525252]">
                                                                {" "}
                                                                ID:{" "}
                                                                <span className=".font-poppins h-8 flex text-[14px] items-center justify-center text-primary-green bg-[#EBFFF4] px-2 py-1 rounded-md font-[500]">
                                                                  {item.subid}
                                                                </span>
                                                              </div>
                                                              <div className="flex flex-row items-center text-[14px] font-[400] gap-2 .font-roboto color-[#525252]">
                                                                {" "}
                                                                TestSuite:{" "}
                                                                <span className=".font-poppins h-8 flex text-[14px] items-center justify-center text-primary-green bg-[#EBFFF4] px-2 py-1 rounded-md font-[500]">
                                                                  {ele.model ||
                                                                    ele.taskTitle}
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <hr></hr>
                                                        <div
                                                          className="form-group row"
                                                          style={{
                                                            marginTop: "2rem",
                                                          }}
                                                        >
                                                          <label
                                                            for="example-description-input"
                                                            className="pading-right-0px col-sm-3 col-form-label input-label"
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            Description
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <textarea
                                                              id="textarea"
                                                              className="form-control input-style-detail"
                                                              name="subTaskDescriptionInSideDrawer"
                                                              
                                                              value={
                                                                item.description
                                                              }
                                                              maxlength="225"
                                                              rows="6"
                                                              placeholder="Description and Steps to reproduce"
                                                            ></textarea>
                                                          </div>
                                                        </div>
                                                        <div className="form-group row">
                                                          <label
                                                            for="example-summary-input"
                                                            className="col-sm-3 col-form-label input-label"
                                                          >
                                                            Expected Result
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <textarea
                                                              id="textarea"
                                                              className="form-control input-style-detail"
                                                              name="subTaskSummaryInSideDrawer"
                                                              
                                                              value={item.summary}
                                                              maxlength="225"
                                                              rows="2"
                                                              placeholder="Summary"
                                                            ></textarea>
                                                          </div>
                                                        </div>
                                                        <div className="form-group row">
                                                          <label className="col-sm-3 col-form-label input-label">
                                                            Browser
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <select
                                                              className="form-control input-style-detail"
                                                              name="subTaskBrowserInSideDrawer"
                                                              value={item.browser}
                                                              
                                                            >
                                                              
                                                            </select>
                                                          </div>
                                                        </div>
  
                                                        <div className="form-group row">
                                                          <label className="col-sm-3 col-form-label input-label">
                                                            OS
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <select
                                                              name="subTaskOsInSideDrawer"
                                                              value={item.os}
                                                              className="form-control input-style-detail"
                                                              
                                                            >
                                                              
                                                            </select>
                                                          </div>
                                                        </div>
  
                                                        <div className="form-group row">
                                                          <label className="col-sm-3 col-form-label input-label">
                                                            Type
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <select
                                                              name="subTaskTestingInSideDrawer"
                                                              value={item.testing}
                                                              className="form-control input-style-detail"
                                                              
                                                            >
                                                              
                                                            </select>
                                                          </div>
                                                        </div>
                                                        <div className="form-group row">
                                                          <label className="col-sm-3 col-form-label input-label">
                                                            Priority
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <select
                                                              name="subTaskpriorityInSideDrawer"
                                                              value={
                                                                item.priority
                                                              }
                                                              className="form-control input-style-detail"
                                                              onChange={(e) =>
                                                                this.onModelListTestDetailUpdate(
                                                                  "priority",
                                                                  index,
                                                                  i,
                                                                  item.subtaskId,
                                                                  e.target.value
                                                                )
                                                              }
                                                              
                                                            >
                                                             
                                                            </select>
                                                          </div>
                                                        </div>
  
                                                        <div className="form-group row">
                                                          <label
                                                            for="example-id-input "
                                                            className="col-sm-3 col-form-label input-label"
                                                          >
                                                            Created By
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <input
                                                              name="subTaskCreatedByInSideDrawer"
                                                              className="form-control input-style-detail"
                                                              type="text"
                                                              value={
                                                                (item.username =
                                                                  this.getUsername(
                                                                    item.username
                                                                  ))
                                                              }
                                                              
                                                              placeholder="Created By"
                                                              disabled
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="form-group row">
                                                          <label
                                                            for="example-id-input "
                                                            className="col-sm-3 col-form-label input-label"
                                                          >
                                                            Test type
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <Select
                                                              className=" input-style-detail"
                                                              isMulti
                                                              isSearchable={false}
                                                              value={
                                                                item.test_type
                                                                  ? this.state.testType.filter(
                                                                      ({
                                                                        value,
                                                                      }) =>
                                                                        item.test_type.indexOf(
                                                                          value
                                                                        ) !== -1
                                                                    )
                                                                  : this.state
                                                                      .testType[0]
                                                              }
                                                              
                                                              
                                                              defaultValue={
                                                                this.state
                                                                  .testType[0]
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                         
                                                            
  
                                                        {item.field !== null &&
                                                          item.field.map(
                                                            (eNew, indexNew) => (
                                                              <div className="form-group row">
                                                                <label
                                                                  key={indexNew}
                                                                  for="example-id-input"
                                                                  className="col-sm-3 col-form-label input-label"
                                                                >
                                                                  {eNew.fileName}
                                                                </label>
                                                                <div className="col-sm-8 ml-3">
                                                                  <input
                                                                    type="hidden"
                                                                    name="subTaskCustomFieldInSideDrawer"
                                                                    value={JSON.stringify(
                                                                      eNew
                                                                    )}
                                                                  />
                                                                  <input
                                                                    key={indexNew}
                                                                    className="form-control input-style-detail"
                                                                    type="text"
                                                                    name="subTaskCustomFieldInSideDrawer123"
                                                                    value={
                                                                      eNew.fileValue
                                                                    }
                                                                    ref={(el) => {
                                                                      if (el) {
                                                                        el.style.setProperty(
                                                                          "font-size",
                                                                          "13px",
                                                                          "important"
                                                                        );
                                                                        el.style.setProperty(
                                                                          "font-family",
                                                                          "'Roboto', sans-serif",
                                                                          "important"
                                                                        );
                                                                        el.style.setProperty(
                                                                          "font-weight",
                                                                          "400px",
                                                                          "important"
                                                                        );
                                                                        el.style.setProperty(
                                                                          "color",
                                                                          "#64748B",
                                                                          "important"
                                                                        );
                                                                      }
                                                                    }}
                                                                   
                                                                    placeholder={
                                                                      eNew.fileName
                                                                    }
                                                                  />
                                                                </div>
                                                              </div>
                                                            )
                                                          )}
                                                          
                                                      </form>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Scrollbars>
                    
                                      </div>
                                  
                                </div>
                                )
                              );
                            })}
                      </div>
                    );
                  })}
                  </div>
              </div>
              </div>
              </>)}
              {mergeDataByIdAll.status === 1 && (<>
    
              <div class=" w-[97%] mt-[25px]">
              <div className="border border-[#eeeeee]">
              {mergeDataById.length > 0 &&
                  mergeDataById.filter((ele) => ele.type === "MERGED").map((ele, index) => {
                    return (
                      <div style={{border:'0.5px solid #89AAFF'}}>
                      <div className="tests " style={{borderColor:'#89AAFF'}}>
                        <div
                          className="flex "
                          style={{
                            borderBottom: "0.5px solid #89AAFF",
                            marginBottom: 3,
                            backgroundColor: "#E8F0FF",
                          }}
                        >
                          <div className="w-0.5 px-0.5 py-3  bg-[#89AAFF]" />
                          <div className="model-input  w-full !bg-[#E8F0FF]">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flex: 1,
                              }}
                            >
                              {ele.expanded ? (
                                <MdOutlineArrowDropDown
                                  className="text-[#82A6FF]"
                                  size={28}
                                  onClick={() => {
                                    ele.expanded = !ele.expanded;
                                    this.setState({
                                      testCasesData: testCasesData,
                                    });
                                  }}
                                />
                              ) : (
                                <MdArrowRight
                                  className="text-black"
                                  size={28}
                                  onClick={() => {
                                    ele.expanded = !ele.expanded;
                                    this.setState({
                                      testCasesData: testCasesData,
                                    });
                                  }}
                                />
                              )}
                            <input
                                autoComplete="off"
                                type="text"
                                placeholder="Add Task Title here"
                                className="input1 w-full !bg-[#E8F0FF]"
                                name="model"
                                value={ele.model || ele.taskTitle}
                                disabled
                               
                              />
                              
                            </div>
                          </div>
                        </div>

                        {/* Div Goes Here */}
                        {ele.isCollapse ||
                          ele.lists
                            .filter((el) => {
                              if (this.state.filterProps.length <= 0) {
                                return (
                                  el.subTaskTitle === "" ||
                                  el.subTaskTitle === null ||
                                  el.subTaskTitle
                                    ?.toString()
                                    .toLowerCase()
                                    .includes(search.toLowerCase())
                                );
                              } else {
                                return (
                                  (el.subTaskTitle === "" ||
                                    el.subTaskTitle === null ||
                                    el.subTaskTitle
                                      ?.toString()
                                      .toLowerCase()
                                      .includes(search.toLowerCase())) &&
                                  (this.state.filterProps.includes(
                                    el.testing?.toString().toLowerCase()
                                  ) ||
                                  this.state.filterProps.includes(
                                      el.priority
                                        ? el.priority
                                            ?.toString()
                                            .toLowerCase()
                                        : null
                                    ) ||
                                    this.state.filterProps.some((item) =>
                                      el.test_type
                                        ? el.test_type.includes(item)
                                        : null
                                    ))
                                );
                              }
                            })

                            .map((item, i, row) => {
                              // console.log(
                              // filterProps.includes(
                              //   item.testing.toString().toLowerCase()

                              // )

                              item.testing = item.testing || "manual";
                              
                              return (
                                ele.expanded &&
                                item.subTaskTitle && (
                                  <div
                                    className={`model-list qq pl-12 pr-4 cursor-pointer !border-[#89AAFF] !bg-[#F7F9FF]${
                                      item.subTaskTitle ? "" : " !bg-[#F7F9FF]"
                                    }`}
                                    // onMouseEnter={this.mouseEnter.bind(this)}
                                    // onMouseLeave={this.mouseLeave.bind(this)}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flex: 1,
                                        borderColor:"#89AAFF"
                                      }}
                                      className={`model-list-testsuite cursor-pointer !bg-[#F7F9FF]` }
                                    >
                                      <input
                                        type="text"
                                        autoComplete="off"
                                        placeholder="Add new Test case here"
                                        className={`list-input !placeholder-primary-green ${
                                          item.classNameDependsOnCondtion
                                        } ${
                                          item.subTaskTitle
                                            ? " bg-transparent"
                                            : ""
                                        }`}
                                        name="list"
                                        value={
                                          item.subTaskTitle !== null
                                            ? item.subTaskTitle
                                            : ""
                                        }
                                        onBlur={
                                          (e) =>
                                            this.saveSubTaskChanges(
                                              e.currentTarget.value,
                                              ele.id,
                                              item,
                                              projectNameSort + index + i,
                                              i,
                                              index
                                            )
                                          // console.log(e.currentTarget.value)
                                        }
                                        
                                      />
                                      {item.subTaskTitle ? (
                                        <span
                                          className="mr-8 pl-2 flex items-center open-details-button cursor-pointer"
                                          name=""
                                          onClick={() =>
                                            this.onClickDetail(
                                              index + "-" + i,
                                              item.subtaskId
                                            )
                                          }
                                        >
                                          <span className="details-button">
                                            Details
                                          </span>

                                          <svg
                                            className="details-svg"
                                            width="13"
                                            height="13"
                                            viewBox="0 0 13 13"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M6.83333 0.875488H6.25C3.35051 0.875488 1 3.22599 1 6.12549C1 9.025 3.35051 11.3755 6.25 11.3755H6.83333M9.16667 3.79215L11.5 6.12549M11.5 6.12549L9.16667 8.45882M11.5 6.12549H4.5"
                                              stroke="#999999"
                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        </span>
                                      ) : (
                                        <span className="pr-[27px]" />
                                      )}
                                    </div>

                                    <div
                                      style={{
                                        width:
                                          dynamicStyle[index + "-" + i] ||
                                          dynamicStyle,
                                        zIndex: 5000,
                                      }}
                                      className="sidebar-new"
                                    >
                                      <div className="button-items detail-btn">
                                        <i
                                          className="mdi mdi-close btn-float-left"
                                          onClick={() =>
                                            this.onClickCloseDetail(
                                              index + "-" + i
                                            )
                                          }
                                        ></i>
                                      </div>
                                      <Scrollbars style={{ height: "85%" }}>
                                        {/* <a onClick={() => this.onClickCloseDetail()} className="closebtn">&times;</a> */}
                                        <div className="row test-row sidebar-nav-body">
                                          <div className="col-12">
                                            <div className="card m-b-20">
                                              <div className="card-body">
                                              <form
                                                      
                                                      >
                                                        <div className="form-group row">
                                                          <div className="col-sm-12 p-0 mr-6 mb-6">
                                                            <input
                                                              className="form-control test-header-title"
                                                              type="text"
                                                              name="subTaskNameInSideDrawer"
                                                              style={{
                                                                marginBottom: 20,
                                                                marginTop: "auto",
                                                                paddingLeft: 0,
                                                              }}
                                                              ref={(el) => {
                                                                if (el) {
                                                                  el.style.setProperty(
                                                                    "font-size",
                                                                    "20px",
                                                                    "important"
                                                                  );
                                                                  el.style.setProperty(
                                                                    "font-family",
                                                                    "'Raleway', sans-serif",
                                                                    "important"
                                                                  );
                                                                  el.style.setProperty(
                                                                    "font-weight",
                                                                    "700px",
                                                                    "important"
                                                                  );
                                                                }
                                                              }}
                                                              
                                                              value={
                                                                item.subTaskTitle
                                                              }
                                                            />
                                                            <div className="flex flex-row items-cente gap-2 ">
                                                              <div className="flex flex-row items-center text-[14px] font-[400] gap-2 .font-roboto color-[#525252]">
                                                                {" "}
                                                                ID:{" "}
                                                                <span className=".font-poppins h-8 flex text-[14px] items-center justify-center text-primary-green bg-[#EBFFF4] px-2 py-1 rounded-md font-[500]">
                                                                  {item.subid}
                                                                </span>
                                                              </div>
                                                              <div className="flex flex-row items-center text-[14px] font-[400] gap-2 .font-roboto color-[#525252]">
                                                                {" "}
                                                                TestSuite:{" "}
                                                                <span className=".font-poppins h-8 flex text-[14px] items-center justify-center text-primary-green bg-[#EBFFF4] px-2 py-1 rounded-md font-[500]">
                                                                  {ele.model ||
                                                                    ele.taskTitle}
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <hr></hr>
                                                        <div
                                                          className="form-group row"
                                                          style={{
                                                            marginTop: "2rem",
                                                          }}
                                                        >
                                                          <label
                                                            for="example-description-input"
                                                            className="pading-right-0px col-sm-3 col-form-label input-label"
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            Description
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <textarea
                                                              id="textarea"
                                                              className="form-control input-style-detail"
                                                              name="subTaskDescriptionInSideDrawer"
                                                              
                                                              value={
                                                                item.description
                                                              }
                                                              maxlength="225"
                                                              rows="6"
                                                              placeholder="Description and Steps to reproduce"
                                                            ></textarea>
                                                          </div>
                                                        </div>
                                                        <div className="form-group row">
                                                          <label
                                                            for="example-summary-input"
                                                            className="col-sm-3 col-form-label input-label"
                                                          >
                                                            Expected Result
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <textarea
                                                              id="textarea"
                                                              className="form-control input-style-detail"
                                                              name="subTaskSummaryInSideDrawer"
                                                              
                                                              value={item.summary}
                                                              maxlength="225"
                                                              rows="2"
                                                              placeholder="Summary"
                                                            ></textarea>
                                                          </div>
                                                        </div>
                                                        <div className="form-group row">
                                                          <label className="col-sm-3 col-form-label input-label">
                                                            Browser
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <select
                                                              className="form-control input-style-detail"
                                                              name="subTaskBrowserInSideDrawer"
                                                              value={item.browser}
                                                              
                                                            >
                                                              
                                                            </select>
                                                          </div>
                                                        </div>
  
                                                        <div className="form-group row">
                                                          <label className="col-sm-3 col-form-label input-label">
                                                            OS
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <select
                                                              name="subTaskOsInSideDrawer"
                                                              value={item.os}
                                                              className="form-control input-style-detail"
                                                              
                                                            >
                                                            </select>
                                                          </div>
                                                        </div>
  
                                                        <div className="form-group row">
                                                          <label className="col-sm-3 col-form-label input-label">
                                                            Type
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <select
                                                              name="subTaskTestingInSideDrawer"
                                                              value={item.testing}
                                                              className="form-control input-style-detail"
                                                              
                                                            >
                                                             
                                                            </select>
                                                          </div>
                                                        </div>
                                                        <div className="form-group row">
                                                          <label className="col-sm-3 col-form-label input-label">
                                                            Priority
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <select
                                                              name="subTaskpriorityInSideDrawer"
                                                              value={
                                                                item.priority
                                                              }
                                                              className="form-control input-style-detail"
                                                              onChange={(e) =>
                                                                this.onModelListTestDetailUpdate(
                                                                  "priority",
                                                                  index,
                                                                  i,
                                                                  item.subtaskId,
                                                                  e.target.value
                                                                )
                                                              }
                                                              
                                                            >
                                                             
                                                            </select>
                                                          </div>
                                                        </div>
  
                                                        <div className="form-group row">
                                                          <label
                                                            for="example-id-input "
                                                            className="col-sm-3 col-form-label input-label"
                                                          >
                                                            Created By
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <input
                                                              name="subTaskCreatedByInSideDrawer"
                                                              className="form-control input-style-detail"
                                                              type="text"
                                                              value={
                                                                (item.username =
                                                                  this.getUsername(
                                                                    item.username
                                                                  ))
                                                              }
                                                              
                                                              placeholder="Created By"
                                                              disabled
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="form-group row">
                                                          <label
                                                            for="example-id-input "
                                                            className="col-sm-3 col-form-label input-label"
                                                          >
                                                            Test type
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <Select
                                                              className=" input-style-detail"
                                                              isMulti
                                                              isSearchable={false}
                                                              value={
                                                                item.test_type
                                                                  ? this.state.testType.filter(
                                                                      ({
                                                                        value,
                                                                      }) =>
                                                                        item.test_type.indexOf(
                                                                          value
                                                                        ) !== -1
                                                                    )
                                                                  : this.state
                                                                      .testType[0]
                                                              }
                                                              
                                                              
                                                              defaultValue={
                                                                this.state
                                                                  .testType[0]
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                         
                                                            
  
                                                        {item.field !== null &&
                                                          item.field.map(
                                                            (eNew, indexNew) => (
                                                              <div className="form-group row">
                                                                <label
                                                                  key={indexNew}
                                                                  for="example-id-input"
                                                                  className="col-sm-3 col-form-label input-label"
                                                                >
                                                                  {eNew.fileName}
                                                                </label>
                                                                <div className="col-sm-8 ml-3">
                                                                  <input
                                                                    type="hidden"
                                                                    name="subTaskCustomFieldInSideDrawer"
                                                                    value={JSON.stringify(
                                                                      eNew
                                                                    )}
                                                                  />
                                                                  <input
                                                                    key={indexNew}
                                                                    className="form-control input-style-detail"
                                                                    type="text"
                                                                    name="subTaskCustomFieldInSideDrawer123"
                                                                    value={
                                                                      eNew.fileValue
                                                                    }
                                                                    ref={(el) => {
                                                                      if (el) {
                                                                        el.style.setProperty(
                                                                          "font-size",
                                                                          "13px",
                                                                          "important"
                                                                        );
                                                                        el.style.setProperty(
                                                                          "font-family",
                                                                          "'Roboto', sans-serif",
                                                                          "important"
                                                                        );
                                                                        el.style.setProperty(
                                                                          "font-weight",
                                                                          "400px",
                                                                          "important"
                                                                        );
                                                                        el.style.setProperty(
                                                                          "color",
                                                                          "#64748B",
                                                                          "important"
                                                                        );
                                                                      }
                                                                    }}
                                                                   
                                                                    placeholder={
                                                                      eNew.fileName
                                                                    }
                                                                  />
                                                                </div>
                                                              </div>
                                                            )
                                                          )}
                                                          
                                                      </form>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Scrollbars>
                    
                                      </div>
                                  
                                </div>
                                )
                              );
                            })}
                      </div>
                      </div>
                    );
                  })}
                  {mergeDataById.length > 0 &&
                  mergeDataById.filter((ele) => ele.type === "DESTINATION").map((ele, index) => {
                    return (
                      <div className="tests">
                        <div
                          className="flex "
                          style={{
                            borderBottom: "0.5px solid #D1D5DB",
                            marginBottom: 3,
                            backgroundColor: "#F8F8F8",
                          }}
                        >
                          <div className="w-0.5 px-0.5 py-3 bg-primary-green" />
                          <div className="model-input  w-full">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flex: 1,
                              }}
                            >
                              {ele.expanded ? (
                                <MdOutlineArrowDropDown
                                  className="text-primary-green"
                                  size={28}
                                  onClick={() => {
                                    ele.expanded = !ele.expanded;
                                    this.setState({
                                      testCasesData: testCasesData,
                                    });
                                  }}
                                />
                              ) : (
                                <MdArrowRight
                                  className="text-black"
                                  size={28}
                                  onClick={() => {
                                    ele.expanded = !ele.expanded;
                                    this.setState({
                                      testCasesData: testCasesData,
                                    });
                                  }}
                                />
                              )}
                            <input
                                autoComplete="off"
                                type="text"
                                placeholder="Add Task Title here"
                                className="input1 w-full"
                                name="model"
                                value={ele.model || ele.taskTitle}
                                disabled
                               
                              />
                              
                            </div>
                          </div>
                        </div>

                        {/* Div Goes Here */}
                        {ele.isCollapse ||
                          ele.lists
                            .filter((el) => {
                              if (this.state.filterProps.length <= 0) {
                                return (
                                  el.subTaskTitle === "" ||
                                  el.subTaskTitle === null ||
                                  el.subTaskTitle
                                    ?.toString()
                                    .toLowerCase()
                                    .includes(search.toLowerCase())
                                );
                              } else {
                                return (
                                  (el.subTaskTitle === "" ||
                                    el.subTaskTitle === null ||
                                    el.subTaskTitle
                                      ?.toString()
                                      .toLowerCase()
                                      .includes(search.toLowerCase())) &&
                                  (this.state.filterProps.includes(
                                    el.testing?.toString().toLowerCase()
                                  ) ||
                                  this.state.filterProps.includes(
                                      el.priority
                                        ? el.priority
                                            ?.toString()
                                            .toLowerCase()
                                        : null
                                    ) ||
                                    this.state.filterProps.some((item) =>
                                      el.test_type
                                        ? el.test_type.includes(item)
                                        : null
                                    ))
                                );
                              }
                            })

                            .map((item, i, row) => {
                              // console.log(
                              // filterProps.includes(
                              //   item.testing.toString().toLowerCase()

                              // )

                              item.testing = item.testing || "manual";
                              
                              return (
                                ele.expanded &&
                                item.subTaskTitle && (
                                  <div
                                    className={`model-list qq pl-12 pr-4 cursor-pointer hover:!bg-[#E8FFF2] ${
                                      item.subTaskTitle ? "" : " !bg-white"
                                    }`}
                                    // onMouseEnter={this.mouseEnter.bind(this)}
                                    // onMouseLeave={this.mouseLeave.bind(this)}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flex: 1,
                                      }}
                                      className={`model-list-testsuite cursor-pointer`}
                                    >
                                      <input
                                        type="text"
                                        autoComplete="off"
                                        placeholder="Add new Test case here"
                                        className={`list-input !placeholder-primary-green ${
                                          item.classNameDependsOnCondtion
                                        } ${
                                          item.subTaskTitle
                                            ? " bg-transparent"
                                            : ""
                                        }`}
                                        name="list"
                                        value={
                                          item.subTaskTitle !== null
                                            ? item.subTaskTitle
                                            : ""
                                        }
                                        onBlur={
                                          (e) =>
                                            this.saveSubTaskChanges(
                                              e.currentTarget.value,
                                              ele.id,
                                              item,
                                              projectNameSort + index + i,
                                              i,
                                              index
                                            )
                                          // console.log(e.currentTarget.value)
                                        }
                                        
                                      />
                                      {item.subTaskTitle ? (
                                        <span
                                          className="mr-8 pl-2 flex items-center open-details-button2 cursor-pointer"
                                          name=""
                                          onClick={() =>
                                            this.onClickDetail(
                                              index + "-" + i,
                                              item.subtaskId
                                            )
                                          }
                                        >
                                          <span className="details-button">
                                            Details
                                          </span>

                                          <svg
                                            className="details-svg"
                                            width="13"
                                            height="13"
                                            viewBox="0 0 13 13"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M6.83333 0.875488H6.25C3.35051 0.875488 1 3.22599 1 6.12549C1 9.025 3.35051 11.3755 6.25 11.3755H6.83333M9.16667 3.79215L11.5 6.12549M11.5 6.12549L9.16667 8.45882M11.5 6.12549H4.5"
                                              stroke="#999999"
                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        </span>
                                      ) : (
                                        <span className="pr-[27px]" />
                                      )}
                                    </div>

                                    <div
                                      style={{
                                        width:
                                          dynamicStyle[index + "-" + i] ||
                                          dynamicStyle,
                                        zIndex: 5000,
                                      }}
                                      className="sidebar-new"
                                    >
                                      <div className="button-items detail-btn">
                                        <i
                                          className="mdi mdi-close btn-float-left"
                                          onClick={() =>
                                            this.onClickCloseDetail(
                                              index + "-" + i
                                            )
                                          }
                                        ></i>
                                      </div>
                                      <Scrollbars style={{ height: "85%" }}>
                                        {/* <a onClick={() => this.onClickCloseDetail()} className="closebtn">&times;</a> */}
                                        <div className="row test-row sidebar-nav-body">
                                          <div className="col-12">
                                            <div className="card m-b-20">
                                              <div className="card-body">
                                              <form
                                                      
                                                      >
                                                        <div className="form-group row">
                                                          <div className="col-sm-12 p-0 mr-6 mb-6">
                                                            <input
                                                              className="form-control test-header-title"
                                                              type="text"
                                                              name="subTaskNameInSideDrawer"
                                                              style={{
                                                                marginBottom: 20,
                                                                marginTop: "auto",
                                                                paddingLeft: 0,
                                                              }}
                                                              ref={(el) => {
                                                                if (el) {
                                                                  el.style.setProperty(
                                                                    "font-size",
                                                                    "20px",
                                                                    "important"
                                                                  );
                                                                  el.style.setProperty(
                                                                    "font-family",
                                                                    "'Raleway', sans-serif",
                                                                    "important"
                                                                  );
                                                                  el.style.setProperty(
                                                                    "font-weight",
                                                                    "700px",
                                                                    "important"
                                                                  );
                                                                }
                                                              }}
                                                              
                                                              value={
                                                                item.subTaskTitle
                                                              }
                                                            />
                                                            <div className="flex flex-row items-cente gap-2 ">
                                                              <div className="flex flex-row items-center text-[14px] font-[400] gap-2 .font-roboto color-[#525252]">
                                                                {" "}
                                                                ID:{" "}
                                                                <span className=".font-poppins h-8 flex text-[14px] items-center justify-center text-primary-green bg-[#EBFFF4] px-2 py-1 rounded-md font-[500]">
                                                                  {item.subid}
                                                                </span>
                                                              </div>
                                                              <div className="flex flex-row items-center text-[14px] font-[400] gap-2 .font-roboto color-[#525252]">
                                                                {" "}
                                                                TestSuite:{" "}
                                                                <span className=".font-poppins h-8 flex text-[14px] items-center justify-center text-primary-green bg-[#EBFFF4] px-2 py-1 rounded-md font-[500]">
                                                                  {ele.model ||
                                                                    ele.taskTitle}
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <hr></hr>
                                                        <div
                                                          className="form-group row"
                                                          style={{
                                                            marginTop: "2rem",
                                                          }}
                                                        >
                                                          <label
                                                            for="example-description-input"
                                                            className="pading-right-0px col-sm-3 col-form-label input-label"
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            Description
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <textarea
                                                              id="textarea"
                                                              className="form-control input-style-detail"
                                                              name="subTaskDescriptionInSideDrawer"
                                                              
                                                              value={
                                                                item.description
                                                              }
                                                              maxlength="225"
                                                              rows="6"
                                                              placeholder="Description and Steps to reproduce"
                                                            ></textarea>
                                                          </div>
                                                        </div>
                                                        <div className="form-group row">
                                                          <label
                                                            for="example-summary-input"
                                                            className="col-sm-3 col-form-label input-label"
                                                          >
                                                            Expected Result
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <textarea
                                                              id="textarea"
                                                              className="form-control input-style-detail"
                                                              name="subTaskSummaryInSideDrawer"
                                                              
                                                              value={item.summary}
                                                              maxlength="225"
                                                              rows="2"
                                                              placeholder="Summary"
                                                            ></textarea>
                                                          </div>
                                                        </div>
                                                        <div className="form-group row">
                                                          <label className="col-sm-3 col-form-label input-label">
                                                            Browser
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <select
                                                              className="form-control input-style-detail"
                                                              name="subTaskBrowserInSideDrawer"
                                                              value={item.browser}
                                                              
                                                            >
                                                            
                                                            </select>
                                                          </div>
                                                        </div>
  
                                                        <div className="form-group row">
                                                          <label className="col-sm-3 col-form-label input-label">
                                                            OS
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <select
                                                              name="subTaskOsInSideDrawer"
                                                              value={item.os}
                                                              className="form-control input-style-detail"
                                                              
                                                            >
                                                            </select>
                                                          </div>
                                                        </div>
  
                                                        <div className="form-group row">
                                                          <label className="col-sm-3 col-form-label input-label">
                                                            Type
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <select
                                                              name="subTaskTestingInSideDrawer"
                                                              value={item.testing}
                                                              className="form-control input-style-detail"
                                                              
                                                            >
                                                             
                                                            </select>
                                                          </div>
                                                        </div>
                                                        <div className="form-group row">
                                                          <label className="col-sm-3 col-form-label input-label">
                                                            Priority
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <select
                                                              name="subTaskpriorityInSideDrawer"
                                                              value={
                                                                item.priority
                                                              }
                                                              className="form-control input-style-detail"
                                                              onChange={(e) =>
                                                                this.onModelListTestDetailUpdate(
                                                                  "priority",
                                                                  index,
                                                                  i,
                                                                  item.subtaskId,
                                                                  e.target.value
                                                                )
                                                              }
                                                              
                                                            >
                                                              
                                                            </select>
                                                          </div>
                                                        </div>
  
                                                        <div className="form-group row">
                                                          <label
                                                            for="example-id-input "
                                                            className="col-sm-3 col-form-label input-label"
                                                          >
                                                            Created By
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <input
                                                              name="subTaskCreatedByInSideDrawer"
                                                              className="form-control input-style-detail"
                                                              type="text"
                                                              value={
                                                                (item.username =
                                                                  this.getUsername(
                                                                    item.username
                                                                  ))
                                                              }
                                                              
                                                              placeholder="Created By"
                                                              disabled
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="form-group row">
                                                          <label
                                                            for="example-id-input "
                                                            className="col-sm-3 col-form-label input-label"
                                                          >
                                                            Test type
                                                          </label>
                                                          <div className="col-sm-8 ml-3">
                                                            <Select
                                                              className=" input-style-detail"
                                                              isMulti
                                                              isSearchable={false}
                                                              value={
                                                                item.test_type
                                                                  ? this.state.testType.filter(
                                                                      ({
                                                                        value,
                                                                      }) =>
                                                                        item.test_type.indexOf(
                                                                          value
                                                                        ) !== -1
                                                                    )
                                                                  : this.state
                                                                      .testType[0]
                                                              }
                                                              
                                                              
                                                              defaultValue={
                                                                this.state
                                                                  .testType[0]
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                         
                                                            
  
                                                        {item.field !== null &&
                                                          item.field.map(
                                                            (eNew, indexNew) => (
                                                              <div className="form-group row">
                                                                <label
                                                                  key={indexNew}
                                                                  for="example-id-input"
                                                                  className="col-sm-3 col-form-label input-label"
                                                                >
                                                                  {eNew.fileName}
                                                                </label>
                                                                <div className="col-sm-8 ml-3">
                                                                  <input
                                                                    type="hidden"
                                                                    name="subTaskCustomFieldInSideDrawer"
                                                                    value={JSON.stringify(
                                                                      eNew
                                                                    )}
                                                                  />
                                                                  <input
                                                                    key={indexNew}
                                                                    className="form-control input-style-detail"
                                                                    type="text"
                                                                    name="subTaskCustomFieldInSideDrawer123"
                                                                    value={
                                                                      eNew.fileValue
                                                                    }
                                                                    ref={(el) => {
                                                                      if (el) {
                                                                        el.style.setProperty(
                                                                          "font-size",
                                                                          "13px",
                                                                          "important"
                                                                        );
                                                                        el.style.setProperty(
                                                                          "font-family",
                                                                          "'Roboto', sans-serif",
                                                                          "important"
                                                                        );
                                                                        el.style.setProperty(
                                                                          "font-weight",
                                                                          "400px",
                                                                          "important"
                                                                        );
                                                                        el.style.setProperty(
                                                                          "color",
                                                                          "#64748B",
                                                                          "important"
                                                                        );
                                                                      }
                                                                    }}
                                                                   
                                                                    placeholder={
                                                                      eNew.fileName
                                                                    }
                                                                  />
                                                                </div>
                                                              </div>
                                                            )
                                                          )}
                                                          
                                                      </form>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Scrollbars>
                    
                                      </div>
                                  
                                </div>
                                )
                              );
                            })}
                      </div>
                    );
                  })}
                </div></div>

              </>)}
              
            </div>
            
          )}

          {/* **************          End of Design Page 3              ************ */}
        </div>
        {this.state.rejectModal && (
          <TwoActionPopup
            label="Reject Merge Request?"
            description="Please provide a detailed feedback on why this merge request is being rejected."
            successText="Reject Request"
            abortText="Cancel"
            onSuccess={this.rejectMergeStatus}
            onAbort={this.handleRejectModal}
            isTextAreaRequired={true}
            textAreaLabel="Reason for Rejection"
            textAreaPlaceHolder="Type your reason here.."
            isDangerPage={true}
            width={530}
            height={420}
          />
        )}
        {this.onTestSuitCreate()}
      </>
    );
  }
}
