import React, { Component } from "react";
import AUX from "../hoc/Aux_";
import { Link } from "react-router-dom";
import {
  BASE_URL,
  EMAIL_REGEX,
  PASS_REGEX,
  LOGO_FULL_URL,
} from "../_helpers/constants";
import { history } from "../_helpers/history";
import Promises from "../_helpers/utils";
import AuthSidebar from "../_components/AuthSidebar";
import Axios from "axios";
import * as CryptoJS from "crypto-js";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

export default class Pages_invite extends Component {
  constructor(props) {
    super(props);
    // alert(this.props.match.params.id)
    this.state = {
      email: "",
      username: "",
      organization: "",
      password: "",
      domain: "",
      country: "",
      submitted: false,
      errorText: "",
      successText: "",
      cyphertext: "",
    };
  }

  componentDidMount = () => {
    try {
      let email = new URL(window.location.href).searchParams.get("id");
      email = email.replace(/ /g, '+');
      console.log("fetched cyphrtext",email)
      var bytes = CryptoJS.AES.decrypt(
        email
          .toString()
          .replace("xMl3Jk", "+")
          .replace("Por21Ld", "/")
          .replace("Ml32", "="),
        "secretkey123"
      );
      console.log("byes cyphrtext", bytes);
      var originalText = bytes.toString(CryptoJS.enc.Utf8);
      console.log("orginal text", originalText);

      if (!originalText) {
        NotificationManager.error("Invalid ULR");
        setTimeout(() => {
          history.push("/register");
        }, 1000);
      } else {
        this.setState({ email: originalText, cyphertext: email });
      }
    } catch {
      NotificationManager.error("Invalid ULR");
      setTimeout(() => {
        history.push("/register");
      }, 1000);
    }
  };

  onRegister = () => {
    const { email, password, username, cyphertext } = this.state;
    this.setState({ submitted: true });

    if (email.length == 0) {
      this.setState({ errorText: "Please enter Email Address" });
    } else if (username.length == 0) {
      this.setState({ errorText: "Please enter Username" });
    } else if (password.length == 0) {
      this.setState({ errorText: "Please enter password" });
    } else if (!PASS_REGEX.test(password)) {
      this.setState({
        errorText:
          "Password must contain Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.",
      });
    } else {
      const data = JSON.stringify({
        email: email,
        firstname: username,
        password: password,
        cyphertext,
      });

      var myHeaders = new Headers();
      myHeaders.append("X-L10N-Locale", "en");
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: data,
        redirect: "follow",
      };

      fetch(`${BASE_URL}auth/invite`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log("res", result);
          if (result.invalidUrl === true) {
            NotificationManager.error("Invalid Invite URL",);
            setTimeout(() => {
              history.push("/register");
            }, 1000);
            return;
          }
          if (result.code === 401) {
            this.setState({ loading: false });
            localStorage.clear();
            history.push("/login?redirect=1");
          }
          if (result.status) {
            this.setState({ successText: "User Registered Successfully" });
            Promises.setUserId(result.id);
            Promises.setToken(result.token);
            Promises.setName(result.firstname);
            Promises.setOrg(result.organization);
            Promises.setLocalIteam(
              "domain",
              window.location.host.split(".")[1]
                ? window.location.host.split(".")[0]
                : "localhost"
            );
            setTimeout(() => {
              history.push("/dashboard");
            }, 1000);
          } else {
            this.setState({
              errorText:
                result.msg || "Something went wrong! please try again later",
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
          localStorage.clear();
          history.push("/login?redirect=1");
        });
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
  };

  validateEmail = (email) => {
    return EMAIL_REGEX.test(String(email).toLowerCase());
  };

  render() {
    const { user, successText, errorText, username, email, password } =
      this.state;

    return (
      <AUX>
        {/* <div className="accountbg" style={{ background: "url('../assets/images/bg-2.jpg')", backgroundSize: "cover", position: 'fixed' }}></div>
				<div className="wrapper-page account-page-full">

					<div className="card">
						<div className="card-body">

							<h3 className="text-center m-0">
								<span style={{
									fontFamily: 'Bungee Outline',
									fontSize: 40,
									letterSpacing: 3,
									fontWeight: 700
								}}>oyetest</span>
							</h3>

							<div className="p-3">
								<h4 className="font-18 m-b-5 text-center">Free Register</h4>
								<p className="text-muted text-center">Sign up to continue to Oyetest.</p>

								<div className="form-horizontal m-t-30">

									<div className="form-group">
										<label for="username">Your Name</label>
										<input type="text" className="form-control" id="username" placeholder="Enter your name" value={username} onChange={(e) => this.setState({ username: e.target.value })} />
									</div>

									<div className="form-group">
										<label for="useremail">Email</label>
										<input type="email" className="form-control" id="useremail" placeholder="Enter email" value={email} onChange={(e) => this.setState({ email: e.target.value })} />
									</div>

									<div className="form-group">
										<label for="userpassword">Password</label>
										<input type="password" className="form-control" id="userpassword" placeholder="Enter password" value={password} onChange={(e) => this.setState({ password: e.target.value })} />
									</div>

									<div className="form-group">
										<label for="userpassword">Mobile Number</label>
										<input type="text" className="form-control" id="userpassword" placeholder="mobile number" value={mobileNumber} onChange={(e) => this.setState({ mobileNumber: e.target.value })} />
									</div>

									{errorText.length > 0 && <div className="alert alert-danger mb-0" role="alert">
										{errorText}
									</div>}

									{successText.length > 0 && <div className="alert alert-success" role="alert">
										{successText}
									</div>}

									<div className="form-group row m-t-20">
										<div className="col-12 text-right">
											<button className="btn btn-primary w-md waves-effect waves-light" onClick={this.onRegister} type="submit">Register</button>
										</div>
									</div>

									<div className="m-t-40 text-center">
										<p className="">Already have an account ? <Link to="/" className="font-500 font-14 font-secondary"> Login </Link> </p>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div> */}

        <div className="custom-container signup-page !pl-[50px]">
          <AuthSidebar />
          <div className="custom-container-2">
            <div className="form-custom-container">
              <h2> Registration </h2>
              <p className="heading-description">
                {" "}
                Already have an account? <Link to="/login">Login</Link>{" "}
              </p>
              {/* <form> */}
              <div className="form-field">
                <label htmlFor="useremail" className="input-label">
                  {" "}
                  Email ID
                </label>
                <input
                  type="email"
                  id="useremail"
                  value={email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                  disabled
                />
              </div>
              <div className="form-field">
                <label htmlFor="username" className="input-label">
                  {" "}
                  Your Full Name{" "}
                </label>
                <input
                  type="text"
                  id="username"
                  value={username}
                  onChange={(e) => this.setState({ username: e.target.value })}
                />
              </div>

              <div className="form-field">
                <label htmlFor="userpassword" className="input-label">
                  {" "}
                  Password{" "}
                </label>
                <input
                  type="password"
                  id="userpassword"
                  value={password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
              </div>
              {/* <div className="form-field">
              <label htmlFor="mobile"> Mobile Number </label>
              <input type="text" id="mobile" value={mobileNumber} onChange={(e) => this.setState({ mobileNumber: e.target.value })}/>
              </div> */}
              {errorText.length > 0 && (
                <div className="alert alert-danger mb-3" role="alert">
                  {errorText}
                </div>
              )}

              {successText.length > 0 && (
                <div className="alert alert-success mb-3" role="alert">
                  {successText}
                </div>
              )}
              <div
                className="login-btn-div- primary_btn cursor-pointer"
                onClick={this.onRegister}
              >
                <button
                  type="submit"
                  // className="btn btn-primary- text-white font-roboto hover:!bg-transparent"
                  onClick={this.onRegister}
                >
                  Sign up
                </button>
              </div>
              {/* </form> */}
            </div>
          </div>
          <div>
            <NotificationContainer />
          </div>
        </div>
      </AUX>
    );
  }
}
