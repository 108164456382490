import React, { useState, useEffect } from "react";
import { ColorRing } from "react-loader-spinner";

const TwoActionPopup = ({
  onAbort,
  onSuccess,
  label,
  description,
  successText,
  abortText,
  isDangerPage = false,
  height,
  width,
  isInputRequired = false,
  isTextAreaRequired = false, 
  textAreaPlaceHolder='',
  textAreaLabel="",
  initialInputValue = "",
  successLoading = false,
  successIcon=false,
}) => {
  const [inputValue, setInputValue] = useState();
  const [isInputValid, setIsInputValid] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState("");
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setIsInputValid(value.trim() === initialInputValue); // Check if input is not empty
  };
  const handleTextAreaChange = (e) => {
    setTextAreaValue(e.target.value); // Update textarea value
  };

  const handleSuccessClick = () => {
    if (isInputRequired && !isInputValid) {
      return; // Prevent success action if input is invalid
    }
    if(isTextAreaRequired){
      onSuccess(textAreaValue);
      return
    }
    onSuccess();
  };
  return (
    <div
      className="!h-full !w-full absolute top-0 left-0 flex justify-center items-center"
      style={{
        zIndex: 9999 - 1,
        backgroundColor: "rgba(128, 128, 128, 0.8)",
      }}
    >
      <div
        className={`bg-white shadow rounded-2xl !opacity-100 px-4 py-4 flex flex-col justify-between overflow-clip b`}
        style={{ height: height ? height : 260, width: width ? width : 400}}
      >
        <div>
         {successIcon?<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF"/>
<rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8"/>
<path d="M38 27.0801V28.0001C37.9988 30.1565 37.3005 32.2548 36.0093 33.9819C34.7182 35.7091 32.9033 36.9726 30.8354 37.584C28.7674 38.1954 26.5573 38.122 24.5345 37.3747C22.5117 36.6274 20.7847 35.2462 19.611 33.4372C18.4373 31.6281 17.8798 29.4882 18.0217 27.3364C18.1636 25.1847 18.9972 23.1364 20.3983 21.4972C21.7994 19.8579 23.6928 18.7155 25.7962 18.2403C27.8996 17.765 30.1003 17.9824 32.07 18.8601M38 20.0001L28 30.0101L25 27.0101" stroke="#27AE60" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
: <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="4" y="4" width="48" height="48" rx="24" fill="#FFD5DA" />
            <rect
              x="4"
              y="4"
              width="48"
              height="48"
              rx="24"
              stroke="#FFEBEB"
              stroke-width="8"
            />
            <path
              d="M27.9998 24V28M27.9998 32H28.0098M26.2898 18.86L17.8198 33C17.6451 33.3024 17.5527 33.6453 17.5518 33.9945C17.5508 34.3437 17.6413 34.6871 17.8142 34.9905C17.9871 35.2939 18.2365 35.5467 18.5375 35.7238C18.8385 35.9009 19.1806 35.9961 19.5298 36H36.4698C36.819 35.9961 37.1611 35.9009 37.4621 35.7238C37.7631 35.5467 38.0124 35.2939 38.1854 34.9905C38.3583 34.6871 38.4488 34.3437 38.4478 33.9945C38.4468 33.6453 38.3544 33.3024 38.1798 33L29.7098 18.86C29.5315 18.5661 29.2805 18.3231 28.981 18.1544C28.6814 17.9858 28.3435 17.8972 27.9998 17.8972C27.656 17.8972 27.3181 17.9858 27.0186 18.1544C26.7191 18.3231 26.468 18.5661 26.2898 18.86Z"
              stroke="#F14336"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>}
        </div>
        <div className="">
          <p
            className="text-[#101828] mb-2 mt-2 font-raleway"
            style={{ fontWeight: 700, fontSize: 20 }}
          >
            {label}
          </p>
          <p
            className="font-roboto text-[#667085] font-roboto text-justify"
            style={{ fontWeight: 400, fontSize: 14 }}
          >
            {description}
          </p>
          {isInputRequired && (
            <div className="mt-4">
              <p
                className="font-roboto text-[#000000] font-roboto text-justify"
                style={{ fontWeight: 500, fontSize: 15, marginBottom: 10 }}
              >
                Type "{initialInputValue}" in the box below to confirm
              </p>
              <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                className="p-2 rounded w-full"
                placeholder="Enter required text"
                style={{
                  borderColor: isInputValid ? "#00CD70 " : "#FF4545",
                  borderWidth: "2px ",
                  borderStyle: "solid",
                  height: 44,
                }}
              />
              {/* {!isInputValid && (
                <span className="text-[#FF4545] text-sm mt-1">
                  This field is required.
                </span>
              )} */}
            </div>
          )}
          {isTextAreaRequired && (
            <div className="mt-2 mb-3">
              <p
                className="font-roboto text-[#000000] text-justify"
                style={{ fontWeight: 500, fontSize: 15, marginBottom: 10 }}
              >
                {textAreaLabel}
              </p>
              <textarea
                value={textAreaValue}
                onChange={handleTextAreaChange}
                className="p-2 rounded w-full text-[12px] text-[#BBBBBB]"
                placeholder={textAreaPlaceHolder}
                style={{
                  borderColor: "#B4B4B4",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  height: 100,
                  resize: "none",
                  fontSize:'12px'
                }}
              />
              <div className="text-right text-sm text-[#667085]">
                {textAreaValue.length}/1000
              </div>
            </div>
          )}
        </div>
        <div className="flex items-center justify-between gap-x-3 ">
          <button
            className="rounded-md  flex-grow h-12 flex items-center justify-center text-[#64748B]"
            style={{
              boxShadow: " 0px 1px 2px 0px #1018280D",
              fontSize: 14,
              fontWeight: 500,
              border: isDangerPage ? "" : "0.5px solid #316BFF",
              borderRadius: 8,
              width: 130,
              background: isDangerPage ? "#F0F0F0" : "#fff",
              color: isDangerPage ? "#757575" : "#64748B",
            }}
            onClick={onAbort}
          >
            {abortText}
          </button>
          <button
            className={`rounded-md border-none flex-grow h-12 w-44 text-white flex items-center justify-center gap-x-2 `}
            onClick={handleSuccessClick}
            style={{
              borderRadius: 8,
              fontSize: 14,
              backgroundColor: isDangerPage ? "#FF0003" : "#316BFF",
            }}
          >
            {successLoading ? (
              <ColorRing
                visible={true}
                height="30"
                width="96"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={[
                  "#ffffff",
                  "#ffffff",
                  "#ffffff",
                  "#ffffff",
                  "#ffffff",
                  "#ffffff",
                ]}
              />
            ) : (
              successText
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TwoActionPopup;