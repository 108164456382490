import React, { useEffect, useState } from "react";
import { BASE_URL } from "../_helpers/constants";
import { history } from "../_helpers/history";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import OtpInput from "react-otp-input";
import Promises from "../_helpers/utils";
import TwoActionPopup from "./TwoActionPopup";
import SuccessComponent from "./SuccessComponent";

const secondsToMinute = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
};

const PendingVerification = ({
  email,
  defaultResendCall = false,
  isForgetPass = false,
  changeStatus,
  isFinalPage = true,
}) => {
  const [otpEntered, setOtpEntered] = useState("");
  const [lastOtpSentTime, setLastOtpSentTime] = useState("");
  const [expiresIn, setExpiresIn] = useState(600);
  const [errorMsg, setErrorMsg] = useState("");
  const [verifyRes, setVerifyRes] = useState(null);
  const [resendRes, setResendRes] = useState(null);
  const [completed, setCompleted] = useState({ status: false, link: "/" });
  //   const [expiredOtp, setExpiredOtp] = useState(false)

  useEffect(() => {
    if (defaultResendCall) {
      onResend();
    }
  }, [defaultResendCall]);

  const verifyOtp = () => {
    const data = JSON.stringify({
      email: email,
      otp: otpEntered,
    });
    if (otpEntered?.length < 6) {
      NotificationManager.error("Please enter valid otp");
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: data,
      redirect: "follow",
    };

    fetch(`${BASE_URL}auth/verify-otp`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setVerifyRes(result);
        console.log("res", result);
        if (result.code === 401) {
          // this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        if (result.status) {
          if (isForgetPass) {
            Promises.setToken(result.token);
            changeStatus("otpEntered");
          } else {
            NotificationManager.success("You have been verified successfully");
            NotificationManager.success(
              "We are redirecting to your workspace please login your workspace."
            );
            // const link = `https://${result.domain}.oyetest.com/login`;
            const link = `https://${result.domain}.joslery.com/login`;
            //   const link = `http://${result.domain}.localhost:3001/login`;
            setCompleted({ status: true, link: link });

            setTimeout(() => {
              window.location = link;
            }, 3000);
          }
        } else {
          setErrorMsg(result.message);
          if (result.error) {
            // this.setState({ errorText: result.error });
            NotificationManager.error("Invalid OTP");
            console.log(result.error);
          }
        }
      })
      .catch((error) => console.log(error));
  };

  const onResend = () => {
    const data = JSON.stringify({
      email: email,
    });
    setVerifyRes(null);
    // const timeDiff = lastOtpSentTime
    //   ? dateDifferenceInSeconds(lastOtpSentTime, new Date())
    //   : -1;
    // if (resendRes && resendRes.status && lastOtpSentTime && timeDiff < 120) {
    // //   NotificationManager.error(
    // //     `Please wait ${secondsToMinute(
    // //       120 - Math.round(timeDiff)
    // //     )} minute before resending OTP`
    // //   );
    //   return;
    // }

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: data,
      redirect: "follow",
    };

    fetch(`${BASE_URL}auth/resend-otp`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setResendRes(result);
        if (!result.status) {
          setErrorMsg(result.message);
        }
        console.log("res", result);
        if (result.code === 401) {
          // this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        if (result.status) {
          NotificationManager.success("OTP has been sent successfully");
          setLastOtpSentTime(new Date());
          setExpiresIn(600);
          setErrorMsg("");
        } else {
          //   NotificationManager.error("Error sending OTP, please try later");
          setErrorMsg(result.message);
          console.log(result);
          //   NotificationManager.error(result.error);
          if (result.error) {
            // this.setState({ errorText: result.error });
          }
        }
      })
      .catch((error) =>console.log({ errorText: error.error }));
  };

  //   function dateDifferenceInSeconds(date1, date2) {
  //     const timestamp1 = date1.getTime();
  //     const timestamp2 = date2.getTime();

  //     const differenceInMilliseconds = timestamp2 - timestamp1;

  //     return differenceInMilliseconds / 1000;
  //   }

  return (
    <div className="flex flex-col items-center justify-center">
      {!completed.status && (
        <div>
          <h2
            className="text-black font-raleway"
            style={{ fontWeight: 700, fontSize: 28 }}
          >
            Please verify your account
          </h2>
          <p
            className="heading-description my-3 mb-4 font-roboto text-[#4F4F4F]"
            style={{ fontWeight: 400, fontSize: 16 }}
          >
            Enter the 6-digit OTP verification code we sent on {email}
          </p>
          {/* <form> */}
          <div className="form-field">
            <div className="otp-input !pl-0 !ml-0 !flex !flex-col">
              <OtpInput
                value={otpEntered}
                className="form-group"
                inputStyle={{
                  width: 90,
                  height: 60,
                  fontSize: 25,
                  borderRadius: 8,
                  border: `1.2px solid var(--${
                    errorMsg?.length > 0 ? "red" : "blue"
                  }, ${errorMsg?.length > 0 ? "red" : "#316BFF"})`,
                  // padding: "0 10px",
                  // marginRight: 12,
                  fontFamily: "Poppins",
                  fontWeight: 500,
                }}
                onChange={(e) => setOtpEntered(e)}
                numInputs={6}
                isInputNum={true}
                separator={<span className="px-2"></span>}
                renderInput={(props) => {
                  console.log("otp render item ", props);
                  return (
                    <input
                      style={{
                        background: props.inputProps ? "red" : "#E8ECF4",
                      }}
                      {...props}
                    />
                  );
                }}
                containerStyle={{ display: "flex", alignItems: "center" }}
              />
              {errorMsg && <p className="text-sm text-red-500">{errorMsg}</p>}
              {resendRes &&
                resendRes.status &&
                expiresIn > 0 &&
                !verifyRes?.otpExpired && (
                  <p className="mt-3 opacity-70">
                    Code expires in:{" "}
                    <span className="text-sm text-red-500">
                      <CooldownTimer
                        expiresIn={expiresIn}
                        setExpiresIn={setExpiresIn}
                      />
                    </span>
                  </p>
                )}
            </div>
          </div>

          {/* <div className="otp-timer"> 
                Code expires in: <span className="timer"> 00:43 </span>  
              </div> */}

          <div className="login-btn-div">
            <button
              className="bg-primary-green primary_btn text-white py-3"
              // style={{ borderRadius: 8, fontWeight: 500, fontSize: 14 }}
              onClick={() => verifyOtp()}
            >
              Verify
            </button>
          </div>
          <p
            className="font-roboto flex mt-3 text-secondary-gray"
            style={{
              fontWeight: 400,
              fontSize: 16,
              opacity: 0.7,
            }}
          >
            Didn’t get the code?{" "}
            <p
              className="ml-2 text-primary-blue cursor-pointer"
              onClick={onResend}
            >
              Resend
            </p>{" "}
          </p>
          {/* </form> */}
        </div>
      )}
      {completed.status && (
        <div>
          <SuccessComponent
            label="Account created successfully"
            description="Congrats! Your account has been activated. You are being redirected to the dashboard"
            successText="Login"
            successAction={() => {
              window.location = completed.link;
            }}
          />
        </div>
      )}
      <NotificationContainer />
      {(expiresIn <= 0 || verifyRes?.otpExpired) && (
        <TwoActionPopup
          label="OTP has expired!!"
          description="You have reached the maximum time allowed to verify. Please wait
        resend or try again later."
          onSuccess={() => {
            // history.push("/contactus");
            onResend();
            setExpiresIn(600);
            setErrorMsg("");
          }}
          onAbort={() => {
            history.push("/login?redirect=1");
          }}
          successText="Resend"
          abortText="Close"
        />
      )}
      {(resendRes?.invalidEmail || resendRes?.unRegisteredUser) && (
        <TwoActionPopup
          label="Account not found!!"
          description={resendRes.message}
          onSuccess={() => {
            history.push("/register");
          }}
          onAbort={() => {
            history.push("/login?redirect=1");
          }}
          successText="Sign up"
          abortText="Login"
        />
      )}
      {resendRes?.maxResendReached && (
        <TwoActionPopup
          label="Too many resend requests!!"
          description={resendRes.message}
          onSuccess={() => {
            history.push("/contactus");
          }}
          onAbort={() => {
            history.push("/login?redirect=1");
          }}
          successText="Contact us"
          abortText="Close"
        />
      )}
      {verifyRes?.otpNotFound && (
        <TwoActionPopup
          label="Too many incorrect attempts!!"
          description={verifyRes.message}
          onSuccess={() => {
            // history.push("/contactus");
            onResend();
            setExpiresIn(600);
            setErrorMsg("");
          }}
          onAbort={() => {
            history.push("/login?redirect=1");
          }}
          successText="Resend"
          abortText="Close"
        />
      )}
    </div>
  );
};

const CooldownTimer = ({ expiresIn, setExpiresIn }) => {
  //   const [cooldown, setCooldown] = useState(initialCooldown);

  useEffect(() => {
    // Start the interval when the component mounts
    // console.log("Received cooldown", initialCooldown, cooldown);
    // setCooldown(initialCooldown);

    const id = setInterval(() => {
      setExpiresIn((prevCooldown) => {
        if (prevCooldown > 0) {
          return prevCooldown - 1;
        } else {
          //   clearInterval(id);
          return 0;
        }
      });
    }, 1000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(id);
  }, []);

  return <span> {secondsToMinute(expiresIn)}</span>;
};

export default PendingVerification;
