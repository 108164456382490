import React, { Component } from "react";
import "../styles/Dashboard.css";
// import "../styles/component/_testRun.css";
import { BASE_URL } from "../_helpers/constants";
import Promises, { formatDate } from "../_helpers/utils";
// import moment from "moment";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import Select from "react-select";
import { MdDeleteForever } from "react-icons/md";
import { IoSearchOutline } from "react-icons/io5";
import { history } from "../_helpers/history";
import { confirmAlert } from "react-confirm-alert";
import { filter } from "underscore";
import TwoActionPopup from "./TwoActionPopup";
import Table from "./Table";
import MultiSelectDropdown from "./MultiSelect";

export default class Administrator extends Component {
  constructor() {
    super();
    this.state = {
      plist: [],
      selectedOption1: null,
      selectedOption2: null,
      isSubmit: false,
      newList: "",
      search: "",
      testCasesData: [],
      hiddenSections: [],
      projectId: "",
      email: "",
      email_err: "",
      name: "",
      name_err: "",
      pwd: "",
      pwd_err: "",
      pwd1: "",
      pwd1_err: "",
      url: "",
      url_err: "",
      digits: "",
      digits_err: "",
      number: "",
      number_err: "",
      alpha_num: "",
      alpha_num_err: "",
      min: "",
      min_err: "",
      filter: {
        role: [0, 1, 2],
        status: [0, 1],
      },
      twoOptionModal: {
        open: false,
        successText: "",
        abortText: "",
        description: "",
        label: "",
        onAbort: () => {},
        onSuccess: () => {},
        isDangerPage: false,
      },
    };
    this.handleSubmit1 = this.handleSubmit1.bind(this);
    this.handleSubmit2 = this.handleSubmit2.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
  }

  columns = [
    // { field: "id", headerName: "ID", width: 100 },
    { field: "email", headerName: "Email ID", width: 220 },
    {
      field: "firstname",
      headerName: "Name",
      width: 200,
      renderCell: (e) => (
        <div
          className="vertical-center-flex !text-[#232323]"
          style={{ opacity: e.firstname ? 1 : 0.5 }}
        >
          <p className="mt-0 mb-0">{e.firstname || "Unregistered User"}</p>
        </div>
      ),
    },
    {
      field: "invitedBy",
      headerName: "Invited By",
      // enableSorting: true,
      width: 220,
      renderCell: (e) => {
        return (
          <div className=" vertical-center-flex !text-[#232323]">
            <p className="mt-0 mb-0 flex flex-row gap-x-1 items-center">
              <img
                src={
                  e.createdByImgUrl ||
                  process.env.PUBLIC_URL + "/assets/images/default-img.png"
                }
                alt="user"
                className="rounded-circle w-8 h-8"
              />
              {e.invitedBy ? e.invitedBy : "NA"}
            </p>
          </div>
        );
      },
    },
    {
      field: "isEnable",
      headerName: "Status",
      width: 80,
      renderCell: (e) => (
        <div
          className={` vertical-center-flex font-robot !font[400] text[14px]`}
        >
          {e.isEnable == 1 && (
            <p className="mt-0 mb-0 !text-[#00CD70]">Registered</p>
          )}
          {e.isEnable == 0 && (
            <p className="mt-0 mb-0 !text-[#F09C1E]">Pending</p>
          )}
        </div>
      ),
    },
    {
      field: "role",
      headerName: "Role",
      width: 80,
      renderCell: (e) => (
        <div className=" pl-0 flex items-center !text-[#232323]">
          {e.role === 1 && <p className="mt-0 mb-0">Owner</p>}
          {e.role === 0 && Promises.getLocalIteam("projectRole") == 1 && (
            <select
              className="form-select select-role pl-0 !h-4 pr-2 !bg-transparent"
              aria-label="Default select example"
              ref={(el) =>
                el && el.style.setProperty("font-size", "14px", "important")
              }
              onChange={(ee) => {
                this.handleChange2(ee, e.email, e.firstname || " this user ");
              }}
              value={e.role === 0 ? "User" : "Admin"}
            >
              <option value="0">User</option>
              <option value="2">Admin</option>
            </select>
          )}
          {e.role === 0 && Promises.getLocalIteam("projectRole") != 1 && (
            <p className="mt-0 mb-0">User</p>
          )}
          {e.role === 2 && <p className="mt-0 mb-0">Admin</p>}
        </div>
      ),
    },
    {
      field: "verifiedAt",
      headerName: "Joining Date",
      width: 190,
      renderCell: (e) => (
        <div className=" pl-0 flex items-center !text-[#232323]">
          <p className="mt-0 mb-0" style={{ opacity: e.verifiedAt ? 1 : 0.5 }}>
            {e.verifiedAt ? formatDate(e.verifiedAt) : "Not joined yet"}
          </p>
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "",
      width: 150,
      renderCell: (e) => (
        <div className="!w-[8rem]">
          <p
            className="mt-0 mb-0 font-poppins !font-[500] !text-primary-blue- action-button"
            style={{
              cursor: "pointer",
              fontSize: 14,
              color:
                e.isResendDisabled || e.userId === Promises.getUserId()
                  ? "#A7ABB5"
                  : "rgb(49 107 255)",
            }}
            onClick={() => {
              if (e.isResendDisabled || e.userId === Promises.getUserId())
                return;
              this.handleSubmit2(e.email);
            }}
          >
            Resend invite
          </p>
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "",
      width: 80,
      renderCell: (e) => (
        <div
          className=" flex flex-row gap-x-1 items-center text-secondary-error text-[14px] cursor-pointer font-poppins !font-[500] action-button"
          style={{ opacity: e.userId === Promises.getUserId() ? 0.5 : 1 }}
          onClick={() => {
            if (e.userId === Promises.getUserId()) return null;
            this.handleRemove(e.email, e.firstname || " this user ");
          }}
        >
          <MdDeleteForever size={14} />
          Remove
        </div>
      ),
    },
  ];

  /*
  .##........#######...######...####..######...######.
  .##.......##.....##.##....##...##..##....##.##....##
  .##.......##.....##.##.........##..##.......##......
  .##.......##.....##.##...####..##..##........######.
  .##.......##.....##.##....##...##..##.............##
  .##.......##.....##.##....##...##..##....##.##....##
  .########..#######...######...####..######...######.
  */
  componentDidMount = () => {
    this.setState({ loading: true, projectId: this.props.projectId }, () => {
      // this.getTestCasesData('initial')
      this.getProjectEmail();
      let projects = JSON.parse(Promises.getLocalIteam("projects")),
        plist = [{ label: "All Projects", checked: true, type: "all" }];

      projects.forEach((element) => {
        plist.push({
          value: element.id,
          label: element.name,
          key: element.name,
          checked: false,
          type: "val",
        });
      });
      this.setState({ plist: plist });
      console.log("this.props.projectId==>" + this.props.projectId);
    });
  };
  handleChangeEmail(e) {
    this.setState({ email: e.target.value });
    var EmailReg =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (e.target.value == "") {
      this.setState({ email_err: "Required Field", isSubmit: false });
    } else if (EmailReg.test(e.target.value))
      this.setState({ email_err: "", isSubmit: true });
    else this.setState({ email_err: "Enter Valid Email", isSubmit: false });
  }
  handleSubmit1() {
    this.onCreateUser();
  }
  handleRemove(email, name = "") {
    this.setState({
      twoOptionModal: {
        open: true,
        successText: "Remove",
        abortText: "Cancel",
        description: `Removing ${name} from the team will revoke their team access. This action cannot be undone.`,
        label: `Remove ${name} from Team?`,
        onAbort: () => {
          console.log("canceled");
          this.setState({
            twoOptionModal: { ...this.state.twoOptionModal, open: false },
          });
        },
        onSuccess: () => {
          this.handleDelete(email);
          this.setState({
            twoOptionModal: { ...this.state.twoOptionModal, open: false },
          });
        },
        isDangerPage: true,
      },
    });
    // confirmAlert({
    //   className: "delete-project-modal",
    //   message: "Are you sure to Remove this user?",
    //   buttons: [
    //     {
    //       className: "btn btn-primary delete-project-yes",
    //       label: "Yes",
    //       onClick: () => this.handleDelete(email),
    //     },
    //     {
    //       className: "btn btn-secondary delete-project-no",
    //       label: "Cancel",
    //       onClick: () => console.log("canceled"),
    //     },
    //   ],
    // });
  }
  handleDelete(email) {
    const token = Promises.getToken();
    const orgId = Promises.getOrg();

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var raw = JSON.stringify({
      email: email,
      orgId: orgId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BASE_URL}projects/remove/org/user`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (JSON.parse(result).code === 200) {
          NotificationManager.success(JSON.parse(result).msg);
          this.componentDidMount();
        } else {
          NotificationManager.error(JSON.parse(result).msg);
        }
      })
      .catch((error) => {
        NotificationManager.error("Something went wrong ");
      });
  }
  handleSubmit2(email) {
    // const { selectedOption1 } = this.state;
    const token = Promises.getToken();
    const orgId = Promises.getOrg();

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var raw = JSON.stringify({
      email: email,
      orgId: orgId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BASE_URL}projects/invite`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (JSON.parse(result).code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        if (JSON.parse(result).code === 403) {
          this.setState({
            dataEmail: this.state.dataEmail.map((el) => {
              if (el.email === email) {
                console.log("found user", el);
                return { ...el, isResendDisabled: 1 };
              }
              return el;
            }),
          });
        }
        if (JSON.parse(result).code === 200) {
          NotificationManager.success("Invited successfully");
        } else {
          NotificationManager.error(JSON.parse(result).msg);
        }
      })
      .catch((error) => {
        NotificationManager.error(
          "Something went wrong with creating project, Please try again later"
        );
        localStorage.clear();
        history.push("/login?redirect=1");
      });
  }
  updateRole = (email) => {
    const token = Promises.getToken();

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var raw = JSON.stringify({
      email: email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BASE_URL}auth/update/role`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (JSON.parse(result).code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        if (JSON.parse(result).code === 200) {
          NotificationManager.success(JSON.parse(result).msg);
          this.setState({ email: "", email_err: "", isSubmit: true });
          this.getProjectEmail();
          this.setState({ selectedOption1: null });
        } else {
          NotificationManager.error(JSON.parse(result).msg);
          this.setState({ email: "", email_err: "", isSubmit: true });
          this.setState({ selectedOption1: null });
        }
      })
      .catch((error) => {
        localStorage.clear();
        history.push("/login?redirect=1");
        NotificationManager.error(
          "Something went wrong with creating project, Please try again later"
        );
      });
  };
  handleUpdateRole = (email) => {
    confirmAlert({
      className: "delete-project-modal",
      message: "You can't change it back. Are you sure?",
      buttons: [
        {
          className: "btn btn-primary delete-project-yes",
          label: "Yes",
          onClick: () => this.updateRole(email),
        },
        {
          className: "btn btn-secondary delete-project-no",
          label: "Cancel",
          onClick: () => console.log("canceled"),
        },
      ],
    });
  };

  onCreateUser = () => {
    const { email, selectedOption1 } = this.state;
    const token = Promises.getToken();
    const orgId = Promises.getOrg();

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var raw = JSON.stringify({
      email: email,
      orgId: orgId,
      projects: selectedOption1,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BASE_URL}projects/invite`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (JSON.parse(result).code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        if(JSON.parse(result).dupOrgUser){
          NotificationManager.warning(JSON.parse(result).msg)
          this.setState({ email: "", email_err: "", isSubmit: true });
          return;
        }
        if (JSON.parse(result).code === 200) {
          NotificationManager.success(JSON.parse(result).msg);
          this.setState({ email: "", email_err: "", isSubmit: true });
          this.getProjectEmail();
          this.setState({ selectedOption1: null });
        } else {
          NotificationManager.error(JSON.parse(result).msg);
          this.setState({ email: "", email_err: "", isSubmit: true });
          this.setState({ selectedOption1: null });
        }
      })
      .catch((error) => {
        NotificationManager.error(
          "Something went wrong with creating project, Please try again later"
        );
        localStorage.clear();
        history.push("/login?redirect=1");
      });
  };
  handleChange1 = (selectedOption1) => {
    this.setState({ selectedOption1 });
  };
  handleChange2 = (e, mail, name) => {
    if (e.target.value === "2") {
      // this.updateRole(mail);

      this.setState({
        twoOptionModal: {
          open: true,
          successText: "Confirm",
          abortText: "Cancel",
          description: `This will grant ${name} access to change roles, settings and more. This action cannot be undone.`,
          label: `Make ${name} admin?`,
          onAbort: () => {
            this.setState({
              dataEmail: this.state.dataEmail.map((el) => {
                if (el.email === mail) {
                  console.log("found user", el);
                  return { ...el, role: 0 };
                }
                return el;
              }),
              twoOptionModal: { ...this.state.twoOptionModal, open: false },
            });
          },
          onSuccess: () => {
            this.updateRole(mail);
            this.setState({
              twoOptionModal: { ...this.state.twoOptionModal, open: false },
            });
          },
        },
      });

      // confirmAlert({
      //   className: "update-user-modal",
      //   message: "Once updated you can't change it back. Are you sure?",
      //   buttons: [
      //     {
      //       className: "btn btn-primary delete-project-yes",
      //       label: "Yes",
      //       onClick: () => this.updateRole(mail),
      //     },
      //     {
      //       className: "btn btn-secondary delete-project-no",
      //       label: "Cancel",
      //       onClick: () => {
      //         this.setState({
      //           dataEmail: this.state.dataEmail.map((el) => {
      //             if (el.email === mail) {
      //               console.log("found user", el);
      //               return { ...el, role: 0 };
      //             }
      //             return el;
      //           }),
      //         });
      //         console.log("canceled");
      //       },
      //     },
      //   ],
      // });
    }
    // this.setState({ selectedOption2 });
  };
  getProjectEmail = () => {
    const token = Promises.getToken();
    const orgId = Promises.getOrg();

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BASE_URL}projects/org/user/${orgId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("FETCHING org rmail ===========>", result);
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        if (!result.status) {
          // this.setState({ testCasesEmpty: true, loading: false })
        } else {
          console.log("getProjectEmail-==>" + JSON.stringify(result));
          this.setState({ dataEmail: result.data });
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ loading: false });
        localStorage.clear();
        history.push("/login?redirect=1");
      });
  };

  /*
  ..######...#######..##.....##.########...#######..##....##.########.##....##.########
  .##....##.##.....##.###...###.##.....##.##.....##.###...##.##.......###...##....##...
  .##.......##.....##.####.####.##.....##.##.....##.####..##.##.......####..##....##...
  .##.......##.....##.##.###.##.########..##.....##.##.##.##.######...##.##.##....##...
  .##.......##.....##.##.....##.##........##.....##.##..####.##.......##..####....##...
  .##....##.##.....##.##.....##.##........##.....##.##...###.##.......##...###....##...
  ..######...#######..##.....##.##.........#######..##....##.########.##....##....##...
  */

  render() {
    const { dataEmail, search, selectedOption1, plist, selectedOption2 } =
      this.state;
    const userId = Promises.getUserId();
    return (
      <div
        className="card invite-user-dashboard border-0 m-b-20 !p-0 !px-[78px] !pt-8 !static !overflow-hidden"
        style={{ height: "95vh" }}
      >
        <p className="primary_heading" style={{ marginBottom: 10 }}>
          Invite user to join the team
        </p>

        <form className=" flex flex-row items-center-" action="#">
          <div className="flex flex-row !items-center justify-start !gap-x-3 mb-8">
            <div className="mr-2" style={{ width: 320 }}>
              <label>Email ID</label>
              <div className="relative">
                <input
                  type="text"
                  value={this.state.email}
                  onChange={this.handleChangeEmail}
                  className="form-control input-design placeholder:!font-[300] placeholder:!text-[#64748B] !font-roboto o"
                  placeholder="Enter user email-adress here..."
                  style={{
                    border: "1px solid #D0D5DD",
                    borderRadius: 4,
                  }}
                />
                <div className="!h-[10] absolute left-2 text-sm">
                  <div id="err" style={{ color: "red" }}>
                    {this.state.email_err}
                  </div>
                </div>
              </div>
            </div>

            <div className="min-w-[320px] mr-2 pt-[23px]">
              <label className="control-label">Projects</label>
              <div className="w-80 pt-3-">
                {/* <Select
                  classNamePrefix="invite-select- text-robto"
                  value={
                    selectedOption1 && selectedOption1.length > 0
                      ? selectedOption1
                      : null
                  }
                  placeholder="Choose a project"
                  onChange={this.handleChange1}
                  isMulti={true}
                  options={plist}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      borderColor: state.isFocused ? "#121C73" : "#D0D5DD",
                      fontSize: 12,
                      borderRadius: 4,
                      fontWeight: 300,
                      fontFamily: "Roboto",
                      color: "##64748B",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: 44,
                      minHeight: 44,
                      padding: "0 8px",
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      height: "100%",
                      padding: "0 8px",
                      display: "flex",
                      alignItems: "center",
                    }),
                    input: (provided) => ({
                      ...provided,
                      margin: 0,
                      padding: 0,
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }),
                    multiValue: (provided) => ({
                      ...provided,
                      display: "flex",
                      alignItems: "center",
                      height: 20,
                    }),
                    multiValueLabel: (provided) => ({
                      ...provided,
                      fontSize: 12,
                      display: "flex",
                      alignItems: "center",
                    }),
                    multiValueRemove: (provided) => ({
                      ...provided,
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      fontSize: 12,
                      color: "#64748B",
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      display: "flex",
                      alignItems: "center",
                    }),
                    clearIndicator: (provided) => ({
                      ...provided,
                      display: "flex",
                      alignItems: "center",
                    }),
                  }}
                /> */}
                <MultiSelectDropdown
                  title={"Choose project"}
                  id={"projects"}
                  items={plist}
                  onChange={this.handleChange1}
                />
              </div>
            </div>

            <div className="mt-[25px]">
              <div />
              <button
                disabled={!this.state.isSubmit}
                onClick={this.handleSubmit1}
                type="button"
                className="primary_btn"
              >
                Send Invite
              </button>
            </div>
          </div>
        </form>
        <div
          className="flex justify-between items-center w-full"
          style={{ marginTop: 2 }}
        >
          <div className="flex flex-row gap-x-3 items-center">
            <div
              style={{
                position: "relative",
              }}
            >
              <input
                autoComplete="off"
                type="text"
                className="form-control !placeholder-[#316BFF]"
                placeholder="Search"
                value={search}
                onChange={(e) => this.setState({ search: e.target.value })}
                style={{
                  border: "0.3px solid #316BFF",
                  borderRadius: 8,
                  height: 40,
                  fontSize: 12,
                  width: 230,
                  background: "rgba(146, 198, 255, 0.1)",
                }}
              />
              <span
                style={{
                  position: "absolute",
                  color: "#316BFF",
                  top: 12,
                  right: 22,
                }}
              >
                <IoSearchOutline />
              </span>
            </div>
          </div>
          <div className="relative !r-0 !h-[40px]" style={{ height: 40 }}>
            <button
              type="button"
              onClick={() =>
                this.setState({ showFilter: !this.state.showFilter })
              }
              style={{
                position: "relative",
                width: 110,
                background: "rgba(146, 198, 255, 0.1)",
                borderRadius: 8,
              }}
              className="px-3 border rounded-md !flex !flex-row !items-center !justify-center gap-x-3 !text-[#316BFF] !border-[#316BFF] py-2"
            >
              <svg
                width="11"
                height="10"
                viewBox="0 0 11 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.15 4.11744C7.56459 4.11737 7.9681 3.97419 8.30054 3.70918C8.63299 3.44417 8.87663 3.07149 8.99525 2.64651H10.45C10.5959 2.64651 10.7358 2.58452 10.8389 2.47418C10.9421 2.36384 11 2.21418 11 2.05814C11 1.90209 10.9421 1.75244 10.8389 1.6421C10.7358 1.53175 10.5959 1.46977 10.45 1.46977H8.99525C8.87643 1.04501 8.63271 0.672578 8.30028 0.407795C7.96785 0.143012 7.56445 0 7.15 0C6.73555 0 6.33215 0.143012 5.99972 0.407795C5.66729 0.672578 5.42357 1.04501 5.30475 1.46977H0.55C0.404131 1.46977 0.264236 1.53175 0.161091 1.6421C0.0579462 1.75244 0 1.90209 0 2.05814C0 2.21418 0.0579462 2.36384 0.161091 2.47418C0.264236 2.58452 0.404131 2.64651 0.55 2.64651H5.30475C5.42337 3.07149 5.66701 3.44417 5.99946 3.70918C6.3319 3.97419 6.73541 4.11737 7.15 4.11744ZM0.55 7.35349C0.404131 7.35349 0.264236 7.41548 0.161091 7.52582C0.0579462 7.63616 0 7.78582 0 7.94186C0 8.09791 0.0579462 8.24756 0.161091 8.35791C0.264236 8.46825 0.404131 8.53023 0.55 8.53023H1.72975C1.84857 8.95499 2.09229 9.32742 2.42472 9.59221C2.75715 9.85699 3.16055 10 3.575 10C3.98945 10 4.39285 9.85699 4.72528 9.59221C5.05771 9.32742 5.30143 8.95499 5.42025 8.53023H10.45C10.5959 8.53023 10.7358 8.46825 10.8389 8.35791C10.9421 8.24756 11 8.09791 11 7.94186C11 7.78582 10.9421 7.63616 10.8389 7.52582C10.7358 7.41548 10.5959 7.35349 10.45 7.35349H5.42025C5.30143 6.92873 5.05771 6.5563 4.72528 6.29152C4.39285 6.02674 3.98945 5.88372 3.575 5.88372C3.16055 5.88372 2.75715 6.02674 2.42472 6.29152C2.09229 6.5563 1.84857 6.92873 1.72975 7.35349H0.55Z"
                  fill="#316BFF"
                />
              </svg>
              Filter
            </button>
            {this.state.showFilter && (
              <div
                className="absolute top-[54px] w-[250px] h-[350px] bg-white z-50 right-0 rounded-[8px] py-4 flex flex-col justify-between px-4"
                style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
              >
                <div className="flex flex-col justify-start">
                  <p
                    className="border-b font-raleway pb-1"
                    style={{
                      color: "#090A0A",
                      fontSize: 15,
                    }}
                  >
                    Select Filter
                  </p>
                  {[
                    {
                      label: "Role",
                      field: "role",
                      list: [
                        { label: "Admin", val: 2 },
                        { label: "User", val: 0 },
                      ],
                    },
                    {
                      label: "Register Status",
                      field: "status",
                      list: [
                        { label: "Registered", val: 1 },
                        { label: "Pending", val: 0 },
                      ],
                    },
                  ].map((item, i) => {
                    return (
                      <div className="">
                        <p className="font-robot font-semibold !text-[14px] opacity-75 mb-1 mt-3 text-[#646464]">
                          {item.label}
                        </p>
                        <div>
                          {item.list.map((op) => {
                            return (
                              <div className="flex flex-row gap-x-2 items-center mb-1">
                                <input
                                  className="h-4 w-4"
                                  type="checkbox"
                                  checked={this.state.filter[
                                    item.field
                                  ].includes(op.val)}
                                  onClick={() => {
                                    const filter = { ...this.state.filter };
                                    if (filter[item.field].includes(op.val)) {
                                      filter[item.field] = filter[
                                        item.field
                                      ].filter((id) => id !== op.val);
                                    } else {
                                      filter[item.field].push(op.val);
                                    }
                                    this.setState({ filter });
                                  }}
                                />
                                <p className="font-roboto !text-[14px] text-primary-gray !font-[400]">
                                  {op.label}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="flex flex-row-reverse items-center gap-x-2">
                  <button
                    className="rounded-md  flex-grow !h-[29px] flex items-center justify-center text-[#757575] bg-[#F0F0F0]"
                    style={{
                      boxShadow: " 0px 1px 2px 0px #1018280D",
                      fontSize: 14,
                      fontWeight: 500,
                      border: "0.5px solid #316BFF",
                      borderRadius: 8,
                      width: 130,
                    }}
                    // onClick={onAbort}
                  >
                    Close
                  </button>
                  <button
                    className="rounded-md border-none bg-primary-blue !h-[29px] w-44 text-white flex items-center justify-center gap-x-2 flex-grow"
                    style={{ borderRadius: 8, fontSize: 14 }}
                  >
                    Apply
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* {dataEmail?.length>0 && ( */}
        <div className="!overflow-scroll !h-[57vh] border rounded-md mt-2">
          <Table
            columns={this.columns}
            data={
              dataEmail?.filter(
                (el) =>
                  (el.firstname.toLowerCase().includes(search.toLowerCase()) ||
                    el.email.toLowerCase().includes(search.toLowerCase())) &&
                  this.state.filter.role.includes(el.role) &&
                  this.state.filter.status.includes(el.isEnable)
              ) || []
            }
          />
        </div>
        {/* )} */}

        <NotificationContainer />
        {this.state.twoOptionModal.open && (
          <TwoActionPopup
            label={this.state.twoOptionModal.label}
            description={this.state.twoOptionModal.description}
            successText={this.state.twoOptionModal.successText}
            abortText={this.state.twoOptionModal.abortText}
            onSuccess={this.state.twoOptionModal.onSuccess}
            onAbort={this.state.twoOptionModal.onAbort}
            isDangerPage={this.state.twoOptionModal.isDangerPage}
            height={300}
          />
        )}
      </div>
    );
  }
}
