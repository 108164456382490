import React, { Component } from "react";
import { Link } from "react-router-dom";
import { history } from "../_helpers/history";
import Promises from "../_helpers/utils";
import { BASE_URL, LOGO_FULL_URL, recaptha_siteKey } from "../_helpers/constants";
import Modal from "react-modal";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import ReCAPTCHA from "react-google-recaptcha";
import Footer from "../_components/Footer";
import { FooterSection, LandingNav } from "./Pages_landing";

export default class Pages_Pricing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      createModalVisible: false,
      email: "",
      name: "",
      msg: "",
      email_err: "",
      isSubmit: false,
      captcha: "",
      showDrop: false,
      isPlan: false,
      viewState: 0,
      selectedOption1: null,
      plan_data: [],
      switch1: true,
      showPricingPlanNotification: true,
    };
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
  }

  componentWillMount() {
    Modal.setAppElement("body");
  }
  componentDidMount() {
    Promises.setPage("price");
    this.setState({ loading: true }, () => {
      this.getOrgIntigration();
    });

    const siteUrl = window.location.search;
    const urlParams = new URLSearchParams(siteUrl);
    if (urlParams.has("session_id")) {
      console.log("session_id", urlParams.get("session_id")); // 159
      this.saveSession(urlParams.get("session_id"));
    }
  }
  formatNumber(value) {
    let number = parseFloat(value);  // Convert the string to a float
    if (Number.isInteger(number)) {  // Check if the number is an integer
        return parseInt(number);     // Return it as an integer (remove the decimal)
    } else {
        return value;                // Return the original string if it has a fractional part
    }
}

  handler(type = 1) {
    if (type === 1) {
      this.setState({ selectedTab: "test-result" });
    } else {
      this.setState({ selectedTab: "test-case" });
    }
  }
  handlerAdmin() {
    this.setState({ selectedTab: "administrator" });
    this.setState({ viewState: 2 });
  }
  onLogout() {
    localStorage.clear();
  }

  toggleprofile() {
    this.setState((prevState) => ({
      dropdownOpenprofile: !prevState.dropdownOpenprofile,
    }));
  }

  getOrgIntigration = (type = "1m") => {
    const token = Promises.getToken();

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BASE_URL}plan/${type}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          console.log("===================>", result);
          this.setState({
            isPlan: true,
            plan_data: result.data,
          });
        } else {
          this.setState({
            isPlan: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  ///session/save

  saveSession = (sessionId = "") => {
    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var raw = JSON.stringify({
      session_id: sessionId,
      // description: this.state.description,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: raw,
    };

    fetch(`${BASE_URL}plan/session/save`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          NotificationManager.success("Project Update successfully");
          setTimeout(() => {
            this.props.history.push("/dashboard");
          }, 1000);
        } else {
          NotificationManager.error(
            "Something went wrong with Updating project, Please try again later"
          );
        }
      })
      .catch((error) => {
        // debugger;
        console.log("error", error);
        NotificationManager.error(
          "Something went wrong with Updating project, Please try again later"
        );
      });
  };
  getSessionURL = (id) => {
    const token = Promises.getToken();

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BASE_URL}plan/session/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          console.log("===================>", result);
          if (result.data) {
            window.location.href = result.data;
          }
        } else {
          this.setState({
            isPlan: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  selectedProject = (val, isImport) => {
    console.log(val.name);
    this.setState({
      selectedTab: "test-case",
      titleName: val.name,
      selectedProjectId: val.id,
    });
    this.setState({ importState: isImport });
    this.setState({ viewState: 1 });
    this.props.history.push(`dashboard/${val.name}`);

    if (this.child.current) {
      this.child.current.changeIndex(val.id);
    }
  };

  handleChange = (type) => {
    console.log("============>", type);
    if (type) {
      this.getOrgIntigration("1m");
    } else {
      this.getOrgIntigration("1y");
    }
  };

  handleChangeEmail(e, type) {
    console.log(e.target.value + "==>" + type);
    if (type == "name") {
      this.setState({ name: e.target.value });
    } else if (type == "email") {
      this.setState({ email: e.target.value });
    } else if (type == "msg") {
      this.setState({ msg: e.target.value });
    }
    this.setState({ name_err: "", email_err: "", msg_err: "" });
    const { name, email, msg } = this.state;
    var EmailReg =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (name == "") {
      this.setState({ name_err: "Required Field", isSubmit: false });
    } else if (email == "") {
      this.setState({ email_err: "Required Field", isSubmit: false });
    } else if (msg == "") {
      this.setState({ msg_err: "Required Field", isSubmit: false });
    } else if (EmailReg.test(email)) {
      this.setState({ email_err: "", isSubmit: true });
    } else {
      this.setState({ email_err: "Enter Valid Email", isSubmit: false });
    }
  }

  onChangeCaptcha = (val) => {
    this.setState({ captcha: val });
  };

  sendmail = () => {
    this.setState({ createModalVisible: false });
    console.log("SAVING DATA =============>");
    const { isSubmit, name, email, msg } = this.state;
    if (isSubmit) {
      var myHeaders = new Headers();
      myHeaders.append("X-L10N-Locale", "en");
      myHeaders.append("Content-Type", "application/json");

      var raw = {
        name: name,
        email: email,
        msg: msg,
      };

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(raw),
        redirect: "follow",
      };

      fetch(`${BASE_URL}auth/send-mail`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 401) {
            this.setState({ loading: false });
            localStorage.clear();
            history.push("/login?redirect=1");
          }
          NotificationManager.info(result.msg);
        })
        .catch((error) => {
          console.log("error send-email", error);
        });
      this.setState({ isSubmit: false, name: "", email: "", msg: "" });
    }
  };

  onTestSuitCreate = () => {
    const { name, testCasesData, createModalVisible } = this.state;
    console.log(createModalVisible);
    return (
      <Modal
        overlayClassName="OverlayPricingModal"
        className="create-modal pricing-modal test-cases-modal"
        onRequestClose={() => this.setState({ createModalVisible: false })}
        isOpen={createModalVisible}
      >
        <div className="modal-header clearfix">
          <h1>Ask for a better price!</h1>
          <i
            className="mdi mdi-close"
            onClick={() => this.setState({ createModalVisible: false })}
          ></i>
        </div>

        <label id="label" className="mt-30" htmlFor="name">
          Your Full Name
        </label>
        <input
          type="text"
          className="form-control"
          name="title"
          value={this.state.name}
          onChange={(e) => this.handleChangeEmail(e, "name")}
        />
        <span id="err" style={{ color: "red" }}>
          {this.state.name_err}
        </span>
        <label id="label" className="mt-30" htmlFor="name">
          Email
        </label>
        <input
          type="text"
          className="form-control"
          name="email"
          value={this.state.email}
          onChange={(e) => this.handleChangeEmail(e, "email")}
          id="email"
        />

        <span id="err" style={{ color: "red" }}>
          {this.state.email_err}
        </span>
        <label id="label" className="mt-30" htmlFor="name">
          Message
        </label>
        <textarea
          row="5"
          id="contact-msg"
          value={this.state.msg}
          onChange={(e) => this.handleChangeEmail(e, "msg")}
        ></textarea>
        <span id="err" style={{ color: "red" }}>
          {this.state.msg_err}
        </span>
        <div className="form-field ">
          <label htmlFor="phone"> Phone </label>
          <div className="phone-field">
            {/* <select name="phone" id="phone">
                  <option value="+91">+91</option>
                  <option value="+10">+10</option>
                </select> */}
            <input type="text" id="phone" name="phone" />
          </div>
        </div>
        <div className="form-field ">
          <ReCAPTCHA
            style={{ marginTop: "20px" }}
            sitekey={recaptha_siteKey}
            onChange={this.onChangeCaptcha}
          />
        </div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={this.sendmail}
          disabled={!this.state.captcha}
        >
          Request price
        </button>
      </Modal>
    );
  };

  render() {
    return (
      <>
        <div className="custom-container home-page bg-main">
          <div>
            <NotificationContainer />
          </div>
         <LandingNav scrolled={true}/>
          <div className="d-flex  flex-column align-items-center justify-content-center" style={{ background: '#F9F9F9', paddingTop: 90 }}>
            <div
              className="card-body noauth-pricing-page"
              style={{
                position: "relative",
                width: 926,
              }}
            >
              <div className="pricing-heading">
                <h2 className="pricing-main-heading">The Right Plan for Your Business</h2>
              </div>
              <div className="toggle-button-main">
                <p>
                  We have several powerful plans to showcase your business and get discovered
                  as a creative entrepreneurs. Everything you need.
                </p>

              </div>
              {/* <div class="toggle-button-cover">
                  <div class="button-cover">
                    <div class="button b2" id="button-10">
                      <input
                        type="checkbox"
                        class="checkbox"
                        checked={this.state.switch1 ? "checked" : ""}
                        onChange={() => {
                          this.setState({ switch1: !this.state.switch1 });
                          this.handleChange(!this.state.switch1);
                        }}
                      />
                      <div class="knobs">
                        <span>Monthly</span>
                      </div>
                      <div class="layer"></div>
                    </div>
                  </div>
                </div> */}
              <div className="toggle-new">Bill Annualy
                <input
                  type="checkbox"
                  id="switch1"
                  checked={this.state.switch1 ? "checked" : ""}
                  switch="none"
                  onChange={() => {
                    this.setState({ switch1: !this.state.switch1 });
                    this.handleChange(!this.state.switch1);
                  }}
                />
                <label
                  for="switch1"
                  data-on-label=""
                  data-off-label=""
                ></label>
                Bill Monthly
              </div>

            </div>
          </div>
          <section className="pricing-cards-section">
            <div className="pricing-card-main-div container">
              <div className="row" style={{ justifyContent: 'center' }}>
                {this.state.plan_data.map((ele, index) => {
                  const temp = JSON.parse(ele.ProductFeatures);
                  // const temp = ['asd', 'adasd']
                  return (
                    <div className="col-lg-4 col-sm-6" style={{ maxWidth: 280, marginRight: 32 }}>
                      <div className="pricing-card">
                        <div
                          className="pricing-main-text"
                        >
                          <h3>{ele.productPlanName}</h3>
                          {ele.productPlanName.toLowerCase() !== "basic" && <div className="pricing-popular-chip">Popular</div>}
                          {/* <p>15 days trial period</p> */}
                        </div>
                        <div className="pricing-price">
                          <h2>
                            <span style={{ color: '#181059' }}>$</span>{this.formatNumber(ele.productPlanPrice)}{this.state.switch1 ?  (<span>{" "}/month</span>) : (<span>{" "}/annualy</span>)}
                          </h2>
                        </div>
                        <div className="pricing-nav-btn w-100 mt-[0.7rem]">
                        
                          <a
                          href="/register"
                            className={ele.productPlanName.toLowerCase() !== "basic" ? "btn btn-primary w-100 pricing-btn-second" : "btn btn-primary w-100 pricing-btn-first"}
                            // onClick={() => {
                            //   this.getSessionURL(ele.id);
                            // }}
                            rel="noreferrer"
                          >
                            Start your 15 days trial
                          </a>
                        </div>
                        <div className="pricing-features">
                          <ul>
                            {temp.map((eleChild) => {
                              return (
                                <li className="!flex">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/plain-checkmark.png"
                                    }
                                    alt="cirlce-img"
                                  />{" "}
                                  {eleChild}
                                </li>

                              );
                            })}
                          </ul>
                        </div>
                        {/* <div className="pricing-nav-btn w-100">
                          <button
                            className="btn btn-primary w-100 pricing-btn-first"
                            onClick={() => {
                              this.getSessionURL(ele.id);
                            }}
                          >
                            Choose Plan
                          </button>
                        </div> */}
                      </div>
                    </div>
                  );
                })}
                {/*   <div className="col-lg-4 col-sm-6">
                  <div className="pricing-card">
                    <div className="pricing-main-text">
                      <h3>Advance</h3>
                      <p>Advanced tools to take your work to the next level.</p>
                    </div>
                    <div className="pricing-price">
                      <h2>
                        $54<span>/Month</span>
                      </h2>
                    </div>
                    <div className="pricing-features">
                      <ul>
                        <li>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/check-circle-1.png"
                            }
                            alt="cirlce-img"
                          />{" "}
                          1 user
                        </li>
                        <li>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/check-circle-1.png"
                            }
                            alt="cirlce-img"
                          />{" "}
                          1 user
                        </li>
                        <li>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/check-circle-1.png"
                            }
                            alt="cirlce-img"
                          />{" "}
                          1 user
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-nav-btn w-100">
                      <button className="btn btn-primary w-100 pricing-btn-first">
                        Choose Plan
                      </button>
                    </div>
                  </div>
                </div>
                */}
                <div className="col-lg-4 col-sm-6" style={{ maxWidth: 280  }}>
                  <div className="pricing-card">

                    <div className="pricing-main-text">
                      <h3>Enterprise</h3>
                    </div>
                    <div className="pricing-price !mt-[0.3rem]">
                      <h2 style={{fontSize:'17px', fontWeight:'400'}}>
                        Custom
                      </h2>
                    </div>
                    <div className="pricing-nav-btn w-100 !mt-[1rem]">
                      <button
                        className="btn btn-primary w-100 pricing-btn-first"
                        onClick={() =>
                         window.location="/contactus"
                        }
                      >
                        Contact us
                      </button>
                    </div>
                    <div className="pricing-features">
                      <ul>
                        <li className="d-flex">
                          <span><img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/plain-checkmark.png"
                            }
                            alt="cirlce-img"
                          /></span>{" "}
                          Customization as per your requirements
                        </li>
                      </ul>
                    </div>
                    {/* <div className="pricing-nav-btn w-100">
                      <button
                        className="btn btn-primary w-100 pricing-btn-second"
                        onClick={() =>
                          this.setState({ createModalVisible: true })
                        }
                      >
                        Choose Plan
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <section className="description-block pricing-block">
            <div className="page-custom-container pricing-container">
              <div className="custom-row">
                <div className="pricing-main">
                  <div className="pricing-header">
                    <h1>Simple, transparent pricing</h1>
                    <p>No contracts. No surprise fees.</p>
                  </div>
                  <div className="pricing-card-list">
                    <div className="card">
                      <img
                        className="pricing-lines"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/pricing-lines.png"
                        }
                      />
                      <div className="">
                        <h1>FREE</h1>
                        <h2>Basic</h2>
                        <h4>Free forever plan</h4>
                        <div className="pricing-feature-list">
                          <ul>
                            <li>
                              {" "}
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/check-circle-1.png"
                                }
                              />{" "}
                              1 User
                            </li>
                            <li>
                              {" "}
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/check-circle-1.png"
                                }
                              />{" "}
                              Up to 5 Projects
                            </li>
                            <li>
                              {" "}
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/check-circle-1.png"
                                }
                              />{" "}
                              25 Test Runs
                            </li>
                          </ul>
                          <Link to="/register">
                            <button
                              type="submit"
                              className="btn btn-primary w-100"
                            >
                              Start Now
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="card ">
                      <img
                        className="pricing-lines"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/pricing-lines.png"
                        }
                      />
                      <div className="">
                        <h1>Custom</h1>
                        <h2>Premium</h2>
                        <h4>Customise plan as per requirements</h4>
                        <div className="pricing-feature-list premium-price">
                          <ul>
                            <li>
                              {" "}
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/check-circle-1.png"
                                }
                              />{" "}
                              Unlimited users
                            </li>
                            <li>
                              {" "}
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/check-circle-1.png"
                                }
                              />{" "}
                              Unlimited projects
                            </li>
                            <li>
                              {" "}
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/check-circle-1.png"
                                }
                              />{" "}
                              Unlimited Test Runs
                            </li>
                            <li>
                              {" "}
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/check-circle-1.png"
                                }
                              />{" "}
                              Automation
                            </li>
                            <li>
                              {" "}
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/check-circle-1.png"
                                }
                              />{" "}
                              Integration
                            </li>
                          </ul>
                          <button
                            type="submit"
                            className="btn btn-primary w-100"
                            onClick={() =>
                              this.setState({ createModalVisible: true })
                            }
                          >
                            Contact Us
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
        <div style={{paddingBottom:30}}>
        <FooterSection />
        </div>
        </div>
        {this.onTestSuitCreate()}
      </>
    );
  }
}
