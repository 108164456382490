import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";

const ImageInput = ({ setImage, btnSize }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.size > 5 * 1024 * 1024) {
        alert("File size exceeds 5MB. Please choose a smaller file.");
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        setImageSrc(reader.result);
      };
    }
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      const croppedImageUrl = URL.createObjectURL(croppedImage);
      setImageSrc(null);
      setImage({ file: croppedImage, url: croppedImageUrl }); // Save the cropped image
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels, setImage]);

  return (
    <div className="ImageInput">
      {!imageSrc ? (
        <div
          className="input-trigger cursor-pointer"
          style={{
            width: btnSize,
            height: btnSize,
            background: "red",
            opacity: 0,
            zIndex: 99999,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => document.getElementById("fileInput").click()}
        >
          <input
            type="file"
            id="fileInput"
            accept="image/*"
            style={{ display: "none" }}
            onChange={onSelectFile}
          />
          <span style={{ color: "white", fontSize: "20px" }}>
            Click to upload an image
          </span>
        </div>
      ) : (
        <div
          className="overlay"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(128, 128, 128, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
            overflow: "hidden",
          }}
        >
          <div
            className="crop-container"
            style={{
              position: "relative",
              width: "80%",
              height: "80%",
              background: "white",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              style={{
                containerStyle: { width: "100%", height: "calc(100% - 150px)" },
              }}
            />
            <div
              className="controls"
              style={{
                position: "absolute",
                bottom: "10px",
                left: "50%",
                transform: "translateX(-50%)",
                width: "80%",
                textAlign: "center",
                zIndex: 10,
              }}
            >
              <input
                type="range"
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e) => {
                  setZoom(e.target.value);
                }}
                className="zoom-range"
                style={{ width: "100%", marginBottom: "5px" }}
              />
              <div
                className="mb-8"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <button
                  onClick={() => setImageSrc(null)}
                  style={{
                    padding: "10px 20px",
                    borderRadius: "5px",
                    border: "none",
                    cursor: "pointer",
                    backgroundColor: "#f0f0f0",
                    color: "#757575",
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={showCroppedImage}
                  style={{
                    padding: "10px 20px",
                    borderRadius: "5px",
                    border: "none",
                    cursor: "pointer",
                    backgroundColor: "#316BFF",
                    color: "white",
                  }}
                >
                  Crop
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.src = url;
  });

const getCroppedImg = async (imageSrc, pixelCrop) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        console.error("Canvas is empty");
        reject(new Error("Canvas is empty"));
        return;
      }
      blob.name = "croppedImage.png";
      resolve(blob);
    }, "image/png");
  });
};

export default ImageInput;
