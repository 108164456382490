import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSlug from 'rehype-slug'; // Automatically generate IDs for headings
import rehypeAutolinkHeadings from 'rehype-autolink-headings';
import 'github-markdown-css/github-markdown.css';
import { List, ListItem, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CircleIcon from '@mui/icons-material/Circle';
import '../styles/docs.css';
import { IoSearchOutline } from "react-icons/io5";
import {  LOGO_FULL_URL } from "../_helpers/constants";
const Docs = () => {
  
  const [markdown, setMarkdown] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [docsItem, setDocsItem] = useState('');
  const [activeHeading, setActiveHeading] = useState('');
  const markdownRef = useRef(null);
  const [headings, setHeadings] = useState([]);

  useEffect(() => {
    if (markdownRef.current) {
      // Query headings in the rendered markdown using the ref
      const headingElements = markdownRef.current.querySelectorAll('h1, h2, h3, h4, h5, h6');
      
      const headingsList = Array.from(headingElements).map((heading, index) => {
        if (!heading.id) {
          heading.id = `${heading.tagName.toLowerCase()}-${index}`;
        }
        return {
          text: heading.innerText,
          id: heading.id,
          element: heading,
        };
      });

      setHeadings(headingsList);
      
    }
  }, [markdown]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            
            setActiveHeading(entry.target.id);
            console.log("Intersecting id:", activeHeading)
          }
        });
      },
      {
        rootMargin: '-18% 0px -52% 0px', // This ensures the heading is in the middle of the screen
      }
    );

    headings.forEach((heading) => {   
      const element = document.getElementById(heading.id);
      if (element) {
        
        observer.observe(element);
      }
    });

    // Cleanup function to unobserve all
    return () => {
      observer.disconnect();
    };
  }, [headings]);

  const filteredHeadings = headings.filter((heading) =>
    heading.text.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const scrollToHeading = (id) => {
    console.log(id)
    const headingElement = document.getElementById(id);
    if (headingElement) {
      headingElement.scrollIntoView({ behavior: 'smooth' });
      setSearchQuery('');
      setActiveHeading(id);
    }
  };

  useEffect(() => {
    const originalOverflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, [])
  useEffect(() => {

    const pathSegments = window.location.pathname.split('/');
    const docsItem = pathSegments[pathSegments.length - 1]; 
    setDocsItem(docsItem);
    if (docsItem) {
      import(`../content/${docsItem}.md`)
        .then((res) => fetch(res.default))
        .then((response) => response.text())
        .then((text) => setMarkdown(text))
        .catch(() => setMarkdown('# Content not found'));
    } else {
      setMarkdown('# No document specified');
    }
  }, [window.location.pathname.split('/')]);

  return (
    <>
    <div className="flex flex-col w-screen !overflow-hidden">
      <div className="flex justify-between items-center sticky top-0 h-[12vh] mx-12"> 
      <Link to="/">
                  {" "}
                  <img width="170" height="55" src={LOGO_FULL_URL} />
                </Link>
                <div
                          className="flex items-center "
                          style={{
                            position: "relative",
                          }}
                        >
                        <input
                            
                            type="text"
                            className="form-control !h-[33px] !mt-0 !placeholder-[#676060]"
                            placeholder="What are you looking for?"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            // onBlur={() => setSearchQuery('')}
                            style={{
                              border: "1px solid #EBEBEB",
                              borderRadius: 6,
                              height: 44,
                              fontSize: 12,
                              width: 307,
                              background: "#F4F8FB",
                              paddingLeft: 40,
                              
                            }}
                          />
                          <span
                            style={{
                              position: "absolute",
                              color: "#676060",
                              top: 8,
                              left: 8,
                            }}
                          >
                            <IoSearchOutline />
                          </span>
                          {searchQuery && filteredHeadings.length > 0 && (
          <ul
            style={{
              position: 'absolute',
              top: '50px',
              left: '0',
              right: '0',
              background: '#ffffff',
              border: '1px solid #ddd',
              borderRadius: '4px',
              zIndex: 99,
              maxHeight: '200px',
              overflowY: 'auto',
              width: '307px',
            }}
          >
            {filteredHeadings.map((heading) => (
              <li
                key={heading.id}
                onClick={() => {console.log(heading.id); scrollToHeading(heading.id)}}
                style={{
                  padding: '8px 12px',
                  cursor: 'pointer',
                  
                }}
              >
                {heading.text}
              </li>
            ))}
          </ul>
        )}
                          </div>
                          <div className='flex gap-4 items-center ' ><Link to="/login" style={{ textDecoration: 'none' }}><p className='hover:!text-[#27AE60] font-poppins cursor-pointer text-[14px] text-[rgba(27, 31, 38, 0.72)]'>Back to OyeTest</p></Link>
                          <Link to="/register" style={{ textDecoration: 'none' }}> <button className=' font-poppins h-[40px] py-[6px] px-3 cursor-pointer text-[14px] font-[600] text-white flex items-center justify-center !bg-[#316BFF] hover:!bg-[#1c5af7] gap-3' style={{borderRadius:8}}>Sign Up <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 8.99999H12M12 8.99999L9.25001 7M12 8.99999L9.25001 11" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.33423 5.25C6.34432 3.61873 6.4247 2.73529 7.06501 2.15901C7.79727 1.5 8.97577 1.5 11.3328 1.5H12.1661C14.5231 1.5 15.7017 1.5 16.4339 2.15901C17.1661 2.81802 17.1661 3.87868 17.1661 6V12C17.1661 14.1213 17.1661 15.1819 16.4339 15.841C15.7935 16.4173 14.8119 16.4897 12.9993 16.4987M6.33423 12.75C6.34432 14.3812 6.4247 15.2647 7.06501 15.841C7.59944 16.3219 8.37152 16.4519 9.66594 16.487" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
</svg>
</button></Link></div>
</div>
<div className="flex justify-start sticky top-0 items-center h-[6vh] px-12 gap-2 border-t border-b border-[#E4E4E4]" style={{boxShadow: '0px 25px 21px rgba(0, 0, 0, 0.04)'}}> <p className='font-railway text-[#969696] text-[10px]'> Documentation </p> <svg width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 1L3.625 3.25L1 5.5" stroke="#3DB766" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<p className='font-railway text-[#969696] text-[10px]'> Integrations </p> <svg width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 1L3.625 3.25L1 5.5" stroke="#3DB766" stroke-linecap="round" stroke-linejoin="round"/>
</svg> <p className='font-railway text-[#27AE60] text-[10px]'> {docsItem} </p>
</div>
    <div className="flex bg-white h-[81vh] overflow-y-auto overflow-x-hidden mb-1">
      <div className="w-[20vw] p-4 border-r sticky top-0 border-[#E4E4E4]" style={{boxShadow:'0px 25px 21px rgba(0, 0, 0, 0.04)'}}>
        <DotLineMenu />
      </div>
      <div className="w-[62vw] p-8 " style={{paddingBottom:'6rem'}} ref={markdownRef}>
      <ReactMarkdown
        className="markdown"
        children={markdown}
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw, rehypeSlug, rehypeAutolinkHeadings]}
        allowDangerousHtml={true}
        components={{
          img: ({ node, ...props }) => (
            <span style={{ display: 'flex', justifyContent: 'center' }}>
            <img {...props} style={{
              width: 'auto',         
              maxWidth: '100%',      
              height: 'auto',        
              margin: '20px 0',
              border: '2px solid #ccc', 
              borderRadius: '8px',      
              padding: '5px',           
            }} alt={props.alt} />
            </span>
          ),
          video: VideoWithLoader,
          h1: ({ node, ...props }) => (
            <h1 {...props} style={{ fontFamily: 'Raleway', lineHeight: '1.4' }} />
          ),
          p: ({ node, ...props }) => (
            <p {...props} style={{ fontFamily: 'Roboto', lineHeight: '1.6' }} />
          ),
        }}
      />
      </div>
      <div className='w-[18vw] p-4 flex flex-col sticky top-0 py-[8px] border-l border-[#E4E4E4]'>
      <p className='font-raleway text-[12px] text-[#898989]'> On this page</p>
         {headings.map((heading) => (
              
              <div
                className={`font-poppins font-normal cursor-pointer text-[13px] flex !flex-row items-center gap-4  justify-start text-[#262A36] ${activeHeading === heading.id ? '!text-[#27AE60] !bg-[#e4fef4] !mx-[-1.8rem]' : ''}`}
                key={heading.id}
                onClick={() => scrollToHeading(heading.id)}
                
              >
                {activeHeading === heading.id && (<span className="w-0.5 h-auto px-0.5 py-3 bg-[#27AE60]" />)}
                <span style={{
                  padding: '8px 10px',
                  
                  
                }}>{heading.text}</span> 
              </div>
            ))}
            </div>
    </div>
    </div>
    </>
  );
};

const VideoWithLoader = (props) => {
  const [loading, setLoading] = useState(true);

  return (
    <span style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
      {loading && (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}>
          <div style={{
            width: 100,  
            height: 100,  
          }}>
            <div className="loader"></div> 
          </div>
        </div>
      )}
      <video
        {...props}
        style={{
          width: 'auto',
          maxWidth: '100%',
          height: 'auto',
          margin: '20px 0',
          border: '2px solid #ccc',
          borderRadius: '8px',
          padding: '5px',
          display: loading ? 'none' : 'block',
        }}
        autoPlay
        loop
        muted
        onLoadStart={() => setLoading(true)}
        onLoadedData={() => setLoading(false)}
      />
    </span>
  );
};

const DotLineMenu = () => {
  const [open, setOpen] = useState(true);

  const [activePath, setActivePath] = useState('');

  useEffect(() => {
    const path = window.location.pathname.split('/').pop(); // Get the last segment of the path
    setActivePath(path);
  }, [window.location.pathname]); 

  const handleClick = () => {
    setOpen(!open);
  };
  const isActive = (path) => {
    const currentPath = window.location.pathname.split('/').pop(); // Get the last segment of the path
    return currentPath === path;
  };
  return (
    <List component="nav" className="sidebar-nav">
      <ListItem button onClick={handleClick} >
      <ListItemIcon>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_127_168)">
<path d="M6.99996 12.8334C10.2083 12.8334 12.8333 10.2084 12.8333 7.00008C12.8333 3.79175 10.2083 1.16675 6.99996 1.16675C3.79163 1.16675 1.16663 3.79175 1.16663 7.00008C1.16663 10.2084 3.79163 12.8334 6.99996 12.8334Z" stroke="#292D32" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.52087 6.99993L6.17171 8.65076L9.47921 5.34912" stroke="#292D32" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_127_168">
<rect width="14" height="14" fill="white"/>
</clipPath>
</defs>
</svg>

        </ListItemIcon>
        <ListItemText primary="Integrations" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div style={{ position: 'relative' }}>
          {/* Timeline Line */}
          <svg
            style={{
              position: 'absolute',
              top: '20px',
              left: '43px',
              width: '2px',
              height: 'calc(100% - 40px)', // Adjust this to match the height of the list
              zIndex: -10,
            }}
            viewBox="0 0 2 100%"
            preserveAspectRatio="none"
          >
            <line
              x1="1"
              y1="0"
              x2="1"
              y2="100%"
              stroke="#E4E4E4"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>

          <List component="div" disablePadding>
            <ListItem
              button
              component={Link}
              to="/docs/integration-overview"
              className={`nested-list-item ${isActive('integration-overview') ? 'active' : ''}`}
              style={{ position: 'relative', zIndex: 1 }}
            >
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>
              <ListItemText primary="Integration Overview" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/docs/github-integration"
              className={`nested-list-item ${isActive('github-integration') ? 'active' : ''}`}
              style={{ position: 'relative', zIndex: 1 }}
            >
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>
              <ListItemText primary="GitHub Integration" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/docs/jira-integration"
              className={`nested-list-item ${isActive('jira-integration') ? 'active' : ''}`}
              style={{ position: 'relative', zIndex: 1 }}
            >
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>
              <ListItemText primary="Jira Integration" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/docs/asana-integration"
              className={`nested-list-item ${isActive('asana-integration') ? 'active' : ''}`}
              style={{ position: 'relative', zIndex: 1 }}
            >
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>
              <ListItemText primary="Asana Integration" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/docs/trello-integration"
              className={`nested-list-item ${isActive('trello-integration') ? 'active' : ''}`}
              style={{ position: 'relative', zIndex: 1 }}
            >
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>
              <ListItemText primary="Trello Integration" />
            </ListItem>
            
          </List>
        </div>
      </Collapse>
    </List>
  );
};

export default Docs;
