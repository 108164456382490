import React, { Component, useEffect, useState } from "react";
import "../styles/component/_testRun.css";
import update from "immutability-helper";
import { Tooltip } from "@material-ui/core";

import { BASE_URL } from "../_helpers/constants";
import Promises, { formatDate } from "../_helpers/utils";
import Modal from "react-modal";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Button } from "reactstrap";
import { Scrollbars } from "react-custom-scrollbars";
import Select, { components } from "react-select";
import MainActionPopup from "./MainActionPopup";
import TwoActionPopup from "./TwoActionPopup";
import { confirmAlert } from "react-confirm-alert";
import DataTable from "react-data-table-component";
import * as _ from "underscore";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { history } from "../_helpers/history";
import { MdArrowRight, MdOutlineArrowDropDown } from "react-icons/md";
import { IoSearchOutline } from "react-icons/io5";
import Table from "../_components/Table";
import NotFound from "./NotFound";

export default class TestRun extends Component {
  constructor(props) {
    super(props);
    this.toggleprofile = this.toggleprofile.bind(this);
    this.toggleprofile1 = this.toggleprofile1.bind(this);
    this.wrapperRef = React.createRef();
    this.sidebarRef = React.createRef();
    // this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  state = {
    createIssue: false,
    dropdownOpenprofile2: false,
    filteredTestcasesData: [],
    filteredCustomTestcasesData:[],
    filterParameters2: {
      type: [],
      os: [],
      browser: [],
      assignedStatus: []
    },
    filterParameters: {
      type: [],
      priority: [],
      testType: [],
    },
    paginationDetails: {
      pageNumber: 0,
      pageSize: 30, //for testing. it shall be 50 otherwise
      totalPages: 1,
    },
    toggledClearRows: false,
    issuesVisisble: false,
    pending: true,
    assigneeEmail: "",
    tableRows: [],
    filteredData: [],
    selectedRows: [],
    selectedAssignors: '',
    filterOSProps: [],
    filterBrowserProps: [],
    filterTypeProps: [],
    filterAssignProps: [],
    search: "",
    searchTotal: "",
    showTotalFilter: false,
    newList: "",
    testCasesData: [],
    customTestCasesData: [],
    hiddenSections: [],
    fildData: [],
    curruntTestName: "",
    projectId: "",
    showCreateTestRun: false,
    loading: true,
    createModalVisible: false,
    createCustomModalVisible: false,
    createCustomListModalVisible: false,
    isIntegrationData: false,
    testCasesEmpty: false,
    integrationData: [],
    issues: [],
    showFilter: false,
    showOSFilter: false,
    showBrowserFilter: false,
    filterProps: [],
    issueInputValue: "",
    issueInputDesc: "",
    bulkBrowser: "",
    bulkOs: "",
    bulkExecutiontype: "",
    twoActionPopup: false,
    testType: [
      {
        value: "functional",
        label: "Functional",
      },
      {
        value: "unit",
        label: "Unit",
      },
      {
        value: "security",
        label: "Security",
      },
      {
        value: "integration",
        label: "Integration",
      },
      {
        value: "system",
        label: "System",
      },
      {
        value: "acceptance",
        label: "Acceptance",
      },
    ],
    test_type_value: [],
    intType: "",
    latest_key: "",
    name: `D${moment().format("DDMMYYYY")}T${moment().format("HHmmss")}`,
    description: `D${moment().format("DDMMYYYY")}T${moment().format("HHmmss")}`,
    curruntTestrunId: localStorage.getItem("curruntTestrunId") || "",
    isTestRunCreated: localStorage.getItem("isTestRunCreated"),
    curruntprojectName: localStorage.getItem("projectName") || "",
    TestRundateAndTime: `D${moment().format("DDMMYYYY")}T${moment().format(
      "HHmmss"
    )}`,
    dynamicStyle: [],
    isCheckParent: [],
    isCheck: [],
    testHistory: [],
    untestedCount: "",
    projectUsers: [],
  };

  /*
  .##........#######...######...####..######...######.
  .##.......##.....##.##....##...##..##....##.##....##
  .##.......##.....##.##.........##..##.......##......
  .##.......##.....##.##...####..##..##........######.
  .##.......##.....##.##....##...##..##.............##
  .##.......##.....##.##....##...##..##....##.##....##
  .########..#######...######...####..######...######.
  */

  componentDidMount = () => {
    console.log("=== In Component Mount ==>");
    document.addEventListener("mousedown", this.handleClickOutside);

    this.setState({ projectId: this.props.projectId }, () => {
      const { curruntTestrunId, isTestRunCreated } = this.state;
      if (isTestRunCreated && curruntTestrunId !== "") {
        console.log(
          "=== In getTestResult Mount ==>" +
            curruntTestrunId +
            " ** " +
            this.state.isTestRunCreated
        );
        this.setState(
          {
            loading: true,
            createModalVisible: false,
            createCustomModalVisible: false,
            createCustomListModalVisible: false,
            showCreateTestRun: false,
          },
          () => console.log("1 ", this.state.showCreateTestRun)
        );
      }
      this.getTestResult();
      this.getIntegrationData();
      // this.getTestCasesData("initial");
      this.getCustomTestCasesData();
      this.getProjectUserData();
    });
  };
  // componentDidUpdate(prevProps, prevState) {
  //   if (!prevState.createIssue && this.state.createIssue) {
  //     // Scroll to the input when createIssue becomes true
  //     this.issueTitleRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }
  handleAddFilterOrSearchOnCustomTestCases = () => {
    const { search, customTestCasesData, filterParameters } = this.state;

    // Apply search filter
    let searchMatchedList = customTestCasesData.map((ele) => {
      return {
        ...ele,
        lists: ele.lists.filter((el) => {
          const searchMatch = el.subTaskTitle
            ?.toString()
            .toLowerCase()
            .includes(search.toLowerCase());
          return searchMatch;
        }),
      };
    });
    searchMatchedList = searchMatchedList.filter((el) => el.lists.length > 0);

    // Apply additional filter parameters
    let filterMatchedList = searchMatchedList.map((ele) => {
      return {
        ...ele,
        lists: ele.lists.filter((el) => {
          const typeMatch = filterParameters.type?.length
            ? filterParameters.type.includes(
                el.testing.toString().toLowerCase()
              )
            : true;
          const priorityMatch = filterParameters.priority?.length
            ? filterParameters.priority.includes(
                el.priority ? el.priority.toString().toLowerCase() : null
              )
            : true;
          const testTypeMatch = filterParameters.testType?.length
            ? filterParameters.testType.some((item) =>
                el.test_type ? el.test_type.includes(item) : null
              )
            : true;
          return typeMatch && priorityMatch && testTypeMatch;
        }),
      };
    });
    filterMatchedList = filterMatchedList.filter((el) => el.lists.length > 0);

    this.setState({ filteredCustomTestcasesData: filterMatchedList });
  };
  handleAddFilterOrSearchOnTestCases = () => {
    const { search, testCasesData, filterParameters } = this.state;

    // Apply search filter
    let searchMatchedList = testCasesData.map((ele) => {
      return {
        ...ele,
        lists: ele.lists.filter((el) => {
          const searchMatch = el.subTaskTitle
            ?.toString()
            .toLowerCase()
            .includes(search.toLowerCase());
          return searchMatch;
        }),
      };
    });
    searchMatchedList = searchMatchedList.filter((el) => el.lists.length > 0);

    // Apply additional filter parameters
    let filterMatchedList = searchMatchedList.map((ele) => {
      return {
        ...ele,
        lists: ele.lists.filter((el) => {
          const typeMatch = filterParameters.type?.length
            ? filterParameters.type.includes(
                el.testing.toString().toLowerCase()
              )
            : true;
          const priorityMatch = filterParameters.priority?.length
            ? filterParameters.priority.includes(
                el.priority ? el.priority.toString().toLowerCase() : null
              )
            : true;
          const testTypeMatch = filterParameters.testType?.length
            ? filterParameters.testType.some((item) =>
                el.test_type ? el.test_type.includes(item) : null
              )
            : true;
          return typeMatch && priorityMatch && testTypeMatch;
        }),
      };
    });
    filterMatchedList = filterMatchedList.filter((el) => el.lists.length > 0);

    this.setState({ filteredTestcasesData: filterMatchedList });
  };
  toggleFilterParameters = (key, filteredValue) => {
    console.log("Key", key, "value", filteredValue);

    const found = this.state.filterParameters[key].some(
      (el) => el === filteredValue.toString().toLowerCase()
    );

    if (!found) {
      console.log("not Found ", found);
      this.setState((prevState) => ({
        filterParameters: {
          ...prevState.filterParameters,
          [key]: [
            ...prevState.filterParameters[key],
            filteredValue.toString().toLowerCase(),
          ],
        },
      }));
    } else {
      console.log("Found ", found);

      this.setState({
        filterParameters: {
          ...this.state.filterParameters,
          [key]: this.state.filterParameters[key].filter(
            (item) => item !== filteredValue.toString().toLowerCase()
          ),
        },
      });
    }
  };

  handleAddFilterOrSearchOnTestCases2 = () => {
    const { tableRows, filterParameters2, searchTotal } = this.state;

  let newFilteredData = tableRows;

  // Filter by browser
  if (filterParameters2.browser.length > 0) {
    const lowerCaseBrowserProps = filterParameters2.browser.map((prop) =>
      prop.toLowerCase()
    );
    newFilteredData = newFilteredData.filter((row) =>
      lowerCaseBrowserProps.includes(row.browser.toLowerCase())
    );
  }

  // Filter by OS
  if (filterParameters2.os.length > 0) {
    const lowerCaseOSProps = filterParameters2.os.map((prop) =>
      prop.toLowerCase()
    );
    newFilteredData = newFilteredData.filter((row) =>
      lowerCaseOSProps.includes(row.os.toLowerCase())
    );
  }

  // Filter by assigned status
  if (filterParameters2.assignedStatus.length > 0) {
    const lowerCaseAssignProps = filterParameters2.assignedStatus.map((prop) =>
      prop.toLowerCase()
    );
    newFilteredData = newFilteredData.filter((row) => {
      const assignee = row.assignee
        ? row.assignee.toLowerCase()
        : "unassigned";
      return (
        (assignee !== "unassigned" &&
          lowerCaseAssignProps.includes("assigned")) ||
        (assignee === "unassigned" &&
          lowerCaseAssignProps.includes("unassigned"))
      );
    });
  }

  // Filter by type
  if (filterParameters2.type.length > 0) {
    const lowerCaseTypeProps = filterParameters2.type.map((prop) =>
      prop.toLowerCase()
    );
    
    newFilteredData = newFilteredData.filter((row) =>
      lowerCaseTypeProps.includes(row.executiontype.toLowerCase())
    );
  }

  // Apply search filter
  if (searchTotal) {
    newFilteredData = newFilteredData.filter((row) =>
      row.testcases.toLowerCase().includes(searchTotal)
    );
  }

  this.setState({ filteredData: newFilteredData });
  };

  toggleFilterParameters2 = (key, filteredValue) => {
    console.log("Key", key, "value", filteredValue);

    const found = this.state.filterParameters2[key].some(
      (el) => el === filteredValue.toString().toLowerCase()
    );

    if (!found) {
      console.log("not Found ", found);
      this.setState((prevState) => ({
        filterParameters2: {
          ...prevState.filterParameters2,
          [key]: [
            ...prevState.filterParameters2[key],
            filteredValue.toString().toLowerCase(),
          ],
        },
      }));
    } else {
      console.log("Found ", found);

      this.setState({
        filterParameters2: {
          ...this.state.filterParameters2,
          [key]: this.state.filterParameters2[key].filter(
            (item) => item !== filteredValue.toString().toLowerCase()
          ),
        },
      });
    }
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.filterParameters !== this.state.filterParameters) {
      this.handleAddFilterOrSearchOnTestCases();
    }
    if (prevState.filterParameters !== this.state.filterParameters && this.state.createCustomListModalVisible) {
      this.handleAddFilterOrSearchOnCustomTestCases();
    }
    if (prevState.filterParameters2 !== this.state.filterParameters2) {
      this.handleAddFilterOrSearchOnTestCases2();
    }
    if (this.state.testCasesData !== prevState.testCasesData) {
      this.setState({ filteredTestcasesData: this.state.testCasesData });
    }
    
  }; 

  onSubmitTestRun = () => {
    confirmAlert({
      className: "delete-project-modal",
      message: (
        <span>
          There are <strong>{this.state.untestedCount}</strong> untested
          testcases. Do you still want to submit{" "}
          <strong>({this.state.curruntTestName})</strong>?
        </span>
      ),
      buttons: [
        {
          className: "bg-transparent delete-project-no",
          label: "Yes",
          onClick: () => this.saveChangesTestRun(false),
        },
        {
          className: "btn btn-primary delete-project-yes",
          label: "No",
          onClick: () => console.log("canceled"),
        },
      ],
    });
  };

  handleClickOutside(event) {
    // if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
    //   // alert("You clicked outside of me!");
    //   this.setState({ showFilter: false });
    // }
  }

  onModelUpdate = (e, index) => {
    const { testCasesData } = this.state;
    let newState = update(testCasesData, {
      [index]: {
        model: { $set: e.target.value },
      },
    });
    this.setState({ testCasesData: newState });
  };

  onModelListTestDetailUpdate = (
    fieldName,
    taskIndex,
    subTaskIndex,
    subTaskId,
    val
  ) => {
    // const { testCasesData } = this.state;
    // let newState = update(testCasesData, {
    //   [index]: {
    //     lists: {
    //       [subIndex]: {
    //         [key]: { $set: val.target.value },
    //       },
    //     },
    //   },
    // });
    // this.setState({ testCasesData: newState });
    // const isClickSave = true;
    // this.saveChangesTestRun(isClickSave);

    const copyTask = [...this.state.testCasesData];
    // let newState = update(testCasesData, {
    //   [index]: {
    //     lists: {
    //       [subIndex]: {
    //         [key]: { $set: val.target.value },
    //       },
    //     },
    //   },
    // });
    // this.setState({ testCasesData: newState });

    if (fieldName === "name") {
      const myRowIndex = copyTask.findIndex(
        (row, index) => index === taskIndex
      );
      copyTask[myRowIndex].lists[subTaskIndex].subTaskTitle = val;
      console.log("--> ", val);
    } else if (fieldName === "description") {
      const myRowIndex = copyTask.findIndex(
        (row, index) => index === taskIndex
      );
      copyTask[myRowIndex].lists[subTaskIndex].description = val;
    } else if (fieldName === "summary") {
      const myRowIndex = copyTask.findIndex(
        (row, index) => index === taskIndex
      );
      copyTask[myRowIndex].lists[subTaskIndex].summary = val;
    } else if (fieldName === "os") {
      const myRowIndex = copyTask.findIndex(
        (row, index) => index === taskIndex
      );
      copyTask[myRowIndex].lists[subTaskIndex].os = val;
    } else if (fieldName === "browser") {
      const myRowIndex = copyTask.findIndex(
        (row, index) => index === taskIndex
      );
      copyTask[myRowIndex].lists[subTaskIndex].browser = val;
    } else if (fieldName === "testing") {
      const myRowIndex = copyTask.findIndex(
        (row, index) => index === taskIndex
      );
      copyTask[myRowIndex].lists[subTaskIndex].testing = val;
    } else if (fieldName === "status") {
      const myRowIndex = copyTask.findIndex(
        (row, index) => index === taskIndex
      );
      copyTask[myRowIndex].lists[subTaskIndex].testStatus = val;
    } else if (fieldName === "priority") {
      const myRowIndex = copyTask.findIndex(
        (row, index) => index === taskIndex
      );
      copyTask[myRowIndex].lists[subTaskIndex].priority = val;
    } else if (fieldName === "test_type") {
      const myRowIndex = copyTask.findIndex(
        (row, index) => index === taskIndex
      );
      copyTask[myRowIndex].lists[subTaskIndex].test_type = val;
      this.setState({ test_type_value: val });
    } else if (
      fieldName === fieldName &&
      fieldName !== "testing" &&
      fieldName !== "browser" &&
      fieldName !== "os" &&
      fieldName !== "summary" &&
      fieldName !== "description" &&
      fieldName !== "status" &&
      fieldName !== "priority" &&
      fieldName !== "test_type"
    ) {
      // const myRowIndex = copyTask.findIndex(
      //   (row, index) => index === taskIndex
      // );
      // copyTask[myRowIndex].lists[subTaskIndex].field = val;
    }
    this.setState({ testCasesData: copyTask });
    // console.log(this.state.testCasesData);
  };

  getTestResult = (type) => {
    console.log("FETCHING DATA12 ========>");
    this.setState(
      {
        loading: true,
        createModalVisible: false,
        createCustomModalVisible: false,
        createCustomListModalVisible: false,
        showCreateTestRun: true,
      },
      () => console.log("2 ", this.state.showCreateTestRun)
    );
    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,

      redirect: "follow",
    };
    console.log(requestOptions);

    fetch(
      `${BASE_URL}projects/run/projectid/${this.state.projectId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("FETCHING RESULT ===========>", result);
        if (!result.data.status) {
          this.setState({ loading: false, showCreateTestRun: false }, () =>
            console.log("3 ", this.state.showCreateTestRun)
          );
        } else {
          // console.log(result)
          // if(result.field && result.field.length >0){
          //   this.setState({ fildData: result.field })
          // }
          const data = result.data.data;
          console.log(result);
          if (data.length === 0 && result.testRunStatus === "INACTIVE") {
            this.setState({ loading: false, showCreateTestRun: false });
          } else {
            if (result.testRunStatus === "INACTIVE") {
              console.log("InActive ", result);
              this.setState(
                {
                  curruntTestName: localStorage.getItem("onCreateRunName"),
                  // curruntTestrunId:result.id,
                  showCreateTestRun: false,
                  testCasesData: data.map((task) => ({
                    ...task,
                    expanded: true,
                  })),
                  customTestCasesData: data.map((task) => ({
                    ...task,
                    expanded: true,
                  })),
                  loading: false,
                  graphCount: result.count,
                  filteredTestcasesData: data.map((task) => ({
                    ...task,
                    expanded: true,
                  })),
                  filteredCustomTestcasesData: data.map((task) => ({
                    ...task,
                    expanded: true,
                  })),
                },
                () => console.log("4 ", this.state.showCreateTestRun)
              );
            } else {
              if (data.length === 0) {
                NotificationManager.error(
                  "You have been assigned 0 TestCases",
                  "Un-Assigned"
                );
              }

              this.setState(
                {
                  curruntTestName: localStorage.getItem("onCreateRunName"),
                  // curruntTestrunId:result.id,
                  showCreateTestRun: true,
                  testCasesData: data.map((task) => ({
                    ...task,
                    expanded: true,
                  })),
                  customTestCasesData: data.map((task) => ({
                    ...task,
                    expanded: true,
                  })),
                  filteredTestcasesData: data.map((task) => ({
                    ...task,
                    expanded: true,
                  })),
                  filteredCustomTestcasesData: data.map((task) => ({
                    ...task,
                    expanded: true,
                  })),
                  loading: false,
                  graphCount: result.count,
                },
                () => {
                  this.state.untestedCount = result.count.untested || 0;
                  this.state.curruntTestName = result.data.name;
                  console.log("Active 5", result, this.state.showCreateTestRun);
                }
              );
            }
          }
          console.log("Test Result Data", this.state.testCasesData);
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState(
          {
            showCreateTestRun: false,
            testCasesEmpty: true,
            loading: false,
          },
          () => console.log("6 ", this.state.showCreateTestRun)
        );
      });
  };
  getProjectUserData = (flag) => {
    const token = Promises.getToken();
    let { projectUsers } = this.state;

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${BASE_URL}auth/user/project/${this.state.projectId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("FETCHING getProjectUserData ===========>", projectUsers);
        if (!result.status) {
          // this.setState({ testCasesEmpty: true, loading: false })
        } else {
          console.log(result);
          projectUsers = [];
          result.data.forEach((element) => {
            projectUsers.push({
              value: element.email,
              label: element.firstname,
            });
          });
          this.setState({ projectUsers: projectUsers });
          console.log("****--> " + JSON.stringify(projectUsers));
        }
      })
      .catch((error) => {
        console.log("error", error);
        
      });
  };
  getTableRow = () => {
    console.log("FETCHING ALL TESTCASES DATA ========>");

    const token = Promises.getToken();
    const {
      filterBrowserProps,
      filterOSProps,
      filterTypeProps,
      filterAssignProps,
    } = this.state;
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var raw = JSON.stringify({
      browserFilter: filterBrowserProps,
      osFilter: filterOSProps,
      executionTypeFilter: filterTypeProps,
      assigneeStatus: filterAssignProps,
    });

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      // body:raw,
      redirect: "follow",
    };

    console.log(requestOptions);

    // this.setState({ loading: false });

    fetch(
      `${BASE_URL}projects/task/assign/${this.state.projectId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("FETCHING RESULT ===========>", result);
        if (!result.data.status) {
          this.setState({ loading: false, showCreateTestRun: false }, () =>
            console.log("3 ", this.state.showCreateTestRun)
          );
        } else {
          const data = result.data.data;
          const output = [];
          data.forEach((task) => {
            task.lists.forEach((subtask) => {
              output.push({
                id: subtask.subtaskResultId,
                testcases: subtask.subTaskTitle || "",
                assignee: subtask.assignee || "unassigned",
                assignor: subtask.assignor || "unassigned",
                browser: subtask.browser || "Default",
                os: subtask.os || "Default",
                executiontype: subtask.testing || "",
              });
            });
          });
          console.log("data to show", output);
          if (data.length === 0) {
            this.setState({ pending: false, showCreateTestRun: false });
          } else {
            if (result.testRunStatus === "INACTIVE") {
              console.log("InActive ", result);
              this.setState({
                tableRows: output,
                customTestCasesData: data,

                graphCount: result.count,
              });
            } else {
              this.setState(
                {
                  tableRows: output,
                  customTestCasesData: data.map((task) => ({
                    ...task,
                    expanded: true,
                  })),
                  filteredCustomTestcasesData: data.map((task) => ({
                    ...task,
                    expanded: true,
                  })),
                  pending: false,
                  graphCount: result.count,
                },
                () => {
                  this.state.untestedCount = result.count.untested;
                  this.state.curruntTestName = result.data.name;
                  console.log("active test case", result, this.state.tableRows);
                }
              );
              this.setState({ filteredData: this.state.tableRows });
            }
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
        NotificationManager.error(
          "Something went wrong with saving your data, This may happen due to internal server error, Please try Again"
        );
      });
  };

  getTestCasesData = (type) => {
    console.log("FETCHING DATA ========>");
    const token = Promises.getToken();
    const { curruntTestrunId } = this.state;
    const currentID = curruntTestrunId
      ? curruntTestrunId
      : this.state.projectId;
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    console.log(requestOptions);
    console.log("userid", Promises.getUserId());
    fetch(
      `${BASE_URL}projects/run/projectid/${this.state.projectId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("FETCHING RESULT ===========>", result);
        if (!result.data.status) {
          this.setState({ testCasesEmpty: true, loading: false });
        } else {
          console.log(result);
          const data = result.data.data;

          if (result.field && result.field.length > 0) {
            this.setState({ fildData: result.field });
          }

          if (data.length === 0) {
            this.setState({ testCasesEmpty: true, loading: false });
          } else {
            this.setState(
              {
                testCasesEmpty: false,
                testCasesData: data,
                loading: false,
                customTestCasesData: data.map((task) => ({
                  ...task,
                  expanded: true,
                })),
                filteredCustomTestcasesData: data.map((task) => ({
                  ...task,
                  expanded: true,
                })),
              },
              () => {
                if (type === "initial") {
                  const { testCasesData } = this.state;
                  // const obj = { name: '', type: 'input' };
                  const newData = testCasesData;
                  // newData.map((item, index) => {
                  //   const list = item.lists;
                  //   list.push(obj);
                  //   newData[index].lists = list;
                  // })
                  this.setState({
                    testCasesData: newData.map((task) => ({
                      ...task,
                      expanded: true,
                    })),
                    customTestCasesData: newData.map((task) => ({
                      ...task,
                      expanded: true,
                    })),
                    filteredCustomTestcasesData: newData.map((task) => ({
                      ...task,
                      expanded: true,
                    })),
                    
                  });
                  console.log("Inital ==> ", this.state.customTestCasesData);
                }
              }
            );
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ testCasesEmpty: true, loading: false });
      });
  };

  getCustomTestCasesData = (type) => {
    // console.log("FETCHING DATA ========>");
    const token = Promises.getToken();

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    // console.log(requestOptions);
    const { pageNumber, pageSize } = this.state.paginationDetails;
    fetch(`${BASE_URL}projects/task/${this.state.projectId}?page=${pageNumber}&pageSize=${pageSize}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("FETCHING RESULT ===========>", result);
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        if (!result.status) {
          this.setState({ testCasesEmpty: true});
        } else {
          // console.log(result);
          const data = result.data;
          // csvData = [];
          // csvData.push([
          //   "Section",
          //   "ID",
          //   "OS",
          //   "TestCase",
          //   "Type",
          //   "Status",
          //   "Browser",
          //   "Description",
          //   "Expected",
          //   "Username",
          // ]);
          // if (data) {
          //   data.forEach((a) => {
          // console.log("a data init" + JSON.stringify(a));
          //     if (a.lists) {
          //       a.lists.forEach((b) => {
          //         let barray = [];
          // console.log("barray init" + JSON.stringify(b));
          //         barray.push(a.taskTitle);
          //         let keyToImport = [
          //           "id",
          //           "os",
          //           "name",
          //           "type",
          //           "status",
          //           "browser",
          //           "summary",
          //           "testing",
          //           "username",
          //         ];
          //         keyToImport.forEach((c, index) => {
          //           // barray.push(b[c]);
          //           if (b.subtaskId && c === "id") {
          //             barray.push(b.subtaskId);
          //           } else if (b.os && c === "os") {
          //             barray.push(b.os);
          //           } else if (b.subTaskTitle && c === "name") {
          //             barray.push(b.subTaskTitle);
          //           } else if (b.browser && c === "browser") {
          //             barray.push(b.browser);
          //           } else if (b.description && c === "summary") {
          //             barray.push(b.description);
          //           } else if (b.testing && c === "testing") {
          //             barray.push(b.testing);
          //           } else if (b.username && c === "username") {
          //             barray.push(b.username);
          //           } else {
          //             barray.push(b[c]);
          //           }
          // console.log("barray " + c);
          //         });
          //         // console.log("barray " + barray);
          //         csvData.push(barray);
          //       });
          //     }
          //   });
          // }
          // Need to discuss
          // if (result.field && result.field.length > 0) {
          //   this.setState({ fildData: result.field });
          // }

          if (data.length === 0 || (data.length === 1 && data[0].lists.length === 0)) {
            this.setState({ testCasesEmpty: true});
            
            
          } else {
            // const { testCasesData } = this.state;
            // const newData = testCasesData;
            // let obj = {};
            // testCasesData.map((item, index) => {
            //   // const list = item.lists;
            //   // list.push(obj);
            //   // newData[index].lists = list;
            //   obj.id = item.id;
            //   obj.model = item.taskTitle;
            //   obj.lists = item.lists;
            // });
            // console.log(obj);

            // ************************************* //
            this.setState(
              {
                testCasesEmpty: false,
                customTestCasesData: data.map((task) => ({
                  ...task,
                  expanded: true,
                })),
                filteredCustomTestcasesData: data.map((task) => ({
                  ...task,
                  expanded: true,
                })),
                testCasesEmpty: false,
              },
              () => {
                if (type === "initial") {
                  const { customTestCasesData } = this.state;
                  // const obj = { name: '', type: 'input' };
                  const newData = customTestCasesData;

                  const dataArry = [];
                  newData.map((item, index) => {
                    // const list = item.lists;
                    // list.push(obj);
                    // newData[index].lists = list;
                    // console.log(item);
                    let obj = {};
                    obj.id = item.id;
                    obj.taskTitle = item.taskTitle || item.model;
                    obj.lists = item.lists;
                    item.lists.map((val) => {
                      console.log("Fields ", val.field);
                    });
                    this.setState(
                      {
                        fildData: item.lists.map((val) => {
                          return val.field;
                        }),
                      },
                      () => {
                        console.log("Filed Data", this.state.fildData);
                      }
                    );
                    if (item.lists.length === 0) {
                      obj.lists = [
                        {
                          field: null,
                          subtaskId: null,
                          subid: null,
                          os: null,
                          subTaskTitle: null,
                          browser: null,
                          summary: null,
                          testing: "",
                          username: "",
                          description: null,
                          id: "",
                        },
                      ];
                    }
                    // console.log(obj);

                    dataArry.push(obj);
                  });
                  // console.log("Testers");
                  // console.log(dataArry);
                  this.setState({ customTestCasesData: dataArry.map((task) => ({
                    ...task,
                    expanded: true,
                  })),
                  filteredCustomTestcasesData: dataArry.map((task) => ({
                    ...task,
                    expanded: true,
                  }))});
                }
              }
            );
          }
        }
      })
      .catch((error) => {
        // console.log("error", error);
        this.setState({ testCasesEmpty: true });
        localStorage.clear();
        history.push("/login?redirect=1");
      });
  };

  onCreateRun = () => {
    if(this.state.testCasesEmpty){
      NotificationManager.error("No test cases found, to Create test Run");
      return;
    }
    const { name, description, TestRundateAndTime } = this.state;
    const token = Promises.getToken();
    this.setState({ loading: true });
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var raw = JSON.stringify({
      name: name || TestRundateAndTime,
      description: description || TestRundateAndTime,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BASE_URL}projects/run/${this.state.projectId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        console.log(result);
        if (result.data) {
          // const tempRes = JSON.parse(result);
          this.componentDidMount();
          localStorage.setItem("onCreateRunName", description);
          this.setState(
            {
              curruntTestrunId: this.state.projectId,
              showCreateTestRun: true,
              curruntTestName: description,
              // createModalVisible: false,
              isTestRunCreated: true,
              loading: true,
            },
            () => console.log("7 ", this.state.showCreateTestRun)
          );
          localStorage.setItem("curruntTestrunId", result.data.id);
          localStorage.setItem("isTestRunCreated", true);
          NotificationManager.success("TestRun Created Successfully");
          this.getTestCasesData("initial");
        } else {
          this.setState(
            {
              // createModalVisible: false,
              showCreateTestRun: false,
              loading: false,
            },
            () => console.log("8 ", this.state.showCreateTestRun)
          );
          NotificationManager.error("No test cases found");
        }
      })
      .catch((error) => {
        this.setState({
          createModalVisible: false,
          loading: false,
        });
        console.log("error", error);
        NotificationManager.error("No test cases found");
        localStorage.clear();
        history.push("/login?redirect=1");
      });
  };

  onCreateCustomRun = () => {
    const { name, description, isCheckParent, isCheck } = this.state;
    const jsonArray = [];
    const token = Promises.getToken();

    console.log("** ", this.state.isCheckParent);
    console.log("**** ", this.state.isCheck);
    this.setState({
      filterProps: [],
      filterParameters: {
        type: [],
        priority: [],
        testType: [],
      },
    })

    isCheckParent.forEach((taskId) => {
      jsonArray.push({
        task_id: taskId,
        all_subtask: true,
      });
    });

    if (isCheck.length > 0) {
      jsonArray.push({
        subtask_id_list: isCheck,
      });
    }

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    // var raw = JSON.stringify({
    //   name: name,
    //   description: description,
    // });

    var raw = JSON.stringify({
      name: name,
      description: description,
      data: jsonArray,
    });

    console.log("Raw --> ", raw);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BASE_URL}projects/run/${this.state.projectId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        console.log(result);
        console.log(result);
        if (result.data) {
          // const tempRes = JSON.parse(result);
          localStorage.setItem("onCreateRunName", description);
          this.setState(
            {
              curruntTestrunId: this.state.projectId,
              showCreateTestRun: true,
              curruntTestName: description,
              createCustomListModalVisible: false,
              createCustomModalVisible: false,
              isTestRunCreated: true,
              loading: true,
              isCheck: [],
              untestedCount : result.count.untested,
              curruntTestName : result.data.name
            },
            () =>{console.log("9 ", this.state.showCreateTestRun)}
          );
          localStorage.setItem("curruntTestrunId", result.data.id);
          localStorage.setItem("isTestRunCreated", true);
          NotificationManager.success("TestRun Created Successfully");
          console.log("isChecked ", this.state.isCheck);
          this.getTestCasesData("initial");
        } else {
          this.setState(
            {
              // createModalVisible: false,
              showCreateTestRun: false,
              loading: false,
            },
            () => console.log("10 ", this.state.showCreateTestRun)
          );
          NotificationManager.error("No test cases found");
        }
      })
      .catch((error) => {
        this.setState({
          createModalVisible: false,
          loading: false,
        });
        console.log("error", error);
        NotificationManager.error("No test cases found");
        localStorage.clear();
        history.push("/login?redirect=1");
      });
  };

  onModelListUpdate = (index, subIndex, val) => {
    const { testCasesData } = this.state;
    let newState = update(testCasesData, {
      [index]: {
        lists: {
          [subIndex]: {
            name: { $set: val.target.value },
          },
        },
      },
    });
    this.setState({ testCasesData: newState });
  };

  deleteModel = (index) => {
    const { testCasesData } = this.state;
    let newState = update(testCasesData, {
      $splice: [[index, 1]],
    });
    this.setState({ testCasesData: newState });
  };

  deleteList = (index, i) => {
    const { testCasesData } = this.state;
    let newState = update(testCasesData, {
      [index]: {
        lists: {
          $splice: [[i, 1]],
        },
      },
    });
    this.setState({ testCasesData: newState });
  };

  onAdd = (type, index) => {
    this.setState({ testCasesEmpty: false });
    const { testCasesData } = this.state;

    const newElement = {
      model: "",
      lists: [{ name: "", testStatus: "failed" }],
    };

    const newList = { name: "", testStatus: "failed" };

    if (type === "model") {
      this.setState({
        testCasesData: update(testCasesData, {
          $push: [newElement],
        }),
      });
    } else {
      this.setState({
        testCasesData: update(testCasesData, {
          [index]: {
            lists: {
              $push: [newList],
            },
          },
        }),
      });
    }
  };

  updateStatus = (taskIndex, subTaskIndex, newStatus, resultID) => {
    // const { testCasesData } = this.state;
    // this.setState(
    //   {
    //     testCasesData: update(testCasesData, {
    //       [index]: {
    //         lists: {
    //           [i]: {
    //             status: { $set: newStatus },
    //             flag: { $set: 1 },
    //             testing: { $set: "manual" },
    //           },
    //         },
    //       },
    //     }),
    //   },
    //   () => {
    //     const isClickSave = true;
    //     this.saveChangesTestRun(isClickSave);
    //   }
    // );
    const copyTask = [...this.state.testCasesData];
    const myRowIndex = copyTask.findIndex((row, i) => i === taskIndex);
    copyTask[myRowIndex].lists[subTaskIndex].testStatus = newStatus;

    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    let raw = {
      status: newStatus,
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    fetch(`${BASE_URL}result/${resultID}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (JSON.parse(result).code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        console.log(JSON.parse(result));
        // if(!isClickSave){
        //   const orgId = Promises.getOrg();
        //   var raw = {
        //     id:curruntTestrunId,
        //     orgId: orgId,
        //     pname:curruntprojectName
        //   }

        //   var requestOptions = {
        //     method: 'POST',
        //     headers: myHeaders,
        //     body: JSON.stringify(raw),
        //     redirect: 'follow'
        //   };

        //   fetch(`${BASE_URL}projects/email`, requestOptions).then(response => response.text())
        //   .then(result => {
        //     console.log('done')
        //   })
        //   localStorage.removeItem('curruntTestrunId')
        //   localStorage.removeItem('isTestRunCreated')
        //   this.props.handler();
        // }
      })
      .catch((error) => {
        console.log("error", error);
        NotificationManager.error(
          "Something went wrong with saving your data, This may happen due to internal server error, Please try Again"
        );
        localStorage.clear();
        history.push("/login?redirect=1");
      });

    this.setState({ testCasesData: copyTask });

    // console.log(taskIndex + " ** " + subTaskIndex + " ** " + newStatus);
    // console.log(
    //   "=== Update Status ==> " + JSON.stringify(this.state.testCasesData)
    // );
  };

  handleChangeName = (event) => {
    this.setState({ name: event.target.value });
  };
  handleChangeDesc = (event) => {
    this.setState({ description: event.target.value });
  };
  toggleprofile() {
    this.setState((prevState) => ({
      dropdownOpenprofile: !prevState.dropdownOpenprofile,
    }));
  }
  toggleprofile1() {
    this.setState((prevState) => ({
      dropdownOpenprofile1: !prevState.dropdownOpenprofile1,
    }));
  }
  toggleprofile2 = () => {
    this.setState((prevState) => ({
      dropdownOpenprofile2: !prevState.dropdownOpenprofile2,
    }));
  };
  saveChangesTestRun = (isClickSave) => {
    console.log("SAVING DATA =============>");
    const { testCasesData, curruntTestrunId, curruntprojectName } = this.state;
    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);
    var raw;
    // if (!isClickSave) {
    //   raw = {
    //     data: testCasesData,
    //     isProcessing: 1,
    //   };
    // } else {
    //   raw = {
    //     data: testCasesData,
    //   };
    // }

    console.log(this.state.projectId);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: "",
      redirect: "follow",
    };

    fetch(
      `${BASE_URL}projects/update/run/${this.state.projectId}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        if (JSON.parse(result).code === 403) {
          NotificationManager.error(JSON.parse(result).error);
        } else {
          console.log(JSON.stringify(result));
          // if(!isClickSave){
          const orgId = Promises.getOrg();
          var raw = {
            id: curruntTestrunId,
            orgId: orgId,
            pname: curruntprojectName,
          };

          let emailRequestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(raw),
            redirect: "follow",
          };

          fetch(`${BASE_URL}projects/email`, emailRequestOptions)
            .then((response) => response.text())
            .then((result) => {
              if (JSON.parse(result).code === 401) {
                this.setState({ loading: false });
                localStorage.clear();
                history.push("/login?redirect=1");
              }
              console.log("== Email Sent ==>");
            });
          localStorage.removeItem("curruntTestrunId");
          localStorage.removeItem("isTestRunCreated");
          this.props.handler();
        }
        // }
      })
      .catch((error) => {
        console.log("error", error);
        NotificationManager.error(
          "Something went wrong with saving your data, This may happen due to internal server error, Please try Again"
        );
        localStorage.clear();
        history.push("/login?redirect=1");
      });
  };

  saveCustomChangesTestRun = (isClickSave) => {
    console.log("SAVING DATA =============>");
    const {
      testCasesData,
      isCheck,
      name,
      description,
      curruntTestrunId,
      curruntprojectName,
    } = this.state;
    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var raw = {
      name: name,
      description: description,
      data: [
        {
          subtask_id_list: this.state.isCheck.map((val) => val.subtaskId),
        },
      ],
    };
    console.log(raw);
    // if (!isClickSave) {
    //   raw = {
    //     data: testCasesData,
    //     isProcessing: 1,
    //   };
    // } else {
    //   raw = {
    //     data: testCasesData,
    //   };
    // }

    console.log(this.state.projectId);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    fetch(`${BASE_URL}projects/run/${this.state.projectId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(JSON.stringify(result));
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        // if(!isClickSave){
        const orgId = Promises.getOrg();
        var raw = {
          id: curruntTestrunId,
          orgId: orgId,
          pname: curruntprojectName,
        };

        let emailRequestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(raw),
          redirect: "follow",
        };

        fetch(`${BASE_URL}projects/email`, emailRequestOptions)
          .then((response) => response.text())
          .then((result) => {
            console.log("== Email Sent ==>");
            if (JSON.parse(result).code === 401) {
              this.setState({ loading: false });
              localStorage.clear();
              history.push("/login?redirect=1");
            }
          });
        localStorage.removeItem("curruntTestrunId");
        localStorage.removeItem("isTestRunCreated");
        this.props.handler();
        // }
      })
      .catch((error) => {
        console.log("error", error);
        NotificationManager.error(
          "Something went wrong with saving your data, This may happen due to internal server error, Please try Again"
        );
        localStorage.clear();
        history.push("/login?redirect=1");
      });
  };

  getTheme = (status) => {
    // console.log("Color ==> " + status);
    switch (status) {
      case "untested":
        return "#FF9A28";
      case "pass":
        return "#00C865";
      case "failed":
        return "#FF4440";
      default:
        return "#FF9A28";
    }
  };
  getUsername = (name) => {
    if (name && name !== "") {
      return name;
    } else {
      return localStorage.getItem("name");
    }
  };

  selectCollapse = (ele, index) => {
    const { testCasesData } = this.state;
    const newData = testCasesData;
    newData[index].isCollapse = !ele.isCollapse;
    console.log("Testcase ", testCasesData);
    this.setState({ testCasesData: newData });
  };

  onParentCheckbox = (ele, index) => {
    const { testCasesData } = this.state;
    const newData = testCasesData;
    newData[index].isChecked = !ele.isChecked;

    if (newData[index].isChecked === false) {
      newData[index].lists.map((li) => (li.isChildChecked = false));

      this.setState(
        {
          isCheck: this.state.isCheck.filter(
            (item) => item.taskId !== ele.taskId
          ),
        }
        // () => console.log(this.state.isCheck)
      );
    } else {
      newData[index].lists.map((li) => {
        if (!li.isChildChecked) {
          li.isChildChecked = !li.isChildChecked;
        }
        // console.log(li);
        if (li.isChildChecked) {
          // this.setState({ isCheck: newData[index].lists });
          this.setState(
            (prevState) => ({
              isCheck: [
                ...prevState.isCheck,
                {
                  subtaskId: li.subtaskId,
                  status: li.testStatus,
                },
              ],
            }),
            () => console.log(this.state.isCheck)
          );
          // console.log(this.state.isCheck);
        }
      });
    }
    // newData[index].lists.map((li) => {
    //   if(li.isChildChecked){
    //     this.setState({ isCheck: [...this.state.isCheck, li] });
    //   }
    // });
    console.log(this.state.isCheck);
    this.setState({ testCasesData: newData });
  };

  onCustomizeParentCheckbox = (ele, index) => {
    const { filteredCustomTestcasesData } = this.state;
    const newData = filteredCustomTestcasesData;

    newData[index].isChecked = !ele.isChecked;
    // console.log(ele);

    if (newData[index].isChecked === false) {
      const updatedParentArr = this.state.isCheckParent?.filter(
        (currVal) => currVal !== ele.id
      );
      this.setState({
        isCheckParent: updatedParentArr,
      });
      newData[index].lists.map((li) => (li.isChildChecked = false));
    } else {
      this.setState({ isCheckParent: [...this.state.isCheckParent, ele.id] });
      newData[index].lists.map((li) => (li.isChildChecked = true));
    }

    // if (newData[index].isChecked === false) {
    //   newData[index].lists.map((li) => (li.isChildChecked = false));

    //   this.setState(
    //     {
    //       isCheck: this.state.isCheck.filter(
    //         (i) => !newData[index].lists.some((j) => j.subtaskId === i)
    //       ),
    //     },
    //     () => {
    //       console.log("if ", this.state.isCheck);
    //     }
    //   );

    //   // newData[index].lists.map((li) => (li.isChildChecked = false));

    //   // this.setState(
    //   //   {
    //   //     isCheck: this.state.isCheck.filter(
    //   //       (item) => item.taskId !== ele.taskId
    //   //     ),
    //   //   },
    //   //   () => console.log(this.state.isCheck)
    //   // );
    // } else {
    //   newData[index].lists.map((li) => {
    //     if (!li.isChildChecked) {
    //       li.isChildChecked = !li.isChildChecked;
    //     }
    //     // console.log(li);
    //     if (li.isChildChecked) {
    //       // this.setState({ isCheck: newData[index].lists });
    //       this.setState(
    //         (prevState) => ({
    //           isCheck: [
    //             ...prevState.isCheck,
    //             {
    //               subtaskId: li.subtaskId,
    //               status: li.testStatus,
    //             },
    //           ],
    //         }),
    //         () => console.log(this.state.isCheck)
    //       );
    //       // console.log(this.state.isCheck);
    //     }
    //   });
    // }
    // newData[index].lists.map((li) => {
    //   if(li.isChildChecked){
    //     this.setState({ isCheck: [...this.state.isCheck, li] });
    //   }
    // });
    console.log(this.state.isCheckParent);
    this.setState({ filteredCustomTestcasesData: newData });
  };

  onChildCheckbox = (subtaskId, currentItem, parentIndex, childIndex) => {
    const { testCasesData } = this.state;
    // console.log(testCasesData);
    const newData = testCasesData;
    const found = this.state.isCheck.some((el) => el.subtaskId === subtaskId);
    newData[parentIndex].lists[childIndex].isChildChecked =
      !currentItem.isChildChecked;
    // console.log(newData[parentIndex].lists[childIndex].isChildChecked);
    if (!found) {
      // console.log("Found ", found);
      this.setState(
        (prevState) => ({
          isCheck: [
            ...prevState.isCheck,
            {
              subtaskId: currentItem.subtaskId,
              status: currentItem.testStatus,
            },
          ],
        }),
        () => console.log(this.state.isCheck)
      );
    } else {
      console.log("Found ", found);

      this.setState(
        {
          isCheck: this.state.isCheck.filter(
            (item) => item.subtaskId !== subtaskId
          ),
        },
        () => console.log(this.state.isCheck)
      );
    }

    console.log(this.state.isCheck);
    this.setState({ testCasesData: newData });
  };

  onCustomizeChildCheckbox = (
    subtaskId,
    currentItem,
    parentIndex,
    childIndex
  ) => {
    const { filteredCustomTestcasesData } = this.state;
    const newData = filteredCustomTestcasesData;
    const found = this.state.isCheck.some((el) => el === subtaskId);

    newData[parentIndex].lists[childIndex].isChildChecked =
      !currentItem.isChildChecked;

    if (found) {
      const updatedCheck = this.state.isCheck?.filter(
        (currVal) => currVal !== subtaskId
      );
      this.setState({
        isCheck: updatedCheck,
      });
      newData[parentIndex].lists[childIndex].isChildChecked = false;
    } else {
      this.setState({ isCheck: [...this.state.isCheck, subtaskId] });
      newData[parentIndex].lists[childIndex].isChildChecked = true;
    }

    // console.log(newData[parentIndex].lists[childIndex].isChildChecked);
    // if (!found) {
    //   console.log("Found 1", found);
    //   let isAllSubtaskSelect = newData[parentIndex].lists.every(
    //     (v) => v.isChildChecked === true
    //   );
    //   isAllSubtaskSelect
    //     ? (newData[parentIndex].isChecked = true)
    //     : (newData[parentIndex].isChecked = false);
    //   this.setState(
    //     (prevState) => ({
    //       isCheck: [
    //         ...prevState.isCheck,
    //         {
    //           subtaskId: currentItem.subtaskId,
    //           status: currentItem.testStatus,
    //         },
    //       ],
    //     }),
    //     () => console.log(this.state.isCheck)
    //   );
    // } else {
    //   console.log("Found ", found);
    //   let isAllSubtaskSelect = newData[parentIndex].lists.every(
    //     (v) => v.isChildChecked === false
    //   );
    //   isAllSubtaskSelect
    //     ? (newData[parentIndex].isChecked = false)
    //     : (newData[parentIndex].isChecked = true);
    //   this.setState(
    //     {
    //       isCheck: this.state.isCheck.filter(
    //         (item) => item.subtaskId !== subtaskId
    //       ),
    //     },
    //     () => console.log(this.state.isCheck)
    //   );
    // }

    console.log(this.state.isCheck);
    this.setState({ filteredCustomTestcasesData: newData });
  };

  setAllUntested = async () => {
    const { testCasesData } = this.state;
    const untested = [];

    const newData = testCasesData.map((task) => {
      task.lists.map((sub) => {
        sub.testStatus = sub.status = "untested";
        this.setState({ testCasesData });
      });

      //getting tasks & status
      untested.push({
        taskId: task.taskId,
        status: "untested",
      });
      return task;
    });

    await this.setState({ testCasesData: newData });

    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    // var raw = {

    // };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(untested),
      redirect: "follow",
    };

    fetch(`${BASE_URL}result/bulk`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
      })
      .catch((error) => {
        console.log("error", error);
        NotificationManager.error(
          "Something went wrong with saving your data, This may happen due to internal server error, Please try Again"
        );
        localStorage.clear();
        history.push("/login?redirect=1");
      });
  };

  setAllPass = async () => {
    console.log(this.state.isCheck);
    const { testCasesData } = this.state;
    const passedTasks = [];

    const newData = testCasesData.map((task) => {
      task.lists.map((sub) => {
        sub.testStatus = sub.status = "pass";
        this.setState({ testCasesData });
      });

      //getting tasks & status
      passedTasks.push({
        taskId: task.taskId,
        status: "pass",
      });
      return task;
    });

    await this.setState({ testCasesData: newData });

    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    // var raw = {

    // };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(passedTasks),
      redirect: "follow",
    };

    fetch(`${BASE_URL}result/bulk`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
      })
      .catch((error) => {
        console.log("error", error);
        NotificationManager.error(
          "Something went wrong with saving your data, This may happen due to internal server error, Please try Again"
        );
        localStorage.clear();
        history.push("/login?redirect=1");
      });
  };

  setAllFailed = async () => {
    const { testCasesData } = this.state;
    const failed = [];

    const newData = testCasesData.map((task) => {
      task.lists.map((sub) => {
        sub.testStatus = sub.status = "failed";
        this.setState({ testCasesData });
      });

      //getting tasks & status
      failed.push({
        taskId: task.taskId,
        status: "failed",
      });
      return task;
    });

    await this.setState({ testCasesData: newData });
    console.log("State1 ", this.state.isCheck);

    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(failed),
      redirect: "follow",
    };
    console.log("Failed ", this.state.isCheck);

    fetch(`${BASE_URL}result/bulk`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
      })
      .catch((error) => {
        console.log("error", error);
        NotificationManager.error(
          "Something went wrong with saving your data, This may happen due to internal server error, Please try Again"
        );
        localStorage.clear();
        history.push("/login?redirect=1");
      });
  };

  onClickDetail = (i, subTaskId) => {
    console.log("+++>");
    let { dynamicStyle } = this.state;
    dynamicStyle[i] = "40%";
    this.setState({
      dynamicStyle: dynamicStyle,
    });
    this.getTestcaseHistory(subTaskId);
    if (this.state.latest_key) {
      this.getTestResult();
    }
  };

  getTestcaseHistory = (subTaskId) => {
    this.setState({ testHistory: [] });
    console.log(subTaskId);

    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BASE_URL}task/subtask/result/${subTaskId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        // console.log(result);
        // result.map((data) => console.log(data));
        this.setState({ testHistory: result }, () => {
          console.log("test history:-" + result);
        });
        // NotificationManager.success("Field updated successfully");
        // this.getTestCasesData('')
      })
      .catch((error) => {
        // console.log("error", error);
        NotificationManager.error(
          "Something went wrong with saving your data, This may happen due to internal server error, Please try Again"
        );
        // localStorage.clear();
        // history.push("/login?redirect=1");
      });
  };

  onClickCloseDetail = (i) => {
    let { dynamicStyle } = this.state;
    dynamicStyle[i] = "0%";
    this.setState({
      dynamicStyle: dynamicStyle,
    });
    this.setState({ testHistory: [] });
  };

  onSubmitsubTaskDataInSideDrawer = (e, subTaskTitle) => {
    e.preventDefault();
    const data = new FormData(e.target);
    console.log("On Submit");
    console.log(
      data.get("subTaskIDInSideDrawer") +
        " **  " +
        data.get("subTaskTaskIDBtnInSideDrawer") +
        " **  " +
        subTaskTitle +
        " **  " +
        data.get("subTaskDescriptionInSideDrawer") +
        " **  " +
        data.get("subTaskSummaryInSideDrawer") +
        " **  " +
        data.get("subTaskBrowserInSideDrawer") +
        " **  " +
        data.get("subTaskOsInSideDrawer") +
        " **  " +
        data.get("subTaskTestingInSideDrawer") +
        " **  " +
        data.get("subTaskCreatedByInSideDrawer")
    );

    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var raw = {
      projectid: this.state.projectId,
      taskid: data.get("subTaskTaskIDBtnInSideDrawer"),
      title: subTaskTitle,
      status: data.get("subTaskStatusInSideDrawer"),
      details: {
        subid: data.get("subTaskShortIDBtnInSideDrawer"),
        description: data.get("subTaskDescriptionInSideDrawer"),
        summary: data.get("subTaskSummaryInSideDrawer"),
        browser: data.get("subTaskBrowserInSideDrawer"),
        os: data.get("subTaskOsInSideDrawer"),
        testing: data.get("subTaskTestingInSideDrawer"),
        username: data.get("subTaskCreatedByInSideDrawer"),
        priority: data.get("subTaskpriorityInSideDrawer"),
        test_type: this.state.test_type_value,
        field: [{ rawJSON: "" }],
      },
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    fetch(
      `${BASE_URL}result/subtaskresult/${data.get(
        "subTaskResultIDInSideDrawer"
      )}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // testCasesData.unshift({
        //   id: result.taskId,
        //   model: this.state.name,
        //   lists: [
        //     {
        //       field: [],
        //       subtaskId: null,
        //       subid: null,
        //       os: null,
        //       subTaskTitle: null,
        //       browser: null,
        //       summary: null,
        //       testing: "",
        //       username: "",
        //       description: null,
        //       id: "",
        //     },
        //   ],
        // });
        console.log(result);
        // console.log(testCasesData);
        // this.getTestCasesData('working')
      })
      .catch((error) => {
        console.log("error", error);
        NotificationManager.error(
          "Something went wrong with saving your data, This may happen due to internal server error, Please try Again"
        );
      });
  };

  handleChangeIntigretion = (selectedOptIntegration) => {
    this.setState({ selectedOptIntegration }, () => {
      console.log("slecetd issue", this.state.selectedOptIntegration);
    });
  };

  pushIntegration = async (
    indexValue,
    isLink = false,
    issueKey = this.state.selectedOptIntegration,
    intType
  ) => {
    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);
    if (!issueKey?.label && this.state.issueInputValue == "") {
      return;
    }
    var raw = {
      subtaskResultId: indexValue,
      projectid: this.state.projectId,
      issue_name: this.state.issueInputValue,
      issue_desc: this.state.issueInputDesc,
      isLink,
      issueKey,
      intType,
    };
    console.log("created new", raw);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    fetch(`${BASE_URL}task/subtask/integration`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        if (result && result?.data) {
          this.getIntegrationData();
          this.setState({
            latest_key: result?.data || result.data?.data?.number,
          });
          if (result.code === 200) {
            NotificationManager.success(result.msg);
          }
        } else {
          NotificationManager.error(result.msg);
        }
      })
      .catch((error) => {
        NotificationManager.error(
          "Something went wrong with saving your data, This may happen due to internal server error, Please try Again"
        );
      });
  };

  getIntegrationData = () => {
    const token = Promises.getToken();

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${BASE_URL}projects/task/integration/${this.state.projectId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (!result.status) {
          this.setState({
            isIntegrationData: false,
            testCasesEmpty: false,
            
          });
        } else {
          // console.log(result);
          const data = result.data;
          let tmpIssue = [];
          if (result.type === "asana" || result.type === "trello") {
            result.issues.forEach((element) => {
              tmpIssue.push({
                label2: (
                  <Tooltip title="Vanilla" arrow>
                    <span>{element.name}</span>
                  </Tooltip>
                ),
                label: element.name,
                value: element.gid,
                status: element.status,
              });
            });
          } else if (result.type === "github") {
            result.issues.forEach((element) => {
              tmpIssue.push({
                label2: (
                  <Tooltip title="Vanilla" arrow>
                    <span>{element.name}</span>
                  </Tooltip>
                ),
                label: element.name,
                value: element.id,
                status: element.status,
              });
            });
          } else {
            result.issues.forEach((element) => {
              tmpIssue.push({
                label2: (
                  <Tooltip title="Vanilla" arrow>
                    <span>{element.key}</span>
                  </Tooltip>
                ),
                label: element.key,
                value: element.value,
                status: element.status,
              });
            });
          }

          this.setState({
            isIntegrationData: true,
            integrationData: data,
            testCasesEmpty: true,
          
            issues: tmpIssue,
            intType: result.type,
          });
        }
      })
      .catch((error) => {
        // console.log("error", error);
        this.setState({
          isIntegrationData: false,
          testCasesEmpty: true,
          
        });
      });
  };

  /*
  ..######...#######..##.....##.########...#######..##....##.########.##....##.########
  .##....##.##.....##.###...###.##.....##.##.....##.###...##.##.......###...##....##...
  .##.......##.....##.####.####.##.....##.##.....##.####..##.##.......####..##....##...
  .##.......##.....##.##.###.##.########..##.....##.##.##.##.######...##.##.##....##...
  .##.......##.....##.##.....##.##........##.....##.##..####.##.......##..####....##...
  .##....##.##.....##.##.....##.##........##.....##.##...###.##.......##...###....##...
  ..######...#######..##.....##.##.........#######..##....##.########.##....##....##...
  */

  handleBrowserChange = (rowId, selectedoption) => {
    console.log("new val", rowId, selectedoption.target.value);
  };
  handDetailChange = (type) => {
    const { selectedRows, bulkBrowser, bulkOs, bulkExecutiontype, tableRows } =
      this.state;
    console.log(
      "check",
      selectedRows,
      bulkBrowser,
      bulkOs,
      bulkExecutiontype,
      tableRows
    );
    let infoObj = [];

    if (type === "browser") {
      selectedRows.forEach((id) => {
        const tableRowData = tableRows.find((tableRow) => tableRow.id === id);

        if (tableRowData) {
          infoObj.push({
            id: id,
            browser: bulkBrowser,
            os: tableRowData.os,
            testType: tableRowData.executiontype,
          });
        }
      });
    } else if (type === "os") {
      selectedRows.forEach((id) => {
        const tableRowData = tableRows.find((tableRow) => tableRow.id === id);

        if (tableRowData) {
          infoObj.push({
            id: id,
            browser: tableRowData.browser,
            os: bulkOs,
            testType: tableRowData.executiontype,
          });
        }
      });
    } else if (type === "execution") {
      selectedRows.forEach((id) => {
        const tableRowData = tableRows.find((tableRow) => tableRow.id === id);

        if (tableRowData) {
          infoObj.push({
            id: id,
            browser: tableRowData.browser,
            os: tableRowData.os,
            testType: bulkExecutiontype,
          });
        }
      });
    }
    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    console.log(infoObj);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(infoObj),
      redirect: "follow",
    };
    console.log(requestOptions);

    fetch(`${BASE_URL}result/bulk`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.code === 200) {
          this.setState({
            bulkBrowser: "",
            bulkOs: "",
            bulkExecutiontype: "",
            toggledClearRows: true,
          });
          NotificationManager.success(result.msg, "Updated");

          this.getTableRow();
        } else {
          NotificationManager.error(result.msg);
        }
      })
      .catch((error) => {
        console.log("error", error);
        NotificationManager.error(
          "Something went wrong with saving your data, This may happen due to internal server error, Please try Again"
        );
      });
  };
  onProjectTestrun = () => {
    const { name, description, createModalVisible, TestRundateAndTime } =
      this.state;

    return (
      <Modal
        className="create-modal test-run-model"
        onRequestClose={() => this.setState({ createModalVisible: false })}
        isOpen={createModalVisible}
      >
        <div className="test-run-modal-title">
          <h3>Create Test Run</h3>
          <i
            className="mdi mdi-close"
            onClick={() => this.setState({ createModalVisible: false })}
          ></i>
        </div>
        {/* <label id="label" className="m-0" htmlFor="name">
          Name
        </label>
        <input
          type="text"
          className="form-control"
          name="username"
          placeholder={TestRundateAndTime}
          defaultValue={TestRundateAndTime}
          value={name}
          onChange={this.handleChangeName}
        /> */}
        <label id="label" htmlFor="description">
          Testrun name
        </label>
        <input
          type="text"
          className="form-control"
          name="username"
          defaultValue={TestRundateAndTime}
          value={description}
          onChange={this.handleChangeDesc}
          placeholder={TestRundateAndTime}
        />
        <button className="btn btn-primary mr-3" onClick={this.onCreateRun}>
          Create
        </button>
        {"or"}
        <button className="btn btn-primary mr-3" onClick={this.onCreateRun}>
          Customize
        </button>
        <button
          className="btn btn-secondary test-run-cancel"
          onClick={() => this.setState({ createModalVisible: false })}
        >
          Cancel
        </button>
      </Modal>
    );
  };

  onFilteredPropsItems = (filteredValue) => {
    console.log(filteredValue);
    const found = this.state.filterProps.some(
      (el) => el === filteredValue.toString().toLowerCase()
    );

    if (!found) {
      // console.log("Found ", found);
      this.setState(
        (prevState) => ({
          filterProps: [
            ...prevState.filterProps,
            filteredValue.toString().toLowerCase(),
          ],
        }),
        () => console.log(this.state.filterProps)
      );
    } else {
      // console.log("Found ", found);

      this.setState(
        {
          filterProps: this.state.filterProps.filter(
            (item) => item !== filteredValue.toString().toLowerCase()
          ),
        },
        () => console.log(this.state.filterProps)
      );
    }
  };

  onCustomTestrun = () => {
    const { name, description, createCustomModalVisible, TestRundateAndTime } =
      this.state;

    // this.setState({ createModalVisible: false });

    return (
      <Modal
        className="create-modal test-run-model"
        onRequestClose={() =>
          this.setState({ createCustomModalVisible: false })
        }
        isOpen={createCustomModalVisible}
      >
        <div className="test-run-modal-title">
          <h3>Customize Test Run</h3>
          <i
            className="mdi mdi-close"
            onClick={() => this.setState({ createCustomModalVisible: false })}
          ></i>
        </div>
        <label id="label" className="m-0" htmlFor="name">
          Name
        </label>
        <input
          type="text"
          className="form-control"
          name="username"
          placeholder={TestRundateAndTime}
          defaultValue={TestRundateAndTime}
          value={name}
          onChange={this.handleChangeName}
        />
        <label id="label" htmlFor="description">
          Description
        </label>
        <textarea
          className="form-control test-run-desc"
          defaultValue={TestRundateAndTime}
          value={description}
          onChange={this.handleChangeDesc}
        ></textarea>
        <button
          className="btn btn-primary mr-3"
          onClick={this.onCreateCustomRun}
        >
          Create
        </button>

        <button
          className="btn btn-secondary test-run-cancel"
          onClick={() => this.setState({ createCustomModalVisible: false })}
        >
          Cancel
        </button>
      </Modal>
    );
  };

  InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    // const [isActive, setIsActive] = useState(false);
    // const onMouseDown = () => setIsActive(true);
    // const onMouseUp = () => setIsActive(false);
    // const onMouseLeave = () => setIsActive(false);

    // // styles
    // let bg = "transparent";
    // if (isFocused) bg = "#eee";
    // if (isActive) bg = "#B2D4FF";

    const style = {
      alignItems: "center",
      // backgroundColor: bg,
      color: "inherit",
      display: "flex ",
    };

    // prop assignment
    const props = {
      ...innerProps,
      // onMouseDown,
      // onMouseUp,
      // onMouseLeave,
      style,
    };
    return (
      <components.Option
        {...rest}
        // isDisabled={isDisabled}
        // isFocused={isFocused}
        // isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <input
          type="checkbox"
          className="reactSelect-checkbox"
          style={{ width: 18, marginRight: 15, height: 25 }}
          checked={isSelected}
        />
        {children}
      </components.Option>
    );
  };
  handleFilterType = (value) => {
    this.setState((prevState) => ({
      filterTypeProps: prevState.filterTypeProps.includes(value)
        ? prevState.filterTypeProps.filter((item) => item !== value)
        : [...prevState.filterTypeProps, value],
    }));
  };
  handleFilterAssign = (value) => {
    this.setState((prevState) => ({
      filterAssignProps: prevState.filterAssignProps.includes(value)
        ? prevState.filterAssignProps.filter((item) => item !== value)
        : [...prevState.filterAssignProps, value],
    }));
  };
  handleFilterBrowser = (value) => {
    this.setState((prevState) => ({
      filterBrowserProps: prevState.filterBrowserProps.includes(value)
        ? prevState.filterBrowserProps.filter((item) => item !== value)
        : [...prevState.filterBrowserProps, value],
    }));
  };

  applyFiltersBrowser = () => {
    const { tableRows, filteredData, filterBrowserProps, filterOSProps } =
      this.state;
    let newFilteredData = filteredData;

    if (filterBrowserProps.length > 0 && filterOSProps.length > 0) {
      newFilteredData = newFilteredData.filter((row) =>
        filterBrowserProps.includes(row.browser)
      );
    } else if (filterBrowserProps.length > 0) {
      newFilteredData = tableRows;
      newFilteredData = newFilteredData.filter((row) =>
        filterBrowserProps.includes(row.browser)
      );
    } else if (filterBrowserProps.length <= 0 && filterOSProps.length <= 0) {
      newFilteredData = tableRows;
    }

    this.setState({ filteredData: newFilteredData });
  };
  applyFilters = () => {
    const {
      tableRows,
      filterBrowserProps,
      filterOSProps,
      filterAssignProps,
      filterTypeProps,
    } = this.state;

    let newFilteredData = tableRows;

    if (filterBrowserProps.length > 0) {
      const lowerCaseBrowserProps = filterBrowserProps.map((prop) =>
        prop.toLowerCase()
      );
      newFilteredData = newFilteredData.filter((row) =>
        lowerCaseBrowserProps.includes(row.browser.toLowerCase())
      );
    }

    if (filterOSProps.length > 0) {
      const lowerCaseOSProps = filterOSProps.map((prop) => prop.toLowerCase());
      newFilteredData = newFilteredData.filter((row) =>
        lowerCaseOSProps.includes(row.os.toLowerCase())
      );
    }

    if (filterAssignProps.length > 0) {
      const lowerCaseAssignProps = filterAssignProps.map((prop) =>
        prop.toLowerCase()
      );
      newFilteredData = newFilteredData.filter((row) => {
        const assignee = row.assignee
          ? row.assignee.toLowerCase()
          : "unassigned";
        return (
          (assignee !== "unassigned" &&
            lowerCaseAssignProps.includes("assigned")) ||
          (assignee === "unassigned" &&
            lowerCaseAssignProps.includes("unassigned"))
        );
      });
    }

    if (filterTypeProps.length > 0) {
      const lowerCaseTypeProps = filterTypeProps.map((prop) =>
        prop.toLowerCase()
      );
      newFilteredData = newFilteredData.filter((row) =>
        lowerCaseTypeProps.includes(row.executiontype.toLowerCase())
      );
    }

    this.setState({ filteredData: newFilteredData });
  };
  handleFilterOS = (value) => {
    this.setState((prevState) => ({
      filterOSProps: prevState.filterOSProps.includes(value)
        ? prevState.filterOSProps.filter((item) => item !== value)
        : [...prevState.filterOSProps, value],
    }));
  };

  applyFiltersOS = () => {
    const { tableRows, filteredData, filterOSProps, filterBrowserProps } =
      this.state;
    let newFilteredData = filteredData;

    if (filterOSProps.length > 0 && filterBrowserProps.length > 0) {
      newFilteredData = newFilteredData.filter((row) =>
        filterOSProps.includes(row.os)
      );
    } else if (filterOSProps.length > 0) {
      newFilteredData = tableRows;
      newFilteredData = newFilteredData.filter((row) =>
        filterOSProps.includes(row.os)
      );
    } else if (filterBrowserProps.length <= 0 && filterOSProps.length <= 0) {
      newFilteredData = tableRows;
    }

    this.setState({ filteredData: newFilteredData });
  };
  handleRowSelection = (row) => {
    console.log(row);
    const selectedRows = row.selectedRows;
    const selectedRowIds = selectedRows.map((row) => row.id);
    this.setState(
      (prevState) => ({
        ...prevState,
        selectedRows: selectedRowIds,
      }),
      () => {
        console.log("selectedrows", this.state.selectedRows);
      }
    );
  };
  toggleBrowserFilter = () => {
    this.setState((prevState) => ({
      showBrowserFilter: !prevState.showBrowserFilter,
    }));
  };

  toggleOSFilter = () => {
    this.setState((prevState) => ({ showOSFilter: !prevState.showOSFilter }));
  };
  handleAssignSubmit = () => {
    const { selectedAssignors, selectedRows } = this.state;
    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var raw = JSON.stringify({
      userId: Promises.getUserId(),
      email: selectedAssignors || this.state.projectUsers[0].value,
      orgId: Promises.getOrg(),
      subtaskResultIds: selectedRows,
      status: true,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log(requestOptions);

    fetch(`${BASE_URL}result/assign/${this.state.projectId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.code === 200) {
          this.setState({ selectedRows: [], toggledClearRows: true });
          NotificationManager.success(result.msg, "Assigned");

          this.getTableRow();
        } else {
          NotificationManager.error(result.msg);
        }
      })
      .catch((error) => {
        console.log("error", error);
        NotificationManager.error(
          "Something went wrong with saving your data, This may happen due to internal server error, Please try Again"
        );
      });
    this.setState({ toggledClearRows: false });
  };
  handleChange1 = (value) => {
    this.setState({ selectedAssignors: value});
    console.log(`Option selected:`, this.state.selectedAssignors);
  };
  handleInputChangeSelect(inputValue) {
    if (inputValue.length > 0) {
      this.setState({ menuIsOpen: true }, () => console.log(inputValue));
    } else {
      this.setState({ menuIsOpen: false });
    }
  }
  handleAssignClick = () => {
    if (this.state.selectedRows?.length > 0) {
      this.setState({selectedAssignor:this.state.projectUsers[0].value })
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="delete-project-modal shadow-lg !w-[515px] !px-12 !py-12 bg-white rounded-md">
              <h3 className="font-raleway font-[700] text-[22px] mb-6">
                Assign Test Cases to User
              </h3>

              <div className="form-group">
                <label>User name:</label>
                <select
                  aria-label="!border !rounded-md"
                  style={{ fontSize: "14px" }}
                  
                  defaultValue={"Default"}
                  className="px-2 pr-8 custom-select !h-[60px]"
                  onChange={(e) =>
                    this.handleChange1( e.target.value )
                  }
                  placeholder={"Assign User"}
                >
                  {this.state.projectUsers.map((user) => (
                    <option value={user.value}>{user.label}</option>
                  ))}
                  {/* <option value="Default">Default</option>
                  <option value="windows">Windowns</option>
                  <option value="mac">Mac</option>
                  <option value="other">Other</option> */}
                </select>
              </div>

              <div className="modal-buttons flex flex-row-reverse items-center gap-x-8 ">
                <button
                  className="btn primary_btn flex-grow delete-project-yes"
                  onClick={() => {
                    this.handleAssignSubmit();
                    onClose();
                  }}
                >
                  Assign
                </button>
                <button
                  className="rounded-md   !h-11 flex items-center flex-grow- justify-center text-[#64748B]"
                  style={{
                    boxShadow: " 0px 1px 2px 0px #1018280D",
                    fontSize: 14,
                    fontWeight: 500,
                    borderRadius: 8,
                    width: 140,
                    background: "#F0F0F0",
                    color: "#64748B",
                  }}
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          );
        },
      });
    } else {
      NotificationManager.warning("Select Test-Cases to Assign");
    }
  };
  handleSearchChange = (e) => {
    const searchTotal = e.target.value.toLowerCase();

    this.setState({ searchTotal });

    const serachData = this.state.tableRows.filter((item) =>
      item.testcases.toLowerCase().includes(searchTotal)
    );

    this.setState({ filteredData: serachData });
  };
  bulkUpdateBrowser = () => {
    if (this.state.selectedRows?.length > 0) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="delete-project-modal shadow-lg !w-[515px] !px-12 !py-12 bg-white rounded-md">
              <h3 className="font-raleway font-[700] text-[22px] mb-6">
                Bulk Update Browser
              </h3>

              <div className="form-group">
                <label>Select Browser:</label>
                <select
                  aria-label="!border !rounded-md"
                  style={{ fontSize: "14px" }}
                  defaultValue={"Default"}
                  className="px-2 pr-8 custom-select !h-[60px]"
                  onChange={(e) =>
                    this.setState({ bulkBrowser: e.target.value }, () =>
                      console.log(this.state.bulkBrowser)
                    )
                  }
                >
                  <option value="Default">Default</option>
                  <option value="chrome">Chrome</option>
                  <option value="firefox">Firefox</option>
                  <option value="other">Other</option>
                </select>
              </div>

              <div className="modal-buttons flex flex-row-reverse items-center gap-x-8 ">
                <button
                  className="btn primary_btn flex-grow delete-project-yes"
                  onClick={() => {
                    this.handDetailChange("browser");
                    onClose();
                  }}
                >
                  Update
                </button>
                <button
                  className="rounded-md   !h-11 flex items-center flex-grow- justify-center text-[#64748B]"
                  style={{
                    boxShadow: " 0px 1px 2px 0px #1018280D",
                    fontSize: 14,
                    fontWeight: 500,
                    borderRadius: 8,
                    width: 140,
                    background: "#F0F0F0",
                    color: "#64748B",
                  }}
                  onClick={() => {
                    this.setState({ bulkBrowser: "" });
                    onClose();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          );
        },
      });
    } else {
      NotificationManager.warning("There is no Test-Cases Selected.");
    }
  };
  bulkUpdateOS = () => {
    if (this.state.selectedRows?.length > 0) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="delete-project-modal shadow-lg !w-[515px] !px-12 !py-12 bg-white rounded-md">
              <h3 className="font-raleway font-[700] text-[22px] mb-6">
                Bulk Update Operating System
              </h3>

              <div className="form-group">
                <label>Select OS:</label>
                <select
                  aria-label="!border !rounded-md"
                  style={{ fontSize: "14px" }}
                  defaultValue={"Default"}
                  className="px-2 pr-8 custom-select !h-[60px]"
                  onChange={(e) =>
                    this.setState({ bulkOs: e.target.value }, () =>
                      console.log(this.state.bulkOs)
                    )
                  }
                >
                  <option value="Default">Default</option>
                  <option value="windows">Windowns</option>
                  <option value="mac">Mac</option>
                  <option value="other">Other</option>
                </select>
              </div>

              <div className="modal-buttons flex flex-row-reverse items-center gap-x-8 ">
                <button
                  className="btn primary_btn flex-grow delete-project-yes"
                  onClick={() => {
                    this.handDetailChange("os");
                    onClose();
                  }}
                >
                  Update
                </button>
                <button
                  className="rounded-md   !h-11 flex items-center flex-grow- justify-center text-[#64748B]"
                  style={{
                    boxShadow: " 0px 1px 2px 0px #1018280D",
                    fontSize: 14,
                    fontWeight: 500,
                    borderRadius: 8,
                    width: 140,
                    background: "#F0F0F0",
                    color: "#64748B",
                  }}
                  onClick={() => {
                    this.setState({ bulkOs: "" });
                    onClose();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          );
        },
      });
    } else {
      NotificationManager.warning("There is no Test-Cases Selected.");
    }
  };
  bulkUpdateExecution = () => {
    if (this.state.selectedRows?.length > 0) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="delete-project-modal shadow-lg !w-[515px] !px-12 !py-12 bg-white rounded-md">
              <h3 className="font-raleway font-[700] text-[22px] mb-6">
                Bulk Update Execution Type
              </h3>

              <div className="form-group">
                <label>Select Execution Type:</label>
                <select
                  aria-label="!border !rounded-md"
                  style={{ fontSize: "14px" }}
                  defaultValue={"Default"}
                  className="px-2 pr-8 custom-select !h-[60px]"
                  onChange={(e) =>
                    this.setState({ bulkExecutiontype: e.target.value }, () =>
                      console.log(this.state.bulkExecutiontype)
                    )
                  }
                >
                  <option value="manual">Manual</option>
                  <option value="automated">Automated</option>
                </select>
              </div>

              <div className="modal-buttons flex flex-row-reverse items-center gap-x-8 ">
                <button
                  className="btn primary_btn flex-grow delete-project-yes"
                  onClick={() => {
                    this.handDetailChange("execution");
                    onClose();
                  }}
                >
                  Update
                </button>
                <button
                  className="rounded-md   !h-11 flex items-center flex-grow- justify-center text-[#64748B]"
                  style={{
                    boxShadow: " 0px 1px 2px 0px #1018280D",
                    fontSize: 14,
                    fontWeight: 500,
                    borderRadius: 8,
                    width: 140,
                    background: "#F0F0F0",
                    color: "#64748B",
                  }}
                  onClick={() => {
                    this.setState({ bulkExecutiontype: "" });
                    onClose();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          );
        },
      });
    } else {
      NotificationManager.warning("There is no Test-Cases Selected.");
    }
  };
  render() {
    const {
      search,
      searchTotal,
      pending,
      showTotalFilter,
      testCasesData,
      curruntTestName,
      curruntTestrunId,
      isTestRunCreated,
      loading,
      dynamicStyle,
      name,
      description,
      showCreateTestRun,
      TestRundateAndTime,
      createCustomModalVisible,
      createCustomListModalVisible,
      createModalVisible,
      customtestCasesData,
      issues,
      intType,
      selectedOptIntegration,
      latest_key,
      filterProps,
      tableRows,
      filteredData,
      selectedRows,
      showBrowserFilter,
      showOSFilter,
      filterOSProps,
      filterBrowserProps,
    } = this.state;
    const showDetailsIcon = "showDetailsIcon";
    const customStyles = {
      table: {
        style: {
          tableLayout: "fixed",
          border: "1px solid #D9D9D9",
          borderRadius: "8px",
        },
      },
      headCells: {
        style: {
          fontWeight: "400",
          fontSize: "12px",

          textAlign: "center",
          color: "#4F5B67",

          backgroundColor: "#F7F7F7",
        },
      },
      cells: {
        style: {
          fontWeight: "400",
          fontSize: "16px",

          textAlign: "center",
          color: "#000000",
        },
      },

      rows: {
        style: {
          "&:last-child td": {
            borderBottom: "none",
          },
        },
      },
      noData: {
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    };
    const columns = [
      {
        name: <span className="input-label flex-grow">Test Case</span>,
        selector: (row) => row.testcases,
        // width: "212px",
        left: true,
        sortable: true,
        conditionalCellStyles: [
          {
            when: (row) => true,
            style: { fontSize: "14px", fontWeight: 400, flex: "grow" },
          },
        ],
      },
      {
        name: <span className="input-label">Assignee</span>,
        selector: (row) => row.assignee,
        width: "212px",
        center: true,
        sortable: true,
        conditionalCellStyles: [
          {
            when: (row) => row.assignee === "unassigned",
            style: {
              color: "#999797",
              textTransform: "capitalize",
              fontSize: "14px",
              fontWeight: 400,
            },
          },
        ],
      },
      {
        name: <span className="input-label">Assignor</span>,
        selector: (row) => row.assignor,
        width: "212px",
        center: true,
        sortable: true,
        conditionalCellStyles: [
          {
            when: (row) => row.assignor === "unassigned",
            style: {
              color: "#999797",
              textTransform: "capitalize",
              fontSize: "14px",
              fontWeight: 400,
            },
          },
        ],
      },
      {
        name: <span className="input-label">Browser</span>,
        selector: (row) => row.browser,
        width: "212px",
        center: true,
        button: true,
        conditionalCellStyles: [
          {
            when: (row) => true,
            style: { fontSize: "14px", fontWeight: 400 },
          },
        ],
        // cell: (row) => (
        //   <DropdownComponent
        //     onSelectChange={(selectedOption) =>
        //       this.handleBrowserChange(row.id, selectedOption)
        //     }
        //     options={[
        //       { value: "Default", label: "Default " },
        //       { value: "chrome", label: "Chrome " },

        //       { value: "firefox", label: "Firefox " },
        //       { value: "other", label: "Other " },
        //     ]}
        //     currentValue={row.browser}
        //   />
        // ),
      },
      {
        name: <span className="input-label">OS</span>,
        selector: (row) => row.os,
        width: "212px",
        center: true,
        button: true,
        conditionalCellStyles: [
          {
            when: (row) => true,
            style: { fontSize: "14px", fontWeight: 400 },
          },
        ],
        // cell: (row) => (
        //   <DropdownComponent
        //     onSelectChange={(selectedOption) =>
        //       this.handleBrowserChange(row.id, selectedOption)
        //     }
        //     style={{
        //       fontSize: "14px",
        //       fontWeight: 400,
        //       textTransform: "capitalize",
        //     }}
        //     options={[
        //       { value: "Default", label: "Default " },
        //       { value: "windows", label: "Windows " },
        //       { value: "mac", label: "Mac " },
        //       { value: "other", label: "Other " },
        //     ]}
        //     currentValue={row.os}
        //   />
        // ),
      },
      {
        name: <span className="input-label">Execution Type</span>,
        selector: (row) => row.executiontype,
        width: "212px",
        center: true,
        sortable: true,
        conditionalCellStyles: [
          {
            when: (row) => true,
            style: {
              fontSize: "14px",
              fontWeight: 400,
              textTransform: "capitalize",
            },
          },
        ],
      },
    ];
    const DropdownComponent = ({ onSelectChange, options, currentValue }) => {
      // Convert value to the format expected by react-select

      return (
        <div style={{ position: "absolute", zIndex: 1 }}>
          <select
            className="select-option !text-[14px] !font-[400] !capitalize"
            aria-label="Default select example"
            style={{
              border: "none",
            }}
            defaultValue={currentValue}
            onChange={onSelectChange}
            options={options}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      );
    };

    return (
      <>
        <div className="tab-content">
          <ul
            className="nav nav-tabs nav-tabs-custom ml-3 !border-none !mb-0 mt-4 bg-[#F2F2F2] w-[450px] rounded-t-[8px]"
            role="tablist"
          >
            <li className="nav-item gap-x-1 ">
              <a
                className="nav-link active rounded-t-[8px] overflow-clip flex items-center justify-center !bg-red-400"
                data-toggle="tab"
                href="#mytestcases"
                role="tab"
                onClick={() => {
                  this.setState({ issuesVisisble: false });
                  this.getIntegrationData();
                  this.getTestResult();
                }}
              >
                <span className="d-none d-md-block">My TestCases</span>
                {/* <span className="d-block d-md-none">
                  <i className="mdi mdi-email h5"></i>
                </span> */}
              </a>
            </li>
            <li className="nav-item flex items-center justify-center">
              <a
                className="nav-link rounded-t-[8px] overflow-clip flex items-center justify-center"
                data-toggle="tab"
                href="#alltestcases"
                role="tab"
                onClick={() => {
                  this.setState({ issuesVisisble: false });
                  
                  this.getTableRow();
                }}
              >
                <span className="d-none d-md-block flex items-center justify-center">
                  All TestCases
                </span>
                {/* <span className="d-block d-md-none">
                  <i className="mdi mdi-account h5"></i>
                </span> */}
              </a>
            </li>
            <li className="nav-item flex items-center justify-center">
              <a
                className="nav-link rounded-t-[8px] overflow-clip flex items-center justify-center cursor-pointer"
                onClick={() => this.setState({ issuesVisisble: true })}
                data-toggle="tab"
                href="#issues"
                role="tab"
              >
                <span className="d-none d-md-block flex items-center justify-center">
                  Issues
                </span>
                {/* <span className="d-block d-md-none">
                  <i className="mdi mdi-account h5"></i>
                </span> */}
              </a>
            </li>
          </ul>
          
                <div className=" tab-pane mx-3 !mt-0 "
                id="issues"
                
            role="tabpanel"
            style={{border: '1px solid #eeeeee',
            borderRadius: 5,
            overflowY: "auto",
            overflowX: "hidden",
            height: "calc(100vh - 185px)"}
            }
            >
           
            
              <div className="mx-3 !mt-0" >
                  <IssuesTab projectId={this.props.projectId} />
                  </div>
                
              
              </div>
          <div
            className="tab-pane mx-3 !mt-0 bg-white relative"
            id="alltestcases"
            role="tabpanel"
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              height: "calc(100vh - 185px)",
              border: '1px solid #eeeeee',
              borderRadius: 5,
            }}
          >
            {!showCreateTestRun &&
              !createCustomModalVisible &&
              !createCustomListModalVisible && (
                <MainActionPopup
                  label={"Create Test Run"}
                  inputFieldLabel="Test Run Name"
                  inputFieldPlaceholder={
                    TestRundateAndTime || "Enter Test Run Name..."
                  }
                  inputFieldText={name}
                  onInputFieldChange={this.handleChangeName}
                  textAreaLabel={"Test Run Description"}
            textAreaPlaceholder={"Enter description for your test run.."}
            onTextareaChange={this.handleChangeDesc
            }
            textareaText={description}
            textareaMaxLength={200}
                  height={483}
                  width={515}
                  secondaryIsAbort={false}
                  secondaryText={"Customize"}
                  onSecondary={() => {
                    if(this.state.testCasesEmpty){
                      NotificationManager.error("No test cases found, to Create test Run");
                      return;
                    }
                    this.setState(
                      {
                        createModalVisible: false,
                        createCustomListModalVisible: true,
                      },
                      () => {
                        console.log("Call13");
                        this.getCustomTestCasesData("inital");
                      }
                    );
                  }}
                  successTick={true}
                  successText={" Create "}
                  onSuccess={this.onCreateRun}
                  bgOpacity={0.2}
                />
              )}

            {!showCreateTestRun && createCustomModalVisible && (
              <div className="create-modal custom-test-modal-name test-run-model">
                {/* <div className="test-run-modal-title">
              <h3>Create Customize Test Run</h3>
              <i
                className="mdi mdi-close"
                onClick={() =>
                  this.setState({ createCustomModalVisible: false })
                }
              ></i>
            </div> */}
                <div>
                  <label
                    id="label"
                    htmlFor="description"
                    style={{ marginTop: 15 }}
                  >
                    Test Run Name
                  </label>
                  <textarea
                    className="form-field-input test-run-desc"
                    defaultValue={TestRundateAndTime}
                    value={description}
                    onChange={this.handleChangeDesc}
                  ></textarea>
                </div>
                {this.state.isCheck.length > 0 ? (
                  <p
                    style={{
                      fontSize: 13,
                      letterSpacing: 0.5,
                      lineHeight: "18px",
                      marginTop: 24,
                    }}
                  >
                    <b>Note: </b>You’ve selected {this.state.isCheck.length}{" "}
                    Test cases to run, to go ahead click on Create.
                  </p>
                ) : (
                  <p
                    style={{
                      fontSize: 13,
                      letterSpacing: 0.5,
                      lineHeight: 18,
                      marginTop: 24,
                      color: "red",
                    }}
                  >
                    <b>Note: </b> Please Select Test Cases
                  </p>
                )}
                <div
                  className="d-flex align-items-center"
                  style={{ marginTop: 30 }}
                >
                  <button
                    className="btn btn-primary mr-3"
                    onClick={this.onCreateCustomRun}
                    // onClick={() => {
                    //   this.setState(
                    //     {
                    //       createCustomModalVisible: false,
                    //       createCustomListModalVisible: true,
                    //     },
                    //     () => {
                    //       console.log("Call");
                    //       this.getTestCasesData("inital");
                    //     }
                    //   );
                    // }}
                  >
                    Create
                  </button>
                  <button
                    className="btn btn-secondary test-run-cancel"
                    onClick={() =>
                      this.setState({
                        createCustomModalVisible: false,
                        isCheck: [],
                        isCheckParent: [],
                      })
                    }
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
            {!showCreateTestRun &&
              !createCustomModalVisible &&
              createCustomListModalVisible && (
                <div
                    className="create-modal select-custom-run-modal test-run-model "
                    style={{
                      height: "calc(100vh - 167px)",
                      position: "relative",
                    }}
                  >
                    <div className="test-run-modal-title">
                      <h3 className="primary_heading">
                        Select Testcases for Run
                      </h3>
                    </div>
                    <div
                      className="flex justify-between mb-3"
                      // style={{
                      //   position: "relative",
                      // }}
                    >
                      <div className="flex flex-row gap-x-3">
                        <div
                          className="flex flex-row items-center gap-0 !pr-2"
                          style={{
                            position: "relative",
                            background: "rgba(146, 198, 255, 0.1)",
                            border: "0.3px solid #316BFF",
                            borderRadius: 8,
                            height: 44,
                            fontSize: 12,
                            width: 220,
                          }}
                        >
                          <input
                            autoComplete="off"
                            type="text"
                            className="form-control-  !placeholder-[#316BFF] bg-transparent !border-none !py-0 !text-[14px] px-3"
                            placeholder="Search"
                            value={search}
                            onChange={(e) =>
                              this.setState({ search: e.target.value }, () => {
                                this.handleAddFilterOrSearchOnCustomTestCases();
                              })
                            }
                          />

                          <IoSearchOutline size={22} color="#316BFF" />
                        </div>
                        <div className="relative">
                          <button
                            type="button"
                            onClick={() =>
                              this.setState({
                                showFilter: !this.state.showFilter,
                              })
                            }
                            style={{
                              position: "relative",
                              width: 110,
                              background: "rgba(146, 198, 255, 0.1)",
                              borderRadius: 8,
                            }}
                            className="px-3 border rounded-md !flex !flex-row !items-center !justify-center gap-x-3 !text-[#316BFF] !border-[#316BFF]  text-[12px] !font-[300] !h-[44px]"
                          >
                            <svg
                              width="11"
                              height="10"
                              viewBox="0 0 11 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.15 4.11744C7.56459 4.11737 7.9681 3.97419 8.30054 3.70918C8.63299 3.44417 8.87663 3.07149 8.99525 2.64651H10.45C10.5959 2.64651 10.7358 2.58452 10.8389 2.47418C10.9421 2.36384 11 2.21418 11 2.05814C11 1.90209 10.9421 1.75244 10.8389 1.6421C10.7358 1.53175 10.5959 1.46977 10.45 1.46977H8.99525C8.87643 1.04501 8.63271 0.672578 8.30028 0.407795C7.96785 0.143012 7.56445 0 7.15 0C6.73555 0 6.33215 0.143012 5.99972 0.407795C5.66729 0.672578 5.42357 1.04501 5.30475 1.46977H0.55C0.404131 1.46977 0.264236 1.53175 0.161091 1.6421C0.0579462 1.75244 0 1.90209 0 2.05814C0 2.21418 0.0579462 2.36384 0.161091 2.47418C0.264236 2.58452 0.404131 2.64651 0.55 2.64651H5.30475C5.42337 3.07149 5.66701 3.44417 5.99946 3.70918C6.3319 3.97419 6.73541 4.11737 7.15 4.11744ZM0.55 7.35349C0.404131 7.35349 0.264236 7.41548 0.161091 7.52582C0.0579462 7.63616 0 7.78582 0 7.94186C0 8.09791 0.0579462 8.24756 0.161091 8.35791C0.264236 8.46825 0.404131 8.53023 0.55 8.53023H1.72975C1.84857 8.95499 2.09229 9.32742 2.42472 9.59221C2.75715 9.85699 3.16055 10 3.575 10C3.98945 10 4.39285 9.85699 4.72528 9.59221C5.05771 9.32742 5.30143 8.95499 5.42025 8.53023H10.45C10.5959 8.53023 10.7358 8.46825 10.8389 8.35791C10.9421 8.24756 11 8.09791 11 7.94186C11 7.78582 10.9421 7.63616 10.8389 7.52582C10.7358 7.41548 10.5959 7.35349 10.45 7.35349H5.42025C5.30143 6.92873 5.05771 6.5563 4.72528 6.29152C4.39285 6.02674 3.98945 5.88372 3.575 5.88372C3.16055 5.88372 2.75715 6.02674 2.42472 6.29152C2.09229 6.5563 1.84857 6.92873 1.72975 7.35349H0.55Z"
                                fill="#316BFF"
                              />
                            </svg>
                            Filter
                          </button>
                          <div
                              className="filter-dashboard"
                              style={{
                                display: this.state.showFilter
                                  ? "block"
                                  : "none",
                              }}
                              ref={this.wrapperRef}
                            >
                              <h5
                        style={{
                          fontSize: 16,
                          fontWeight: 600,
                          padding: 16,
                          borderBottom: "1px solid #ECECEC",
                        }}
                      >
                        Select filter
                      </h5>
                      <div class="filter-div-properties">
                        <div className="filter-properties !border-b-0">
                          <p
                            className="my-2"
                            style={{ fontSize: 14, opacity: 0.7 }}
                          >
                            Type
                          </p>
                          <div className="d-flex flex-column">
                            <label htmlFor="automatedFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="Automated"
                                checked={this.state.filterParameters.type.includes(
                                  "automated"
                                )}
                                id="automatedFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "type",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />
                              Automated
                            </label>

                            <label htmlFor="manualFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="Manual"
                                checked={this.state.filterParameters.type.includes(
                                  "manual"
                                )}
                                id="manualFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "type",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Manual
                            </label>
                          </div>
                        </div>
                        <div className="filter-properties">
                          <p>Priority</p>
                          <div className="d-flex flex-column">
                            <label htmlFor="highFilter">
                              <input
                                className="priorityFilter !w-3 !h-3"
                                type="checkbox"
                                value="High"
                                checked={this.state.filterParameters.priority.includes(
                                  "high"
                                )}
                                id="highFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "priority",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />
                              High
                            </label>
                            <label htmlFor="mediumFilter">
                              <input
                                className="priorityFilter !w-3 !h-3"
                                type="checkbox"
                                value="Medium"
                                checked={this.state.filterParameters.priority.includes(
                                  "medium"
                                )}
                                id="mediumFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "priority",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Medium
                            </label>
                            <label htmlFor="lowFilter">
                              <input
                                className="priorityFilter !w-3 !h-3"
                                type="checkbox"
                                value="Low"
                                checked={this.state.filterParameters.priority.includes(
                                  "low"
                                )}
                                id="lowFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "priority",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Low
                            </label>
                          </div>
                        </div>
                        <div className="filter-properties">
                          <p>Test Type</p>
                          <div className="d-flex flex-column">
                            <label htmlFor="functionalFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="Functional"
                                checked={this.state.filterParameters.testType.includes(
                                  "functional"
                                )}
                                id="functionalFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "testType",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />
                              Functional
                            </label>

                            <label htmlFor="unitFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="Unit"
                                checked={this.state.filterParameters.testType.includes(
                                  "unit"
                                )}
                                id="unitFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "testType",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Unit
                            </label>

                            <label htmlFor="securityFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="Security"
                                checked={this.state.filterParameters.testType.includes(
                                  "security"
                                )}
                                id="securityFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "testType",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Security
                            </label>

                            <label htmlFor="integrationFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="Integration"
                                checked={this.state.filterParameters.testType.includes(
                                  "integration"
                                )}
                                id="integrationFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "testType",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Integration
                            </label>

                            <label htmlFor="systemFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="System"
                                checked={this.state.filterParameters.testType.includes(
                                  "system"
                                )}
                                id="systemFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "testType",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              System
                            </label>

                            <label htmlFor="acceptanceFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="Acceptance"
                                id="acceptanceFilter"
                                checked={this.state.filterParameters.testType.includes(
                                  "acceptance"
                                )}
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "testType",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Acceptance
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-evenly !mt-[16px]">
                      <button
                        type="button"
                        class="filter-btn"
                        onClick={() =>
                          this.setState({
                            filterProps: [],
                            filterParameters: {
                              type: [],
                              priority: [],
                              testType: [],
                            },
                          })
                        }
                      >
                        Clear
                      </button>
                      <button 
                      type="button"
                      onClick={() =>
                        this.setState({ showFilter: !this.state.showFilter })
                      }
                      className="rounded-lg !h-[40px] flex flex-row !w-[80px] !items-center justify-center gap-x-2  px-[16px] py-[8px] !bg-[#E7EAEE] !text-[14px] font-poppins text-[#636463] !font-[600] ">
                        Cancel
                      </button>
                      </div>
                            </div>
                        </div>
                      </div>

                      <div
                        className="flex flex-row cursor-pointer items-center gap-x-2 !text-[14px]"
                        style={{
                          position: "relative",
                        }}
                      >
                        <button
                          className="px-8 gap-x-3 flex items-center justify-center bg-[#E7EAEE] rounded-[8px] font-[400] opacity-70 !h-[44px]"
                          onClick={() =>
                              this.setState({
                                createCustomListModalVisible: false,
                                isCheck: [],
                                filterProps: [],
                            filterParameters: {
                              type: [],
                              priority: [],
                              testType: [],
                            },
                              })
                            }
                        >
                          <svg
                            width="16"
                            height="12"
                            viewBox="0 0 16 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.72951 1.21171V3.78079L7.33545 3.78667C11.5443 3.82837 13.9146 5.74432 14.5326 9.62731C12.2208 6.88965 9.5549 6.88848 7.34176 6.88789H6.72951V9.43524L1.05809 5.32318L6.72951 1.21171ZM7.34176 0L0 5.32318L7.34176 10.6464V7.5146C10.2802 7.51577 13.0046 7.66731 15.3084 11.8728C15.3084 9.20206 14.8775 3.23514 7.34176 3.16055V0Z"
                              fill="#636463"
                            />
                          </svg>
                          Back
                        </button>
                        <button
                          className={`primary_btn px-8 gap-x-3 flex items-center justify-center ${
                            this.state.isCheck.length === 0 && this.state.isCheckParent?.length === 0
                              ? " opacity-70 "
                              : ""
                          }`}
                          disabled={this.state.isCheck.length === 0 && this.state.isCheckParent?.length === 0}
                        onClick={this.onCreateCustomRun}
                        >
                          Continue
                          <svg
                            width="15"
                            height="10"
                            viewBox="0 0 15 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14 5L1 5M14 5L10.75 8.5M14 5L10.75 1.5"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>

                    <div
                      style={{
                        height: "calc(100vh - 370px)",
                        marginBottom: 25,
                        overflowY: "auto",
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: "#EEEEEE",
                        borderRadius: 5,
                      }}
                    >
                   
                   {this.state.filteredCustomTestcasesData.length === 0 && (
                      <div className="" style={{ paddingTop: 100 }}>
                        <NotFound
                          subject={"Testcase"}
                          search={search}
                          actionNeeded={false}
                          clear={() => {
                            this.setState({
                              search: "",
                              showFilter: false,
                              filterProps: [],
                              filterParameters: {
                                type: [],
                                priority: [],
                                testType: [],
                              },
                            });
                          }}
                        />
                      </div>
                    )}
                      {this.state.filteredCustomTestcasesData.length > 0 &&
                        this.state.filteredCustomTestcasesData.map((ele, index) => {
                          if (ele.lists.length === 0) return null;
                          return (
                            <div className="tests">
                              <div
                                className="flex items-center"
                                style={{
                                  borderBottom: "0.5px solid #D1D5DB",
                                  marginBottom: 3,
                                  backgroundColor: ele.isChecked
                                    ? "rgb(232 244 255)"
                                    : "#F8F8F8",
                                }}
                              >
                                <div className="w-0.5 px-0.5 py-3 bg-primary-green" />

                                <div className="model-input  w-full !bg-transparent">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      flex: 1,
                                    }}
                                  >
                                    {ele.expanded ? (
                                      <MdOutlineArrowDropDown
                                        className="text-primary-green cursor-pointer"
                                        size={28}
                                        onClick={() => {
                                          ele.expanded = !ele.expanded;
                                          this.setState({
                                            testCasesData: testCasesData,
                                          });
                                        }}
                                      />
                                    ) : (
                                      <MdArrowRight
                                        className="text-black cursor-pointer"
                                        size={28}
                                        onClick={() => {
                                          ele.expanded = !ele.expanded;
                                          this.setState({
                                            testCasesData: testCasesData,
                                          });
                                        }}
                                      />
                                    )}

                                    <input
                                      autoComplete="off"
                                      type="text"
                                      placeholder="Add Task Title here"
                                      className="input1 w-full !bg-transparent"
                                      name="model"
                                      value={ele.model || ele.taskTitle}
                                      disabled={true}
                                    />

                                    <input
                                      type="checkbox"
                                      className="!w-4 !h-4 !m-0 !mr-8 cursor-pointer showTaskDeleteIcon"
                                      onChange={() =>
                                        this.onCustomizeParentCheckbox(
                                          ele,
                                          index
                                        )
                                      }
                                      checked={
                                        ele.isChecked &&
                                        this.state.isCheckParent.length !== 0
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              {/* Div Goes Here */}
                              {ele.isCollapse ||
                                ele.lists
                                  .filter((el) => {
                                    if (filterProps.length <= 0) {
                                      return (
                                        el.subTaskTitle === "" ||
                                        el.subTaskTitle === null ||
                                        el.subTaskTitle
                                          ?.toString()
                                          .toLowerCase()
                                          .includes(search.toLowerCase())
                                      );
                                    } else {
                                      return (
                                        (el.subTaskTitle === "" ||
                                          el.subTaskTitle === null ||
                                          el.subTaskTitle
                                            ?.toString()
                                            .toLowerCase()
                                            .includes(search.toLowerCase())) &&
                                        (filterProps.includes(
                                          el.testing?.toString().toLowerCase()
                                        ) ||
                                          filterProps.includes(
                                            el.priority
                                              ? el.priority
                                                  ?.toString()
                                                  .toLowerCase()
                                              : null
                                          ) ||
                                          filterProps.some((item) =>
                                            el.test_type
                                              ? el.test_type.includes(item)
                                              : null
                                          ))
                                      );
                                    }
                                  })

                                  .map((item, i, row) => {
                                    return (
                                      ele.expanded &&
                                      item.subTaskTitle && (
                                        <div
                                          className={`model-list qq pl-[1.5rem] pr-4 ${
                                            item.subTaskTitle
                                              ? ""
                                              : " !bg-white"
                                          }`}
                                          // onMouseEnter={this.mouseEnter.bind(this)}
                                          // onMouseLeave={this.mouseLeave.bind(this)}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              flex: 1,
                                            }}
                                            className={`model-list-testsuite `}
                                          >
                                            <input
                                              type="checkbox"
                                              className="!w-4 !h-4 !m-0 !mr-4 cursor-pointer"
                                              onChange={() =>
                                                this.onCustomizeChildCheckbox(
                                                  item.subtaskId,
                                                  item,
                                                  index,
                                                  i
                                                )
                                              }
                                              checked={item.isChildChecked}
                                            />
                                            <input
                                              type="text"
                                              autoComplete="off"
                                              placeholder="Add new Test case here"
                                              className={`list-input !placeholder-primary-green ${
                                                item.classNameDependsOnCondtion
                                              } ${
                                                item.subTaskTitle
                                                  ? " bg-transparent"
                                                  : ""
                                              }`}
                                              name="list"
                                              value={
                                                item.subTaskTitle !== null
                                                  ? item.subTaskTitle
                                                  : ""
                                              }
                                              disabled={true}
                                            />
                                          </div>
                                        </div>
                                      )
                                    );
                                  })}
                            </div>
                          );
                        })}
                    </div>
                 
                  </div>
                )}
            {showCreateTestRun && !this.state.issuesVisisble && (
              <>
              
                <div className="row">
                  <div className="card-body w-full h-full" style={{ width: "100%" }}>
                    <style>{`
    
                
                input{
                  width:40%;
                }
              `}</style>
              
<div style={{
                    position: "sticky",
                    top: 0,
                    right: 0,
                    left: 0,
                    backgroundColor: "#FFFFFF",
                    paddingTop:20,
                    zIndex: 99,
                    borderBottom: '1px solid #eeeeee',
                  }}>
                    <div className="!mt-[10px]">
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      
                      <div className="flex flex-row items-center justify-between w-full mb-3">
                        <div className="flex flex-row items-center gap-x-3">
                          <div
                            className="flex items-center !p-0 !m-0"
                            style={{
                              position: "relative",
                            }}
                          >
                            <input
                              autoComplete="off"
                              type="text"
                              className="form-control !h-[42px] !mt-0 !placeholder-[#316BFF]"
                              placeholder="Search"
                              value={searchTotal}
                              onChange={(e) =>
                                this.setState({ searchTotal: e.target.value }, () => {
                                  this.handleAddFilterOrSearchOnTestCases2();
                                })
                              }
                              style={{
                                border: "0.3px solid #316BFF",
                                borderRadius: 8,
                                height: 44,
                                fontSize: 12,
                                width: 260,
                                background: "rgba(146, 198, 255, 0.1)",
                              }}
                            />
                            <span
                              style={{
                                position: "absolute",
                                color: "#316BFF",
                                top: 12.5,
                                right: 12,
                              }}
                            >
                              <IoSearchOutline />
                            </span>
                          </div>
                          <div className="action-options d-inline-block">
                            <button
                              className="flex flex-row gap-x-3 items-center !h-[42px] border-primary-green text-primary-green action-btn hover:!border-gray-300"
                              onClick={this.handleAssignClick}
                            >
                              {" "}
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_80_7013)">
                                  <path
                                    d="M9.99542 6.63146C11.667 6.63146 13.0221 5.27637 13.0221 3.60479C13.0221 1.93321 11.667 0.578125 9.99542 0.578125C8.32383 0.578125 6.96875 1.93321 6.96875 3.60479C6.96875 5.27637 8.32383 6.63146 9.99542 6.63146Z"
                                    fill="#00CD70"
                                  />
                                  <path
                                    d="M7.33327 10.8445C7.34251 10.5986 7.42444 10.3609 7.56872 10.1616C7.71299 9.96222 7.91313 9.81013 8.14384 9.7245C8.37454 9.63888 8.62545 9.62358 8.86485 9.68053C9.10425 9.73749 9.32139 9.86414 9.48882 10.0445L11.2044 11.8889L14.2444 8.49781C12.9886 7.66096 11.5089 7.22401 9.99994 7.24448C8.95321 7.21801 7.9134 7.42116 6.9536 7.83963C5.9938 8.2581 5.13732 8.88174 4.44438 9.6667C4.38576 9.74485 4.35452 9.84013 4.35549 9.93781V12.3334C4.35539 12.5646 4.44537 12.7867 4.60634 12.9527C4.76732 13.1186 4.98662 13.2153 5.21771 13.2223H9.0266L7.66216 11.7556C7.54942 11.6333 7.46226 11.4897 7.40578 11.3332C7.3493 11.1768 7.32465 11.0106 7.33327 10.8445Z"
                                    fill="#00CD70"
                                  />
                                  <path
                                    d="M14.782 13.2221C15.0131 13.2152 15.2324 13.1185 15.3934 12.9525C15.5544 12.7866 15.6444 12.5644 15.6443 12.3332V10.6621L13.3643 13.2221H14.782Z"
                                    fill="#00CD70"
                                  />
                                  <path
                                    d="M17.0088 7.27484C16.9652 7.23543 16.9142 7.20506 16.8588 7.18549C16.8033 7.16593 16.7446 7.15755 16.6859 7.16086C16.6272 7.16417 16.5697 7.17909 16.5168 7.20476C16.4639 7.23043 16.4166 7.26633 16.3777 7.3104L11.2133 13.0882L8.90218 10.6037C8.86424 10.5594 8.81794 10.5229 8.76591 10.4965C8.71389 10.47 8.65716 10.454 8.59897 10.4495C8.54078 10.445 8.48226 10.4519 8.42676 10.47C8.37126 10.4881 8.31987 10.5169 8.27551 10.5548C8.23385 10.5962 8.20079 10.6453 8.17822 10.6995C8.15566 10.7536 8.14404 10.8117 8.14404 10.8704C8.14404 10.9291 8.15566 10.9872 8.17822 11.0413C8.20079 11.0955 8.23385 11.1446 8.27551 11.186L11.2444 14.386L17.04 7.88818C17.1147 7.80158 17.1532 7.68951 17.1474 7.57527C17.1416 7.46104 17.092 7.35343 17.0088 7.27484Z"
                                    fill="#00CD70"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_80_7013">
                                    <rect width="16" height="16" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                              Assign
                            </button>
                          </div>
                        </div>
                        <div className="flex flex-row gap-x-2 itmes-center justify-center"><div className="">
                            <Dropdown
                              isOpen={this.state.dropdownOpenprofile2}
                              toggle={this.toggleprofile2}
                            >
                              <DropdownToggle
                                className="!text-primary-description !border-primary-gray flex items-center gap-x-2 hover:!border-primary-gray hover:!text-primary-description !h-[42px] "
                                style={{ borderRadius: 8 }}
                              >
                                <button className="flex flex-row items-center">
                                  <svg
                                    width="25"
                                    height="25"
                                    viewBox="0 0 25 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7.43701 10.4166L12.5595 15.625L17.6821 10.4166H7.43701Z"
                                      fill="#00CD70"
                                    />
                                  </svg>
                                  Bulk Update
                                </button>
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={this.bulkUpdateBrowser}>
                                  Change Browser
                                </DropdownItem>
                                <DropdownItem onClick={this.bulkUpdateOS}>
                                  Change OS
                                </DropdownItem>
                                <DropdownItem
                                  onClick={this.bulkUpdateExecution}
                                >
                                  Change Exexcution Type
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>

                          <div
                            style={{
                              position: "relative",
                            }}
                          >
                            <button
                              type="button"
                              ref={this.wrapperRef}
                              onClick={() =>
                                this.setState({
                                  showFilter: !this.state.showFilter,
                                })
                              }
                              style={{
                                position: "relative",
                                width: 110,
                                background: "rgba(146, 198, 255, 0.1)",
                                borderRadius: 8,
                              }}
                              className="px-3 border rounded-md !flex !flex-row !items-center !justify-center gap-x-3 !text-[#316BFF] !border-[#316BFF] h-full text-[12px] !font-[300]"
                            >
                              <svg
                                width="11"
                                height="10"
                                viewBox="0 0 11 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M7.15 4.11744C7.56459 4.11737 7.9681 3.97419 8.30054 3.70918C8.63299 3.44417 8.87663 3.07149 8.99525 2.64651H10.45C10.5959 2.64651 10.7358 2.58452 10.8389 2.47418C10.9421 2.36384 11 2.21418 11 2.05814C11 1.90209 10.9421 1.75244 10.8389 1.6421C10.7358 1.53175 10.5959 1.46977 10.45 1.46977H8.99525C8.87643 1.04501 8.63271 0.672578 8.30028 0.407795C7.96785 0.143012 7.56445 0 7.15 0C6.73555 0 6.33215 0.143012 5.99972 0.407795C5.66729 0.672578 5.42357 1.04501 5.30475 1.46977H0.55C0.404131 1.46977 0.264236 1.53175 0.161091 1.6421C0.0579462 1.75244 0 1.90209 0 2.05814C0 2.21418 0.0579462 2.36384 0.161091 2.47418C0.264236 2.58452 0.404131 2.64651 0.55 2.64651H5.30475C5.42337 3.07149 5.66701 3.44417 5.99946 3.70918C6.3319 3.97419 6.73541 4.11737 7.15 4.11744ZM0.55 7.35349C0.404131 7.35349 0.264236 7.41548 0.161091 7.52582C0.0579462 7.63616 0 7.78582 0 7.94186C0 8.09791 0.0579462 8.24756 0.161091 8.35791C0.264236 8.46825 0.404131 8.53023 0.55 8.53023H1.72975C1.84857 8.95499 2.09229 9.32742 2.42472 9.59221C2.75715 9.85699 3.16055 10 3.575 10C3.98945 10 4.39285 9.85699 4.72528 9.59221C5.05771 9.32742 5.30143 8.95499 5.42025 8.53023H10.45C10.5959 8.53023 10.7358 8.46825 10.8389 8.35791C10.9421 8.24756 11 8.09791 11 7.94186C11 7.78582 10.9421 7.63616 10.8389 7.52582C10.7358 7.41548 10.5959 7.35349 10.45 7.35349H5.42025C5.30143 6.92873 5.05771 6.5563 4.72528 6.29152C4.39285 6.02674 3.98945 5.88372 3.575 5.88372C3.16055 5.88372 2.75715 6.02674 2.42472 6.29152C2.09229 6.5563 1.84857 6.92873 1.72975 7.35349H0.55Z"
                                  fill="#316BFF"
                                />
                              </svg>
                              Filter
                            </button>
                            <div
                              className="filter-dashboard"
                              style={{
                                display: this.state.showFilter
                                  ? "block"
                                  : "none",
                              }}
                            >
                              <h5
                        style={{
                          fontSize: 16,
                          fontWeight: 600,
                          padding: 16,
                          borderBottom: "1px solid #ECECEC",
                        }}
                      >
                        Select filter
                      </h5>
                      <div class="filter-div-properties">
                        <div className="filter-properties !border-b-0">
                          <p
                            className="my-2"
                            style={{ fontSize: 14, opacity: 0.7 }}
                          >
                            Execution Type
                          </p>
                          <div className="d-flex flex-column">
                            <label htmlFor="automatedFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="automated"
                                checked={this.state.filterParameters2.type.includes(
                                  "automated"
                                )}
                                id="automatedFilter"
                                onChange={(e) => {
                                  
                                  this.toggleFilterParameters2(
                                    "type",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />
                              Automated
                            </label>

                            <label htmlFor="manualFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="manual"
                                checked={this.state.filterParameters2.type.includes(
                                  "manual"
                                )}
                                id="manualFilter"
                                onChange={(e) => {
                                  
                                  this.toggleFilterParameters2(
                                    "type",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Manual
                            </label>
                          </div>
                        </div>
                        <div className="filter-properties">
                          <p>Os</p>
                          <div className="d-flex flex-column">
                            <label htmlFor="macFilter">
                              <input
                                className="priorityFilter !w-3 !h-3"
                                type="checkbox"
                                value="mac"
                                checked={this.state.filterParameters2.os.includes(
                                  "mac"
                                )}
                                id="macFilter"
                                onChange={(e) => {
                                  
                                  this.toggleFilterParameters2(
                                    "os",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />
                              Mac
                            </label>
                            <label htmlFor="windowsFilter">
                              <input
                                className="priorityFilter !w-3 !h-3"
                                type="checkbox"
                                value="windows"
                                checked={this.state.filterParameters2.os.includes(
                                  "windows"
                                )}
                                id="windowsFilter"
                                onChange={(e) => {
                                  
                                  this.toggleFilterParameters2(
                                    "os",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Windows
                            </label>
                            <label htmlFor="otherFilter">
                              <input
                                className="priorityFilter !w-3 !h-3"
                                type="checkbox"
                                value="other"
                                checked={this.state.filterParameters2.os.includes(
                                  "other"
                                )}
                                id="otherFilter"
                                onChange={(e) => {
                                  
                                  this.toggleFilterParameters2(
                                    "os",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Other
                            </label>
                          </div>
                        </div>
                        <div className="filter-properties">
                          <p>Browser</p>
                          <div className="d-flex flex-column">
                            <label htmlFor="chromeFilter">
                              <input
                                className="priorityFilter !w-3 !h-3"
                                type="checkbox"
                                value="chrome"
                                checked={this.state.filterParameters2.browser.includes(
                                  "chrome"
                                )}
                                id="chromeFilter"
                                onChange={(e) => {
                                  
                                  this.toggleFilterParameters2(
                                    "browser",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />
                              Chrome
                            </label>
                            <label htmlFor="firefoxFilter">
                              <input
                                className="priorityFilter !w-3 !h-3"
                                type="checkbox"
                                value="firefox"
                                checked={this.state.filterParameters2.browser.includes(
                                  "firefox"
                                )}
                                id="firefoxFilter"
                                onChange={(e) => {
                                  
                                  this.toggleFilterParameters2(
                                    "browser",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Firefox
                            </label>
                            <label htmlFor="otherFilter">
                              <input
                                className="priorityFilter !w-3 !h-3"
                                type="checkbox"
                                value="other"
                                checked={this.state.filterParameters2.browser.includes(
                                  "other"
                                )}
                                id="otherFilter"
                                onChange={(e) => {
                                  
                                  this.toggleFilterParameters2(
                                    "browser",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Other
                            </label>
                          </div>
                        </div>
                        <div className="filter-properties ">
                          <p
                          >
                            Assign Status
                          </p>
                          <div className="d-flex flex-column">
                            <label htmlFor="assignedFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="assigned"
                                checked={this.state.filterParameters2.assignedStatus.includes(
                                  "assigned"
                                )}
                                id="assignedFilter"
                                onChange={(e) => {
                                  
                                  this.toggleFilterParameters2(
                                    "assignedStatus",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />
                              Assigned
                            </label>

                            <label htmlFor="unassignedFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="unassigned"
                                checked={this.state.filterParameters2.assignedStatus.includes(
                                  "unassigned"
                                )}
                                id="unassignedFilter"
                                onChange={(e) => {
                                  
                                  this.toggleFilterParameters2(
                                    "assignedStatus",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Un-assigned
                            </label>
                          </div>
                        </div> 
                      </div>
                      <div className="flex items-center justify-evenly !mt-[16px]">
                      <button
                        type="button"
                        class="filter-btn"
                        onClick={() =>
                          this.setState({
                            
                            filterParameters2: {
                              type: [],
                              os: [],
                              browser: [],
                              assignedStatus: []
                            },
                          })
                        }
                      >
                        Clear
                      </button>
                      <button 
                      type="button"
                      onClick={() =>
                        this.setState({ showFilter: !this.state.showFilter })
                      }
                      className="rounded-lg !h-[40px] flex flex-row !w-[80px] !items-center justify-center gap-x-2  px-[16px] py-[8px] !bg-[#E7EAEE] !text-[14px] font-poppins text-[#636463] !font-[600] ">
                        Cancel
                      </button>
                      </div>
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                    </div>
                    </div>
                    <div>
                    {pending && (
                <SkeletonTheme color="#f3f3f3" highlightColor="#f9f9f9">
                  <Skeleton count={5} height={100} />
                </SkeletonTheme>
              )}
                    {!pending && filteredData.length === 0 && (
                      <div className="" style={{ paddingTop: 100 }}>
                        <NotFound
                          subject={"Testcase"}
                          search={search}
                          actionNeeded={false}
                          clear={() => {
                            this.setState({
                              searchTotal: "",
                              showFilter: false,
                              filterParameters2: {
                                type: [],
                                os: [],
                                browser: [],
                                assignedStatus: []
                              },
                            });
                          }}
                        />
                      </div>
                    )}
                    {!pending && filteredData.length > 0 && (
                    <DataTable
                      data={filteredData}
                      columns={columns}
                      responsive
                      fixedHeader={true}
                      progressPending={pending}
                      pagination={true}
                      customStyles={customStyles}
                      highlightOnHover={true}
                      pointerOnHover={true}
                      selectableRows
                      onSelectedRowsChange={this.handleRowSelection}
                      clearSelectedRows={this.state.toggledClearRows}
                    />
                    )}
                    </div>
                  </div>
                </div>
                <div
                  className="filter-dashboard"
                  style={{
                    display: this.state.showBrowserFilter ? "block" : "none",
                    height: "215px",
                    width: "190px",
                    paddingTop: "15px",
                    paddingLeft: "7px",
                    right: "28%",
                    top: "50%",
                  }}
                  ref={this.wrapperRef}
                >
                  <div class="filter-div-properties">
                    <div className="filter-properties">
                      <div className="d-flex flex-column">
                        <label htmlFor="DefaultFilter">
                          <input
                            className="typeFilter"
                            type="checkbox"
                            value="Default"
                            //   checked={filterBrowserProps.includes("Default")
                            // }
                            id="DefaultFilter"
                            onChange={(e) =>
                              this.handleFilterBrowser(e.target.value)
                            }
                            // checked={ele.isChecked}
                            style={{
                              height: 16,
                              width: 16,
                              marginRight: 8,
                            }}
                          />
                          Default
                        </label>

                        <label htmlFor="ChromeFilter">
                          <input
                            className="typeFilter"
                            type="checkbox"
                            value="chrome"
                            //   checked={filterBrowserProps.includes("Chrome")
                            // }
                            id="ChromeFilter"
                            onChange={(e) =>
                              this.handleFilterBrowser(e.target.value)
                            }
                            // checked={ele.isChecked}
                            style={{
                              height: 16,
                              width: 16,
                              marginRight: 8,
                            }}
                          />
                          Chrome
                        </label>
                        <label htmlFor="FirefoxFilter">
                          <input
                            className="typeFilter"
                            type="checkbox"
                            value="firefox"
                            //   checked={filterBrowserProps.includes("Firefox")
                            // }
                            id="FirefoxFilter"
                            onChange={(e) =>
                              this.handleFilterBrowser(e.target.value)
                            }
                            // checked={ele.isChecked}
                            style={{
                              height: 16,
                              width: 16,
                              marginRight: 8,
                            }}
                          />
                          Firefox
                        </label>
                        <label htmlFor="OthersFilter">
                          <input
                            className="typeFilter"
                            type="checkbox"
                            value="other"
                            // checked={filterBrowserProps.includes("Others")
                            // }
                            id="OthersFilter"
                            onChange={(e) =>
                              this.handleFilterBrowser(e.target.value)
                            }
                            // checked={ele.isChecked}
                            style={{
                              height: 16,
                              width: 16,
                              marginRight: 8,
                            }}
                          />
                          Others
                        </label>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                      }}
                    >
                      <button
                        type="button"
                        class="btn btn-primary "
                        onClick={() => {
                          this.applyFiltersBrowser();
                          this.setState({
                            showBrowserFilter: !this.state.showBrowserFilter,
                          });
                        }}
                        style={{
                          fontweight: "500 ",
                          fontsize: "14px",
                          width: "64px",
                          height: "32px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Apply
                      </button>
                      <button
                        type="button"
                        class="btn"
                        onClick={() =>
                          this.setState({
                            showBrowserFilter: !this.state.showBrowserFilter,
                          })
                        }
                        style={{
                          fontweight: "500 ",
                          color: "#181818",
                          fontsize: "14px",
                          width: "64px",
                          height: "32px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="filter-dashboard"
                  style={{
                    display: this.state.showOSFilter ? "block" : "none",
                    height: "215px",
                    width: "190px",
                    paddingTop: "15px",
                    paddingLeft: "7px",
                    right: "13.5%",
                    top: "50%",
                  }}
                  ref={this.wrapperRef}
                >
                  <div class="filter-div-properties">
                    <div className="filter-properties">
                      <div className="d-flex flex-column">
                        <label htmlFor="DefaultFilter">
                          <input
                            className="typeFilter"
                            type="checkbox"
                            value="Default"
                            // checked={this.state.tableRows.includes(
                            //   "Default"
                            // )}
                            id="DefaultFilter"
                            onChange={(e) =>
                              this.handleFilterOS(e.target.value)
                            }
                            // checked={ele.isChecked}
                            style={{
                              height: 16,
                              width: 16,
                              marginRight: 8,
                            }}
                          />
                          Default
                        </label>

                        <label htmlFor="WindowsFilter">
                          <input
                            className="typeFilter"
                            type="checkbox"
                            value="windows"
                            // checked={this.state.tableRows.includes(
                            //   "Windows"
                            // )}
                            id="WindowsFilter"
                            onChange={(e) =>
                              this.handleFilterOS(e.target.value)
                            }
                            // checked={ele.isChecked}
                            style={{
                              height: 16,
                              width: 16,
                              marginRight: 8,
                            }}
                          />
                          Windows
                        </label>
                        <label htmlFor="MacFilter">
                          <input
                            className="typeFilter"
                            type="checkbox"
                            value="mac"
                            // checked={this.state.tableRows.includes(
                            //   "Mac"
                            // )}
                            id="MacFilter"
                            onChange={(e) =>
                              this.handleFilterOS(e.target.value)
                            }
                            // checked={ele.isChecked}
                            style={{
                              height: 16,
                              width: 16,
                              marginRight: 8,
                            }}
                          />
                          Mac
                        </label>
                        <label htmlFor="OthersFilter">
                          <input
                            className="typeFilter"
                            type="checkbox"
                            value="other"
                            id="ChromeFilter"
                            onChange={(e) =>
                              this.handleFilterOS(e.target.value)
                            }
                            // checked={ele.isChecked}
                            style={{
                              height: 16,
                              width: 16,
                              marginRight: 8,
                            }}
                          />
                          Others
                        </label>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                      }}
                    >
                      <button
                        type="button"
                        class="btn btn-primary "
                        onClick={() => {
                          this.applyFiltersOS();
                          this.setState({
                            showOSFilter: !this.state.showOSFilter,
                          });
                        }}
                        style={{
                          fontweight: "500 ",
                          fontsize: "14px",
                          width: "64px",
                          height: "32px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Apply
                      </button>
                      <button
                        type="button"
                        class="btn"
                        onClick={() =>
                          this.setState({
                            showOSFilter: !this.state.showOSFilter,
                          })
                        }
                        style={{
                          fontweight: "500 ",
                          color: "#181818",
                          fontsize: "14px",
                          width: "64px",
                          height: "32px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className="active tab-pane px-3 row"
            id="mytestcases"
            role="tab"
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              height: "calc(100vh - 185px)",
            }}
          >
            <div
              className="test-case-body test-run-body bg-white"
              // style={{ height: "calc(100vh - 133px)" }}
            >
              {loading && (
                <SkeletonTheme color="#f3f3f3" highlightColor="#f9f9f9">
                  <Skeleton count={5} height={100} />
                </SkeletonTheme>
              )}
              {!showCreateTestRun &&
                !createCustomModalVisible &&
                !createCustomListModalVisible && (
                  <MainActionPopup
                  label={"Create Test Run"}
                  inputFieldLabel="Test Run Name"
                  inputFieldPlaceholder={
                    TestRundateAndTime || "Enter Test Run Name..."
                  }
                  inputFieldText={name}
                  onInputFieldChange={this.handleChangeName}
                  textAreaLabel={"Test Run Description"}
            textAreaPlaceholder={"Enter description for your test run.."}
            onTextareaChange={this.handleChangeDesc
            }
            textareaText={description}
            textareaMaxLength={200}
                  height={483}
                  width={515}
                  secondaryIsAbort={false}
                  secondaryText={"Customize"}
                  onSecondary={() => {
                    if(this.state.testCasesEmpty){
                      NotificationManager.error("No test cases found, to Create test Run");
                      return;
                    }
                    this.setState(
                      {
                        createModalVisible: false,
                        createCustomListModalVisible: true,
                      },
                      () => {
                        console.log("Call13");
                        this.getCustomTestCasesData("inital");
                      }
                    );
                  }}
                  successTick={true}
                  successText={" Create "}
                  onSuccess={this.onCreateRun}
                  bgOpacity={0.2}
                />
                )}

              {!showCreateTestRun && createCustomModalVisible && (
                <div className="create-modal custom-test-modal-name test-run-model">
                  {/* <div className="test-run-modal-title">
              <h3>Create Customize Test Run</h3>
              <i
                className="mdi mdi-close"
                onClick={() =>
                  this.setState({ createCustomModalVisible: false })
                }
              ></i>
            </div> */}
                  <div>
                    <label
                      id="label"
                      htmlFor="description"
                      style={{ marginTop: 15 }}
                    >
                      Test Run Name
                    </label>
                    <textarea
                      className="form-field-input test-run-desc"
                      defaultValue={TestRundateAndTime}
                      value={description}
                      onChange={this.handleChangeDesc}
                    ></textarea>
                  </div>
                  {this.state.isCheck.length > 0 ? (
                    <p
                      style={{
                        fontSize: 13,
                        letterSpacing: 0.5,
                        lineHeight: "18px",
                        marginTop: 24,
                      }}
                    >
                      <b>Note: </b>You’ve selected {this.state.isCheck.length}{" "}
                      Test cases to run, to go ahead click on Create.
                    </p>
                  ) : (
                    <p
                      style={{
                        fontSize: 13,
                        letterSpacing: 0.5,
                        lineHeight: 18,
                        marginTop: 24,
                        color: "red",
                      }}
                    >
                      <b>Note: </b> Please Select Test Cases
                    </p>
                  )}
                  <div
                    className="d-flex align-items-center"
                    style={{ marginTop: 30 }}
                  >
                    <button
                      className="btn btn-primary mr-3"
                      onClick={this.onCreateCustomRun}
                      // onClick={() => {
                      //   this.setState(
                      //     {
                      //       createCustomModalVisible: false,
                      //       createCustomListModalVisible: true,
                      //     },
                      //     () => {
                      //       console.log("Call");
                      //       this.getTestCasesData("inital");
                      //     }
                      //   );
                      // }}
                    >
                      Create
                    </button>
                    <button
                      className="btn btn-secondary test-run-cancel"
                      onClick={() =>
                        this.setState({
                          createCustomModalVisible: false,
                          isCheck: [],
                          isCheckParent: [],
                        })
                      }
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}

              {!showCreateTestRun &&
                !createCustomModalVisible &&
                createCustomListModalVisible && (
                  <div
                    className="create-modal select-custom-run-modal test-run-model "
                    style={{
                      height: "calc(100vh - 167px)",
                      position: "relative",
                    }}
                  >
                    <div className="test-run-modal-title">
                      <h3 className="primary_heading">
                        Select Testcases for Run
                      </h3>
                    </div>
                    <div
                      className="flex justify-between mb-3"
                      // style={{
                      //   position: "relative",
                      // }}
                    >
                      <div className="flex flex-row gap-x-3">
                        <div
                          className="flex flex-row items-center gap-0 !pr-2"
                          style={{
                            position: "relative",
                            background: "rgba(146, 198, 255, 0.1)",
                            border: "0.3px solid #316BFF",
                            borderRadius: 8,
                            height: 44,
                            fontSize: 12,
                            width: 220,
                          }}
                        >
                          <input
                            autoComplete="off"
                            type="text"
                            className="form-control-  !placeholder-[#316BFF] bg-transparent !border-none !py-0 !text-[14px] px-3"
                            placeholder="Search"
                            value={search}
                            onChange={(e) =>
                              this.setState({ search: e.target.value }, () => {
                                this.handleAddFilterOrSearchOnCustomTestCases();
                              })
                            }
                          />

                          <IoSearchOutline size={22} color="#316BFF" />
                        </div>
                        <div className="relative">
                          <button
                            type="button"
                            onClick={() =>
                              this.setState({
                                showFilter: !this.state.showFilter,
                              })
                            }
                            style={{
                              position: "relative",
                              width: 110,
                              background: "rgba(146, 198, 255, 0.1)",
                              borderRadius: 8,
                            }}
                            className="px-3 border rounded-md !flex !flex-row !items-center !justify-center gap-x-3 !text-[#316BFF] !border-[#316BFF]  text-[12px] !font-[300] !h-[44px]"
                          >
                            <svg
                              width="11"
                              height="10"
                              viewBox="0 0 11 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.15 4.11744C7.56459 4.11737 7.9681 3.97419 8.30054 3.70918C8.63299 3.44417 8.87663 3.07149 8.99525 2.64651H10.45C10.5959 2.64651 10.7358 2.58452 10.8389 2.47418C10.9421 2.36384 11 2.21418 11 2.05814C11 1.90209 10.9421 1.75244 10.8389 1.6421C10.7358 1.53175 10.5959 1.46977 10.45 1.46977H8.99525C8.87643 1.04501 8.63271 0.672578 8.30028 0.407795C7.96785 0.143012 7.56445 0 7.15 0C6.73555 0 6.33215 0.143012 5.99972 0.407795C5.66729 0.672578 5.42357 1.04501 5.30475 1.46977H0.55C0.404131 1.46977 0.264236 1.53175 0.161091 1.6421C0.0579462 1.75244 0 1.90209 0 2.05814C0 2.21418 0.0579462 2.36384 0.161091 2.47418C0.264236 2.58452 0.404131 2.64651 0.55 2.64651H5.30475C5.42337 3.07149 5.66701 3.44417 5.99946 3.70918C6.3319 3.97419 6.73541 4.11737 7.15 4.11744ZM0.55 7.35349C0.404131 7.35349 0.264236 7.41548 0.161091 7.52582C0.0579462 7.63616 0 7.78582 0 7.94186C0 8.09791 0.0579462 8.24756 0.161091 8.35791C0.264236 8.46825 0.404131 8.53023 0.55 8.53023H1.72975C1.84857 8.95499 2.09229 9.32742 2.42472 9.59221C2.75715 9.85699 3.16055 10 3.575 10C3.98945 10 4.39285 9.85699 4.72528 9.59221C5.05771 9.32742 5.30143 8.95499 5.42025 8.53023H10.45C10.5959 8.53023 10.7358 8.46825 10.8389 8.35791C10.9421 8.24756 11 8.09791 11 7.94186C11 7.78582 10.9421 7.63616 10.8389 7.52582C10.7358 7.41548 10.5959 7.35349 10.45 7.35349H5.42025C5.30143 6.92873 5.05771 6.5563 4.72528 6.29152C4.39285 6.02674 3.98945 5.88372 3.575 5.88372C3.16055 5.88372 2.75715 6.02674 2.42472 6.29152C2.09229 6.5563 1.84857 6.92873 1.72975 7.35349H0.55Z"
                                fill="#316BFF"
                              />
                            </svg>
                            Filter
                          </button>
                          <div
                              className="filter-dashboard"
                              style={{
                                display: this.state.showFilter
                                  ? "block"
                                  : "none",
                              }}
                              ref={this.wrapperRef}
                            >
                              <h5
                        style={{
                          fontSize: 16,
                          fontWeight: 600,
                          padding: 16,
                          borderBottom: "1px solid #ECECEC",
                        }}
                      >
                        Select filter
                      </h5>
                      <div class="filter-div-properties">
                        <div className="filter-properties !border-b-0">
                          <p
                            className="my-2"
                            style={{ fontSize: 14, opacity: 0.7 }}
                          >
                            Type
                          </p>
                          <div className="d-flex flex-column">
                            <label htmlFor="automatedFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="Automated"
                                checked={this.state.filterParameters.type.includes(
                                  "automated"
                                )}
                                id="automatedFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "type",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />
                              Automated
                            </label>

                            <label htmlFor="manualFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="Manual"
                                checked={this.state.filterParameters.type.includes(
                                  "manual"
                                )}
                                id="manualFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "type",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Manual
                            </label>
                          </div>
                        </div>
                        <div className="filter-properties">
                          <p>Priority</p>
                          <div className="d-flex flex-column">
                            <label htmlFor="highFilter">
                              <input
                                className="priorityFilter !w-3 !h-3"
                                type="checkbox"
                                value="High"
                                checked={this.state.filterParameters.priority.includes(
                                  "high"
                                )}
                                id="highFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "priority",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />
                              High
                            </label>
                            <label htmlFor="mediumFilter">
                              <input
                                className="priorityFilter !w-3 !h-3"
                                type="checkbox"
                                value="Medium"
                                checked={this.state.filterParameters.priority.includes(
                                  "medium"
                                )}
                                id="mediumFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "priority",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Medium
                            </label>
                            <label htmlFor="lowFilter">
                              <input
                                className="priorityFilter !w-3 !h-3"
                                type="checkbox"
                                value="Low"
                                checked={this.state.filterParameters.priority.includes(
                                  "low"
                                )}
                                id="lowFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "priority",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Low
                            </label>
                          </div>
                        </div>
                        <div className="filter-properties">
                          <p>Test Type</p>
                          <div className="d-flex flex-column">
                            <label htmlFor="functionalFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="Functional"
                                checked={this.state.filterParameters.testType.includes(
                                  "functional"
                                )}
                                id="functionalFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "testType",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />
                              Functional
                            </label>

                            <label htmlFor="unitFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="Unit"
                                checked={this.state.filterParameters.testType.includes(
                                  "unit"
                                )}
                                id="unitFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "testType",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Unit
                            </label>

                            <label htmlFor="securityFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="Security"
                                checked={this.state.filterParameters.testType.includes(
                                  "security"
                                )}
                                id="securityFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "testType",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Security
                            </label>

                            <label htmlFor="integrationFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="Integration"
                                checked={this.state.filterParameters.testType.includes(
                                  "integration"
                                )}
                                id="integrationFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "testType",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Integration
                            </label>

                            <label htmlFor="systemFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="System"
                                checked={this.state.filterParameters.testType.includes(
                                  "system"
                                )}
                                id="systemFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "testType",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              System
                            </label>

                            <label htmlFor="acceptanceFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="Acceptance"
                                id="acceptanceFilter"
                                checked={this.state.filterParameters.testType.includes(
                                  "acceptance"
                                )}
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "testType",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Acceptance
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-evenly !mt-[16px]">
                      <button
                        type="button"
                        class="filter-btn"
                        onClick={() =>
                          this.setState({
                            filterProps: [],
                            filterParameters: {
                              type: [],
                              priority: [],
                              testType: [],
                            },
                          })
                        }
                      >
                        Clear
                      </button>
                      <button 
                      type="button"
                      onClick={() =>
                        this.setState({ showFilter: !this.state.showFilter })
                      }
                      className="rounded-lg !h-[40px] flex flex-row !w-[80px] !items-center justify-center gap-x-2  px-[16px] py-[8px] !bg-[#E7EAEE] !text-[14px] font-poppins text-[#636463] !font-[600] ">
                        Cancel
                      </button>
                      </div>
                            </div>
                        </div>
                      </div>

                      <div
                        className="flex flex-row cursor-pointer items-center gap-x-2 !text-[14px]"
                        style={{
                          position: "relative",
                        }}
                      >
                        <button
                          className="px-8 gap-x-3 flex items-center justify-center bg-[#E7EAEE] rounded-[8px] font-[400] opacity-70 !h-[44px]"
                          onClick={() =>
                              this.setState({
                                createCustomListModalVisible: false,
                                isCheck: [],
                                filterProps: [],
                            filterParameters: {
                              type: [],
                              priority: [],
                              testType: [],
                            },
                              })
                            }
                        >
                          <svg
                            width="16"
                            height="12"
                            viewBox="0 0 16 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.72951 1.21171V3.78079L7.33545 3.78667C11.5443 3.82837 13.9146 5.74432 14.5326 9.62731C12.2208 6.88965 9.5549 6.88848 7.34176 6.88789H6.72951V9.43524L1.05809 5.32318L6.72951 1.21171ZM7.34176 0L0 5.32318L7.34176 10.6464V7.5146C10.2802 7.51577 13.0046 7.66731 15.3084 11.8728C15.3084 9.20206 14.8775 3.23514 7.34176 3.16055V0Z"
                              fill="#636463"
                            />
                          </svg>
                          Back
                        </button>
                        <button
                          className={`primary_btn px-8 gap-x-3 flex items-center justify-center ${
                            this.state.isCheck.length === 0 && this.state.isCheckParent?.length === 0
                              ? " opacity-70 "
                              : ""
                          }`}
                          disabled={this.state.isCheck.length === 0 && this.state.isCheckParent?.length === 0}
                        onClick={this.onCreateCustomRun}
                        >
                          Continue
                          <svg
                            width="15"
                            height="10"
                            viewBox="0 0 15 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14 5L1 5M14 5L10.75 8.5M14 5L10.75 1.5"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>

                    <div
                      style={{
                        height: "calc(100vh - 370px)",
                        marginBottom: 25,
                        overflowY: "auto",
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: "#EEEEEE",
                        borderRadius: 5,
                      }}
                    >
                   
                   {this.state.filteredCustomTestcasesData.length === 0 && (
                      <div className="" style={{ paddingTop: 100 }}>
                        <NotFound
                          subject={"Testcase"}
                          search={search}
                          actionNeeded={false}
                          clear={() => {
                            this.setState({
                              search: "",
                              showFilter: false,
                              filterProps: [],
                              filterParameters: {
                                type: [],
                                priority: [],
                                testType: [],
                              },
                            });
                          }}
                        />
                      </div>
                    )}
                      {this.state.filteredCustomTestcasesData.length > 0 &&
                        this.state.filteredCustomTestcasesData.map((ele, index) => {
                          if (ele.lists.length === 0) return null;
                          return (
                            <div className="tests">
                              <div
                                className="flex items-center"
                                style={{
                                  borderBottom: "0.5px solid #D1D5DB",
                                  marginBottom: 3,
                                  backgroundColor: ele.isChecked
                                    ? "rgb(232 244 255)"
                                    : "#F8F8F8",
                                }}
                              >
                                <div className="w-0.5 px-0.5 py-3 bg-primary-green" />

                                <div className="model-input  w-full !bg-transparent">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      flex: 1,
                                    }}
                                  >
                                    {ele.expanded ? (
                                      <MdOutlineArrowDropDown
                                        className="text-primary-green cursor-pointer"
                                        size={28}
                                        onClick={() => {
                                          ele.expanded = !ele.expanded;
                                          this.setState({
                                            testCasesData: testCasesData,
                                          });
                                        }}
                                      />
                                    ) : (
                                      <MdArrowRight
                                        className="text-black cursor-pointer"
                                        size={28}
                                        onClick={() => {
                                          ele.expanded = !ele.expanded;
                                          this.setState({
                                            testCasesData: testCasesData,
                                          });
                                        }}
                                      />
                                    )}

                                    <input
                                      autoComplete="off"
                                      type="text"
                                      placeholder="Add Task Title here"
                                      className="input1 w-full !bg-transparent"
                                      name="model"
                                      value={ele.model || ele.taskTitle}
                                      disabled={true}
                                    />

                                    <input
                                      type="checkbox"
                                      className="!w-4 !h-4 !m-0 !mr-8 cursor-pointer showTaskDeleteIcon"
                                      onChange={() =>
                                        this.onCustomizeParentCheckbox(
                                          ele,
                                          index
                                        )
                                      }
                                      checked={
                                        ele.isChecked &&
                                        this.state.isCheckParent.length !== 0
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              {/* Div Goes Here */}
                              {ele.isCollapse ||
                                ele.lists
                                  .filter((el) => {
                                    if (filterProps.length <= 0) {
                                      return (
                                        el.subTaskTitle === "" ||
                                        el.subTaskTitle === null ||
                                        el.subTaskTitle
                                          ?.toString()
                                          .toLowerCase()
                                          .includes(search.toLowerCase())
                                      );
                                    } else {
                                      return (
                                        (el.subTaskTitle === "" ||
                                          el.subTaskTitle === null ||
                                          el.subTaskTitle
                                            ?.toString()
                                            .toLowerCase()
                                            .includes(search.toLowerCase())) &&
                                        (filterProps.includes(
                                          el.testing?.toString().toLowerCase()
                                        ) ||
                                          filterProps.includes(
                                            el.priority
                                              ? el.priority
                                                  ?.toString()
                                                  .toLowerCase()
                                              : null
                                          ) ||
                                          filterProps.some((item) =>
                                            el.test_type
                                              ? el.test_type.includes(item)
                                              : null
                                          ))
                                      );
                                    }
                                  })

                                  .map((item, i, row) => {
                                    return (
                                      ele.expanded &&
                                      item.subTaskTitle && (
                                        <div
                                          className={`model-list qq pl-[1.5rem] pr-4 ${
                                            item.subTaskTitle
                                              ? ""
                                              : " !bg-white"
                                          }`}
                                          // onMouseEnter={this.mouseEnter.bind(this)}
                                          // onMouseLeave={this.mouseLeave.bind(this)}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              flex: 1,
                                            }}
                                            className={`model-list-testsuite `}
                                          >
                                            <input
                                              type="checkbox"
                                              className="!w-4 !h-4 !m-0 !mr-4 cursor-pointer"
                                              onChange={() =>
                                                this.onCustomizeChildCheckbox(
                                                  item.subtaskId,
                                                  item,
                                                  index,
                                                  i
                                                )
                                              }
                                              checked={item.isChildChecked}
                                            />
                                            <input
                                              type="text"
                                              autoComplete="off"
                                              placeholder="Add new Test case here"
                                              className={`list-input !placeholder-primary-green ${
                                                item.classNameDependsOnCondtion
                                              } ${
                                                item.subTaskTitle
                                                  ? " bg-transparent"
                                                  : ""
                                              }`}
                                              name="list"
                                              value={
                                                item.subTaskTitle !== null
                                                  ? item.subTaskTitle
                                                  : ""
                                              }
                                              disabled={true}
                                            />
                                          </div>
                                        </div>
                                      )
                                    );
                                  })}
                            </div>
                          );
                        })}
                    </div>
                 
                  </div>
                )}
              {/* {showCreateTestRun && testCasesData.length > 0 && (
          <div>
            <button
              type="button"
              className="btn btn-primary float-right mt-0"
              onClick={() => this.saveChangesTestRun(false)}
            >
              Submit test result
            </button>
            <div className="float-right" style={{ padding: "10px" }}>
              {curruntTestName}
            </div>
          </div>
        )} */}
              
              {showCreateTestRun && !loading && !this.state.issuesVisisble && (
                <div
                  style={{
                    position: "sticky",
                    top: 0,
                    right: 0,
                    left: 0,
                    backgroundColor: "#FFFFFF",
                    paddingTop: 20,
                    borderBottom: '1px solid #eeeeee',
                  }}
                >
                  <div
                    className="d-flex align-items-center justify-content-between !font-[700] text-[17px] font-raleway"
                    style={{ marginBottom: 24 }}
                  >
                    <p className="">
                      Test Run Name:{" "}
                      <span className="!font-[500] !text-[#6A7279]">
                        {curruntTestName}
                      </span>
                    </p>
                    <div className="d-flex">
                      {/* <div
                  className="action-options d-inline-block"
                  style={{ marginRight: 16 }}
                >
                  <button
                    className="btn btn-primary"
                    // onClick={this.onCreateCustomRun}
                    onClick={() => {
                      this.setState({
                        createCustomModalVisible: false,
                        createCustomListModalVisible: true,
                        showCreateTestRun: false,
                      });
                      this.getTestCasesData("inital");
                    }}
                  >
                    Customized
                  </button>
                </div> */}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div className="flex flex-row items-center justify-between w-full mb-3">
                      <div className="flex flex-row items-center gap-x-3">
                        <div
                          className="flex items-center !p-0 !m-0"
                          style={{
                            position: "relative",
                          }}
                        >
                          <input
                            autoComplete="off"
                            type="text"
                            className="form-control !h-[40px] !mt-0 !placeholder-[#316BFF]"
                            placeholder="Search"
                            value={search}
                            onChange={(e) =>
                              this.setState({ search: e.target.value }, () => {
                                this.handleAddFilterOrSearchOnTestCases();
                              })
                            }
                            style={{
                              border: "0.3px solid #316BFF",
                              borderRadius: 8,
                              height: 44,
                              fontSize: 12,
                              width: 260,
                              background: "rgba(146, 198, 255, 0.1)",
                            }}
                          />
                          <span
                            style={{
                              position: "absolute",
                              color: "#316BFF",
                              top: 12,
                              right: 12,
                            }}
                          >
                            <IoSearchOutline />
                          </span>
                        </div>
                        <div className="action-options d-inline-block">
                          <Dropdown
                            isOpen={this.state.dropdownOpenprofile1}
                            toggle={this.toggleprofile1}
                            className="!text-[#454545] !border-gray-600 flex items-center gap-x-2 hover:!border-gray-300 hover:!text-[#454545] !h-[42px] "
                            style={{ borderRadius: 8 }}
                          >
                            <DropdownToggle
                              caret
                              className="flex flex-row gap-x-3 items-center border-primary-green text-primary-green action-btn"
                            >
                              {" "}
                              <svg
                                width="13"
                                height="12"
                                viewBox="0 0 13 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M11.7 5.42857V0.285713H10.4V5.42857H9.10001V6.57143H10.4V11.7143H11.7V6.57143H13V5.42857H11.7ZM7.14999 0.285713H5.85001V2.57143H4.55V3.71428H5.85001V11.7143H7.14999V3.71428H8.45V2.57143H7.14999V0.285713ZM2.60001 5.42857H3.89999V6.57143H2.60001V11.7143H1.3V6.57143H0V5.42857H1.3V0.285713H2.60001V5.42857Z"
                                  fill="#27AE60"
                                />
                              </svg>
                              Action
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                className="flex gap-x-1 items-center"
                                onClick={() => {
                                  this.setState({
                                    testCasesData: this.state.testCasesData.map(
                                      (testsuite) => {
                                        return {
                                          ...testsuite,
                                          expanded: true,
                                          isActionMenuVisible: false,
                                        };
                                      }
                                    ),
                                  });
                                }}
                              >
                                <svg
                                  width="14"
                                  height="12"
                                  viewBox="0 0 14 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clip-path="url(#clip0_334_292)">
                                    <path
                                      d="M8.10339 4.34999C8.15791 4.40434 8.22582 4.44694 8.3019 4.47452C8.37799 4.50211 8.46022 4.51394 8.5423 4.50911C8.62437 4.50428 8.7041 4.48292 8.77537 4.44667C8.84663 4.41042 8.90753 4.36025 8.95339 4.29999C9.0357 4.19966 9.07516 4.07688 9.06479 3.95336C9.05442 3.82984 8.99489 3.71353 8.89672 3.62499L7.19672 2.14999C7.14629 2.1015 7.08499 2.06278 7.01671 2.03629C6.94842 2.0098 6.87465 1.99612 6.80006 1.99612C6.72546 1.99612 6.65169 2.0098 6.5834 2.03629C6.51512 2.06278 6.45382 2.1015 6.40339 2.14999L4.70339 3.62499C4.60522 3.71353 4.54569 3.82984 4.53532 3.95336C4.52495 4.07688 4.56441 4.19966 4.64672 4.29999C4.69258 4.36025 4.75348 4.41042 4.82474 4.44667C4.89601 4.48292 4.97574 4.50428 5.05781 4.50911C5.13989 4.51394 5.22212 4.50211 5.29821 4.47452C5.37429 4.44694 5.4422 4.40434 5.49672 4.34999L6.23339 3.69999V8.29999L5.49672 7.64999C5.4422 7.59565 5.37429 7.55305 5.29821 7.52546C5.22212 7.49788 5.13989 7.48605 5.05781 7.49088C4.97574 7.4957 4.89601 7.51706 4.82474 7.55331C4.75348 7.58956 4.69258 7.63974 4.64672 7.69999C4.56441 7.80032 4.52495 7.9231 4.53532 8.04663C4.54569 8.17015 4.60522 8.28646 4.70339 8.37499L6.40339 9.84999C6.45382 9.89849 6.51512 9.93721 6.5834 9.9637C6.65169 9.99019 6.72546 10.0039 6.80006 10.0039C6.87465 10.0039 6.94842 9.99019 7.01671 9.9637C7.08499 9.93721 7.14629 9.89849 7.19672 9.84999L8.89672 8.37499C8.99489 8.28646 9.05442 8.17015 9.06479 8.04663C9.07516 7.9231 9.0357 7.80032 8.95339 7.69999C8.90753 7.63974 8.84663 7.58956 8.77537 7.55331C8.7041 7.51706 8.62437 7.4957 8.5423 7.49088C8.46022 7.48605 8.37799 7.49788 8.3019 7.52546C8.22582 7.55305 8.15791 7.59565 8.10339 7.64999L7.36672 8.29999V3.69999L8.10339 4.34999Z"
                                      fill="black"
                                    />
                                    <path
                                      d="M1.69997 1.5H11.9C12.0503 1.5 12.1944 1.44732 12.3007 1.35355C12.4069 1.25979 12.4666 1.13261 12.4666 1C12.4666 0.867392 12.4069 0.740215 12.3007 0.646447C12.1944 0.552678 12.0503 0.5 11.9 0.5H1.69997C1.54968 0.5 1.40554 0.552678 1.29927 0.646447C1.193 0.740215 1.1333 0.867392 1.1333 1C1.1333 1.13261 1.193 1.25979 1.29927 1.35355C1.40554 1.44732 1.54968 1.5 1.69997 1.5Z"
                                      fill="black"
                                    />
                                    <path
                                      d="M11.9 10.5H1.69997C1.54968 10.5 1.40554 10.5527 1.29927 10.6464C1.193 10.7402 1.1333 10.8674 1.1333 11C1.1333 11.1326 1.193 11.2598 1.29927 11.3536C1.40554 11.4473 1.54968 11.5 1.69997 11.5H11.9C12.0503 11.5 12.1944 11.4473 12.3007 11.3536C12.4069 11.2598 12.4666 11.1326 12.4666 11C12.4666 10.8674 12.4069 10.7402 12.3007 10.6464C12.1944 10.5527 12.0503 10.5 11.9 10.5Z"
                                      fill="black"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_334_292">
                                      <rect
                                        width="13.6"
                                        height="12"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                                Expand All
                              </DropdownItem>
                              <DropdownItem
                                className="flex gap-x-1 items-center"
                                onClick={() => {
                                  this.setState({
                                    testCasesData: this.state.testCasesData.map(
                                      (testsuite) => {
                                        return {
                                          ...testsuite,
                                          expanded: false,
                                          isActionMenuVisible: false,
                                        };
                                      }
                                    ),
                                  });
                                }}
                              >
                                <svg
                                  width="13"
                                  height="14"
                                  viewBox="0 0 13 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M6.12071 4.67064C6.16891 4.72318 6.22751 4.76512 6.29278 4.79382C6.35805 4.82251 6.42857 4.83733 6.49987 4.83733C6.57117 4.83733 6.6417 4.82251 6.70697 4.79382C6.77224 4.76512 6.83083 4.72318 6.87904 4.67064L8.50404 3.07272C8.59788 2.97681 8.65478 2.85081 8.66469 2.71699C8.67461 2.58318 8.63689 2.45017 8.55821 2.34147C8.51437 2.2762 8.45615 2.22184 8.38804 2.18257C8.31992 2.1433 8.24371 2.12016 8.16525 2.11493C8.0868 2.1097 8.00819 2.12252 7.93546 2.1524C7.86273 2.18228 7.79782 2.22843 7.74571 2.28731L7.04154 2.99147V1.58314C7.04154 1.43948 6.98447 1.30171 6.88289 1.20012C6.78131 1.09854 6.64353 1.04147 6.49987 1.04147C6.35621 1.04147 6.21844 1.09854 6.11686 1.20012C6.01527 1.30171 5.95821 1.43948 5.95821 1.58314V2.99147L5.25404 2.28731C5.20192 2.22843 5.13701 2.18228 5.06428 2.1524C4.99155 2.12252 4.91295 2.1097 4.83449 2.11493C4.75604 2.12016 4.67983 2.1433 4.61171 2.18257C4.54359 2.22184 4.48538 2.2762 4.44154 2.34147C4.36286 2.45017 4.32514 2.58318 4.33505 2.71699C4.34496 2.85081 4.40187 2.97681 4.49571 3.07272L6.12071 4.67064Z"
                                    fill="black"
                                  />
                                  <path
                                    d="M6.87904 9.32953C6.83083 9.277 6.77224 9.23505 6.70697 9.20635C6.6417 9.17766 6.57117 9.16284 6.49987 9.16284C6.42857 9.16284 6.35805 9.17766 6.29278 9.20635C6.22751 9.23505 6.16891 9.277 6.12071 9.32953L4.49571 10.9274C4.40187 11.0234 4.34496 11.1494 4.33505 11.2832C4.32514 11.417 4.36286 11.55 4.44154 11.6587C4.48538 11.724 4.54359 11.7783 4.61171 11.8176C4.67983 11.8569 4.75604 11.88 4.83449 11.8852C4.91295 11.8905 4.99155 11.8777 5.06428 11.8478C5.13701 11.8179 5.20192 11.7717 5.25404 11.7129L5.95821 11.0087V12.417C5.95821 12.5607 6.01527 12.6985 6.11686 12.8C6.21844 12.9016 6.35621 12.9587 6.49987 12.9587C6.64353 12.9587 6.78131 12.9016 6.88289 12.8C6.98447 12.6985 7.04154 12.5607 7.04154 12.417V11.0087L7.74571 11.7129C7.79782 11.7717 7.86273 11.8179 7.93546 11.8478C8.00819 11.8777 8.0868 11.8905 8.16525 11.8852C8.24371 11.88 8.31992 11.8569 8.38804 11.8176C8.45615 11.7783 8.51437 11.724 8.55821 11.6587C8.63689 11.55 8.67461 11.417 8.66469 11.2832C8.65478 11.1494 8.59788 11.0234 8.50404 10.9274L6.87904 9.32953Z"
                                    fill="black"
                                  />
                                  <path
                                    d="M1.62516 6.45844H11.3752C11.5188 6.45844 11.6566 6.40137 11.7582 6.29979C11.8598 6.19821 11.9168 6.06043 11.9168 5.91677C11.9168 5.77311 11.8598 5.63534 11.7582 5.53376C11.6566 5.43218 11.5188 5.37511 11.3752 5.37511H1.62516C1.4815 5.37511 1.34373 5.43218 1.24215 5.53376C1.14056 5.63534 1.0835 5.77311 1.0835 5.91677C1.0835 6.06043 1.14056 6.19821 1.24215 6.29979C1.34373 6.40137 1.4815 6.45844 1.62516 6.45844Z"
                                    fill="black"
                                  />
                                  <path
                                    d="M11.3752 7.54147H1.62516C1.4815 7.54147 1.34373 7.59854 1.24215 7.70012C1.14056 7.80171 1.0835 7.93948 1.0835 8.08314C1.0835 8.2268 1.14056 8.36457 1.24215 8.46616C1.34373 8.56774 1.4815 8.62481 1.62516 8.62481H11.3752C11.5188 8.62481 11.6566 8.56774 11.7582 8.46616C11.8598 8.36457 11.9168 8.2268 11.9168 8.08314C11.9168 7.93948 11.8598 7.80171 11.7582 7.70012C11.6566 7.59854 11.5188 7.54147 11.3752 7.54147Z"
                                    fill="black"
                                  />
                                </svg>
                                Collapse All
                              </DropdownItem>
                              <DropdownItem onClick={this.setAllUntested}>
                                Mark all Untested
                              </DropdownItem>
                              <DropdownItem onClick={this.setAllPass}>
                                Mark all Pass
                              </DropdownItem>
                              <DropdownItem onClick={this.setAllFailed}>
                                Mark all Failed
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </div>
                      <div className="flex flex-row gap-x-2 itmes-center justify-center">
                        <div
                          style={{
                            position: "relative",
                          }}
                        >
                          <button
                            type="button"
                            ref={this.wrapperRef}
                            onClick={() =>
                              this.setState({
                                showFilter: !this.state.showFilter,
                              })
                            }
                            style={{
                              position: "relative",
                              width: 110,
                              background: "rgba(146, 198, 255, 0.1)",
                              borderRadius: 8,
                            }}
                            className="px-3 border rounded-md !flex !flex-row !items-center !justify-center gap-x-3 !text-[#316BFF] !border-[#316BFF] h-full text-[12px] !font-[300]"
                          >
                            <svg
                              width="11"
                              height="10"
                              viewBox="0 0 11 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.15 4.11744C7.56459 4.11737 7.9681 3.97419 8.30054 3.70918C8.63299 3.44417 8.87663 3.07149 8.99525 2.64651H10.45C10.5959 2.64651 10.7358 2.58452 10.8389 2.47418C10.9421 2.36384 11 2.21418 11 2.05814C11 1.90209 10.9421 1.75244 10.8389 1.6421C10.7358 1.53175 10.5959 1.46977 10.45 1.46977H8.99525C8.87643 1.04501 8.63271 0.672578 8.30028 0.407795C7.96785 0.143012 7.56445 0 7.15 0C6.73555 0 6.33215 0.143012 5.99972 0.407795C5.66729 0.672578 5.42357 1.04501 5.30475 1.46977H0.55C0.404131 1.46977 0.264236 1.53175 0.161091 1.6421C0.0579462 1.75244 0 1.90209 0 2.05814C0 2.21418 0.0579462 2.36384 0.161091 2.47418C0.264236 2.58452 0.404131 2.64651 0.55 2.64651H5.30475C5.42337 3.07149 5.66701 3.44417 5.99946 3.70918C6.3319 3.97419 6.73541 4.11737 7.15 4.11744ZM0.55 7.35349C0.404131 7.35349 0.264236 7.41548 0.161091 7.52582C0.0579462 7.63616 0 7.78582 0 7.94186C0 8.09791 0.0579462 8.24756 0.161091 8.35791C0.264236 8.46825 0.404131 8.53023 0.55 8.53023H1.72975C1.84857 8.95499 2.09229 9.32742 2.42472 9.59221C2.75715 9.85699 3.16055 10 3.575 10C3.98945 10 4.39285 9.85699 4.72528 9.59221C5.05771 9.32742 5.30143 8.95499 5.42025 8.53023H10.45C10.5959 8.53023 10.7358 8.46825 10.8389 8.35791C10.9421 8.24756 11 8.09791 11 7.94186C11 7.78582 10.9421 7.63616 10.8389 7.52582C10.7358 7.41548 10.5959 7.35349 10.45 7.35349H5.42025C5.30143 6.92873 5.05771 6.5563 4.72528 6.29152C4.39285 6.02674 3.98945 5.88372 3.575 5.88372C3.16055 5.88372 2.75715 6.02674 2.42472 6.29152C2.09229 6.5563 1.84857 6.92873 1.72975 7.35349H0.55Z"
                                fill="#316BFF"
                              />
                            </svg>
                            Filter
                          </button>
                          <div
                            className="filter-dashboard"
                            style={{
                              display: this.state.showFilter ? "block" : "none",
                            }}
                          >
                            <h5
                        style={{
                          fontSize: 16,
                          fontWeight: 600,
                          padding: 16,
                          borderBottom: "1px solid #ECECEC",
                        }}
                      >
                        Select filter
                      </h5>
                      <div class="filter-div-properties">
                        <div className="filter-properties !border-b-0">
                          <p
                            className="my-2"
                            style={{ fontSize: 14, opacity: 0.7 }}
                          >
                            Type
                          </p>
                          <div className="d-flex flex-column">
                            <label htmlFor="automatedFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="Automated"
                                checked={this.state.filterParameters.type.includes(
                                  "automated"
                                )}
                                id="automatedFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "type",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />
                              Automated
                            </label>

                            <label htmlFor="manualFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="Manual"
                                checked={this.state.filterParameters.type.includes(
                                  "manual"
                                )}
                                id="manualFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "type",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Manual
                            </label>
                          </div>
                        </div>
                        <div className="filter-properties">
                          <p>Priority</p>
                          <div className="d-flex flex-column">
                            <label htmlFor="highFilter">
                              <input
                                className="priorityFilter !w-3 !h-3"
                                type="checkbox"
                                value="High"
                                checked={this.state.filterParameters.priority.includes(
                                  "high"
                                )}
                                id="highFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "priority",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />
                              High
                            </label>
                            <label htmlFor="mediumFilter">
                              <input
                                className="priorityFilter !w-3 !h-3"
                                type="checkbox"
                                value="Medium"
                                checked={this.state.filterParameters.priority.includes(
                                  "medium"
                                )}
                                id="mediumFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "priority",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Medium
                            </label>
                            <label htmlFor="lowFilter">
                              <input
                                className="priorityFilter !w-3 !h-3"
                                type="checkbox"
                                value="Low"
                                checked={this.state.filterParameters.priority.includes(
                                  "low"
                                )}
                                id="lowFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "priority",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Low
                            </label>
                          </div>
                        </div>
                        <div className="filter-properties">
                          <p>Test Type</p>
                          <div className="d-flex flex-column">
                            <label htmlFor="functionalFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="Functional"
                                checked={this.state.filterParameters.testType.includes(
                                  "functional"
                                )}
                                id="functionalFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "testType",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />
                              Functional
                            </label>

                            <label htmlFor="unitFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="Unit"
                                checked={this.state.filterParameters.testType.includes(
                                  "unit"
                                )}
                                id="unitFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "testType",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Unit
                            </label>

                            <label htmlFor="securityFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="Security"
                                checked={this.state.filterParameters.testType.includes(
                                  "security"
                                )}
                                id="securityFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "testType",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Security
                            </label>

                            <label htmlFor="integrationFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="Integration"
                                checked={this.state.filterParameters.testType.includes(
                                  "integration"
                                )}
                                id="integrationFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "testType",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Integration
                            </label>

                            <label htmlFor="systemFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="System"
                                checked={this.state.filterParameters.testType.includes(
                                  "system"
                                )}
                                id="systemFilter"
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "testType",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              System
                            </label>

                            <label htmlFor="acceptanceFilter">
                              <input
                                className="typeFilter !w-3 !h-3"
                                type="checkbox"
                                value="Acceptance"
                                id="acceptanceFilter"
                                checked={this.state.filterParameters.testType.includes(
                                  "acceptance"
                                )}
                                onChange={(e) => {
                                  this.onFilteredPropsItems(e.target.value);
                                  this.toggleFilterParameters(
                                    "testType",
                                    e.target.value
                                  );
                                }}
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Acceptance
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-evenly !mt-[16px]">
                      <button
                        type="button"
                        class="filter-btn"
                        onClick={() =>
                          this.setState({
                            filterProps: [],
                            filterParameters: {
                              type: [],
                              priority: [],
                              testType: [],
                            },
                          })
                        }
                      >
                        Clear
                      </button>
                      <button 
                      type="button"
                      onClick={() =>
                        this.setState({ showFilter: !this.state.showFilter })
                      }
                      className="rounded-lg !h-[40px] flex flex-row !w-[80px] !items-center justify-center gap-x-2  px-[16px] py-[8px] !bg-[#E7EAEE] !text-[14px] font-poppins text-[#636463] !font-[600] ">
                        Cancel
                      </button>
                      </div>
                          </div>
                        </div>
                        <div className="">
                          <button
                            type="button"
                            className="primary_btn !font-[400] px-2 !h-[40px]"
                            onClick={() =>
                              this.setState({ twoActionPopup: true })
                            }
                          >
                            <svg
                              width="33"
                              height="32"
                              viewBox="0 0 33 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21.8671 12.7656L14.7428 20.2891L10.7639 16.0878L10.8644 15.9309L14.0246 19.1862C14.2139 19.3812 14.4744 19.4907 14.7461 19.4896C15.0179 19.4885 15.2775 19.3769 15.4652 19.1804L19.0396 15.4386L19.0425 15.4356L21.7666 12.6081L21.8671 12.7656Z"
                                stroke="white"
                                stroke-width="2"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Submit test result
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <input
              type="text"
              placeholder="Search test case"
              className="form-control input-design float-left w-100"
              value={search}
              onChange={(e) => this.setState({ search: e.target.value })}
            /> */}
                  {/* <div className="action-options ml-2 d-inline-block">
              <Dropdown
                isOpen={this.state.dropdownOpenprofile1}
                toggle={this.toggleprofile1}
              >
                <DropdownToggle caret>Action</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={this.setAllUntested}>
                    Mark all Untested
                  </DropdownItem>
                  <DropdownItem onClick={this.setAllPass}>
                    Mark all Pass
                  </DropdownItem>
                  <DropdownItem onClick={this.setAllFailed}>
                    Mark all Failed
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div> */}
                </div>
              )}

              {showCreateTestRun && !this.state.issuesVisisble && (
                <div>
                  {/* {testCasesData.length > 0 && (
            <div className="testRunActions-options">
              <div className="dropdown-options">
                <div>
                  <div
                    class="form-check"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      style={{
                        height: 16,
                        width: 16,
                      }}
                    />
                    <label
                      className="form-check-label mb-0"
                      htmlFor="flexCheckDefault"
                    >
                      Select All
                    </label>
                  </div>
                </div>
              </div>
              <div className="action-options">
                <Dropdown
                  isOpen={this.state.dropdownOpenprofile1}
                  toggle={this.toggleprofile1}
                >
                  <DropdownToggle caret>Action</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={this.setAllUntested}>
                      Mark all Untested
                    </DropdownItem>
                    <DropdownItem onClick={this.setAllPass}>
                      Mark all Pass
                    </DropdownItem>
                    <DropdownItem onClick={this.setAllFailed}>
                      Mark all Failed
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          )} */}
                  <div
                    className={
                      "main-test-run " +
                      (testCasesData.length <= 0 ? "border-0" : "")
                    }
                    style={{
                      // marginTop: testCasesData.length <= 0 ? "" : "20px",
                      borderRadius: 5,
                    }}
                  >
                    {!isTestRunCreated && (
                      <div className="empty-container">
                        <div className="">
                          <div className="">
                            <div className="text-center">
                              {/* <button type="button" className="btn btn-primary" onClick={() => this.setState({ createModalVisible: true })}>Add Test Run</button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                   {!loading && this.state.filteredTestcasesData.length === 0 && (
                      <div className="" style={{ paddingTop: 100 }}>
                        <NotFound
                          subject={"Testcase"}
                          search={search}
                          actionNeeded={false}
                          clear={() => {
                            this.setState({
                              search: "",
                              showFilter: false,
                              filterProps: [],
                              filterParameters: {
                                type: [],
                                priority: [],
                                testType: [],
                              },
                            });
                          }}
                        />
                      </div>
                    )}
  
                    {!loading &&
                      this.state.filteredTestcasesData.length > 0 &&
                      this.state.filteredTestcasesData.map((ele, index) => {
                        return (
                          <div className="tests">
                            <div
                              className="flex "
                              style={{
                                borderBottom: "0.5px solid #D1D5DB",
                                marginBottom: 3,
                                backgroundColor: "#F8F8F8",
                              }}
                            >
                              <div className="w-0.5 px-0.5 py-3 bg-primary-green" />
                              <div className="model-input  w-full">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flex: 1,
                                  }}
                                >
                                  {ele.expanded ? (
                                    <MdOutlineArrowDropDown
                                      className="text-primary-green"
                                      size={28}
                                      onClick={() => {
                                        ele.expanded = !ele.expanded;
                                        this.setState({
                                          testCasesData: testCasesData,
                                        });
                                      }}
                                    />
                                  ) : (
                                    <MdArrowRight
                                      className="text-black"
                                      size={28}
                                      onClick={() => {
                                        ele.expanded = !ele.expanded;
                                        this.setState({
                                          testCasesData: testCasesData,
                                        });
                                      }}
                                    />
                                  )}

                                  <input
                                    autoComplete="off"
                                    type="text"
                                    placeholder="Add Task Title here"
                                    className="input1 w-full"
                                    name="model"
                                    value={ele.model || ele.taskTitle}
                                    // onBlur={() => this.updateTaskChanges(index)}
                                    // onChange={(e) =>
                                    //   this.onModelUpdate(e, index)
                                    // }
                                    disabled={true}
                                  />
                                </div>
                              </div>
                            </div>

                            {/* Div Goes Here */}
                            {ele.isCollapse ||
                              ele.lists
                                .filter((el) => {
                                  if (filterProps.length <= 0) {
                                    return (
                                      el.subTaskTitle === "" ||
                                      el.subTaskTitle === null ||
                                      el.subTaskTitle
                                        .toString()
                                        .toLowerCase()
                                        .includes(search.toLowerCase())
                                    );
                                  } else {
                                    return (
                                      (el.subTaskTitle === "" ||
                                        el.subTaskTitle === null ||
                                        el.subTaskTitle
                                          .toString()
                                          .toLowerCase()
                                          .includes(search.toLowerCase())) &&
                                      (filterProps.includes(
                                        el.testing.toString().toLowerCase()
                                      ) ||
                                        filterProps.includes(
                                          el.priority
                                            ? el.priority
                                                .toString()
                                                .toLowerCase()
                                            : null
                                        ) ||
                                        filterProps.some((item) =>
                                          el.test_type
                                            ? el.test_type.includes(item)
                                            : null
                                        ))
                                    );
                                  }
                                })

                                .map((item, i, row) => {
                                  // console.log(
                                  // filterProps.includes(
                                  //   item.testing.toString().toLowerCase()

                                  // )

                                  item.testing = item.testing || "manual";
                                  item.integration =
                                    item.integration != null
                                      ? JSON.parse(
                                          JSON.stringify(item.integration)
                                        )
                                      : item.integration;
                                  // console.log(item.integration);
                                  let tmpInt = item.integration
                                    ? JSON.parse(item.integration)
                                    : item.integration;
                                  let cg = tmpInt;
                                  tmpInt = tmpInt
                                    ? _.get(tmpInt, "value")
                                    : tmpInt;

                                  let intTmpType = cg ? _.get(cg, "type") : cg;

                                  // console.log(
                                  //   "item.integration",
                                  //   tmpInt,
                                  //   intTmpType,
                                  //   item.integration
                                  // );
                                  if (intTmpType === "asana") {
                                    let project_key = cg
                                      ? _.get(cg, "project_key")
                                      : cg;
                                    //https://app.asana.com/0/1199525592925725/1203666857435636/f
                                    item.intiURL = `https://app.asana.com/0/${project_key}/${tmpInt}`;
                                  }
                                  if (intTmpType === "jira") {
                                    let domain = cg ? _.get(cg, "domain") : cg;
                                    //https://mkgareja.atlassian.net/browse/OYET-127
                                    item.intiURL = `https://${domain}.atlassian.net/browse/${tmpInt}`;
                                  }
                                  if (intTmpType === "trello") {
                                    item.intiURL = `https://trello.com/c/${tmpInt}`;
                                  }
                                  if (intTmpType === "github") {
                                    let githubUserName = cg
                                      ? _.get(cg, "githubUserName")
                                      : cg;
                                    let project_label = cg
                                      ? _.get(cg, "project_label")
                                      : cg;

                                    item.intiURL = `https://github.com/${githubUserName}/${project_label}/issues/${tmpInt}`;
                                  }

                                  return (
                                    ele.expanded && (
                                      <div
                                        className={`model-list hover:!bg-[#E8F4FF] qq pl-12 pr-4 ${
                                          item.subTaskTitle ? "" : " !bg-white"
                                        }`}
                                        // onMouseEnter={this.mouseEnter.bind(this)}
                                        // onMouseLeave={this.mouseLeave.bind(this)}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            flex: 1,
                                          }}
                                          className={`model-list-testsuite `}
                                        >
                                          <input
                                            autoFocus={
                                              item.subTaskTitle === "" ||
                                              item.subTaskTitle === null
                                                ? true
                                                : false
                                            }
                                            // ref={(el) =>
                                            //   (item.subTaskTitle === "" ||
                                            //     item.subTaskTitle === null) &&
                                            //   el
                                            //     ? el.focus()
                                            //     : null
                                            // }

                                            type="text"
                                            autoComplete="off"
                                            placeholder="Add new Test case here"
                                            className={`list-input !placeholder-primary-green ${
                                              item.classNameDependsOnCondtion
                                            } ${
                                              item.subTaskTitle
                                                ? " bg-transparent"
                                                : ""
                                            }`}
                                            name="list"
                                            value={
                                              item.subTaskTitle !== null
                                                ? item.subTaskTitle
                                                : ""
                                            }
                                            // onChange={(e) =>
                                            //   this.onModelListUpdate(
                                            //     ele.id,
                                            //     item.subtaskId,
                                            //     i,
                                            //     e.target.value
                                            //   )
                                            // }
                                            disabled={true}
                                            onKeyDown={(e) => {
                                              if (e.key === "Enter") {
                                                this.addNewList(
                                                  e.currentTarget.value,
                                                  ele.id
                                                );
                                              }
                                            }}
                                          />
                                          {item.subTaskTitle ? (
                                            <span
                                              className="mr-8 pl-2 flex items-center open-details-button cursor-pointer"
                                              name=""
                                              onClick={() =>
                                                this.onClickDetail(
                                                  index + "-" + i,
                                                  item.subtaskId
                                                )
                                              }
                                            >
                                              <span className="details-button">
                                                Details
                                              </span>

                                              <svg
                                                className="details-svg"
                                                width="13"
                                                height="13"
                                                viewBox="0 0 13 13"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M6.83333 0.875488H6.25C3.35051 0.875488 1 3.22599 1 6.12549C1 9.025 3.35051 11.3755 6.25 11.3755H6.83333M9.16667 3.79215L11.5 6.12549M11.5 6.12549L9.16667 8.45882M11.5 6.12549H4.5"
                                                  stroke="#999999"
                                                  stroke-width="1.5"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                />
                                              </svg>
                                            </span>
                                          ) : (
                                            <span className="pr-[27px]" />
                                          )}
                                          <Tooltip
                                            placement="top"
                                            title="Click to change the status"
                                            arrow
                                          >
                                            <div
                                              className="status-container !w-20 overflow-hidden"
                                              style={{
                                                backgroundColor: this.getTheme(
                                                  item.testStatus
                                                ),
                                                display:
                                                  item.testStatus === ""
                                                    ? "none"
                                                    : "flex",
                                              }}
                                              onClick={() => {
                                                let newStatus;
                                                if (
                                                  item.testStatus === "pass"
                                                ) {
                                                  newStatus = "failed";
                                                  this.setState(
                                                    {
                                                      isCheck:
                                                        this.state.isCheck.map(
                                                          (el) =>
                                                            el.subtaskId ===
                                                            item.subtaskId
                                                              ? {
                                                                  ...el,
                                                                  status:
                                                                    "failed",
                                                                }
                                                              : el
                                                        ),
                                                    },
                                                    () =>
                                                      console.log(
                                                        this.state.isCheck
                                                      )
                                                  );
                                                } else if (
                                                  item.testStatus === "failed"
                                                ) {
                                                  newStatus = "untested";
                                                  this.setState(
                                                    {
                                                      isCheck:
                                                        this.state.isCheck.map(
                                                          (el) =>
                                                            el.subtaskId ===
                                                            item.subtaskId
                                                              ? {
                                                                  ...el,
                                                                  status:
                                                                    "untested",
                                                                }
                                                              : el
                                                        ),
                                                    },
                                                    () => {
                                                      // this.state.untestedCount++;
                                                      this.setState({
                                                        untestedCount:
                                                          this.state
                                                            .untestedCount + 1,
                                                      });
                                                      console.log(
                                                        this.state.isCheck
                                                      );
                                                    }
                                                  );
                                                } else {
                                                  newStatus = "pass";
                                                  this.setState(
                                                    {
                                                      isCheck:
                                                        this.state.isCheck.map(
                                                          (el) =>
                                                            el.subtaskId ===
                                                            item.subtaskId
                                                              ? {
                                                                  ...el,
                                                                  status:
                                                                    "pass",
                                                                }
                                                              : el
                                                        ),
                                                    },
                                                    () => {
                                                      this.state
                                                        .untestedCount--;
                                                      console.log(
                                                        this.state.isCheck
                                                      );
                                                    }
                                                  );
                                                }
                                                this.updateStatus(
                                                  index,
                                                  i,
                                                  newStatus,
                                                  item.subtaskId
                                                );
                                              }}
                                            >
                                              <p>{item.testStatus}</p>
                                            </div>
                                          </Tooltip>
                                        </div>

                                        <div
                                          ref={this.sidebarRef}
                                          style={{
                                            width:
                                              dynamicStyle[index + "-" + i] ||
                                              dynamicStyle,
                                            zIndex: 5000,
                                          }}
                                          className="sidebar-new"
                                        >
                                          <div className="button-items detail-btn">
                                            <i
                                              className="mdi mdi-close btn-float-left"
                                              onClick={() =>
                                                this.onClickCloseDetail(
                                                  index + "-" + i
                                                )
                                              }
                                            ></i>
                                          </div>
                                          <Scrollbars style={{ height: "85%" }}>
                                            {/* <a onClick={() => this.onClickCloseDetail()} className="closebtn">&times;</a> */}
                                            <div className="row test-row sidebar-nav-body">
                                              <div className="col-12">
                                                <div className="card m-b-20">
                                                  <div className="card-body">
                                                    <form
                                                      onSubmit={(e) =>
                                                        this.onSubmitsubTaskDataInSideDrawer(
                                                          ele.id,
                                                          item.subtaskId,
                                                          e
                                                        )
                                                      }
                                                    >
                                                      <div className="form-group row">
                                                        <div className="col-sm-12 p-0 mr-6 mb-6">
                                                          <input
                                                            className="form-control test-header-title"
                                                            type="text"
                                                            name="subTaskNameInSideDrawer"
                                                            style={{
                                                              marginBottom: 20,
                                                              marginTop: "auto",
                                                              paddingLeft: 0,
                                                            }}
                                                            ref={(el) => {
                                                              if (el) {
                                                                el.style.setProperty(
                                                                  "font-size",
                                                                  "20px",
                                                                  "important"
                                                                );
                                                                el.style.setProperty(
                                                                  "font-family",
                                                                  "'Raleway', sans-serif",
                                                                  "important"
                                                                );
                                                                el.style.setProperty(
                                                                  "font-weight",
                                                                  "700px",
                                                                  "important"
                                                                );
                                                              }
                                                            }}
                                                            onChange={(e) =>
                                                              this.onModelListTestDetailUpdate(
                                                                "name",
                                                                index,
                                                                i,
                                                                item.subtaskId,
                                                                e.target.value
                                                              )
                                                            }
                                                            value={
                                                              item.subTaskTitle
                                                            }
                                                          />
                                                          <div className="flex flex-row items-cente gap-2 ">
                                                            <div className="flex flex-row items-center text-[14px] font-[400] gap-2 .font-roboto color-[#525252]">
                                                              {" "}
                                                              ID:{" "}
                                                              <span className=".font-poppins h-8 flex text-[14px] items-center justify-center text-primary-green bg-[#EBFFF4] px-2 py-1 rounded-md font-[500]">
                                                                {item.subid}
                                                              </span>
                                                            </div>
                                                            <div className="flex flex-row items-center text-[14px] font-[400] gap-2 .font-roboto color-[#525252]">
                                                              {" "}
                                                              TestSuite:{" "}
                                                              <span className=".font-poppins h-8 flex text-[14px] items-center justify-center text-primary-green bg-[#EBFFF4] px-2 py-1 rounded-md font-[500]">
                                                                {ele.model ||
                                                                  ele.taskTitle}
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <hr></hr>
                                                      <div
                                                        className="form-group row"
                                                        style={{
                                                          marginTop: "2rem",
                                                        }}
                                                      >
                                                        <label
                                                          for="example-description-input"
                                                          className="pading-right-0px col-sm-3 col-form-label input-label"
                                                          style={{
                                                            width: "100%",
                                                          }}
                                                        >
                                                          Description
                                                        </label>
                                                        <div className="col-sm-8 ml-3">
                                                          <textarea
                                                            id="textarea"
                                                            className="form-control input-style-detail"
                                                            name="subTaskDescriptionInSideDrawer"
                                                            // ref={(el) => {
                                                            //   if (el) {
                                                            //     el.style.setProperty(
                                                            //       "font-size",
                                                            //       "13px",
                                                            //       "important"
                                                            //     );
                                                            //     el.style.setProperty(
                                                            //       "font-family",
                                                            //       "'Roboto', sans-serif",
                                                            //       "important"
                                                            //     );
                                                            //     el.style.setProperty(
                                                            //       "font-weight",
                                                            //       "400px",
                                                            //       "important"
                                                            //     );
                                                            //     el.style.setProperty(
                                                            //       "color",
                                                            //       "#64748B",
                                                            //       "important"
                                                            //     );
                                                            //   }
                                                            // }}
                                                            onChange={(e) =>
                                                              this.onModelListTestDetailUpdate(
                                                                "description",
                                                                index,
                                                                i,
                                                                item.subtaskId,
                                                                e.target.value
                                                              )
                                                            }
                                                            value={
                                                              item.description
                                                            }
                                                            maxlength="225"
                                                            rows="6"
                                                            placeholder="Description and Steps to reproduce"
                                                          ></textarea>
                                                        </div>
                                                      </div>
                                                      <div className="form-group row">
                                                        <label
                                                          for="example-summary-input"
                                                          className="col-sm-3 col-form-label input-label"
                                                        >
                                                          Expected Result
                                                        </label>
                                                        <div className="col-sm-8 ml-3">
                                                          <textarea
                                                            id="textarea"
                                                            className="form-control input-style-detail"
                                                            name="subTaskSummaryInSideDrawer"
                                                            // ref={(el) => {
                                                            //   if (el) {
                                                            //     el.style.setProperty(
                                                            //       "font-size",
                                                            //       "13px",
                                                            //       "important"
                                                            //     );
                                                            //     el.style.setProperty(
                                                            //       "font-family",
                                                            //       "'Roboto', sans-serif",
                                                            //       "important"
                                                            //     );
                                                            //     el.style.setProperty(
                                                            //       "font-weight",
                                                            //       "400px",
                                                            //       "important"
                                                            //     );
                                                            //     el.style.setProperty(
                                                            //       "color",
                                                            //       "#64748B",
                                                            //       "important"
                                                            //     );
                                                            //   }
                                                            // }}
                                                            onChange={(e) =>
                                                              this.onModelListTestDetailUpdate(
                                                                "summary",
                                                                index,
                                                                i,
                                                                item.subtaskId,
                                                                e.target.value
                                                              )
                                                            }
                                                            value={item.summary}
                                                            maxlength="225"
                                                            rows="2"
                                                            placeholder="Summary"
                                                          ></textarea>
                                                        </div>
                                                      </div>
                                                      <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label input-label">
                                                          Browser
                                                        </label>
                                                        <div className="col-sm-8 ml-3">
                                                          <select
                                                            className="form-control input-style-detail"
                                                            name="subTaskBrowserInSideDrawer"
                                                            value={item.browser}
                                                            // ref={(el) => {
                                                            //   if (el) {
                                                            //     el.style.setProperty(
                                                            //       "font-size",
                                                            //       "13px",
                                                            //       "important"
                                                            //     );
                                                            //     el.style.setProperty(
                                                            //       "font-family",
                                                            //       "'Roboto', sans-serif",
                                                            //       "important"
                                                            //     );
                                                            //     el.style.setProperty(
                                                            //       "font-weight",
                                                            //       "400px",
                                                            //       "important"
                                                            //     );
                                                            //     el.style.setProperty(
                                                            //       "color",
                                                            //       "#64748B",
                                                            //       "important"
                                                            //     );
                                                            //   }
                                                            // }}
                                                            onChange={(e) =>
                                                              this.onModelListTestDetailUpdate(
                                                                "browser",
                                                                index,
                                                                i,
                                                                item.subtaskId,
                                                                e.target.value
                                                              )
                                                            }
                                                          >
                                                            
                                                            <option value="chrome">
                                                              Chrome
                                                            </option>
                                                            <option value="firefox">
                                                              Firefox
                                                            </option>
                                                            <option value="other">
                                                              Other
                                                            </option>
                                                          </select>
                                                        </div>
                                                      </div>

                                                      <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label input-label">
                                                          OS
                                                        </label>
                                                        <div className="col-sm-8 ml-3">
                                                          <select
                                                            name="subTaskOsInSideDrawer"
                                                            value={item.os}
                                                            className="form-control input-style-detail"
                                                            onChange={(e) =>
                                                              this.onModelListTestDetailUpdate(
                                                                "os",
                                                                index,
                                                                i,
                                                                item.subtaskId,
                                                                e.target.value
                                                              )
                                                            }
                                                            // ref={(el) => {
                                                            //   if (el) {
                                                            //     el.style.setProperty(
                                                            //       "font-size",
                                                            //       "13px",
                                                            //       "important"
                                                            //     );
                                                            //     el.style.setProperty(
                                                            //       "font-family",
                                                            //       "'Roboto', sans-serif",
                                                            //       "important"
                                                            //     );
                                                            //     el.style.setProperty(
                                                            //       "font-weight",
                                                            //       "400px",
                                                            //       "important"
                                                            //     );
                                                            //     el.style.setProperty(
                                                            //       "color",
                                                            //       "#64748B",
                                                            //       "important"
                                                            //     );
                                                            //   }
                                                            // }}
                                                          >
                                                            
                                                            <option value="windows">
                                                              Windows
                                                            </option>
                                                            <option value="mac">
                                                              Mac
                                                            </option>
                                                            <option value="other">
                                                              Other
                                                            </option>
                                                          </select>
                                                        </div>
                                                      </div>

                                                      <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label input-label">
                                                          Type
                                                        </label>
                                                        <div className="col-sm-8 ml-3">
                                                          <select
                                                            name="subTaskTestingInSideDrawer"
                                                            value={item.testing}
                                                            className="form-control input-style-detail"
                                                            onChange={(e) =>
                                                              this.onModelListTestDetailUpdate(
                                                                "testing",
                                                                index,
                                                                i,
                                                                item.subtaskId,
                                                                e.target.value
                                                              )
                                                            }
                                                            // ref={(el) => {
                                                            //   if (el) {
                                                            //     el.style.setProperty(
                                                            //       "font-size",
                                                            //       "13px",
                                                            //       "important"
                                                            //     );
                                                            //     el.style.setProperty(
                                                            //       "font-family",
                                                            //       "'Roboto', sans-serif",
                                                            //       "important"
                                                            //     );
                                                            //     el.style.setProperty(
                                                            //       "font-weight",
                                                            //       "400px",
                                                            //       "important"
                                                            //     );
                                                            //     el.style.setProperty(
                                                            //       "color",
                                                            //       "#64748B",
                                                            //       "important"
                                                            //     );
                                                            //   }
                                                            // }}
                                                          >
                                                            <option
                                                              value=""
                                                              selected
                                                              disabled
                                                            >
                                                              Select
                                                            </option>
                                                            <option value="manual">
                                                              Manual
                                                            </option>
                                                            <option value="automated">
                                                              Automated
                                                            </option>
                                                          </select>
                                                        </div>
                                                      </div>
                                                      <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label input-label">
                                                          Priority
                                                        </label>
                                                        <div className="col-sm-8 ml-3">
                                                          <select
                                                            name="subTaskpriorityInSideDrawer"
                                                            value={
                                                              item.priority
                                                            }
                                                            className="form-control input-style-detail"
                                                            onChange={(e) =>
                                                              this.onModelListTestDetailUpdate(
                                                                "priority",
                                                                index,
                                                                i,
                                                                item.subtaskId,
                                                                e.target.value
                                                              )
                                                            }
                                                            // ref={(el) => {
                                                            //   if (el) {
                                                            //     el.style.setProperty(
                                                            //       "font-size",
                                                            //       "13px",
                                                            //       "important"
                                                            //     );
                                                            //     el.style.setProperty(
                                                            //       "font-family",
                                                            //       "'Roboto', sans-serif",
                                                            //       "important"
                                                            //     );
                                                            //     el.style.setProperty(
                                                            //       "font-weight",
                                                            //       "400px",
                                                            //       "important"
                                                            //     );
                                                            //     el.style.setProperty(
                                                            //       "color",
                                                            //       "#64748B",
                                                            //       "important"
                                                            //     );
                                                            //   }
                                                            // }}
                                                          >
                                                            <option value="high">
                                                              High
                                                            </option>
                                                            <option
                                                              value="medium"
                                                              selected
                                                            >
                                                              Medium
                                                            </option>
                                                            <option value="low">
                                                              Low
                                                            </option>
                                                          </select>
                                                        </div>
                                                      </div>

                                                      <div className="form-group row">
                                                        <label
                                                          for="example-id-input "
                                                          className="col-sm-3 col-form-label input-label"
                                                        >
                                                          Created By
                                                        </label>
                                                        <div className="col-sm-8 ml-3">
                                                          <input
                                                            name="subTaskCreatedByInSideDrawer"
                                                            className="form-control input-style-detail"
                                                            type="text"
                                                            value={
                                                              (item.username =
                                                                this.getUsername(
                                                                  item.username
                                                                ))
                                                            }
                                                            // ref={(el) => {
                                                            //   if (el) {
                                                            //     el.style.setProperty(
                                                            //       "font-size",
                                                            //       "13px",
                                                            //       "important"
                                                            //     );
                                                            //     el.style.setProperty(
                                                            //       "font-family",
                                                            //       "'Roboto', sans-serif",
                                                            //       "important"
                                                            //     );
                                                            //     el.style.setProperty(
                                                            //       "font-weight",
                                                            //       "400px",
                                                            //       "important"
                                                            //     );
                                                            //     el.style.setProperty(
                                                            //       "color",
                                                            //       "#5A5B5E",
                                                            //       "important"
                                                            //     );
                                                            //   }
                                                            // }}
                                                            placeholder="Created By"
                                                            disabled
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="form-group row">
                                                        <label
                                                          for="example-id-input "
                                                          className="col-sm-3 col-form-label input-label"
                                                        >
                                                          Test type
                                                        </label>
                                                        <div className="col-sm-8 ml-3">
                                                          <Select
                                                            className=" input-style-detail"
                                                            isMulti
                                                            isSearchable={false}
                                                            value={
                                                              item.test_type
                                                                ? this.state.testType.filter(
                                                                    ({
                                                                      value,
                                                                    }) =>
                                                                      item.test_type.indexOf(
                                                                        value
                                                                      ) !== -1
                                                                  )
                                                                : this.state
                                                                    .testType[0]
                                                            }
                                                            onChange={
                                                              (e) =>
                                                                this.onModelListTestDetailUpdate(
                                                                  "test_type",
                                                                  index,
                                                                  i,
                                                                  item.subtaskId,
                                                                  e.map(
                                                                    (x) =>
                                                                      x.value
                                                                  )
                                                                )
                                                              // console.log(
                                                              //   e.map(
                                                              //     (x) => x.value
                                                              //   )
                                                              // )
                                                            }
                                                            options={
                                                              this.state
                                                                .testType
                                                            }
                                                            defaultValue={
                                                              this.state
                                                                .testType[0]
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="form-group row">
                                                        <label
                                                          for="example-description-input "
                                                          className="pading-right-0px col-sm-3 col-form-label input-label"
                                                        >
                                                          Last Executed{" "}
                                                        </label>
                                                        <div
                                                          className="col-sm-8 ml-3"
                                                          style={{
                                                            display: "flex",
                                                            alignItems:
                                                              "center",
                                                            justifyContent:
                                                              "flex-start",
                                                          }}
                                                        >
                                                          {this.state
                                                            .testHistory &&
                                                            this.state.testHistory.map(
                                                              (history) => {
                                                                return (
                                                                  <Tooltip
                                                                    placement="top"
                                                                    title={
                                                                      history.testStatus +
                                                                      " " +
                                                                      formatDate(
                                                                        history.createdAt
                                                                      )
                                                                    }
                                                                    arrow
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        marginRight: 15,
                                                                        display:
                                                                          "flex",
                                                                        alignItems:
                                                                          "center",
                                                                        flexDirection:
                                                                          "column",
                                                                      }}
                                                                    >
                                                                      <div
                                                                        classname="testcase-history"
                                                                        style={{
                                                                          width: 13,
                                                                          height: 13,
                                                                          borderRadius: 50,
                                                                          cursor:
                                                                            "pointer",
                                                                          backgroundColor:
                                                                            "#FFFFFF",
                                                                          color:
                                                                            "white",
                                                                        }}
                                                                      >
                                                                        {history.testStatus ===
                                                                          "untested" && (
                                                                          <>
                                                                            <svg
                                                                              width="13"
                                                                              height="13"
                                                                              viewBox="0 0 13 13"
                                                                              fill="none"
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                              <path
                                                                                d="M6.5 0C2.89545 0 0 2.89545 0 6.5C0 10.1045 2.89545 13 6.5 13C10.1045 13 13 10.1045 13 6.5C13 2.89545 10.1045 0 6.5 0ZM10.4591 7.56364C10.4 7.8 10.1636 7.91818 9.92727 7.85909L6.61818 7.20909C6.5 7.20909 6.38182 7.09091 6.26364 7.03182C6.02727 6.91364 5.90909 6.73636 5.90909 6.5L6.02727 1.77273C6.02727 1.47727 6.26364 1.3 6.5 1.3C6.73636 1.3 6.97273 1.53636 6.97273 1.77273L7.03182 5.85L10.1045 6.91364C10.4 6.97273 10.5182 7.26818 10.4591 7.56364Z"
                                                                                fill="#FF9A28"
                                                                              />
                                                                            </svg>
                                                                          </>
                                                                        )}
                                                                        {history.testStatus ===
                                                                          "pass" && (
                                                                          <>
                                                                            <svg
                                                                              width="13"
                                                                              height="13"
                                                                              viewBox="0 0 13 13"
                                                                              fill="none"
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                              <path
                                                                                fill-rule="evenodd"
                                                                                clip-rule="evenodd"
                                                                                d="M6.5 13C8.22391 13 9.87721 12.3152 11.0962 11.0962C12.3152 9.87721 13 8.22391 13 6.5C13 4.77609 12.3152 3.12279 11.0962 1.90381C9.87721 0.684819 8.22391 0 6.5 0C4.77609 0 3.12279 0.684819 1.90381 1.90381C0.684819 3.12279 0 4.77609 0 6.5C0 8.22391 0.684819 9.87721 1.90381 11.0962C3.12279 12.3152 4.77609 13 6.5 13ZM5.55286 9.165H4.89357L2.78571 7.05714L3.445 6.39786L5.22786 8.18071L9.12786 4.27143L9.78714 4.93071L5.55286 9.165Z"
                                                                                fill="#27AE60"
                                                                              />
                                                                            </svg>
                                                                          </>
                                                                        )}
                                                                        {history.testStatus ===
                                                                          "failed" && (
                                                                          <>
                                                                            <svg
                                                                              width="13"
                                                                              height="13"
                                                                              viewBox="0 0 13 13"
                                                                              fill="none"
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                              <path
                                                                                fill-rule="evenodd"
                                                                                clip-rule="evenodd"
                                                                                d="M8.79815 8.2225C8.95699 8.38093 8.95699 8.64095 8.79815 8.79939C8.63971 8.95783 8.38216 8.95783 8.22331 8.79939L6.50243 7.07689L4.76937 8.81154C4.60931 8.96998 4.35014 8.96998 4.19007 8.81154C4.03042 8.64904 4.03042 8.38907 4.19007 8.23063L5.92313 6.49593L4.20225 4.7775C4.0434 4.61907 4.0434 4.35905 4.20225 4.20061C4.36028 4.04217 4.61784 4.04217 4.77669 4.20061L6.49757 5.92311L8.24362 4.17626C8.40368 4.01782 8.66246 4.01782 8.82252 4.17626C8.98218 4.33876 8.98218 4.59467 8.82252 4.75717L7.07687 6.50407L8.79815 8.2225ZM6.5 0C2.90997 0 0 2.90875 0 6.5C0 10.0913 2.90997 13 6.5 13C10.09 13 13 10.0913 13 6.5C13 2.90875 10.09 0 6.5 0Z"
                                                                                fill="#FF2727"
                                                                              />
                                                                            </svg>
                                                                          </>
                                                                        )}
                                                                      </div>
                                                                    </div>
                                                                  </Tooltip>
                                                                );
                                                              }
                                                            )}
                                                        </div>
                                                      </div>

                                                      {(intType !== "" ||
                                                        intTmpType?.trim()) &&
                                                        !this.state
                                                          .createIssue && (
                                                          <>
                                                            <div
                                                              className="form-group row"
                                                              style={{
                                                                display: "flex",
                                                                alignItems:
                                                                  "center",
                                                              }}
                                                            >
                                                              <label
                                                                htmlFor="example-id-input" // Replace 'for' with 'htmlFor' in JSX
                                                                className="col-sm-3 col-form-label input-label"
                                                              >
                                                                {" "}
                                                                {intTmpType?.trim() ? (
                                                                  `${intTmpType} url`
                                                                ) : (
                                                                  <>
                                                                    {intType ===
                                                                      "jira" &&
                                                                      "Jira Bug"}
                                                                    {intType ===
                                                                      "asana" &&
                                                                      "Asana Bug"}
                                                                    {intType ===
                                                                      "trello" &&
                                                                      "Trello Card"}
                                                                    {intType ===
                                                                      "github" &&
                                                                      "GitHub Issue"}
                                                                  </>
                                                                )}
                                                              </label>
                                                              <div className="col-sm-8 ml-3 d-flex align-items-center">
                                                                {item?.integration ? (
                                                                  <div className="row m-0">
                                                                    <div
                                                                      className="col-sm-12 input-style-detail"
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      onClick={() =>
                                                                        window.open(
                                                                          item.intiURL,
                                                                          "_blank"
                                                                        )
                                                                      }
                                                                    >
                                                                      Click here
                                                                      to view
                                                                      <i
                                                                        className="fa fa-external-link ml-2"
                                                                        aria-hidden="true"
                                                                      ></i>
                                                                    </div>
                                                                  </div>
                                                                ) : (
                                                                  <div
                                                                    style={{
                                                                      flexGrow: 1,
                                                                    }}
                                                                  >
                                                                    <Select
                                                                      onChange={(
                                                                        issueKey
                                                                      ) =>
                                                                        this.handleChangeIntigretion(
                                                                          issueKey
                                                                        )
                                                                      }
                                                                      onInputChange={
                                                                        this
                                                                          .handleInputChange
                                                                      }
                                                                      options={
                                                                        issues
                                                                      }
                                                                      isMulti={
                                                                        false
                                                                      }
                                                                      placeholder={`Link this bug to ${intType}`}
                                                                    />
                                                                  </div>
                                                                )}
                                                              </div>
                                                            </div>
                                                          </>
                                                        )}

                                                      {this.state
                                                        .createIssue && (
                                                        <>
                                                          <div
                                                            className="form-group"
                                                            style={{
                                                              marginLeft:
                                                                "1rem",
                                                              marginTop: "2rem",
                                                            }}
                                                          >
                                                            <label
                                                              for="example-description-input"
                                                              className="pading-right-0px col-sm-3 col-form-label input-label"
                                                              style={{
                                                                width: "100%",
                                                              }}
                                                            >
                                                              Issue Title
                                                            </label>
                                                            <div className="col-sm-11">
                                                              <input
                                                                id="text"
                                                                className="form-control input-style-detail"
                                                                name="subTaskDescriptionInSideDrawer"
                                                                // ref={(el) => {
                                                                //   if (el) {
                                                                //     el.style.setProperty(
                                                                //       "font-size",
                                                                //       "13px",
                                                                //       "important"
                                                                //     );
                                                                //     el.style.setProperty(
                                                                //       "font-family",
                                                                //       "'Roboto', sans-serif",
                                                                //       "important"
                                                                //     );
                                                                //     el.style.setProperty(
                                                                //       "font-weight",
                                                                //       "400px",
                                                                //       "important"
                                                                //     );
                                                                //     el.style.setProperty(
                                                                //       "color",
                                                                //       "#64748B",
                                                                //       "important"
                                                                //     );
                                                                //     el.style.setProperty(
                                                                //       "height",
                                                                //       "40px",
                                                                //       "important"
                                                                //     );
                                                                //   }
                                                                // }}
                                                                onChange={(e) =>
                                                                  this.setState(
                                                                    {
                                                                      issueInputValue:
                                                                        e.target
                                                                          .value,
                                                                    }
                                                                  )
                                                                }
                                                                value={
                                                                  this.state
                                                                    .issueInputValue
                                                                }
                                                                autofocus
                                                                placeholder="Issue Title"
                                                              />
                                                            </div>
                                                          </div>
                                                          <div
                                                            className="form-group"
                                                            style={{
                                                              marginLeft:
                                                                "1rem",
                                                            }}
                                                          >
                                                            <label
                                                              for="example-description-input"
                                                              className="pading-right-0px col-sm-3 col-form-label input-label"
                                                              style={{
                                                                width: "100%",
                                                              }}
                                                            >
                                                              Issue Description
                                                            </label>
                                                            <div className="col-sm-11">
                                                              <textarea
                                                                id="textarea"
                                                                className="form-control input-style-detail"
                                                                name="subTaskDescriptionInSideDrawer"
                                                                onChange={(e) =>
                                                                  this.setState(
                                                                    {
                                                                      issueInputDesc:
                                                                        e.target
                                                                          .value,
                                                                    }
                                                                  )
                                                                }
                                                                value={
                                                                  this.state
                                                                    .issueInputDesc
                                                                }
                                                                maxlength="225"
                                                                rows="6"
                                                                placeholder="Issue Description"
                                                              ></textarea>
                                                            </div>
                                                          </div>
                                                        </>
                                                      )}
                                                      {item.field !== null &&
                                                        item.field.map(
                                                          (eNew, indexNew) => (
                                                            <div className="form-group row">
                                                              <label
                                                                key={indexNew}
                                                                for="example-id-input"
                                                                className="col-sm-3 col-form-label input-label"
                                                              >
                                                                {eNew.fileName}
                                                              </label>
                                                              <div className="col-sm-8 ml-3">
                                                                <input
                                                                  type="hidden"
                                                                  name="subTaskCustomFieldInSideDrawer"
                                                                  value={JSON.stringify(
                                                                    eNew
                                                                  )}
                                                                />
                                                                <input
                                                                  key={indexNew}
                                                                  className="form-control input-style-detail"
                                                                  type="text"
                                                                  name="subTaskCustomFieldInSideDrawer123"
                                                                  value={
                                                                    eNew.fileValue
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    this.onModelListTestDetailUpdate(
                                                                      eNew.fileName,
                                                                      index,
                                                                      i,
                                                                      item.subtaskId,
                                                                      e.target
                                                                        .value
                                                                    )
                                                                  }
                                                                  placeholder={
                                                                    eNew.fileName
                                                                  }
                                                                />
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      <div
                                                        className=" relative"
                                                        style={{
                                                          position: "fixed",
                                                          bottom: "0",
                                                          padding: 20,
                                                        }}
                                                      >
                                                        <div className="flex flex-row justify-between !w-[500px] ">
                                                          <div />
                                                          <div className=" flex flex-row !items-center justify-between gap-x-4">
                                                            {!this.state
                                                              .createIssue && (
                                                              <>
                                                                <Button
                                                                  className="rounded-lg flex flex-row !items-center justify-center gap-x-2  h-[44px] w-[146px] primary_btn font-poppins text-white !font-[600] hover:!text-white !border-none btn-secondary-"
                                                                  type="submit"
                                                                  onClick={(
                                                                    e
                                                                  ) => {
                                                                    e.preventDefault();
                                                                    this.pushIntegration(
                                                                      item.subtaskResultId,
                                                                      true
                                                                    );
                                                                    this.onClickCloseDetail(
                                                                      index +
                                                                        "-" +
                                                                        i
                                                                    );
                                                                  }}
                                                                >
                                                                  <svg
                                                                    width="16"
                                                                    height="12"
                                                                    viewBox="0 0 16 12"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                  >
                                                                    <path
                                                                      fill-rule="evenodd"
                                                                      clip-rule="evenodd"
                                                                      d="M15.2703 0.758767C15.5876 1.09212 15.5746 1.6196 15.2412 1.93692L5.61162 11.1036C5.44717 11.2601 5.22597 11.3428 4.99916 11.3325C4.77236 11.3222 4.55958 11.2197 4.41003 11.0489L0.706328 6.81814C0.403177 6.47184 0.43815 5.94537 0.784441 5.64222C1.13073 5.33907 1.65721 5.37404 1.96036 5.72033L5.09178 9.29737L14.0921 0.729749C14.4255 0.412424 14.9529 0.425416 15.2703 0.758767Z"
                                                                      fill="white"
                                                                    />
                                                                  </svg>
                                                                  Update
                                                                </Button>
                                                              </>
                                                            )}
                                                            {intType !== "" &&
                                                              !item?.integration &&
                                                              !this.state
                                                                .createIssue && (
                                                                <>
                                                                  <Button
                                                                    className="rounded-lg !flex !flex-row !items-center justify-center gap-x-2 py-[6px] h-[44px] !w-[150px] bg-red-200 .font-poppins"
                                                                    // style={{border: '1px solid #316BFF', display: 'flex',
                                                                    //   justifyContent: 'center',
                                                                    //   alignItems: 'center',
                                                                    //   gap: '0.3rem'}}
                                                                    // color="btn btn-third"
                                                                    type="button"
                                                                    onClick={() => {
                                                                      this.setState(
                                                                        {
                                                                          createIssue: true,
                                                                          issueInputDesc: `testCase ID: ${item.subid} \nDescription: ${item.description}`,
                                                                        },
                                                                        () => {
                                                                          // Scroll down by a fixed amount
                                                                          if (
                                                                            this
                                                                              .sidebarRef
                                                                              .current
                                                                          ) {
                                                                            this.sidebarRef.current.scrollBy(
                                                                              {
                                                                                top: 200, // Scroll down by 200 pixels (adjust as needed)
                                                                                behavior:
                                                                                  "smooth",
                                                                              }
                                                                            );
                                                                          }
                                                                        }
                                                                      );
                                                                    }}
                                                                  >
                                                                    <svg
                                                                      width="13"
                                                                      height="14"
                                                                      viewBox="0 0 13 14"
                                                                      fill="none"
                                                                      xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                      <path
                                                                        d="M6.5 12.335V1.66501"
                                                                        stroke="#316BFF"
                                                                        stroke-width="2"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                      />
                                                                      <path
                                                                        d="M1.16504 7H11.835"
                                                                        stroke="#316BFF"
                                                                        stroke-width="2"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                      />
                                                                    </svg>
                                                                    Create
                                                                    Issues
                                                                  </Button>
                                                                </>
                                                              )}
                                                            {intType !== "" &&
                                                              this.state
                                                                .createIssue && (
                                                                <>
                                                                  <Button
                                                                    className="rounded-lg flex flex-row items-center !bg-[#E7EAEE] !text-[#64748B] hover:!text-[#64748B] hover:!bg-[#e9eaec] !font-[600]  justify-center gap-x-2 px-[8px] py-[6px] h-[44px] w-[116px] .font-poppins !border-none"
                                                                    // color="btn btn-primary"
                                                                    type="button"
                                                                    onClick={() => {
                                                                      this.setState(
                                                                        {
                                                                          createIssue: false,
                                                                        }
                                                                      );
                                                                    }}
                                                                  >
                                                                    Cancel
                                                                  </Button>

                                                                  <Button
                                                                    className="rounded-lg flex flex-row !items-center justify-center gap-x-2  h-[44px] w-[176px] primary_btn font-poppins text-white !font-[600] hover:!text-white !border-none"
                                                                    // color="btn btn-primary"
                                                                    type="button"
                                                                    onClick={() => {
                                                                      if (
                                                                        !this
                                                                          .state
                                                                          .selectedOptIntegration
                                                                          ?.label &&
                                                                        this
                                                                          .state
                                                                          .issueInputValue ==
                                                                          ""
                                                                      ) {
                                                                        NotificationManager.error(
                                                                          "Select one Issue or create one!"
                                                                        );
                                                                        return;
                                                                      }
                                                                      this.pushIntegration(
                                                                        item.subtaskResultId,
                                                                        false
                                                                      );

                                                                      this.onClickCloseDetail(
                                                                        index +
                                                                          "-" +
                                                                          i
                                                                      );
                                                                    }}
                                                                  >
                                                                    <svg
                                                                      width="16"
                                                                      height="12"
                                                                      viewBox="0 0 16 12"
                                                                      fill="none"
                                                                      xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                      <path
                                                                        fill-rule="evenodd"
                                                                        clip-rule="evenodd"
                                                                        d="M15.2703 0.758767C15.5876 1.09212 15.5746 1.6196 15.2412 1.93692L5.61162 11.1036C5.44717 11.2601 5.22597 11.3428 4.99916 11.3325C4.77236 11.3222 4.55958 11.2197 4.41003 11.0489L0.706328 6.81814C0.403177 6.47184 0.43815 5.94537 0.784441 5.64222C1.13073 5.33907 1.65721 5.37404 1.96036 5.72033L5.09178 9.29737L14.0921 0.729749C14.4255 0.412424 14.9529 0.425416 15.2703 0.758767Z"
                                                                        fill="white"
                                                                      />
                                                                    </svg>
                                                                    Create
                                                                    Issues
                                                                  </Button>
                                                                </>
                                                              )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </form>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Scrollbars>
                                        </div>
                                      </div>
                                    )
                                  );
                                })}

                            {/* <div className="add-con">
                  {index == testCasesData.length - 1 && <ControlPoint style={{ fontSize: 20, color: '#263238' }} />}
                  {index == testCasesData.length - 1 && <p className="add" style={{ color: '#263238', marginRight: 10, cursor: 'pointer' }} onClick={() => this.onAdd('model', index)}>Add model</p>}
                </div> */}
                          </div>
                        );
                      })}

                    {this.onProjectTestrun()}
                    
                    {/* {this.onCustomTestrun()} */}
                  </div>
                </div>
              )}

              
            </div>
          </div>
        </div>
        {this.state.twoActionPopup && this.state.untestedCount != 0 && (
                
                <TwoActionPopup
                  label={`Untested Testcases`}
                  description={`There are ${this.state.untestedCount} untested
    testcases. Do you still want to submit
    (${this.state.curruntTestName})?`}
                  successText={"Submit Anyway"}
                  abortText={"Cancel"}
                  onSuccess={() => {
                    this.setState({ twoActionPopup: false });
                    this.saveChangesTestRun(false);
                  }}
                  onAbort={() => {
                    this.setState({ twoActionPopup: false });
                  }}
                />
              )}
              {this.state.twoActionPopup && this.state.untestedCount === 0 && (
                
                <TwoActionPopup
                  label={`Submit current test run?`}
                  description={`Are you sure you want submit this 
    (${this.state.curruntTestName}) test run? Once submitted, the result will be recorded, and no further changes can be made.`}
                  successText={"Submit Test Run"}
                  abortText={"Cancel"}
                  onSuccess={() => {
                    this.setState({ twoActionPopup: false });
                    this.saveChangesTestRun(false);
                  }}
                  successIcon={true}
                  height={285}
                  onAbort={() => {
                    this.setState({ twoActionPopup: false });
                  }}
                />
              )}

      </>
    );
  }
}

const IssuesTab = ({ projectId }) => {
  const wrapperRef = React.createRef();
  const [search, setSearch] = useState("");
  const [issuesData, setIssuesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const columnsStructure = [
    {
      field: "issue_id",
      headerName: "ID",
      width: 120,
      renderCell: (row) => (
        <p classname=" !text-[#64748B] monospace" style={{ color: "#64748B" }}>
          {row.issue_id}
        </p>
      ),
    },
    {
      field: "issue_name",
      headerName: "Issue Title",
      width: 300,
      enableSorting: false,
    },
    {
      field: "sub_id",
      headerName: "Test Case ID",
      width: 150,
      enableSorting: false,
    },

    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (row) => (
        <div className="flex items-center " title={row.type}>
          {(row.status === "OPEN" || row.status === "DONE") && (
            <span className="w-16 h-8 flex items-center justify-center text-primary-green bg-[#EBFFF4] px-2 py-1 rounded-md !font-[500]">
              {row.status}
            </span>
          )}
          {row.status !== "OPEN" && (
            <span className=" bg-gray-100 px-2 py-1 rounded-md text-[12px] font-[400] text-center font-poppins w-16 h-8 flex items-center justify-center opacity-70">
              {row.status ? row.status : "Unknown"}
            </span>
          )}
        </div>
      ),
    },
    {
      field: "createdBy",
      headerName: "Created By",
      width: 140,
      renderCell: (row) => (
        <div className="flex flex-row gap-x-2 items-center">
          <img
            // src={`https://api.dicebear.com/5.x/initials/svg?seed=${Promises.getName()}`}
            src={
              row.creator_img_url ||
              process.env.PUBLIC_URL + "/assets/images/default-img.png"
            }
            alt="user"
            className="rounded-circle w-6 h-6"
          />
          <p className="capitalize">{row.created_by_user_name}</p>
        </div>
      ),
    },
    {
      field: "created_at",
      headerName: "Created On",
      enableSorting: true,
      width: 180,
      // renderCell: (row) => {
      //   const date = new Date(row.createdAt);
      //   return formatDate(date);
      // },
    },

    {
      field: "actions",
      headerName: "",
      width: 120,
      renderCell: (row) => (
        <a
          target="_blank"
          rel="noreferrer"
          href={row.link}
          className="text-primary-blue flex items-center gap-x-3"
        >
          View Status{" "}
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 9.68978L9.33333 1.35645M9.33333 1.35645H3M9.33333 1.35645V7.35645"
              stroke="#316BFF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </a>
      ),
    },
  ];

  useEffect(() => {
    getIssues();
  }, [projectId]);

  const getIssues = async () => {
    setLoading(true);
    const token = Promises.getToken();

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    // console.log(requestOptions);

    fetch(`${BASE_URL}projects/integration/issues/${projectId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("FETCHING RESULT ===========>", result);
        if (result.code === 401) {
          setLoading(false);
          localStorage.clear();
          history.push("/login?redirect=1");
        }

        const data = result.issues;
        setIssuesData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
        setIssuesData([]);
      });
  };

  return (
    <div className=" w-full h-full">
      <div style={{
                    position: "sticky",
                    top: 0,
                    right: 0,
                    left: 0,
                    backgroundColor: "#FFFFFF",
                    paddingTop: 20,
                    zIndex: 99,
                    borderBottom: '1px solid #eeeeee',
                  }}>
      <div className="pt-2 pb-3 w-full ">
        <p className="primary_heading !text-[20px]">Project Issues</p>
        <div className="flex items-center justify-between mt-3 w-full">
          <div
            className="w-full"
            style={{
              display: "flex",
            }}
          >
            <div className="flex flex-row items-center justify-between !w-full mb-3">
              <div className="flex flex-row items-center gap-x-3">
                <div
                  className="flex items-center !p-0 !m-0"
                  style={{
                    position: "relative",
                  }}
                >
                  <input
                    autoComplete="off"
                    type="text"
                    className="form-control !h-[40px] !mt-0 !placeholder-[#316BFF]"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    style={{
                      border: "0.3px solid #316BFF",
                      borderRadius: 8,
                      height: 44,
                      fontSize: 12,
                      width: 260,
                      background: "rgba(146, 198, 255, 0.1)",
                    }}
                  />
                  <span
                    style={{
                      position: "absolute",
                      color: "#316BFF",
                      top: 12,
                      right: 12,
                    }}
                  >
                    <IoSearchOutline />
                  </span>
                </div>
              </div>
              <div className="flex flex-row gap-x-2 itmes-center justify-center">
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <button
                    type="button"
                    ref={wrapperRef}
                    onClick={() => {
                      // this.setState({
                      //   showFilter: !this.state.showFilter,
                      // })
                    }}
                    style={{
                      position: "relative",
                      width: 110,
                      background: "rgba(146, 198, 255, 0.1)",
                      borderRadius: 8,
                      height: 40,
                    }}
                    className="px-3 border rounded-md !flex !flex-row !items-center !justify-center gap-x-3 !text-[#316BFF] !border-[#316BFF] text-[12px] !font-[300] "
                  >
                    <svg
                      width="11"
                      height="10"
                      viewBox="0 0 11 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.15 4.11744C7.56459 4.11737 7.9681 3.97419 8.30054 3.70918C8.63299 3.44417 8.87663 3.07149 8.99525 2.64651H10.45C10.5959 2.64651 10.7358 2.58452 10.8389 2.47418C10.9421 2.36384 11 2.21418 11 2.05814C11 1.90209 10.9421 1.75244 10.8389 1.6421C10.7358 1.53175 10.5959 1.46977 10.45 1.46977H8.99525C8.87643 1.04501 8.63271 0.672578 8.30028 0.407795C7.96785 0.143012 7.56445 0 7.15 0C6.73555 0 6.33215 0.143012 5.99972 0.407795C5.66729 0.672578 5.42357 1.04501 5.30475 1.46977H0.55C0.404131 1.46977 0.264236 1.53175 0.161091 1.6421C0.0579462 1.75244 0 1.90209 0 2.05814C0 2.21418 0.0579462 2.36384 0.161091 2.47418C0.264236 2.58452 0.404131 2.64651 0.55 2.64651H5.30475C5.42337 3.07149 5.66701 3.44417 5.99946 3.70918C6.3319 3.97419 6.73541 4.11737 7.15 4.11744ZM0.55 7.35349C0.404131 7.35349 0.264236 7.41548 0.161091 7.52582C0.0579462 7.63616 0 7.78582 0 7.94186C0 8.09791 0.0579462 8.24756 0.161091 8.35791C0.264236 8.46825 0.404131 8.53023 0.55 8.53023H1.72975C1.84857 8.95499 2.09229 9.32742 2.42472 9.59221C2.75715 9.85699 3.16055 10 3.575 10C3.98945 10 4.39285 9.85699 4.72528 9.59221C5.05771 9.32742 5.30143 8.95499 5.42025 8.53023H10.45C10.5959 8.53023 10.7358 8.46825 10.8389 8.35791C10.9421 8.24756 11 8.09791 11 7.94186C11 7.78582 10.9421 7.63616 10.8389 7.52582C10.7358 7.41548 10.5959 7.35349 10.45 7.35349H5.42025C5.30143 6.92873 5.05771 6.5563 4.72528 6.29152C4.39285 6.02674 3.98945 5.88372 3.575 5.88372C3.16055 5.88372 2.75715 6.02674 2.42472 6.29152C2.09229 6.5563 1.84857 6.92873 1.72975 7.35349H0.55Z"
                        fill="#316BFF"
                      />
                    </svg>
                    Filter
                  </button>
                  {false && (
                    <div className="filter-dashboard">
                      <h5
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          padding: 16,
                          borderBottom: "1px solid #ECECEC",
                        }}
                      >
                        Select filter
                      </h5>
                      <div class="filter-div-properties">
                        <div className="filter-properties">
                          <p>Type</p>
                          <div className="d-flex flex-column">
                            <label htmlFor="automatedFilter">
                              <input
                                className="typeFilter"
                                type="checkbox"
                                value="Automated"
                                // checked={this.state.filterProps.includes(
                                //   "automated"
                                // )}
                                id="automatedFilter"
                                onChange={(e) =>
                                  this.onFilteredPropsItems(e.target.value)
                                }
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />
                              Automated
                            </label>

                            <label htmlFor="manualFilter">
                              <input
                                className="typeFilter"
                                type="checkbox"
                                value="Manual"
                                // checked={this.state.filterProps.includes(
                                //   "manual"
                                // )}
                                id="manualFilter"
                                onChange={(e) =>
                                  this.onFilteredPropsItems(e.target.value)
                                }
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Manual
                            </label>
                          </div>
                        </div>
                        <div className="filter-properties">
                          <p>Priority</p>
                          <div className="d-flex flex-column">
                            <label htmlFor="highFilter">
                              <input
                                className="priorityFilter"
                                type="checkbox"
                                value="High"
                                // checked={this.state.filterProps.includes(
                                //   "high"
                                // )}
                                id="highFilter"
                                // onChange={(e) =>
                                //   this.onFilteredPropsItems(
                                //     e.target.value
                                //   )
                                // }
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />
                              High
                            </label>
                            <label htmlFor="mediumFilter">
                              <input
                                className="priorityFilter"
                                type="checkbox"
                                value="Medium"
                                // checked={this.state.filterProps.includes(
                                //   "medium"
                                // )}
                                id="mediumFilter"
                                // onChange={(e) =>
                                //   this.onFilteredPropsItems(
                                //     e.target.value
                                //   )
                                // }
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Medium
                            </label>
                            <label htmlFor="lowFilter">
                              <input
                                className="priorityFilter"
                                type="checkbox"
                                value="Low"
                                // checked={this.state.filterProps.includes(
                                //   "low"
                                // )}
                                id="lowFilter"
                                // onChange={(e) =>
                                //   this.onFilteredPropsItems(
                                //     e.target.value
                                //   )
                                // }
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Low
                            </label>
                          </div>
                        </div>
                        <div className="filter-properties">
                          <p>Test Type</p>
                          <div className="d-flex flex-column">
                            <label htmlFor="functionalFilter">
                              <input
                                className="typeFilter"
                                type="checkbox"
                                value="Functional"
                                // checked={this.state.filterProps.includes(
                                //   "functional"
                                // )}
                                id="functionalFilter"
                                // onChange={(e) =>
                                //   this.onFilteredPropsItems(
                                //     e.target.value
                                //   )
                                // }
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />
                              Functional
                            </label>

                            <label htmlFor="unitFilter">
                              <input
                                className="typeFilter"
                                type="checkbox"
                                value="Unit"
                                // checked={this.state.filterProps.includes(
                                //   "unit"
                                // )}
                                id="unitFilter"
                                // onChange={(e) =>
                                //   this.onFilteredPropsItems(
                                //     e.target.value
                                //   )
                                // }
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Unit
                            </label>

                            <label htmlFor="securityFilter">
                              <input
                                className="typeFilter"
                                type="checkbox"
                                value="Security"
                                // checked={this.state.filterProps.includes(
                                //   "security"
                                // )}
                                id="securityFilter"
                                // onChange={(e) =>
                                //   this.onFilteredPropsItems(
                                //     e.target.value
                                //   )
                                // }
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Security
                            </label>

                            <label htmlFor="integrationFilter">
                              <input
                                className="typeFilter"
                                type="checkbox"
                                value="Integration"
                                // checked={this.state.filterProps.includes(
                                //   "integration"
                                // )}
                                id="integrationFilter"
                                // onChange={(e) =>
                                //   this.onFilteredPropsItems(
                                //     e.target.value
                                //   )
                                // }
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Integration
                            </label>

                            <label htmlFor="systemFilter">
                              <input
                                className="typeFilter"
                                type="checkbox"
                                value="System"
                                // checked={this.state.filterProps.includes(
                                //   "system"
                                // )}
                                id="systemFilter"
                                // onChange={(e) =>
                                //   this.onFilteredPropsItems(
                                //     e.target.value
                                //   )
                                // }
                                // checked={ele.isChecked}
                                // style={{
                                //   height: 16,
                                //   width: 16,
                                //   marginRight: 8,
                                // }}
                              />{" "}
                              System
                            </label>

                            <label htmlFor="acceptanceFilter">
                              <input
                                className="typeFilter"
                                type="checkbox"
                                value="Acceptance"
                                id="acceptanceFilter"
                                // checked={this.state.filterProps.includes(
                                //   "acceptance"
                                // )}
                                // onChange={(e) =>
                                //   this.onFilteredPropsItems(
                                //     e.target.value
                                //   )
                                // }
                                // checked={ele.isChecked}
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 8,
                                }}
                              />{" "}
                              Acceptance
                            </label>
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        class="btn btn-primary filter-btn"
                        // onClick={() =>
                        //   this.setState({ filterProps: [] }, () =>
                        //     console.log(this.state.filterProps)
                        //   )
                        // }
                      >
                        Clear
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div>
      <Table
        columns={columnsStructure}
        loading={loading}
        search={search.length ? true : false}
        data={
          issuesData.filter(
            (item) =>
              item.issue_id.toLowerCase().includes(search.toLowerCase()) ||
              item.sub_id.toLowerCase().includes(search.toLowerCase()) ||
              item.issue_name?.toLowerCase().includes(search.toLowerCase()) ||
              item.created_by_user_name
                .toLowerCase()
                .includes(search.toLowerCase())
          ) || []
        }
      />
      </div>

      {/* <Table
        columns={sampleColumns}
        data={
          issuesData.filter(
            (item) =>
              item.id.toLowerCase().includes(search.toLowerCase()) ||
              item.label.toLowerCase().includes(search.toLowerCase()) ||
              item.createdBy.toLowerCase().includes(search.toLowerCase())
          ) || []
        }
        search={search.length?true:false}
      /> */}
    </div>
  );
};

const sampleColumns = [
  {
    field: "id",
    headerName: "ID",
    width: 120,
    renderCell: (row) => (
      <p classname=" !text-[#64748B] monospace" style={{ color: "#64748B" }}>
        {row.id}
      </p>
    ),
  },
  {
    field: "label",
    headerName: "Issue Name",
    width: 300,
    enableSorting: false,
  },

  {
    field: "status",
    headerName: "Status",
    width: 100,
    renderCell: (row) => (
      <div className="flex items-center ">
        {row.status ? (
          <span className="w-16 h-8 flex items-center justify-center bg-gray-100  px-2 py-1 rounded-md !font-[500] font-poppins !text-[12px]">
            Open
          </span>
        ) : (
          <span className=" bg-gray-100 px-2 py-1 rounded-md text-[12px] font-[400] font-poppins w-16 h-8 flex items-center justify-center opacity-70">
            Closed
          </span>
        )}
      </div>
    ),
  },
  {
    field: "createdBy",
    headerName: "Created By",
    width: 220,
    renderCell: (row) => (
      <div className="flex flex-row gap-x-2 items-center">
        <img
          // src={`https://api.dicebear.com/5.x/initials/svg?seed=${Promises.getName()}`}
          src={
            row.creatorImgUrl ||
            process.env.PUBLIC_URL + "/assets/images/default-img.png"
          }
          alt="user"
          className="rounded-circle w-6 h-6"
        />
        <p className="capitalize">{row.createdBy}</p>
      </div>
    ),
  },
  {
    field: "createdAt",
    headerName: "Created On",
    enableSorting: true,
    width: 180,
    renderCell: (row) => {
      const date = new Date(row.createdAt);
      return formatDate(date);
    },
  },

  {
    field: "actions",
    headerName: "",
    width: 80,
    renderCell: (row) => (
      <a
        href="www.google.com"
        className="text-primary-blue flex items-center gap-x-3"
        target="_blank"
        rel="noreferrer"
      >
        View Status{" "}
        <svg
          width="11"
          height="11"
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 9.68978L9.33333 1.35645M9.33333 1.35645H3M9.33333 1.35645V7.35645"
            stroke="#316BFF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </a>
    ),
  },
];
const sampleData = [
  {
    id: "ISO-ISS-742",
    label: "This is a Demo Issue name",
    status: 0,
    createdAt: "2021-09-01",
    createdBy: "William Vangance",
    creatorUrl: null,
  },
  {
    id: "ISO-ISS-742",
    label: "This is a Demo demo name but name is not demo",
    status: 0,
    createdAt: "2021-09-01",
    createdBy: "Sasuke Uchiha",
    creatorUrl: null,
  },
  {
    id: "ISO-ISS-742",
    label: "This demo is name not is was before",
    status: 1,
    createdAt: "2021-09-01",
    createdBy: "Neeveet Rathore",
    creatorUrl: null,
  },
  {
    id: "ISO-ISS-742",
    label: "'Undefined' stack trace for webpack log",
    status: 1,
    createdAt: "2021-09-01",
    createdBy: "Md. Ashif Al Ansari",
    creatorUrl: null,
  },
  {
    id: "ISO-ISS-742",
    label: "This is a Demo Issue without any reason in JAVAC compiler",
    status: 0,
    createdAt: "2021-09-01",
    createdBy: "Jacob Charles",
    creatorUrl: null,
  },
];
