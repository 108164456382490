import React from "react";
import { ColorRing } from "react-loader-spinner";

const MainActionPopup = ({
  onSecondary,
  onSuccess,
  label,
  successText,
  successTick = false,
  secondaryText,
  isDangerPage = false,
  secondaryIsAbort = true,
  height,
  inputFieldLabel = "",
  inputFieldPlaceholder = "",
  inputFieldText,
  textAreaLabel = "",
  textAreaPlaceholder = "",
  onInputFieldChange,
  onTextareaChange,
  textareaText,
  successLoading = false,
  width = 600,
  bgOpacity = 0.1,
  isPromotion = false,
  promotionVisible = false,
  promotionOptions = [],
  textareaMaxLength = 500,
}) => {
  return (
    <div
      className="!h-[100%] !w-[100%] h-[105vh]- w-[100vw]- absolute top-0 left-0 flex justify-center items-center rounded-md"
      style={{
        zIndex: 999999999999 - 1,
        backgroundColor: `rgba(128, 128, 128, ${bgOpacity})`,
      }}
    >
      <div
        className={`bg-white shadow rounded-2xl  !opacity-100 px-12 py-12 flex flex-col justify-between overflow-clip- b`}
        style={{ height: height ? height : 260, width: width ? width : 600 }}
      >
        <div className="">
          <p
            className="text-[#101828] mb-2 font-raleway"
            style={{ fontWeight: 700, fontSize: 22 }}
          >
            {label}
          </p>
          <div className="flex flex-col gap-y-8 mt-6">
            {/* <p
              className="font-poppins text-[#667085] font-poppins text-justify"
              style={{ fontWeight: 400, fontSize: 14 }}
            ></p> */}
            {inputFieldLabel && (
              <div>
                <p className="input-label mb-[7px]">{inputFieldLabel}</p>
                <input
                  type="text"
                  className="border-none px-3 py-2 w-full"
                  placeholder={inputFieldPlaceholder}
                  value={inputFieldText}
                  onChange={onInputFieldChange}
                  style={{
                    border: "1px solid #D0D5DD",
                    borderRadius: 4,
                    fontWeight: 300,
                    fontSize: 14,
                  }}
                  maxLength={50}
                />
                <div className="flex flex-row items-center justify-between">
                  <div />
                  <p className="text-gray-300 text-xs">
                    {inputFieldText?.length}/50
                  </p>
                </div>
              </div>
            )}
            {textAreaLabel && (
              <div className="w-full">
                <p className="input-label mb-[7px]">{textAreaLabel}</p>
                <textarea
                  type="text"
                  className="border-none px-3 py-2 w-full !h-[100px] resize-none font-roboto"
                  placeholder={textAreaPlaceholder}
                  value={textareaText}
                  onChange={onTextareaChange}
                  style={{
                    border: "1px solid #D0D5DD",
                    borderRadius: 4,
                    fontWeight: 300,
                    fontSize: 14,
                  }}
                  maxLength={textareaMaxLength}
                />
                <div className="flex flex-row items-center justify-between">
                  <div />
                  <p className="text-gray-300 text-xs">
                    {textareaText?.length}/{textareaMaxLength}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center justify-between gap-x-3 ">
          <button
            className="rounded-md relative   h-11 flex items-center flex-grow- justify-center text-[#64748B]"
            style={{
              boxShadow: " 0px 1px 2px 0px #1018280D",
              fontSize: 14,
              fontWeight: 500,
              border: secondaryIsAbort ? "" : "0.5px solid #316BFF",
              borderRadius: 8,
              width: secondaryIsAbort ? 140 : 200,
              background: secondaryIsAbort ? "#F0F0F0" : "#92C6FF1A",
              color: secondaryIsAbort ? "#64748B" : "#316BFF",
            }}
            onClick={onSecondary}
          >
            <span className="flex gap-x-3 items-center">
              {isPromotion && (
                <span className={`${!promotionVisible?"-rotate-90":""}`}>
                <svg
                  width="11"
                  height="8"
                  viewBox="0 0 11 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.2937 1.67733L6.18077 6.83067C5.7906 7.302 5.18922 7.302 4.81518 6.83067L0.702278 1.67733C0.312109 1.18936 0.507193 0.815834 1.10857 0.815834H9.88737C10.5054 0.815834 10.6838 1.18988 10.2937 1.67733Z"
                    fill="#316BFF"
                  />
                </svg>
                </span>
              )}
              {secondaryText}
            </span>

            {promotionVisible && (
              <div
                className="absolute  left-0 bg-transparent !w-[100%]  border overflow-y-scroll"
                style={{ bottom: -85 }}
              >
                {promotionOptions.map((op) => (
                  <div
                    className="bg-white flex items-center  !text-black px-3 py-2 gap-x-2 hover:!bg-blue-100 !font-[400]"
                    onClick={ op.onClick}
                  >
                    {op.logo} {op.label}
                  </div>
                ))}
              </div>
            )}
          </button>
          {!secondaryIsAbort && <p className="mx-8">or</p>}

          <button
            className={`rounded-md border-none  h-11 w-44 text-white flex items-center justify-center gap-x-2 primary_btn flex-grow min-w-[180px]`}
            onClick={onSuccess}
            style={{
              borderRadius: 8,
              fontSize: 14,
              width: 163,
              //   backgroundColor: isDangerPage ? "#FF0003" : "#316BFF",
            }}
          >
            {successLoading ? (
              <ColorRing
                visible={true}
                height="30"
                width="96"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={[
                  "#ffffff",
                  "#ffffff",
                  "#ffffff",
                  "#ffffff",
                  "#ffffff",
                  "#ffffff",
                ]}
              />
            ) : (
              <div className="flex flex-row items-center gap-x-1">
                {successTick && (
                  <svg
                    width="15"
                    height="11"
                    viewBox="0 0 15 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.7703 0.258767C15.0876 0.592119 15.0746 1.1196 14.7412 1.43692L5.1116 10.6036C4.94715 10.7601 4.72596 10.8428 4.49915 10.8325C4.27234 10.8222 4.05957 10.7197 3.91002 10.5489L0.206313 6.31814C-0.0968379 5.97184 -0.0618655 5.44537 0.284426 5.14222C0.630718 4.83907 1.15719 4.87404 1.46035 5.22033L4.59176 8.79737L13.5921 0.229749C13.9254 -0.0875763 14.4529 -0.0745843 14.7703 0.258767Z"
                      fill="white"
                    />
                  </svg>
                )}
                {successText}
              </div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MainActionPopup;
