import React, { Component, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { TiAttachment } from "react-icons/ti";
import { FaCommentMedical, FaReply } from "react-icons/fa";
import { NotificationManager } from "react-notifications";
import { history } from "../_helpers/history";
import Promises, { getSubdomain, isValidToken } from "../_helpers/utils";
import { BASE_URL, LOGO_FULL_URL } from "../_helpers/constants";
import { getFullUrl, getShareId, organizationName } from "../utils/url";
import moment from "moment";
import { FooterSection, LandingNav } from "./Pages_landing";
import Navbar from "../_components/Navbar/Navbar";
import { MdArrowRight, MdOutlineArrowDropDown } from "react-icons/md";
import * as _ from "underscore";
import Scrollbars from "react-custom-scrollbars";
import Select from "react-select";
import { Tooltip } from "@material-ui/core";
import { Button } from "reactstrap";

import { IoSearchOutline } from "react-icons/io5";
export default class Pages_ShareUrl extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      testCasesData: [],
      newInputSubtask: {
        title: "",
        taskId: null,
        taskIndex: -1,
      },
      dynamicStyle: [],
      shareId: "",
      shareUrlname: "",
      sharedAttachments: null,
      isUserLoggedIn: false,
      dropdownOpenprofile: false,
      isCommentMode: false,
      selection: {
        text: "",
        dataType: null,
        subTaskId: null,
        taskId: null,
        descriptionId: null,
        startOffset: 0,
        endOffset: 0,
      },
      viewType: "testcase",
      canComment: true,
      comments: { testcase: [], description: [], diagram: [], pdf: [] },
      highlightedSpans: [], //highlighted spans
      workspaceUsers: [],
      showUsersList: false,
      searchUserText: "",
      commentText: "",
      taggedUsers: [],
      isThread: false,
      currentThreadId: "",
      dataType: "",
      imageComment: {
        commentText: "",
        selectedArea: null,
      },
      testType: [
        {
          value: "functional",
          label: "Functional",
        },
        {
          value: "unit",
          label: "Unit",
        },
        {
          value: "security",
          label: "Security",
        },
        {
          value: "integration",
          label: "Integration",
        },
        {
          value: "system",
          label: "System",
        },
        {
          value: "acceptance",
          label: "Acceptance",
        },
      ],
    };
  }

  onClickCloseDetail = (i) => {
    // this.saveChanges();
    let { dynamicStyle } = this.state;
    dynamicStyle[i] = "0%";
    this.setState({
      dynamicStyle: dynamicStyle,
    });
  };
  onClickDetail = async (i, subTaskId) => {
    console.log("+++> ", subTaskId);
    let { dynamicStyle } = this.state;
    dynamicStyle[i] = "40%";
    this.setState({
      dynamicStyle: dynamicStyle,
    });

    this.getTestcaseHistory(subTaskId);
  };
  getTestcaseHistory = (subTaskId) => {
    this.setState({ testHistory: [] });
    // console.log(subTaskId);

    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BASE_URL}task/subtask/result/${subTaskId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        // console.log(result);
        // result.map((data) => console.log(data));
        this.setState({ testHistory: result }, () => {
          console.log(this.state.testHistory);
        });
        // NotificationManager.success("Field updated successfully");
        // this.getTestCasesData('')
      })
      .catch((error) => {
        // console.log("error", error);
        NotificationManager.error(
          "Something went wrong with fetching your data, This may happen due to internal server error, Please try Again"
        );
        // localStorage.clear();
        // history.push("/login?redirect=1");
      });
  };

  getUsername = (name) => {
    if (name && name !== "") {
      return name;
    } else {
      return localStorage.getItem("name");
    }
  };
  addNewSubTask = async (taskId, taskIndex, subtaskData) => {
    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    const raw = {
      projectid: this.state.projectId,
      taskid: taskId,
      title: subtaskData.title,
      details: {
        projectid: this.state.projectId,
        taskid: taskId,
        title: subtaskData.title,
      },
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    fetch(`${BASE_URL}task/subtask/`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const updatedData = [...this.state.filteredTestcasesData];
        updatedData[taskIndex].expanded = true;
        const newSubTask = {
          ...result.newSubTask[0],
        };
        newSubTask.subTaskTitle = newSubTask.title;
        newSubTask.subtaskId = newSubTask.id;

        updatedData[taskIndex].lists.push(newSubTask);
        // if (result.data) {
        this.setState({
          testCasesData: updatedData,
          newInputSubtask: {
            title: "",
            taskId: null,
            taskIndex: -1,
          },
        });
        // }
      })
      .catch((error) => {
        console.log("error", error);
        NotificationManager.error(
          "Something went wrong with saving your data, This may happen due to internal server error, Please try Again"
        );
      });
  };

  getTestcaseComments = async () => {
    const token = Promises.getToken();
    const shareId = await getShareId();

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    // console.log(requestOptions);

    fetch(
      `${BASE_URL}projects/snapshot/${shareId}/comment/testcase`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("FETCHING RESULT ===========>", result);
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }

        const data = result.data;
        this.setState((prev) => ({
          comments: { ...prev.comments, testcase: data },
        }));
      })
      .catch((error) => {
        console.log("error", error);
        this.setState((prev) => ({
          comments: { ...prev.comments, testcase: [] },
        }));
      });
  };

  getImageComments = async () => {
    const token = Promises.getToken();
    const shareId = await getShareId();

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    // console.log(requestOptions);

    fetch(
      `${BASE_URL}projects/snapshot/${shareId}/comment/description`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("FETCHING RESULT ===========>", result);
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }

        const data = result.data;
        this.setState((prev) => ({
          comments: { ...prev.comments, description: data },
        }));
      })
      .catch((error) => {
        console.log("error", error);
        this.setState((prev) => ({
          comments: { ...prev.comments, description: [] },
        }));
      });
  };
  getDescriptionComments = async () => {
    const token = Promises.getToken();
    const shareId = await getShareId();

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    // console.log(requestOptions);

    fetch(
      `${BASE_URL}projects/snapshot/${shareId}/comment/image`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("FETCHING RESULT ===========>", result);
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }

        const data = result.data;
        this.setState((prev) => ({
          comments: { ...prev.comments, diagram: data },
        }));
      })
      .catch((error) => {
        console.log("error", error);
        this.setState((prev) => ({
          comments: { ...prev.comments, diagram: [] },
        }));
      });
  };
  handleTestThreadClick = (start, end, subtaskId, taskId, threadId) => {
    console.log("thread clicked", start, end, subtaskId, taskId, threadId);
    if (!threadId) return;
    const span = document.querySelector(`span[threadId="${threadId}"]`);

    span?.classList.add("blink-bg");
    span?.scrollIntoView({ behavior: "smooth", block: "center" });
    setTimeout(() => {
      span.classList.remove("blink-bg");
    }, 1000);

    this.setState({
      isThread: true,
      currentThreadId: threadId,
      dataType: "testcase",
    });
  };
  handleDescThreadClick = async (start, end, attachmentId, threadId) => {
    console.log("thread clicked", start, end, attachmentId, threadId);
    if (!threadId) return;
    const span = document.querySelector(`span[descThreadId="${threadId}"]`);

    span?.classList.add("blink-bg");
    span?.scrollIntoView({ behavior: "smooth", block: "center" });
    setTimeout(() => {
      span.classList.remove("blink-bg");
    }, 1000);

    await this.changeThread(threadId, "description");
  };
  handleImageThreadClick = async (x, y, attachmentId, threadId) => {
    console.log("thread clicked", x, y, attachmentId, threadId);
    if (!threadId) return;
    const div = document.querySelector(`div[imageThreadId="${threadId}"]`);
    console.log("found image thread", div);
    div?.classList.add("blink-bg");
    div?.scrollIntoView({ behavior: "smooth", block: "center" });
    setTimeout(() => {
      div.classList.remove("blink-bg");
    }, 1000);

    await this.changeThread(threadId, "image");
  };

  changeThread = async (threadId, type) => {
    this.setState({
      isThread: true,
      currentThreadId: threadId,
      dataType: type,
    });
  };

  formatTestcaseParagraph = (paragraph) => {
    if (!paragraph) return;
    // console.log("recieved paragrph for format", paragraph);
    const formattedText = [];
    let currentOffset = 0; // Track current offset within the paragraph

    // Sort subtasks by startOffset for efficient processing
    const sortedSubtasks = this.state.comments.testcase
      .filter((subtask) => subtask.subtaskId === paragraph.subtaskId)
      .sort((a, b) => a.startOffset - b.startOffset);

    sortedSubtasks.forEach((subtask) => {
      // const start = subtask.startOffset;
      // const end = subtask.endOffset;
      // const taskId=subtask.taskId;
      const {
        startOffset: start,
        endOffset: end,
        taskId,
        subtaskId,
        id: threadId,
      } = subtask;
      // Handle text before the first subtask
      if (start > currentOffset) {
        formattedText.push(paragraph.text.slice(currentOffset, start));
      }

      // Handle highlighted section
      formattedText.push(
        <span
          key={threadId}
          threadId={threadId}
          className="bg-yellow-300  cursor-pointer"
          onClick={() =>
            this.handleTestThreadClick(start, end, taskId, subtaskId, threadId)
          }
        >
          {paragraph.text.slice(start, end)}
        </span>
      );

      currentOffset = end; // Update current offset after processing the subtask
    });

    // Handle text after the last subtask (if any)
    if (currentOffset < paragraph.text.length) {
      formattedText.push(paragraph.text.slice(currentOffset));
    }

    // If no subtasks match the paragraph's subtaskID, return the plain paragraph
    if (formattedText.length === 0) {
      return (
        <p
          key={paragraph.subtaskId}
          className="list-input"
          name="list"
          data-type="testcase"
          data-sub-id={paragraph.subtaskId}
          data-task-id={paragraph.taskId}
        >
          {paragraph.text}
        </p>
      );
    }

    return (
      <p
        key={paragraph.subtaskId}
        className="list-input"
        name="list"
        data-type="testcase"
        data-sub-id={paragraph.subtaskId}
        data-task-id={paragraph.taskId}
      >
        {formattedText}
      </p>
    );
  };

  getWorkspaceUsers = () => {
    const token = Promises.getToken();
    if (!token) {
      console.log("Token not available");
      // history.push("/login?redirect=1");
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      fetch(`${BASE_URL}projects/workspace/users`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          // console.log("users from organization", result);
          this.setState({ workspaceUsers: result.data });
        });
    } catch (error) {
      console.log("error getting users of the organization", error);
    }
  };

  callCommentThreadCreate = async () => {
    if (this.state.selection.dataType === "testcase") {
      this.handleNewTestcaseCommentThreadCreate();
    } else if (this.state.selection.dataType === "description") {
      this.handleNewDescriptionCommentThreadCreate();
    }
  };
  handleNewTestcaseCommentThreadCreate = async () => {
    const token = Promises.getToken();

    if (!token) {
      NotificationManager.error("Please login to comment");
      history.push({
        pathname: "/login",
        search: "?redirect=1",
      });
      return;
    }
    const shareId = await getShareId();

    var myHeaders = new Headers();

    if (!token) {
      NotificationManager.error("Please login to comment");
      history.push({
        pathname: "/login",
        search: "?redirect=1",
      });
      return;
    }

    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    const raw = {
      commentText: this.state.commentText,
      taggedUsers: this.state.taggedUsers,
      startOffset: this.state.selection.startOffset,
      endOffset: this.state.selection.endOffset,
      taskId: this.state.selection.taskId,
      subtaskId: this.state.selection.subTaskId,
      url: getFullUrl(),
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(raw),
    };

    try {
      fetch(
        `${BASE_URL}projects/snapshot/${shareId}/comment/testcase/create-thread`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log("new created thread", result.data);
          this.setState(
            (prev) => ({
              commentText: "",
              taggedUsers: [],
              isCommentMode: false,
              canComment: true,
              selection: {
                text: "",
                dataType: null,
                subTaskId: null,
                taskId: null,
                descriptionId: null,
                startOffset: 0,
                endOffset: 0,
              },
              threadId: "",
              isThread: false,
              comments: {
                ...prev.comments,
                testcase: [...prev.comments.testcase, result.data],
              },
            }),
            () => {
              this.removeHighlights();
              // this.convertHighlightToPermanent(result.data);
              NotificationManager.success("Comment added successfully");
            }
          );
        });
    } catch (error) {
      console.log("error posting comments ", error);
    }
  };

  handleNewDescriptionCommentThreadCreate = async () => {
    const token = Promises.getToken();
    const shareId = await getShareId();

    if (!token) {
      NotificationManager.error("Please login to comment");
      history.push({
        pathname: "/login",
        search: "?redirect=1",
      });
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    const raw = {
      commentText: this.state.commentText,
      taggedUsers: this.state.taggedUsers,
      startOffset: this.state.selection.startOffset,
      endOffset: this.state.selection.endOffset,
      attachmentId: this.state.selection.descriptionId,
      url: getFullUrl(),
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(raw),
    };

    try {
      fetch(
        `${BASE_URL}projects/snapshot/${shareId}/comment/description/create-thread`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log("new desc created thread", result.data);
          this.setState(
            (prev) => ({
              commentText: "",
              taggedUsers: [],
              isCommentMode: false,
              canComment: true,
              selection: {
                text: "",
                dataType: null,
                subTaskId: null,
                taskId: null,
                descriptionId: null,
                startOffset: 0,
                endOffset: 0,
              },
              threadId: "",
              isThread: false,
              comments: {
                ...prev.comments,
                description: [...prev.comments.description, result.data],
              },
            }),
            () => {
              this.removeHighlights(true);
              NotificationManager.success("Comment added successfully");
            }
          );
        });
    } catch (error) {
      console.log("error posting comments ", error);
    }
  };
  handleNewImageCommentThreadCreate = async (
    commentText,
    selectedArea,
    attachmentId
  ) => {
    const token = Promises.getToken();
    const shareId = await getShareId();

    if (!token) {
      NotificationManager.error("Please login to comment");
      history.push({
        pathname: "/login",
        search: "?redirect=1",
      });
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    const raw = {
      commentText: commentText,
      taggedUsers: this.state.taggedUsers,
      xCord: selectedArea.x,
      yCord: selectedArea.y,
      attachmentId: attachmentId,
      url: getFullUrl(),
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(raw),
    };

    try {
      fetch(
        `${BASE_URL}projects/snapshot/${shareId}/comment/image/create-thread`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log("new image created thread", result.data);
          //handle update of comments of image
          this.setState((prev) => ({
            comments: {
              ...prev.comments,
              diagram: [...prev.comments.diagram, result.data],
            },
          }));
        });
    } catch (error) {
      console.log("error posting comments ", error);
    }
  };

  toggleShowUsersMenu = async (value = -1) => {
    if (value === -1) {
      value = !this.state.showUsersList;
    }
    this.setState({ showUsersList: value });
  };

  handleTagButtonPress = async () => {
    this.setState((prev) => ({
      commentText: prev.commentText + " @",
      showUsersList: true,
    }));
  };

  handleCommentTextChange = (event) => {
    const newText = event.target.value;
    this.setState({ commentText: newText });

    const atIndex = newText.lastIndexOf("@");
    // console.log("last index of @", atIndex);
    if (atIndex !== -1) {
      const searchTerm = newText.slice(atIndex + 1);
      this.setState({ searchUserText: searchTerm, showUsersList: true });
      // setShowUserList(true);
    } else {
      this.setState({ showUsersList: false });
    }
  };

  handleSelectUser = (user) => {
    if (!user?.userId) return;

    // const span = document.createElement("span");
    // span.textContent='@'+user.name;
    // span.classList.add("text-blue-500")
    // span.classList.add("font-italic")
    // span.classList.add("font-semibold")
    // span.classList.add("mx-2")

    const textArea = document.getElementById("comment-box");
    const atIndex = this.state.commentText.lastIndexOf("@");
    const newText = this.state.commentText.slice(0, atIndex);
    this.setState(
      {
        commentText: newText + " @" + user.name,
        showUsersList: false,
        taggedUsers: [...this.state.taggedUsers, user.userId],
        searchUserText: "",
      },
      () => {
        // textArea.appendChild(span)
      }
    );
  };
  handleRemoveUser = (user) => {
    if (!user?.userId) return;

    this.setState({
      showUsersList: false,
      taggedUsers: this.state.taggedUsers.filter((u) => u.value !== user.value),
    });
  };

  handleSelection = () => {
    // console.log("Selection su task id",this.state.selection.subTaskId)
    // console.log("Selection su task id",this.state.selection.subTaskId)
    if (!this.state.canComment) {
      console.log("currently cant comment", this.state.canComment);
      return;
    }
    if (!this.state.isCommentMode) {
      console.log("not comment mode", this.state.isCommentMode);
      return;
    }

    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const selectedElement =
        selection.anchorNode.parentElement.closest("[data-type]");
      if (selectedElement) {
        console.log("found item", selectedElement);
        const selectedText = selection.toString();
        const dataType = selectedElement.getAttribute("data-type");
        const dataSubId = selectedElement.getAttribute("data-sub-id");
        const dataTaskId = selectedElement.getAttribute("data-task-id");
        const descriptionId = selectedElement.getAttribute("descriptionId");

        const range = selection.getRangeAt(0);

        // Debugging logs
        // console.log("Selected Element:", selectedElement);
        // console.log("Range:", range);

        // Calculate the offsets relative to the selectedElement
        const startOffset = this.getOffsetRelativeToElement(
          selectedElement,
          range.startContainer,
          range.startOffset
        );
        const endOffset = this.getOffsetRelativeToElement(
          selectedElement,
          range.endContainer,
          range.endOffset
        );

        // console.log("Calculated Start Offset:", startOffset);
        // console.log("Calculated End Offset:", endOffset);

        this.setState(
          {
            selection: {
              text: selectedText,
              dataType: dataType,
              subTaskId: dataSubId,
              taskId: dataTaskId,
              descriptionId,
              startOffset,
              endOffset,
            },
          },
          () => {
            this.setState({ canComment: false });
            this.applyHighlight();
          }
        );
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { startOffset, endOffset } = this.state.selection;

    if (
      startOffset !== prevState.startOffset ||
      endOffset !== prevState.endOffset
    ) {
      this.applyHighlight();
    }
    if (
      this.state.isUserLoggedIn !== prevState.isUserLoggedIn &&
      this.state.isUserLoggedIn
    ) {
      this.getWorkspaceUsers();
    }
  }
  getOffsetRelativeToElement = (element, container, offset) => {
    let totalOffset = 0;
    let node = container;

    while (node && node !== element) {
      if (node.previousSibling) {
        node = node.previousSibling;
        totalOffset += node.textContent.length;
      } else {
        node = node.parentNode;
      }
    }

    return totalOffset + offset;
  };

  applyHighlight = () => {
    const { subTaskId, descriptionId, startOffset, endOffset } =
      this.state.selection;
    console.log("subTaskId:", subTaskId);
    console.log("descriptionId from apply highlight", descriptionId);

    try {
      const paragraph =
        document.querySelector(`p[data-sub-id="${subTaskId}"]`) ||
        document.querySelector(`p[descriptionId="${descriptionId}"]`);
      // console.log("Found paragraph:", paragraph);

      if (!paragraph) {
        console.log(
          "No paragraph found with the specified subTaskId.",
          subTaskId
        );
        return;
      }

      // Ensure the paragraph has a text node
      const textNode = paragraph.firstChild;

      if (textNode && textNode.nodeType === Node.TEXT_NODE) {
        const paragraphTextLength = textNode.textContent.length;

        // console.log("Paragraph text length:", paragraphTextLength);
        // console.log("Start offset:", startOffset);
        // console.log("End offset:", endOffset);

        // Adjust offsets if necessary
        const safeStartOffset = Math.min(startOffset, paragraphTextLength);
        const safeEndOffset = Math.min(endOffset, paragraphTextLength);

        // console.log("Safe start offset:", safeStartOffset);
        // console.log("Safe end offset:", safeEndOffset);

        if (safeStartOffset < safeEndOffset) {
          const range = document.createRange();
          try {
            range.setStart(textNode, safeStartOffset);
            range.setEnd(textNode, safeEndOffset);

            const span = document.createElement("span");
            span.classList.add("bg-blue-400", "text-white");
            span.id = "temp-comment-part";
            range.surroundContents(span);

            // Store reference to the highlighted span
            this.setState((prevState) => ({
              highlightedSpans: [...prevState.highlightedSpans, span],
            }));
          } catch (error) {
            console.error("Error creating range:", error);
          }
        } else {
          console.error(
            "Invalid range: start offset is greater than or equal to end offset.",
            safeStartOffset,
            safeEndOffset
          );
        }
      } else {
        console.error("The paragraph does not contain a valid text node.");
      }
    } catch (error) {
      console.log("cant highlight", error);
    }
  };
  removeHighlights = (deleteSpan = false) => {
    const spans = document.querySelectorAll("#temp-comment-part");

    spans.forEach((span) => {
      const parent = span.parentNode;
      // Create a document fragment to hold the span's children
      // const grandparent = parent.parentNode;
      if (deleteSpan) {
        // grandparent.removeChild(parent);
        // parent.removeChild(span);
        // return;
      }
      const docFrag = document.createDocumentFragment();
      while (span.firstChild) {
        docFrag.appendChild(span.firstChild);
      }
      // Replace the span with the document fragment
      parent.replaceChild(docFrag, span);
    });

    // Merge all text nodes in the parent paragraph
    const paragraphs = document.querySelectorAll("p[data-sub-id]");
    paragraphs.forEach((paragraph) => {
      paragraph.normalize();
    });

    this.setState({ highlightedSpans: [] });
  };

  handleCancel = () => {
    this.setState(
      {
        isCommentMode: false,
        canComment: true,
        selection: {
          text: "",
          dataType: null,
          subTaskId: null,
          taskId: null,
          descriptionId: null,
          startOffset: 0,
          endOffset: 0,
        },
        commentText: "",
        searchUserText: "",
        isThread: false,
        currentThreadId: "",
      },
      () => {
        this.removeHighlights();
      }
    );

    // this.setState({ isCommentMode: false }, this.removeHighlights);
  };
  turonCommentMode = () => {
    this.setState({ isCommentMode: true });
  };

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };
  toggleprofile = () => {
    this.setState((prevState) => ({
      dropdownOpenprofile: !prevState.dropdownOpenprofile,
    }));
  };
  toggleCommentMode = (val = -1) => {
    if (val === -1) {
      val = !this.state.isCommentMode;
    }
    this.setState((prevState) => ({ isCommentMode: val }));
  };
  onLogout = () => {
    localStorage.clear();
    this.setState({ isUserLoggedIn: false });
  };

  componentDidMount = async () => {
    Promises.setPage("shareurl");
    document.addEventListener("mouseup", this.handleSelection);
    const token = await Promises.getToken();
    this.setState({ isUserLoggedIn: isValidToken(token) });
    let part = window.location.host.split(".");
    let host = part[0];
    console.log("==>" + host, this.props.match.params.shareid);
    if (
      host === "oyetest" ||
      host === "joslery" ||
      (host === "www" && (part[1] === "oyetest" || part[1] === "joslery")) ||
      host === "localhost:3000" ||
      host === "localhost:3001" ||
      host === "192"
    ) {
      console.log("throwing the user out pageshareurl 24", host);
      history.push("/dashboard");
    }
    if (!this.props.match.params.shareid) {
      // history.push("/error");
    }
    this.setState(
      {
        projectNameSort: localStorage.getItem("projectName")
          ? localStorage.getItem("projectName").substring(0, 2).toUpperCase()
          : "",
        // loading: true,
        projectId:
          this.state.testCasesData.length &&
          this.state.testCasesData[0].lists.length
            ? this.state.testCasesData[0].lists[0].projectId
            : null,
        projectLen: Promises.getLocalIteam("projectLen"),
      },
      () => {
        // this.getIntegrationData();
      }
    );
    this.getTestCasesData(this.props.match.params.shareid, host);
    if (process.env.REACT_APP_SHARE_URL_COMMENT_ENABLED === "true") {
      this.getTestcaseComments();
      this.getDescriptionComments();
      this.getImageComments();
    }
  };
  componentWillUnmount() {
    document.removeEventListener("mouseup", this.handleSelection);
  }

  getTestCasesData = (ShareId, subdomain) => {
    // console.log("FETCHING DATA ========>");
    const token = Promises.getToken();

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    // console.log(requestOptions);

    fetch(
      `${BASE_URL}projects/snapshot/${subdomain}/${ShareId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("FETCHING RESULT ===========>", result);
        if (result.code === 404) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/dashboard");
        }
        if (result.data.length === 0) {
          this.setState({ testCasesEmpty: true, loading: false });
          history.push("/error");
        } else {
          // console.log(result);
          this.setState({ shareUrlname: result.name });
          this.setState({ sharedAttachments: result.sharedAttachments });
          const data = result.data;
          // csvData = [];
          // csvData.push([
          //   "Section",
          //   "ID",
          //   "OS",
          //   "TestCase",
          //   "Type",
          //   "Status",
          //   "Browser",
          //   "Description",
          //   "Expected",
          //   "Username",
          // ]);
          // if (data) {
          //   data.forEach((a) => {
          // console.log("a data init" + JSON.stringify(a));
          //     if (a.lists) {
          //       a.lists.forEach((b) => {
          //         let barray = [];
          // console.log("barray init" + JSON.stringify(b));
          //         barray.push(a.taskTitle);
          //         let keyToImport = [
          //           "id",
          //           "os",
          //           "name",
          //           "type",
          //           "status",
          //           "browser",
          //           "summary",
          //           "testing",
          //           "username",
          //         ];
          //         keyToImport.forEach((c, index) => {
          //           // barray.push(b[c]);
          //           if (b.subtaskId && c === "id") {
          //             barray.push(b.subtaskId);
          //           } else if (b.os && c === "os") {
          //             barray.push(b.os);
          //           } else if (b.subTaskTitle && c === "name") {
          //             barray.push(b.subTaskTitle);
          //           } else if (b.browser && c === "browser") {
          //             barray.push(b.browser);
          //           } else if (b.description && c === "summary") {
          //             barray.push(b.description);
          //           } else if (b.testing && c === "testing") {
          //             barray.push(b.testing);
          //           } else if (b.username && c === "username") {
          //             barray.push(b.username);
          //           } else {
          //             barray.push(b[c]);
          //           }
          // console.log("barray " + c);
          //         });
          //         // console.log("barray " + barray);
          //         csvData.push(barray);
          //       });
          //     }
          //   });
          // }
          // Need to discuss
          // if (result.field && result.field.length > 0) {
          //   this.setState({ fildData: result.field });
          // }

          if (data.length === 0) {
            this.setState({ testCasesEmpty: true, loading: false });
            history.push("/error");
          } else {
            // const { testCasesData } = this.state;
            // const newData = testCasesData;
            // let obj = {};
            // testCasesData.map((item, index) => {
            //   // const list = item.lists;
            //   // list.push(obj);
            //   // newData[index].lists = list;
            //   obj.id = item.id;
            //   obj.model = item.taskTitle;
            //   obj.lists = item.lists;
            // });
            // console.log(obj);

            // ************************************* //
            this.setState(
              { testCasesEmpty: false, testCasesData: data, loading: false },
              () => {
                this.setState({
                  projectId:
                    this.state.testCasesData.length &&
                    this.state.testCasesData[0].lists.length
                      ? this.state.testCasesData[0].lists[0].projectId
                      : null,
                });
              }
            );
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ testCasesEmpty: true, loading: false });
        // localStorage.clear();
        // history.push("/login?redirect=1");
      });
  };

  selectCollapse = (ele, index) => {
    const { testCasesData } = this.state;
    const newData = testCasesData;
    newData[index].isCollapse = !ele.isCollapse;
    // console.log(ele);
    this.setState({ testCasesData: newData });
  };

  render() {
    const { testCasesData, projectNameSort, dynamicStyle } = this.state;
    return (
      <div className="custom-container home-page relative ">
        {this.state.isUserLoggedIn ? (
          <Navbar
            fixed={true}
            selectInvite={() => history.push("/dashboard")}
          />
        ) : (
          <LandingNav
            scrolled={true}
            bgColor="#fff"
            redirectAfterLogin={true}
          />
        )}

        <div className="flex flex-row px-3 relative bg-[#f9f9f9]">
          <section
            className=" test-case-body h-auto border-0 rounded-0  pt-32"
            style={{
              flexDirection: "column",
              paddingLeft: 15,
              paddingRight: 15,
              width:
                process.env.REACT_APP_SHARE_URL_COMMENT_ENABLED === "true"
                  ? "70%"
                  : "100%",
            }}
          >
            <div class="w-full flex flex-row items-center gap-x-8 border-b pb-2  mb-6 mx-2">
              <div
                class={` relative font-poppins text-[14px] !font-[400] cursor-pointer ${
                  this.state.viewType === "testcase" ? " custom-border " : ""
                } !pb-0`}
                onClick={() => {
                  this.setState({ viewType: "testcase" });
                }}
              >
                Test Cases
              </div>
              <div
                class={`relative font-poppins text-[14px] !font-[400] cursor-pointer ${
                  this.state.viewType === "context" ? " custom-border " : ""
                } !pb-0`}
                onClick={() => {
                  this.setState({ viewType: "context" });
                }}
              >
                Shared Context
              </div>
            </div>
            <div className="page-custom-container-">
              {this.state.viewType === "testcase" && (
                <div
                  className="main-test-case   border rounded-[8px]  my-4 px-3 py-1 !pt-3"
                  style={{
                    // backgroundColor: "red",
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  {process.env.REACT_APP_SHARE_URL_COMMENT_ENABLED ===
                    "true" && (
                    <p className="primary_heading !text-[23px] mb-2">
                      Test Plan - {this.state.shareUrlname}
                    </p>
                  )}
                  <div
                    className="d-flex justify-between "
                    style={{
                      flexDirection:
                        process.env.REACT_APP_SHARE_URL_COMMENT_ENABLED ===
                        "true"
                          ? "row"
                          : "row-reverse",
                    }}
                  >
                    <div className="flex flex-row gap-x-3 ">
                      <div
                        className="flex flex-row items-center gap-0 !pr-2"
                        style={{
                          position: "relative",
                          background: "rgba(146, 198, 255, 0.1)",
                          border: "0.3px solid #316BFF",
                          borderRadius: 8,
                          height: 40,
                          fontSize: 12,
                          width: 220,
                        }}
                      >
                        <input
                          autoComplete="off"
                          type="text"
                          className="form-control- !placeholder-[#316BFF] bg-transparent !border-none !py-0 !text-[14px] px-3"
                          placeholder="Search"
                          value={this.state.search}
                          onChange={(e) =>
                            this.setState({ search: e.target.value })
                          }
                        />

                        <IoSearchOutline size={22} color="#316BFF" />
                      </div>
                    </div>

                    <div
                      className="flex flex-row cursor-pointer items-center gap-x-2"
                      style={{
                        position: "relative",
                      }}
                    >
                      {process.env.REACT_APP_SHARE_URL_COMMENT_ENABLED ===
                      "true" ? (
                        <button
                          type="button"
                          style={{
                            position: "relative",
                            width: 110,
                            background: "rgba(146, 198, 255, 0.1)",
                            borderRadius: 8,
                          }}
                          className="px-3 border rounded-md !flex !flex-row !items-center !justify-center gap-x-3 !text-[#316BFF] !border-[#316BFF] h-full text-[12px] !font-[300]"
                        >
                          Comment
                        </button>
                      ) : (
                        <p className="primary_heading !text-[23px] ">
                          Test Plan - {this.state.shareUrlname}
                        </p>
                      )}
                    </div>
                  </div>

                  {testCasesData.length > 0 &&
                    testCasesData
                      .map((ele) => {
                        return {
                          ...ele,
                          lists: ele.lists.filter((el) => {
                            const searchMatch = el.subTaskTitle
                              ?.toString()
                              .toLowerCase()
                              .includes(this.state.search.toLowerCase());
                            return searchMatch;
                          }),
                        };
                      })
                      .map((ele, index) => {
                        return (
                          <div
                            className="tests !mt-6"
                            style={{
                              overflow: "scroll",
                              height: "70vh",
                            }}
                          >
                            <div
                              className="flex !bg-[#f8f8f8] "
                              style={{
                                borderBottom: "0.5px solid #D1D5DB",
                                marginBottom: 3,
                                backgroundColor: "#F8F8F8",
                              }}
                            >
                              <div className="w-0.5 px-0.5 py-3 bg-primary-green" />
                              <div className="model-input !bg-[#f8f8f8]  w-full">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flex: 1,
                                  }}
                                >
                                  {(
                                    ele.expanded === null ||
                                    ele.expanded === undefined
                                      ? true
                                      : ele.expanded
                                  ) ? (
                                    <MdOutlineArrowDropDown
                                      className="text-primary-green cursor-pointer"
                                      size={28}
                                      onClick={() => {
                                        ele.expanded = !ele.expanded;
                                        this.setState({
                                          testCasesData: testCasesData,
                                        });
                                      }}
                                    />
                                  ) : (
                                    <MdArrowRight
                                      className="text-black cursor-pointer"
                                      size={28}
                                      onClick={() => {
                                        ele.expanded = !ele.expanded;
                                        this.setState({
                                          testCasesData: testCasesData,
                                        });
                                      }}
                                    />
                                  )}

                                  <input
                                    autoComplete="off"
                                    type="text"
                                    placeholder="Add Task Title here"
                                    className="input1 !bg-[#f8f8f8]  w-full"
                                    name="model"
                                    value={
                                      typeof ele.model !== "string"
                                        ? ele.taskTitle
                                        : ele.model
                                    }
                                    onBlur={() => {
                                      if (ele.model.length > 0)
                                        this.updateTaskChanges(index);
                                      else
                                        this.onModelUpdate(
                                          { target: { value: ele.taskTitle } },
                                          index
                                        );
                                    }}
                                    onChange={(e) =>
                                      this.onModelUpdate(e, index)
                                    }
                                  />
                                  <div
                                    className="showTaskDeleteIcon"
                                    onClick={() => this.onDelete(index)}
                                  >
                                    {/* <i
                                  className="fa fa-trash"
                                  style={{
                                    marginLeft: "10px",
                                    fontSize: 16,
                                    color: "#263238",
                                    cursor: "pointer",
                                  }}
                                ></i> */}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/delete-icon.png"
                                      }
                                      alt="Delete Icon"
                                      style={{
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Div Goes Here */}
                            {ele.isCollapse ||
                              ele.lists.map((item, i, row) => {
                                // console.log(
                                // filterProps.includes(
                                //   item.testing.toString().toLowerCase()

                                // )

                                item.testing = item.testing || "manual";
                                item.integration =
                                  item.integration != null
                                    ? JSON.parse(
                                        JSON.stringify(item.integration)
                                      )
                                    : item.integration;
                                // console.log(item.integration);
                                let tmpInt = item.integration
                                  ? JSON.parse(item.integration)
                                  : item.integration;
                                let cg = tmpInt;
                                tmpInt = tmpInt
                                  ? _.get(tmpInt, "value")
                                  : tmpInt;

                                let intTmpType = cg ? _.get(cg, "type") : cg;

                                // console.log(
                                //   "item.integration",
                                //   tmpInt,
                                //   intTmpType,
                                //   item.integration
                                // );
                                if (intTmpType === "asana") {
                                  let project_key = cg
                                    ? _.get(cg, "project_key")
                                    : cg;
                                  //https://app.asana.com/0/1199525592925725/1203666857435636/f
                                  item.intiURL = `https://app.asana.com/0/${project_key}/${tmpInt}`;
                                }
                                if (intTmpType === "jira") {
                                  let domain = cg ? _.get(cg, "domain") : cg;
                                  //https://mkgareja.atlassian.net/browse/OYET-127
                                  item.intiURL = `https://${domain}.atlassian.net/browse/${tmpInt}`;
                                }
                                if (intTmpType === "trello") {
                                  item.intiURL = `https://trello.com/c/${tmpInt}`;
                                }

                                return (
                                  (ele.expanded === null ||
                                  ele.expanded === undefined
                                    ? true
                                    : ele.expanded) &&
                                  item.subTaskTitle && (
                                    <div
                                      className={`model-list qq pl-12 pr-4 ${
                                        item.subTaskTitle ? "" : " !bg-white"
                                      }`}
                                      // onMouseEnter={this.mouseEnter.bind(this)}
                                      // onMouseLeave={this.mouseLeave.bind(this)}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          flex: 1,
                                        }}
                                        className={`model-list-testsuite `}
                                      >
                                        <input
                                          type="text"
                                          autoComplete="off"
                                          placeholder="Add new Test case here"
                                          className={`list-input !placeholder-primary-green  ${
                                            item.classNameDependsOnCondtion
                                          } ${
                                            item.subTaskTitle
                                              ? " bg-transparent"
                                              : ""
                                          }`}
                                          name="list"
                                          value={
                                            item.subTaskTitle !== null
                                              ? item.subTaskTitle
                                              : ""
                                          }
                                          onBlur={
                                            (e) =>
                                              this.saveSubTaskChanges(
                                                e.currentTarget.value,
                                                ele.id,
                                                item,
                                                projectNameSort + index + i,
                                                i,
                                                index
                                              )
                                            // console.log(e.currentTarget.value)
                                          }
                                          onChange={(e) =>
                                            this.onModelListUpdate(
                                              ele.id,
                                              item.subtaskId,
                                              i,
                                              e.target.value
                                            )
                                          }
                                          onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                              this.addNewList(
                                                e.currentTarget.value,
                                                ele.id
                                              );
                                            }
                                          }}
                                        />
                                        {item.subTaskTitle ? (
                                          <span
                                            className="mr-8 pl-2 flex items-center open-details-button cursor-pointer"
                                            name=""
                                            onClick={() =>
                                              this.onClickDetail(
                                                index + "-" + i,
                                                item.subtaskId
                                              )
                                            }
                                          >
                                            <span className="details-button">
                                              Details
                                            </span>

                                            <svg
                                              className="details-svg"
                                              width="13"
                                              height="13"
                                              viewBox="0 0 13 13"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M6.83333 0.875488H6.25C3.35051 0.875488 1 3.22599 1 6.12549C1 9.025 3.35051 11.3755 6.25 11.3755H6.83333M9.16667 3.79215L11.5 6.12549M11.5 6.12549L9.16667 8.45882M11.5 6.12549H4.5"
                                                stroke="#999999"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                            </svg>
                                          </span>
                                        ) : (
                                          <span className="pr-[27px]" />
                                        )}
                                      </div>

                                      <div
                                        style={{
                                          width:
                                            dynamicStyle[index + "-" + i] ||
                                            dynamicStyle,
                                          zIndex: 99999999999,
                                        }}
                                        className="sidebar-new"
                                      >
                                        <div className="button-items detail-btn">
                                          <i
                                            className="mdi mdi-close btn-float-left"
                                            onClick={() =>
                                              this.onClickCloseDetail(
                                                index + "-" + i
                                              )
                                            }
                                          ></i>
                                        </div>
                                        <Scrollbars style={{ height: "85%" }}>
                                          {/* <a onClick={() => this.onClickCloseDetail()} className="closebtn">&times;</a> */}
                                          <div className="row test-row sidebar-nav-body">
                                            <div className="col-12">
                                              <div className="card m-b-20">
                                                <div className="card-body">
                                                  <form
                                                    onSubmit={(e) =>
                                                      this.onSubmitsubTaskDataInSideDrawer(
                                                        ele.id,
                                                        item.subtaskId,
                                                        e
                                                      )
                                                    }
                                                  >
                                                    <div className="form-group row">
                                                      <div className="col-sm-12 p-0 mr-6 mb-6">
                                                        <input
                                                          className="form-control test-header-title font-roboto"
                                                          type="text"
                                                          name="subTaskNameInSideDrawer"
                                                          style={{
                                                            marginBottom: 20,
                                                            marginTop: "auto",
                                                            paddingLeft: 0,
                                                          }}
                                                          ref={(el) => {
                                                            if (el) {
                                                              el.style.setProperty(
                                                                "font-size",
                                                                "20px",
                                                                "important"
                                                              );

                                                              el.style.setProperty(
                                                                "font-weight",
                                                                "700px",
                                                                "important"
                                                              );
                                                            }
                                                          }}
                                                          onChange={(e) =>
                                                            this.onModelListTestDetailUpdate(
                                                              "name",
                                                              index,
                                                              i,
                                                              item.subtaskId,
                                                              e.target.value
                                                            )
                                                          }
                                                          value={
                                                            item.subTaskTitle
                                                          }
                                                        />
                                                        <div className="flex flex-row items-cente gap-2 ">
                                                          <div className="flex flex-row items-center text-[14px] font-[400] gap-2 .font-roboto color-[#525252]">
                                                            {" "}
                                                            ID:{" "}
                                                            <span className=".font-poppins h-8 flex text-[14px] items-center justify-center text-primary-green bg-[#EBFFF4] px-2 py-1 rounded-md font-[500]">
                                                              {item.subid}
                                                            </span>
                                                          </div>
                                                          <div className="flex flex-row items-center text-[14px] font-[400] gap-2 .font-roboto color-[#525252]">
                                                            {" "}
                                                            TestSuite:{" "}
                                                            <span className=".font-poppins h-8 flex text-[14px] items-center justify-center text-primary-green bg-[#EBFFF4] px-2 py-1 rounded-md font-[500]">
                                                              {ele.model ||
                                                                ele.taskTitle}
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <hr></hr>
                                                    <div
                                                      className="form-group row"
                                                      style={{
                                                        marginTop: "2rem",
                                                      }}
                                                    >
                                                      <label
                                                        for="example-description-input"
                                                        className="pading-right-0px col-sm-3 col-form-label input-label"
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                      >
                                                        Description
                                                      </label>
                                                      <div className="col-sm-8 ml-3">
                                                        <textarea
                                                          id="textarea"
                                                          className="form-control input-style-detail"
                                                          name="subTaskDescriptionInSideDrawer"
                                                          // ref={(el) => {
                                                          //   if (el) {
                                                          //     el.style.setProperty(
                                                          //       "font-size",
                                                          //       "13px",
                                                          //       "important"
                                                          //     );
                                                          //     el.style.setProperty(
                                                          //       "font-family",
                                                          //       "'Roboto', sans-serif",
                                                          //       "important"
                                                          //     );
                                                          //     el.style.setProperty(
                                                          //       "font-weight",
                                                          //       "400px",
                                                          //       "important"
                                                          //     );
                                                          //     el.style.setProperty(
                                                          //       "color",
                                                          //       "#64748B",
                                                          //       "important"
                                                          //     );
                                                          //   }
                                                          // }}
                                                          onChange={(e) =>
                                                            this.onModelListTestDetailUpdate(
                                                              "description",
                                                              index,
                                                              i,
                                                              item.subtaskId,
                                                              e.target.value
                                                            )
                                                          }
                                                          value={
                                                            item.description
                                                          }
                                                          maxlength="225"
                                                          rows="6"
                                                          placeholder="Description and Steps to reproduce"
                                                        ></textarea>
                                                      </div>
                                                    </div>
                                                    <div className="form-group row">
                                                      <label
                                                        for="example-summary-input"
                                                        className="col-sm-3 col-form-label input-label"
                                                      >
                                                        Expected Result
                                                      </label>
                                                      <div className="col-sm-8 ml-3">
                                                        <textarea
                                                          id="textarea"
                                                          className="form-control input-style-detail"
                                                          name="subTaskSummaryInSideDrawer"
                                                          // ref={(el) => {
                                                          //   if (el) {
                                                          //     el.style.setProperty(
                                                          //       "font-size",
                                                          //       "13px",
                                                          //       "important"
                                                          //     );
                                                          //     el.style.setProperty(
                                                          //       "font-family",
                                                          //       "'Roboto', sans-serif",
                                                          //       "important"
                                                          //     );
                                                          //     el.style.setProperty(
                                                          //       "font-weight",
                                                          //       "400px",
                                                          //       "important"
                                                          //     );
                                                          //     el.style.setProperty(
                                                          //       "color",
                                                          //       "#64748B",
                                                          //       "important"
                                                          //     );
                                                          //   }
                                                          // }}
                                                          onChange={(e) =>
                                                            this.onModelListTestDetailUpdate(
                                                              "summary",
                                                              index,
                                                              i,
                                                              item.subtaskId,
                                                              e.target.value
                                                            )
                                                          }
                                                          value={item.summary}
                                                          maxlength="225"
                                                          rows="2"
                                                          placeholder="Summary"
                                                        ></textarea>
                                                      </div>
                                                    </div>
                                                    <div className="form-group row">
                                                      <label className="col-sm-3 col-form-label input-label">
                                                        Browser
                                                      </label>
                                                      <div className="col-sm-8 ml-3">
                                                        <select
                                                          className="form-control input-style-detail"
                                                          name="subTaskBrowserInSideDrawer"
                                                          value={item.browser}
                                                          // ref={(el) => {
                                                          //   if (el) {
                                                          //     el.style.setProperty(
                                                          //       "font-size",
                                                          //       "13px",
                                                          //       "important"
                                                          //     );
                                                          //     el.style.setProperty(
                                                          //       "font-family",
                                                          //       "'Roboto', sans-serif",
                                                          //       "important"
                                                          //     );
                                                          //     el.style.setProperty(
                                                          //       "font-weight",
                                                          //       "400px",
                                                          //       "important"
                                                          //     );
                                                          //     el.style.setProperty(
                                                          //       "color",
                                                          //       "#64748B",
                                                          //       "important"
                                                          //     );
                                                          //   }
                                                          // }}
                                                          onChange={(e) =>
                                                            this.onModelListTestDetailUpdate(
                                                              "browser",
                                                              index,
                                                              i,
                                                              item.subtaskId,
                                                              e.target.value
                                                            )
                                                          }
                                                        >
                                                          <option value="chrome">
                                                            Chrome
                                                          </option>
                                                          <option value="firefox">
                                                            Firefox
                                                          </option>
                                                          <option value="other">
                                                            Other
                                                          </option>
                                                        </select>
                                                      </div>
                                                    </div>

                                                    <div className="form-group row">
                                                      <label className="col-sm-3 col-form-label input-label">
                                                        OS
                                                      </label>
                                                      <div className="col-sm-8 ml-3">
                                                        <select
                                                          name="subTaskOsInSideDrawer"
                                                          value={item.os}
                                                          className="form-control input-style-detail"
                                                          onChange={(e) =>
                                                            this.onModelListTestDetailUpdate(
                                                              "os",
                                                              index,
                                                              i,
                                                              item.subtaskId,
                                                              e.target.value
                                                            )
                                                          }
                                                          // ref={(el) => {
                                                          //   if (el) {
                                                          //     el.style.setProperty(
                                                          //       "font-size",
                                                          //       "13px",
                                                          //       "important"
                                                          //     );
                                                          //     el.style.setProperty(
                                                          //       "font-family",
                                                          //       "'Roboto', sans-serif",
                                                          //       "important"
                                                          //     );
                                                          //     el.style.setProperty(
                                                          //       "font-weight",
                                                          //       "400px",
                                                          //       "important"
                                                          //     );
                                                          //     el.style.setProperty(
                                                          //       "color",
                                                          //       "#64748B",
                                                          //       "important"
                                                          //     );
                                                          //   }
                                                          // }}
                                                        >
                                                          <option value="mac">
                                                            Mac
                                                          </option>
                                                          <option value="windows">
                                                            Windows
                                                          </option>
                                                          <option value="other">
                                                            Other
                                                          </option>
                                                        </select>
                                                      </div>
                                                    </div>

                                                    <div className="form-group row">
                                                      <label className="col-sm-3 col-form-label input-label">
                                                        Type
                                                      </label>
                                                      <div className="col-sm-8 ml-3">
                                                        <select
                                                          name="subTaskTestingInSideDrawer"
                                                          value={item.testing}
                                                          className="form-control input-style-detail"
                                                          onChange={(e) =>
                                                            this.onModelListTestDetailUpdate(
                                                              "testing",
                                                              index,
                                                              i,
                                                              item.subtaskId,
                                                              e.target.value
                                                            )
                                                          }
                                                          // ref={(el) => {
                                                          //   if (el) {
                                                          //     el.style.setProperty(
                                                          //       "font-size",
                                                          //       "13px",
                                                          //       "important"
                                                          //     );
                                                          //     el.style.setProperty(
                                                          //       "font-family",
                                                          //       "'Roboto', sans-serif",
                                                          //       "important"
                                                          //     );
                                                          //     el.style.setProperty(
                                                          //       "font-weight",
                                                          //       "400px",
                                                          //       "important"
                                                          //     );
                                                          //     el.style.setProperty(
                                                          //       "color",
                                                          //       "#64748B",
                                                          //       "important"
                                                          //     );
                                                          //   }
                                                          // }}
                                                        >
                                                          <option
                                                            value=""
                                                            selected
                                                            disabled
                                                          >
                                                            Select
                                                          </option>
                                                          <option value="manual">
                                                            Manual
                                                          </option>
                                                          <option value="automated">
                                                            Automated
                                                          </option>
                                                        </select>
                                                      </div>
                                                    </div>
                                                    <div className="form-group row">
                                                      <label className="col-sm-3 col-form-label input-label">
                                                        Priority
                                                      </label>
                                                      <div className="col-sm-8 ml-3">
                                                        <select
                                                          name="subTaskpriorityInSideDrawer"
                                                          value={item.priority}
                                                          className="form-control input-style-detail"
                                                          onChange={(e) =>
                                                            this.onModelListTestDetailUpdate(
                                                              "priority",
                                                              index,
                                                              i,
                                                              item.subtaskId,
                                                              e.target.value
                                                            )
                                                          }
                                                          // ref={(el) => {
                                                          //   if (el) {
                                                          //     el.style.setProperty(
                                                          //       "font-size",
                                                          //       "13px",
                                                          //       "important"
                                                          //     );
                                                          //     el.style.setProperty(
                                                          //       "font-family",
                                                          //       "'Roboto', sans-serif",
                                                          //       "important"
                                                          //     );
                                                          //     el.style.setProperty(
                                                          //       "font-weight",
                                                          //       "400px",
                                                          //       "important"
                                                          //     );
                                                          //     el.style.setProperty(
                                                          //       "color",
                                                          //       "#64748B",
                                                          //       "important"
                                                          //     );
                                                          //   }
                                                          // }}
                                                        >
                                                          <option value="high">
                                                            High
                                                          </option>
                                                          <option
                                                            value="medium"
                                                            selected
                                                          >
                                                            Medium
                                                          </option>
                                                          <option value="low">
                                                            Low
                                                          </option>
                                                        </select>
                                                      </div>
                                                    </div>

                                                    <div className="form-group row">
                                                      <label
                                                        for="example-id-input "
                                                        className="col-sm-3 col-form-label input-label"
                                                      >
                                                        Created By
                                                      </label>
                                                      <div className="col-sm-8 ml-3">
                                                        <input
                                                          name="subTaskCreatedByInSideDrawer"
                                                          className="form-control input-style-detail"
                                                          type="text"
                                                          value={
                                                            (item.username =
                                                              this.getUsername(
                                                                item.username
                                                              ))
                                                          }
                                                          // ref={(el) => {
                                                          //   if (el) {
                                                          //     el.style.setProperty(
                                                          //       "font-size",
                                                          //       "13px",
                                                          //       "important"
                                                          //     );
                                                          //     el.style.setProperty(
                                                          //       "font-family",
                                                          //       "'Roboto', sans-serif",
                                                          //       "important"
                                                          //     );
                                                          //     el.style.setProperty(
                                                          //       "font-weight",
                                                          //       "400px",
                                                          //       "important"
                                                          //     );
                                                          //     el.style.setProperty(
                                                          //       "color",
                                                          //       "#5A5B5E",
                                                          //       "important"
                                                          //     );
                                                          //   }
                                                          // }}
                                                          placeholder="Created By"
                                                          disabled
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="form-group row">
                                                      <label
                                                        for="example-id-input "
                                                        className="col-sm-3 col-form-label input-label"
                                                      >
                                                        Test type
                                                      </label>
                                                      <div className="col-sm-8 ml-3">
                                                        <Select
                                                          className=" input-style-detail"
                                                          isMulti
                                                          isSearchable={false}
                                                          value={
                                                            item.test_type
                                                              ? this.state.testType.filter(
                                                                  ({ value }) =>
                                                                    item.test_type.indexOf(
                                                                      value
                                                                    ) !== -1
                                                                )
                                                              : this.state
                                                                  .testType[0]
                                                          }
                                                          onChange={
                                                            (e) =>
                                                              this.onModelListTestDetailUpdate(
                                                                "test_type",
                                                                index,
                                                                i,
                                                                item.subtaskId,
                                                                e.map(
                                                                  (x) => x.value
                                                                )
                                                              )
                                                            // console.log(
                                                            //   e.map(
                                                            //     (x) => x.value
                                                            //   )
                                                            // )
                                                          }
                                                          options={
                                                            this.state.testType
                                                          }
                                                          defaultValue={
                                                            this.state
                                                              .testType[0]
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="form-group row">
                                                      <label
                                                        for="example-description-input "
                                                        className="pading-right-0px col-sm-3 col-form-label input-label"
                                                      >
                                                        Last Executed{" "}
                                                      </label>
                                                      <div
                                                        className="col-sm-8 ml-3"
                                                        style={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                          justifyContent:
                                                            "flex-start",
                                                        }}
                                                      >
                                                        {this.state
                                                          .testHistory &&
                                                          this.state.testHistory.map(
                                                            (history) => {
                                                              return (
                                                                <Tooltip
                                                                  placement="top"
                                                                  title={
                                                                    history.testStatus +
                                                                    " " +
                                                                    history.createdAt
                                                                  }
                                                                  arrow
                                                                >
                                                                  <div
                                                                    style={{
                                                                      marginRight: 15,
                                                                      display:
                                                                        "flex",
                                                                      alignItems:
                                                                        "center",
                                                                      flexDirection:
                                                                        "column",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      classname="testcase-history"
                                                                      style={{
                                                                        width: 13,
                                                                        height: 13,
                                                                        borderRadius: 50,
                                                                        cursor:
                                                                          "pointer",
                                                                        backgroundColor:
                                                                          "#FFFFFF",
                                                                        color:
                                                                          "white",
                                                                      }}
                                                                    >
                                                                      {history.testStatus ===
                                                                        "untested" && (
                                                                        <>
                                                                          <svg
                                                                            width="13"
                                                                            height="13"
                                                                            viewBox="0 0 13 13"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                          >
                                                                            <path
                                                                              d="M6.5 0C2.89545 0 0 2.89545 0 6.5C0 10.1045 2.89545 13 6.5 13C10.1045 13 13 10.1045 13 6.5C13 2.89545 10.1045 0 6.5 0ZM10.4591 7.56364C10.4 7.8 10.1636 7.91818 9.92727 7.85909L6.61818 7.20909C6.5 7.20909 6.38182 7.09091 6.26364 7.03182C6.02727 6.91364 5.90909 6.73636 5.90909 6.5L6.02727 1.77273C6.02727 1.47727 6.26364 1.3 6.5 1.3C6.73636 1.3 6.97273 1.53636 6.97273 1.77273L7.03182 5.85L10.1045 6.91364C10.4 6.97273 10.5182 7.26818 10.4591 7.56364Z"
                                                                              fill="#FF9A28"
                                                                            />
                                                                          </svg>
                                                                        </>
                                                                      )}
                                                                      {history.testStatus ===
                                                                        "pass" && (
                                                                        <>
                                                                          <svg
                                                                            width="13"
                                                                            height="13"
                                                                            viewBox="0 0 13 13"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                          >
                                                                            <path
                                                                              fill-rule="evenodd"
                                                                              clip-rule="evenodd"
                                                                              d="M6.5 13C8.22391 13 9.87721 12.3152 11.0962 11.0962C12.3152 9.87721 13 8.22391 13 6.5C13 4.77609 12.3152 3.12279 11.0962 1.90381C9.87721 0.684819 8.22391 0 6.5 0C4.77609 0 3.12279 0.684819 1.90381 1.90381C0.684819 3.12279 0 4.77609 0 6.5C0 8.22391 0.684819 9.87721 1.90381 11.0962C3.12279 12.3152 4.77609 13 6.5 13ZM5.55286 9.165H4.89357L2.78571 7.05714L3.445 6.39786L5.22786 8.18071L9.12786 4.27143L9.78714 4.93071L5.55286 9.165Z"
                                                                              fill="#27AE60"
                                                                            />
                                                                          </svg>
                                                                        </>
                                                                      )}
                                                                      {history.testStatus ===
                                                                        "failed" && (
                                                                        <>
                                                                          <svg
                                                                            width="13"
                                                                            height="13"
                                                                            viewBox="0 0 13 13"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                          >
                                                                            <path
                                                                              fill-rule="evenodd"
                                                                              clip-rule="evenodd"
                                                                              d="M8.79815 8.2225C8.95699 8.38093 8.95699 8.64095 8.79815 8.79939C8.63971 8.95783 8.38216 8.95783 8.22331 8.79939L6.50243 7.07689L4.76937 8.81154C4.60931 8.96998 4.35014 8.96998 4.19007 8.81154C4.03042 8.64904 4.03042 8.38907 4.19007 8.23063L5.92313 6.49593L4.20225 4.7775C4.0434 4.61907 4.0434 4.35905 4.20225 4.20061C4.36028 4.04217 4.61784 4.04217 4.77669 4.20061L6.49757 5.92311L8.24362 4.17626C8.40368 4.01782 8.66246 4.01782 8.82252 4.17626C8.98218 4.33876 8.98218 4.59467 8.82252 4.75717L7.07687 6.50407L8.79815 8.2225ZM6.5 0C2.90997 0 0 2.90875 0 6.5C0 10.0913 2.90997 13 6.5 13C10.09 13 13 10.0913 13 6.5C13 2.90875 10.09 0 6.5 0Z"
                                                                              fill="#FF2727"
                                                                            />
                                                                          </svg>
                                                                        </>
                                                                      )}
                                                                    </div>
                                                                  </div>
                                                                </Tooltip>
                                                              );
                                                            }
                                                          )}
                                                      </div>
                                                    </div>

                                                    {item.field !== null &&
                                                      item.field.map(
                                                        (eNew, indexNew) => (
                                                          <div className="form-group row">
                                                            <label
                                                              key={indexNew}
                                                              for="example-id-input"
                                                              className="col-sm-3 col-form-label input-label"
                                                            >
                                                              {eNew.fileName}
                                                            </label>
                                                            <div className="col-sm-8 ml-3">
                                                              <input
                                                                type="hidden"
                                                                name="subTaskCustomFieldInSideDrawer"
                                                                value={JSON.stringify(
                                                                  eNew
                                                                )}
                                                              />
                                                              <input
                                                                key={indexNew}
                                                                className="form-control input-style-detail"
                                                                type="text"
                                                                name="subTaskCustomFieldInSideDrawer123"
                                                                value={
                                                                  eNew.fileValue
                                                                }
                                                                ref={(el) => {
                                                                  if (el) {
                                                                    el.style.setProperty(
                                                                      "font-size",
                                                                      "13px",
                                                                      "important"
                                                                    );
                                                                    el.style.setProperty(
                                                                      "font-family",
                                                                      "'Roboto', sans-serif",
                                                                      "important"
                                                                    );
                                                                    el.style.setProperty(
                                                                      "font-weight",
                                                                      "400px",
                                                                      "important"
                                                                    );
                                                                    el.style.setProperty(
                                                                      "color",
                                                                      "#64748B",
                                                                      "important"
                                                                    );
                                                                  }
                                                                }}
                                                                onChange={(e) =>
                                                                  this.onModelListTestDetailUpdate(
                                                                    eNew.fileName,
                                                                    index,
                                                                    i,
                                                                    item.subtaskId,
                                                                    e.target
                                                                      .value
                                                                  )
                                                                }
                                                                placeholder={
                                                                  eNew.fileName
                                                                }
                                                              />
                                                            </div>
                                                          </div>
                                                        )
                                                      )}
                                                    <div
                                                      className=" relative"
                                                      style={{
                                                        position: "fixed",
                                                        bottom: "0",
                                                        padding: 20,
                                                      }}
                                                    >
                                                      <div className="flex flex-row justify-between !w-[500px] ">
                                                        <div />
                                                        <div className=" flex flex-row !items-center justify-between gap-x-4">
                                                          <Button
                                                            className="rounded-lg flex flex-row !items-center justify-center gap-x-2  h-[40px] w-[146px] primary_btn font-poppins text-white !font-[600] hover:!text-white !border-none "
                                                            color="btn btn-primary"
                                                            type="button"
                                                            onClick={() =>
                                                              this.onClickCloseDetail(
                                                                index + "-" + i
                                                              )
                                                            }
                                                          >
                                                            <svg
                                                              width="16"
                                                              height="12"
                                                              viewBox="0 0 16 12"
                                                              fill="none"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                              <path
                                                                fill-rule="evenodd"
                                                                clip-rule="evenodd"
                                                                d="M15.2703 0.758767C15.5876 1.09212 15.5746 1.6196 15.2412 1.93692L5.61162 11.1036C5.44717 11.2601 5.22597 11.3428 4.99916 11.3325C4.77236 11.3222 4.55958 11.2197 4.41003 11.0489L0.706328 6.81814C0.403177 6.47184 0.43815 5.94537 0.784441 5.64222C1.13073 5.33907 1.65721 5.37404 1.96036 5.72033L5.09178 9.29737L14.0921 0.729749C14.4255 0.412424 14.9529 0.425416 15.2703 0.758767Z"
                                                                fill="white"
                                                              />
                                                            </svg>
                                                            Submit
                                                          </Button>
                                                        </div>
                                                      </div>{" "}
                                                    </div>
                                                  </form>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Scrollbars>
                                      </div>
                                    </div>
                                  )
                                );
                              })}

                            <div
                              style={{
                                display:
                                  process.env
                                    .REACT_APP_ADD_TESTCASES_ENABLED === "true"
                                    ? "flex"
                                    : "hidden",
                                alignItems: "center",
                                flex: 1,
                              }}
                              className={`model-list-testsuite ${
                                process.env.REACT_APP_ADD_TESTCASES_ENABLED ===
                                "true"
                                  ? "!flex"
                                  : "!hidden"
                              } pl-12 pr-4 !h-[25px]`}
                            >
                              <input
                                type="text"
                                autoComplete="off"
                                placeholder="Add new Test case here"
                                className={`list-input !bg-transparent ${
                                  process.env
                                    .REACT_APP_ADD_TESTCASES_ENABLED === "true"
                                    ? "!block"
                                    : "!hidden"
                                }  !placeholder-primary-green !h-[25px]`}
                                style={{
                                  display:
                                    process.env
                                      .REACT_APP_ADD_TESTCASES_ENABLED ===
                                    "true"
                                      ? "block"
                                      : "hidden",
                                }}
                                name="list"
                                value={
                                  this.state.newInputSubtask.taskId === ele.id
                                    ? this.state.newInputSubtask.title
                                    : ""
                                }
                                onFocus={() => {
                                  this.setState({
                                    taskId: ele.id,
                                    taskIndex: index,
                                  });
                                }}
                                onBlur={(e) => {
                                  if (
                                    this.state.newInputSubtask.title.trim() !==
                                    ""
                                  ) {
                                    this.addNewSubTask(ele.taskId, index, {
                                      title: this.state.newInputSubtask.title,
                                    });
                                  }
                                  // console.log(e.currentTarget.value)
                                }}
                                onChange={(e) =>
                                  this.setState({
                                    newInputSubtask: {
                                      title: e.target.value,
                                      taskId: ele.id,
                                      taskIndex: index,
                                    },
                                  })
                                }
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    if (
                                      this.state.newInputSubtask.title.trim() !==
                                      ""
                                    ) {
                                      this.addNewSubTask(ele.taskId, index, {
                                        title: this.state.newInputSubtask.title,
                                      });
                                    }
                                  }
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                </div>
              )}
            </div>
            {this.state.viewType === "context" && (
              <div>
                {this.state.sharedAttachments?.pdf?.url ||
                this.state.sharedAttachments?.pdf?.description ||
                this.state.sharedAttachments?.diagram?.url ||
                this.state.sharedAttachments?.diagram?.description ||
                this.state.sharedAttachments?.textFile.text ||
                this.state.sharedAttachments?.otherPdf?.url ||
                this.state.sharedAttachments?.otherPdf?.description ||
                this.state.sharedAttachments?.figma?.screeenshot?.url ||
                this.state.sharedAttachments?.figma?.url ? (
                  <p className="font-semibold text-lg flex flex-row items-center color-[#181059] mb-3">
                    <TiAttachment />
                    Test Plan - {this.state?.shareUrlname}
                  </p>
                ) : (
                  <p className="my-40 text-center text-lg opacity-50">
                    {" "}
                    No attachments provided
                  </p>
                )}
                <div>
                  {(this.state.sharedAttachments?.pdf?.url ||
                    this.state.sharedAttachments?.pdf?.description) && (
                    <div className="mb-3">
                      <AttachmentCard
                        heading="Requirement PRD File"
                        file={this.state.sharedAttachments?.pdf?.url}
                        fileType={"pdf"}
                        description={
                          this.state.sharedAttachments?.pdf?.description
                        }
                        attachmentId={
                          this.state.sharedAttachments?.pdf?.attachmentId
                        }
                        descriptionThreads={this.state.comments.description}
                        handleCancel={this.handleCancel}
                        turonCommentMode={this.turonCommentMode}
                        toggleCommentMode={this.toggleCommentMode}
                        callCommentThreadCreate={this.callCommentThreadCreate}
                        isCommentMode={this.state.isCommentMode}
                        changeThread={this.changeThread}
                      />

                      {/* <iframe
                    src={`data:application/pdf;base64,${this.state.sharedAttachments.pdfFile.base64}`}
                    type="application/pdf"
                    width="100%"
                    height="800px"
                    title="PDF Preview"
                  /> */}
                    </div>
                  )}

                  {this.state.sharedAttachments?.textFile.text && (
                    <div className="mb-3">
                      <AttachmentCard
                        fileType="txt"
                        description={
                          this.state.sharedAttachments?.textFile.text
                        }
                        heading="Comment"
                        attachmentId={
                          this.state.sharedAttachments?.textFile.attachmentId
                        }
                        descriptionThreads={this.state.comments.description}
                        handleCancel={this.handleCancel}
                        toggleCommentMode={this.toggleCommentMode}
                        callCommentThreadCreate={this.callCommentThreadCreate}
                        isCommentMode={this.state.isCommentMode}
                        changeThread={this.changeThread}
                      />
                    </div>
                  )}

                  {(this.state.sharedAttachments?.diagram?.url ||
                    this.state.sharedAttachments?.diagram?.description) && (
                    <div className="mb-3">
                      <AttachmentCard
                        description={
                          this.state.sharedAttachments?.diagram?.description
                        }
                        file={this.state.sharedAttachments?.diagram?.url}
                        heading="Requirement Diagram"
                        fileType="img"
                        attachmentId={
                          this.state.sharedAttachments?.diagram?.attachmentId
                        }
                        descriptionThreads={this.state.comments.description}
                        handleCancel={this.handleCancel}
                        toggleCommentMode={this.toggleCommentMode}
                        callCommentThreadCreate={this.callCommentThreadCreate}
                        isCommentMode={this.state.isCommentMode}
                        changeThread={this.changeThread}
                        handleNewImageCommentThreadCreate={
                          this.handleNewImageCommentThreadCreate
                        }
                        handleImageThreadClick={this.handleImageThreadClick}
                        imageComments={this.state.comments.diagram}
                      />
                    </div>
                  )}

                  {(this.state.sharedAttachments?.figma?.link ||
                    this.state.sharedAttachments?.figma?.screenshot?.url) && (
                    <div className="mb-3">
                      <AttachmentCard
                        fileType={
                          this.state.sharedAttachments?.figma?.link
                            ? "figma"
                            : "img"
                        }
                        description={
                          this.state.sharedAttachments?.figma?.screenshot
                            ?.description
                        }
                        file={
                          this.state.sharedAttachments?.figma?.link ||
                          this.state.sharedAttachments?.figma?.screenshot?.url
                        }
                        heading={"Figma Design"}
                        attachmentId={
                          this.state.sharedAttachments?.figma?.screenshot
                            .attachmentId
                        }
                        descriptionThreads={this.state.comments.description}
                        handleCancel={this.handleCancel}
                        toggleCommentMode={this.toggleCommentMode}
                        callCommentThreadCreate={this.callCommentThreadCreate}
                        isCommentMode={this.state.isCommentMode}
                        changeThread={this.changeThread}
                      />
                    </div>
                  )}

                  {(this.state.sharedAttachments?.otherPdf?.url ||
                    this.state?.sharedAttachments?.otherPdf?.description) && (
                    <div className="mb-3">
                      <AttachmentCard
                        description={
                          this.state.sharedAttachments?.otherPdf?.description
                        }
                        file={this.state.sharedAttachments?.otherPdf?.url}
                        heading="Extra File"
                        fileType="link"
                        attachmentId={
                          this.state.sharedAttachments?.otherPdf?.attachmentId
                        }
                        descriptionThreads={this.state.comments.description}
                        handleCancel={this.handleCancel}
                        toggleCommentMode={this.toggleCommentMode}
                        callCommentThreadCreate={this.callCommentThreadCreate}
                        isCommentMode={this.state.isCommentMode}
                        changeThread={this.changeThread}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </section>

          {process.env.REACT_APP_SHARE_URL_COMMENT_ENABLED === "true" && (
            <section
              className="relative bg-white  w-[25%] ml-[5%] border-l border-r mt-16 !z-0 rounded-md !overflow-hidden "
              style={{ marginTop: this.state.isUserLoggedIn ? 70 : "" }}
            >
              <div className="sticky w-[100] bg-white ">
                <div className="fixed h-[90vh] overflow-y-scroll">
                  {this.state.selection.text.length <= 10 &&
                    !this.state.isThread && (
                      <div>
                        <div className="border-b border-t mr-4">
                          <input
                            className="border-none px-2 py-1 text-lg"
                            type="text"
                            placeholder="Search Comments"
                          />
                        </div>
                        {this.state?.comments.testcase?.map((thread) =>
                          thread?.comments?.map((comment) => {
                            return (
                              <CommentCard
                                commentText={comment.commentText}
                                userId={comment.userId}
                                userName={comment.name}
                                createdAt={comment.createdAt}
                                taskId={thread.taskId}
                                subtaskId={thread.subtaskId}
                                threadId={thread.id}
                                startOffset={thread.startOffset}
                                endOffset={thread.endOffset}
                                dataType="testcase"
                                handleThreadClick={this.handleTestThreadClick}
                              />
                            );
                          })
                        )}
                        {this.state?.comments.description?.map((thread) =>
                          thread?.comments?.map((comment) => {
                            return (
                              <CommentCard
                                commentText={comment.commentText}
                                userId={comment.userId}
                                userName={comment.name}
                                createdAt={comment.createdAt}
                                taskId={thread.taskId}
                                subtaskId={thread.subtaskId}
                                threadId={thread.id}
                                startOffset={thread.startOffset}
                                endOffset={thread.endOffset}
                                dataType="description"
                                handleThreadClick={this.handleDescThreadClick}
                              />
                            );
                          })
                        )}
                        {this.state?.comments.diagram?.map((thread) =>
                          thread.comments?.map((comment) => {
                            return (
                              <CommentCard
                                commentText={comment.commentText}
                                userId={comment.userId}
                                userName={comment.name}
                                createdAt={comment.createdAt}
                                threadId={thread.id}
                                dataType="image"
                                handleThreadClick={this.handleImageThreadClick}
                                xCord={thread.xCord}
                                yCord={thread.yCord}
                              />
                            );
                          })
                        )}
                        {!this.state?.comments.testcase?.length &&
                          !this.state?.comments.diagram?.length &&
                          !this.state?.comments.description?.length && (
                            <div className="h-24 w-[25vw] text-center mt-12 items-center justify-center ">
                              <p className="text-lg font-semibold opacity-50">
                                No Comments
                              </p>
                              <p className="text-sm opacity-35">
                                Be the first one to start!
                              </p>
                            </div>
                          )}
                      </div>
                    )}
                  <div className="w-full">
                    {(this.state.selection.text.length > 10 ||
                      this.state.isThread) && (
                      <CommentThreadCard
                        commentText={this.state.commentText}
                        handleCancel={this.handleCancel}
                        handleCommentTextChange={this.handleCommentTextChange}
                        callCommentThreadCreate={this.callCommentThreadCreate}
                        handleTagButtonPress={this.handleTagButtonPress}
                        showUsersList={this.state.showUsersList}
                        searchUserText={this.state.searchUserText}
                        workspaceUsers={this.state.workspaceUsers}
                        onSelectUser={this.handleSelectUser}
                        isThread={this.state.isThread}
                        threadId={this.state.currentThreadId}
                        dataType={
                          this.state.selection.dataType || this.state.dataType
                        }
                        taggedUsers={this.state.taggedUsers}
                        toggleShowUsersMenu={this.toggleShowUsersMenu}
                      />
                    )}
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
        {!this.state.isUserLoggedIn && (
          <div className="!z-[99999] !px-8 !w-full !py-12 !pt-16 border-t- !bg-[rgb(249,249,249)] relative">
            <FooterSection showLogo={true} hasXpadding={false} />
          </div>
        )}
      </div>
    );
  }
}

const AttachmentCard = ({
  description,
  heading,
  file,
  fileType,
  attachmentId,
  descriptionThreads,
  isCommentMode,
  toggleCommentMode,
  handleCancel,
  turonCommentMode,
  callCommentThreadCreate,
  changeThread,
  handleNewImageCommentThreadCreate,
  imageComments,
  handleImageThreadClick,
}) => {
  // const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [selectedArea, setSelectedArea] = useState(null);

  useEffect(() => {
    // Fetch comments from the server if needed
    // Example: axios.get(`/api/comments?attachmentId=${attachmentId}`)
    //   .then(response => setComments(response.data))
    //   .catch(error => console.error(error));
  }, [attachmentId]);

  const handleImageClick = (e) => {
    if (process.env.REACT_APP_SHARE_URL_COMMENT_ENABLED !== "true") return;
    const rect = e.target.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100; // x position as percentage of image width
    const y = ((e.clientY - rect.top) / rect.height) * 100; // y position as percentage of image height
    setSelectedArea({ x, y });
  };

  const handleCommentSubmit = (e) => {
    e.preventDefault();
    if (!newComment || !selectedArea) return;

    handleNewImageCommentThreadCreate(newComment, selectedArea, attachmentId);
    setNewComment("");
    setSelectedArea(null);
  };

  const formatDescriptionParagraph = (paragraph) => {
    if (!paragraph) return <p>No description available</p>;

    // Format description with potential clickable spans for threads
    const formattedText = [];
    let currentOffset = 0;

    descriptionThreads
      .filter((subtask) => subtask?.attachmentId === paragraph.attachmentId)
      .sort((a, b) => a.startOffset - b.startOffset)
      .forEach((subtask) => {
        const { startOffset: start, endOffset: end, id: threadId } = subtask;

        if (start > currentOffset) {
          formattedText.push(paragraph.text.slice(currentOffset, start));
        }

        formattedText.push(
          <span
            key={threadId}
            descThreadId={threadId}
            className="bg-yellow-300 cursor-pointer"
            onClick={() => handleThreadClick(start, end, threadId)}
          >
            {paragraph.text.slice(start, end)}
          </span>
        );

        currentOffset = end;
      });

    if (currentOffset < paragraph.text.length) {
      formattedText.push(paragraph.text.slice(currentOffset));
    }

    return (
      <p
        key={paragraph.attachmentId}
        data-type="description"
        descriptionId={paragraph.attachmentId}
        className="text-justify whitespace-pre-wrap"
      >
        {formattedText}
      </p>
    );
  };

  const handleThreadClick = async (start, end, threadId) => {
    // Handle click on thread span
    console.log("Thread clicked:", start, end, threadId);

    // Example logic: scroll or highlight the clicked thread
    const span = document.querySelector(`span[descThreadId="${threadId}"]`);
    if (span) {
      span.classList.add("blink-bg");
      span.scrollIntoView({ behavior: "smooth", block: "center" });
      setTimeout(() => {
        span.classList.remove("blink-bg");
      }, 1000);
    }

    // Perform any other necessary actions (e.g., update state)
    await changeThread(threadId, "description");
  };

  return (
    <div
      className="bg-white border border-gray-100 rounded-md"
      style={{ objectFit: "contain", overflow: "hidden" }}
    >
      <div className="border-gray-red-100 border-b px-3 py-2 flex flex-row items-center justify-between h-12">
        <p className="capitalize font-semibold">{heading}</p>
        {process.env.REACT_APP_SHARE_URL_COMMENT_ENABLED === "true" ? (
          <div className="flex flex-row items-center">
            {!isCommentMode ? (
              <FaCommentMedical
                className="cursor-pointer text-gray-500"
                onClick={turonCommentMode}
                size={23}
              />
            ) : (
              <FaCommentMedical
                className="cursor-pointer text-primary-green"
                onClick={handleCancel}
                size={23}
              />
            )}
          </div>
        ) : (
          <div />
        )}
      </div>
      <div className="px-3 py-2 relative" style={{ objectFit: "contain" }}>
        {fileType === "img" && (
          <div
            className="flex relative w-full h-full pb-2"
            style={{
              float: "left",
              marginRight: 25,
              width: 500,
              height: 500,
              objectFit: "contain",
            }}
          >
            <img
              src={file}
              alt="Attachment"
              className=" cursor-pointer"
              style={{ objectFit: "contain", width: "100%", height: "100%" }}
              onClick={handleImageClick}
            />
            {imageComments?.map(
              (thread, index) =>
                thread?.comments?.length > 0 && (
                  <div
                    key={index}
                    className="comment-bubble absolute flex flex-row items-center cursor-pointer"
                    onClick={() => {
                      handleImageThreadClick(
                        thread.xCord,
                        thread.yCord,
                        attachmentId,
                        thread.id
                      );
                    }}
                    style={{
                      left: `${thread.xCord}%`,
                      top: `${thread.yCord}%`,
                      background: "rgba(0, 0, 0, 0.7)",
                      color: "white",
                      padding: "5px 10px",
                      borderRadius: "5px",
                    }}
                    imageThreadId={thread.id}
                  >
                    {thread.comments[0].commentText?.slice(0, 20) + "..."}
                    <p className="rounded-full flex items-center justify-center bg-primary-green h-4 text-sm w-4">
                      {thread.comments.length}
                    </p>
                  </div>
                )
            )}
            {selectedArea && (
              <form
                onSubmit={handleCommentSubmit}
                className="comment-form absolute flex flex-col bg-white shadow-md shadow-black px-3 py-2"
                style={{
                  left: `${selectedArea.x}%`,
                  top: `${selectedArea.y + 2}%`, // Adjusted for better visual separation
                  zIndex: 300,
                }}
              >
                <p className="text-lg font-semibold opacity-70">Add Comment</p>
                <textarea
                  type="text"
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  placeholder="Write here..."
                  className="p-2 border border-gray-300 rounded h-40 w-80 mb-2"
                />
                <div className="flex flex-row justify-between">
                  <div />
                  <div className="flex flex-row justify-between items-center">
                    <button
                      className="text-black bg-gray-200 w-20 h-10 rounded-md"
                      onClick={() => setSelectedArea(null)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="ml-2 p-2 bg-primary-green text-white rounded-md w-20 h-10"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        )}
        <div>
          {formatDescriptionParagraph({
            text: description,
            attachmentId: attachmentId?.toString(),
          })}
        </div>
        {fileType === "figma" && (
          <div>
            <iframe
              width="100%"
              height="600px"
              title="Figma Preview"
              src={`https://www.figma.com/embed?embed_host=share&url=${file}`}
              allowFullScreen
            ></iframe>
            <p className="text-sm">
              If the preview is not loaded, kindly visit this URL{" "}
              <span>
                <a href={file} target="_blank" rel="noreferrer">
                  {file}
                </a>
              </span>
            </p>
          </div>
        )}
        {fileType === "pdf" && (
          <div className="mt-2">
            <iframe
              src={file}
              type="application/pdf"
              width="100%"
              height="800px"
              title="PDF Preview"
            />
          </div>
        )}
        {fileType === "link" && (
          <div className="mt-2">
            <p>
              Visit the link to see the attachment:{" "}
              <a href={file} target="_blank" rel="noreferrer">
                {file}
              </a>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

const CommentThreadCard = ({
  commentText,
  handleCommentTextChange,
  handleCancel,
  callCommentThreadCreate,
  handleTagButtonPress,
  showUsersList,
  searchUserText,
  workspaceUsers,
  onSelectUser,
  isThread = false,
  dataType = "testcase",
  threadId,
  taggedUsers,
  toggleShowUsersMenu,
}) => {
  const [thread, setThread] = useState({ comments: [] });

  const fetchAllTestcaseCommentsForThread = () => {
    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    // console.log(requestOptions);

    fetch(
      `${BASE_URL}projects//comment/testcase/thread/${threadId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("FETCHING RESULT ===========>", result);
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }

        // console.log(result);
        const data = result.data;
        setThread(data);
        console.log("fetched thread", data);
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ testCasesEmpty: true, loading: false });
        // localStorage.clear();
        // history.push("/login?redirect=1");
      });
  };

  const handleAddCommentToTestcaseThread = () => {
    const token = Promises.getToken();
    var myHeaders = new Headers();

    if (!token) {
      NotificationManager.error("Please login to comment");
      history.push({
        pathname: "/login",
        search: "?redirect=1",
      });
      return;
    }

    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    const raw = {
      commentText,
      taggedUsers,
      url: getFullUrl(),
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(raw),
    };

    // console.log(requestOptions);

    fetch(
      `${BASE_URL}projects/comment/testcase/thread/${threadId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("FETCHING RESULT ===========>", result);
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }

        // console.log(result);
        const data = result.data;
        setThread(data);
        console.log("fetched thread", data);
        handleCommentTextChange({ target: { value: "" } });
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ testCasesEmpty: true, loading: false });
        // localStorage.clear();
        // history.push("/login?redirect=1");
      });
  };
  const handleAddCommentToImageThread = () => {
    const token = Promises.getToken();
    var myHeaders = new Headers();

    if (!token) {
      NotificationManager.error("Please login to comment");
      history.push({
        pathname: "/login",
        search: "?redirect=1",
      });
      return;
    }

    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    const raw = {
      commentText,
      taggedUsers,
      url: getFullUrl(),
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(raw),
    };

    // console.log(requestOptions);

    fetch(
      `${BASE_URL}projects/comment/image/thread/${threadId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("FETCHING RESULT ===========>", result);
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }

        // console.log(result);
        const data = result.data;
        setThread((prev) => ({
          ...prev,
          comments: [...prev.comments, data.newComment],
        }));
        handleCommentTextChange({ target: { value: "" } });
        console.log("fetched thread", data);
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ testCasesEmpty: true, loading: false });
        // localStorage.clear();
        // history.push("/login?redirect=1");
      });
  };
  const fetchAllDescriptionCommentsForThread = () => {
    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    // console.log(requestOptions);

    fetch(
      `${BASE_URL}projects/comment/description/thread/${threadId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("FETCHING RESULT ===========>", result);
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }

        // console.log(result);
        const data = result.data;
        setThread(data);
        console.log("fetched thread", data);
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ testCasesEmpty: true, loading: false });
        // localStorage.clear();
        // history.push("/login?redirect=1");
      });
  };
  const fetchAllImageCommentsForThread = () => {
    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    // console.log(requestOptions);

    fetch(
      `${BASE_URL}projects/comment/image/thread/${threadId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("FETCHING RESULT ===========>", result);
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }

        // console.log(result);
        const data = result.data;
        setThread(data);
        console.log("fetched thread", data);
      })
      .catch((error) => {
        console.log("error fetching image thread", error);
      });
  };
  const handleAddCommentToDescriptionThread = () => {
    const token = Promises.getToken();
    var myHeaders = new Headers();

    if (!token) {
      NotificationManager.error("Please login to comment");
      history.push({
        pathname: "/login",
        search: "?redirect=1",
      });
      return;
    }

    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    const raw = {
      commentText,
      taggedUsers,
      url: getFullUrl(),
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(raw),
    };

    // console.log(requestOptions);

    fetch(
      `${BASE_URL}projects//comment/description/thread/${threadId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("FETCHING RESULT ===========>", result);
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }

        // console.log(result);
        const data = result.data;
        setThread((prev) => ({
          comments: [...prev.comments, data.newComment],
        }));
        console.log("fetched thread", data);
        handleCommentTextChange({ target: { value: "" } });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    if (!threadId) return;
    //get thread list
    console.log("isThread", isThread, "datatype", dataType);
    if (isThread && dataType === "testcase") {
      fetchAllTestcaseCommentsForThread();
    } else if (isThread && dataType === "description") {
      fetchAllDescriptionCommentsForThread();
    } else if (isThread && dataType === "image") {
      fetchAllImageCommentsForThread();
    }
  }, [threadId, dataType, isThread]);
  //fixed right-[10vw] top-[10vh]
  return (
    <div
      className=" mb-4 z-30 py-3 shadow-2xl shadow-blue-100 opacity-100 bg-white"
      style={{ width: "100%" }}
    >
      <div className="px-3 border-b pb-3">
        <p className="text-lg font-semibold">Create Comment</p>
        <div className="px-2 py-1 border-red-200 ">
          <div className="flex justify-between items-center mb-2">
            <img
              src={process.env.PUBLIC_URL + "/assets/images/default-img.png"}
              alt="user"
              className="rounded-circle w-8 h-8"
            />
            <p>{Promises.getName()}</p>
          </div>
          <div className="border border-red-200 rounded-md mb-2">
            <textarea
              type="text"
              className="h-40 px-2 py-1 border-none"
              style={{ width: 325 }}
              value={commentText}
              onChange={(e) => handleCommentTextChange(e)}
              id="comment-box"
            />
          </div>
          <div className="flex justify-between">
            <div
              className="font-semibold text-lg px-2 py-2 cursor-pointer"
              onClick={handleTagButtonPress}
            >
              @
            </div>
            <div className="flex flex-row">
              <div
                className="mr-3 w-24 bg-gray-300 text-black rounded-md flex items-center justify-center cursor-pointer"
                onClick={handleCancel}
              >
                cancel
              </div>
              <div
                className="w-24 text-white bg-primary-green rounded-md flex items-center justify-center cursor-pointer"
                onClick={() => {
                  console.log(isThread, dataType, "at comment thread card");
                  if (!isThread) callCommentThreadCreate();
                  else if (dataType === "testcase") {
                    handleAddCommentToTestcaseThread();
                  } else if (dataType === "description") {
                    handleAddCommentToDescriptionThread();
                  } else if (dataType === "image") {
                    handleAddCommentToImageThread();
                  }
                }}
              >
                comment
              </div>
            </div>
          </div>
        </div>
      </div>
      {showUsersList && (
        <UserList
          searchText={searchUserText}
          users={workspaceUsers}
          onSelectUser={onSelectUser}
          toggleShowUsersMenu={toggleShowUsersMenu}
        />
      )}

      {isThread && (
        <div>
          {thread.comments.map((comment) => (
            <CommentCard
              commentText={comment.commentText}
              userId={comment.userId}
              userName={comment.name}
              createdAt={comment.createdAt}
              taskId={thread.taskId}
              subtaskId={thread.subtaskId}
              threadId={thread.id}
              startOffset={thread.startOffset}
              endOffset={thread.endOffset}
              commentType={dataType}
              handleThreadClick={() => {}}
              showReplyButton={false}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const UserList = ({
  searchText = "",
  users = [],
  onSelectUser,
  toggleShowUsersMenu,
}) => {
  const [filteredUsers, setFilteredUsers] = useState(users);
  useEffect(() => {
    setFilteredUsers(
      users.filter((user) =>
        user.name.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [searchText, users]);

  useEffect(() => {
    if (filteredUsers.length === 0) {
      toggleShowUsersMenu(false);
    }
  }, [filteredUsers]);

  return (
    <div className="px-3 py-1 absolute bg-gray-200 shadow-md z-20 w-full">
      <div className="flex justify-end">
        <div
          className="text-red-400 opacity-50 font-semibold"
          onClick={() => {
            toggleShowUsersMenu(false);
          }}
        >
          X
        </div>
      </div>
      <ul className="max-h-40 overflow-scroll">
        {filteredUsers.map((user) => (
          <li
            className="flex flex-row items-center mb-2 cursor-pointer border-b pb-2"
            key={user.userId}
            onClick={() => onSelectUser(user)}
          >
            <img
              // src={`https://api.dicebear.com/5.x/initials/svg?seed=${Promises.getName()}`}
              src={process.env.PUBLIC_URL + "/assets/images/default-img.png"}
              alt="user"
              className="rounded-circle w-8 h-8 mr-4"
            />
            {user.name.trim() || "Unknown User"}
          </li>
        ))}
      </ul>
    </div>
  );
};

const CommentCard = ({
  commentText,
  dataType,
  taskId,
  subtaskId,
  attachmentId,
  threadId,
  startOffset,
  endOffset,
  handleThreadClick,
  userId,
  userName,
  createdAt,
  showReplyButton = true,
  xCord,
  yCord,
}) => {
  return (
    <div className="px-4 !mr-4 pb-8 pt-2 border-b z-0">
      <div className="flex justify-between items-center my-3">
        <div className="flex items-center">
          <img
            // src={`https://api.dicebear.com/5.x/initials/svg?seed=${Promises.getName()}`}
            src={process.env.PUBLIC_URL + "/assets/images/default-img.png"}
            alt="user"
            className="rounded-circle w-8 h-8 mr-4"
          />
          <p className="font-bold opacity-75">{userName}</p>
        </div>
        <p>{moment.utc(createdAt).local().fromNow()} </p>
        {/* <p>{moment(createdAt).fromNow()} </p> */}
      </div>
      <div>
        <p className="text-justify">{commentText}</p>
      </div>
      {showReplyButton && (
        <div
          className="text-primary-green font-semibold flex items-center justify-start mt-2 cursor-pointer"
          onClick={() => {
            if (dataType === "testcase")
              handleThreadClick(
                startOffset,
                endOffset,
                subtaskId,
                taskId,
                threadId
              );
            else if (dataType === "description") {
              handleThreadClick(startOffset, endOffset, attachmentId, threadId);
            } else if (dataType === "image") {
              handleThreadClick(xCord, yCord, attachmentId, threadId);
            }
          }}
        >
          <FaReply className="mr-2" />
          Reply
        </div>
      )}
    </div>
  );
};

// const Testcases = ({ testcases }) => {
//   return (
//     <div>
// {this.state.filteredTestcasesData.length > 0 &&
//   this.state.filteredTestcasesData.map((ele, index) => {
//     if (ele.lists.length === 0) return null;
//     return (
//       <div className="tests mb-1">
//         <div
//           className="flex items-center "
//           style={{
//             borderBottom: "0.5px solid #D1D5DB",
//             marginBottom: 3,
//             backgroundColor: ele.isChecked
//               ? "rgb(232 244 255)"
//               : "#F8F8F8",
//           }}
//         >
//           <div className="w-0.5 px-0.5 py-3 bg-primary-green" />

//           <div className="model-input  w-full !py-0">
//             <div
//               style={{
//                 display: "flex",
//                 alignItems: "center",
//                 flex: 1,
//               }}
//             >
//               {ele.expanded ? (
//                 <MdOutlineArrowDropDown
//                   className="text-primary-green cursor-pointer"
//                   size={28}
//                   onClick={() => {
//                     ele.expanded = !ele.expanded;
//                     this.setState({
//                       testCasesData: testCasesData,
//                     });
//                   }}
//                 />
//               ) : (
//                 <MdArrowRight
//                   className="text-black cursor-pointer"
//                   size={28}
//                   onClick={() => {
//                     ele.expanded = !ele.expanded;
//                     this.setState({
//                       testCasesData: testCasesData,
//                     });
//                   }}
//                 />
//               )}

//               <input
//                 autoComplete="off"
//                 type="text"
//                 placeholder="Add Task Title here"
//                 className="input1 w-full !bg-transparent"
//                 name="model"
//                 value={ele.model || ele.taskTitle}
//                 disabled={true}
//               />

//               <input
//                 type="checkbox"
//                 className="!w-4 !h-4 !m-0 !mr-8 cursor-pointer showTaskDeleteIcon"
//                 onChange={() => this.onParentCheckbox(ele, index)}
//                 checked={ele.isChecked && this.state.isCheck.length !== 0}
//               />
//             </div>
//           </div>
//         </div>

//         {/* Div Goes Here */}
//         {ele.isCollapse ||
//           ele.lists.map((item, i, row) => {
//             return (
//               ele.expanded &&
//               item.subTaskTitle && (
//                 <div
//                   className={`model-list qq pl-[1.5rem] pr-4 ${
//                     item.subTaskTitle ? "" : " !bg-white"
//                   }`}
//                   // onMouseEnter={this.mouseEnter.bind(this)}
//                   // onMouseLeave={this.mouseLeave.bind(this)}
//                 >
//                   <div
//                     style={{
//                       display: "flex",
//                       alignItems: "center",
//                       flex: 1,
//                     }}
//                     className={`model-list-testsuite `}
//                   >
//                     <input
//                       type="checkbox"
//                       className="!w-4 !h-4 !m-0 !mr-4 cursor-pointer"
//                       onChange={() =>
//                         this.onChildCheckbox(
//                           item.subtaskId,
//                           item,
//                           index,
//                           i
//                         )
//                       }
//                       checked={
//                         item.isChildChecked &&
//                         this.state.isCheck.length !== 0
//                       }
//                     />
//                     <input
//                       type="text"
//                       autoComplete="off"
//                       placeholder="Add new Test case here"
//                       className={`list-input !placeholder-primary-green ${
//                         item.classNameDependsOnCondtion
//                       } ${item.subTaskTitle ? " bg-transparent" : ""}`}
//                       name="list"
//                       value={
//                         item.subTaskTitle !== null
//                           ? item.subTaskTitle
//                           : ""
//                       }
//                       disabled={true}
//                     />
//                   </div>
//                 </div>
//               )
//             );
//           })}
//       </div>
//     );
//   })}
//     </div>
//   );
// };
