import React from "react";

const NotFound = ({ subject, search, clear, add, actionNeeded = true }) => {
  if (!subject) subject = "Resource";
  
  return (
    <div>
      <div className="flex flex-col items-center justify-center">
        <div>
          <svg
            width="58"
            height="58"
            viewBox="0 0 58 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="4" y="4" width="50" height="50" rx="25" fill="#ECEFFF" />
            <rect
              x="4"
              y="4"
              width="50"
              height="50"
              rx="25"
              stroke="#F5F8FF"
              stroke-width="8"
            />
            <path
              d="M37 37L32.65 32.65M35 27C35 31.4183 31.4183 35 27 35C22.5817 35 19 31.4183 19 27C19 22.5817 22.5817 19 27 19C31.4183 19 35 22.5817 35 27Z"
              stroke="#316BFF"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <p
          className="text-[#101828] my-2"
          style={{ fontSize: 19, fontWeight: 500 }}
        >
          No result found
        </p>
        <div className="w-[380px] text-wrap">
          <p
            className="text-center text-[#667085]"
            style={{ fontSize: 16, fontWeight: 400 }}
          >
            Your search {search ? `"${search}"` : " with filters "} did not
            match any {subject}. Please try again {actionNeeded && (<>or create a new {subject}</>)}.
          </p>
        </div>
      </div>
      <div className="flex flex-row gap-x-4 items-center mb-4 justify-center mt-4">
        <button
          className="w-[158px] h-10"
          style={{
            border: "1px solid #D0D5DD",
            boxShadow: "0px 1px 2px 0px #1018280D",
            borderRadius: 8,
            fontSize: 14,
            fontWeight: 500,
          }}
          onClick={clear}
        >
          Clear {search ? " search" : " filter"}
        </button>
        {actionNeeded && (<>
        <button
          className="flex flex-row w-[188px] bg-[#316BFF] h-10  px-2 justify-center items-center text-white"
          style={{ borderRadius: 8 }}
          onClick={add}
        >
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.25 4.16666V15.8333M4.41669 9.99999H16.0834"
              stroke="white"
              stroke-width="1.67"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Add New {subject}
        </button></>)}
      </div>
    </div>
  );
};

export default NotFound;
