import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { LOGO_FULL_URL } from "../_helpers/constants";

const AuthSidebar = ({ width = 35, svgNumber = -1 }) => {
  return (
  <div
  className={`bg-[#F4F4F4] w-[90vw] lg:w-[525px] h-[50vh] md:h-[100vh] lg:h-[100vh] overflow-clip flex flex-col items-center justify-center px-4 pt-8 lg:pt-0 !py-0`}
>
  <div className="text-custom-container flex flex-col items-center justify-center">
    <Link to="/" className="w-[200px] md:w-[240px] lg:w-[390px]">
      <img className="w-full" alt="logo oyesense" src={LOGO_FULL_URL} />
    </Link>
    <div className="flex flex-col items-center justify-center font-popins">
      <p className="text-[#262259] font-raleway my-[10px] mb-2 text-[14px] md:text-[16px] lg:text-[25px] !font-[700] ">
        Simplified Test Case Management
      </p>
      <p className="text-[#303030CC] text-center font-normal text-[14px] md:text-[16px] max-w-[90vw] lg:max-w-[440px]">
        Adapt to your evolving work style with Oyetest. Seamlessly integrate, merge, and share your test cases.
      </p>
    </div>
  </div>

      {svgNumber !== -1 && (
        <div className="mt-[170px]">
          {svgNumber === 0 && (
            <svg
              width="310"
              height="220"
              viewBox="0 0 310 220"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              id="my-sample-check"
            >
              <g clip-path="url(#clip0_8_177)">
                <path
                  opacity="0.1"
                  d="M122.895 19.3022C111.15 14.5276 98.7421 10.5417 86.0757 11.0426C67.4517 11.7753 49.5897 22.979 40.8213 39.4262C33.3903 53.3609 32.4738 69.6935 29.2739 85.1575C25.3253 104.213 17.7744 122.315 9.70672 140.022C5.37445 149.534 0.802387 159.251 0.0936637 169.679C-0.894819 184.152 6.02456 198.577 16.8792 208.206C27.7339 217.835 42.1428 222.916 56.6077 224.025C64.3051 224.613 72.3488 224.067 79.1883 220.484C86.4194 216.695 91.6948 209.845 98.9552 206.109C105.685 202.646 113.559 202.158 121.115 202.611C131.011 203.205 140.762 205.278 150.045 208.76C160.606 212.728 170.427 218.469 180.965 222.493C191.502 226.516 203.276 228.757 214.048 225.402C224.157 222.25 232.166 214.595 239.36 206.842C251.197 194.113 261.993 180.454 271.644 165.997C278.148 156.256 284.201 145.977 287.079 134.622C292.575 112.912 285.008 88.4187 268.226 73.6074C254.328 61.3167 235.667 55.9879 217.4 52.7427C200.742 49.7933 181.482 49.6094 166.148 41.92C151.526 34.5877 138.22 25.5368 122.895 19.3022Z"
                  fill="#00CD70"
                  fill-opacity="0.55"
                />
                <path
                  d="M248.86 153.713L249.305 154.161L249.505 153.391L249.292 152.631H248.743L248.86 153.713Z"
                  fill="#464A5F"
                />
                <path
                  opacity="0.1"
                  d="M248.86 153.713L249.305 154.161L249.505 153.391L249.292 152.631H248.743L248.86 153.713Z"
                  fill="black"
                />
                <path
                  d="M248.86 153.713L249.292 154.174L248.86 154.68L248.397 154.507L248.282 154.283L248.86 153.713Z"
                  fill="#9C9CA1"
                />
                <path
                  opacity="0.1"
                  d="M248.86 153.713L249.292 154.174L248.86 154.68L248.397 154.507L248.282 154.283L248.86 153.713Z"
                  fill="black"
                />
                <path
                  opacity="0.1"
                  d="M78.623 182.651C82.0236 182.651 84.7804 182.273 84.7804 181.806C84.7804 181.34 82.0236 180.962 78.623 180.962C75.2224 180.962 72.4656 181.34 72.4656 181.806C72.4656 182.273 75.2224 182.651 78.623 182.651Z"
                  fill="#00CD70"
                />
                <path
                  opacity="0.1"
                  d="M39.9923 191.009C42.4099 191.009 44.3698 190.739 44.3698 190.407C44.3698 190.074 42.4099 189.805 39.9923 189.805C37.5746 189.805 35.6147 190.074 35.6147 190.407C35.6147 190.739 37.5746 191.009 39.9923 191.009Z"
                  fill="#00CD70"
                />
                <path
                  d="M68.7435 170.416H66.2656"
                  stroke="#565987"
                  stroke-miterlimit="10"
                />
                <path
                  d="M65.7754 176.049L61.8907 176.214"
                  stroke="#565987"
                  stroke-miterlimit="10"
                />
                <path
                  d="M63.4121 171.543L63.8331 173.565"
                  stroke="#565987"
                  stroke-miterlimit="10"
                />
                <path
                  d="M59.2823 177.7L59.3569 179.539"
                  stroke="#565987"
                  stroke-miterlimit="10"
                />
                <path
                  d="M76.1265 180.754C75.8504 180.529 75.6489 180.226 75.5483 179.885C75.5045 179.714 75.5205 179.533 75.5937 179.372C75.6669 179.211 75.7928 179.08 75.9506 179.001C76.321 178.859 76.7153 179.115 77.0164 179.374C77.3175 179.632 77.6585 179.928 78.0502 179.872C77.8488 179.689 77.698 179.457 77.6126 179.199C77.5272 178.941 77.5099 178.665 77.5626 178.398C77.5806 178.289 77.6275 178.186 77.6985 178.1C77.9036 177.879 78.274 177.975 78.5218 178.148C79.3024 178.697 79.5209 179.747 79.5236 180.711C79.5683 180.358 79.5737 180 79.5395 179.645C79.5226 179.469 79.5501 179.291 79.6194 179.127C79.6888 178.964 79.7978 178.821 79.9365 178.71C80.1218 178.61 80.3307 178.561 80.5414 178.569C80.7143 178.542 80.8909 178.552 81.0598 178.598C81.2287 178.644 81.3861 178.725 81.5218 178.835C81.8203 179.155 81.743 179.691 81.4845 180.042C81.1936 180.365 80.8493 180.636 80.4667 180.842C80.1773 181.017 79.9299 181.253 79.742 181.534C79.7195 181.574 79.7016 181.616 79.6888 181.66H77.4853C76.994 181.42 76.537 181.115 76.1265 180.754Z"
                  fill="#00CD70"
                />
                <path
                  opacity="0.1"
                  d="M5.76304 41.2338C8.94588 41.2338 11.5261 38.6536 11.5261 35.4708C11.5261 32.288 8.94588 29.7078 5.76304 29.7078C2.5802 29.7078 0 32.288 0 35.4708C0 38.6536 2.5802 41.2338 5.76304 41.2338Z"
                  fill="#00CD70"
                />
                <path
                  opacity="0.1"
                  d="M162.191 16.9506C165.374 16.9506 167.954 14.3704 167.954 11.1876C167.954 8.00476 165.374 5.42456 162.191 5.42456C159.008 5.42456 156.428 8.00476 156.428 11.1876C156.428 14.3704 159.008 16.9506 162.191 16.9506Z"
                  fill="#00CD70"
                />
                <path
                  opacity="0.1"
                  d="M253.619 39.7177C256.802 39.7177 259.382 37.1375 259.382 33.9547C259.382 30.7719 256.802 28.1917 253.619 28.1917C250.436 28.1917 247.856 30.7719 247.856 33.9547C247.856 37.1375 250.436 39.7177 253.619 39.7177Z"
                  fill="#00CD70"
                />
                <path
                  opacity="0.1"
                  d="M17.2864 76.9232C22.6206 76.9232 26.9448 72.599 26.9448 67.2648C26.9448 61.9306 22.6206 57.6064 17.2864 57.6064C11.9523 57.6064 7.62809 61.9306 7.62809 67.2648C7.62809 72.599 11.9523 76.9232 17.2864 76.9232Z"
                  fill="#00CD70"
                />
                <path
                  opacity="0.1"
                  d="M135.254 220.003C140.588 220.003 144.913 215.679 144.913 210.344C144.913 205.01 140.588 200.686 135.254 200.686C129.92 200.686 125.596 205.01 125.596 210.344C125.596 215.679 129.92 220.003 135.254 220.003Z"
                  fill="#00CD70"
                />
                <path
                  opacity="0.1"
                  d="M263.92 214.434C269.254 214.434 273.578 210.11 273.578 204.776C273.578 199.442 269.254 195.117 263.92 195.117C258.585 195.117 254.261 199.442 254.261 204.776C254.261 210.11 258.585 214.434 263.92 214.434Z"
                  fill="#00CD70"
                />
              </g>
              <g clip-path="url(#clip1_8_177)">
                <path
                  d="M130.436 111.701C140.032 94.0949 158.336 80.5759 177.712 81.8783C163.42 98.0899 155.085 119.052 154.184 141.056C153.866 149.543 154.359 159.018 148.956 165.373C145.595 169.328 140.456 171.243 135.404 171.645C130.352 172.046 125.301 171.094 120.316 170.146L119.107 170.533C118.646 150.286 120.841 129.308 130.436 111.701Z"
                  fill="#00CD70"
                />
                <path
                  d="M177.741 82.302C163.412 89.4967 151.195 101.513 143.695 116.167C142.073 119.335 140.693 122.696 140.09 126.25C139.488 129.805 139.909 133.19 141.073 136.567C142.137 139.654 143.569 142.683 143.895 145.986C144.239 149.468 143.011 152.723 140.8 155.307C138.095 158.469 134.462 160.427 130.846 162.214C126.831 164.198 122.63 166.193 119.786 169.896C119.442 170.344 118.794 169.761 119.138 169.313C124.086 162.87 132.556 161.659 138.429 156.469C141.17 154.048 143.227 150.75 143.097 146.886C142.984 143.507 141.51 140.382 140.407 137.266C139.248 133.994 138.67 130.704 139.086 127.216C139.511 123.647 140.768 120.223 142.305 117.025C145.771 109.814 150.513 103.193 155.947 97.4704C162.191 90.892 169.468 85.4761 177.463 81.4573C177.958 81.2088 178.232 82.055 177.741 82.302Z"
                  fill="white"
                />
                <path
                  d="M145.891 111.269C143.695 109.594 142.141 107.159 141.507 104.399C140.872 101.639 141.2 98.7331 142.43 96.2013C142.68 95.6899 143.454 96.072 143.203 96.5841C142.053 98.9395 141.749 101.646 142.344 104.215C142.94 106.784 144.396 109.046 146.451 110.594C146.897 110.93 146.335 111.604 145.891 111.269Z"
                  fill="white"
                />
                <path
                  d="M140.336 135.076C145.84 134.237 150.839 131.265 154.335 126.753C154.681 126.305 155.329 126.889 154.983 127.336C151.336 132.024 146.128 135.106 140.398 135.966C139.852 136.048 139.792 135.157 140.336 135.076Z"
                  fill="white"
                />
                <path
                  d="M162.614 91.9906C163.334 92.8235 164.242 93.4568 165.255 93.8335C166.268 94.2102 167.355 94.3186 168.418 94.1489C168.963 94.0602 169.022 94.9509 168.48 95.0391C167.305 95.2209 166.105 95.0997 164.985 94.6861C163.864 94.2724 162.858 93.579 162.055 92.6663C161.972 92.5869 161.921 92.4776 161.913 92.3607C161.905 92.2438 161.94 92.128 162.01 92.0368C162.085 91.9477 162.19 91.8929 162.303 91.8843C162.416 91.8756 162.528 91.9138 162.614 91.9906Z"
                  fill="white"
                />
                <path
                  d="M216.264 137.42C215.926 137.486 215.587 137.551 215.245 137.623C210.695 138.529 206.224 139.818 201.874 141.479C201.536 141.601 201.195 141.731 200.861 141.864C190.423 145.981 180.808 152.084 172.506 159.862C169.205 162.961 166.135 166.316 163.322 169.901C159.438 174.851 155.604 180.54 150.528 183.768C150.002 184.111 149.456 184.419 148.894 184.692L119.653 172.041C119.6 171.978 119.545 171.921 119.492 171.857L118.302 171.408C118.435 171.212 118.576 171.013 118.709 170.817C118.786 170.704 118.869 170.593 118.945 170.479C118.998 170.404 119.052 170.329 119.096 170.259C119.113 170.234 119.131 170.209 119.146 170.191C119.19 170.12 119.238 170.059 119.279 169.995C120.067 168.877 120.865 167.762 121.672 166.651C121.674 166.644 121.674 166.644 121.684 166.64C127.851 158.192 134.748 150.206 142.588 143.567C142.824 143.367 143.062 143.16 143.312 142.966C146.861 139.994 150.638 137.329 154.604 134.998C156.78 133.729 159.016 132.576 161.303 131.543C167.218 128.886 173.497 127.215 179.91 126.593C192.695 125.355 205.716 128.395 215.521 136.772C215.772 136.987 216.016 137.199 216.264 137.42Z"
                  fill="#00CD70"
                />
                <path
                  d="M216.045 137.779C200.452 134.525 183.761 136.447 169.314 143.437C166.19 144.949 163.148 146.765 160.616 149.225C158.082 151.685 156.465 154.652 155.445 158.079C154.513 161.212 153.908 164.53 152.261 167.372C150.526 170.368 147.667 172.196 144.41 172.871C140.425 173.696 136.394 172.979 132.475 172.135C128.124 171.198 123.619 170.152 119.211 171.323C118.677 171.464 118.497 170.592 119.03 170.45C126.699 168.413 134.161 172.765 141.846 172.31C145.432 172.098 148.978 170.757 151.105 167.59C152.965 164.821 153.592 161.4 154.51 158.219C155.473 154.879 156.911 151.889 159.256 149.365C161.655 146.783 164.636 144.838 167.708 143.25C174.638 139.669 182.247 137.361 189.888 136.203C198.671 134.872 207.608 135.118 216.311 136.93C216.85 137.043 216.58 137.89 216.045 137.779Z"
                  fill="white"
                />
                <path
                  d="M173.894 140.907C173.108 138.19 173.272 135.27 174.359 132.667C175.446 130.065 177.384 127.951 179.828 126.702C180.323 126.451 180.72 127.242 180.224 127.493C177.947 128.652 176.142 130.622 175.134 133.047C174.127 135.472 173.984 138.193 174.731 140.719C174.893 141.268 174.055 141.453 173.894 140.907Z"
                  fill="white"
                />
                <path
                  d="M155.717 156.426C160.596 159.213 166.303 159.979 171.699 158.572C172.234 158.432 172.414 159.305 171.88 159.444C166.262 160.898 160.324 160.088 155.253 157.176C154.77 156.898 155.237 156.15 155.717 156.426Z"
                  fill="white"
                />
                <path
                  d="M198.374 136.015C198.469 137.132 198.828 138.208 199.419 139.145C200.011 140.082 200.816 140.851 201.763 141.383C202.249 141.654 201.782 142.403 201.299 142.133C200.256 141.54 199.367 140.69 198.712 139.656C198.056 138.622 197.653 137.437 197.538 136.203C197.518 136.088 197.54 135.969 197.601 135.87C197.662 135.772 197.757 135.701 197.866 135.673C197.977 135.648 198.092 135.671 198.188 135.735C198.283 135.799 198.35 135.899 198.374 136.015Z"
                  fill="white"
                />
                <path
                  d="M160.563 158.292C156.468 137.639 151.349 130.753 151.349 130.753L149.394 129.171L147.042 127.266L147.053 127.021L146.496 126.823L146.365 126.717L146.15 126.545L146.115 126.584L146.043 126.663L135.401 122.89L121.9 118.106L115.649 109.631C114.631 108.25 113.32 107.135 111.819 106.371C110.318 105.608 108.668 105.217 106.999 105.23L87.0329 105.384C84.3332 105.405 81.7388 106.478 79.7595 108.393L66.6363 121.09L46.1879 131.277L46.1533 131.241L45.9386 131.401L42.7791 132.976L42.9095 133.684L40.954 135.156C40.954 135.156 35.8352 141.563 31.7401 160.78C30.6984 165.67 30.1856 176.159 30.0005 189.085C29.9912 189.738 30.1067 190.388 30.3401 190.995C30.5735 191.602 30.9202 192.154 31.3602 192.62C31.8001 193.086 32.3245 193.456 32.9027 193.708C33.481 193.961 34.1016 194.091 34.7286 194.091H143.948L157.58 194.091C158.206 194.091 158.826 193.961 159.404 193.709C159.982 193.456 160.506 193.087 160.946 192.621C161.386 192.156 161.733 191.604 161.967 190.998C162.2 190.392 162.316 189.743 162.308 189.09C162.127 175.025 161.614 163.595 160.563 158.292Z"
                  fill="#3F3D56"
                />
                <path
                  d="M174.577 18.011C170.328 18.011 166.252 19.7717 163.248 22.9057C160.243 26.0398 158.555 30.2905 158.555 34.7227C158.555 39.1549 160.243 43.4056 163.248 46.5396C166.252 49.6737 170.328 51.4344 174.577 51.4344H293.978C298.227 51.4344 302.303 49.6737 305.307 46.5396C308.312 43.4056 310 39.1549 310 34.7227C310 30.2905 308.312 26.0398 305.307 22.9057C302.303 19.7717 298.227 18.011 293.978 18.011H174.577Z"
                  fill="#E5E5E5"
                />
                <path
                  d="M174.577 21.3931C171.188 21.3931 167.937 22.7974 165.54 25.2972C163.144 27.797 161.797 31.1874 161.797 34.7227C161.797 38.2579 163.144 41.6483 165.54 44.1481C167.937 46.6479 171.188 48.0522 174.577 48.0522H293.978C297.367 48.0522 300.618 46.6479 303.015 44.1481C305.411 41.6483 306.758 38.2579 306.758 34.7227C306.758 31.1874 305.411 27.797 303.015 25.2972C300.618 22.7974 297.367 21.3931 293.978 21.3931H174.577Z"
                  fill="white"
                />
                <path
                  d="M100.151 97.4062C114.597 97.4062 126.308 85.191 126.308 70.1228C126.308 55.0546 114.597 42.8394 100.151 42.8394C85.705 42.8394 73.9941 55.0546 73.9941 70.1228C73.9941 85.191 85.705 97.4062 100.151 97.4062Z"
                  fill="#FFC4CD"
                />
                <path
                  d="M93.4598 96.0699C93.348 95.8133 93.2366 95.5558 93.1255 95.2974C93.1683 95.2988 93.2107 95.3052 93.2535 95.3064L93.4598 96.0699Z"
                  fill="#2F2E41"
                />
                <path
                  d="M73.2668 44.0801C74.5898 42.9793 76.136 41.9665 77.8274 42.0234C79.5188 42.0802 81.2341 43.6737 80.8836 45.4006C83.9904 39.6629 88.9356 35.2499 94.851 32.9363C100.766 30.6227 107.274 30.5562 113.232 32.7487C117.436 34.2957 121.558 37.3907 122.524 41.9301C122.772 43.0953 122.825 44.3731 123.523 45.3193C124.402 46.5123 126.085 46.7822 127.47 46.3625C127.484 46.3583 127.498 46.3541 127.512 46.3498C127.746 46.2664 127.999 46.2582 128.237 46.3262C128.476 46.3943 128.689 46.5354 128.85 46.7314C129.011 46.9274 129.112 47.1693 129.139 47.4256C129.167 47.682 129.12 47.9412 129.005 48.1695L127.816 50.4834C129.315 50.832 130.873 50.7975 132.357 50.3827C132.604 50.3153 132.864 50.329 133.103 50.4221C133.341 50.5152 133.547 50.683 133.69 50.9026C133.834 51.1221 133.909 51.3826 133.905 51.6484C133.901 51.9142 133.818 52.1722 133.668 52.387C129.746 58.0038 123.193 61.6309 116.506 61.5882C111.754 61.558 106.952 59.8495 102.327 60.9895C100.538 61.4305 98.8655 62.2838 97.4336 63.4867C96.0016 64.6897 94.846 66.2117 94.0515 67.9413C93.257 69.6708 92.8439 71.564 92.8424 73.4818C92.8408 75.3996 93.251 77.2934 94.0427 79.0243C92.6218 77.4032 89.8749 77.787 88.4214 79.3765C86.968 80.966 86.5917 83.3383 86.7382 85.5296C86.9621 88.881 88.221 92.039 89.5481 95.1126C78.4212 94.7472 67.8969 86.6058 64.3545 75.5943C60.7971 64.5367 64.5253 51.3537 73.2668 44.0801Z"
                  fill="#2F2E41"
                />
                <path
                  opacity="0.2"
                  d="M55.7454 152.056L63.0819 193.691L67.4839 194.091L55.7454 152.056Z"
                  fill="black"
                />
                <path
                  opacity="0.2"
                  d="M136.558 148.916L129.222 193.661L124.82 194.091L136.558 148.916Z"
                  fill="black"
                />
                <path
                  d="M79.5241 30.0679C78.1174 30.6805 76.6496 30.0091 76.0341 28.4715C75.4091 26.9103 75.9928 25.3255 77.422 24.703C78.8514 24.0805 80.293 24.7535 80.9281 26.3399C81.5536 27.9024 80.9762 29.4354 79.5241 30.0679ZM76.8295 21.6301L75.7111 22.1173C75.4316 22.2375 75.1191 22.244 74.8354 22.1354C74.5516 22.0269 74.3172 21.8112 74.1786 21.531L74.1233 21.4181C72.9199 19.1543 72.7821 16.403 73.7159 13.2448C74.581 10.4117 74.9403 8.41928 74.2177 6.61421C73.3823 4.52722 71.5982 3.87347 69.3231 4.8205C68.4407 5.20482 68.16 5.19869 67.4656 5.9172C67.3145 6.07539 67.1345 6.20024 66.9362 6.28429C66.7379 6.36835 66.5254 6.40988 66.3113 6.40643C66.1044 6.40389 65.9001 6.35792 65.7106 6.27125C65.5211 6.18458 65.3503 6.059 65.2084 5.90199C64.9349 5.5994 64.7816 5.19983 64.7796 4.78397C64.7776 4.36811 64.927 3.96695 65.1975 3.6615C66.3647 2.3871 67.7724 1.37889 69.3319 0.70049C74.2201 -1.42853 76.6808 1.71466 77.8069 4.52752C78.9083 7.27882 78.2887 9.83157 77.2932 13.1376C76.459 15.8848 76.5076 17.9204 77.4552 19.9444C77.5237 20.0974 77.5617 20.2632 77.5671 20.432C77.5726 20.6007 77.5452 20.7689 77.4868 20.9263C77.4284 21.0838 77.34 21.2273 77.2271 21.3482C77.1141 21.4692 76.9789 21.5651 76.8295 21.6301Z"
                  fill="#00CD70"
                />
                <path
                  d="M200.951 42.693H177.195C177.119 42.6935 177.044 42.6785 176.974 42.6487C176.905 42.619 176.841 42.5751 176.787 42.5196C176.734 42.4641 176.691 42.3982 176.662 42.3255C176.633 42.2528 176.619 42.1748 176.619 42.0961C176.619 42.0174 176.633 41.9395 176.662 41.8668C176.691 41.7941 176.734 41.7281 176.787 41.6726C176.841 41.6172 176.905 41.5733 176.974 41.5435C177.044 41.5138 177.119 41.4987 177.195 41.4993H200.951C201.102 41.5004 201.247 41.5637 201.353 41.6755C201.46 41.7873 201.519 41.9385 201.519 42.0961C201.519 42.2537 201.46 42.4049 201.353 42.5167C201.247 42.6285 201.102 42.6919 200.951 42.693Z"
                  fill="#00CD70"
                />
                <path
                  d="M231.087 42.8919H207.331C207.255 42.8925 207.18 42.8775 207.111 42.8477C207.041 42.8179 206.977 42.7741 206.924 42.7186C206.87 42.6631 206.828 42.5971 206.799 42.5245C206.77 42.4518 206.755 42.3738 206.755 42.2951C206.755 42.2164 206.77 42.1384 206.799 42.0658C206.828 41.9931 206.87 41.9271 206.924 41.8716C206.977 41.8161 207.041 41.7723 207.111 41.7425C207.18 41.7128 207.255 41.6977 207.331 41.6983H231.087C231.163 41.6977 231.237 41.7128 231.307 41.7425C231.377 41.7723 231.441 41.8161 231.494 41.8716C231.548 41.9271 231.59 41.9931 231.619 42.0658C231.648 42.1384 231.663 42.2164 231.663 42.2951C231.663 42.3738 231.648 42.4518 231.619 42.5245C231.59 42.5971 231.548 42.6631 231.494 42.7186C231.441 42.7741 231.377 42.8179 231.307 42.8477C231.237 42.8775 231.163 42.8925 231.087 42.8919Z"
                  fill="#00CD70"
                />
                <path
                  d="M261.224 43.0909H237.468C237.317 43.0899 237.172 43.0265 237.066 42.9147C236.959 42.8029 236.899 42.6517 236.899 42.4941C236.899 42.3365 236.959 42.1853 237.066 42.0735C237.172 41.9617 237.317 41.8983 237.468 41.8972H261.224C261.299 41.8967 261.374 41.9117 261.444 41.9415C261.514 41.9712 261.578 42.0151 261.631 42.0706C261.685 42.1261 261.727 42.1921 261.756 42.2647C261.785 42.3374 261.8 42.4154 261.8 42.4941C261.8 42.5728 261.785 42.6507 261.756 42.7234C261.727 42.7961 261.685 42.8621 261.631 42.9176C261.578 42.973 261.514 43.0169 261.444 43.0467C261.374 43.0764 261.3 43.0915 261.224 43.0909Z"
                  fill="#00CD70"
                />
                <path
                  d="M291.36 43.2899H267.604C267.453 43.2888 267.308 43.2255 267.202 43.1136C267.095 43.0018 267.035 42.8506 267.035 42.693C267.035 42.5354 267.095 42.3842 267.202 42.2724C267.308 42.1606 267.453 42.0973 267.604 42.0962H291.36C291.511 42.0973 291.656 42.1606 291.762 42.2724C291.869 42.3842 291.928 42.5354 291.928 42.693C291.928 42.8506 291.869 43.0018 291.762 43.1136C291.656 43.2255 291.511 43.2888 291.36 43.2899Z"
                  fill="#00CD70"
                />
                <path
                  d="M189.021 37.2001C191.459 37.2001 193.436 35.1382 193.436 32.5947C193.436 30.0512 191.459 27.9893 189.021 27.9893C186.582 27.9893 184.605 30.0512 184.605 32.5947C184.605 35.1382 186.582 37.2001 189.021 37.2001Z"
                  fill="#00CD70"
                />
                <path
                  d="M219.339 37.2001C221.777 37.2001 223.754 35.1382 223.754 32.5947C223.754 30.0512 221.777 27.9893 219.339 27.9893C216.9 27.9893 214.923 30.0512 214.923 32.5947C214.923 35.1382 216.9 37.2001 219.339 37.2001Z"
                  fill="#00CD70"
                />
                <path
                  d="M249.658 37.2001C252.096 37.2001 254.073 35.1382 254.073 32.5947C254.073 30.0512 252.096 27.9893 249.658 27.9893C247.219 27.9893 245.242 30.0512 245.242 32.5947C245.242 35.1382 247.219 37.2001 249.658 37.2001Z"
                  fill="#00CD70"
                />
                <path
                  d="M279.975 37.2001C282.414 37.2001 284.391 35.1382 284.391 32.5947C284.391 30.0512 282.414 27.9893 279.975 27.9893C277.537 27.9893 275.56 30.0512 275.56 32.5947C275.56 35.1382 277.537 37.2001 279.975 37.2001Z"
                  fill="#00CD70"
                />
              </g>
              <defs>
                <clipPath id="clip0_8_177">
                  <rect width="296.758" height="220" fill="white" />
                </clipPath>
                <clipPath id="clip1_8_177">
                  <rect
                    width="280"
                    height="194.091"
                    fill="white"
                    transform="translate(30)"
                  />
                </clipPath>
              </defs>
            </svg>
          )}
          {svgNumber === 1 && (
            <svg
              width="288"
              height="200"
              viewBox="0 0 288 200"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_3_1868)">
                <path
                  d="M57.2742 200C88.9059 200 114.548 198.052 114.548 195.65C114.548 193.248 88.9059 191.3 57.2742 191.3C25.6425 191.3 0 193.248 0 195.65C0 198.052 25.6425 200 57.2742 200Z"
                  fill="#E6E6E6"
                />
                <path
                  d="M43.7793 44.3764C53.7467 40.1369 66.1614 42.823 78.2856 44.6873C78.3984 42.4296 79.7659 39.682 78.3334 38.1069C76.593 36.1934 76.7532 34.1828 77.8005 32.1607C80.4786 26.9897 76.6416 21.4874 72.7675 16.9124C71.8943 15.8835 70.7908 15.0752 69.5465 14.5529C68.3022 14.0306 66.9522 13.8092 65.6063 13.9067L58.4298 14.4193C56.6835 14.544 55.0169 15.1991 53.653 16.2969C52.2892 17.3947 51.2932 18.8828 50.7982 20.5622C49.0846 22.8927 48.1674 25.2168 48.7449 27.5288C46.1782 29.2829 45.7469 31.4002 46.9003 33.7871C47.8783 35.2899 47.8679 36.7529 46.8558 38.1753C45.5266 39.9888 44.5086 42.0109 43.8434 44.1587L43.7793 44.3764Z"
                  fill="#2F2E41"
                />
                <path
                  d="M280.647 172.897H77.4982C75.6916 172.897 73.9591 172.179 72.6816 170.902C71.4042 169.624 70.6865 167.892 70.6865 166.085C144.328 157.527 216.585 157.527 287.459 166.085C287.459 167.892 286.741 169.624 285.464 170.902C284.186 172.179 282.454 172.897 280.647 172.897Z"
                  fill="#2F2E41"
                />
                <path
                  d="M287.459 166.486L70.6865 166.085L95.8097 123.812L95.9298 123.612V30.4482C95.9298 29.3174 96.1524 28.1976 96.5851 27.1529C97.0178 26.1081 97.6521 25.1588 98.4517 24.3592C99.2513 23.5596 100.201 22.9254 101.245 22.4927C102.29 22.06 103.41 21.8373 104.541 21.8374H252.402C253.533 21.8373 254.653 22.06 255.698 22.4927C256.743 22.9254 257.692 23.5596 258.491 24.3592C259.291 25.1588 259.925 26.1081 260.358 27.1529C260.791 28.1976 261.013 29.3174 261.013 30.4482V124.253L287.459 166.486Z"
                  fill="#3F3D56"
                />
                <path
                  d="M104.344 27.8479C103.601 27.8487 102.888 28.1445 102.362 28.6703C101.836 29.1962 101.54 29.9091 101.54 30.6527V115.599C101.54 116.342 101.836 117.055 102.362 117.581C102.888 118.107 103.601 118.402 104.344 118.403H253.801C254.545 118.402 255.257 118.107 255.783 117.581C256.309 117.055 256.605 116.342 256.606 115.599V30.6527C256.605 29.9091 256.309 29.1962 255.783 28.6704C255.257 28.1446 254.545 27.8488 253.801 27.8479H104.344Z"
                  fill="white"
                />
                <path
                  d="M104.971 129.623C104.741 129.623 104.516 129.689 104.323 129.813C104.129 129.937 103.975 130.114 103.879 130.322L96.1374 147.151C96.0531 147.334 96.0163 147.536 96.0302 147.737C96.0442 147.938 96.1085 148.132 96.2172 148.302C96.326 148.472 96.4756 148.611 96.6525 148.708C96.8294 148.805 97.0277 148.856 97.2293 148.856H260.023C260.228 148.856 260.429 148.803 260.608 148.703C260.787 148.604 260.938 148.46 261.045 148.285C261.153 148.111 261.215 147.912 261.224 147.707C261.233 147.503 261.19 147.299 261.098 147.116L252.683 130.287C252.584 130.087 252.431 129.919 252.241 129.801C252.051 129.684 251.832 129.622 251.609 129.623H104.971Z"
                  fill="#2F2E41"
                />
                <path
                  d="M178.472 26.4455C179.467 26.4455 180.275 25.6383 180.275 24.6425C180.275 23.6466 179.467 22.8394 178.472 22.8394C177.476 22.8394 176.668 23.6466 176.668 24.6425C176.668 25.6383 177.476 26.4455 178.472 26.4455Z"
                  fill="white"
                />
                <path
                  d="M162.47 152.061C162.206 152.061 161.949 152.149 161.739 152.309C161.529 152.47 161.378 152.695 161.309 152.95L159.368 160.163C159.32 160.341 159.313 160.528 159.349 160.709C159.385 160.89 159.462 161.06 159.574 161.207C159.686 161.353 159.831 161.472 159.996 161.553C160.162 161.635 160.344 161.678 160.528 161.678H197.457C197.649 161.678 197.838 161.632 198.008 161.544C198.178 161.456 198.325 161.329 198.436 161.173C198.547 161.017 198.619 160.836 198.647 160.646C198.674 160.457 198.655 160.263 198.593 160.082L196.096 152.87C196.014 152.634 195.861 152.429 195.657 152.284C195.454 152.139 195.21 152.061 194.96 152.061H162.47Z"
                  fill="#2F2E41"
                />
                <path
                  d="M261.013 122.21V123.812H95.8096L95.9339 123.612V122.21H261.013Z"
                  fill="#2F2E41"
                />
                <path
                  d="M256.405 56.0963C271.896 56.0963 284.453 43.5387 284.453 28.0481C284.453 12.5576 271.896 0 256.405 0C240.915 0 228.357 12.5576 228.357 28.0481C228.357 43.5387 240.915 56.0963 256.405 56.0963Z"
                  fill="#00CD70"
                />
                <path
                  d="M268.025 40.2691H244.785C244.36 40.2687 243.953 40.0997 243.653 39.7992C243.352 39.4988 243.183 39.0913 243.183 38.6664V24.6423C243.183 24.2174 243.352 23.81 243.653 23.5095C243.953 23.209 244.36 23.04 244.785 23.0396H268.025C268.45 23.04 268.858 23.209 269.158 23.5095C269.459 23.81 269.628 24.2174 269.628 24.6423V38.6664C269.628 39.0913 269.459 39.4987 269.158 39.7992C268.858 40.0997 268.45 40.2687 268.025 40.2691ZM244.785 24.6423V38.6664H268.026L268.025 24.6423L244.785 24.6423Z"
                  fill="white"
                />
                <path
                  d="M263.618 24.6423H249.193V18.2313C249.193 13.5878 252.226 10.2175 256.405 10.2175C260.584 10.2175 263.618 13.5878 263.618 18.2313L263.618 24.6423ZM250.796 23.0395H262.015V18.2313C262.015 14.4566 259.708 11.8203 256.405 11.8203C253.102 11.8203 250.796 14.4566 250.796 18.2313L250.796 23.0395Z"
                  fill="white"
                />
                <path
                  d="M256.405 32.8564C257.291 32.8564 258.008 32.1388 258.008 31.2536C258.008 30.3685 257.291 29.6509 256.405 29.6509C255.52 29.6509 254.803 30.3685 254.803 31.2536C254.803 32.1388 255.52 32.8564 256.405 32.8564Z"
                  fill="white"
                />
                <path
                  d="M236.826 89.5906H121.32C120.753 89.5899 120.209 89.3643 119.808 88.9634C119.407 88.5624 119.182 88.0187 119.181 87.4516V58.7996C119.182 58.2325 119.407 57.6889 119.808 57.2879C120.209 56.8869 120.753 56.6613 121.32 56.6606H236.826C237.393 56.6613 237.936 56.8869 238.337 57.2879C238.738 57.6889 238.964 58.2325 238.965 58.7996V87.4516C238.964 88.0187 238.738 88.5624 238.337 88.9634C237.936 89.3643 237.393 89.5899 236.826 89.5906ZM121.32 57.5162C120.98 57.5166 120.654 57.652 120.413 57.8926C120.172 58.1332 120.037 58.4594 120.037 58.7996V87.4516C120.037 87.7919 120.172 88.1181 120.413 88.3587C120.654 88.5993 120.98 88.7346 121.32 88.735H236.826C237.166 88.7346 237.492 88.5993 237.733 88.3587C237.973 88.1181 238.109 87.7919 238.109 87.4516V58.7996C238.109 58.4594 237.973 58.1331 237.733 57.8925C237.492 57.6519 237.166 57.5166 236.826 57.5162L121.32 57.5162Z"
                  fill="#E6E6E6"
                />
                <path
                  d="M139.267 80.7392C144.079 80.7392 147.98 76.8382 147.98 72.0261C147.98 67.214 144.079 63.313 139.267 63.313C134.455 63.313 130.554 67.214 130.554 72.0261C130.554 76.8382 134.455 80.7392 139.267 80.7392Z"
                  fill="#E6E6E6"
                />
                <path
                  d="M159.391 66.2175C159.2 66.2172 159.011 66.2546 158.834 66.3274C158.658 66.4002 158.497 66.5072 158.362 66.6421C158.227 66.7769 158.12 66.9371 158.047 67.1135C157.974 67.2898 157.936 67.4788 157.936 67.6697C157.936 67.8606 157.974 68.0496 158.047 68.226C158.12 68.4023 158.227 68.5625 158.362 68.6974C158.497 68.8322 158.658 68.9392 158.834 69.012C159.011 69.0848 159.2 69.1222 159.391 69.1219H227.851C228.236 69.1219 228.605 68.9689 228.877 68.6965C229.15 68.4242 229.303 68.0548 229.303 67.6697C229.303 67.2846 229.15 66.9152 228.877 66.6429C228.605 66.3705 228.236 66.2175 227.851 66.2175H159.391Z"
                  fill="#E6E6E6"
                />
                <path
                  d="M159.39 74.9304C159.005 74.9304 158.636 75.0834 158.363 75.3557C158.091 75.6281 157.938 75.9975 157.938 76.3826C157.938 76.7677 158.091 77.1371 158.363 77.4094C158.636 77.6818 159.005 77.8348 159.39 77.8348H188.849C189.234 77.8348 189.603 77.6818 189.876 77.4094C190.148 77.1371 190.301 76.7677 190.301 76.3826C190.301 75.9975 190.148 75.6281 189.876 75.3557C189.603 75.0834 189.234 74.9304 188.849 74.9304H159.39Z"
                  fill="#E6E6E6"
                />
                <path
                  d="M95.5257 75.5178L74.1736 86.8696L73.9033 77.4098C80.8725 76.2464 87.5029 74.2208 93.6337 70.9231L95.8669 65.4059C96.1102 64.8047 96.5057 64.2771 97.0145 63.875C97.5233 63.4728 98.128 63.2099 98.7691 63.112C99.4103 63.014 100.066 63.0845 100.671 63.3165C101.277 63.5485 101.812 63.934 102.224 64.4352C102.826 65.1684 103.127 66.1032 103.066 67.0501C103.005 67.9971 102.586 68.8854 101.894 69.5351L95.5257 75.5178Z"
                  fill="#FFB8B8"
                />
                <path
                  d="M43.3538 141.059C43.198 140.487 43.165 139.89 43.2568 139.305C43.3486 138.72 43.5632 138.161 43.8864 137.665L48.5769 130.466C49.6863 128.764 51.3885 127.534 53.3534 127.016C55.3183 126.497 57.4057 126.727 59.2107 127.661C57.2394 131.016 57.5142 133.96 59.8914 136.518C56.2011 138.32 52.7903 140.645 49.7648 143.422C49.2124 143.798 48.5781 144.037 47.9147 144.119C47.2512 144.201 46.5778 144.123 45.9506 143.892C45.3234 143.661 44.7605 143.283 44.3088 142.79C43.8571 142.297 43.5297 141.704 43.3538 141.059Z"
                  fill="#2F2E41"
                />
                <path
                  d="M96.4981 131.647C95.7884 132.83 94.7865 133.811 93.5886 134.495C92.3907 135.179 91.0371 135.544 89.6576 135.554L58.4974 135.79L57.146 127.952L70.9302 123.898L59.3082 114.979L72.0113 100.114L95.0841 121.618C96.4327 122.875 97.3089 124.557 97.5663 126.382C97.8237 128.208 97.4466 130.066 96.4981 131.647Z"
                  fill="#2F2E41"
                />
                <path
                  d="M53.6319 185.251H46.3344C39.7823 140.339 33.1128 95.3011 52.8211 78.491L76.0651 82.2749L73.0921 102.005L60.1186 116.871L53.6319 185.251Z"
                  fill="#2F2E41"
                />
                <path
                  d="M60.0469 195.425C59.4756 195.581 58.8778 195.614 58.2929 195.522C57.7079 195.431 57.1489 195.216 56.6528 194.893L48.7292 193.828C47.0265 192.719 45.7967 191.016 45.2782 189.051C44.7597 187.087 44.9895 184.999 45.9231 183.194C49.2787 185.165 52.2222 184.89 54.7801 182.513C56.5875 186.162 59.5654 185.94 62.4094 189.014C62.786 189.567 63.0251 190.201 63.1069 190.864C63.1887 191.528 63.1108 192.201 62.8797 192.828C62.6485 193.456 62.2709 194.019 61.7781 194.47C61.2854 194.922 60.6918 195.249 60.0469 195.425Z"
                  fill="#2F2E41"
                />
                <path
                  d="M70.93 48.4901L57.416 45.2468C59.6605 40.6525 59.8453 35.5405 58.7674 30.1111L67.9569 29.8408C68.2448 36.5924 69.116 42.9002 70.93 48.4901Z"
                  fill="#FFB8B8"
                />
                <path
                  d="M74.644 90.4128C64.8064 97.1164 57.8554 90.6421 52.5509 80.6531C53.288 74.5408 52.0945 67.2261 49.8841 59.2781C48.9011 55.7725 49.2681 52.0257 50.9127 48.7775C52.5573 45.5293 55.3599 43.0156 58.7673 41.7329L70.3893 46.598C80.2578 54.6419 82.2002 63.355 78.4977 72.5448L74.644 90.4128Z"
                  fill="#00CD70"
                />
                <path
                  d="M46.3343 55.247L35.5231 60.9228L54.713 72.2746L57.3831 78.8609C57.5613 79.3005 57.6481 79.7718 57.6383 80.2461C57.6284 80.7203 57.522 81.1876 57.3257 81.6194C57.1293 82.0512 56.8471 82.4385 56.4961 82.7577C56.1452 83.0768 55.733 83.3212 55.2845 83.4758C54.7748 83.6516 54.2312 83.707 53.6965 83.6376C53.1617 83.5683 52.6503 83.3761 52.2023 83.0761C51.7542 82.7761 51.3817 82.3764 51.1138 81.9084C50.846 81.4404 50.6902 80.9168 50.6585 80.3785L50.3885 75.7882L25.9984 67.7395C25.1767 67.4683 24.4274 67.0137 23.8074 66.41C23.1873 65.8064 22.7128 65.0696 22.4196 64.2554C21.9924 63.0686 21.9706 61.7736 22.3578 60.5731C22.745 59.3726 23.5192 58.3344 24.5593 57.6208L44.1721 44.1655L46.3343 55.247Z"
                  fill="#FBBDBD"
                />
                <path
                  d="M57.1459 56.328C52.6369 54.352 48.5368 56.4985 43.3616 58.22L42.5508 43.8951C47.6896 41.1563 52.589 40.4178 57.1459 42.5438L57.1459 56.328Z"
                  fill="#00CD70"
                />
                <path
                  d="M62.5398 36.9362C67.305 36.9362 71.1679 33.0733 71.1679 28.3081C71.1679 23.5429 67.305 19.6799 62.5398 19.6799C57.7746 19.6799 53.9116 23.5429 53.9116 28.3081C53.9116 33.0733 57.7746 36.9362 62.5398 36.9362Z"
                  fill="#FFB8B8"
                />
                <path
                  d="M72.8616 27.2194C64.319 28.0561 57.8096 26.6589 53.6494 22.6809V19.4773H72.1367L72.8616 27.2194Z"
                  fill="#2F2E41"
                />
              </g>
              <defs>
                <clipPath id="clip0_3_1868">
                  <rect width="287.459" height="200" fill="white" />
                </clipPath>
              </defs>
            </svg>
          )}
        </div>
      )}
    </div>
  );
};

export default AuthSidebar;
