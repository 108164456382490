/* eslint-disable no-unused-expressions */
/* eslint-disable default-case */
/* eslint-disable react/jsx-no-undef */
import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import "../styles/Dashboard.css";
import { Tooltip } from "@material-ui/core";
import { history } from "../_helpers/history";
import { BASE_URL, LOGO_FULL_URL, LOGO_URL } from "../_helpers/constants";
import TestCases from "../_components/TestCases";
import TestRun from "../_components/TestRun";
import TestResult from "../_components/TestResult";
import Administrator from "../_components/Administrator";
import Analytics from "../_components/Analytics";
import Setting from "../_components/Setting";
import MergeProject from "../_components/MergeProject";
import Promises from "../_helpers/utils";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { confirmAlert } from "react-confirm-alert";
import Modal from "react-modal";
import { Link, Redirect } from "react-router-dom";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { MdSort, MdOutlineCreateNewFolder } from "react-icons/md";
import {
  IoEyeOutline,
  IoSearchOutline,
  IoReaderOutline,
} from "react-icons/io5";
import { Scrollbars } from "react-custom-scrollbars";
import "react-notifications/lib/notifications.css";
import Projectlist from "../_components/Projectlist";
import ProfilePopUp from "../_components/Navbar/ProfilePopUp";
import NotFound from "../_components/NotFound";
import Navbar from "../_components/Navbar/Navbar";
import ProjectCardSkeleton from "../skeletons/ProjectCard";
import MainActionPopup from "../_components/MainActionPopup";
// import InfiniteScroll from "react-infinite-scroll-component";
export default class Pages_dashboard extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.toggle = this.toggle.bind(this);
    this.updatedAiState = this.toggle.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.toggleprofile = this.toggleprofile.bind(this);
    this.togglebadge = this.togglebadge.bind(this);
    this.handler = this.handler.bind(this);
  }

  state = {
    importState: 0,
    aiState: 0,
    viewState: 0,
    selectedTab: localStorage.getItem("tab")
      ? localStorage.getItem("tab")
      : "test-case",
    isDrawerVisible: false,
    search: "",
    PROJECTS: [],
    titleName: "",
    selectIndex: 0,
    selectedProjectId: "",
    loading: false,
    MainActionPopupLoading: false,
    name: "",
    description: "",
    editProjectID: "",
    createModalVisible: false,
    isEditProjectName: false,
    error: "",
    onSortBy: 0,
  };

  /*
  .##........#######...######...####..######.
  .##.......##.....##.##....##...##..##....##
  .##.......##.....##.##.........##..##......
  .##.......##.....##.##...####..##..##......
  .##.......##.....##.##....##...##..##......
  .##.......##.....##.##....##...##..##....##
  .########..#######...######...####..######.
  */

  componentDidMount() {
    Promises.setPage("dashboard");
    this.setState({ loading: true }, () => {
      this.fetchProjects();
      // this.fetchUserProfile();
      this.checkRouteLinks();
    });
  }

  checkRouteLinks() {
    const { projectname } = this.props.match.params;
    let projectId = "";
    if (projectname !== undefined) {
      console.log("Router Link-> " + projectname);

      var projectlist = JSON.parse(window.localStorage.getItem("projects"));
      console.log("String-->" + projectname);
      for (var i = 0; i < projectlist?.length; i++) {
        if (projectlist[i].name === projectname) {
          projectId = projectlist[i].id;
        }
      }
      this.setState({
        selectedTab: localStorage.getItem("tab")
          ? localStorage.getItem("tab")
          : "test-case",
        titleName: projectname,
        selectedProjectId: projectId,
      });
      this.setState({ viewState: 1 });
      console.log("1 ", this.state.selectedTab);
      // if (this.child.current) {
      //   this.child.current.changeIndex(projectId);
      // }
    }
  }

  handler(type = 1) {
    if (type === 1) {
      this.setState({ selectedTab: "test-result" });
    } else {
      this.setState({ selectedTab: "test-case" });
    }
    console.log("2 ", this.state.selectedTab);
  }
  handlerAdmin() {
    this.setState({ selectedTab: "administrator" });
    this.setState({ viewState: 2 });
  }
  onLogout() {
    localStorage.clear();
  }

  fetchProjects = () => {
    this.setState({ loading: true });
    console.log("Fetch Projects");
    const token = Promises.getToken();
    if (!token) {
      history.push("/login?redirect=1");
    }
    // console.log(token);
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BASE_URL}projects`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result, result.data.length);
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        if (result.data) {
          Promises.setLocalIteam(
            "projectLen",
            result.data.length > 0 ? "1" : "0"
          );
        }

        if (result.status && result.data.length > 0) {
          Promises.setLocalIteam("projects", JSON.stringify(result.data));
          const projectName = Promises.getLocalIteam("projectName");
          const projectRole = Promises.getLocalIteam("projectRole");
          Promises.setLocalIteam(
            "projectRole",
            projectRole || result.data[0].role
          );
          Promises.setLocalIteam(
            "projectName",
            projectName || result.data[0].name
          );
          const pId = Promises.getLocalIteam("projectId");
          Promises.setLocalIteam("projectId", pId || result.data[0].id);
          this.setState({
            selectedTab: localStorage.getItem("tab")
              ? localStorage.getItem("tab")
              : "test-case",
            PROJECTS: result.data,
            projectRole: projectRole || result.data[0].role,
            titleName: projectName || result.data[0].name,
            selectedProjectId: pId || result.data[0].id,
            loading: false,
          });
          if (this.child.current) {
            this.child.current.changeIndex(pId || result.data[0].id);
          }
          console.log("3 ", this.state.selectedTab);
        } else {
          this.setState({
            selectedTab: localStorage.getItem("tab")
              ? localStorage.getItem("tab")
              : "test-case",
            PROJECTS: result.data,
            loading: false,
          });
          Promises.deleteLocalIteam("projectName");
          Promises.deleteLocalIteam("projectRole");
          Promises.deleteLocalIteam("projectId");
          this.setState({ loading: false });
          console.log("4 ", this.state.selectedTab);
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ loading: false });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }
  toggle1() {
    this.setState((prevState) => ({
      dropdownOpen1: !prevState.dropdownOpen1,
    }));
  }
  toggleprofile() {
    this.setState((prevState) => ({
      dropdownOpenprofile: !prevState.dropdownOpenprofile,
    }));
  }
  togglebadge() {
    this.setState((prevState) => ({
      dropdownOpenbadge: !prevState.dropdownOpenbadge,
    }));
  }
  togglescreen(e) {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  setActiveTab = (tab, subtab, moretab, toggleTab, e) => {
    this.setState({ Tab: tab, SubTab: subtab, MoreTab: moretab });
    if (subtab === "email") {
      this.setState({ toggleEmail: toggleTab });
    } else if (subtab === "form") {
      this.setState({ toggleForm: toggleTab });
    } else if (subtab === "chart") {
      this.setState({ toggleChart: toggleTab });
    } else if (subtab === "table") {
      this.setState({ toggleTable: toggleTab });
    } else if (subtab === "icon") {
      this.setState({ toggleIcon: toggleTab });
    } else if (subtab === "map") {
      this.setState({ toggleMap: toggleTab });
    } else if (subtab === "emt") {
      this.setState({ toggleEmailTemp: toggleTab });
    } else {
    }
  };

  validateProjectName(e) {
    if (e.target.value.match("^[a-zA-Z0-9_-]*$") != null) {
      this.setState({ name: e.target.value });
    }
  }

  onCreate = (pname, pdesc) => {
    this.setState({ MainActionPopupLoading: true });
    let { PROJECTS } = this.state;
    let name, description;
    if (typeof pname !== "object" && pname !== 0) {
      name = pname;
      description = pdesc;
      this.setState({
        name: pname,
        description: pdesc,
      });
    } else {
      name = this.state.name;
      description = this.state.description;
    }
    const token = Promises.getToken();

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var raw = JSON.stringify({
      name: this.capitalizeFirstLetter(name),
      description: description,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BASE_URL}projects`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (JSON.parse(result).code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        if (JSON.parse(result).isDuplicateProject === true) {
          // alert("Duplicate project")
          NotificationManager.error(
            "Project name already exists. Please enter a different name."
          );
          return;
        }
        if (JSON.parse(result).code === 200) {
          const tempArr = PROJECTS;

          tempArr.push({
            name: name,
            description: description,
          });

          this.setState({
            name: "",
            type: "",
            description: "",
            PROJECTS: tempArr,
            createModalVisible: false,
            viewState: 0,
          });
          const val = {
            id: result.projectid,
            name: this.capitalizeFirstLetter(name),
          };

          this.selectedProject(val, 0, 0);
          // setIndexTitle
          // this.setState({loadingTC:false})
          NotificationManager.success("Project created successfully");
          Promises.deleteLocalIteam("projectName");
          Promises.deleteLocalIteam("projectRole");
          Promises.deleteLocalIteam("projectId");
          Promises.deleteLocalIteam("tab");
          this.fetchProjects();
          // this.fetchUserProfile()
        } else {
          NotificationManager.error(
            "Something went wrong with creating project, Please try again later"
          );
        }
      })
      .catch((error) => {
        // console.log('error', error)
        NotificationManager.error(
          "Something went wrong with creating project, Please try again later"
        );
        localStorage.clear();
        history.push("/login?redirect=1");
      })
      .finally(() => {
        this.setState({ MainActionPopupLoading: false });
      });
  };
  capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  deleteProject = (projectID) => {
    const token = Promises.getToken();

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BASE_URL}projects/${projectID}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(JSON.parse(result));
        if (JSON.parse(result).code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        if (JSON.parse(result).code == 200) {
          NotificationManager.success("Project deleted successfully");
          // alert('Project deleted successfully')
          this.fetchProjects();
        } else {
          NotificationManager.error(
            "Something went wrong with creating project, Please try again later"
          );
        }
      })
      .catch((error) => {
        // console.log('error', error)
        NotificationManager.error(
          "Something went wrong with creating project, Please try again later"
        );

        localStorage.clear();
        history.push("/login?redirect=1");
      });
  };

  editProjectName = (projectID, projectName, projectDescription) => {
    console.log(projectID, " ** ", projectName);

    this.setState(
      {
        createModalVisible: true,
        viewState: 0,
        isEditProjectName: true,
        name: projectName,
        description: projectDescription,
        editProjectID: projectID,
      },
      () => {
        console.log(this.state);
      }
    );
  };

  onUpdateProjectName = () => {
    this.setState({ MainActionPopupLoading: true });
    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var raw = JSON.stringify({
      name: this.capitalizeFirstLetter(this.state.name),
      // description: this.state.description,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: raw,
    };

    fetch(
      `${BASE_URL}projects/name/${this.state.editProjectID}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        console.log(JSON.parse(result));
        if (JSON.parse(result).code === 200) {
          this.setState({
            createModalVisible: false,
            viewState: 0,
            name: "",
            description: "",
            editProjectID: "",
            isEditProjectName: false,
          });
          NotificationManager.success("Project Update successfully");
          // <Redirect to="/dashboard" />;
          // alert('Project deleted successfully')
          this.fetchProjects();
        } else {
          NotificationManager.error(
            "Something went wrong with Updating project, Please try again later"
          );
        }
      })
      .catch((error) => {
        // console.log('error', error)
        NotificationManager.error(
          "Something went wrong with Updating project, Please try again later"
        );
      })
      .finally(() => {
        this.setState({ MainActionPopupLoading: false });
      });
  };

  toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState({ isDrawerVisible: open });
  };

  onDelete = (projectID) => {
    console.log(projectID);
    confirmAlert({
      className: "delete-project-modal",
      message: "Are you sure to delete this project?",
      buttons: [
        {
          className: "btn btn-primary delete-project-yes",
          label: "Yes",
          onClick: () => this.deleteProject(projectID),
        },
        {
          className: "btn btn-secondary delete-project-no",
          label: "Cancel",
          onClick: () => console.log("canceled"),
        },
      ],
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    const { project } = this.state;
    this.setState({
      user: {
        ...project,
        [name]: value,
      },
    });
  };
  selectedProject = (val, isImport, isGenerate) => {
    this.setState({
      selectedTab: "test-case",
      titleName: val.name,
      selectedProjectId: val.id,
    });
    console.log("4 ", this.state.selectedTab);
    this.setState({ importState: isImport, aiState: isGenerate });
    console.log("aiState", this.state.aiState);

    this.setState({ viewState: 1 });
    this.props.history.push(`dashboard/${val.name}`);

    if (this.child.current) {
      this.child.current.changeIndex(val.id);
    }
  };

  onSortBy = (event) => {
    console.log("on Select ", typeof event, this.state.PROJECTS);
    let filterData;

    if (parseInt(event) === 1) {
      console.log("Inside");
      filterData = this.state.PROJECTS.sort((a, b) => {
        if (a.firstname.toUpperCase() < b.firstname.toUpperCase()) {
          return -1;
        }
        if (a.firstname.toUpperCase() > b.firstname.toUpperCase()) {
          return 1;
        }

        // names must be equal
        return 0;
      });
    }
    if (parseInt(event) === 2) {
      filterData = this.state.PROJECTS.sort(
        (a, b) =>
          new Date(b.last_executed_timestamp) -
          new Date(a.last_executed_timestamp)
      );
    }

    this.setState({ PROJECTS: filterData });
    console.log("on Select ", event, filterData);
  };

  selectedNavTab = (tabName) => {
    this.setState({ selectedTab: tabName });
    localStorage.setItem("tab", tabName);
  };

  /*
  ..######...#######..##.....##.########...#######..##....##.########.##....##.########
  .##....##.##.....##.###...###.##.....##.##.....##.###...##.##.......###...##....##...
  .##.......##.....##.####.####.##.....##.##.....##.####..##.##.......####..##....##...
  .##.......##.....##.##.###.##.########..##.....##.##.##.##.######...##.##.##....##...
  .##.......##.....##.##.....##.##........##.....##.##..####.##.......##..####....##...
  .##....##.##.....##.##.....##.##........##.....##.##...###.##.......##...###....##...
  ..######...#######..##.....##.##.........#######..##....##.########.##....##....##...
  */

  render() {
    const { viewState } = this.state;

    switch (viewState) {
      case 0:
        return (
          <div id="main-body">
            {this.renderHeader4()}
            {this.renderBody1()}
            {this.onProjectCreate()}
            <NotificationContainer />
          </div>
        );
      case 1:
        return this.renderMainView();
      case 2:
        return this.renderInviteView();
    }
  }
  renderHeader4 = () => {
    // const { loading } = this.state;
    // const { selectedTab, titleName } = this.state;
    const selectInvite = () => {
      this.setState({ viewState: 2, selectedTab: "administrator" });
    };

    return <Navbar selectInvite={selectInvite} />;
  };

  renderProjectListHeader = () => {
    return (
      <Navbar
        selectDashboard={() => {
          this.setState({ viewState: 0 });
          this.props.history.push("/dashboard");
        }}
      />
    );
  };

  renderBody1 = () => {
    const { PROJECTS, search } = this.state;
    // const filteredProjects = PROJECTS.filter(el =>
    //   el.name.toLowerCase().includes(search.toLowerCase())
    // );

    // const getClassName = (index) => {
    //   const itemsPerRow = 3; // Adjust according to your grid-cols-x setting
    //   const isLastRow = index >= Math.floor(filteredProjects.length / itemsPerRow) * itemsPerRow;
    //   return `col-sm-3- project-list-details !border-gray-100 shadow-[rgba(0,_0,_0,_0.2)_0px_1px_2px] !flex-grow ${isLastRow ? 'justify-start' : 'justify-between'}`;
    // };

    return (
      <div className="project-list-section !px-[78px]">
        <div className="row ">
          <div className="project-list-title !flex !flex-row !justify-between !pr-0">
            <h2 className="text-[#181059]" style={{ fontWeight: 500 }}>
              Projects{" "}
              {/* <span className="pl-1 text-[#316BFF]">({PROJECTS?.length})</span> */}
            </h2>

            {/* <button
              type="button"
              class="btn btn-secondary"
              onClick={() => {
                this.setState(
                  { createModalVisible: true, viewState: 1 },
                  () => {
                    console.log(this.state);
                  }
                );
              }}
            >
              + Add new project
            </button> */}

            <div
              className="d-flex align-items-center"
              // style={{ paddingRight: 80, marginTop: 16 }}
            >
              <div className="flex flex-row gap-x-3">
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <input
                    autoComplete="off"
                    type="text"
                    className="form-control !placeholder-[#316BFF]"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => this.setState({ search: e.target.value })}
                    style={{
                      border: "0.3px solid #316BFF",
                      borderRadius: 8,
                      height: 40,
                      fontSize: 12,
                      width: 230,
                      background: "rgba(146, 198, 255, 0.1)",
                    }}
                  />
                  <span
                    style={{
                      position: "absolute",
                      color: "#316BFF",
                      top: 12,
                      right: 22,
                    }}
                  >
                    <IoSearchOutline />
                  </span>
                </div>
              </div>

              <div
                className="form-select  !flex !flex-row !px-3 !items-center border rounded-md !justify-center  text-[#3B4147] ml-3"
                style={{ borderRadius: 8 }}
              >
                <select
                  className="bg-none border-none flex-grow"
                  style={{
                    height: 43,
                    fontSize: 12,
                    fontWeight: 300,
                    width: 109,
                  }}
                  onChange={(e) => this.onSortBy(e.target.value)}
                  defaultValue={"Sort by"}
                >
                  {/* <option selected>Sort by: Last Created</option> */}
                  <option value="1">Created by me</option>
                  <option value="2">Last Executed</option>
                </select>

                <svg
                  width="8"
                  height="4"
                  viewBox="0 0 8 4"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.666748 0.666668L4.00008 4L7.33341 0.666668H0.666748Z"
                    fill="#27AE60"
                  />
                </svg>
              </div>
            </div>
          </div>

          <ul className="project-row project-outer-div grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4  md:m-auto !pr-0 !mr-0 gap-x-6 gap-y-6 ">
            {!search && (
              <li
                style={{ height: 220, borderWidth: 2 }}
                className=" project-list-details"
                onClick={() => {
                  this.setState(
                    { createModalVisible: true, viewState: 0 },
                    () => {
                      console.log(this.state);
                    }
                  );
                }}
              >
                <Projectlist isNewProjectButton={true} />
              </li>
            )}
            {this.state.loading &&
              Array(11)
                .fill(0)
                .map((it, i) => {
                  return (
                    <li
                      style={{ height: 220, borderWidth: 2 }}
                      className="project-list-details"
                    >
                      <ProjectCardSkeleton />
                    </li>
                  );
                })}

            {PROJECTS &&
              PROJECTS.filter((el) =>
                el.name.toLowerCase().includes(search.toLowerCase())
              ).map((ele, index) => {
                return (
                  <li
                    style={{ height: 220, borderWidth: 2 }}
                    className=" project-list-details "
                  >
                    <Projectlist
                      indexVal={index}
                      ProjectName={ele.name}
                      Createdby={ele.firstname}
                      lastRun={ele.last_executed_timestamp}
                      UsersCount={ele.totalUser}
                      TestcaseCount={ele.testCaseCount}
                      TotalPendingRequest={ele.totalPendingRequest}
                      Otherprops={ele}
                      deleteProject={this.onDelete}
                      editProjectName={this.editProjectName}
                      SelectedProject={this.selectedProject}
                    />
                  </li>
                );
              })}

            {search &&
              PROJECTS.filter((el) =>
                el.name.toLowerCase().includes(search.toLowerCase())
              ).length === 0 && (
                <div
                  className="flex flex-col justify-center items-center !w-full"
                  style={{ paddingTop: 120 }}
                >
                  <NotFound
                    subject={"Project"}
                    search={search}
                    clear={() => {
                      this.setState({ search: "" });
                    }}
                    add={() => {
                      this.setState(
                        { createModalVisible: true, viewState: 1 },
                        () => {
                          console.log(this.state);
                        }
                      );
                    }}
                  />
                </div>
              )}
          </ul>
        </div>
      </div>
    );
  };
  renderMainView = () => {
    const { isDrawerVisible } = this.state;

    return (
      <div id="main-body">
        <div fluid="true" style={{ height: "100vh" }}>
          <div class="row" style={{ height: "130vh" }}>
            {/* <Col className="main-col" lg={2}>
              {this.renderSideBar()}
            </Col> */}
            <Col id="main-col-2" lg={12}>
              {this.renderHeader1()}
              {this.renderHeader2()}
              {this.renderBody()}
            </Col>
          </div>
        </div>
        {this.onProjectCreate()}
        <NotificationContainer />
      </div>
    );
  };

  renderInviteView = () => {
    return (
      <div id="main-body">
        <div>
          <Row>
            {/* <Col className="main-col" lg={2}>
              {this.renderSideBar()}
            </Col> */}
            <Col id="main-col-2" lg={12}>
              {/* {this.renderHeader1()}
              {this.renderHeader2()} */}
              {this.renderProjectListHeader()}
              {this.renderBody()}
            </Col>
          </Row>
        </div>
        <NotificationContainer />
      </div>
    );
  };

  setIndexTitle = (i, ele) => {
    console.log(ele);
    this.setState({
      selectedTab: localStorage.getItem("tab")
        ? localStorage.getItem("tab")
        : "test-case",
      titleName: ele.name,
      selectedProjectId: ele.id,
    });
    console.log("5 ", this.state.selectedTab);
    Promises.setLocalIteam("projectName", ele.name);
    Promises.setLocalIteam("projectRole", ele.role);
    Promises.setLocalIteam("projectId", ele.id);
    if (this.child.current) {
      this.child.current.changeIndex(ele.id);
    }
  };
  changeProjectMergeParent = (id, name, role) => {
    this.setState({
      selectedTab: "merge",
      titleName: name,
      selectedProjectId: id,
    });
    Promises.setLocalIteam("projectName", name);
    Promises.setLocalIteam("projectRole", role);
    Promises.setLocalIteam("projectId", id);
    if (this.child.current) {
      this.child.current.changeIndex(id);
    }
  };
  renderSideBar = () => {
    const { PROJECTS, search, loading, titleName } = this.state;

    return (
      <div className="drawerBody">
        <div className="company-name">
          {/* <img width="65" height="65" src={LOGO_URL} />
           */}
          <div className="circular-logo-name">
            {Promises.getLocalIteam("domain").charAt(0).toUpperCase()}
          </div>
          <span className="cmp-name">{Promises.getLocalIteam("domain")}</span>
        </div>
        <div className="row-con" style={{ marginBottom: 10 }}>
          <span>Projects</span>
          <Tooltip title="Click to create a new Project">
            <div
              style={{ cursor: "pointer" }}
              className="add-project"
              onClick={() => this.setState({ createModalVisible: true })}
            >
              + Add
            </div>
          </Tooltip>
        </div>
        <div className="form-group project-search-sidebar">
          <div className="form-group">
            <input
              autoComplete="off"
              type="text"
              className="form-control"
              id="username"
              placeholder="Search"
              value={search}
              onChange={(e) => this.setState({ search: e.target.value })}
            />
            <span>
              <img
                src={process.env.PUBLIC_URL + "/assets/images/search-icon.svg"}
                alt="search-icon-img"
              />
            </span>
          </div>
          {/* <input autoComplete="off" type="text" className="form-control" id="username" placeholder="Search Project" value={search} onChange={(e) => this.setState({ search: e.target.value })} /> */}
        </div>
        {loading && (
          <SkeletonTheme color="lightgray" highlightColor="#f9f9f9">
            <p>
              <Skeleton count={5} height={40} />
            </p>
          </SkeletonTheme>
        )}
        {/* {!loading && PROJECTS.length === 0 && (
          <div style={{ textAlign: 'center' }}>
            <span>No Projects Found, <br /> Go ahead and create your <br />
              <span onClick={() => this.setState({ createModalVisible: true })} style={{ color: 'green', fontSize: 22, textDecorationLine: 'underline', cursor: 'pointer' }}>First Project</span>
            </span>
          </div>
        )} */}
        <Scrollbars style={{ height: "500px" }}>
          {PROJECTS.filter((el) =>
            el.name.toLowerCase().includes(search.toLowerCase())
          ).map((ele, index) => {
            return (
              <div
                key={`${index}s`}
                onClick={() => this.setIndexTitle(index, ele)}
                className={
                  titleName === ele.name
                    ? "project-tab-selected"
                    : "project-tab"
                }
              >
                {ele.name}
              </div>
            );
          })}
        </Scrollbars>
      </div>
    );
  };

  onProjectCreate = () => {
    const { name, description, createModalVisible, isEditProjectName } =
      this.state;

    return (
      <div style={{ display: createModalVisible ? "block" : "hidden" }}>
        {createModalVisible && (
          <MainActionPopup
            label={`${isEditProjectName ? "Update" : "Create New"} Project`}
            successText={`${isEditProjectName ? "Update" : "Create"} Project`}
            successTick={true}
            onSuccess={
              isEditProjectName ? this.onUpdateProjectName : this.onCreate
            }
            secondaryText={"Cancel"}
            secondaryIsAbort={true}
            onSecondary={() => {
              console.log("cancelled");
              this.setState({
                createModalVisible: false,
                viewState: 0,
                isEditProjectName: false,
              });
            }}
            inputFieldLabel={"Project Name"}
            inputFieldPlaceholder={"Name your project..."}
            onInputFieldChange={(e) => {
              e.target.value.match("^[a-zA-Z0-9_-]*$") != null
                ? this.setState({ name: e.target.value, error: "" })
                : this.setState({
                    error: "Only alpha numeric  _ and - are allowed",
                  });
            }}
            inputFieldText={name}
            textAreaLabel={"Project Description"}
            textAreaPlaceholder={"Enter description for your project.."}
            onTextareaChange={(e) =>
              this.setState({ description: e.target.value })
            }
            textareaText={description}
            height={483}
            successLoading={this.state.MainActionPopupLoading}
            bgOpacity={0.4}
          />
        )}
      </div>
    );
  };

  updatedImportState = (importState) => {
    this.setState({ importState: importState });
  };
  updatedAiState = (aiState) => {
    console.log("aiState:", aiState);
    if (aiState === 0) {
      console.log("in");
      this.setState({ viewState: 0 });
      this.props.history.push("/dashboard");
    }
    this.setState({ aiState: aiState }, () => {
      console.log(this.state.aiState);
    });
  };

  renderHeader1 = () => {
    const { loading } = this.state;

    // return (
    //   // <Container fluid className="header-main">
    //   //   <header id="topnav">
    //   //     <div className="topbar-main">
    //   //       <div className="container-fluid">
    //   //         <div className="menu-extras topbar-custom">
    //   //           <ul className="list-inline float-right mb-0">
    //   //             <li className="list-inline-item dropdown notification-list">
    //   //               <div className="title-con">
    //   //                 <div>
    //   //                   <span className="header-title">{loading ? (
    //   //                     <SkeletonTheme color="lightgray" highlightColor="#f9f9f9">
    //   //                       <Skeleton height={40} width={300} />
    //   //                     </SkeletonTheme>
    //   //                   ) : this.state.titleName}</span>
    //   //                 </div>
    //   //               </div>
    //   //             </li>
    //   //             <li className="list-inline-item dropdown notification-list">
    //   //               <Dropdown isOpen={this.state.dropdownOpenprofile} toggle={this.toggleprofile}>
    //   //                 <DropdownToggle className="nav-link dropdown-toggle droptest arrow-none waves-effect nav-user" tag="a">
    //   //                   <img src="assets/images/users/avatar-4.jpg" alt="user" className="rounded-circle" />
    //   //                 </DropdownToggle>
    //   //                 <DropdownMenu>
    //   //                   <DropdownItem><i className="mdi mdi-account-circle m-r-5"></i> Profile</DropdownItem>
    //   //                   {/* <DropdownItem><i className="mdi mdi-wallet m-r-5"></i> My Wallet</DropdownItem> */}
    //   //                   {/* <DropdownItem><span className="badge badge-success float-right">11</span><i className="mdi mdi-settings m-r-5"></i> Settings</DropdownItem> */}
    //   //                   <DropdownItem><i className="mdi mdi-lock-open-outline m-r-5"></i> Lock screen</DropdownItem>
    //   //                   <DropdownItem><Link onClick={this.setActiveTab.bind(this, 'pages', 'Pages_login_2', '')} to="/"><i className="mdi mdi-power text-danger"></i> Logout</Link></DropdownItem>
    //   //                 </DropdownMenu>
    //   //               </Dropdown>
    //   //             </li>
    //   //           </ul>
    //   //         </div>
    //   //       </div>
    //   //     </div>
    //   //   </header>
    //   // </Container>
    // )
  };

  renderHeader2 = () => {
    const { loading } = this.state;
    const { selectedTab, titleName } = this.state;

    return (
      <header id="topnav" className="">
        <div className="topbar-main  !bg-primary-green !h-[48px] !px-[18px]">
          <div className="container-fluid d-flex align-items-center justify-content-between !justify-between">
            <div className="logo gap-x-3 text-[17px] font-raleway !font-[500]">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer"
                onClick={() => {
                  this.setState({ viewState: 0 });
                  this.props.history.push("/dashboard");
                }}
              >
                <path
                  d="M9.83333 16.5H9C4.85783 16.5 1.5 13.1422 1.5 9C1.5 4.85787 4.85783 1.5 9 1.5H9.83333M9.83333 12.3333L6.5 9M6.5 9L9.83333 5.66667M6.5 9H16.5"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              {titleName}
            </div>

            <div className="menu-extras topbar-custom">
              <ProfilePopUp />
            </div>
            {/* <div className="clearfix"></div> */}
          </div>
        </div>

        {this.state.importState === 0 && (
          <div className="navbar-custom !bg-[#fff] !px-[18px]">
            <div
              className="container-fluid d-flex align-items-center justify-content-between "
              style={{
                boxShadow: "0px 1px 2px 0px #0000000F",

                // boxShadow: "0px 1px 3px 0px #0000001A",
              }}
            >
              <div id="navigation">
                <ul className="navigation-menu">
                  <li
                    className={`has-submenu ${
                      selectedTab === "test-case" && `active`
                    }`}
                    style={{ color: "#4A4A4A", BorderColor: "black" }}
                  >
                    <Link
                      to="#"
                      onClick={() => this.selectedNavTab("test-case")}
                      style={{ color: "#4A4A4A", BorderColor: "#E1E1E1" }}
                    >
                      Test Plan
                    </Link>
                  </li>
                  <li
                    className={`has-submenu ${
                      selectedTab === "test-run" && `active`
                    }`}
                  >
                    <Link
                      to="#"
                      onClick={() => this.selectedNavTab("test-run")}
                      style={{ color: "#4A4A4A" }}
                    >
                      Test Run
                    </Link>
                  </li>
                  <li
                    className={`has-submenu ${
                      selectedTab === "test-result" && `active`
                    }`}
                  >
                    <Link
                      to="#"
                      onClick={() => this.selectedNavTab("test-result")}
                      style={{ color: "#4A4A4A" }}
                    >
                      Test Result
                    </Link>
                  </li>
                  <li
                    className={`has-submenu project-float-left ${
                      selectedTab === "analytics" && `active`
                    }`}
                  >
                    <Link
                      to="#"
                      onClick={() => this.selectedNavTab("analytics")}
                      style={{ color: "#4A4A4A" }}
                    >
                      Analytics
                    </Link>
                  </li>
                  <li
                    className={`has-submenu ${
                      selectedTab === "merge" && `active`
                    }`}
                  >
                    <Link
                      to="#"
                      onClick={() => this.selectedNavTab("merge")}
                      style={{ color: "#4A4A4A" }}
                    >
                      Merge
                    </Link>
                  </li>
                  <li
                    className={`has-submenu ${
                      selectedTab === "setting" && `active`
                    }`}
                  >
                    <Link
                      to="#"
                      onClick={() => this.selectedNavTab("setting")}
                      style={{ color: "#4A4A4A" }}
                    >
                      Settings
                    </Link>
                  </li>
                  {/* <li className={`has-submenu li-float-left`}>
                  <Link
                    to="#"
                    onClick={() => {
                      this.setState({ viewState: 0 });
                      this.props.history.push("/dashboard");
                    }}
                  >
                    Goto Dashboard
                  </Link>
                </li> */}
                  {/* <li className={`has-submenu project-float-left ${selectedTab === 'administrator' && `active`}`}>
                  <Link  onClick={() => this.setState({ selectedTab: 'administrator' })}><SupervisedUserCircleRounded id="tab-icon" /> Administrator</Link>
                </li>  */}
                </ul>
                {/* <div className="row-con">
                <div className={`tab ${selectedTab === 'test-case' && `selected-tab`}`} onClick={() => this.setState({ selectedTab: 'test-case' })}>
                  <Edit id="tab-icon" />
                  <span className="tab-title">Test Cases</span>
                </div>
                <div className={`tab ${selectedTab === 'test-run' && `selected-tab`}`} onClick={() => this.setState({ selectedTab: 'test-run' })}>
                  <PlayArrowOutlined style={{ fontSize: 22 }} id="tab-icon" />
                  <span className="tab-title">Test Run</span>
                </div>
                <div className={`tab ${selectedTab === 'test-result' && `selected-tab`}`} onClick={() => this.setState({ selectedTab: 'test-result' })}>
                  <HourglassEmpty id="tab-icon" />
                  <span className="tab-title">Test Result</span>
                </div>


                <div className="delete-project" onClick={this.onDelete}>
                  <Delete />
                  <span>Delete Project</span>
                </div>
              </div> */}
              </div>
              <div>
                <NotificationContainer />
              </div>
            </div>
          </div>
        )}
      </header>
      // </Container>
    );
  };

  renderBody = () => {
    const { selectedTab, selectedProjectId, PROJECTS, importState } =
      this.state;
    console.log("Selected Tab ", selectedTab);
    switch (selectedTab) {
      case "test-case":
        return (
          <TestCases
            parentMethod={this.onCreate}
            projectId={selectedProjectId}
            ref={this.child}
            projectLen={PROJECTS}
            importState={importState}
            aiState={this.state.aiState}
            updatedImportState={this.updatedImportState}
            updatedAiState={this.updatedAiState}
          />
        );
      case "test-run":
        return (
          selectedProjectId && (
            <TestRun handler={this.handler} projectId={selectedProjectId} />
          )
        );
      case "test-result":
        return (
          selectedProjectId && <TestResult projectId={selectedProjectId} />
        );
      case "administrator":
        return (
          selectedProjectId && <Administrator projectId={selectedProjectId} />
        );
      case "analytics":
        return selectedProjectId && <Analytics projectId={selectedProjectId} />;
      case "setting":
        return (
          selectedProjectId && (
            <Setting
              handler={this.handler}
              parentMethod={this.fetchProjects}
              projectId={selectedProjectId}
            />
          )
        );
      case "merge":
        return (
          selectedProjectId && (
            <MergeProject
              changeProjectMerge={this.changeProjectMergeParent}
              parentMethod={this.fetchProjects}
              projectId={selectedProjectId}
            />
          )
        );
    }
  };
}
