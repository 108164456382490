import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
 
import { history } from "../_helpers/history";
import "./App.css";

import { ProjectDetail } from "../ProjectDetail/ProjectDetail";
import Pages_login_2 from "../pages/Pages_login_2";
import Pages_register_2 from "../pages/Pages_register_2";
import Pages_invite from "../pages/Pages_invite";
import Pages_recoverpw_2 from "../pages/Pages_recoverpw_2";
import Pages_dashboard from "../pages/Pages_dashboard";
import Integration from '../pages/Intigration'
import Pages_price  from "../pages/Pages_price";
import Pages_projectlist from "../pages/Pages_projectlist";
import Pages_welcome from "../pages/Pages_welcome";
import Pages_landing from "../pages/Pages_landing";
import Pages_policy from "../pages/Pages_policy";
import Pages_terms from "../pages/Pages_terms";
import Pages_Pricing from "../pages/Pages_pricing";
import Pages_gdpr from "../pages/Pages_gdpr";
import Pages_ShareUrl from "../pages/Pages_shareurl";
import Pages_Error from "../pages/Pages_error";
import Pages_Contactus from "../pages/Pages_contact_us";
import Pages_Cookie from "../pages/Pages_cookie";
import Pages_Cancellation from "../pages/Pages_cancellation";
import Pages_Support from "../pages/Pages_support";
import Pages_Aboutus from "../pages/Pages_aboutus";
import Pages_Expire from "../pages/Pages_expire";
import Pages_settings from "../pages/Pages_settings";
import Docs from "../pages/Docs";

export class App extends React.Component {
  constructor(props) {
    super(props);
    console.log("History ", history.location);
  }
  // componentDidUpdate=()=>{
  //   console.log("subdomain  ",window.location.hostname.split('.')[0])
  // }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://assets.lemonsqueezy.com/lemon.js';
    script.defer = true;
    document.head.appendChild(script);
    this.script = script;
 }

  render() {
    const { alert } = this.props;
    return (
      <Router history={history}>
        <Switch>
          <Route path="/" exact component={Pages_landing} />
          <Route path="/login" exact component={Pages_login_2} />
          <Route path="/forgetpassword" component={Pages_recoverpw_2} />
          <Route path="/register" component={Pages_register_2} />
          <Route path="/dashboard/:projectname?" component={Pages_dashboard} />
          <Route path="/integration" component={Integration} />
          <Route path="/settings" component={Pages_settings} />
          <Route path="/price" component={Pages_price} />
          <Route path="/project" component={Pages_projectlist} />
          <Route path="/projectdetail" component={ProjectDetail} />
          <Route path="/invite" component={Pages_invite} />
          <Route path="/welcome" component={Pages_welcome} />
          <Route path="/terms" component={Pages_terms} />
          <Route path="/pricing" component={Pages_Pricing} />
          <Route path="/policy" component={Pages_policy} />
          <Route path="/gdpr" component={Pages_gdpr} />
          <Route path="/cookie" component={Pages_Cookie} />
          <Route path="/cancellation" component={Pages_Cancellation} />
          <Route path="/aboutus" component={Pages_Aboutus} />
          <Route path="/support" component={Pages_Contactus} />
          <Route path="/contactus" component={Pages_Contactus} />
          <Route path="/docs/:docsItem" component={Docs} />
          <Route exact path="/docs">
          <Redirect to="/docs/integration-overview" />
        </Route>
          <Route path="/testcase/:shareid?" exact component={Pages_ShareUrl} />
          <Route path="/expire" component={Pages_Expire} />
          <Route path="/error" component={Pages_Error} />
          <Route path="/*" component={Pages_Error} />
        </Switch>
      </Router>
    );
  }
}