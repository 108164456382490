import { jwtDecode } from "jwt-decode";
import { host } from "./constants";

export default class Promises {
  static isAlwaysLogin(val) {
    console.log(val);
    localStorage.setItem("alwaysLogin", val);
  }

  static getisAlwaysLogin() {
    localStorage.getItem("alwaysLogin");
  }

  static setUserId(val) {
    localStorage.setItem("userId", val);
  }

  static getUserId() {
    return localStorage.getItem("userId");
  }

  static deleteUserId() {
    localStorage.removeItem("userId");
  }

  static setToken(val) {
    localStorage.setItem("token", val);
  }
  static setName(val) {
    localStorage.setItem("name", val);
  }
  static setEmail(val) {
    localStorage.setItem("email", val);
  }
  static setOrg(val) {
    localStorage.setItem("orgId", val);
  }
  static setOrgName(val) {
    localStorage.setItem("orgName", val);
  }
  static setProfileImage(val) {
    localStorage.setItem("profileImage", val);
  }
  static setFirstName(val) {
    localStorage.setItem("firstname", val);
  }
  static setLastName(val) {
    localStorage.setItem("lastname", val);
  }
  static setUserRole(val) {
    localStorage.setItem("role", val);
  }
  static setOrgLogo(val) {
    localStorage.setItem("orgLogo", val);
  }
  static setPage(val) {
    localStorage.setItem("page", val);
  }
  static getPage() {
    return localStorage.getItem("page");
  }
  static getOrg() {
    return localStorage.getItem("orgId");
  }
  static getOrgName() {
    return localStorage.getItem("orgName");
  }
  static getName() {
    return localStorage.getItem("name");
  }
  static getFirstName() {
    return localStorage.getItem("firstname");
  }
  static getLastName() {
    return localStorage.getItem("lastname");
  }
  static getProfileImage() {
    return localStorage.getItem("profileImage");
  }
  static getOrgLogo() {
    return localStorage.getItem("orgLogo");
  }
  static getRole() {
    return localStorage.getItem("role");
  }
  static getEmail() {
    return localStorage.getItem("email");
  }
  static getToken() {
    return localStorage.getItem("token");
  }
  static getProjectRole() {
    return localStorage.getItem("projectRole");
  }

  static deleteToken() {
    localStorage.removeItem("token");
  }
  static setLocalIteam(key, val) {
    localStorage.setItem(key, val);
  }
  static getLocalIteam(key) {
    // console.log('get iteam'+key)
    return localStorage.getItem(key);
  }
  static deleteLocalIteam(key) {
    localStorage.removeItem(key);
  }
}

export const isValidToken = (token) => {
  if (!token) return false;
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp > currentTime;
  } catch (error) {
    console.error("Error decoding token:", error);
    return false;
  }
};

export const setupFetchInterceptor = () => {
  const { fetch: originalFetch } = window;
  console.log("Interceptor activated");

  window.fetch = async (...args) => {
    let [resource, config] = args;
    try {
      const response = await originalFetch(resource, config);
      console.log("Response YOOOOO111111 ========> ", response);
      return response;
    } catch (error) {
      console.error("Fetch error:", error);
      throw error; // rethrow the error to maintain expected fetch behavior
    }
  };
};

export const isValidEmail = (email) => {
  // Regular expression for basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // List of blocked domains
  // const blockedDomains = [
  //   "gmail.com",
  //   "yahoo.com",
  //   "hotmail.com",
  //   "outlook.com",
  // ];

  // Check if the email matches the regex
  if (!emailRegex.test(email)) {
    console.log(email, { message: `Invalid email format`, status: false });
    return { message: `Invalid email format`, status: false };
  }
  return { status: true, message: "" };
  // console.log("host is ", host);
  // if (host !== "oyetest") {
  //   return { status: true, message: "", host };
  // }

  // // Extract the domain from the email
  // const domain = email.split("@")[1];

  // // Check if the domain is in the list of blocked domains
  // if (blockedDomains.includes(domain)) {
  //   console.log(email, {
  //     message: `Email domain is blocked ${domain}`,
  //     status: false,
  //   });
  //   return { message: `Please use only business emails.`, status: false };
  // }

  // console.log("valid email", email);

  // return { status: true, message: "" };
};

export const validatePassword = (password) => {
  // Regular expressions for different criteria
  const lengthRegex = /^.{8,}$/;
  const uppercaseRegex = /[A-Z]/;
  const numberRegex = /\d/;
  const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

  // Check if the password meets all criteria
  if (!lengthRegex.test(password)) {
    return {
      status: false,
      msg: "Password must be at least 8 characters long",
    };
  }
  if (!uppercaseRegex.test(password)) {
    return {
      status: false,
      msg: "Password must include at least one uppercase letter",
    };
  }
  if (!numberRegex.test(password)) {
    return { status: false, msg: "Password must include at least one number" };
  }
  if (!specialCharRegex.test(password)) {
    return {
      status: false,
      msg: "Password must include at least one special character",
    };
  }

  // If all criteria are met
  return { status: true, msg: "" };
};
export const isValidString = (input) => {
  // match strings with only letters and more than 3 characters
  const regex = /^[a-zA-Z\s]{3,}$/;
  return regex.test(input);
};

export const formatDate = (inputDate) => {
  // Create a Date object from the input string
  if (!inputDate) return new Date();
  try {

    const date = new Date(inputDate);

    // Define options for the date formatting
    const options = { month: "short", day: "numeric", year: "numeric" };

    // Format the date using Intl.DateTimeFormat
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );

    return formattedDate;
  } catch (error) {
    console.error("Error formatting date:", error);
    return new Date();
  }
};

export const getSubdomain = () => {
  const hostname = window.location.hostname;
  const parts = hostname.split('.');

  if (parts.length >= 3) {
    return parts[0]; 
  } else {
    return null; // No subdomain found
  }
};