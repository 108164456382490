import React, {  useEffect, useRef, useState } from "react";
import { FooterSection, LandingNav } from "./Pages_landing";

const  Pages_Policy= ()=> {
    return (
      <div className="custom-container home-page ">
        <LandingNav scrolled={true} bgColor="#fff" />
        <section className="description-block !pt-28">
          <div className="page-custom-container">
            <div className="custom-row">
              <div>
                <div>
                  <h2 className="heading">Privacy Policy</h2>
                  <p>
                    This Privacy Policy, together with Terms of Use, describes
                    the policies and procedures of Oyesenses Limited (“Oyetest”/
                    “Oyesense”/ “Company”/ “We”/ “Us”/ “Our”) for collection,
                    storage, use and disclosure of the information provided by
                    our customers, stakeholders, persons who visits our website,
                    (Hereinafter referred to as / “User”/”Users”/ “User’s/
                    “you”/“your”/“customer”). The Company is engaged in the
                    business of selling men’s and women’s Ethnic Wear and
                    accessories, under the brand name and style “Oyetest”
                    through retail stores and eCommerce sites. This privacy
                    policy covers your rights in relation to your information,
                    how we protect & use it and how you can contact us in case
                    you have a complaint.
                  </p>
                  <p>
                    {" "}
                    This privacy policy is an electronic record in the form of
                    an electronic contract formed under the Information
                    Technology Act, 2000 and the rules made thereunder and the
                    amended provisions pertaining to electronic documents /
                    records in various statutes as amended by the information
                    technology act, 2000. This privacy policy does not require
                    any physical, electronic or digital signature.This document
                    is published and shall be construed in accordance with the
                    provisions of the Information Technology(Reasonable Security
                    Practices and Procedures and Sensitive Personal Data of
                    Information) Rules, 2011 under Information Technology Act,
                    2000; that require publishing of the privacy policy for
                    collection, use, storage and transfer of sensitive personal
                    data or information.
                  </p>
                  <p>
                    This privacy policy is a legally binding document between
                    you and Oyesense. You hereby acknowledge and agree that you
                    are voluntarily providing your personal have read and
                    understood this Policy and that we will collect Personal
                    Information from you for the purposes and usage of
                    collection as described below. The terms of this privacy
                    policy will be effective upon your acceptance/consent of the
                    same (directly or indirectly in electronic form, by clicking
                    on the "I Accept" tab or by use or access of the services or
                    by other means) and will govern the relationship between you
                    and Oyesense.
                  </p>
                  <p>
                    Please read this privacy policy carefully. By using the
                    services, accessing the website, forming any contractual,
                    financial or commercial obligation or other services, you
                    indicate that you understand, agree and consent to this
                    privacy policy. If you do not agree with the terms of this
                    privacy policy, please do not use this platform. You hereby
                    provide your unconditional consent or agreements to Oyesense
                    as provided under the applicable provisions of Information
                    Technology Act, 2000.
                  </p>
                </div>
                <div>
                  <h3 > WHAT INFORMATION DO WE COLLECT ?</h3>
                  
                  <p>
                    This Privacy Policy covers our treatment of your personal
                    data or information including sensitive information or data
                    (as under the Information Technology Rules 2011
                    (collectively known as “Personal Information ”) which may
                    include such information as disclosed at the time you
                    provide your consent which may include such personal or
                    sensitive personal information as disclosed by you for the
                    purposes and uses as described below and such data or
                    information may include but is not limited to your
                    end-users’ name, email address, IP address, identification
                    number, phone number, postal address, in addition to other
                    details that may include gender, location, occupation, data
                    relating to the user or end user’s physical, physiological,
                    genetic, mental, economic, cultural or social identity, bank
                    account details, your communications shared with us,
                    information included in your reviews or otherwise provided
                    for display of services, transaction details on our services
                    or other such information of demographic nature when you
                    sign up for this platform.
                  </p>
                </div>
                <div>
                  <h3 > HOW WE COLLECT YOUR DATA OR INFORMATION?</h3>
                  <p>
                    Website and Cookies: When you interact with our website, we
                    use cookies to automatically collect, store and use
                    technical information about your system and the pages or
                    information you requested on our Platform. Cookies are
                    widely used to make websites work, or work more efficiently,
                    as well as to provide information to the website owner or
                    others. You may be able to change the preferences on your
                    browser or device to prevent or limit your computer's or
                    device's acceptance of cookies, but doing so may prevent you
                    from taking advantage of our Website's best features. Our
                    advertising partners may transmit cookies to your computer
                    or device when you click on ads that appear on our site.
                    This Privacy Policy does not cover cookies delivered to you
                    by any of our advertisers
                  </p>
                  <p>
                    KYC details: You will be required to provide your Personal
                    Information to establish any business, financial or
                    contractual relationship between you and Oyesense, such
                    information may include but not limited to your address
                    proof, PAN number, Voter ID card number, or other Social
                    security numbers as may be applicable. The Data or
                    information would be collected from you when you visit our
                    premises or when such is sent by you to us through any form
                    of electronic communication or when you provide the
                    information in written or oral form and such is uploaded on
                    our systems. You would be responsible for the authenticity
                    of the information so provided and Oyesense would not be
                    liable to verify its authenticity.
                  </p>
                  <p>
                    Account Information: We collect information that helps/
                    allows us to communicate with you. Such information is
                    obtained when you voluntarily sign up to obtain information
                    about our services through the website,
                    visit/subscribe/follow/like our social media pages, order or
                    register to get services by the way of purchase of products,
                    when you request for a replacement, provide us with
                    feedback, contact us, participate in any contest, register
                    or participate in any event hosted by Oyesense whether be
                    under the name of Oyesenses Limited or otherwise. Such
                    information collected from you may include your name,
                    contact information, postal/billing address any other
                    information that is voluntarily provided by you or is
                    necessary for us to provide services to you.
                  </p>
                </div>
                <div>
                  <h3>WHY WE COLLECT YOUR INFORMATION?</h3>
                  <p>
                    In supplying your personal information, you consent to this
                    information being collected, used, disclosed, transferred to
                    the Company and stored by us for providing a more
                    personalized shopping experience for you. Our purpose of
                    collecting your information includes, but is not restricted
                    to : To keep you updated on our new product collections; To
                    process any orders that you place with us and to facilitate
                    any returns; To meet billing aspects; To personalize and
                    improve your experience when you shop; To inform you about
                    products and services that may interest you; To provide you
                    access for your registered account (on our platform); To
                    offer and manage gift card or offers; To develop and improve
                    our products, range and services; To maintain our list of
                    users; To protect, investigate and deter against fraudulent,
                    harmful, unauthorized, or illegal activity; To fulfil our
                    obligations under law or otherwise in maintaining our record
                    to meet statutory regulatory and tax requirements; To learn
                    more about how users of services access and interact with
                    our sites and services;
                  </p>
                </div>
                <div>
                  <h3> CUSTOMER TESTIMONIALS AND REVIEWS</h3>
                  <p>
                    We may post customer testimonials and reviews on our web
                    site which may contain personally identifiable information.
                    We do obtain the customer's consent via email prior to
                    posting the testimonial to post their name along with their
                    testimonial. If you would like to request to have your
                    testimonial or review removed from our site you may email us
                    at the email id given under the Terms of Use.
                  </p>
                </div>
                <div>
                  <h3>Website Cookies</h3>
                  <p>
                    This website uses cookies to record log data. Cookies are
                    small text files sent by us to your computer, and from your
                    computer or mobile device to us each time you visit our
                    website. They are unique to you or your web browser.
                    Session-based cookies last only while your browser is open
                    and are automatically deleted when you close your browser
                    session. Persistent cookies last until you or your browser
                    deletes them, or until they expire.
                  </p>
                  <p>
                    Most browsers allow you to control cookies, including
                    whether to accept them and how to remove them. In such case,
                    you may set the browsers to notify you if you receive a
                    cookie, or you may choose to block cookies with your
                    browser, but please note that if you choose to erase or
                    block your cookies, you will need to re-enter your original
                    user ID and password to gain access to certain parts of the
                    Platform.
                  </p>
                  <p>
                    We use cookies which are not specific to your account but
                    are unique and allow us to understand how you use our
                    Website, remember choices you make in respect of search
                    parameters such as size, colour or product line. These can
                    then be used to provide you with an experience more
                    appropriate to your selections and to make the visits more
                    tailored and pleasant. You may opt out of these cookies
                    using your browser settings but it may affect the
                    performance of our Website. To opt-out of receiving
                    communications from us on behalf of our partners, and from
                    us in general, after setting up an account, please choose to
                    unsubscribe.
                  </p>
                </div>
                <div>
                  <h3>Cookies used for Advertising Purpose:</h3>
                  <p>
                    Cookies and other ad technology such as beacons, pixels, and
                    tags help us serve relevant ads to you more effectively.
                    These kinds of cookies are typically used to personalise ads
                    on this or other sites based on your use of our site, to
                    measure ad effectiveness (for example, how often you click
                    on or view ads and whether you go on to buy something from
                    the advertiser) and to stop you being shown the same ad
                    repeatedly. We work with website analytics and advertising
                    partners, including Google Display Network, Facebook, etc.
                    to deliver Oyetest.com advertisements on third party
                    publisher websites - these partners may set cookies on your
                    computer's web browser. These cookies allow our partners to
                    recognize your computer so that the ad server can show you
                    Oyetest.com advertisements elsewhere on the Internet and our
                    analytics software can measure your engagement and
                    interactions, while using Oyetest.com services. This
                    information allows an ad network to deliver targeted
                    advertisements that they believe will be of most interest to
                    you and it allows Oyetest.com to optimize the performance of
                    our advertising campaigns and the usability of our website.
                    In other words, we use analytics data in concert with data
                    about our online ads that have been shown using the Google
                    Display Network, Facebook for Advertisers, or other online
                    advertising networks. By doing so, we can understand how
                    anonymous users interacted with our website after seeing our
                    ads.
                  </p>
                </div>
                <div>
                  <h3>SHARING PERSONAL DATA OR INFORMATION:</h3>
                  <p>
                    We do not use your personal information for any other
                    purposes, unless we have your specific consent to do so. The
                    Company does not rent or sell, personal information about
                    any of its users except as required under applicable laws.
                    However, the company may share or disclose your personal
                    information in the following manner:
                  </p>
                  <p>
                    The Company may share/use the personal information provided
                    by users to with our our affiliates, representatives,
                    employee, agents, franchisee, vendor, subsidiary, with our
                    trusted partners (e.g.: courier partners, website hosts,
                    content delivery networks and businesses, etc.) who work on
                    behalf of or with the Company under strict confidentiality
                    agreements. We may also share your personal information with
                    third party service providers who provide data processing
                    services to us, however in cases if such is shared, we
                    ensure that the third-party service providers are bound by
                    strict confidentiality agreements.
                  </p>
                  <p>
                    The Company reserves the right to disclose your personal
                    information when required to do so by law or regulation, or
                    under any legal obligation or order under law or in response
                    to a request from a law enforcement or governmental agency
                    or judicial, quasi-judicial or any other statutory or
                    constitutional authority or to establish or exercise our
                    legal rights or defend against legal claims. The Company
                    reserves its rights to responds to court orders, or legal
                    process, or to establish or exercise its legal rights or
                    defend against legal claims. The Company believes it is
                    necessary to share information in order to investigate,
                    prevent, or take action regarding illegal activities,
                    suspected fraud, situations involving potential threats to
                    the physical safety of any person, or as otherwise required
                    by law. If the Company sells all or part of its business or
                    makes a sale or transfer of its assets or is otherwise
                    involved in a merger or transfer of all or a material part
                    of its business, the Company may transfer your Information
                    to the party or parties involved in the transaction as part
                    of that transaction. The Company does not allow any
                    unauthorized persons or organization to use any information
                    that the Company may collect from you through this website.
                    However, the Company is not responsible for any information
                    collected or shared or used by any other third-party website
                    due to your browser settings.
                  </p>
                  <p>
                    This website, and any services available from this website
                    and our other platform, do not knowingly collect or process
                    any sensitive personal data.
                  </p>
                  <p>
                    This website and any services available from this website
                    and our other platforms which are directed to children under
                    the age of 18 (Eighteen) years should have parental consent.
                    By using our services, we will assume parental, or guardian
                    permission has been obtained in case you are under the age
                    of 18 years. The platform reserves the right to indefinitely
                    suspend or terminate or block your access and use of
                    services on the platform through your registered account if
                    it is brought to our notice or if it is discovered that you
                    are under the age of 18 years and using our platform without
                    parental consent.
                  </p>
                </div>
                <div>
                  <h3>
                    INTERNATIONAL TRANSFER/ CROSS BORDER TRANSFER OF PERSONAL
                    AND/OR SENSITIVE PERSONAL INFORMATION:
                  </h3>
                  <p>
                    In conducting the operations of the company and fulfilling
                    the purposes as mentioned earlier your Personal Information
                    may be stored and processed in a country outside the
                    jurisdiction of the country from which your Personal
                    Information is collected. Those countries may have data
                    protection laws different from yours and in certain cases
                    may not provide data protection inferior to yours. We have
                    taken adequate checks to ensure that your data is protected,
                    which includes checking whether the outside country third
                    party complies with applicable data protection laws, rules
                    and regulations, further on contracts entered between
                    Oyesense and outside country third party service providers
                    also obligate such third party to ensure and implement
                    adequate measures to protect your Personal Information.
                  </p>
                </div>
                <div>
                  <h3>USER RIGHTS:</h3>
                  <p>
                    The User can always choose to not disclose his/her personal
                    information with us or if once disclosed has the right to
                    rescind their consent. However, please keep in mind that we
                    may need some information to allow you register with us or
                    to take advantage of some or all our features or form a
                    commercial, financial or contractual relation with us. If
                    you choose not to provide that information or subsequently
                    rescind you consent, your ability to use our website and
                    services may be limited and/or seized.
                  </p>
                  <p>
                    The User can add, delete, modify or make any other changes
                    in the account details. When you register or update your
                    details, we maintain a copy of unrevised information in our
                    records. Upon a request by you, under certain conditions, we
                    will:
                  </p>
                  <p>
                    Provide you, with a copy of your personal data; Correct any
                    errors in your personal data, as identified by you; Restrict
                    our processing of your personal data; Erase/Delete your
                    personal data; If you object to the processing of personal
                    data, we will stop sending you direct communication and stop
                    processing your personal data, save and except in our
                    legitimate interests.
                  </p>
                </div>
                <div>
                  <h3>PROTECTION OF YOUR PERSONAL INFORMATION</h3>
                  <p>
                    We work to protect the security of your personal information
                    during transmission by using GoDaddy SSL software, which
                    encrypts the information that you feed in while registering
                    or placing an order. We maintain physical, electronic and
                    procedural safeguards in connection with the collection,
                    storage and disclosure of personal information (including
                    sensitive personal information). We may at times, request
                    proof of identity before we disclose personal information to
                    you. Please note, your Payment Card details are maintained
                    in encrypted form on secured server. However, no method of
                    electronic transmission or storage is 100% secure and we
                    cannot guarantee its outright security. In spite of all
                    these precautions on our part, if there is any attack in the
                    form of hacking, distribution denial of service attack
                    (DDOS), etc, which results in your personal data on our
                    platform being compromised, we shall not be liable to you
                    for any such loss or damage caused by any technologically
                    harmful material such as viruses or a DDOS or otherwise. The
                    Website may contain links to other sites. We are not
                    responsible for the privacy policies and/or practices on
                    other sites. When following a link to another site you
                    should read that site's privacy policy. We endeavour to
                    protect the privacy of your account and other Personal
                    Information we hold in our records, but we cannot guarantee
                    complete security. Unauthorized entry or use, hardware or
                    software failure, and other factors may compromise the
                    security of user information at any time. Keeping in mind
                    the sensitive nature of information, we request you not to
                    be ignorant about unauthorized access to your password and
                    to your computer while signing up. We request you to be sure
                    to sign off when you have finished using a shared computer.
                  </p>
                </div>
                <div>
                  <h3>HOW LONG WE KEEP YOUR INFORMATION?</h3>
                  <p>
                    We will retain your personal information for the period
                    necessary to fulfil the purpose(s) outlined in this Privacy
                    Policy and unless a longer retention period is required or
                    permitted by law. We will retain such relevant personal
                    information that is necessary in relation to the purpose(s)
                    such as providing you the services for or in order to comply
                    with our legal obligations, enforce the terms of our
                    contracts, resolve disputes or prevent abuse. We will retain
                    the personal information you provided when registering an
                    account on our Website so long as that account remains in
                    existence. In the case of any contact you may have with our
                    customer services department, we will retain those details
                    for as long as is necessary to resolve your query and for a
                    short period after the query is closed.
                  </p>
                </div>
                <div>
                  <h3>CHANGES TO THIS PRIVACY POLICY</h3>
                  <p>
                    We may change this Privacy Policy from time to time. Use of
                    information we collect now is subject to the Privacy Policy
                    in effect at the time such information is used. Whenever we
                    revise or modify the policy, the same will be updated
                    online. If you continue to access this website or use
                    services available from this website or our other platform
                    after those changes have come into effect, you acknowledge
                    and agree to the revised policy. We further request you to
                    visit our privacy statement/policy periodically for changes
                    and/or updates, if any.
                  </p>
                </div>
                <div>
                  <h3>CONTACTING US</h3>
                  <p>
                    For any question or concern or feedback about this Privacy
                    Policy, please feel free to write us through your registered
                    email id (as was used when registering an account on our
                    Website) . Our contact us through phone at +91 6362723848
                    and email support@oyesense.com .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <FooterSection />
      </div>
    );

}

export default Pages_Policy;