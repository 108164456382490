import React from "react";
import Skeleton from "react-loading-skeleton";

const IntegrationCard = () => {
  return (
    <div className="!h-[76px] rounded-md bg-gray-200- flex flex-row pl-[22px] pr-[10px] items-center justify-between border">
      <div className="flex flex-row items-center gap-x-[23px]">
        <div>
          <Skeleton circle height={52} width={53} />
        </div>
        <div className="flex flex-col items-center justify-start">
          <Skeleton height={18} width={72} />
          <Skeleton height={26} width={72} />
        </div>
      </div>
      <div className="flex flex-row items-center gap-x-[23px]">
        <Skeleton height={25} width={60} />
        <Skeleton height={25} width={80} />
      </div>
    </div>
  );
};

export default IntegrationCard;