import React from "react";
import "./index.css";
import DeleteOutlineOutlined from "@material-ui/icons/DeleteOutlineOutlined";
import ControlPoint from "@material-ui/icons/ControlPoint";
import update from "immutability-helper";
import { history } from "../_helpers/history";
import { BASE_URL } from "../_helpers/constants";
import Promises from "../_helpers/utils";
import ReactTooltip from "react-tooltip";
import Axios from "axios";

export class ProjectDetail extends React.Component {
  state = {
    projectData: "",
    testCasesEmpty: false,
    testCasesData: [],
  };

  /*
	.##........#######...######...####..######.
	.##.......##.....##.##....##...##..##....##
	.##.......##.....##.##.........##..##......
	.##.......##.....##.##...####..##..##......
	.##.......##.....##.##....##...##..##......
	.##.......##.....##.##....##...##..##....##
	.########..#######...######...####..######.
	*/

  componentDidMount() {
    const { projectData } = this.props.location;
    this.setState({ projectData: projectData });
    this.getTestCases();
  }

  getTestCases = () => {
    const { projectData } = this.props.location;
    const token = Promises.getToken();
    console.log(projectData);

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BASE_URL}projects/task/${projectData.id}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log("===========>", JSON.parse(JSON.parse(result).data));
        if (JSON.parse(result).code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        if (JSON.parse(result).error) {
          this.setState({ testCasesEmpty: true });
        } else {
          this.setState({ testCasesData: JSON.parse(JSON.parse(result).data) });
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ testCasesEmpty: true });
        localStorage.clear();
        history.push("/login?redirect=1");
      });
  };

  handleDeleteUser(id) {
    return (e) => this.props.deleteUser(id);
  }

  onProjectCardClick = (ele) => {
    history.push({
      pathname: "/projectDetail",
      projectData: ele,
    });
  };

  onModelUpdate = (e, index) => {
    const { testCasesData } = this.state;
    let newState = update(testCasesData, {
      [index]: {
        model: { $set: e.target.value },
      },
    });
    this.setState({ testCasesData: newState });
  };

  onModelListUpdate = (index, subIndex, val) => {
    const { testCasesData } = this.state;
    let newState = update(testCasesData, {
      [index]: {
        lists: {
          [subIndex]: {
            name: { $set: val.target.value },
          },
        },
      },
    });
    this.setState({ testCasesData: newState });
  };

  deleteModel = (index) => {
    const { testCasesData } = this.state;
    let newState = update(testCasesData, {
      $splice: [[index, 1]],
    });
    this.setState({ testCasesData: newState });
  };

  deleteList = (index, i) => {
    const { testCasesData } = this.state;
    let newState = update(testCasesData, {
      [index]: {
        lists: {
          $splice: [[i, 1]],
        },
      },
    });
    this.setState({ testCasesData: newState });
  };

  onAdd = (type, index) => {
    this.setState({ testCasesEmpty: false });
    const { testCasesData } = this.state;

    const newElement = {
      model: "",
      lists: [{ name: "", status: "failed" }],
    };

    const newList = { name: "", status: "failed" };

    if (type == "model") {
      this.setState({
        testCasesData: update(testCasesData, {
          $push: [newElement],
        }),
      });
    } else {
      this.setState({
        testCasesData: update(testCasesData, {
          [index]: {
            lists: {
              $push: [newList],
            },
          },
        }),
      });
    }
  };

  onChangeStatus = (index, i, status) => {
    const { testCasesData } = this.state;

    this.setState({
      testCasesData: update(testCasesData, {
        [index]: {
          lists: {
            [i]: {
              status: { $set: status == "pass" ? "failed" : "pass" },
            },
          },
        },
      }),
    });
  };

  saveChanges = () => {
    const { testCasesData } = this.state;
    console.log("=========>", testCasesData);
    const { projectData } = this.props.location;
    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var raw = {
      data: testCasesData,
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    fetch(`${BASE_URL}projects/task/${projectData.id}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (JSON.parse(result).code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        alert("Your data Save successfully");
        console.log(JSON.parse(result));
      })
      .catch((error) => {
        console.log("error", error);
        alert(
          "Something went wrong, This may happen due to internal server error, Please try Again"
        );
        localStorage.clear();
        history.push("/login?redirect=1");
      });
  };

  /*
	..######...#######..##.....##.########...#######..##....##.########.##....##.########
	.##....##.##.....##.###...###.##.....##.##.....##.###...##.##.......###...##....##...
	.##.......##.....##.####.####.##.....##.##.....##.####..##.##.......####..##....##...
	.##.......##.....##.##.###.##.########..##.....##.##.##.##.######...##.##.##....##...
	.##.......##.....##.##.....##.##........##.....##.##..####.##.......##..####....##...
	.##....##.##.....##.##.....##.##........##.....##.##...###.##.......##...###....##...
	..######...#######..##.....##.##.........#######..##....##.########.##....##....##...
	*/

  render() {
    const { projectData } = this.state;
    return (
      <div className="home-container">
        <div className="header">
          <h2>{projectData.name} Dashboard</h2>
          <h2> Dashboard</h2>
          <div className="header-right">
            <div className="btn-container" onClick={this.saveChanges}>
              <p>Save Changes</p>
            </div>
            <div
              className="btn-container"
              style={{ marginLeft: 10, backgroundColor: "gray" }}
              onClick={() => {
                alert("All the changes are discarded");
                setTimeout(() => {
                  this.getTestCases();
                }, 500);
              }}
            >
              <p>Discard</p>
            </div>
          </div>
        </div>
        {this.renderBody()}
      </div>
    );
  }

  renderBody = () => {
    const { testCasesEmpty, testCasesData } = this.state;

    return (
      <div className="body-container">
        {testCasesEmpty ? (
          <div className="center-container">
            <p className="note">
              No Test Cases Found, <br />
              Go ahead and start writing your First Test case... <br />
              <p
                onClick={() => this.onAdd("model")}
                style={{
                  color: "green",
                  textDecorationLine: "underline",
                  cursor: "pointer",
                }}
              >
                Start Writing
              </p>
            </p>
          </div>
        ) : (
          <div className="test-element">
            {testCasesData.map((ele, index) => {
              return (
                <div className="tests">
                  <div className="model-input">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p className="model-index">{index + 1}.</p>
                      <input
                        type="text"
                        placeholder="Model title here"
                        className="input1"
                        name="model"
                        value={ele.model}
                        onChange={(e) => this.onModelUpdate(e, index)}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        alignSelf: "flex-end",
                      }}
                      onClick={() => this.deleteModel(index)}
                    >
                      <p className="model-index">Delete model</p>
                      {/* <DeleteOutlineOutlined
                        className="delete-icon"
                        style={{ color: "white", fontSize: 25, marginLeft: 5 }}
                      /> */}
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/delete-icon.png"
                        }
                        alt="Delete Icon"
                        style={{
                          marginLeft: "5px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>
                  {ele.lists.map((item, i) => {
                    return (
                      <div className="model-list">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <p className="model-index">-</p>
                          <input
                            type="text"
                            placeholder="Test case here"
                            className="list-input"
                            name="list"
                            value={item.name}
                            onChange={(e) =>
                              this.onModelListUpdate(index, i, e)
                            }
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <p
                            onClick={() =>
                              this.onChangeStatus(index, i, item.status)
                            }
                            data-tip
                            data-for="statustip"
                            className="list-status"
                            style={{
                              color: item.status == "pass" ? "green" : "red",
                              cursor: "pointer",
                            }}
                          >
                            {item.status}
                          </p>
                          <ReactTooltip
                            id="statustip"
                            place="top"
                            effect="solid"
                          >
                            Press to change Status
                          </ReactTooltip>
                          {/* <DeleteOutlineOutlined
                            className="delete-icon"
                            style={{
                              color: "gray",
                              fontSize: 20,
                              marginLeft: 15,
                              cursor: "pointer",
                            }}
                            onClick={() => this.deleteList(index, i)}
                          /> */}
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/delete-icon.png"
                            }
                            alt="Delete Icon"
                            onClick={() => this.deleteList(index, i)}
                            style={{
                              marginLeft: "15px",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                  <div className="add-con">
                    {index == testCasesData.length - 1 && <ControlPoint />}
                    {index == testCasesData.length - 1 && (
                      <p
                        className="add"
                        style={{ marginRight: 10, cursor: "pointer" }}
                        onClick={() => this.onAdd("model", index)}
                      >
                        Add model
                      </p>
                    )}
                    <ControlPoint />
                    <p
                      className="add"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.onAdd("list", index)}
                    >
                      Add list
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };
}
