import React, { Component } from "react";
import "../styles/component/_testRun.css";
import ReactApexChart from "react-apexcharts";
import Promises from "../_helpers/utils";
import { BASE_URL } from "../_helpers/constants";
import Select from "react-select";
import "../styles/component/_testRun.css";
import "../styles/component/_testResult.css";
import { MDBDataTable } from "mdbreact";
import * as _ from "underscore";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
const getPropValues = (o, prop) =>
  ((res) => (
    JSON.stringify(o, (key, value) => (key === prop && res.push(value), value)),
    res
  ))([]);

export default class Analytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      options: {
        colors: ["#00C865", "#FF4440", "#FF9A28"],
        dataLabels: {
          enabled: false,
          style: {
            colors: ["#00C865", "#FF4440", "#FF9A28"],
          },
        },
        chart: {
          toolbar: {
            show: false,
          },
        },
        stroke: {
          show: true,
          curve: "smooth",
          lineCap: "butt",
          colors: ["#00C865", "#FF4440", "#FF9A28"],
          width: 0,
          dashArray: 0,
        },
        fill: {
          colors: ["#00C865", "#FF4440", "#FF9A28"],
        },
        markers: {
          colors: ["#00C865", "#FF4440", "#FF9A28"],
        },
        xaxis: {
          type: "datetime",
          categories: [],
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm",
          },
        },
      },
      series: [
        {
          name: "Pass",
          data: [],
        },
        {
          name: "Fail",
          data: [],
        },
        {
          name: "Untested",
          data: [],
        },
      ],
      options2: {
        colors: ["#00C865", "#FF4440"],
        dataLabels: {
          enabled: false,
          style: {
            colors: ["#00C865", "#FF4440"],
          },
        },
        chart: {
          toolbar: {
            show: false,
          },
        },
        stroke: {
          show: true,
          curve: "smooth",
          lineCap: "butt",
          colors: ["#00C865", "#FF4440"],
          width: 0,
          dashArray: 0,
        },
        fill: {
          colors: ["#00C865", "#FF4440"],
        },
        markers: {
          colors: ["#00C865", "#FF4440"],
        },
        xaxis: {
          type: "datetime",
          categories: [],
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm",
          },
        },
      },
      series2: [
        {
          name: "manualrate",
          data: [],
        },
        {
          name: "automationrate",
          data: [],
        },
      ],
    };
  }

  /*
  .##........#######...######...####..######...######.
  .##.......##.....##.##....##...##..##....##.##....##
  .##.......##.....##.##.........##..##.......##......
  .##.......##.....##.##...####..##..##........######.
  .##.......##.....##.##....##...##..##.............##
  .##.......##.....##.##....##...##..##....##.##....##
  .########..#######...######...####..######...######.
  */
  componentDidMount = () => {
    this.setState({ loading: true, projectId: this.props.projectId }, () => {
      this.getTestResultAnalytic(10);
    });
  };

  getTestResultAnalytic = (limit) => {
    this.setState({ loading: true });
    const token = Promises.getToken();
    const { projectId } = this.state;
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    console.log(requestOptions);

    fetch(
      `${BASE_URL}projects/analytics/result/${projectId}?page=0&pageSize=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("FETCHING RESULT66 ===========>", result);
        if (!result.status) {
          this.setState({ testCasesEmpty: true, loading: false });
        } else {
          const data = result.data;
          if (data.length == 0) {
            this.setState({ testCasesEmpty: true, loading: false });
          } else {
            let pass = [];
            let failed = [];
            let untested = [];
            let createdAt = [];
            let manualrate = [];
            let automationrate = [];
            let tableRows = [];
            result.data.forEach((element) => {
              console.log("Element ", element);
              // let resArray = getPropValues(JSON.parse(element.data), "status");
              // let resTypeArray = getPropValues(JSON.parse(element.data), "testing");
              // let resFlagArray = getPropValues(JSON.parse(element.data), "flag");
              // let ActualPassManual = JSON.parse(element.data)[0].lists.filter((e) => (e.status == 'pass' && e.testing == 'manual'));
              // let ActualPassAutomation = JSON.parse(element.data)[0].lists.filter((e) => (e.status == 'pass' && e.testing == 'automated'));
              failed.push(element.test_status?.failed || 0);
              pass.push(element.test_status?.pass || 0);
              untested.push(element.test_status?.untested || 0);
              // let tmpmanualrate = (ActualPassManual.length * 100) / (resFlagArray.filter(x => x == 1).length || 1);
              // let tmpautomationrate = (ActualPassAutomation.length * 100) / (resFlagArray.filter(x => x == 2).length || 1);
              manualrate.push(
                element.execution_type_percentage?.manual_execution
              );
              automationrate.push(
                element.execution_type_percentage?.automated_execution
              );
              createdAt.push(element.created_at);
              // tableRows.push({
              //   testrunname: element.id,
              //   date: element.updatedAt,
              //   totaltestcaes: resArray.length,
              //   manualtestcase: resTypeArray.filter(x => x === 'manual').length,
              //   automatedtestcases: resTypeArray.filter(x => x === 'automated').length,
              //   manualexecution: resFlagArray.filter(x => x == 1).length,
              //   automationexecution: resFlagArray.filter(x => x == 2).length,
              //   manualrate: tmpmanualrate.toFixed(2),
              //   automationrate: tmpautomationrate.toFixed(2)
              // })

              tableRows.push({
                testrunname: element.result_description,
                date: element.created_at,
                totaltestcaes: element.total_tests,
                manualtestcase: element.test_type?.manual,
                automatedtestcases: element.test_type?.automated || 0,
                manualexecution: element.execution_type?.manual_execution,
                automationexecution:
                  element.execution_type?.automated_execution,
                manualrate: element.execution_type_percentage?.manual_execution,
                automationrate:
                  element.execution_type_percentage?.automated_execution,
              });
            });
            console.log({ manualrate });

            this.setState({
              options: {
                dataLabels: {
                  enabled: false,
                },
                toolbar: {
                  show: false,
                },
                stroke: {
                  show: true,
                  curve: "smooth",
                  lineCap: "butt",
                  colors: ["#F44336", "#E91E63", "#9C27B0"],
                  width: 0,
                  dashArray: 0,
                },
                xaxis: {
                  type: "datetime",
                  categories: createdAt,
                },
                tooltip: {
                  x: {
                    format: "dd/MM/yy HH:mm",
                  },
                },
              },
              series: [
                {
                  name: "Pass",
                  data: pass,
                },
                {
                  name: "Fail",
                  data: failed,
                },
                {
                  name: "Untested",
                  data: untested,
                },
              ],
              options2: {
                dataLabels: {
                  enabled: false,
                },
                toolbar: {
                  show: false,
                },
                stroke: {
                  show: true,
                  curve: "smooth",
                  lineCap: "butt",
                  colors: ["#F44336", "#E91E63"],
                  width: 0,
                  dashArray: 0,
                },
                xaxis: {
                  type: "datetime",
                  categories: createdAt,
                },
                tooltip: {
                  x: {
                    format: "dd/MM/yy HH:mm",
                  },
                },
              },
              series2: [
                {
                  name: "manualrate",
                  data: manualrate,
                },
                {
                  name: "automationrate",
                  data: automationrate,
                },
              ],
            });
            this.setState({ loading: false, tableRows: tableRows });
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ testCasesEmpty: true, loading: false });
      });
  };
  handleChange1 = (selectedOption) => {
    console.log(`Option selected:`, selectedOption);
    this.getTestResultAnalytic(selectedOption.value);
  };
  getDropDown = (limit) => {
    let userOption = [
      { value: 5, label: 5 },
      { value: 10, label: 10 },
      { value: 15, label: 15 },
      { value: 20, label: 20 },
    ];
    return (
      <div className="modal-body analytics-range-selecter">
        <div className="form-group mb-0">
          <label className="control-label">Select Range</label>
          <Select
            defaultValue={[{ value: 5, label: 5 }]}
            onChange={this.handleChange1}
            options={userOption}
            isMulti={false}
          />
        </div>
      </div>
    );
  };

  /*
  ..######...#######..##.....##.########...#######..##....##.########.##....##.########
  .##....##.##.....##.###...###.##.....##.##.....##.###...##.##.......###...##....##...
  .##.......##.....##.####.####.##.....##.##.....##.####..##.##.......####..##....##...
  .##.......##.....##.##.###.##.########..##.....##.##.##.##.######...##.##.##....##...
  .##.......##.....##.##.....##.##........##.....##.##..####.##.......##..####....##...
  .##....##.##.....##.##.....##.##........##.....##.##...###.##.......##...###....##...
  ..######...#######..##.....##.##.........#######..##....##.########.##....##....##...
  */

  render() {
    const { loading, tableRows } = this.state;
    const data = {
      columns: [
        {
          label: "Testrun name",
          field: "testrunname",
          sort: "asc",
          width: 150,
        },
        {
          label: "Date",
          field: "date",
          sort: "asc",
          width: 270,
        },
        {
          label: "Total Testcaes",
          field: "totaltestcaes",
          sort: "asc",
          width: 200,
        },
        {
          label: "Manual Testcase",
          field: "manualtestcase",
          sort: "asc",
          width: 100,
        },
        {
          label: "Automated testcases",
          field: "automatedtestcases",
          sort: "asc",
          width: 150,
        },
        {
          label: "Manual execution",
          field: "manualexecution",
          sort: "asc",
          width: 100,
        },
        {
          label: "Automation execution",
          field: "automationexecution",
          sort: "asc",
          width: 100,
        },
        {
          label: "Manual sucess rate %",
          field: "manualrate",
          sort: "asc",
          width: 100,
        },
        {
          label: "Automation success rate %",
          field: "automationrate",
          sort: "asc",
          width: 100,
        },
      ],
      rows: tableRows,
    };
    return (
      <div>
        <ul className="nav nav-tabs nav-tabs-custom ml-3 !border-none !mb-0 mt-4 bg-[#F2F2F2] w-[450px] rounded-t-[8px]" role="tablist">
          <li className="nav-item gap-x-1">
            <a
              className="nav-link active rounded-t-[8px] overflow-clip flex items-center justify-center !bg-red-400"
              data-toggle="tab"
              href="#result"
              role="tab"
            >
              <span className="d-none d-md-block">Stats</span>
              {/* <span className="d-block d-md-none">
                <i className="mdi mdi-email h5"></i>
              </span> */}
            </a>
          </li>
          <li className="nav-item flex items-center justify-center">
            <a
              className="nav-link rounded-t-[8px] overflow-clip flex items-center justify-center"
              data-toggle="tab"
              href="#summary"
              role="tab"
            >
              <span className="d-none d-md-block">Summary</span>
              {/* <span className="d-block d-md-none">
                <i className="mdi mdi-account h5"></i>
              </span> */}
            </a>
          </li>
          <li className="nav-item flex items-center justify-center">
            <a
              className="nav-link rounded-t-[8px] overflow-clip flex items-center justify-center"
              data-toggle="tab"
              href="#analysis"
              role="tab"
            >
              <span className="d-none d-md-block">Progress</span>
              {/* <span className="d-block d-md-none">
                <i className="mdi mdi-account h5"></i>
              </span> */}
            </a>
          </li>
        </ul>
        <div className="test-case-body" style={{   width:'auto',  height: 'calc(100vh - 185px)',
    overflow: 'hidden auto',
    margin:'0px 16px 16px 16px' }}>
        {loading && (
          <SkeletonTheme color="#f3f3f3" highlightColor="#f9f9f9">
            <Skeleton count={5} height={100} />
          </SkeletonTheme>
        )}

        <div className="tab-content">
          <div
            className="active tab-pane p-3"
            id="result"
            role="tabpanel"
            
          >
            {this.getDropDown()}
            <ReactApexChart
              options={this.state.options}
              series={this.state.series}
              type="area"
              height="330"
            />
          </div>
          <div
            className="tab-pane p-3"
            id="summary"
            role="tabpanel"
            
          >
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <MDBDataTable bordered hover noBottomColumns data={data} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="tab-pane p-3"
            id="analysis"
            role="tabpanel"
            
          >
            <div className="active tab-pane p-3" id="result" role="tabpanel">
              {this.getDropDown()}
              <ReactApexChart
                options={this.state.options2}
                series={this.state.series2}
                type="area"
                height="330"
              />
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}
