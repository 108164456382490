import React, { Component } from "react";
import { FooterSection, LandingNav } from "./Pages_landing";

export default class Pages_Gdpr extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="custom-container home-page !pb-3">
        <LandingNav scrolled={true} />
        <section className="description-block !mt-28">
          <div className="page-custom-container">
            <div className="custom-row">
              <div>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.4608695652173913",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 15pt 0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "34.5pt",
                      fontFamily: "Arial",
                      color: "#181818",
                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Privacy Policy - GDPR
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.8666666666666667",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If you are a resident of the European Union (“EU”), United
                    Kingdom, Lichtenstein, Norway, or Iceland, you may have
                    additional rights under the EU General Data Protection
                    Regulation (the “GDPR”) with respect to your Personal
                    Information, as outlined below. The terms in this section
                    will be effective as of 24 Jun, 2020.
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.9714285714285713",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.8666666666666667",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    For this section, “Personal Information” shall have the same
                    meaning as “personal data” as defined in the GDPR and we use
                    the term “processing” as it is defined in the GDPR, but
                    “Personal Information” generally means information that can
                    be used to individually identify a person, and “processing”
                    generally covers actions that can be performed in connection
                    with data such as collection, use, storage and disclosure.
                    Oyetest will be the controller of your Personal Information
                    processed in connection with the Services. Note that we may
                    also process Personal Information of our customers’ end
                    users or employees in connection with our provision of
                    services to customers, in which case we are the processor of
                    Personal Information. If we are the processor of your
                    Personal Information (i.e., not the controller), please
                    contact the controller party in the first instance to
                    address your rights with respect to such data.
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.9714285714285713",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.8666666666666667",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If there are any conflicts between this section and any
                    other provision of Oyetest’s general privacy policy
                    available at&nbsp;
                  </span>
                  <a
                    href="https://oyetest.com/policy"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "13.5pt",
                        fontFamily: "Arial",
                        color: "#0e106a",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      https://oyetest.com/privacy-policy
                    </span>
                  </a>
                  <span
                    style={{
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    , the policy or portion that is more protective of Personal
                    Information shall control to the extent of such conflict. If
                    you have any questions about this notice or whether any of
                    the following applies to you, please contact us at
                    contact@oyetest.com
                  </span>
                </p>
                <h3
                  dir="ltr"
                  style={{
                    lineHeight: "1.9714285714285713",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "30pt 0pt 15pt 0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "18pt",
                      fontFamily: "Arial",
                      color: "#181818",
                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    LEGAL GROUNDS FOR PROCESSING
                  </span>
                </h3>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.8666666666666667",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We will only process your Personal Information if we have a
                    lawful basis for doing so. Lawful bases for processing
                    include consent, contractual necessity and our “legitimate
                    interests” or the legitimate interest of others, as further
                    described below.
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.9714285714285713",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  &nbsp;
                </p>
                <ul
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: "48px",
                  }}
                >
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "disc",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "11pt",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Contractual Necessity:
                      </span>
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        &nbsp;We process the following categories of Personal
                        Information as a matter of “contractual necessity”,
                        meaning that we need to process the data to perform
                        under our Terms of Service with you, which enables us to
                        provide you with the Services. When we process data due
                        to contractual necessity, failure to provide such
                        Personal Information will result in your inability to
                        use some or all portions of the Services that require
                        such data.
                      </span>
                    </p>
                    <ul
                      style={{
                        marginTop: 0,
                        marginBottom: 0,
                        paddingInlineStart: "48px",
                      }}
                    >
                      <li
                        aria-level={2}
                        dir="ltr"
                        style={{
                          listStyleType: "circle",
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "22pt",
                        }}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.8666666666666667",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "13.5pt",
                              fontFamily: "Arial",
                              color: "#2e3138",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            Essential account data;
                          </span>
                        </p>
                      </li>
                      <li
                        aria-level={2}
                        dir="ltr"
                        style={{
                          listStyleType: "circle",
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "22pt",
                        }}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.8666666666666667",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "13.5pt",
                              fontFamily: "Arial",
                              color: "#2e3138",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            Essential first-party cookies;
                          </span>
                        </p>
                      </li>
                      <li
                        aria-level={2}
                        dir="ltr"
                        style={{
                          listStyleType: "circle",
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "22pt",
                        }}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.8666666666666667",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "13.5pt",
                              fontFamily: "Arial",
                              color: "#2e3138",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            Connection data;
                          </span>
                        </p>
                      </li>
                      <li
                        aria-level={2}
                        dir="ltr"
                        style={{
                          listStyleType: "circle",
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "22pt",
                        }}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.8666666666666667",
                            marginTop: "0pt",
                            marginBottom: "15pt",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "13.5pt",
                              fontFamily: "Arial",
                              color: "#2e3138",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            Third party account information (e.g. if a User logs
                            into the Services using his/her Salesforce
                            credentials).
                          </span>
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.9714285714285713",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  &nbsp;
                </p>
                <ul
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: "48px",
                  }}
                >
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "disc",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "11pt",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Legitimate Interest:
                      </span>
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        &nbsp;We process the following categories of Personal
                        Information when we believe it furthers the legitimate
                        interest of us or third parties.
                      </span>
                    </p>
                    <ul
                      style={{
                        marginTop: 0,
                        marginBottom: 0,
                        paddingInlineStart: "48px",
                      }}
                    >
                      <li
                        aria-level={2}
                        dir="ltr"
                        style={{
                          listStyleType: "circle",
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "22pt",
                        }}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.8666666666666667",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "13.5pt",
                              fontFamily: "Arial",
                              color: "#2e3138",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            Optional account data;
                          </span>
                        </p>
                      </li>
                      <li
                        aria-level={2}
                        dir="ltr"
                        style={{
                          listStyleType: "circle",
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "22pt",
                        }}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.8666666666666667",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "13.5pt",
                              fontFamily: "Arial",
                              color: "#2e3138",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            Billing data;
                          </span>
                        </p>
                      </li>
                      <li
                        aria-level={2}
                        dir="ltr"
                        style={{
                          listStyleType: "circle",
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "22pt",
                        }}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.8666666666666667",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "13.5pt",
                              fontFamily: "Arial",
                              color: "#2e3138",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            Contact information;
                          </span>
                        </p>
                      </li>
                      <li
                        aria-level={2}
                        dir="ltr"
                        style={{
                          listStyleType: "circle",
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "22pt",
                        }}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.8666666666666667",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "13.5pt",
                              fontFamily: "Arial",
                              color: "#2e3138",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            Online Form, Email and Chat Data;
                          </span>
                        </p>
                      </li>
                      <li
                        aria-level={2}
                        dir="ltr"
                        style={{
                          listStyleType: "circle",
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "22pt",
                        }}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.8666666666666667",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "13.5pt",
                              fontFamily: "Arial",
                              color: "#2e3138",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            Event data;
                          </span>
                        </p>
                      </li>
                      <li
                        aria-level={2}
                        dir="ltr"
                        style={{
                          listStyleType: "circle",
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "22pt",
                        }}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.8666666666666667",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "13.5pt",
                              fontFamily: "Arial",
                              color: "#2e3138",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            Support ticket data;
                          </span>
                        </p>
                      </li>
                      <li
                        aria-level={2}
                        dir="ltr"
                        style={{
                          listStyleType: "circle",
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "22pt",
                        }}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.8666666666666667",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "13.5pt",
                              fontFamily: "Arial",
                              color: "#2e3138",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            Community forums;
                          </span>
                        </p>
                      </li>
                      <li
                        aria-level={2}
                        dir="ltr"
                        style={{
                          listStyleType: "circle",
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "22pt",
                        }}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.8666666666666667",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "13.5pt",
                              fontFamily: "Arial",
                              color: "#2e3138",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            Augmented tracking data;
                          </span>
                        </p>
                      </li>
                      <li
                        aria-level={2}
                        dir="ltr"
                        style={{
                          listStyleType: "circle",
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "22pt",
                        }}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.8666666666666667",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "13.5pt",
                              fontFamily: "Arial",
                              color: "#2e3138",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            Tracking cookies;
                          </span>
                        </p>
                      </li>
                      <li
                        aria-level={2}
                        dir="ltr"
                        style={{
                          listStyleType: "circle",
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "22pt",
                        }}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.8666666666666667",
                            marginTop: "0pt",
                            marginBottom: "15pt",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "13.5pt",
                              fontFamily: "Arial",
                              color: "#2e3138",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            Device identifiers.
                          </span>
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.9714285714285713",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.8666666666666667",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Examples of these legitimate interests include:
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.9714285714285713",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  &nbsp;
                </p>
                <ul
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: "48px",
                  }}
                >
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "disc",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "11pt",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Providing the Services to Users
                      </span>
                    </p>
                  </li>
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "disc",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "11pt",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Facilitating usage of our Service
                      </span>
                    </p>
                  </li>
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "disc",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "11pt",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Providing personalized and customized content and
                        information to Users
                      </span>
                    </p>
                  </li>
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "disc",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "11pt",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Collecting payment from customers
                      </span>
                    </p>
                  </li>
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "disc",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "11pt",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Operation and improvement of our business, products and
                        services
                      </span>
                    </p>
                  </li>
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "disc",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "11pt",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Marketing of our products and services
                      </span>
                    </p>
                  </li>
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "disc",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "11pt",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Provision of User support
                      </span>
                    </p>
                  </li>
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "disc",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "11pt",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        marginTop: "0pt",
                        marginBottom: "15pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Compliance with legal obligations
                      </span>
                    </p>
                  </li>
                </ul>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.9714285714285713",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  &nbsp;
                </p>
                <ul
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: "48px",
                  }}
                >
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "disc",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "11pt",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Consent:
                      </span>
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        &nbsp;In some cases, we process Personal Information
                        based on the consent you expressly grant to us at the
                        time we collect such data. When we process Personal
                        Information based on your consent, it will be expressly
                        indicated to you at the point and time of collection.
                      </span>
                    </p>
                  </li>
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "disc",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "11pt",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        marginTop: "0pt",
                        marginBottom: "15pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Other Processing Grounds:
                      </span>
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        &nbsp;From time to time we may also need to process
                        Personal Information to comply with a legal obligation,
                        if it is necessary to protect the vital interests of you
                        or other data subjects, or if it is necessary for a task
                        carried out in the public interest.
                      </span>
                    </p>
                  </li>
                </ul>
                <h3
                  dir="ltr"
                  style={{
                    lineHeight: "1.9714285714285713",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "30pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 15pt 0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "18pt",
                      fontFamily: "Arial",
                      color: "#181818",
                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    How and with whom do we share your data?
                  </span>
                </h3>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.8666666666666667",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We share Personal Information with vendors, third party
                    service providers and agents who work on our behalf and
                    provide us with services related to the purposes described
                    in this Privacy Policy or our Terms of Service. Examples of
                    such vendors, third party service providers and agents
                    mentioned below
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.8666666666666667",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  &nbsp;
                </p>
                <ol
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: "48px",
                  }}
                >
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "decimal",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        textAlign: "justify",
                        backgroundColor: "#ffffff",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        AWS ,&nbsp;
                      </span>
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "#ffffff",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        AWS is our Cloud Service Provider
                      </span>
                    </p>
                  </li>
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "decimal",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        textAlign: "justify",
                        backgroundColor: "#ffffff",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "#ffffff",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Google play for android application store
                      </span>
                    </p>
                  </li>
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "decimal",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        textAlign: "justify",
                        backgroundColor: "#ffffff",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "#ffffff",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Apple app store for iOS
                      </span>
                    </p>
                  </li>
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "decimal",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        textAlign: "justify",
                        backgroundColor: "#ffffff",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "#ffffff",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Google analytics for service improvement
                      </span>
                    </p>
                  </li>
                </ol>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.8666666666666667",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.8666666666666667",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We also share Personal Information when necessary to
                    complete a transaction initiated or authorized by you or
                    provide you with a product or service you have requested. In
                    addition to those set forth above, these parties also
                    include:
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.9714285714285713",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  &nbsp;
                </p>
                <ul
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: "48px",
                  }}
                >
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "disc",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "11pt",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Other Users (where you post information publicly or as
                        otherwise necessary to effect a transaction initiated or
                        authorized by you through the Services)
                      </span>
                    </p>
                  </li>
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "disc",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "11pt",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Social media services (if you interact with them through
                        your use of the Services)
                      </span>
                    </p>
                  </li>
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "disc",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "11pt",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Third party business partners who you access through the
                        Services (e.g. third party applications)
                      </span>
                    </p>
                  </li>
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "disc",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "11pt",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        marginTop: "0pt",
                        marginBottom: "15pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Other parties authorized by you
                      </span>
                    </p>
                  </li>
                </ul>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.8666666666666667",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We also share information with third parties when you have
                    given us consent to do so (as indicated at the point such
                    information is collected).
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.9714285714285713",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.8666666666666667",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We also share Personal Information when we believe it is
                    necessary to:
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.9714285714285713",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  &nbsp;
                </p>
                <ul
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: "48px",
                  }}
                >
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "disc",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "11pt",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Comply with applicable law or respond to valid legal
                        process, including from law enforcement or other
                        government agencies
                      </span>
                    </p>
                  </li>
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "disc",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "11pt",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Protect us, our business or our Users, for example to
                        enforce our Terms of Service, prevent spam or other
                        unwanted communications and investigate or protect
                        against fraud
                      </span>
                    </p>
                  </li>
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "disc",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "11pt",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        marginTop: "0pt",
                        marginBottom: "15pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Maintain the security of our products and services
                      </span>
                    </p>
                  </li>
                </ul>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.8666666666666667",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If we choose to buy or sell assets, user information is
                    typically one of the transferred business assets. Moreover,
                    if we, or substantially all of our assets, were acquired, or
                    if we go out of business or enter bankruptcy, user
                    information would be one of the assets that is transferred
                    or acquired by a third party, and we would share Personal
                    Information with the party that is acquiring our assets. You
                    acknowledge that such transfers may occur, and that any
                    acquirer of us or our assets may continue to use your
                    Personal Information as set forth in this policy.
                  </span>
                </p>
                <h3
                  dir="ltr"
                  style={{
                    lineHeight: "1.9714285714285713",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "30pt 0pt 15pt 0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "18pt",
                      fontFamily: "Arial",
                      color: "#181818",
                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    How long do we retain your personal information?
                  </span>
                </h3>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.8666666666666667",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We retain Personal Information about you for as long as you
                    have an open account with us or as otherwise necessary to
                    provide you Services.
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.9714285714285713",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.8666666666666667",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Account data (both essential and optional) is maintained as
                    long as you have an active account with us and for up to 90
                    days after account closure.
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.9714285714285713",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.8666666666666667",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Session cookies expire after 14 days, by default, but the
                    expiration can set longer or shorter under your control.
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.9714285714285713",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.8666666666666667",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    In some cases, we retain Personal Information for longer, if
                    doing so is necessary to comply with our legal obligations,
                    resolve disputes or collect fees owed, or is otherwise
                    permitted or required by applicable law, rule or regulation.
                    In particular, contact and company information, as well as
                    subscription and payment activity and any contracts you have
                    executed with us, will be retained for a period of at least
                    7 years, for auditing purposes. We will retain some
                    marketing related information for a period of 5 years, for
                    analytic purposes.
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.9714285714285713",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.8666666666666667",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Content you post on our systems (such as Community Forums)
                    may remain on the Site indefinitely, unless you send us an
                    explicit removal request.
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.9714285714285713",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.8666666666666667",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Our third-party processors may retain data for various
                    periods depending on their individual privacy policies.
                  </span>
                </p>
                <h3
                  dir="ltr"
                  style={{
                    lineHeight: "1.9714285714285713",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "30pt 0pt 15pt 0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "18pt",
                      fontFamily: "Arial",
                      color: "#181818",
                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    What security measures do we use?
                  </span>
                </h3>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.8666666666666667",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We seek to protect Personal Information using appropriate
                    technical and organizational measures based on the type of
                    Personal Information and applicable processing activity. We
                    have a Data Protection Officer and an Information Security
                    Policy in place. VAPT tests are performed on the platform
                    every year. AES 256 encryption is done at Database,
                    application and storage level. For more information please
                    contact&nbsp;
                  </span>
                  <span
                    style={{
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#0e106a",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    contact@oyetest.com
                  </span>
                  <span
                    style={{
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    &nbsp;and we will provide our Security document
                  </span>
                </p>
                <h3
                  dir="ltr"
                  style={{
                    lineHeight: "1.9714285714285713",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "30pt 0pt 15pt 0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "18pt",
                      fontFamily: "Arial",
                      color: "#181818",
                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Personal information of children
                  </span>
                </h3>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.8666666666666667",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    As noted in the Privacy Policy, we do not knowingly collect
                    or solicit Personal Information from anyone under the age of
                    13. If you are under 13, please do not attempt to register
                    for the Services or send any Personal Information about
                    yourself to us. If we learn that we have collected Personal
                    Information from a child under age 13, we will delete that
                    information as quickly as possible. If you believe that a
                    child under 13 may have provided us Personal Information,
                    please contact us at contact@oyetest.com
                  </span>
                </p>
                <h3
                  dir="ltr"
                  style={{
                    lineHeight: "1.9714285714285713",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "30pt 0pt 15pt 0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "18pt",
                      fontFamily: "Arial",
                      color: "#181818",
                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    What rights do you have regarding your personal information?
                  </span>
                </h3>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.8666666666666667",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    You have certain rights with respect to your Personal
                    Information, including those set forth below. For more
                    information about these rights, or to submit a request,
                    please email at contact@oyetest.com. Please note that in
                    some circumstances, we may not be able to fully comply with
                    your request, such as if it is frivolous or extremely
                    impractical, if it jeopardizes the rights of others, or if
                    it is not required by law, but in those circumstances, we
                    will still respond to notify you of such a decision. In some
                    cases, we may also need you to provide us with additional
                    information, which may include Personal Information, if
                    necessary to verify your identity and the nature of your
                    request.
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.9714285714285713",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  &nbsp;
                </p>
                <ul
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: "48px",
                  }}
                >
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "disc",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "11pt",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Access:
                      </span>
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        &nbsp;You can request more information about the
                        Personal Information we hold about you and request a
                        copy of such Personal Information. You can also access
                        certain of your Personal Information by accessing your
                        Account Settings or contacting us at
                        contact@oyetest.com.
                      </span>
                    </p>
                  </li>
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "disc",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "11pt",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Rectification:
                      </span>
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        &nbsp;If you believe that any Personal Information we
                        are holding about you is incorrect or incomplete, you
                        can request that we correct or supplement such data. You
                        can also correct some of this information directly by
                        accessing your account settings or contacting us at
                        contact@oyetest.com.
                      </span>
                    </p>
                  </li>
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "disc",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "11pt",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Download
                      </span>
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        : You can request to download all Personal Information
                        that we have collected by using Export Account in the
                        Account Settings or writing to us at
                        contact@oyetest.com.
                      </span>
                    </p>
                  </li>
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "disc",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "11pt",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Erasure:
                      </span>
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        &nbsp;You can request that we erase some or all of your
                        Personal Information from our systems by using the
                        Delete Account feature in Account Settings.
                      </span>
                    </p>
                  </li>
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "disc",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "11pt",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Withdrawal of Consent:
                      </span>
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        &nbsp;If we are processing your Personal Information
                        based on your consent (as indicated at the time of
                        collection of such data), you have the right to withdraw
                        your consent at any time. Please note, however, that if
                        you exercise this right, you may have to then provide
                        express consent on a case-by-case basis for the use or
                        disclosure of certain of your Personal Information, if
                        such use or disclosure is necessary to enable you to
                        utilize some or all of our Services.
                      </span>
                    </p>
                  </li>
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "disc",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "11pt",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Portability:
                      </span>
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        &nbsp;You can ask for a copy of your Personal
                        Information in a machine-readable format.
                      </span>
                    </p>
                  </li>
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "disc",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "11pt",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Objection:
                      </span>
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        &nbsp;You can contact us to let us know that you object
                        to the further use or disclosure of your Personal
                        Information for certain purposes.
                      </span>
                    </p>
                  </li>
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "disc",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "11pt",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Restriction of Processing:
                      </span>
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        You can ask us to restrict further processing of your
                        Personal Information.
                      </span>
                    </p>
                  </li>
                  <li
                    aria-level={1}
                    dir="ltr"
                    style={{
                      listStyleType: "disc",
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "11pt",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.8666666666666667",
                        marginTop: "0pt",
                        marginBottom: "15pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Right to File Complaint:
                      </span>
                      <span
                        style={{
                          fontSize: "13.5pt",
                          fontFamily: "Arial",
                          color: "#2e3138",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        &nbsp;You have the right to lodge a complaint about
                        Company’s practices with respect to your Personal
                        Information with the supervisory authority of your
                        country or EU Member State.
                      </span>
                    </p>
                  </li>
                </ul>
                <h3
                  dir="ltr"
                  style={{
                    lineHeight: "1.9714285714285713",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "30pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 15pt 0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "18pt",
                      fontFamily: "Arial",
                      color: "#181818",
                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Transfers of personal information
                  </span>
                </h3>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.8666666666666667",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    The Services are hosted and operated in India through
                    Oyetest and its service providers, and if you do not reside
                    in India, laws in India may differ from the laws where you
                    reside. By using the Services, you acknowledge that any
                    Personal Information about you, regardless of whether
                    provided by you or obtained from a third party, is being
                    provided to Oyetest in India and will be hosted on Indian
                    servers, and you authorize Oyetest to transfer, store and
                    process your information to and in India, and possibly other
                    countries.&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.8666666666666667",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.8666666666666667",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "18pt",
                      fontFamily: "Arial",
                      color: "#181818",
                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    What if you have questions regarding your personal
                    information?
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.8666666666666667",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "13.5pt",
                      fontFamily: "Arial",
                      color: "#2e3138",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If you have any questions about this section or our data
                    practices generally, please contact us at
                    contact@oyetest.com
                  </span>
                </p>
              </div>
            </div>
          </div>
        </section>
       <FooterSection />
      </div>
    );
  }
}
