import React, { Component } from "react";
import { Link } from "react-router-dom";
import { history } from "../_helpers/history";
import { BASE_URL, LOGO_FULL_URL } from "../_helpers/constants";

export default class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
        <section className="custom-footer">
        <div className="page-custom-container">
          <div className="custom-footer-wrapper">
           
            <div>
            <span id="f_cpyrt">
                @ {new Date().getFullYear()} oyesense all rights reserved.{" "}
              </span>
            </div>
            <div>
            <a id="f_plcy" href="/aboutus">
                About us
              </a>{" "}
              | {" "}
            <a id="f_plcy" href="/policy">
                Privacy
              </a>{" "}
              |
              <a href="/terms" id="f_trms">
                {" "}
                Terms
              </a>{" "}
              |
              <a id="f_infrm" href="/gdpr">
                {" "}
                GDPR
              </a> {" "}
               |
              <a id="f_infrm" href="/cookie">
                {" "}
                Cookie
              </a> {" "}
              |
              <a id="f_infrm" href="/cancellation">
                {" "}
                Subscription & Cancellation
              </a> | {" "}
              <a id="f_plcy" href="/contactus">
                Contact us
              </a>{" "}
              | {" "}
              <a id="f_plcy" href="/support">
                Support
              </a>{" "}
              
            </div>
          </div>
        </div>
      </section>
    );
  }
}
