import React, { Component } from "react";
import { Link } from "react-router-dom";
import { history } from "../_helpers/history";
import { BASE_URL, LOGO_FULL_URL } from "../_helpers/constants";
import Footer from "../_components/Footer";

export default class Pages_Cancellation extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="custom-container home-page">
        <section className="custom-header">
          <div className="page-custom-container">
            <div className="custom-header-wrapper">
              <div>
                <Link to="/">
                  {" "}
                  <img width="170" height="55" src={LOGO_FULL_URL} />
                </Link>
                <span className="beta-name">BETA</span>
                {/* <svg width="65" height="65" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 92.1114C19 89.9579 20.3788 88.0463 22.4223 87.3668L62.3682 74.0836L61.2187 123.617L25.2689 133.05C22.0994 133.881 19 131.49 19 128.213V92.1114Z" fill="#FF4440"/>
          <path d="M62.2636 74.0836L172.585 37.6346C176.184 36.4457 179.745 39.536 179.075 43.2659L170.461 91.2367C170.119 93.1416 168.708 94.6779 166.839 95.1811L61.2186 123.617L62.2636 74.0836Z" fill="#00C865"/>
          <path d="M61.2186 123.199L111.379 109.823L107.343 152.489C107.134 154.701 105.49 156.51 103.307 156.928L67.1604 163.861C64.0775 164.452 61.2186 162.089 61.2186 158.95V123.199Z" fill="#FF9A28"/>
          </svg> */}
              </div>
              <div className="align-items-inline">
                <div className="outline-btn custom-header-login-btn">
                  <Link to="/pricing">Pricing</Link>
                </div>
                <div className="outline-btn custom-header-login-btn">
                  <Link
                    to="https://api.oyetest.com/automation/docs/#/Authentication/login"
                    target="_blank"
                  >
                    API
                  </Link>
                </div>
                {/* <div className="outline-btn custom-header-login-btn">
                    <Link to="/aboutus">About us</Link>
                  </div> */}
                <div className="outline-btn custom-header-login-btn">
                  <Link to="/login">Login</Link>
                </div>
                {/* <div className="outline-btn">
                  <Link to="/register">Sign Up</Link>
                </div> */}
              </div>
            </div>
          </div>
        </section>
        <section className="description-block">
          <div className="page-custom-container">
            <div className="custom-row">
              <div>
                <div>
                  <h2>Subscription , Billing and Cancellation</h2>
                  <p>Oyetest Software provides multiple subscription options so that you can choose what billing frequency and method fits your team best. The options depend on number of users and features requirements, and what type of payment method you would like to use.</p>
                </div>
                <div>
                    <p>Monthly subscription</p>
                    <p>Annual subscription</p>
                </div>
                <div>
                    <h3>Oyetest Cloud – Monthly Subscriptions</h3>
                    <ol>
                        <li>How does billing work for subscriptions that are billed monthly?
Monthly subscriptions on Oyetest Cloud are billed at the beginning of the month for the previous month, based on the maximum number of users marked active under Administration  Users & Roles each month.
</li>
<li>What happens in case of failed payments?
If charges for the Oyetest Cloud subscription cannot be completed, a notification will be sent to the billing contact on file. If the Oyetest subscription payment is not resolved within a timely manner, Oyetest Software reserves the right to temporarily disable the corresponding Oyetest Cloud instance until payment can be provided. Disabled accounts are not canceled, and standard monthly fees will still apply for the duration of the account’s disabled status.
</li>
<li>How do I activate a Oyetest Cloud subscription?
A monthly Oyetest Cloud subscription can be activated within Oyetest via the Administration  Overview tab. Enter the credit card details for all recurring monthly payments.
</li>
<li>How do I activate a Oyetest Cloud subscription?
A monthly Oyetest Cloud subscription can be activated within Oyetest via the Administration  Overview tab. Enter the credit card details for all recurring monthly payments.
<p>The Oyetest 15-day trial period will complete prior to the commencement of monthly billing for a Oyetest Cloud subscription. The first charge will be prorated based on the time remaining in the month following the trial’s expiration date.
</p>
<p>All monthly fees are charged against a credit card linked to the Oyetest Cloud subscription on the first business day of each calendar month.
</p>
</li>
<li>How do I cancel my monthly subscription?
Your month-to-month Oyetest Cloud subscription will remain active unless a cancellation request is submitted by your team.
<p>In case you’d like to cancel your subscription, please fill out the form in this link (<a id="f_infrm" href="/support">
                  {" "}
                  Link
                </a>). An important reminder: you must be an admin of the account to validate this request, and confirm the details of your account in order for our team to process your cancellation.
</p>
<p>Oyetest Cloud is always canceled at the end of a billing cycle (i.e. to the end of a month). Please note that data in canceled accounts will be removed after a period of time. 
</p>
</li>
                    </ol>
                </div>
                <div>
                    <h3>Oyetest Cloud – Annual Subscriptions</h3>
                    <ol>
                        <li>How do I prepay for Oyetest?
A prepaid Oyetest Cloud subscription can be purchased within Oyetest website. This subscription includes a discount. Therefore, an invoice is provided for 12 months of service for the price of 11 months.
<p>This subscription will auto-renew if no changes are communicated to Oyetest when the notification is sent (no more than 60 days prior to your balance depletion), and a new subscription and invoice will be generated. You can easily turn off the auto-renewal toggle in your Customer Portal which will allow you to adjust your next renewal, read more about it here.  
</p>
<p>If you have any doubts, reach out to your Customer Success Team and we'll be happy to assist you.</p></li>

<li>How does billing work?
Once an invoice is issued, the Oyetest Cloud prepayment is applied to your account as a subscription balance. Payment for this invoice will be due within 30 days, based on our net 30 payment terms. Oyetest Software supports various payment options for the invoice such as credit card payments, bank/wire transfers, or checks. Payment details are listed on the invoice upon purchase.
</li>

<li>How do I get monthly statements?
We will automatically add monthly balance statements as we deduct them from your balance each month. Oyetest is billed at the beginning of the month for the previous month, and billing is based on the maximum number of users marked active under Administration  Users & Roles. 
Monthly statements can also be sent to the designated billing contact on file and copies of all monthly statements issued to your account are located within the Subscriptions area of the Customer Portal.
</li>

<li>How do I cancel my Oyetest Cloud (Prepaid) subscription?
Annual subscriptions are purchased on a 12-month basis, paid in advance.

<p>In case you’d like to cancel your subscription, please fill out the form in this link. An important reminder: you must be an admin of the account to validate this request, and confirm the details of your account in order for our team to process your cancellation.
</p>
<p>If you have any doubts, reach out to your Customer Success team and we'll be happy to assist you. Contacting support
</p>
</li>

                    </ol>
                    <div>
                        <p>Oyetest Software provides ticket-based support via 
                            
                        <a id="f_infrm" href="/contactus">
                  {" "}
                  Contact us Page
                </a>. We can provide helpful documentation and resources that we wouldn’t be able to provide via a call, as our resources can dedicate additional time to fully investigate any specific question to provide the most comprehensive answer. This also allows us to utilize the full Oyetest team as a resource to answer any questions in case any specific use case/scenario question arises that one team member might have more experience with. You can submit all sales, billing, and technical inquiries via the Oyetest support page here.</p>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
       <Footer />
      </div>
    );
  }
}
