import React, { useEffect, useState } from "react";
import {
  BASE_URL,
  LOGO_FULL_URL,
  recaptha_siteKey,
} from "../_helpers/constants";
import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";
import { history } from "../_helpers/history";
import { jwtDecode } from "jwt-decode";
import Promises, {
  formatDate,
  isValidString,
  validatePassword,
} from "../_helpers/utils";

import { MdOutlineEditNotifications, MdOutlineDelete } from "react-icons/md";

import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import Select from "react-select";

import Navbar from "../_components/Navbar/Navbar";
import TwoActionPopup from "../_components/TwoActionPopup";
import ImageInput from "../_components/ImageInput/ImageInput";
import TableEntry from "../skeletons/TableEntry";
import { ContactSidebar } from "./Pages_contact_us";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInputWithCountrySelect, {
  isValidPhoneNumber,
} from "react-phone-number-input";
import SuccessComponent from "../_components/SuccessComponent";
import Table from "../_components/Table";
import { useLocation } from "react-router-dom";
import CopyButton from "../_components/CopyButton";

const WorkspaceNav = () => {
  return <Navbar selectInvite={() => history.push("/dashboard")} />;
};

const Pages_settings = () => {
  const [selectedOption, setSelectedOption] = useState("profile");
  const [user, setUserProfile] = useState(null);
  // const location = useLocation(); // Hook to access the current URL location

  const settingOptions = [
    {
      label: "Account",
      key: "profile",
      icon: "user",
      callback: () => {
        setSelectedOption("profile");
        history.push("/settings/account");
      },
    },
    {
      label: "Security",
      icon: "shield",
      key: "security",
      callback: () => {
        setSelectedOption("security");
        history.push("/settings/security");
      },
    },
    {
      label: "API Keys",
      icon: "key",
      key: "apikeys",
      callback: () => {
        setSelectedOption("apikeys");
        history.push("/settings/api-keys");
      },
    },
    {
      label: "Support",
      icon: "help",
      key: "help",
      callback: () => {
        setSelectedOption("help");
        history.push("/settings/support");
      },
    },
  ];

  useEffect(() => {
    Promises.setPage("settings");
    const token = Promises.getToken();
    
    const urlPath = window.location.pathname;
    const pathParts = urlPath.split("/");
    const param = pathParts[pathParts.length - 1];
    console.log("param: ", param);

    switch (param) {
      case "account": {
        settingOptions[0].callback();
        break;
      }
      case "security": {
        settingOptions[1].callback();
        break;
      }
      case "api-keys": {
        settingOptions[2].callback();
        break;
      }
      case "support": {
        settingOptions[3].callback();
        break;
      }
      default: {
        settingOptions[0].callback();
        console.log(param, "default");
        break;
      }
    }

    if (!token) {
      history.push("/login?redirect=1");
    }
  }, []);

  // useEffect(() => {
  //   // Get the query parameter from the URL
  //   const params = new URLSearchParams(window.location.search);
  //   const option = params.get("option");

  //   if (option) {
  //     setSelectedOption(option);
  //   } else {
  //     setSelectedOption("profile");
  //   }
  // }, []);
  return (
    <div style={{ height: "100vh", overflow: "hidden" }}>
      <WorkspaceNav />
      <div className="flex flex-row">
        <div
          className="border-r pt-12"
          style={{ width: 294, background: "#ECFFF3" }}
        >
          <div className="flex flex-col items-center justify-center">
            <p
              className=" text-white bg-primary-green rounded-full h-24 w-24 text-center"
              style={{ fontSize: 60 }}
            >
              <img
                // src={`https://api.dicebear.com/5.x/initials/svg?seed=${Promises.getName()}`}
                src={
                  Promises.getProfileImage() ||
                  process.env.PUBLIC_URL + "/assets/images/default-img.png"
                }
                alt="user"
                className="rounded-circle h-full w-full border"
              />
            </p>
            <p className="mt-2 text-[18px] capitalize font-[700] text-primary-green font-raleway">
              {Promises.getFirstName()}
            </p>
          </div>
          <div className="mt-8 h-[68vh] overflow-auto">
            {settingOptions.map((option, index) => {
              if (option.key === "apikeys" && (!user || user.role === 0))
                return null;
              return (
                <div
                  key={index}
                  className={` flex flex-row items-center justify-start pl-3 py-0.5 cursor-pointer hover:bg-gray-100 ${
                    option.key === selectedOption
                      ? " !text-white !bg-primary-green "
                      : ""
                  }`}
                  onClick={option.callback}
                >
                  <div className="flex flex-row items-center pl-3">
                    <Icon option={option} selectedOption={selectedOption} />
                    <p
                      className={`pl- ${
                        option.key === selectedOption
                          ? " text-white "
                          : "text-gray-500"
                      } font-raleway font-[700] text-[16px]`}
                      style={{ fontSize: 16, fontWeight: 600 }}
                    >
                      {option.label}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="w-full">
          {selectedOption === "apikeys" && <ApiKeys />}
          {selectedOption === "profile" && (
            <Profile setUserProfile={setUserProfile} />
          )}
          {selectedOption === "help" && <Support />}
          {selectedOption === "security" && <Security />}
        </div>
      </div>
    </div>
  );
};

const Profile = ({ setUserProfile }) => {
  const [type, setType] = useState("personal");
  const [showErrors, setShowErrors] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [user, setUser] = useState({
    fName: "",
    lName: "",
    img: "",
  });
  const [organization, setOrganization] = useState({
    name: "",
    industry: "",
    description: "",
    img: "",
  });

  const [newImage, setNewImage] = useState(null);
  const [localStorageFields, setLocalStorageFields] = useState({
    fName: "",
    lName: "",
    img: "",
  });
  const [initialOrg, setInitialOrg] = useState({
    name: "",
    industry: "",
    description: "",
  });

  useEffect(() => {
    const intialize = async () => {
      await fetchUserProfile();
      await fetchOrganizationProfile();
      setUser({
        fName: Promises.getFirstName(),
        lName: Promises.getLastName(),
        img: Promises.getProfileImage(),
      });
      setLocalStorageFields({
        fName: Promises.getFirstName(),
        lName: Promises.getLastName(),
        img: Promises.getProfileImage(),
      });
    };
    intialize();
  }, []);

  const fetchUserProfile = async () => {
    const token = Promises.getToken();

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BASE_URL}auth/user`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        console.log("fetched result is: ", result);
        const { data } = result;
        const userData = {
          fName: data.firstname,
          lName: data.lastname,
          img: data.imageUrl,
          role: data.role,
        };
        setUser(userData);
        setUserProfile(userData);
        setLocalStorageFields(userData);
        if (data.imageUrl) Promises.setProfileImage(data.imageUrl);
        Promises.setFirstName(data.firstname);
        Promises.setLastName(data.lastname);
        Promises.setOrgName(data.orgName);
        Promises.setOrg(data.organization);
        Promises.setEmail(data.email);
        Promises.setUserRole(data.role);
        Promises.setUserId(data.id);
      })

      .catch((error) => {
        console.log("error fetching profile", error);
      });
  };

  const fetchOrganizationProfile = async () => {
    const token = Promises.getToken();
    const orgId = Promises.getOrg();

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BASE_URL}auth/organization/${orgId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        console.log("fetched result is: ", result);
        const { data } = result;
        setOrganization({
          industry: data.industry,
          description: data.description,
          img: data.logoUrl,
          name: data.companyName,
          domain: data.name,
        });

        if (data.logoUrl) Promises.setOrgLogo(data.logoUrl);
        setInitialOrg({
          name: data.companyName,
          industry: data.industry,
          description: data.description,
          img: data.logoUrl,
        });
      })

      .catch((error) => {
        console.log("error fetching profile", error);
      });
  };

  const updateUserProfile = () => {
    const token = Promises.getToken();

    const formData = new FormData();
    formData.append("firstname", user?.fName);
    formData.append("lastname", user?.lName);

    if (newImage?.file) {
      formData.append("profileImage", newImage.file, "croppedImage.png");
    }

    var requestOptions = {
      method: "POST",
      headers: {
        "X-L10N-Locale": "en",
        authorization: token,
        // No need to set "Content-Type" when using FormData
      },
      body: formData,
      redirect: "follow",
    };

    fetch(`${BASE_URL}auth/user/update`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const parsed = JSON.parse(result);
        if (parsed.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        if (parsed.code === 200) {
          setNewImage({ url: null, file: null });
          fetchUserProfile();
          NotificationManager.success(parsed.msg);
        } else {
          NotificationManager.error(parsed.msg);
        }
      })
      .catch((error) => {
        NotificationManager.error(
          "Something went wrong, Please try again later"
        );
        console.log("error", error);
      });
  };
  const updateOrganizationProfile = () => {
    if (user?.role !== 0 && user?.role !== 1) {
      return NotificationManager.error("Access Denied");
    }
    const token = Promises.getToken();
    const formData = new FormData();
    formData.append("companyName", organization?.name);
    formData.append("description", organization?.description);
    formData.append("industry", organization?.industry);
    const orgId = Promises.getOrg();

    if (newImage?.file) {
      formData.append("organizationLogo", newImage.file, "croppedImage.png");
    }

    var requestOptions = {
      method: "POST",
      headers: {
        "X-L10N-Locale": "en",
        authorization: token,
        // No need to set "Content-Type" when using FormData
      },
      body: formData,
      redirect: "follow",
    };

    fetch(`${BASE_URL}auth/organization/update/${orgId}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const parsed = JSON.parse(result);
        if (parsed.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        if (parsed.code === 200) {
          setNewImage({ url: null, file: null });
          fetchOrganizationProfile();
          NotificationManager.success("Organization updated successfully");
        } else {
          NotificationManager.error(
            "Failed to update organization, please try later."
          );
        }
      })
      .catch((error) => {
        NotificationManager.error(
          "Something went wrong, Please try again later"
        );
        console.log("error", error);
      });
  };

  return (
    <div className="px-8 py-3 w-full lg:!max-w-[940px]">
      <div>
        <p className="primary_heading">Account Settings</p>
        <div className="flex flex-row border-b gap-x-6 mt-8">
          <div
            className="menu-option pb-3 "
            style={{
              color: type === "personal" ? " #316BFF " : "",
              borderBottom: type === "personal" ? " 2px solid #316BFF" : "",
            }}
            onClick={() => {
              setType("personal");
              // setOrganization({ name: "", description: "", industry: "" });
            }}
          >
            Personal
          </div>
          {(user?.role === 1 || user?.role === 2) && (
            <div
              className="menu-option pb-3"
              style={{
                color: type === "organization" ? " #316BFF " : "",
                borderBottom:
                  type === "organization" ? " 2px solid #316BFF" : "",
              }}
              onClick={() => {
                setType("organization");
                // setUser({ fName: "", lName: "", img: "" });
              }}
            >
              Organization
            </div>
          )}
        </div>
      </div>

      <div className="my-6 flex items-center justify-between ">
        <div>
          <h1 className="secondary_heading mb-2">
            {type === "personal" ? "Profile" : "Organization"} Info
          </h1>
          <p className="font-roboto !font-[400] text-[15px] text-[#6F6F6F]">
            Manage your {type} informations from here.
          </p>
        </div>
        <div>
          <div className="z-100 ">
            <ImageInput
              setImage={(img) => {
                console.log("Recieved image in settings: ", img);
                setNewImage(img);
              }}
              btnSize={"6rem"}
            />
            <div
              className="relative cursor-pointer"
              style={{ marginTop: -98 }}
              onClick={() => {
                console.log("click from profile icon");
                document.getElementById("fileInput").click();
              }}
            >
              <img
                // src={`https://api.dicebear.com/5.x/initials/svg?seed=${Promises.getName()}`}
                src={
                  newImage?.file
                    ? newImage.url
                    : type === "personal"
                    ? user?.img ||
                      process.env.PUBLIC_URL + "/assets/images/default-img.png"
                    : organization?.img ||
                      process.env.PUBLIC_URL + "/assets/images/default-img.png"
                }
                alt="user"
                className="rounded-circle w-24 h-24"
              />
              <svg
                className="absolute top-0 left-0"
                width="27"
                height="26"
                viewBox="0 0 27 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.15"
                  y="0.15"
                  width="26.7"
                  height="25.7"
                  rx="6.85"
                  fill="#F5F8FF"
                />
                <rect
                  x="0.15"
                  y="0.15"
                  width="26.7"
                  height="25.7"
                  rx="6.85"
                  stroke="#316BFF"
                  stroke-width="0.3"
                />
                <g clip-path="url(#clip0_10_2335)">
                  <path
                    d="M18.14 10.1999L13.37 14.9699C12.895 15.4449 11.4849 15.6649 11.1699 15.3499C10.8549 15.0349 11.0699 13.6249 11.5449 13.1499L16.32 8.37489C16.4377 8.24642 16.5803 8.14315 16.7391 8.0713C16.8978 7.99945 17.0695 7.9605 17.2438 7.95683C17.418 7.95316 17.5912 7.98483 17.7528 8.04994C17.9145 8.11504 18.0613 8.21224 18.1843 8.33564C18.3074 8.45904 18.4042 8.60609 18.4688 8.76792C18.5335 8.92976 18.5647 9.10301 18.5606 9.27723C18.5564 9.45146 18.517 9.62305 18.4447 9.78163C18.3724 9.9402 18.2688 10.0825 18.14 10.1999Z"
                    stroke="#316BFF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13 9H10.5C9.96956 9 9.46089 9.21071 9.08581 9.58578C8.71074 9.96086 8.5 10.4696 8.5 11V16C8.5 16.5304 8.71074 17.0391 9.08581 17.4142C9.46089 17.7893 9.96956 18 10.5 18H16C17.105 18 17.5 17.1 17.5 16V13.5"
                    stroke="#316BFF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_10_2335">
                    <rect
                      width="12"
                      height="12"
                      fill="white"
                      transform="translate(7.5 7)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
        <NotificationContainer />
      </div>

      <div className="w-full lg:!max-w-[940px]">
        <div className="flex flex-row gap-x-4 w-full mb-4">
          <Input
            label={type === "personal" ? "First Name" : "Organization Name"}
            text={type === "personal" ? user.fName : organization.name}
            setText={(text) => {
              if (type === "personal") setUser((p) => ({ ...p, fName: text }));
              else setOrganization((p) => ({ ...p, name: text }));
            }}
            validateString={showErrors}
            setShowErrors={setShowErrors}
            setHasError={setHasError}
          />
          <Input
            label={type === "personal" ? "Last Name" : "Industry"}
            text={type === "personal" ? user.lName : organization.industry}
            setText={(text) => {
              if (type === "personal") setUser((p) => ({ ...p, lName: text }));
              else setOrganization((p) => ({ ...p, industry: text }));
            }}
            validateString={showErrors}
            setShowErrors={setShowErrors}
            setHasError={setHasError}
          />
        </div>
        <div className="flex flex-row gap-x-4 w-full">
          <Input
            label={type === "personal" ? "Email" : "Team Name"}
            text={
              type === "personal" ? Promises.getEmail() : Promises.getOrgName()
            }
            setText={(text) => {
              setUser((p) => ({ ...p, fName: text }));
            }}
            disabled={true}
          />
          {type === "personal" && (
            <Input
              label="Role"
              text={Promises.getProjectRole() === 0 ? "Admin" : "User"}
              setText={(text) => {
                setUser((p) => ({ ...p, lName: text }));
              }}
              disabled={true}
            />
          )}
        </div>
        {type !== "personal" && (
          <div className="flex flex-row gap-x-4 w-full mt-4">
            <Input
              label="Organization Description"
              text={organization.description}
              placeholder="Give a short description of your organization..."
              setText={(text) => {
                setOrganization((p) => ({ ...p, description: text }));
              }}
              textField={true}
            />
          </div>
        )}

        <div className="max-w-[320px] flex flex-row gap-x-3 mt-8 font-poppins font-[600] text-[14px] text-[#64748B] items-center justify-center">
          <button
            className="bg-[#E7EAEE] hover:bg-[#e5ebf3] rounded-[8px] !h-[44px] w-[110px]"
            disabled={
              type === "personal"
                ? localStorageFields.fName === user.fName &&
                  localStorageFields.lName === user.lName &&
                  !newImage?.file
                : initialOrg.name === organization.name &&
                  initialOrg.description === organization.description &&
                  initialOrg.industry === organization.industry &&
                  !newImage?.file
            }
            style={{
              opacity: (
                type === "personal"
                  ? localStorageFields.fName === user.fName &&
                    localStorageFields.lName === user.lName &&
                    !newImage?.file
                  : initialOrg.name === organization.name &&
                    initialOrg.description === organization.description &&
                    initialOrg.industry === organization.industry &&
                    !newImage?.file
              )
                ? 0.4
                : 1,
            }}
            onClick={() => {
              setUser((p) => ({
                fName: Promises.getFirstName(),
                lName: Promises.getLastName(),
                img: Promises.getProfileImage(),
                role: p.role,
              }));
              setOrganization({
                name: initialOrg.name,
                description: initialOrg.description,
                industry: initialOrg.industry,
                img: initialOrg.img,
              });
              setNewImage(null);
              setShowErrors(false);
            }}
          >
            Cancel
          </button>
          <button
            className="primary_btn w-full h-[46px] rounded-[4px] text-white font-roboto !font-[500] text-[15px] flex items-center gap-x-2"
            onClick={() => {
              if (type === "personal") {
                updateUserProfile();
              } else {
                updateOrganizationProfile();
              }
            }}
            disabled={
              hasError ||
              (type === "personal"
                ? localStorageFields.fName === user.fName &&
                  localStorageFields.lName === user.lName &&
                  !newImage?.file
                : initialOrg.name === organization.name &&
                  initialOrg.description === organization.description &&
                  initialOrg.industry === organization.industry &&
                  !newImage?.file)
            }
            style={{
              opacity:
                hasError ||
                (type === "personal"
                  ? localStorageFields.fName === user.fName &&
                    localStorageFields.lName === user.lName &&
                    !newImage?.file
                  : initialOrg.name === organization.name &&
                    initialOrg.description === organization.description &&
                    initialOrg.industry === organization.industry &&
                    !newImage?.file)
                  ? 0.5
                  : 1,
            }}
          >
            <svg
              width="16"
              height="12"
              viewBox="0 0 16 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.2703 0.758767C15.5876 1.09212 15.5746 1.6196 15.2412 1.93692L5.61162 11.1036C5.44717 11.2601 5.22597 11.3428 4.99916 11.3325C4.77236 11.3222 4.55958 11.2197 4.41003 11.0489L0.706328 6.81814C0.403177 6.47184 0.43815 5.94537 0.784441 5.64222C1.13073 5.33907 1.65721 5.37404 1.96036 5.72033L5.09178 9.29737L14.0921 0.729749C14.4255 0.412424 14.9529 0.425416 15.2703 0.758767Z"
                fill="white"
              />
            </svg>
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

const ApiKeys = () => {
  const [apikeys, setApikeys] = useState([]);
  const [isCreatingApiKey, setIsCreatingApiKey] = useState(false);
  const [twoAction, setTwoAction] = useState({
    show: false,
    isDanger: false,
    successText: "",
    abortText: "",
    onAbort: () => {},
    onSuccess: () => {},
    label: "",
    description: "",
  });

  const calculateExpirationDate = (jwtToken) => {
    try {
      const decodedToken = jwtDecode(jwtToken);
      const exp = decodedToken.exp;
      if (!exp) return "N/A";
      const date = new Date(exp * 1000); // Convert Unix timestamp to milliseconds
      return formatDate(date);
    } catch (error) {
      console.error("Invalid token", error);
      return null;
    }
  };
  const isApiKeyActive = (jwtToken) => {
    try {
      const decodedToken = jwtDecode(jwtToken);
      const exp = decodedToken.exp;
      if (!exp) return true;
      return new Date() <= new Date(exp * 1000);
    } catch (error) {
      console.error("Invalid token", error);
      return false;
    }
  };

  const columns = [
    // { field: "id", headerName: "ID", width: 100 },
    { field: "name", headerName: "Name", width: 150, enableSorting: false },
    {
      field: "apiKey",
      headerName: "API Key",
      width: 220,
      renderCell: (row) => (
        <div
          className="flex items-center pr-2"
          style={{ border: "0.4px solid #C6C6C6", borderRadius: 7, height: 28 }}
        >
          <div className="bg-[#F3F3F3] w-full h-full flex items-center px-2 border-r">
            <input
              type="password"
              readOnly
              value={row.apiKey}
              className="w-full !h-4 border-none bg-transparent  rounded-md text-gray-700 rounded-r-0"
              style={{ borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }}
            />
          </div>
          <button
            className="ml-2 text-blue-500 hover:text-blue-700"
            onClick={() => {
              navigator.clipboard
                .writeText(row.apiKey)
                
                .catch((err) => {
                  console.error("Failed to copy: ", err);
                });
            }}
            // style={{ marginLeft: "10px" }}

            variant="contained"
            size="small"
            color="primary"
          >
            <CopyButton />
          </button>
        </div>
      ),
    },
    {
      field: "Status",
      headerName: "Status",
      width: 70,
      renderCell: (row) => (
        <div className="flex items-center ">
          {isApiKeyActive(row.apiKey) ? (
            <span className="w-16 h-8 flex items-center justify-center text-primary-green bg-[#EBFFF4] px-2 py-1 rounded-md !font-[500]">
              Active
            </span>
          ) : (
            <span className="text-secondary-error bg-red-100 px-2 py-1 rounded-md text-[12px] font-[500] w-16 h-8 flex items-center justify-center ">
              Expired
            </span>
          )}
        </div>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      enableSorting: true,
      width: 150,
      renderCell: (row) => {
        const date = new Date(row.createdAt);
        // const day = date.getDate();
        // const month = date.getMonth() + 1; // Months are zero-indexed
        // const year = date.getFullYear();
        // const dayFormatted = day < 10 ? `0${day}` : day;
        // const monthFormatted = month < 10 ? `0${month}` : month;
        // return `${dayFormatted}/${monthFormatted}/${year}`;
        return formatDate(date);
      },
    },
    {
      field: "userName",
      headerName: "Created By",
      width: 190,
      renderCell: (row) => (
        <div className="flex flex-row gap-x-2 items-center">
          <img
            // src={`https://api.dicebear.com/5.x/initials/svg?seed=${Promises.getName()}`}
            src={
              row.creatorImgUrl ||
              process.env.PUBLIC_URL + "/assets/images/default-img.png"
            }
            alt="user"
            className="rounded-circle w-6 h-6"
          />
          <p className="capitalize">{row.userName}</p>
        </div>
      ),
    },
    {
      field: "expiresAt",
      headerName: "Expires On",
      width: 150,
      renderCell: (row) => calculateExpirationDate(row.apiKey),
      // enableSorting: true,
    },
    {
      field: "actions",
      headerName: "",
      width: 80,
      renderCell: (row) => (
        <button
          className="text-red-500 hover:text-red-700"
          onClick={() => {
            setTwoAction({
              show: true,
              isDanger: true,
              successText: "Delete",
              abortText: "Cancel",
              label: "Delete API Key",
              description:
                "Are you sure you want to delete the key?\nDeleting the key means all the tools using this key will stop working.",
              onAbort: () => {
                setTwoAction({ show: false });
              },
              onSuccess: () => {
                handleDelete(row.id);
                setTwoAction({
                  show: false,
                });
              },
            });
          }}
        >
          <MdOutlineDelete size={18} className="text-primary-error" />
        </button>
      ),
    },
  ];

  useEffect(() => {
    fetchApiKeys();
  }, []);

  const fetchApiKeys = async () => {
    const token = Promises.getToken();
    const orgId = Promises.getOrg();

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BASE_URL}projects//integration/api-key/${orgId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("===================>", result);
        setApikeys(result.apiKeys);
      })
      .catch((error) => {
        console.log("error fetching api keys", error);
      });
  };

  const handleDelete = (id) => {
    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var raw = {
      isDelete: true,
    };

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    fetch(`${BASE_URL}/projects/integration/api-key/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }

        setApikeys((prev) => prev.filter((key) => key.id !== id));
      })
      .catch((error) => {
        // console.log("error", error);
        NotificationManager.error(
          "Something went wrong with saving your data, This may happen due to internal server error, Please try Again"
        );
        localStorage.clear();
        history.push("/login?redirect=1");
      });
  };

  return (
    <div className="w-full !overflow-hidden">
      <p className="px-4 pt-4 primary_heading">API Keys management</p>
      <div className="flex flex-row justify-end px-4 pb-2 my-10">
        <button
          className="px-2 py-1 pt-0 rounded-md text-white flex items-center"
          style={{ background: "#4094F7" }}
          onClick={() => {
            setIsCreatingApiKey(true);
          }}
        >
          <span className="pr-2 text-xl">+</span> New API Key
        </button>
      </div>

      <div className="w-full px-4 py-2 overflow-auto" style={{ height: 550 }}>
        <Table columns={columns} data={apikeys} />
      </div>
      {isCreatingApiKey && (
        <div
          className="h-[105vh] w-[100vw] absolute top-0 left-0 flex justify-center items-center"
          style={{
            zIndex: 9999 - 1,
            backgroundColor: "rgba(128, 128, 128, 0.8)",
          }}
        >
          <CreateAPIKey setIsCreatingApiKey={setIsCreatingApiKey} />
        </div>
      )}
      <NotificationContainer />
      {twoAction?.show && (
        <TwoActionPopup
          height={320}
          isDangerPage={twoAction.isDanger}
          successText={twoAction.successText}
          abortText={twoAction.abortText}
          label={twoAction.label}
          description={twoAction.description}
          onAbort={twoAction.onAbort}
          onSuccess={twoAction.onSuccess}
        />
      )}
    </div>
  );
};

const Input = ({
  label,
  placeholder,
  text,
  setText,
  disabled = false,
  textField = false,
  validateString = false,
  setShowErrors,
  setHasError,
}) => {
  const [error, setError] = useState(null);

  return (
    <div className=" flex-grow">
      <label className="input-label">{label}</label>
      {textField ? (
        <div>
          <textarea
            className=" focus:ring-indigo-500 focus:border-primary-blue block w-full sm:text-sm border-gray-300 rounded-[4px] px-3 text-[#222529] font-roboto !font-[400] text-[15px] !h-40 pt-2"
            style={{ backgroundColor: disabled ? "#F2F2F2" : "" }}
            type="text"
            placeholder={placeholder}
            value={text}
            onChange={(e) => {
              setText(e.target.value);
            }}
            disabled={disabled}
          />
        </div>
      ) : (
        <input
          className="capitalize focus:ring-indigo-500 focus:border-primary-blue block w-full sm:text-sm border-gray-300 h-[46px] rounded-[4px] px-3 text-[#222529] font-roboto !font-[400] text-[15px]"
          style={{ backgroundColor: disabled ? "#F2F2F2" : "" }}
          type="text"
          placeholder={placeholder}
          value={text}
          maxLength={40}
          onChange={(e) => {
            setText(e.target.value);
            setShowErrors(true);
            if (validateString && !isValidString(e.target.value)) {
              setError("Special character not allowed");
              setHasError(true);
            } else {
              setError(null);
              setHasError(false);
            }
          }}
          disabled={disabled}
        />
      )}
      {error && validateString && (
        <p className="text-secondary-error text-sm">
          {text.length < 3 ? "Minimum 3 characters are required" : error}
        </p>
      )}
    </div>
  );
};

const CreateAPIKey = ({ setIsCreatingApiKey }) => {
  const [keyDetails, setKeyDetails] = useState({
    name: "",
    expiry: 0,
    permanent: false,
  });

  const validityOptions = [
    {
      value: 7,
      label: "1 week",
    },
    {
      value: 14,
      label: "2 weeks",
    },
    {
      value: 30,
      label: "1 month",
    },
    {
      value: 60,
      label: "2 months",
    },
    {
      value: "-1",
      label: "Forever",
    },
  ];
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [apiKey, setApiKey] = useState(null);

  const createApiKey = async () => {
    if (
      keyDetails.permanent
        ? !keyDetails.name
        : keyDetails.expiry <= 0 || !keyDetails.name
    )
      return;

    const token = Promises.getToken();
    const orgId = Promises.getOrg();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var raw = keyDetails;

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    fetch(`${BASE_URL}projects/integration/api-key/${orgId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setApiKey(result.apiKey);
      })
      .catch((error) => {
        console.log("error", error);
        NotificationManager.error(
          "Something went wrong with saving your data, This may happen due to internal server error, Please try Again"
        );
      });
  };

  const handleCopyClick = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        NotificationManager.success("API Key copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  return (
    <div
      className="h-[450px] w-[390px] rounded-md bg-white flex flex-col justify-between"
      style={{ height: apiKey ? 380 : 450 }}
    >
      {!apiKey && (
        <div>
          <div className="mt-4 mx-4 mb-4">
            <p className="font-[400] opacity-75 mb-2 font-poppins">
              API Key name:
            </p>
            <input
              className="w-full px-2 py-1 border"
              placeholder="Ex: Jenkins Test API"
              value={keyDetails.name}
              onChange={(e) =>
                setKeyDetails((p) => {
                  return { ...p, name: e.target.value };
                })
              }
              maxLength={30}
            />
            <div className="flex justify-end items-end opacity-40">
              {keyDetails.name.length}/30
            </div>
          </div>
          <div className="mt-4 mx-4 mb-4">
            <p className="font-[400] opacity-75 mb-2 font-poppins">
              Validity Period:
            </p>
            {/* <Dropdown
          isOpen={isDropdownOpen}
          toggle={() => setIsDropdownOpen((p) => !p)}
        ></Dropdown> */}
            <Select
              className="project-member-select- text-secondary-description font-inter !font-[400]"
              classNamePrefix="react-select"
              isSearchable={false}
              onChange={({ value, label }) => {
                if (label !== "Forever")
                  setKeyDetails((p) => {
                    return { ...p, expiry: value };
                  });
                else
                  setKeyDetails((p) => {
                    return { ...p, permanent: true };
                  });
              }}
              options={validityOptions}
              isMulti={false}
              placeholder={"Select"}
              onInputChange={(val) =>
                val.length > 0
                  ? setIsDropdownOpen(true)
                  : setIsDropdownOpen(false)
              }
              styles={{
                control: (base) => ({
                  ...base,
                  border: "1px solid var(--pgreen, #00CD70)",
                  boxShadow: "none",
                  display: "flex",
                  alignItems: "center",
                  height: 44, // Set the desired height
                  minHeight: 44, // Ensure it doesn't go below this height
                  padding: "0 8px", // Adjust padding as needed
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  height: "100%",
                  // padding: "0 8px", // Adjust padding as needed
                  display: "flex",
                  alignItems: "center",
                }),
                input: (provided) => ({
                  ...provided,
                  margin: 0,
                  padding: 0,
                  height: "100%", // Ensures input height matches the container
                  display: "flex",
                  alignItems: "center",
                }),
                placeholder: (provided) => ({
                  ...provided,
                  fontSize: 14, // Adjust font size as needed
                  display: "flex",
                  alignItems: "center",
                  height: "100%", // Ensures placeholder height matches the container
                }),
                option: (base, { isFocused }) => ({
                  ...base,
                  backgroundColor: isFocused ? "#DEFFE9" : "white",
                  display: "flex",
                  alignItems: "center",
                }),
                singleValue: (provided) => ({
                  ...provided,
                  display: "flex",
                  alignItems: "center",
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  display: "flex",
                  alignItems: "center",
                }),
                clearIndicator: (provided) => ({
                  ...provided,
                  display: "flex",
                  alignItems: "center",
                }),
              }}
            />
          </div>
        </div>
      )}
      {apiKey && (
        <div className="px-4 py-2">
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
            <rect
              x="4"
              y="4"
              width="48"
              height="48"
              rx="24"
              stroke="#ECFDF3"
              stroke-width="8"
            />
            <path
              d="M38 27.0801V28.0001C37.9988 30.1565 37.3005 32.2548 36.0093 33.9819C34.7182 35.7091 32.9033 36.9726 30.8354 37.584C28.7674 38.1954 26.5573 38.122 24.5345 37.3747C22.5117 36.6274 20.7847 35.2462 19.611 33.4372C18.4373 31.6281 17.8798 29.4882 18.0217 27.3364C18.1636 25.1847 18.9972 23.1364 20.3983 21.4972C21.7994 19.8579 23.6928 18.7155 25.7962 18.2403C27.8996 17.765 30.1003 17.9824 32.07 18.8601M38 20.0001L28 30.0101L25 27.0101"
              stroke="#00CD70"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      )}
      {apiKey && (
        <div className="px-4 py-">
          <p className="text-lg font-bold mb-4">API Key Created</p>

          <div className="font-mono text-wrap break-all mb-3">{apiKey}</div>
        </div>
      )}
      <div className="flex flex-row justify-between items-center px-4 gap-x-2 mb-8 font-poppins text-[14px] !font-[600]">
        <button
          className="h-[44px] w-32 rounded-md  flex items-center justify-center bg-[#E7EAEE] text-[#64748B]"
          onClick={() => setIsCreatingApiKey(false)}
        >
          Cancel
        </button>
        <button
          className={`h-[44px] flex-grow rounded-md shadow flex items-center primary_btn justify-center gap-x-2 ${
            (
              keyDetails.permanent
                ? !keyDetails.name
                : keyDetails.expiry <= 0 || !keyDetails.name
            )
              ? " opacity-70 "
              : ""
          } bg-primary-green !text-white text-bold`}
          onClick={() => (apiKey ? handleCopyClick(apiKey) : createApiKey())}
          disabled={
            keyDetails.permanent
              ? !keyDetails.name
              : keyDetails.expiry <= 0 || !keyDetails.name
          }
        >
          {!apiKey && (
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.7703 5.75877C18.0876 6.09212 18.0746 6.6196 17.7412 6.93692L8.1116 16.1036C7.94715 16.2601 7.72596 16.3428 7.49915 16.3325C7.27234 16.3222 7.05957 16.2197 6.91002 16.0489L3.20631 11.8181C2.90316 11.4718 2.93813 10.9454 3.28443 10.6422C3.63072 10.3391 4.15719 10.374 4.46035 10.7203L7.59176 14.2974L16.5921 5.72975C16.9254 5.41242 17.4529 5.42542 17.7703 5.75877Z"
                fill="white"
              />
            </svg>
          )}
          {apiKey ? "Copy" : "Create"}
        </button>
      </div>
    </div>
  );
};

const Icon = ({ option, selectedOption }) => {
  return (
    <div className="w-12 h-12 flex items-center">
      {option.icon === "user" && (
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_84_544)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21.5217 11C21.5217 5.16523 16.8348 0.478271 11 0.478271C5.16523 0.478271 0.478271 5.16523 0.478271 11C0.478271 15.4957 3.25218 19.3217 7.26958 20.8522C8.32175 21.3304 9.56523 21.5217 11 21.5217C12.4348 21.5217 13.7739 21.2348 14.7304 20.8522C18.7478 19.3217 21.5217 15.4957 21.5217 11ZM16.3565 17.6957C15.5913 16.5478 13.487 15.7826 11 15.7826C8.51305 15.7826 6.40871 16.5478 5.64349 17.6957C3.73045 16.0696 2.39131 13.6783 2.39131 11C2.39131 6.2174 6.2174 2.39131 11 2.39131C15.7826 2.39131 19.6087 6.2174 19.6087 11C19.6087 13.6783 18.3652 16.0696 16.3565 17.6957Z"
              fill={selectedOption === option.key ? "white" : "#292D32"}
            />
            <path
              d="M10.9999 11.9564C12.5848 11.9564 13.8695 10.6717 13.8695 9.08685C13.8695 7.50203 12.5848 6.21729 10.9999 6.21729C9.41512 6.21729 8.13037 7.50203 8.13037 9.08685C8.13037 10.6717 9.41512 11.9564 10.9999 11.9564Z"
              fill={selectedOption === option.key ? "white" : "#292D32"}
            />
          </g>
          <defs>
            <clipPath id="clip0_84_544">
              <rect width="22" height="22" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
      {option.icon === "bell" && (
        <MdOutlineEditNotifications
          className={
            option.key === selectedOption ? " !text-white " : "text-gray-500"
          }
          size={32}
        />
      )}
      {option.icon === "shield" && (
        <svg
          width="22"
          height="24"
          viewBox="0 0 22 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.5981 11.124C19.5981 16.014 16.0481 20.594 11.1981 21.934C10.8681 22.024 10.5081 22.024 10.1781 21.934C5.32807 20.594 1.77808 16.014 1.77808 11.124V6.73393C1.77808 5.91393 2.39809 4.98394 3.16809 4.67394L8.73811 2.39397C9.98811 1.88397 11.3981 1.88397 12.6481 2.39397L18.2181 4.67394C18.9781 4.98394 19.6081 5.91393 19.6081 6.73393L19.5981 11.124Z"
            // stroke="#292D32"
            stroke-width="1.75"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke={selectedOption === option.key ? "white" : "#292D32"}
          />
          <path
            d="M10.688 12.5039C11.7926 12.5039 12.688 11.6085 12.688 10.5039C12.688 9.39934 11.7926 8.50391 10.688 8.50391C9.58339 8.50391 8.68799 9.39934 8.68799 10.5039C8.68799 11.6085 9.58339 12.5039 10.688 12.5039Z"
            stroke={selectedOption === option.key ? "white" : "#292D32"}
            stroke-width="1.75"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.688 12.5039V15.5039"
            stroke={selectedOption === option.key ? "white" : "#292D32"}
            stroke-width="1.75"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )}
      {option.icon === "key" && (
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.5 14.3201C18.8137 14.3201 21.5 11.6338 21.5 8.32007C21.5 5.00636 18.8137 2.32007 15.5 2.32007C12.1863 2.32007 9.5 5.00636 9.5 8.32007C9.5 11.6338 12.1863 14.3201 15.5 14.3201Z"
            stroke={selectedOption === option.key ? "white" : "#292D32"}
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.5 19.3201L6.5 17.3201"
            stroke={selectedOption === option.key ? "white" : "#292D32"}
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5.5 22.3201L3.5 20.3201"
            stroke={selectedOption === option.key ? "white" : "#292D32"}
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M3.5 20.3201L11.26 12.5601"
            stroke={selectedOption === option.key ? "white" : "#292D32"}
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )}
      {option.icon === "help" && (
        <svg
          width="19"
          height="18"
          viewBox="0 0 19 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.2549 7.362C16.9544 4.5 15.132 0 9.49991 0C3.86785 0 2.04543 4.5 1.74492 7.362C0.690736 7.73346 -0.00461862 8.67315 2.3097e-05 9.72V10.98C2.3097e-05 12.3718 1.21526 13.5 2.71429 13.5C4.21333 13.5 5.42854 12.3718 5.42854 10.98V9.72C5.42367 8.69575 4.75385 7.77536 3.73214 7.389C3.92601 5.733 4.876 1.8 9.49991 1.8C14.1238 1.8 15.0641 5.733 15.258 7.389C14.2383 7.7762 13.5719 8.6972 13.5713 9.72V10.98C13.5755 11.9475 14.1738 12.828 15.1126 13.248C14.7054 13.959 13.6682 14.922 11.1188 15.21C10.6091 14.4915 9.62317 14.2083 8.76556 14.534C7.90794 14.8597 7.41846 15.7033 7.5973 16.5474C7.77614 17.3915 8.57332 18 9.49991 18C10.2321 17.9962 10.8996 17.6098 11.2254 17.001C15.384 16.56 16.7121 14.571 17.1289 13.401C18.2566 13.0619 19.015 12.0805 18.9998 10.98V9.72C19.0044 8.67315 18.3091 7.73346 17.2549 7.362ZM3.48979 10.98C3.48979 11.3776 3.14259 11.7 2.71429 11.7C2.286 11.7 1.93879 11.3776 1.93879 10.98V9.72C1.93879 9.46277 2.0866 9.22508 2.32654 9.09646C2.56648 8.96784 2.8621 8.96784 3.10204 9.09646C3.34198 9.22508 3.48979 9.46277 3.48979 9.72V10.98ZM15.51 9.72C15.51 9.32235 15.8572 9 16.2855 9C16.7138 9 17.061 9.32235 17.061 9.72V10.98C17.061 11.3776 16.7138 11.7 16.2855 11.7C15.8572 11.7 15.51 11.3776 15.51 10.98V9.72Z"
            fill={selectedOption === option.key ? "white" : "#292D32"}
          />
        </svg>
      )}
    </div>
  );
};

const Support = () => {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    message: "",
    msg_err: "",
  });

  useEffect(() => {
    setFormData((p) => ({
      ...p,
      email: Promises.getEmail(),
      name: Promises.getName(),
    }));
  }, []);

  const [canSend, setCanSend] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const sendmail = () => {
    const { name, email, message, phoneNumber } = formData;
    console.log("SAVING DATA =============>", phoneNumber);

    if (!isValidPhoneNumber(phoneNumber)) {
      NotificationManager.error("Enter Valid Phone Number");
      return;
    }
    if (canSend) {
      var myHeaders = new Headers();
      myHeaders.append("X-L10N-Locale", "en");
      myHeaders.append("Content-Type", "application/json");

      var raw = {
        name: name,
        email: email,
        msg: message,
        phoneNumber,
      };

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(raw),
        redirect: "follow",
      };

      fetch(`${BASE_URL}auth/send-mail`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 401) {
            this.setState({ loading: false });
            localStorage.clear();
            history.push("/login?redirect=1");
          }
          NotificationManager.info(result.msg);
        })
        .catch((error) => {
          console.log("error send-email", error);
        });
      setFormData({ message: "" });
      setSubmitted(true);
    }
  };

  return (
    <div className="h-[90vh] overflow-auto">
      {!submitted && (
        <div className="flex lg:flex-row lg:gap-x-[13%] md:flex-col pl-3 pt-3  ">
          <div
            className="d-flex lg:flex-grow md:w-full flex-column align-items-center justify-content-center font-roboto !font-[400] !text-[#303030CC] !text-[16px]"
            style={
              {
                // paddingTop: 90,
                // paddingBottom: "180px",
              }
            }
          >
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="card-body noauth-pricing-page"
                  // style={{
                  //   position: "relative",
                  //   width: 926,
                  //   border: '1px solid #e0e0e0',
                  //   borderRadius: 15
                  // }}
                >
                  <div className="modal-header clearfix">
                    <h1 className="primary_heading mb-2">
                      Need help? Get in touch
                    </h1>
                    {/* <i
            className="mdi mdi-close"
            onClick={() => this.setState({ createModalVisible: false })}
          ></i> */}
                  </div>
                  <div>
                    <p className="w-[85%] ">
                      Fill out the form below or send us an email. We're here to
                      support you and will get back to you as soon as possible.
                    </p>
                  </div>
                  <label
                    id="label"
                    className="mt-[30px] input-label"
                    htmlFor="name"
                  >
                    Your Full Name{" "}
                    <span className="text-secondary-error">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    value={formData.name}
                    style={{
                      borderColor: "#e0e0e0",
                      backgroundColor: "#F2F2F2",
                    }}
                    onChange={(e) => this.handleChangeEmail(e, "name")}
                    placeholder="John Smith"
                    disabled
                  />
                  {/* <span id="err" style={{ color: "red" }}>
                  {this.state.name_err}
                </span> */}
                  <label
                    id="label"
                    className="mt-[15px] input-label"
                    htmlFor="name"
                  >
                    Email <span className="text-secondary-error">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    value={formData.email}
                    style={{
                      borderColor: "#e0e0e0",
                      backgroundColor: "#F2F2F2",
                    }}
                    onChange={(e) => this.handleChangeEmail(e, "email")}
                    id="email"
                    placeholder="you@company.com"
                    disabled
                  />
                  <div className="form-field mt-4">
                    <label className="input-label" htmlFor="phone">
                      Phone Number
                      <span className="text-secondary-error">*</span>
                    </label>
                    <div className="phone-field- w-full border-">
                      {/* <select name="phone" id="phone">
                  <option value="+91">+91</option>
                  <option value="+10">+10</option>
                </select> */}
                      {/* <input
                          type="text"
                          id="phone"
                          name="phone"
                          style={{
                            border: "1px solid #D0D5DD",
                            boxShadow: "0px 1px 2px 0px #1018280D",
                          }}
                        /> */}
                      <PhoneInputWithCountrySelect
                        international
                        defaultCountry="US"
                        value={""}
                        onChange={(phone) => {
                          console.log("phone ", phone);
                          setFormData((p) => ({ ...p, phoneNumber: phone }));
                        }}
                        placeholder="Enter phone number"
                        numberInputProps={{
                          className: "rounded-md px-4 focus:outline-none...", // my Tailwind classes
                        }}
                      />
                    </div>
                  </div>
                  {/* <span id="err" style={{ color: "red" }}>
                  {this.state.email_err}
                </span> */}
                  <label
                    id="label"
                    className="mt-[15px] input-label"
                    htmlFor="name"
                  >
                    Message <span className="text-secondary-error">*</span>
                  </label>
                  <textarea
                    row="5"
                    id="contact-msg"
                    value={formData.message}
                    onChange={(e) =>
                      setFormData((p) => ({ ...p, message: e.target.value }))
                    }
                    style={{
                      border: "1px solid #D0D5DD",
                      boxShadow: "0px 1px 2px 0px #1018280D",
                    }}
                    className="px-2 py-1"
                  ></textarea>
                  <div className="flex flex-row items-center justify-between">
                    <div />
                    <p className="text-sm text-gray-200">
                      {formData.message.length}/1000
                    </p>
                  </div>
                  <span id="err" style={{ color: "red" }}>
                    {formData.msg_err}
                  </span>

                  <div className="form-field ">
                    <ReCAPTCHA
                      style={{ marginTop: "0px" }}
                      sitekey={recaptha_siteKey}
                      onChange={(e) => setCanSend(true)}
                    />
                  </div>
                  <button
                    type="button"
                    className="primary_btn w-40 "
                    onClick={() => sendmail()}
                    disabled={
                      !(
                        formData.message?.length > 3 &&
                        formData.phoneNumber?.length > 4 &&
                        canSend
                      )
                    }
                    style={{
                      opacity:
                        formData.message?.length > 3 &&
                        formData.phoneNumber?.length > 4 &&
                        canSend
                          ? 1
                          : 0.6,
                    }}
                  >
                    Send message
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-grow- lg:w-[35%] flex items-center justify-center lg:pr-20">
            <ContactSidebar />
          </div>
        </div>
      )}
      {submitted && (
        <div className="h-full flex items-center justify-center bg-[#92c6ff1a]">
          <SuccessComponent
            successAction={() => {
              history.push("/dashboard");
            }}
            successText="Back to dashboard"
            label="Ticket raised successfully"
            description="Thank you for submitting your ticket. Your request has been received and is currently under review."
          />
        </div>
      )}
      <NotificationContainer />
    </div>
  );
};

const Security = () => {
  const [data, setData] = useState({
    oldPass: "",
    newPass: "",
    confPass: "",
  });
  const [error, setError] = useState("");
  const [hidden, setHidden] = useState({ pass1: true, pass2: true });

  const onChangePasswordSubmit = async () => {
    const { oldPass, newPass, confPass } = data;
    if (!confPass || !newPass || !oldPass || newPass !== confPass) {
      return;
    }

    const token = Promises.getToken();

    const raw = {
      oldPassword: oldPass,
      newPassword: newPass,
      password: newPass,
    };
    console.log("stringified raw", JSON.stringify(raw));
    var requestOptions = {
      method: "POST",
      headers: {
        "X-L10N-Locale": "en",
        "Content-Type": "application/json",
        authorization: token,
      },
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    fetch(`${BASE_URL}auth/change-password`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const parsed = JSON.parse(result);
        if (parsed.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        if (parsed.code === 200) {
          NotificationManager.success("Password changed successfully");
          setTimeout(() => {
            history.push("/login?redirect=1");
          }, 1500);
        } else {
          NotificationManager.error(parsed.error);
        }
      })
      .catch((error) => {
        NotificationManager.error(
          "Something went wrong, Please try again later"
        );
        console.log("error", error);
      });
  };

  return (
    <div className="px-4 py-4 max-w-[937px]">
      <div className="pb-4 border-b">
        <p className="primary_heading">Security Settings</p>
      </div>
      <div className="max-w-[500px]">
        <>
          <h2 className="font-raleway !font-[700] my-4 text-[22px]">
            Change password
          </h2>

          <div className="form-field no-margin">
            <div className="flex flex-row justify-between items-center">
              <label
                className="form-field-label text-primary-description font-roboto !font-[500]"
                style={{ fontSize: 14 }}
                htmlFor="userpassword"
              >
                Old Password
              </label>
            </div>
            <input
              type="password"
              id="new-password"
              // className="py-3"
              name="new-password"
              value={data.oldPass}
              onChange={(e) => {
                setData((prev) => ({ ...prev, oldPass: e.target.value }));
              }}
            />
          </div>

          <div className="form-field no-margin">
            <div className="flex flex-row justify-between items-center">
              <label
                className="form-field-label text-primary-description font-roboto !font-[500]"
                style={{ fontSize: 14 }}
                htmlFor="userpassword"
              >
                New Password
              </label>
              <div
                className="flex cursor-pointer gap-x-1 items-center"
                onClick={() => {
                  setHidden((p) => ({ ...p, pass1: !p.pass1 }));
                }}
              >
                {!hidden.pass1 ? (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5687 4.06778L15.9554 3.45449C15.7821 3.28117 15.4621 3.30784 15.2621 3.5478L13.1286 5.66777C12.1686 5.25448 11.1153 5.05448 10.0086 5.05448C6.71519 5.06776 3.86204 6.98774 2.48861 9.74791C2.40858 9.92124 2.40858 10.1345 2.48861 10.2812C3.12855 11.5879 4.08861 12.6679 5.28861 13.4812L3.54195 15.2545C3.34195 15.4545 3.31528 15.7745 3.44865 15.9478L4.06193 16.5611C4.23526 16.7344 4.55525 16.7077 4.75525 16.4678L16.4618 4.76121C16.7152 4.56132 16.7419 4.24135 16.5685 4.06801L16.5687 4.06778ZM10.7153 8.09435C10.4886 8.041 10.2486 7.97437 10.022 7.97437C8.88859 7.97437 7.98202 8.88106 7.98202 10.0143C7.98202 10.241 8.03536 10.4809 8.10199 10.7076L7.20856 11.5877C6.94193 11.121 6.79528 10.6009 6.79528 10.0143C6.79528 8.24103 8.22196 6.81435 9.99528 6.81435C10.582 6.81435 11.102 6.961 11.5686 7.22763L10.7153 8.09435Z"
                      fill="#666666"
                      fill-opacity="0.8"
                    />
                    <path
                      d="M17.5287 9.7479C17.0621 8.81453 16.4487 7.97458 15.6888 7.29456L13.2087 9.7479V10.0145C13.2087 11.7878 11.782 13.2145 10.0087 13.2145H9.74208L8.16876 14.7878C8.75548 14.9078 9.36877 14.9878 9.96877 14.9878C13.2622 14.9878 16.1154 13.0679 17.4888 10.2944C17.6088 10.1077 17.6088 9.9211 17.5287 9.74776L17.5287 9.7479Z"
                      fill="#666666"
                      fill-opacity="0.8"
                    />
                  </svg>
                ) : (
                  <svg
                    width="14"
                    height="9"
                    viewBox="0 0 14 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.868077 5.80351C1.46578 2.92461 3.96439 0.879054 6.775 0.879054C9.58473 0.879054 12.0833 2.92461 12.6819 5.80351C12.7056 5.91775 12.7736 6.01786 12.8709 6.08183C12.9682 6.14579 13.0869 6.16837 13.2008 6.14459C13.3146 6.12081 13.4145 6.05262 13.4782 5.95502C13.542 5.85743 13.5645 5.73842 13.5408 5.62419C12.8598 2.34971 10.0115 0 6.775 0C3.53846 0 0.690168 2.34971 0.00920718 5.62419C-0.0145012 5.73842 0.00800534 5.85743 0.0717757 5.95502C0.135546 6.05262 0.235356 6.12081 0.34925 6.14459C0.463143 6.16837 0.58179 6.14579 0.679089 6.08183C0.776388 6.01786 0.844369 5.91775 0.868077 5.80351ZM6.76624 2.63716C7.57976 2.63716 8.35996 2.96131 8.93521 3.5383C9.51046 4.11529 9.83363 4.89786 9.83363 5.71385C9.83363 6.52984 9.51046 7.31241 8.93521 7.8894C8.35996 8.46639 7.57976 8.79054 6.76624 8.79054C5.95271 8.79054 5.17251 8.46639 4.59726 7.8894C4.02201 7.31241 3.69884 6.52984 3.69884 5.71385C3.69884 4.89786 4.02201 4.11529 4.59726 3.5383C5.17251 2.96131 5.95271 2.63716 6.76624 2.63716Z"
                      fill="#6A707C"
                    />
                  </svg>
                )}
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "19.5px",
                    textAlign: "right",
                    opacity: 0.7,
                  }}
                >
                  {hidden.pass1 ? "Show" : "Hide"}
                </p>
              </div>
            </div>
            <input
              type={hidden.pass1 ? "password" : "text"}
              id="new-password"
              // className="py-3"
              name="new-password"
              value={data.newPass}
              onChange={(e) => {
                let res = validatePassword(e.target.value);
                setError(
                  res.status
                    ? e.target.value !== data.confPass
                      ? "Your passwords don’t match. Please try again."
                      : ""
                    : res.msg
                );
                setData((prev) => ({ ...prev, newPass: e.target.value }));
              }}
            />
          </div>

          <div className="form-field no-margin">
            <div className="flex flex-row justify-between items-center">
              <label
                className="form-field-label text-primary-description font-roboto !font-[500]"
                style={{ fontSize: 14 }}
                htmlFor="userpassword"
              >
                Confirm Password
              </label>
              <div
                className="flex cursor-pointer gap-x-1 items-center"
                onClick={() => {
                  setHidden((p) => ({ ...p, pass2: !p.pass2 }));
                }}
              >
                {!hidden.pass2 ? (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5687 4.06778L15.9554 3.45449C15.7821 3.28117 15.4621 3.30784 15.2621 3.5478L13.1286 5.66777C12.1686 5.25448 11.1153 5.05448 10.0086 5.05448C6.71519 5.06776 3.86204 6.98774 2.48861 9.74791C2.40858 9.92124 2.40858 10.1345 2.48861 10.2812C3.12855 11.5879 4.08861 12.6679 5.28861 13.4812L3.54195 15.2545C3.34195 15.4545 3.31528 15.7745 3.44865 15.9478L4.06193 16.5611C4.23526 16.7344 4.55525 16.7077 4.75525 16.4678L16.4618 4.76121C16.7152 4.56132 16.7419 4.24135 16.5685 4.06801L16.5687 4.06778ZM10.7153 8.09435C10.4886 8.041 10.2486 7.97437 10.022 7.97437C8.88859 7.97437 7.98202 8.88106 7.98202 10.0143C7.98202 10.241 8.03536 10.4809 8.10199 10.7076L7.20856 11.5877C6.94193 11.121 6.79528 10.6009 6.79528 10.0143C6.79528 8.24103 8.22196 6.81435 9.99528 6.81435C10.582 6.81435 11.102 6.961 11.5686 7.22763L10.7153 8.09435Z"
                      fill="#666666"
                      fill-opacity="0.8"
                    />
                    <path
                      d="M17.5287 9.7479C17.0621 8.81453 16.4487 7.97458 15.6888 7.29456L13.2087 9.7479V10.0145C13.2087 11.7878 11.782 13.2145 10.0087 13.2145H9.74208L8.16876 14.7878C8.75548 14.9078 9.36877 14.9878 9.96877 14.9878C13.2622 14.9878 16.1154 13.0679 17.4888 10.2944C17.6088 10.1077 17.6088 9.9211 17.5287 9.74776L17.5287 9.7479Z"
                      fill="#666666"
                      fill-opacity="0.8"
                    />
                  </svg>
                ) : (
                  <svg
                    width="14"
                    height="9"
                    viewBox="0 0 14 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.868077 5.80351C1.46578 2.92461 3.96439 0.879054 6.775 0.879054C9.58473 0.879054 12.0833 2.92461 12.6819 5.80351C12.7056 5.91775 12.7736 6.01786 12.8709 6.08183C12.9682 6.14579 13.0869 6.16837 13.2008 6.14459C13.3146 6.12081 13.4145 6.05262 13.4782 5.95502C13.542 5.85743 13.5645 5.73842 13.5408 5.62419C12.8598 2.34971 10.0115 0 6.775 0C3.53846 0 0.690168 2.34971 0.00920718 5.62419C-0.0145012 5.73842 0.00800534 5.85743 0.0717757 5.95502C0.135546 6.05262 0.235356 6.12081 0.34925 6.14459C0.463143 6.16837 0.58179 6.14579 0.679089 6.08183C0.776388 6.01786 0.844369 5.91775 0.868077 5.80351ZM6.76624 2.63716C7.57976 2.63716 8.35996 2.96131 8.93521 3.5383C9.51046 4.11529 9.83363 4.89786 9.83363 5.71385C9.83363 6.52984 9.51046 7.31241 8.93521 7.8894C8.35996 8.46639 7.57976 8.79054 6.76624 8.79054C5.95271 8.79054 5.17251 8.46639 4.59726 7.8894C4.02201 7.31241 3.69884 6.52984 3.69884 5.71385C3.69884 4.89786 4.02201 4.11529 4.59726 3.5383C5.17251 2.96131 5.95271 2.63716 6.76624 2.63716Z"
                      fill="#6A707C"
                    />
                  </svg>
                )}
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "19.5px",
                    textAlign: "right",
                    opacity: 0.7,
                  }}
                >
                  {hidden.pass2 ? "Show" : "Hide"}
                </p>
              </div>
            </div>
            <input
              type={hidden.pass2 ? "password" : "text"}
              id="confirm-password"
              name="confirm-password"
              value={data.confPass}
              onChange={(e) => {
                setError(
                  data.newPass !== e.target.value
                    ? "Your passwords don’t match. Please try again."
                    : ""
                );
                setData((prev) => ({ ...prev, confPass: e.target.value }));
              }}
            />
            <div className="mt-2">
              {(!error || !data.newPass) && (
                <div style={{ display: "flex" }}>
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 13 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <ellipse
                      cx="6.07325"
                      cy="6"
                      rx="6.07325"
                      ry="6"
                      fill="#316BFF"
                    />
                    <path
                      d="M6.88307 3.538C6.88307 3.748 6.80807 3.928 6.65807 4.078C6.50807 4.228 6.32807 4.303 6.11807 4.303C5.90807 4.303 5.72807 4.228 5.57807 4.078C5.42807 3.928 5.35307 3.748 5.35307 3.538C5.35307 3.328 5.42807 3.148 5.57807 2.998C5.72807 2.848 5.90807 2.773 6.11807 2.773C6.32807 2.773 6.50807 2.848 6.65807 2.998C6.80807 3.148 6.88307 3.328 6.88307 3.538ZM5.47907 10.126V4.852L6.76607 5.005L6.58607 10L5.47907 10.126Z"
                      fill="white"
                    />
                  </svg>
                  <p
                    className="text-sm text-[#316BFF] ml-1"
                    style={{ fontSize: 12, fontWeight: 400 }}
                  >
                    Your password must be at least 8 characters long and include
                    one uppercase letter, number, and a special character.
                  </p>
                </div>
              )}
              {error && data.newPass && (
                <p className="text-sm text-primary-error">{error}</p>
              )}
            </div>
          </div>
          <div className="login-btn-div- flex items-center gap-x-8">
            <button
              className="btn btn-primary primary_btn w-[16rem]"
              type="button"
              onClick={onChangePasswordSubmit}
              disabled={error?.length > 0 || !data.newPass || !data.oldPass}
              style={{
                opacity:
                  error?.length > 0 || !data.newPass || !data.oldPass ? 0.7 : 1,
              }}
            >
              change password
            </button>
            <p
              className="text-secondary-error font-[400] cursor-pointer text-sm opacity-50"
              onClick={() => {
                history.push("/forgetpassword");
              }}
            >
              Forgot password?
            </p>
          </div>
          {/* </form> */}
        </>
      </div>
      <NotificationContainer />
    </div>
  );
};

export default Pages_settings;
