const organizationName = async () => {
  const url = window.location.href; // Get the current URL

  // Regular expression to extract the organization name
  const regex = /https?:\/\/([^.]+)\.localhost:3001\/testcase\/[a-z0-9\-]+/; //local
  // const regex = /https?:\/\/([^.]+)\.oyetest\.com\/testcase\/[a-z0-9\-]+/;   //oyetest
  // const regex = /https?:\/\/([^.]+)\.joslery\.com\/testcase\/[a-z0-9\-]+/;   //joslery

  const match = url.match(regex);

  let organizationName;
  if (match) {
    organizationName = match[1]; // Capture the first group (organization name)
  } else {
    organizationName = ""; // Handle cases where URL doesn't match
  }

  // console.log("Organization Name:", organizationName); // For verification
  return organizationName;
};

const getShareId = async () => {
  const urlPathname = window.location.pathname; // Get the path portion of the URL

  // Regular expression to extract the snapshot id from the URL pathname
  const regex = /\/testcase\/([a-z0-9\-]+)/;
  const match = urlPathname.match(regex);

  let testcaseId;
  if (match) {
    testcaseId = match[1]; // Capture the first group (testcaseId)
    return testcaseId;
  } else {
    return null;
  }
};
const getFullUrl = () => {
  const url = window.location.href;
  return url;
};
export { organizationName, getShareId, getFullUrl };
