import React from "react";
import Skeleton from "react-loading-skeleton";

const ProjectCardSkeleton = () => {
  return (
    <div className="project-card-skeleton h-full w-full py-[14px] px-4">
      <div className="h-[50%] bg-blue-100- border-b">
        <Skeleton className="mb-1" height={25} width={"90%"} />
        <Skeleton height={18} width={"55%"} />
        <div className="overflow-hidden w-[70%]" style={{ borderRadius: 200 }}>
          <Skeleton height={18} />
        </div>
      </div>
      <div className="h-[50%] bg-red-500- pt-2">
        <div className="flex flex-row items-center justify-between pr-16">
          <Skeleton height={18} width={110} />
          <Skeleton height={20} width={20} circle />
        </div>
        <div className="flex flex-row items-center justify-between pr-12">
          <Skeleton height={18} width={130} />
          <Skeleton height={20} width={20} circle />
        </div>
        <div className="flex flex-row items-center justify-between pr-8">
          <Skeleton height={18} width={150} />
          <Skeleton height={20} width={20} circle />
        </div>
      </div>
    </div>
  );
};

export default ProjectCardSkeleton;
