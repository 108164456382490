import React, { Component } from "react";
import { FooterSection, LandingNav } from "./Pages_landing";

export default class Pages_Terms extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="custom-container home-page !mb-3">
     <LandingNav scrolled={true} />
        <section className="description-block !pt-28">
          <div className="page-custom-container">
            <div className="custom-row">
              <div>
                <div>
                  <h2 className="heading">Terms of Use</h2>
                  <p>This is a legal agreement between you, the potential user (“You”) and Oyesense (formerly known as Oyesense OPC Private Limited), its subsidiaries and its affiliates.</p>
                  <p>These Terms of Use (“Terms”) governs the access or use by You, of the applications, websites, contents, products and services (“the Services”), being made available by Oyesense Limited. PLEASE READ THESE TERMS CAREFULLY BEFORE USING THIS WEBSITE OR SERIVCES. Your access or use of Oyesense Limited’s products, services, website located at URL: https://www.Oyetest.com/ (“Site”), mobile site, mobile application (collectively called as “Platform”), is conditioned on your acceptance, without modification, of these Terms. If you do not agree to these Terms, you should not use the Services. You accept the Terms by using the Oyesense Limited Services in any way. In this case, you understand and agree that Oyesense Limited will consider your use of the Services as agreement to the Terms. The Terms include the provisions in this document, as well as those in the Privacy Policy and any additional terms that you agree to.</p>
                  <p>This platform is owned by Oyesense Limited, a company incorporated under the Companies Act, 2013 having its registered office at Unit No. 1201, 12th Floor, Block – A, Laxmi Narayan Resi, Devdaya nagar Road -2 , Thane west – 400610, Maharashtra, India .</p>
                </div>
                <div>
                  <h3>BY USING THIS SITE, YOU ACKNOWLEDGE AND CONFIRM THAT:</h3>
                  <p>These Terms are published and shall be construed in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 under the Information Technology Act, 2000 which requires publishing the rules and regulations, privacy policy and user agreement for access or usage of Oyesense Limited Platform. </p>
                  <p>These Terms are an electronic record in terms of Information Technology Act, 2000 and Rules thereunder as applicable and amended provisions pertaining to electronic records in various statutes under relevant Indian Laws. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
                </div>
                <div>
                  <h3>USER AGREEMENT</h3>
                  <p>All references to "You" or "User", as applicable, shall mean any natural or legal person that accesses, uses or agrees to become a buyer by registering on the Platform as Registered User in order to avail the Services. The User can access the Site or the Platform in accordance with the terms and conditions as set out below. This Agreement/ Terms of Use are for an indefinite period. Oyesense Limited (“Company”/ “We”/ "Us"/ "Our"), at its sole discretion, modify these Terms at any time, without any prior written notice to you. You shall ensure periodical review of these Terms for updates or changes. If you don’t agree with the new Terms, you are free to reject them; unfortunately, that means you will no longer be able to use the Services. If you use the Services in any way after a change to the Terms is effective, that means you agree to all of the changes, and will be bound by them as is.</p>
                </div>
                <div>
                  <h3>ELIGIBILITY</h3>
                  <p>You represent, acknowledge and warrant that you are an individual of legal age, of at least 18 years, to form a binding contract.  If you are under the age of 18 (Eighteen) years you have received your parent’s or guardian’s consent to these Terms on your behalf, to use the Services. We reserve the right to indefinitely refuse, suspend, terminate or block your access and use of our Services on our Platform through your registered account if it is brought to our notice or if it is discovered that you are under the age of 18 years and using our Platform without requisite permissions.</p>
                  <p>You represent, acknowledge and warrant that you are an individual of legal age to form a binding contract. If not, you have received your parent’s or guardian’s consent to these Terms on your behalf, to use the Services. If your use of the Services is prohibited by applicable laws, then you are not authorised to use the Services. We reserve the right to indefinitely refuse, suspend, terminate or block your access and use of our Services on our Platform through your registered account if it is brought to our notice or if it is discovered that your use or access of our Services or Platform is, in any manner, in contravention of applicable laws.</p>
                </div>
                <div>
                  <h3>CHARGES</h3>
                  <p>The Platform reserves the right to charge subscription and / or membership fee(s) in respect of any part or aspect of the Services, provided over the Platform, upon prior notice and also reserves the right to modify such charge(s)/ fee(s) from time to time towards existing and/ or newly introduced services, as the case may be. You are responsible for obtaining access to the service, and that access may involve third party fees (such as internet service provider or airtime charges, messages or data charges). You are responsible for the fee(s) associated with the display or delivery of ordered products. In addition, you are responsible for all equipment necessary to access the service. The Subscriptions are set to automatically renew, if applicable, unless and until you cancel the automatic renewal 7 days prior the next renewal date.</p>
                  <p>Further we enable online verification and authorization of payments through Payment services providers by payment gateways. All Credit Card, Debit Card, E-Wallet and Net Banking transactions are processed over a secured encrypted connection. So you can be rest assured about the mode of payment you choose.</p>
                  <p>The following Payment modes are available on the Website:

Credit Cards: Domestic and International Visa, Master Card and American Express
Debit Cards: Debit Cards of most of the major banks
UPI
Net banking
Wallets, Cash Cards, etc.
Cash on Delivery (if applicable): In this mode, the payment is made at the time of the delivery of your order. However, the COD facility is available only in India.
International Customers can pay via Cards or Paypal as applicable.
</p>
                </div>
                <div>
                  <h3>INTELLECTUAL PROPERTY RIGHTS</h3>
                  <p>Unless otherwise stated, all material(s) including but not limited to texts, audios, videos or graphical images, software, trademarks, logos, service marks, domain names and other intellectual property rights (“IPR”), as reflected/ affixed/ or contained within the Services, are the sole and exclusive property of Oyesense Limited . and as such protected under the copyright laws, trademark laws and other applicable laws. Any use by the User of IPR, without our permission, shall constitute infringement of our proprietary rights, which shall be vigorously prosecuted and pursued to the fullest extent permitted by law. Nothing contained in these Terms of Use, constitutes any permission/transfer/ license for our IPR or any part thereof, in the name of the User. Nothing under these Terms of Use gives you any rights, title, claim, interest or privilege on our intellectual property in your favour.</p>
                </div>
                <div>
                  <h3>LIMITED LICENSE</h3>
                  <p>Subject to the compliance with the Terms herein, Oyesense Limited hereby grants you a personal, limited, non-exclusive, non-transferrable, freely revocable license to use Oyesense Limited Services for the personal and non-commercial viewing and downloading the extracts this Site only. Except for the foregoing limited license, no right, title, or interest shall be transferred to you. You should not (whether directly or using any software program) create a database in electronic or structured manual form by regularly or systematically downloading and storing all or any part of the pages from the Platform. Content on the Site or the Platform and / or Oyesense Limited Services and/or products are being provided to you AS IS for your personal and non-commercial use only and may not be used, copied, reproduced, distributed, transmitted, broadcasted, displayed, sold, licensed or rented or otherwise exploited for any other purpose, without out prior consent.</p>
                </div>
                <div>
                  <h3>PRODUCTS AND PRICING</h3>
                  <p>Products: Oyesense Limited enables the sale of variety of products on its Platform including the products of Oyesense Limited (formerly known as Oyesense OPC Private Limited.) with requisite permission(s) and authorization(s). Each product on the Platform is sold subject to its product description. While Oyesense Limited strives to provide accurate descriptions, pricing details and other information about the products appearing on the Platform, it is likely that certain typographical or other errors may appear on the Platform which may not always reflect the inaccurate position while you are placing the order. In such an event, Oyesense Limited may, at its discretion, contact you for instructions or cancel your order and notify you of such cancellations.</p>
                  <p>Pricing: The pricing information relating to the product shall be disclosed to you at the time of your purchase. Oyesense Limited does not guarantee that the price will be the lowest in the city, region, or geography. Prices and availability are subject to change without notice or any consequential liability to you. Oyesense Limited will have the right to modify the price of the product and contact you for further instructions via the e-mail address provided by you at the time of registration or cancel the order and notify you of such cancellation. If Oyesense Limited cancels the order after the payment has been processed, the entire amount will be remitted to your account from which the payment was made subject to banking protocols.</p>
                </div>
                <div>
                  <h3>INDIVIDUAL REGISTRATION, ACCESS, USE OF SERVICE AND EXCHANGE OF INFORMATION</h3>
                  <p>To access the Services of purchasing products from this Site, you are required to open an Account by completing the registration process (which means providing us correct, complete and accurate details as prompted in the registration form). You agree that each registration to access or use of our Services on the Platform is for a single individual user only. To access the service, you will be asked to enter your individual Username and Password. Therefore, we do not permit any of the following: -</p>
                  <p> - Any other person sharing your Username and Password.
 - Any part of the Site being cached in proxy servers and accessed by individuals who have not registered with the Site as users.
 - Access through a single Username and Password being made available to multiple users on a network.

Upon registration, you shall be responsible for maintaining confidentiality of the password and the account including your personal details. You shall be responsible for ensuring that all such information/ details are always up to date on the Platform. You agree that if you provide any information that is untrue, inaccurate, not updated or incomplete or in case we have reasonable grounds to suspect that such information is untrue, inaccurate, not updated, incomplete, or not in accordance with these Terms of Use or the account has been misused, we shall have the right to indefinitely suspend or terminate or block your access rights immediately without notice and block user access from that IP address.
</p>
<p>You shall be entirely responsible for all activities that occur under/ through your account. By registering, you agree to these Terms of Use in addition to any other specific terms which shall be posted at an appropriate location of the platform. </p>
<p>Therefore, in all such events where you allow others to have access to your account on the Platform for availing services or otherwise, you agree that they will be able to view and access your account information and you shall be solely liable and responsible for all the activities undertaken under your account and any consequences therefrom, which may occur as result of another party using your account. You also agree to immediately notify us of any unauthorized use of your account or any other breach of security and ensure that you exit from your account at the end of each session by signing off. The Company shall not be responsible for any unauthorized access or alteration of your account, transmissions of data, or any material or data sent or received or not sent or not received through the Site. You further agree that we shall not be liable for any loss or damage arising from your failure to comply with this Cause. You may be held liable for losses incurred by the Company or any other user of or visitor to the Website due to the authorized or unauthorized use of your account. Your access to the website may also be occasionally suspended or restricted to allow for repairs, maintenance, or the introduction of new facilities or services. We will attempt to limit the frequency and duration of any such suspension or restriction. However, we will not be liable, if for any reason, the Site is unavailable at any time or for any period.</p>
                </div>
                <div>
                  <h3>HACKING</h3>
                  <p>You acknowledge that we are taking all reasonable precautions to ensure that all your personal information/ data stored on our platform is secure. However, you agree and accept that in case, inspite of all these precautions on our part, if there is any attack in the form of hacking, distribution denial of service attack (DDOS), etc., which results in your personal data on our platform being compromised, we shall not be liable to you for any such loss or damage caused by any technologically harmful material such as viruses or a DDOS or otherwise. In all such cases, you agree to indemnify us from all losses or damage caused to you resulting from such attacks.</p>
                  <p>We shall not be held liable for any loss or damage that may affect your computer, computer equipment, programmes, or any other material as a result of downloading material from our website or our platform, or any of the sites we link to or link to us.</p>
                </div>
                <div>
                  <h3>SHIPPING AND DELIVERY</h3>
                  <p>We endeavor to deliver our orders across India, covering most postal pin codes via our courier partners. The processing and dispatching an Order usually take up to 48 hours from such time the Order is first placed. The date of delivery shall depend on your Pin/Zip code. Orders once confirmed cannot be edited. However, we may accept requests for address change within 12 hours of placing the order, provided such requests are made via email at care@vedantfashions.com.</p>
                  <p>In case you order products from our Platform for delivery outside India, additional import duties and taxes may be imposed (including separate import duties and taxes levied after the delivery reaches the specified destination). You will be responsible for payment of any such import duties and taxes, as applicable. We agree that we do not have any control over these charges and cannot predict their amount. For further details relating to shipping and delivery including duties and taxes for international shipping, please refer to our Shipping and Delivery Policy. </p>
                </div>
                <div>
                  <h3>DISCLAIMER OF WARRANTIES</h3>
                  <p>All the contents of the Site are only for general information or use. You understand and agree that your use of the Services is at your sole risk and that the services are provided "as is" and "as available". In particular, Oyesense Limited, its subsidiaries and affiliates, and its licensors or suppliers do not make any representations or warranties of any kind, express or implied, including without limitation that your use of the Services will meet your requirements; or will be uninterrupted, secure, timely or free from error; or any information obtained by you will be complete, reliable, legal or accurate.</p>
                  <p>We reserve the right to disclosure of any personal information about you and your use of the Site including its contents, without your prior permission if we have a good faith belief that such action is necessary to:

conform to legal requirements or comply with legal process.
protect and defend our rights or property and those of our affiliated companies.
enforce the terms of use; or
act to protect the interest of our members or others.
</p>
<p>Our performance of these Terms is subject to existing laws and legal process, and nothing contained in these Terms is in derogation of our rights to comply with governmental, court and law enforcement requests or requirement relating to your use of the Site or services or information provided to or gathered by us with respect to such use
</p>
                </div>
                <div>
                  <h3>THIRD PARTY CONTENT</h3>
                  <p>Certain links on the Site, including our other online platforms, lead to resources located on servers maintained by third parties over whom we have no control or connection with business or otherwise. You agree and understand that by visiting such external websites you are out of the Site and are no longer using the Services. We have not reviewed these external websites and are not responsible for their content or availability. You acknowledge that we are providing these links only as a matter of convenience. If You decide to access any of the third-party websites linked to the Services, you do so entirely at Your own risk. Please therefore verify the veracity of all information on your own before undertaking reliance and acting thereupon. We, therefore, neither endorse nor offer any judgment or warranty nor accept any responsibility or liability for any damage, loss, or harm (direct or consequential) or any violation of local or international laws that may be incurred by your visit and/or transaction(s) on these websites. Any such loss or damage caused to you by accessing these linked external websites shall be entirely at your own responsibility. </p>
                </div>
                <div>
                  <h3>PRIVACY POLICY</h3>
                  <p>Your use of the platform is also subject to our Privacy Policy as may be amended from time to time. By accepting the provisions of the Terms of Use, expressed or implied, you also accept the provisions of the Privacy Policy. The Privacy Policy is regarding personal information provided by you. Therefore, please also review the Privacy Policy carefully prior to using our platform.  Privacy policy is located at Privacy Policy. If there is any conflict between the provisions within these Terms of Use and the provisions within the Privacy Policy, the provisions of the Privacy Policy will apply. Additionally, by using the Services you acknowledge and agree that, while we are taking all reasonable precautions to secure all your personal information/ data stored on our platform, however, internet transmissions are never completely private or secure. You understand that, despite out safety precautions, if there is any attack in the form of hacking, distribution denial of service attack (DDOS), etc., which results in loss or compromise of your personal data on our platform, we shall not be liable to you for any such loss or damage, caused by any technologically harmful material such as viruses or a DDOS or otherwise.</p>
                </div>
                <div>
                  <h3>COMMUNICATION</h3>
                  <p>When You use the Services or send emails or other data, information or communication to Us, You agree and understand that You are communicating with Us through electronic records and You consent to receive communications via electronic records from us. We may send information and offers of products and services to you periodically and as and when required. We may communicate with You by email or by such other mode of communication, electronic or otherwise.</p>
                </div>
                <div>
                  <h3>NON- ASSIGNMENT</h3>
                  <p>You shall not assign or transfer or purport to assign or transfer, either temporarily or permanently, your account details or this contract between you and us to any other person. You agree to accept that your account on the platform is non-transferable.</p>
                </div>
                <div>
                  <h3>NATIONAL AND INTERNATIONAL EXCHANGE & RETURN POLICY</h3>
                  <p>Exchange, return and/ or cancellation of your order paced on the Platform can be made, subject to certain conditions laid down under the respective National and International Return & Exchange Policy, located at Return, Exchange & Refund Policy. You are therefore requested to review the National Exchange & Return Policy and International Exchange & Return Policy, as applicable, based on your location of delivery.</p>
                </div>
                <div>
                  <h3>INDEMNIFICATION</h3>
                  <p>To the fullest extent allowed by the law,  you agree to defend, indemnify and hold Oyesense Limited and our affiliates harmless from and against any or all claims, liabilities, damages (actual and consequential), , losses and expenses (including attorneys’ fees),  arising from or related to: (i) hacking, any third party claims or your access to or use of the Services or the Platform (including third party use from your account ); (ii) violation of these Terms, privacy policy and other terms and conditions; and (iii) violation of any law, rules and regulations or rights (including infringement of intellectual property rights) of a third party.</p>
                </div>
                <div>
                  <h3>FORCE MAJEURE</h3>
                  <p>We shall not have any liability for any interruption or delay in access to the Platform, due to the Force Majeure Event. Our obligations in respect of the Services on the Platform will be postponed until circumstances beyond our reasonable control cease to continue. The Force Majeure events includes those events resulting from natural hazards, disasters, labour unrest, embargo, strike riots, lockouts, epidemic, pandemic, fire, flood, earthquakes, typhoons, war, acts of foreign enemies or other perils or by any circumstances beyond the reasonable control of the parties, including but not limited to acts of god, war or national emergency, government or court or statutory action, civil commotion or industrial dispute.</p>
                </div>
                <div>
                  <h3>WAIVER AND SEVERABILITY</h3>
                  <p>You acknowledge that our failure to exercise or enforce any right or provision of the terms of use or our failure to demand resolutions to which we are entitled to shall not constitute a waiver of such right or provision or omission of your responsibility to comply with your obligations outlined under these Terms including the documents referred therein. No waiver by us, of any of these Terms, would take effect unless expressly stated in writing to you. If any provision of these Terms is found, by a court of competent jurisdiction, to be invalid, then that provision will be removed to the minimum extent necessary, from the Terms. The remaining Terms will continue to be in full force and effect and enforceable.</p>
                </div>
                <div>
                  <h3>TERMINATION</h3>
                  <p>You acknowledge and accept that the Terms of Use shall continue to apply unless terminated by us. We may, at our discretion, suspend or terminate your use of the platform or any service if we have reasonable grounds to suspect or believe that you have breached, violated, abused, or unethically manipulated or exploited any Terms hereunder (including the documents referred to in there) or have otherwise acted unethically.</p>
                </div>
                <div>
                  <h3>CUSTOMER SUPPORT</h3>
                  <p>You can contact our Customer Care Representative via support@oyesense.com which provides online resolution to your queries / complaints across channels including chat. You can also request for a call back for your concern/ query and we would be happy to reach out. If you wish to speak to our Customer Care Representative directly, please feel free to contact us at +916362723848. Our Customer Care Representative is authorised to resolve complaints and make best effort to bring fair and reasonable customer service decisions. We will address your concern/query within 4 (Four) days from receipt of your complaint by email.</p>
                </div>
                <div>
                  <h3>STATUTE OF LIMITATIONS</h3>
                  <p>You agree and accept that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the services or the terms of use must be filed within one (1) year after such cause of action arose or be forever barred.</p>
                </div>
                <div>
                  <h3>DISPUTE RESOLUTION</h3>
                  <p>We resolve to provide you a cost effective and neutral means of resolution in case of disputes arising out of or in relation to these Terms of Use, including any question regarding its existence, validity, or termination, within a period of 15 days (“Consultation Period”) after the dispute has been reported by you/ or brought to our attention. In case, the Parties are unable to resolve the disputes within 15 days from the Consultation Period, the same shall be settled by arbitration governed by the provisions of Arbitration and Conciliation Act, 1996. The Company shall appoint one (1) Arbitrator to conduct the arbitral proceeding on behalf of both the Parties. The arbitrator shall then conduct the arbitration proceedings in consonance with the provisions of the Indian Arbitration and Conciliation Act, 1996 as may be amended from time to time. The decision of the Arbitrator so appointed shall be binding upon the Parties and the proceedings shall be as decided by the Arbitrator.

The venue/seat of Arbitration shall be Kolkata and the language of arbitration shall be English. A dispute shall be deemed to have arisen when either Party notifies the other Party in writing to that effect.
</p>
                </div>
                <div>
                  <h3>GOVERNING LAW</h3>
                  <p>The Terms of Use by ways of this Agreement and performance hereunder shall be governed by and will be construed in all respects in accordance with the Laws of India. Any action, suit, or other legal proceeding, which is commenced to resolve any matter arising under or relating to these Services, shall be subject to the exclusive jurisdiction of the courts at Maharashtra, India. The use of this Site, Platform or Services thereon is unauthorized in any jurisdiction that does not give effect to all provisions of these terms and conditions including, without limitation, this Clause</p>
                </div>
                <div>
                  <h3>ENTIRE AGREEMENT</h3>
                  <p>These Terms constitute the entire agreement between the parties with respect to the subject matter hereof and supersedes and replaces all prior or contemporaneous understandings or agreements, written or oral, regarding such subject matter.</p>
                </div>
              </div>
            </div>
          </div>
        </section> 
        <FooterSection />
      </div>
    );
  }
}
