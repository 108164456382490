import React, { useState, useEffect } from "react";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import TableEntry from "../skeletons/TableEntry";

const Table = ({ columns, data, search = false,loading=false }) => {
  const [sortConfig, setSortConfig] = useState({
    field: null,
    direction: null,
  });

  const handleSort = (field) => {
    let direction = "ascending";
    if (sortConfig.field === field && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ field, direction });
  };

  const sortedData = React.useMemo(() => {
    if (sortConfig.field) {
      return [...data].sort((a, b) => {
        if (a[sortConfig.field] < b[sortConfig.field]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.field] > b[sortConfig.field]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return data;
  }, [data, sortConfig]);

  return (
    <div className="overflow-x-auto w-full rounded-md">
      <table className="min-w-full bg-white border border-gray-200 rounded-t-md">
        <thead className="rounded-t-md !h-[59px] bg-[#EEEEEE]">
          <tr className="w-full bg-transparent">
            {columns.map((col) => (
              <th
                key={col.field}
                className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold text-table-header cursor-pointer overflow-hidden"
                style={{ width: col.width ? col.width : 100 }}
                onClick={() => col.enableSorting && handleSort(col.field)}
              >
                <div className="flex items-center">
                  {col.headerName}
                  {col.enableSorting && (
                    <span className="ml-2">
                      {sortConfig.field === col.field ? (
                        sortConfig.direction === "ascending" ? (
                          <FaSortUp />
                        ) : (
                          <FaSortDown />
                        )
                      ) : (
                        <FaSort />
                      )}
                    </span>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.length > 0 &&
            sortedData.map((row, rowIndex) => (
              <tr key={row.id} className="hover:bg-[#92c6ff1a]">
                {columns.map((col) => (
                  <td
                    key={col.field}
                    style={{ width: col.width ? col.width : 100 }}
                    className="py-2 px-4 border-b border-gray-200 text-secondary-description !h-[58px] !font-robot !font-[400] !text-[14px]"
                  >
                    {col.renderCell ? col.renderCell(row) : row[col.field]}
                  </td>
                ))}
              </tr>
            ))}
          {!search && loading && sortedData.length === 0 && (
            <tr className="w-full">
              <td colSpan={columns.length} className="p-4">
                <TableEntry />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {(search || !loading) && !sortedData.length && (
        <p className="flex items-center justify-center p-4 w-full opacity-50 ">
          No data found for the given search query. Please try different
          keywords.
        </p>
      )}
    </div>
  );
};

export default Table;