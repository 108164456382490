import React, { useEffect, useState } from "react";

const MultiSelectDropdown = ({ title, items, id, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState(items);

  useEffect(() => {
    if (items.length !== selectedItems.length) {
      setSelectedItems(items);
    }
  }, [items]);

  useEffect(() => {
    const checkedItems = selectedItems.filter(
      (item) => item.checked && item.type !== "all"
    );
    onChange(checkedItems);
  }, [selectedItems, onChange]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleCheckboxChange = (index, type) => {
    const newItems = [...selectedItems];

    if (type === "all") {
      const isChecked = newItems[index].checked;
      newItems.forEach((item) => (item.checked = !isChecked));
    } else {
      newItems[index].checked = !newItems[index].checked;
    }

    setSelectedItems(newItems);
  };

  const getSelectedText = () => {
    const checkedItems = selectedItems.filter(
      (item) => item.checked && item.type !== "all"
    );

    if (checkedItems.length >= 2) {
      return `${checkedItems.length} ${id} selected`;
    }
    return checkedItems.map((item) => item.label).join(", ") || `${title}`;
  };

  return (
    <div className="w-full mb-4 relative bg-white">
      <button
        className=" p-2 pl-3 border border-gray-300 rounded-sm text-left overflow-hidden !h-[45px] !font-[300] !font-roboto !text-[12px] !text-[#64748B] !w-80"
        onClick={toggleDropdown}
        type="button"
      >
        {getSelectedText()}
      </button>
      {isOpen && (
        <div className="border border-t-0 border-gray-300 p-x-2 overflow-auto max-h-36 absolute top-[45px] left-0 w-80 bg-white" style={{zIndex:99999}}>
          <label className="!flex !flex-row !items-center py-2 hover:bg-[#92c6ff1a] !mb-0 !px-2 gap-x-2">
            <input
              type="checkbox"
              checked={selectedItems.every((item) => item.checked)}
              onChange={() => handleCheckboxChange(0, "all")}
              className="h-4 w-4"
            />
            <p className="!text-[14px] !font-[500]">Select all <span className="capitalize">{id}</span></p>
          </label>
          {selectedItems.map((item, index) => {
            return (
              item.type === "val" && (
                <label
                  key={index}
                  className="!flex !flex-row !items-center border-t py-2 hover:bg-[#92c6ff1a] !mb-0 !px-2 gap-x-2"
                >
                  <input
                    type="checkbox"
                    checked={item.checked}
                    onChange={() => handleCheckboxChange(index, item.type)}
                    className="h-4 w-4"
                  />
                  <span>{item.label}</span>
                </label>
              )
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;