import React, { Component } from "react";
import AUX from "../hoc/Aux_";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { BASE_URL, EMAIL_REGEX, PASS_REGEX , recaptha_siteKey} from "../_helpers/constants";
import { history } from "../_helpers/history";
import { NotificationContainer } from "react-notifications";
import PendingVerification from "../_components/PendingVerification";
import AuthSidebar from "../_components/AuthSidebar";
import { isValidEmail, validatePassword } from "../_helpers/utils";

export default class Pages_register_2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      username: "",
      organization: "",
      password: "",
      domain: "",
      country: "",
      mobileNumber: "",
      submitted: false,
      errorText: "",
      successText: "",
      captcha: "",
      otpVerificationPending: false,
      otp: "",
      isHiddenPass: true,
      errors: {
        email: "",
        password: "",
        name: "",
        teamName: "",
      },
      tncAgreed: false,
      generatedLink: "/",
    };
  }

  onRegister = () => {
    const { email, organization, password, username } = this.state;
    console.log(organization);
    this.setState({ submitted: true });
    var regex = /^[a-zA-Z]+[a-zA-Z\d\-]*$/;
    if (email.length === 0) {
      this.setState({
        errors: {
          ...this.state.errors,
          email: "Please enter email address",
        },
      });
    } else if (username.length === 0) {
      this.setState({
        errors: {
          ...this.state.errors,
          name: "Please enter name.",
        },
      });
    } else if (username.length > 30) {
      this.setState({
        errors: {
          ...this.state.errors,
          name: "Name can't have more than 30 characters.",
        },
      });
    } else if (password.length === 0) {
      this.setState({
        errors: {
          ...this.state.errors,
          password: "Please enter password",
        },
      });
    } else if (password.length > 30) {
      this.setState({
        errors: {
          ...this.state.errors,
          password: "Password can't have more than 30 characters.",
        },
      });
    } else if (organization.length === 0) {
      this.setState({
        errors: {
          ...this.state.errors,
          teamName: "Please enter domain name",
        },
      });
    } else if (organization.length > 30) {
      this.setState({
        errors: {
          ...this.state.errors,
          teamName: "Domain can't have more than 30 characters.",
        },
      });
    } else if (email.length > 50) {
      this.setState({
        errors: {
          ...this.state.errors,
          email: "Email cant be longer than 50 characters",
        },
      });
    } else if (!PASS_REGEX.test(password)) {
      this.setState({
        errors: {
          ...this.state.errors,
          email:
            "Password must contain Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.",
        },
      });
    } else {
      const data = JSON.stringify({
        email: email,
        firstname: username,
        organization: organization,
        password: password,
        domain: organization,
        // country: country,
        // mobile: Number(mobileNumber)
      });

      var myHeaders = new Headers();
      myHeaders.append("X-L10N-Locale", "en");
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: data,
        redirect: "follow",
      };

      fetch(`${BASE_URL}auth/sign-up`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log("res", result);
          if (
            result.error ===
            "Invalid email domain. Personal emails are not allowed"
          ) {
            this.setState({
              errors: {
                email: result.error,
              },
            });
          }

          if (!result.status) {
            if (result.duplicateAccount) {
              this.setState({
                errors: {
                  ...this.state.errors,
                  email: result.message,
                },
              });
            }
            return;
          }
          if (result.code === 401) {
            this.setState({ loading: false });
            localStorage.clear();
            history.push("/login?redirect=1");
          }
          if (result.status) {
            // this.setState({ successText: 'User Registered Successfully' })
            // Promises.setUserId(result.id)
            // Promises.setToken(result.token)
            // setTimeout(() => {
            //   history.push("/welcome");
            // }, 1000);
            this.setState({ otpVerificationPending: true });
          } else {
            if (result.error) {
              this.setState({ errorText: result.error });
            }
          }
        })
        .catch((error) => {
          console.log("error here", error);
          // if (
          //   error.error ===
          //   "Invalid email domain. Personal emails are not allowed"
          // ) {

          //   this.setState({
          //     errors: {
          //       email: error.error,
          //     },
          //   });
          // }
        });
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
  };

  removeSpaces = (string) => {
    let stringNew = string.split(" ").join("");
    this.setState({ organization: stringNew });
    let re = /[^a-zA-Z0-9\-]/;
    if (stringNew.length == 0) {
      this.setState({ errorText: "" });
    } else if (
      re.test(stringNew) ||
      (stringNew.length < 3 && stringNew.length >= 1)
    ) {
      this.setState({ errorText: "Invalid Team Name" });
    } else {
      this.setState({ errorText: "" });
      const data = JSON.stringify({
        domain: stringNew,
        // country: country,
        // mobile: Number(mobileNumber)
      });

      var myHeaders = new Headers();
      myHeaders.append("X-L10N-Locale", "en");
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: data,
        redirect: "follow",
      };

      fetch(`${BASE_URL}auth/checkDomain`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 401) {
            this.setState({ loading: false });
            localStorage.clear();
            history.push("/login?redirect=1");
          }
          if (result.code) {
            if (result.code != 200) {
              this.setState({
                errors: {
                  ...this.state.errors,
                  teamName: "That team name is taken . Try another",
                },
              });
            }
          }
        })
        .catch((error) =>
          this.setState({
            errors: {
              ...this.state.errors,
              teamName: "That team name is taken . Try another",
            },
          })
        );
    }
  };

  validateEmail = (email) => {
    return EMAIL_REGEX.test(String(email).toLowerCase());
  };
  onChangeCaptcha = (val) => {
    this.setState({ captcha: val });
  };

  // completeSignup=()=>{
  //   const data = JSON.stringify({
  //     email: this.state.email,
  //     otp:this.state.otp,

  //   });

  //   var myHeaders = new Headers();
  //   myHeaders.append("X-L10N-Locale", "en");
  //   myHeaders.append("Content-Type", "application/json");

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: data,
  //     redirect: "follow",
  //   };

  //   fetch(`${BASE_URL}auth/verify-otp`, requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       console.log("res", result);
  //       if (result.code === 401) {
  //         this.setState({ loading: false });
  //         localStorage.clear();
  //         history.push("/login?redirect=1");
  //       }
  //       if (result.status) {
  //         // this.setState({ successText: 'User Registered Successfully' })
  //         // Promises.setUserId(result.id)
  //         // Promises.setToken(result.token)
  //         // setTimeout(() => {
  //         //   history.push("/welcome");
  //         // }, 1000);
  //         // this.setState({ otpVerificationPending: false });
  //         NotificationManager.success(
  //           "You have been verified successfully"
  //         );
  //         NotificationManager.success(
  //           "We are redirecting to your workspace please login your workspace."
  //         );
  //         setTimeout(() => {
  //           // window.location = `https://${result.domain}.oyetest.com/login`;
  //           window.location = `http://${result.domain}.localhost:3001/login`;
  //         }, 3000);
  //       } else {
  //         if (result.error) {
  //           this.setState({ errorText: result.error });
  //         }
  //       }
  //     })
  //     .catch((error) => this.setState({ errorText: error.error }));
  // }

  // onResend=()=>{
  //   const data = JSON.stringify({
  //     email: this.state.email,
  //   });

  //   var myHeaders = new Headers();
  //   myHeaders.append("X-L10N-Locale", "en");
  //   myHeaders.append("Content-Type", "application/json");

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: data,
  //     redirect: "follow",
  //   };

  //   fetch(`${BASE_URL}auth/resend-otp`, requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       console.log("res", result);
  //       if (result.code === 401) {
  //         this.setState({ loading: false });
  //         localStorage.clear();
  //         history.push("/login?redirect=1");
  //       }
  //       if (result.status) {
  //         // this.setState({ successText: 'User Registered Successfully' })
  //         // Promises.setUserId(result.id)
  //         // Promises.setToken(result.token)
  //         // setTimeout(() => {
  //         //   history.push("/welcome");
  //         // }, 1000);
  //         // this.setState({ otpVerificationPending: false });
  //         NotificationManager.success(
  //           "OTP has been sent successfully"
  //         );
  //       } else {
  //         NotificationManager.error("Error sending otp");
  //         if (result.error) {
  //           this.setState({ errorText: result.error });
  //         }
  //       }
  //     })
  //     .catch((error) => this.setState({ errorText: error.error }));
  // }

  render() {
    const { successText, errorText, username, email, password, organization } =
      this.state;

    return (
      <AUX>
        <div className="custom-container signup-page !flex !items-center !justify-center  !pl-0 lg:!pl-[78px] !py-0 ">
          <AuthSidebar />

          {!this.state.otpVerificationPending && (
            <div className="custom-container-2 !py-0">
              <div className="form-custom-container flex  flex-col  items-start  justify-center  pl-12 overflow-hidden">
                <h2
                  className=" font-raleway !pt-4"
                  style={{ fontWeight: 700, fontSize: 28 }}
                >
                  {" "}
                  Registration{" "}
                </h2>
                <p
                  className="heading-description subheading"
                  // style={{ fontWeight: 400, fontSize: 14 }}
                >
                  {" "}
                  Already have an account? <Link to="/login">Login</Link>{" "}
                </p>
                <form>
                  <div className="form-field">
                    <label
                      htmlFor="username"
                      className="form-field-label input-label"
                    >
                      Your Full Name
                    </label>
                    <input
                      type="text"
                      id="username"
                      value={username}
                      onChange={(e) => {
                        this.setState({ username: e.target.value });
                        if (e.target.value.length >= 3)
                          this.setState({
                            errors: {
                              ...this.state.errors,
                              name: "",
                            },
                          });
                      }}
                    />
                    {this.state.errors?.name && (
                      <p
                        className="text-sm text-red-500 h-3 mb-3 mt-2"
                        // style={{ opacity: this.state.domain ? 1 : 0 }}
                      >
                        {this.state.errors?.name}
                      </p>
                    )}
                  </div>
                  <div className="form-field">
                    <label
                      htmlFor="useremail"
                      className="form-field-label input-label"
                    >
                      Email ID
                    </label>
                    <input
                      type="email"
                      id="useremail"
                      value={email}
                      onChange={(e) => {
                        this.setState({ email: e.target.value });
                        this.setState({
                          errors: {
                            ...this.state.errors,
                            email: isValidEmail(e.target.value).message,
                          },
                        });
                      }}
                    />
                    {this.state.errors?.email && (
                      <p className="text-sm text-red-500 h-3 mt-2">
                        {this.state.errors?.email}
                      </p>
                    )}
                  </div>

                  <div className="form-field">
                    <div className="flex flex-row justify-between items-center">
                      <label
                        htmlFor="userpassword"
                        className="form-field-label input-label"
                      >
                        Password
                      </label>
                      <div
                        className="flex cursor-pointer gap-x-1 items-center"
                        onClick={() => {
                          this.setState({
                            isHiddenPass: !this.state.isHiddenPass,
                          });
                        }}
                      >
                        {!this.state.isHiddenPass ? (
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.5687 4.06778L15.9554 3.45449C15.7821 3.28117 15.4621 3.30784 15.2621 3.5478L13.1286 5.66777C12.1686 5.25448 11.1153 5.05448 10.0086 5.05448C6.71519 5.06776 3.86204 6.98774 2.48861 9.74791C2.40858 9.92124 2.40858 10.1345 2.48861 10.2812C3.12855 11.5879 4.08861 12.6679 5.28861 13.4812L3.54195 15.2545C3.34195 15.4545 3.31528 15.7745 3.44865 15.9478L4.06193 16.5611C4.23526 16.7344 4.55525 16.7077 4.75525 16.4678L16.4618 4.76121C16.7152 4.56132 16.7419 4.24135 16.5685 4.06801L16.5687 4.06778ZM10.7153 8.09435C10.4886 8.041 10.2486 7.97437 10.022 7.97437C8.88859 7.97437 7.98202 8.88106 7.98202 10.0143C7.98202 10.241 8.03536 10.4809 8.10199 10.7076L7.20856 11.5877C6.94193 11.121 6.79528 10.6009 6.79528 10.0143C6.79528 8.24103 8.22196 6.81435 9.99528 6.81435C10.582 6.81435 11.102 6.961 11.5686 7.22763L10.7153 8.09435Z"
                              fill="#666666"
                              fill-opacity="0.8"
                            />
                            <path
                              d="M17.5287 9.7479C17.0621 8.81453 16.4487 7.97458 15.6888 7.29456L13.2087 9.7479V10.0145C13.2087 11.7878 11.782 13.2145 10.0087 13.2145H9.74208L8.16876 14.7878C8.75548 14.9078 9.36877 14.9878 9.96877 14.9878C13.2622 14.9878 16.1154 13.0679 17.4888 10.2944C17.6088 10.1077 17.6088 9.9211 17.5287 9.74776L17.5287 9.7479Z"
                              fill="#666666"
                              fill-opacity="0.8"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="14"
                            height="9"
                            viewBox="0 0 14 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.868077 5.80351C1.46578 2.92461 3.96439 0.879054 6.775 0.879054C9.58473 0.879054 12.0833 2.92461 12.6819 5.80351C12.7056 5.91775 12.7736 6.01786 12.8709 6.08183C12.9682 6.14579 13.0869 6.16837 13.2008 6.14459C13.3146 6.12081 13.4145 6.05262 13.4782 5.95502C13.542 5.85743 13.5645 5.73842 13.5408 5.62419C12.8598 2.34971 10.0115 0 6.775 0C3.53846 0 0.690168 2.34971 0.00920718 5.62419C-0.0145012 5.73842 0.00800534 5.85743 0.0717757 5.95502C0.135546 6.05262 0.235356 6.12081 0.34925 6.14459C0.463143 6.16837 0.58179 6.14579 0.679089 6.08183C0.776388 6.01786 0.844369 5.91775 0.868077 5.80351ZM6.76624 2.63716C7.57976 2.63716 8.35996 2.96131 8.93521 3.5383C9.51046 4.11529 9.83363 4.89786 9.83363 5.71385C9.83363 6.52984 9.51046 7.31241 8.93521 7.8894C8.35996 8.46639 7.57976 8.79054 6.76624 8.79054C5.95271 8.79054 5.17251 8.46639 4.59726 7.8894C4.02201 7.31241 3.69884 6.52984 3.69884 5.71385C3.69884 4.89786 4.02201 4.11529 4.59726 3.5383C5.17251 2.96131 5.95271 2.63716 6.76624 2.63716Z"
                              fill="#6A707C"
                            />
                          </svg>
                        )}
                        <p
                          style={{
                            fontSize: "12px",
                            fontWeight: 400,
                            lineHeight: "19.5px",
                            textAlign: "right",
                            opacity: 0.7,
                          }}
                        >
                          {this.state.isHiddenPass ? "Show" : "Hide"}
                        </p>
                      </div>
                    </div>
                    <input
                      id="userpassword"
                      value={password}
                      type={this.state.isHiddenPass ? "password" : "text"}
                      onChange={(e) => {
                        this.setState({ password: e.target.value });
                        this.setState({
                          errors: {
                            ...this.state.errors,
                            password: validatePassword(e.target.value).msg,
                          },
                        });
                      }}
                    />
                    {/* <p
                      className="text-sm text-red-500 h-3 mb-3"
                      // style={{ opacity: this.state.domain ? 1 : 0 }}
                    >
                      {this.state.errors?.password}
                    </p> */}
                    <div className="">
                      {!this.state.errors?.password && !this.state.password && (
                        <div style={{ display: "flex" }}>
                          <svg
                            width="28"
                            height="28"
                            viewBox="0 0 13 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <ellipse
                              cx="6.07325"
                              cy="6"
                              rx="6.07325"
                              ry="6"
                              fill="#316BFF"
                            />
                            <path
                              d="M6.88307 3.538C6.88307 3.748 6.80807 3.928 6.65807 4.078C6.50807 4.228 6.32807 4.303 6.11807 4.303C5.90807 4.303 5.72807 4.228 5.57807 4.078C5.42807 3.928 5.35307 3.748 5.35307 3.538C5.35307 3.328 5.42807 3.148 5.57807 2.998C5.72807 2.848 5.90807 2.773 6.11807 2.773C6.32807 2.773 6.50807 2.848 6.65807 2.998C6.80807 3.148 6.88307 3.328 6.88307 3.538ZM5.47907 10.126V4.852L6.76607 5.005L6.58607 10L5.47907 10.126Z"
                              fill="white"
                            />
                          </svg>
                          <p
                            className="text-sm text-[#316BFF] ml-1"
                            style={{ fontSize: 12, fontWeight: 400 }}
                          >
                            Password must be 8+ chars, with one uppercase
                            letter, one number and one special character.
                          </p>
                        </div>
                      )}
                      {this.state.errors?.password && (
                        <p className="text-sm text-red-500 mt-2">
                          {this.state.errors?.password}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form-field mb-0">
                    <label
                      htmlFor="organization"
                      className="form-field-label input-label"
                    >
                      {" "}
                      Team Name{" "}
                      <span className="btn-color !font-[400]">
                        {organization
                          ? `(hosted at https://${organization}.oyetest.com)`
                          : ""}{" "}
                      </span>
                    </label>

                    <input
                      type="text"
                      id="organization"
                      value={organization}
                      onChange={(e) => {
                        this.removeSpaces(e.target.value);
                        this.setState({ errorText: "" });
                        if (e.target.value.length < 3) {
                          this.setState({
                            errors: {
                              ...this.state.errors,
                              teamName: "Team name is required",
                            },
                          });
                        } else {
                          this.setState({
                            errors: {
                              ...this.state.errors,
                              teamName: "",
                            },
                          });
                        }
                      }}
                    />
                    {this.state.errors?.teamName && (
                      <p
                        className="text-sm text-red-500 h-3 mb-3 mt-2"
                        // style={{ opacity: this.state.domain ? 1 : 0 }}
                      >
                        {this.state.errors?.teamName}
                      </p>
                    )}
                  </div>

                  <div className="flex items-center gap-x-2 my-3">
                    <input
                      className="h-4 w-4 bg-black  accent-black"
                      type="checkbox"
                      onChange={(e) => {
                        this.setState({ tncAgreed: e.target.checked });
                      }}
                    />
                    <p
                      className=" my-2 "
                      style={{ fontSize: 13, fontWeight: 400 }}
                    >
                      By clicking Sign up, you agree to our
                      <Link className="underline" to="/terms">
                        <span className="underline">Terms of use</span>
                      </Link>{" "}
                      and{" "}
                      <Link to="/privacy">
                        <span className="underline">Privacy Policy</span>
                      </Link>
                      .
                    </p>
                  </div>

                  <div className="form-field mb-0 !mt-0">
                    <ReCAPTCHA
                      // style={{ marginTop: "20px" }}
                      sitekey={recaptha_siteKey}
                      onChange={this.onChangeCaptcha}
                    />
                  </div>

                  {successText.length > 0 && (
                    <div className="alert alert-success mb-0" role="alert">
                      {successText}
                    </div>
                  )}
                  <div className="login-btn-div reg-btn-div">
                    <button
                      type="button"
                      className="btn btn-primary primary_btn"
                      onClick={this.onRegister}
                      disabled={
                        !this.state.captcha ||
                        !this.state.tncAgreed ||
                        this.state.errors?.email ||
                        this.state.errors?.name ||
                        this.state.errors?.password ||
                        this.state.errors?.teamName
                      }
                    >
                      Sign up
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
          {this.state.otpVerificationPending && this.state.email && (
            <div className="flex-grow flex items-center justify-center">
              <PendingVerification email={this.state.email} />
            </div>
          )}
          <NotificationContainer />
        </div>
      </AUX>
    );
  }
}
