import React, { Component } from 'react';
import AUX from '../hoc/Aux_';

export default class Pages_Expire extends Component {
	constructor(props) {
		super(props);

		this.state = {
		};

	}

	componentDidMount = () => {
	};

	render() {
		return (
			<AUX>
				{/* <div className="accountbg" style={{ background: "url('assets/images/bg-2.jpg')", backgroundSize: "cover" }}></div>
				<div className="wrapper-page account-page-full">
					<div className="card">
						<div className="card-body">

							<h3 className="text-center m-0">
								<span style={{
									fontFamily: 'Bungee Outline',
									fontSize: 40,
									letterSpacing: 3,
									fontWeight: 700
								}}>oyetest</span>
							</h3>

							<div className="p-3">
								<h4 className="font-18 m-b-5 text-center">Welcome to oyetest !</h4>
								<p className="text-muted text-center">Please check your email for further information</p>
							</div>
							<div className="m-t-40 text-center">
								<p className="">Don't have an account ? <Link to="/register" className="font-500 font-14 font-secondary"> Signup Now </Link> </p>
							</div>
							<NotificationContainer />
						</div>
					</div>

				</div> */}
				 <div className="custom-container success-page">
      <div className="success-page-wrapper">
        <div className="success-page-content">
          <div className="image-wrapper">
            <img src={process.env.PUBLIC_URL+"/assets/images/success-page.png"} alt="Oyetest"/>
          </div>
          <h2>You’re Trail Period is Expired!</h2>
          <div className="description">
                To access your project, Make sure to update your subscription. <a href='/price'>Click Here</a> to update.
          </div>
        </div>
      </div>
    </div>
			</AUX>
		);
	}
}
