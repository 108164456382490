import React, { Component } from "react";
import { Link } from "react-router-dom";
import { history } from "../_helpers/history";
import Promises from "../_helpers/utils";
import { BASE_URL, LOGO_FULL_URL,recaptha_siteKey } from "../_helpers/constants";
import Modal from "react-modal";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import ReCAPTCHA from "react-google-recaptcha";
import Footer from "../_components/Footer";

export default class Pages_Support extends Component {
  constructor(props) {
    super(props);

    this.state = {
      createModalVisible: false,
      email: "",
      name: "",
      msg: "",
      email_err: "",
      isSubmit: false,
      captcha: "",
      showDrop: false,
      isPlan: false,
      viewState: 0,
      selectedOption1: null,
      plan_data: [],
      switch1: true,
      showPricingPlanNotification: true,
    };
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
  }

  componentWillMount() {
    Modal.setAppElement("body");
  }
  componentDidMount() {
    this.setState({ loading: true }, () => {
      this.getOrgIntigration();
    });

    const siteUrl = window.location.search;
    const urlParams = new URLSearchParams(siteUrl);
    if (urlParams.has("session_id")) {
      console.log("session_id", urlParams.get("session_id")); // 159
      this.saveSession(urlParams.get("session_id"));
    }
  }

  handler(type = 1) {
    if (type === 1) {
      this.setState({ selectedTab: "test-result" });
    } else {
      this.setState({ selectedTab: "test-case" });
    }
  }
  handlerAdmin() {
    this.setState({ selectedTab: "administrator" });
    this.setState({ viewState: 2 });
  }
  onLogout() {
    localStorage.clear();
  }

  toggleprofile() {
    this.setState((prevState) => ({
      dropdownOpenprofile: !prevState.dropdownOpenprofile,
    }));
  }

  getOrgIntigration = (type = "1m") => {
    const token = Promises.getToken();

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BASE_URL}plan/${type}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          console.log("===================>", result);
          this.setState({
            isPlan: true,
            plan_data: result.data,
          });
        } else {
          this.setState({
            isPlan: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  ///session/save

  saveSession = (sessionId = "") => {
    const token = Promises.getToken();
    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var raw = JSON.stringify({
      session_id: sessionId,
      // description: this.state.description,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: raw,
    };

    fetch(`${BASE_URL}plan/session/save`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          NotificationManager.success("Project Update successfully");
          setTimeout(() => {
            this.props.history.push("/dashboard");
          }, 1000);
        } else {
          NotificationManager.error(
            "Something went wrong with Updating project, Please try again later"
          );
        }
      })
      .catch((error) => {
        // debugger;
        console.log("error", error);
        NotificationManager.error(
          "Something went wrong with Updating project, Please try again later"
        );
      });
  };
  getSessionURL = (id) => {
    const token = Promises.getToken();

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BASE_URL}plan/session/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          console.log("===================>", result);
          if (result.data) {
            window.location.href = result.data;
          }
        } else {
          this.setState({
            isPlan: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  selectedProject = (val, isImport) => {
    console.log(val.name);
    this.setState({
      selectedTab: "test-case",
      titleName: val.name,
      selectedProjectId: val.id,
    });
    this.setState({ importState: isImport });
    this.setState({ viewState: 1 });
    this.props.history.push(`dashboard/${val.name}`);

    if (this.child.current) {
      this.child.current.changeIndex(val.id);
    }
  };

  handleChange = (type) => {
    console.log("============>", type);
    if (type) {
      this.getOrgIntigration("1m");
    } else {
      this.getOrgIntigration("1y");
    }
  };

  handleChangeEmail(e, type) {
    console.log(e.target.value + "==>" + type);
    if (type == "name") {
      this.setState({ name: e.target.value });
    } else if (type == "email") {
      this.setState({ email: e.target.value });
    } else if (type == "msg") {
      this.setState({ msg: e.target.value });
    }
    this.setState({ name_err: "", email_err: "", msg_err: "" });
    const { name, email, msg } = this.state;
    var EmailReg =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (name == "") {
      this.setState({ name_err: "Required Field", isSubmit: false });
    } else if (email == "") {
      this.setState({ email_err: "Required Field", isSubmit: false });
    } else if (msg == "") {
      this.setState({ msg_err: "Required Field", isSubmit: false });
    } else if (EmailReg.test(email)) {
      this.setState({ email_err: "", isSubmit: true });
    } else {
      this.setState({ email_err: "Enter Valid Email", isSubmit: false });
    }
  }

  onChangeCaptcha = (val) => {
    this.setState({ captcha: val });
  };

  sendmail = () => {
    this.setState({ createModalVisible: false });
    console.log("SAVING DATA =============>");
    const { isSubmit, name, email, msg } = this.state;
    if (isSubmit) {
      var myHeaders = new Headers();
      myHeaders.append("X-L10N-Locale", "en");
      myHeaders.append("Content-Type", "application/json");

      var raw = {
        name: name,
        email: email,
        msg: msg,
      };

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(raw),
        redirect: "follow",
      };

      fetch(`${BASE_URL}auth/send-mail`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 401) {
            this.setState({ loading: false });
            localStorage.clear();
            history.push("/login?redirect=1");
          }
          NotificationManager.info(result.msg);
        })
        .catch((error) => {
          console.log("error send-email", error);
        });
      this.setState({ isSubmit: false, name: "", email: "", msg: "" });
    }
  };

  onTestSuitCreate = () => {
    const { name, testCasesData, createModalVisible } = this.state;
    console.log(createModalVisible);
    return (
      <Modal
        overlayClassName="OverlayPricingModal"
        className="create-modal pricing-modal test-cases-modal"
        // onRequestClose={() => this.setState({ createModalVisible: true })}
        isOpen={createModalVisible}
      >
        <div className="modal-header clearfix">
          <h1>Ask for a better price!</h1>
          {/* <i
            className="mdi mdi-close"
            onClick={() => this.setState({ createModalVisible: false })}
          ></i> */}
        </div>

        <label id="label" className="mt-30" htmlFor="name">
          Your Full Name
        </label>
        <input
          type="text"
          className="form-control"
          name="title"
          value={this.state.name}
          onChange={(e) => this.handleChangeEmail(e, "name")}
        />
        <span id="err" style={{ color: "red" }}>
          {this.state.name_err}
        </span>
        <label id="label" className="mt-30" htmlFor="name">
          Email
        </label>
        <input
          type="text"
          className="form-control"
          name="email"
          value={this.state.email}
          onChange={(e) => this.handleChangeEmail(e, "email")}
          id="email"
        />

        <span id="err" style={{ color: "red" }}>
          {this.state.email_err}
        </span>
        <label id="label" className="mt-30" htmlFor="name">
          Message
        </label>
        <textarea
          row="5"
          id="contact-msg"
          value={this.state.msg}
          onChange={(e) => this.handleChangeEmail(e, "msg")}
        ></textarea>
        <span id="err" style={{ color: "red" }}>
          {this.state.msg_err}
        </span>
        <div className="form-field ">
          <label htmlFor="phone"> Phone </label>
          <div className="phone-field">
            {/* <select name="phone" id="phone">
                  <option value="+91">+91</option>
                  <option value="+10">+10</option>
                </select> */}
            <input type="text" id="phone" name="phone" />
          </div>
        </div>
        <div className="form-field ">
          <ReCAPTCHA
            style={{ marginTop: "20px" }}
            sitekey={recaptha_siteKey}
            onChange={this.onChangeCaptcha}
          />
        </div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={this.sendmail}
          disabled={!this.state.captcha}
        >
          Contact US
        </button>
      </Modal>
    );
  };

  render() {
    return (
      <>
        <div className="custom-container home-page bg-main">
          <div>
            <NotificationContainer />
          </div>
          <section className="custom-header" style={{
            marginBottom: 0
          }}>
            <div className="page-custom-container">
              <div className="custom-header-wrapper">
                <div>
                  <Link to="/">
                    {" "}
                    <img width="170" height="55" src={LOGO_FULL_URL} />
                  </Link>
                  <span className="beta-name">BETA</span>
                  {/* <svg width="65" height="65" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 92.1114C19 89.9579 20.3788 88.0463 22.4223 87.3668L62.3682 74.0836L61.2187 123.617L25.2689 133.05C22.0994 133.881 19 131.49 19 128.213V92.1114Z" fill="#FF4440"/>
          <path d="M62.2636 74.0836L172.585 37.6346C176.184 36.4457 179.745 39.536 179.075 43.2659L170.461 91.2367C170.119 93.1416 168.708 94.6779 166.839 95.1811L61.2186 123.617L62.2636 74.0836Z" fill="#00C865"/>
          <path d="M61.2186 123.199L111.379 109.823L107.343 152.489C107.134 154.701 105.49 156.51 103.307 156.928L67.1604 163.861C64.0775 164.452 61.2186 162.089 61.2186 158.95V123.199Z" fill="#FF9A28"/>
          </svg> */}
                </div>
                <div className="align-items-inline">
                  <div className="outline-btn custom-header-login-btn">
                    <Link to="/pricing">Pricing</Link>
                  </div>
                  <div className="outline-btn custom-header-login-btn">
                    <a
                      href="https://api.oyetest.com/automation/docs/#/Authentication/login"
                      target="_blank"
                      rel="noreferrer"
                    >
                      API
                    </a>
                  </div>
                  {/* <div className="outline-btn custom-header-login-btn">
                    <Link to="/aboutus">About us</Link>
                  </div> */}
                  <div className="outline-btn custom-header-login-btn">
                    <Link to="/login">Login</Link>
                  </div>
                  <div className="outline-btn">
                    <Link to="/register">Sign Up</Link>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="d-flex  flex-column align-items-center justify-content-center" style={{ background: '#F9F9F9', paddingTop: 90, paddingBottom:'180px' }}>
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="card-body noauth-pricing-page"
                  style={{
                    position: "relative",
                    width: 926,
                    border: '1px solid #e0e0e0',
                    borderRadius: 15
                  }}
                >
                  <div className="modal-header clearfix" style={{ display: 'block' }}>
                    <h1>Submit a request or Call at +916362723848 or Write us at support@oyesense.com</h1>
                    
                    <p>HI THERE! WHAT CAN WE HELP YOU WITH? Make sure you're signed in, otherwise your request won't be submitted until you verify your email.</p>

                    {/* <i
            className="mdi mdi-close"
            onClick={() => this.setState({ createModalVisible: false })}
          ></i> */}
                  </div>

                  <label id="label" className="mt-30" htmlFor="name">
                    Your Full Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    value={this.state.name}
                    style={{ borderColor: '#e0e0e0' }}
                    onChange={(e) => this.handleChangeEmail(e, "name")}
                  />
                  <span id="err" style={{ color: "red" }}>
                    {this.state.name_err}
                  </span>
                  <label id="label" className="mt-30" htmlFor="name">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    value={this.state.email}
                    style={{ borderColor: '#e0e0e0' }}
                    onChange={(e) => this.handleChangeEmail(e, "email")}
                    id="email"
                  />

                  <span id="err" style={{ color: "red" }}>
                    {this.state.email_err}
                  </span>
                  <label id="label" className="mt-30" htmlFor="name">
                    Message
                  </label>
                  <textarea
                    row="5"
                    id="contact-msg"
                    value={this.state.msg}
                    onChange={(e) => this.handleChangeEmail(e, "msg")}
                  ></textarea>
                  <span id="err" style={{ color: "red" }}>
                    {this.state.msg_err}
                  </span>
                  <div className="form-field ">
                    <label htmlFor="phone"> Phone </label>
                    <div className="phone-field">
                      {/* <select name="phone" id="phone">
                  <option value="+91">+91</option>
                  <option value="+10">+10</option>
                </select> */}
                      <input type="text" id="phone" name="phone" />
                    </div>
                  </div>
                  <div className="form-field ">
                    <ReCAPTCHA
                      style={{ marginTop: "20px" }}
                      sitekey={recaptha_siteKey}
                      onChange={this.onChangeCaptcha}
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.sendmail}
                    disabled={!this.state.captcha}
                  >
                    Submit
                  </button>



                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: '-180px' }}>
            <Footer />
          </div>
          {this.onTestSuitCreate()}
        </div>
      </>
    );
  }
}
