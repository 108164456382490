/* eslint-disable no-unused-expressions */
/* eslint-disable default-case */
/* eslint-disable react/jsx-no-undef */
import React, { Component, useEffect, useRef, useState } from "react";
import "../styles/Dashboard.css";
import { history } from "../_helpers/history";
import { BASE_URL } from "../_helpers/constants";
import Promises from "../_helpers/utils";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

// import { Scrollbars } from "react-custom-scrollbars";
import "react-notifications/lib/notifications.css";
import "../styles/component/integration.css";
import { RiEyeCloseLine, RiDeleteBin6Fill } from "react-icons/ri";
import { MdArrowOutward } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import TwoActionPopup from "../_components/TwoActionPopup";
import Navbar from "../_components/Navbar/Navbar";
import IntegrationCard from "../skeletons/integrationCard.jsx";
import { ColorRing } from "react-loader-spinner";

export default class Integration extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.toggleprofile = this.toggleprofile.bind(this);
    this.handler = this.handler.bind(this);
  }

  state = {
    showDrop: false,
    intiType: "init",
    selectedOption1: null,
    removeModal: false,
    loadingRemove: false,
    removeId: "",
    github: {
      showForm: false,
      isIntegrated: false,
      connectionId: "",
      status: "",
      atoken: "",
      githubUsername: "",
      connectionName: "",
    },
    asana: {
      showForm: false,
      isIntegrated: false,
      connectionId: "",
      status: "",
      key: "",
      connectionName: "",
    },
    jira: {
      showForm: false,
      isIntegrated: false,
      connectionId: "",
      status: "",
      key: "",
      domain: "",
      email: "",
      connectionName: "",
    },
    trello: {
      showForm: false,
      isIntegrated: false,
      connectionId: "",
      status: "",
      oauthKey: "",
      memberId: "",
      key: "",
      connectionName: "",
    },

    importState: 0,
    viewState: 0,
    selectedTab: "test-case",
    isDrawerVisible: false,
    search: "",
    PROJECTS: [],
    titleName: "",
    selectIndex: 0,
    selectedProjectId: "",
    loading: false,
    isSubmit: false,
    isIntigration: false,
    domain: "",
    key: "",
    atoken: "",
    description: "",
    editProjectID: "",
    createModalVisible: false,
    isEditProjectName: false,
    githubUserName: "",
    isIntegrated: false,
    // showForm: false,
    showForm: {
      git: false,
      trello: false,
      asana: false,
      jira: false,
    },
    updating:{
      git: false,
      trello: false,
      asana: false,
      jira: false,
    }
  };

  componentDidMount() {
    Promises.setPage("intigration");
    this.setState({ loading: true }, () => {
      this.getOrgIntigration();
    });
  }

  handler(type = 1) {
    if (type === 1) {
      this.setState({ selectedTab: "test-result" });
    } else {
      this.setState({ selectedTab: "test-case" });
    }
  }
  handlerAdmin() {
    this.setState({ selectedTab: "administrator" });
    this.setState({ viewState: 2 });
  }
  onLogout() {
    localStorage.clear();
  }

  toggleprofile() {
    this.setState((prevState) => ({
      dropdownOpenprofile: !prevState.dropdownOpenprofile,
    }));
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  getOrgIntigration = (flag = null) => {
    this.setState({ loading: true });
    const { intiType } = this.state;
    const token = Promises.getToken();
    if (!token) {
      history.push("/login?redirect=1");
    }

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BASE_URL}projects/org/integration`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        if (result.code === 200) {
          this.updateIntegrations(result.integrations);
          this.setState({
            showForm: {
              git: false,
              trello: false,
              asana: false,
              jira: false,
            },
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        NotificationManager.error(error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };
  updateIntegrations = (integrations) => {
    integrations.forEach((integration) => {
      switch (integration.type) {
        case "github":
          this.setState({
            github: {
              showForm: false,
              isIntegrated: true,
              connectionId: integration.id,
              status: integration.status,
              atoken: integration.data.atoken || "",
              githubUsername: integration.data.githubUserName || "",
              connectionName: integration.connection_name || "",
            },
          });
          break;
        case "asana":
          this.setState({
            asana: {
              showForm: false,
              isIntegrated: true,
              connectionId: integration.id,
              status: integration.status,
              key: integration.data.key || "",
              connectionName: integration.connection_name || "",
            },
          });
          break;
        case "jira":
          this.setState({
            jira: {
              showForm: false,
              isIntegrated: true,
              connectionId: integration.id,
              status: integration.status,
              key: integration.data.key || "",
              domain: integration.data.domain || "",
              email: integration.data.email || "",
              connectionName: integration.connection_name || "",
            },
          });
          break;
        case "trello":
          this.setState({
            trello: {
              showForm: false,
              isIntegrated: true,
              connectionId: integration.id,
              status: integration.status,
              oauthKey: integration.data.oauthKey || "",
              memberId: integration.data.memberId || "",
              key: integration.data.key || "",
              connectionName: integration.connection_name || "",
            },
          });
          break;
        default:
          break;
      }
    });
    this.setState({ loading: false });
  };
  updateRaw = (type) => {
    const { github, asana, trello, jira } = this.state;
    let raw;
    switch (type) {
      case "github":
        raw = JSON.stringify({
          type: type,
          domain: "",
          key: "",
          email: "",
          atoken: github.atoken,
          orgId: Promises.getOrg(),
          oauthKey: "",
          memberId: "",
          githubUserName: github.githubUsername,
          connection_name: github.connectionName,
          connection_id: github.connectionId,
          isIntegrated: github.isIntegrated,
        });

        break;
      case "asana":
        raw = JSON.stringify({
          type: type,
          domain: "",
          key: asana.key,
          email: "",
          atoken: "",
          orgId: Promises.getOrg(),
          oauthKey: "",
          memberId: "",
          githubUserName: "",
          connection_name: asana.connectionName,
          connection_id: asana.connectionId,
          isIntegrated: asana.isIntegrated,
        });
        break;
      case "jira":
        raw = JSON.stringify({
          type: type,
          domain: jira.domain,
          key: jira.key,
          email: jira.email,
          atoken: "",
          orgId: Promises.getOrg(),
          oauthKey: "",
          memberId: "",
          githubUserName: "",
          connection_name: jira.connectionName,
          connection_id: jira.connectionId,
          isIntegrated: jira.isIntegrated,
        });
        break;
      case "trello":
        raw = JSON.stringify({
          type: type,
          domain: "",
          key: trello.key,
          email: "",
          atoken: "",
          orgId: Promises.getOrg(),
          oauthKey: trello.oauthKey,
          memberId: trello.memberId,
          githubUserName: "",
          connection_name: trello.connectionName,
          connection_id: trello.connectionId,
          isIntegrated: trello.isIntegrated,
        });
        break;
      default:
        raw = JSON.stringify({
          type: type,
          domain: "",
          key: "",
          email: "",
          atoken: "",
          orgId: Promises.getOrg(),
          oauthKey: "",
          memberId: "",
          githubUserName: "",
          connection_name: "",
          connection_id: "",
          isIntegrated: "",
        });
        break;
    }
    return raw;
  };
  clearIntegration = (connectionId, type) => {
    switch (type) {
      case "github":
        this.setState({
          github: {
            showForm: false,
            isIntegrated: false,
            connectionId: "",
            connectionName: "",
            status: "",
            atoken: "",
            githubUsername: "",
          },
        });
        break;
      case "asana":
        this.setState({
          asana: {
            showForm: false,
            isIntegrated: false,
            connectionId: "",
            connectionName: "",
            status: "",
            key: "",
          },
        });
        break;
      case "jira":
        this.setState({
          jira: {
            showForm: false,
            isIntegrated: false,
            connectionId: "",
            connectionName: "",
            status: "",
            key: "",
            domain: "",
            email: "",
          },
        });
        break;
      case "trello":
        this.setState({
          trello: {
            showForm: false,
            iisIntegrated: false,
            connectionId: "",
            connectionName: "",
            status: "",
            oauthKey: "",
            memberId: "",
            key: "",
          },
        });
        break;
      default:
        break;
    }
  };
  onAddOrgIntigration = (type) => {
    console.log("type is", type);
    this.setState({updating:{
      ...this.state.updating,
      [type]:true
    }})
    const token = Promises.getToken();

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: this.updateRaw(type),
      redirect: "follow",
    };

    console.log("row for update", this.updateRaw(type));

    fetch(`${BASE_URL}projects/org/integration/add`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        if (result.code === 200) {
          NotificationManager.success(result.msg);
          this.updateIntegrations(result.integrations);
          this.getOrgIntigration();
        } else {
          NotificationManager.error(result.msg);
        }
      })
      .catch((error) => {
        NotificationManager.error(
          "Something went wrong with creating project, Please try again later"
        );
      }).finally(()=>{
        this.setState({updating:{
          ...this.state.updating,
          [type]:false
        }})
      })
  };
  handleGithubInputChange = (field, value) => {
    this.setState((prevState) => ({
      github: {
        ...prevState.github,
        [field]: value,
      },
    }));
  };
  handleJiraInputChange = (field, value) => {
    this.setState((prevState) => ({
      jira: {
        ...prevState.jira,
        [field]: value,
      },
    }));
  };
  handleAsanaInputChange = (field, value) => {
    this.setState((prevState) => ({
      asana: {
        ...prevState.asana,
        [field]: value,
      },
    }));
  };
  handleTrelloInputChange = (field, value) => {
    this.setState((prevState) => ({
      trello: {
        ...prevState.trello,
        [field]: value,
      },
    }));
  };
  deleteProject = (projectID) => {
    this.setState({ loadingRemove: true });
    const { removeId, removeType } = this.state;
    const token = Promises.getToken();

    var myHeaders = new Headers();
    myHeaders.append("X-L10N-Locale", "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", token);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${BASE_URL}projects/org/integration/delete/${removeId}`,
      requestOptions
    )
      .then((response) => response.text())
      .then(async (result) => {
        if (JSON.parse(result).code === 401) {
          this.setState({ loading: false });
          localStorage.clear();
          history.push("/login?redirect=1");
        }
        console.log(JSON.parse(result));
        if (JSON.parse(result).code === 200) {
          NotificationManager.success(JSON.parse(result).msg);
          this.clearIntegration(removeId, removeType);
          this.setState({ removeId: "" });

          this.setState({ removeModal: false, loadingRemove: false });
        } else {
          NotificationManager.error(
            "Something went wrong while deleting Integration, Please try again later"
          );
        }
      })
      .catch((error) => {
        // console.log('error', error)
        NotificationManager.error(
          "Something went wrong with creating project, Please try again later"
        );
      });
  };

  editProjectName = (projectID, projectName, projectDescription) => {
    console.log(projectID, " ** ", projectName);

    this.setState(
      {
        createModalVisible: true,
        viewState: 1,
        isEditProjectName: true,
        name: projectName,
        description: projectDescription,
        editProjectID: projectID,
      },
      () => {
        console.log(this.state);
      }
    );
  };

  selectedProject = (val, isImport) => {
    console.log(val.name);
    this.setState({
      selectedTab: "test-case",
      titleName: val.name,
      selectedProjectId: val.id,
    });
    this.setState({ importState: isImport });
    this.setState({ viewState: 1 });
    this.props.history.push(`dashboard/${val.name}`);

    if (this.child.current) {
      this.child.current.changeIndex(val.id);
    }
  };

  render() {
    const { viewState } = this.state;

    switch (viewState) {
      case 0:
        return (
          <div id="main-body">
            {this.renderHeader4()}
            {this.renderBody1()}
          </div>
        );
    }
  }
  renderHeader4 = () => {
    return (
      <Navbar
        selectInvite={() => {
          console.log("selected invite");
          this.props.history.push({
            pathname: "/dashboard",
            appState: {
              selectedTab: "administrator",
              viewState: 2,
            },
          });
        }}
        selectUpgraded={() => {
          history.push("/price");
        }}
      />
    );
  };
  handleChange1 = (selectedOption1) => {
    this.setState({ selectedOption1 });
    this.setState({ intiType: selectedOption1.key }, () => {
      this.getOrgIntigration();
    });
  };
  toggleShowFormGithub = () => {
    this.setState((prevState) => ({
      github: {
        ...prevState.github,
        showForm: !prevState.github.showForm,
      },
    }));
  };

  handleRemoveIntegrationClick = (id, type) => {
    this.setState({
      removeModal: !this.state.removeModal,
      removeId: id,
      removeType: type,
      loadingRemove: false,
      github: {
        ...this.state.github,
        showForm: false,
      },
      asana: {
        ...this.state.asana,
        showForm: false,
      },
      trello: {
        ...this.state.trello,
        showForm: false,
      },
      jira: {
        ...this.state.jira,
        showForm: false,
      },
    });
  };

  renderBody1 = () => {
    const githubIntegrationData = {
      isIntegrated: this.state.github.isIntegrated,
      type: "github",
      status: this.state.github.status,
      toolName: "Github",
      toolSvg: (
        <svg
          width="52"
          height="53"
          viewBox="0 0 52 53"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M26 3.75012C13.4306 3.75012 3.25 13.9307 3.25 26.5001C3.25 36.567 9.76219 45.0698 18.8053 48.0842C19.9428 48.2832 20.3694 47.6007 20.3694 47.0036C20.3694 46.4632 20.3409 44.6717 20.3409 42.7664C14.625 43.8186 13.1462 41.3729 12.6912 40.0932C12.4353 39.4392 11.3263 37.4201 10.3594 36.8798C9.56313 36.4532 8.42563 35.4011 10.3309 35.3726C12.1225 35.3442 13.4022 37.022 13.8288 37.7045C15.8763 41.1454 19.1466 40.1786 20.4547 39.5814C20.6537 38.1026 21.2509 37.1073 21.905 36.5386C16.8431 35.9698 11.5538 34.0076 11.5538 25.3057C11.5538 22.8317 12.4353 20.7842 13.8856 19.1917C13.6581 18.6229 12.8619 16.2911 14.1131 13.1629C14.1131 13.1629 16.0184 12.5657 20.3694 15.4948C22.1894 14.9829 24.1231 14.727 26.0569 14.727C27.9906 14.727 29.9244 14.9829 31.7444 15.4948C36.0953 12.5373 38.0006 13.1629 38.0006 13.1629C39.2519 16.2911 38.4556 18.6229 38.2281 19.1917C39.6784 20.7842 40.56 22.8032 40.56 25.3057C40.56 34.0367 35.2706 35.9698 30.2088 36.5386C31.0544 37.2823 31.6875 38.5476 31.6875 40.5898C31.6875 43.5814 31.6581 46.0823 31.6581 47.0036C31.6581 47.6007 32.0847 48.2832 33.2222 48.3117C42.2659 45.0698 48.7781 36.567 48.7781 26.5001C48.75 13.9307 38.5694 3.75012 26 3.75012Z"
            fill="#1C2A36"
          />
        </svg>
      ),
      subHeading:
        " Github, you'll need your Github username and a fine-grained access token. Generate the token directly from your ",
      accountSettingsLink: "https://docs.github.com/en/authentication/keeping-your-account-and-data-secure/managing-your-personal-access-tokens#fine-grained-personal-access-tokens",
      linkText: "Github account settings.",
      inputFields: [
        {
          labelName: "Connection Name",
          valueState: "connectionName",
          isPassword: false,
          name: "connectionName",
        },
        {
          labelName: "Github Username",
          valueState: "githubUsername",
          isPassword: false,
          name: "githubUsername",
        },
        {
          labelName: "Personal Access Token",
          valueState: "atoken",
          isPassword: true,
          name: "atoken",
        },
      ],
    };
    const jiraIntegrationData = {
      isIntegrated: this.state.jira.isIntegrated,
      type: "jira",
      status: this.state.jira.status,
      toolName: "Jira",
      toolSvg: (
        <svg
          width="52"
          height="53"
          viewBox="0 0 52 53"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M52.8402 24.8509L30.6509 5.64845L28.5 3.78711L11.7967 18.2419L4.15968 24.8509C3.36344 25.5408 3.36344 26.6584 4.15968 27.3483L19.4201 40.5544L28.5 48.4121L45.2033 33.9573L45.4619 33.7335L52.8402 27.3483C53.6366 26.6584 53.6366 25.5408 52.8402 24.8509ZM28.5 32.6968L20.8766 26.0996L28.5 19.5024L36.1234 26.0996L28.5 32.6968Z"
            fill="#2684FF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M28.5 19.5027C23.5089 15.1828 23.4845 8.18607 28.4457 3.84033L11.7627 18.2716L20.8426 26.1293L28.5 19.5027Z"
            fill="url(#paint0_linear_372_1664)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M36.1437 26.082L28.5 32.6969C30.9091 34.7804 32.2627 37.6071 32.2627 40.5546C32.2627 43.502 30.9091 46.3286 28.5 48.4122L45.2238 33.9397L36.1437 26.082Z"
            fill="url(#paint1_linear_372_1664)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_372_1664"
              x1="27.1388"
              y1="12.829"
              x2="14.9098"
              y2="18.7965"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.18" stop-color="#0052CC" />
              <stop offset="1" stop-color="#2684FF" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_372_1664"
              x1="29.9565"
              y1="39.2941"
              x2="42.1677"
              y2="33.3668"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.18" stop-color="#0052CC" />
              <stop offset="1" stop-color="#2684FF" />
            </linearGradient>
          </defs>
        </svg>
      ),
      subHeading:
        " Jira, you'll need your JIra domain, email-address and API token. Generate the token directly from your ",
      accountSettingsLink:
        "https://developer.atlassian.com/cloud/trello/guides/rest-api/api-introduction/",
      linkText: " Atlassian account.",
      inputFields: [
        {
          labelName: "Connection Name",
          valueState: "connectionName",
          isPassword: false,
          name: "connectionName",
        },
        {
          labelName: "Email ID",
          valueState: "email",
          isPassword: false,
          isEmail: true,
          name: "email",
        },
        {
          labelName: "Domain",
          valueState: "domain",
          isPassword: false,
          name: "domain",
        },
        {
          labelName: "API token",
          valueState: "key",
          isPassword: true,
          name: "key",
        },
      ],
    };
    const asanaIntegrationData = {
      isIntegrated: this.state.asana.isIntegrated,
      type: "asana",
      status: this.state.asana.status,
      toolName: "Asana",
      toolSvg: (
        <svg
          width="52"
          height="53"
          viewBox="0 0 52 53"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M38.3433 25.6823C32.458 25.6823 27.6867 30.6334 27.6867 36.7414C27.6867 42.8489 32.458 47.8 38.3433 47.8C44.2289 47.8 49 42.8489 49 36.7414C49 30.6334 44.2289 25.6823 38.3433 25.6823ZM10.6566 25.6832C4.77119 25.6832 0 30.6334 0 36.7414C0 42.8489 4.77119 47.8 10.6566 47.8C16.5423 47.8 21.3138 42.8489 21.3138 36.7414C21.3138 30.6334 16.5423 25.6832 10.6566 25.6832ZM35.1565 11.8585C35.1565 17.9665 30.3855 22.9182 24.5001 22.9182C18.6143 22.9182 13.8434 17.9665 13.8434 11.8585C13.8434 5.75174 18.6143 0.800049 24.5001 0.800049C30.3855 0.800049 35.1565 5.75174 35.1565 11.8585Z"
            fill="#F06A6A"
          />
        </svg>
      ),
      subHeading:
        "  Asana, you'll need your Asana personal access token. Generate the token directly from the  ",
      accountSettingsLink:
        "https://developers.asana.com/docs/personal-access-token",
      linkText: " Asana developer console.",
      inputFields: [
        {
          labelName: "Connection Name",
          valueState: "connectionName",
          isPassword: false,
          name: "connectionName",
        },
        {
          labelName: "API token",
          valueState: "key",
          isPassword: true,
          name: "key",
        },
      ],
    };
    const trelloIntegrationData = {
      isIntegrated: this.state.trello.isIntegrated,
      type: "trello",
      status: this.state.trello.status,
      toolName: "Trello",
      toolSvg: (
        <svg width="52" height="53" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M40.6466 7.40027H11.3781C8.684 7.40027 6.5 9.58289 6.5 12.2753V41.5253C6.5 44.2177 8.684 46.4003 11.3781 46.4003H40.6466C43.3311 46.3866 45.5 44.208 45.5 41.5253V12.2753C45.5 9.59251 43.3311 7.41389 40.6466 7.40027ZM14.625 12.2753H21.125C22.92 12.2753 24.375 13.7303 24.375 15.5253V38.2753C24.375 40.0702 22.92 41.5253 21.125 41.5253H14.625C12.8301 41.5253 11.375 40.0702 11.375 38.2753V15.5253C11.375 13.7303 12.8301 12.2753 14.625 12.2753ZM37.375 12.2753H30.875C29.08 12.2753 27.625 13.7303 27.625 15.5253V26.9003C27.625 28.6952 29.08 30.1503 30.875 30.1503H37.375C39.17 30.1503 40.625 28.6952 40.625 26.9003V15.5253C40.625 13.7303 39.17 12.2753 37.375 12.2753Z" fill="url(#paint0_linear_142_216)"/>
<defs>
<linearGradient id="paint0_linear_142_216" x1="26.0124" y1="46.4003" x2="26.0124" y2="7.40027" gradientUnits="userSpaceOnUse">
<stop stop-color="#0052CC"/>
<stop offset="0.51698" stop-color="#217EF8"/>
<stop offset="1" stop-color="#2684FF"/>
</linearGradient>
</defs>
</svg>


      ),
      subHeading:
        " Trello, you'll need your Trello API Token and API Key. Generate the API Token and API Key directly from your ",
      accountSettingsLink: "https://developer.atlassian.com/cloud/trello/guides/rest-api/api-introduction/",
      linkText: " Trello powerups admin.",
      inputFields: [
        {
          labelName: "Connection Name",
          valueState: "connectionName",
          isPassword: false,
          name: "connectionName",
        },
        {
          labelName: "API Token",
          valueState: "oauthKey",
          isPassword: true,
          name: "oauthKey",
        },
        {
          labelName: "API Key",
          valueState: "key",
          isPassword: true,
          name: "key",
        },
      ],
    };

    // const GithubIntegrationHOC = withIntegration(GithubIntegration);
    // const GithubIntegrationHOC2 = WithIntegration(GithubIntegration2);
    return (
      <div className="project-list-section2">
        <div className="col-lg-12" style={{ overflow: "auto" }}>
          <p
            className="primary_heading"
            style={{ marginBottom: 10, marginTop: 20 }}
          >
            Configure Integrations
          </p>
          <p className="sub_para">
            Configure integrations with popular issue trackers and test
            automation tools. Simply select one or more tools, set up your
            connection, and and map your projects to effectively track and
            manage your test cases.
          </p>
        </div>
        <div
          className="col-lg-12"
          style={{ display: "flex", flexDirection: "column", gap: "0.6rem" }}
        >
          {this.state.loading &&
            Array(4)
              .fill(0)
              .map((x, i) => <IntegrationCard />)}
          {!this.state.loading && (
            <div>
              <WithIntegration
                connectionId={this.state.github.connectionId}
                integrationData={githubIntegrationData}
                onSubmitIntegration={this.onAddOrgIntigration}
                integrationState={this.state.github}
                onInputChange={this.handleGithubInputChange}
                onRemove={this.handleRemoveIntegrationClick}
                showForm={this.state.showForm.git}
                performingSuccess={this.state.updating.git}
                onToggleShowForm={() => {
                  console.log("toggled git");
                  this.setState({
                    showForm: {
                      ...this.state.showForm,
                      git: !this.state.showForm.git,
                    },
                  });
                }}
              />
              <WithIntegration
                connectionId={this.state.jira.connectionId}
                integrationData={jiraIntegrationData}
                onSubmitIntegration={this.onAddOrgIntigration}
                integrationState={this.state.jira}
                onInputChange={this.handleJiraInputChange}
                onRemove={this.handleRemoveIntegrationClick}
                showForm={this.state.showForm.jira}
                performingSuccess={this.state.updating.jira}
                onToggleShowForm={() => {
                  console.log("toggled git");
                  this.setState({
                    showForm: {
                      ...this.state.showForm,
                      jira: !this.state.showForm.jira,
                    },
                  });
                }}
              />
              <WithIntegration
                connectionId={this.state.asana.connectionId}
                integrationData={asanaIntegrationData}
                onSubmitIntegration={this.onAddOrgIntigration}
                integrationState={this.state.asana}
                onInputChange={this.handleAsanaInputChange}
                onRemove={this.handleRemoveIntegrationClick}
                showForm={this.state.showForm.asana}
                performingSuccess={this.state.updating.asana}
                onToggleShowForm={() => {
                  // console.log("toggled git2 asana");
                  this.setState({
                    showForm: {
                      ...this.state.showForm,
                      asana: !this.state.showForm.asana,
                    },
                  });
                }}
              />
            
          
          
          <WithIntegration
            connectionId={this.state.trello.connectionId}
            integrationData={trelloIntegrationData}
            onSubmitIntegration={this.onAddOrgIntigration}
            integrationState={this.state.trello}
            onRemove={this.handleRemoveIntegrationClick}
            onInputChange={this.handleTrelloInputChange}
            showForm={this.state.showForm.trello}
            performingSuccess={this.state.updating.trello}
            onToggleShowForm={() => {
              // console.log("toggled git2 asana");
              this.setState({
                showForm: {
                  ...this.state.showForm,
                  trello: !this.state.showForm.trello,
                },
              });
            }}
          />
          </div>
          )}
          {/* <GithubIntegrationHOC
            connectionId={this.state.github.connectionId}
            integrationData={githubIntegrationData}
            onSubmitIntegration={this.onAddOrgIntigration}
            integrationState={this.state.github}
            onInputChange={this.handleGithubInputChange}
            onRemove={this.handleRemoveIntegrationClick}
            showForm={this.state.showForm.git}
            onToggleShowForm={() => {
              console.log("toggled git");
              this.setState({
                showForm: {
                  ...this.state.showForm,
                  git: !this.state.showForm.git,
                },
              });
            }}
          /> */}
        </div>
        <NotificationContainer />
        {this.state.removeModal && (
          <TwoActionPopup
            label="Remove Github Integration?"
            description="All issues associated with this repository will be deleted permanently from the platform."
            successText="Remove"
            abortText="Cancel"
            onSuccess={this.deleteProject}
            successLoading={this.state.loadingRemove}
            onAbort={this.handleRemoveIntegrationClick}
            isInputRequired={true}
            initialInputValue="REMOVE"
            isDangerPage={true}
            height={359}
          />
        )}
      </div>
    );
  };
}
// const withIntegration = (WrappedComponent) => {
//   return class extends Component {
//     constructor(props) {
//       super(props);

//       this.state = {
//         isIntegrated: props.integrationData.isIntegrated,
//         // showForm:false,
//         isHiddenPass: true,
//         errors: {},
//         inputchange: "",
//       };
//       this.formContainerRef = React.createRef();
//     }
//     componentDidUpdate(prevProps) {
//       // Handle prop changes
//       if (prevProps.integrationData !== this.props.integrationData) {
//         // Reset showForm or other state properties based on new props if necessary
//         this.setState({
//           isIntegrated: this.props.integrationData.isIntegrated,
//           showForm: false, // Reset or initialize as needed
//         });
//       }
//     }

//     handleConnectClick = () => {
//       this.props.onToggleShowForm();

//       // if (this.formContainerRef.current) {
//       //   const container = this.formContainerRef.current;
//       //   console.log("Form container ref:", container);
//       //   container.style.maxHeight = this.props.showForm
//       //     ? `${container.scrollHeight}px`
//       //     : "0";
//       // } else {
//       //   console.error("Form container ref is undefined");
//       // }
//     };

//     handleInputChange = (event) => {
//       const { name, value } = event.target;
//       console.log("1", this.props.showForm);
//       this.props.onInputChange(name, value);
//       // this.setState({inputchange: true});

//       this.setState(
//         (prevState) => ({
//           errors: {
//             ...prevState.errors,
//             [name]: this.validateField(name, value), // Validate field on change
//           },
//         }),
//         () => {
//           // Log after the state has been updated
//           console.log("2", this.state.showForm);
//         }
//       );
//     };

//     handleSubmit = (event) => {
//       event.preventDefault();

//       const errors = this.validateForm();
//       if (Object.keys(errors).length === 0) {
//         this.props.onSubmitIntegration(this.props.integrationData.type);
//       } else {
//         this.setState({ errors });
//       }
//     };

//     handleRemove = () => {
//       this.props.onRemove(
//         this.props.connectionId,
//         this.props.integrationData.type
//       );
//     };

//     validateField(name, value) {
//       let error = "";

//       if (name === "email") {
//         const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//         if (!emailRegex.test(value)) {
//           error = "Invalid email address";
//         }
//       } else if (!value) {
//         error = "This field is required";
//       }

//       return error;
//     }

//     validateForm() {
//       const errors = {};
//       this.props.integrationData.inputFields.forEach((field) => {
//         const value = this.props.integrationState[field.valueState];
//         const error = this.validateField(field.name, value);
//         if (error) {
//           errors[field.name] = error;
//         }
//       });

//       return errors;
//     }

//     render() {
//       const { isIntegrated, isHiddenPass, errors } = this.state;
//       const { integrationData, integrationState, showForm } = this.props;

//       return (
//         <div className="integration-container">
//           <div className="integration-header">
//             <span className="rec"></span>
//             <div className="integration-icon">{integrationData.toolSvg}</div>
//             <div className="integration-status">
//               <span className="integration-tool">
//                 {integrationData.toolName}
//               </span>
//               <span className="integration-status-text">
//                 {isIntegrated ? (
//                   "Integrated"
//                 ) : (
//                   <>
//                     <svg
//                       width="9"
//                       height="9"
//                       viewBox="0 0 9 9"
//                       fill="none"
//                       xmlns="http://www.w3.org/2000/svg"
//                     >
//                       <g clipPath="url(#clip0_77_676)">
//                         <path
//                           fillRule="evenodd"
//                           clipRule="evenodd"
//                           d="M1.38889 4.90002C1.38889 4.20269 1.62222 3.55958 2.01155 3.04002L6.36 7.38847C5.84045 7.7778 5.19733 8.01114 4.5 8.01114C2.78489 8.01114 1.38889 6.61514 1.38889 4.90002ZM7.61111 4.90002C7.61111 5.59736 7.37778 6.24047 6.98845 6.76003L2.64 2.41158C3.15955 2.02225 3.80267 1.78891 4.5 1.78891C6.21511 1.78891 7.61111 3.18491 7.61111 4.90002ZM4.5 0.900024C2.29067 0.900024 0.5 2.69069 0.5 4.90002C0.5 7.10936 2.29067 8.90002 4.5 8.90002C6.70933 8.90002 8.5 7.10936 8.5 4.90002C8.5 2.69069 6.70933 0.900024 4.5 0.900024Z"
//                           fill="#818181"
//                         />
//                       </g>
//                       <defs>
//                         <clipPath id="clip0_77_676">
//                           <rect
//                             width="8"
//                             height="8"
//                             fill="white"
//                             transform="translate(0.5 0.900024)"
//                           />
//                         </clipPath>
//                       </defs>
//                     </svg>
//                     Not integrated
//                   </>
//                 )}
//               </span>
//             </div>
//             <div className="action-item">
//               {isIntegrated && (
//                 <>
//                   <div
//                     style={{
//                       display: "flex",
//                       flexDirection: "row",
//                       gap: "2rem",
//                     }}
//                   >
//                     <button
//                       className="edit-button"
//                       onClick={this.handleConnectClick}
//                       style={{ color: showForm ? "#A7A7A7" : "#316BFF" }}
//                     >
//                       <FiEdit /> Edit
//                     </button>
//                     <button
//                       className="edit-button"
//                       style={{ color: "#FF2B2F" }}
//                       onClick={this.handleRemove}
//                     >
//                       <RiDeleteBin6Fill /> Remove
//                     </button>
//                   </div>
//                 </>
//               )}
//               {!isIntegrated && (
//                 <button
//                   className={showForm ? "cancel-button" : "connect-button"}
//                   onClick={this.handleConnectClick}
//                 >
//                   {showForm ? (
//                     <>Close</>
//                   ) : (
//                     <>
//                       <svg
//                         width="21"
//                         height="21"
//                         viewBox="0 0 21 21"
//                         fill="none"
//                         xmlns="http://www.w3.org/2000/svg"
//                       >
//                         <path
//                           d="M12.1666 8.73336C12.1666 8.27312 11.7935 7.90002 11.3333 7.90002H10.9166C8.38528 7.90002 6.33325 9.95202 6.33325 12.4834C6.33325 15.0147 8.38528 17.0667 10.9166 17.0667H15.0833C17.6146 17.0667 19.6666 15.0147 19.6666 12.4834C19.6666 10.4076 18.2867 8.65409 16.3941 8.09022C15.9248 7.95037 15.4999 8.34075 15.4999 8.83052V8.87361C15.4999 9.28827 15.812 9.62794 16.1953 9.78619C17.2545 10.2234 17.9999 11.2663 17.9999 12.4834C17.9999 14.0942 16.6941 15.4 15.0833 15.4H10.9166C9.30575 15.4 7.99992 14.0942 7.99992 12.4834C7.99992 10.8725 9.30575 9.56669 10.9166 9.56669H11.3333C11.7935 9.56669 12.1666 9.19361 12.1666 8.73336Z"
//                           fill="white"
//                         />
//                         <path
//                           d="M10.0833 3.73337C12.6146 3.73337 14.6666 5.78539 14.6666 8.3167C14.6666 10.848 12.6146 12.9 10.0833 12.9H9.66658C9.20634 12.9 8.83325 12.527 8.83325 12.0667C8.83325 11.6065 9.20634 11.2334 9.66658 11.2334H10.0833C11.6941 11.2334 12.9999 9.92754 12.9999 8.3167C12.9999 6.70587 11.6941 5.40003 10.0833 5.40003H5.91658C4.30575 5.40003 2.99992 6.70587 2.99992 8.3167C2.99992 9.53378 3.74534 10.5767 4.80453 11.0139C5.18782 11.172 5.49992 11.5118 5.49992 11.9265V11.9695C5.49992 12.4593 5.07512 12.8497 4.60574 12.7099C2.71319 12.146 1.33325 10.3925 1.33325 8.3167C1.33325 5.78539 3.38528 3.73337 5.91658 3.73337H10.0833Z"
//                           fill="white"
//                         />
//                       </svg>
//                       Connect
//                     </>
//                   )}
//                 </button>
//               )}
//             </div>
//           </div>
//           {this.props.showForm && (
//             <div
//               ref={this.formContainerRef}
//               className="integration-form-container"
//             >
//               <form onSubmit={this.handleSubmit} className="integration-form">
//                 <div className="integration-form-content">
//                   <p className="integration-tool" style={{ fontSize: 16 }}>
//                     Connect to {integrationData.toolName}
//                   </p>
//                   <p className="form-para">
//                     {integrationData.subHeading}
//                     <a
//                       className="link"
//                       href={integrationData.accountSettingsLink}
//                     >
//                       {integrationData.linkText}
//                       <MdArrowOutward />
//                     </a>
//                   </p>
//                   {integrationData.inputFields.map((field, index) => (
//                     <div className="form-group" key={index}>
//                       <div className="flex flex-row justify-between items-center">
//                         <label
//                           className="form-field-label input-label"
//                           htmlFor={field.name}
//                         >
//                           {field.labelName}
//                         </label>
//                         {field.isPassword && (
//                           <div
//                             className="flex cursor-pointer gap-x-1 items-center"
//                             onClick={() => {
//                               this.setState({
//                                 isHiddenPass: !this.state.isHiddenPass,
//                               });
//                             }}
//                           >
//                             {!this.state.isHiddenPass ? (
//                               <RiEyeCloseLine />
//                             ) : (
//                               <svg
//                                 width="15"
//                                 height="12"
//                                 viewBox="0 0 15 12"
//                                 fill="none"
//                                 xmlns="http://www.w3.org/2000/svg"
//                               >
//                                 <path
//                                   d="M1 7.66667C1 7.66667 2.33333 3.5 7.66667 3.5M7.66667 3.5C13 3.5 14.3333 7.66667 14.3333 7.66667M7.66667 3.5V1M12.6667 1.83333L11 3.91667M2.66667 1.83333L4.33333 3.91667M10.1667 8.5C10.1667 9.88075 9.04742 11 7.66667 11C6.28592 11 5.16667 9.88075 5.16667 8.5C5.16667 7.11925 6.28592 6 7.66667 6C9.04742 6 10.1667 7.11925 10.1667 8.5Z"
//                                   stroke="#464455"
//                                   stroke-linecap="round"
//                                   stroke-linejoin="round"
//                                 />
//                               </svg>
//                             )}
//                             <p
//                               className="font-roboto opacity-70"
//                               style={{
//                                 fontFamily: "Roboto",
//                                 fontSize: "12px",
//                                 fontWeight: 400,
//                                 lineHeight: "19.5px",
//                                 textAlign: "right",
//                               }}
//                             >
//                               {this.state.isHiddenPass ? "Show" : "Hide"}
//                             </p>
//                           </div>
//                         )}
//                       </div>
//                       <input
//                         type={
//                           field.isEmail
//                             ? "email"
//                             : field.isPassword && isHiddenPass
//                             ? "password"
//                             : "text"
//                         }
//                         name={field.name}
//                         value={integrationState[field.valueState]}
//                         onChange={this.handleInputChange}
//                         className="form-field-input px-3"
//                         style={{
//                           backgroundColor:
//                             isIntegrated && !field.isPassword
//                               ? "#E0E0E0"
//                               : "#ffffff",
//                         }}
//                         readOnly={isIntegrated && !field.isPassword}
//                       />
//                       {errors[field.name] && (
//                         <span className="text-sm text-red-500 h-3 mb-3 mt-2">
//                           {errors[field.name]}
//                         </span>
//                       )}
//                     </div>
//                   ))}
//                   <div className="form-action">
//                     <button type="submit" className="enable-button">
//                       <svg
//                         width="15"
//                         height="11"
//                         viewBox="0 0 15 11"
//                         fill="none"
//                         xmlns="http://www.w3.org/2000/svg"
//                       >
//                         <path
//                           fill-rule="evenodd"
//                           clip-rule="evenodd"
//                           d="M14.0432 0.73289C14.3288 1.03291 14.3171 1.50764 14.0171 1.79323L5.35045 10.0432C5.20245 10.1841 5.00337 10.2585 4.79925 10.2492C4.59512 10.2399 4.40362 10.1478 4.26903 9.99401L0.935695 6.18632C0.66286 5.87466 0.694335 5.40083 1.006 5.12799C1.31766 4.85516 1.79149 4.88663 2.06432 5.1983L4.8826 8.41763L12.9829 0.706774C13.2829 0.421181 13.7576 0.432874 14.0432 0.73289Z"
//                           fill="white"
//                         />
//                       </svg>
//                       {isIntegrated ? "Update" : "Enable"} Integration
//                     </button>
//                     <button
//                       type="button"
//                       className="cancel-button"
//                       onClick={this.handleConnectClick}
//                     >
//                       Cancel
//                     </button>
//                   </div>
//                 </div>
//               </form>
//             </div>
//           )}
//         </div>
//       );
//     }
//   };
// };

const WithIntegration = ({
  integrationData,
  integrationState,
  showForm,
  onToggleShowForm,
  onInputChange,
  onSubmitIntegration,
  onRemove,
  connectionId,
  performingSuccess
}) => {
  const [isIntegrated, setIsIntegrated] = useState(
    integrationData.isIntegrated
  );
  const [isHiddenPass, setIsHiddenPass] = useState(true);
  const [errors, setErrors] = useState({});
  // const formContainerRef = useRef(null);
  const [color, setColor] = useState();
  const [backgroundColor, setBackgroundColor] = useState();

  useEffect(() => {
    setIsIntegrated(integrationData.isIntegrated);
  }, [integrationData]);
  useEffect(() => {
    const updateColors = () => {
      switch (integrationData.status) {
        case "Active":
          setColor("#00CD70"); // Green
          setBackgroundColor("#E8FFF2"); // Light Green
          break;
        case "Failed":
          setColor("#FF5656"); // Red
          setBackgroundColor("#FFEFEF"); // Light Red
          break;
        case "Expired":
          setColor("#FF9A28"); // Orange
          setBackgroundColor("#FFEFDC"); // Light Orange
          break;
        default:
          setColor(undefined);
          setBackgroundColor(undefined);
          break;
      }
    };

    updateColors();
  }, [integrationData.status]);

  const handleConnectClick = () => {
    onToggleShowForm();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    onInputChange(name, value);

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: validateField(name, value),
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      await onSubmitIntegration(integrationData.type); 
    } else {
      setErrors(errors);
    }
  };

  const handleRemove = () => {
    onRemove(connectionId, integrationData.type);
  };

  const validateField = (name, value) => {
    let error = "";

    if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        error = "Invalid email address";
      }
    } else if (!value) {
      error = "This field is required";
    }

    return error;
  };

  const validateForm = () => {
    const errors = {};
    integrationData.inputFields.forEach((field) => {
      const value = integrationState[field.valueState];
      const error = validateField(field.name, value);
      if (error) {
        errors[field.name] = error;
      }
    });

    return errors;
  };

  return (
    <div className="integration-container">
      <div className="integration-header items-center">
        <span className="rec"></span>
        <div className="integration-icon">{integrationData.toolSvg}</div>
        <div className="integration-status " style={{ gap: "0.3rem" }}>
          <span className="integration-tool pt-2 !mb-0 !pb-0">
            {integrationData.toolName}
          </span>
          <span
            className="integration-status-text flex flex-row items-center justify-center"
            style={{
              color: isIntegrated ? color : "#9A9A9A",
              backgroundColor: isIntegrated ? backgroundColor : "#F5F5F5",
            }}
          >
            {isIntegrated ? (
              <>
                <div
                  className="h-[5px] w-[5px] rounded-full"
                  style={{ backgroundColor: color }}
                />

                {integrationData.status}
              </>
            ) : (
              <>
                <svg
                  width="9"
                  height="9"
                  viewBox="0 0 9 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_77_676)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.38889 4.90002C1.38889 4.20269 1.62222 3.55958 2.01155 3.04002L6.36 7.38847C5.84045 7.7778 5.19733 8.01114 4.5 8.01114C2.78489 8.01114 1.38889 6.61514 1.38889 4.90002ZM7.61111 4.90002C7.61111 5.59736 7.37778 6.24047 6.98845 6.76003L2.64 2.41158C3.15955 2.02225 3.80267 1.78891 4.5 1.78891C6.21511 1.78891 7.61111 3.18491 7.61111 4.90002ZM4.5 0.900024C2.29067 0.900024 0.5 2.69069 0.5 4.90002C0.5 7.10936 2.29067 8.90002 4.5 8.90002C6.70933 8.90002 8.5 7.10936 8.5 4.90002C8.5 2.69069 6.70933 0.900024 4.5 0.900024Z"
                      fill="#818181"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_77_676">
                      <rect
                        width="8"
                        height="8"
                        fill="white"
                        transform="translate(0.5 0.900024)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                Not integrated
              </>
            )}
          </span>
        </div>
        <div className="action-item">
          {isIntegrated && (
            <div style={{ display: "flex", flexDirection: "row", gap: "2rem" }}>
              <button
                className="edit-button"
                onClick={handleConnectClick}
                style={{ color: showForm ? "#A7A7A7" : "#316BFF" }}
              >
                <FiEdit /> Edit
              </button>
              <button
                className="edit-button"
                style={{ color: "#FF2B2F" }}
                onClick={handleRemove}
              >
                <RiDeleteBin6Fill /> Remove
              </button>
            </div>
          )}
          {!isIntegrated && (
            <button
              className={showForm ? "cancel-button" : "connect-button"}
              onClick={handleConnectClick}
            >
              {showForm ? (
                "Close"
              ) : (
                <>
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.1666 8.73336C12.1666 8.27312 11.7935 7.90002 11.3333 7.90002H10.9166C8.38528 7.90002 6.33325 9.95202 6.33325 12.4834C6.33325 15.0147 8.38528 17.0667 10.9166 17.0667H15.0833C17.6146 17.0667 19.6666 15.0147 19.6666 12.4834C19.6666 10.4076 18.2867 8.65409 16.3941 8.09022C15.9248 7.95037 15.4999 8.34075 15.4999 8.83052V8.87361C15.4999 9.28827 15.812 9.62794 16.1953 9.78619C17.2545 10.2234 17.9999 11.2663 17.9999 12.4834C17.9999 14.0942 16.6941 15.4 15.0833 15.4H10.9166C9.30575 15.4 7.99992 14.0942 7.99992 12.4834C7.99992 10.8725 9.30575 9.56669 10.9166 9.56669H11.3333C11.7935 9.56669 12.1666 9.19361 12.1666 8.73336Z"
                      fill="white"
                    />
                    <path
                      d="M10.0833 3.73337C12.6146 3.73337 14.6666 5.78539 14.6666 8.3167C14.6666 10.848 12.6146 12.9 10.0833 12.9H9.66658C9.20634 12.9 8.83325 12.527 8.83325 12.0667C8.83325 11.6065 9.20634 11.2334 9.66658 11.2334H10.0833C11.6941 11.2334 12.9999 9.92754 12.9999 8.3167C12.9999 6.70587 11.6941 5.40003 10.0833 5.40003H5.91658C4.30575 5.40003 2.99992 6.70587 2.99992 8.3167C2.99992 9.53378 3.74534 10.5767 4.80458 11.0139C5.18788 11.1722 5.49992 11.5118 5.49992 11.9265V11.9696C5.49992 12.4594 5.07506 12.8498 4.60575 12.71C2.71319 12.1461 1.33325 10.3927 1.33325 8.3167C1.33325 5.78539 3.38528 3.73337 5.91658 3.73337H10.0833Z"
                      fill="white"
                    />
                    <path
                      d="M4.83317 7.00002C4.83317 6.53978 5.20626 6.16669 5.6665 6.16669H11.6665C12.1267 6.16669 12.4998 6.53978 12.4998 7.00002C12.4998 7.46026 12.1267 7.83336 11.6665 7.83336H5.6665C5.20626 7.83336 4.83317 7.46026 4.83317 7.00002Z"
                      fill="white"
                    />
                  </svg>
                  Connect
                </>
              )}
            </button>
          )}
        </div>
      </div>

      {showForm && (
        <div className="integration-form-container- !block">
          <form onSubmit={handleSubmit} className="integration-form">
            <div className="integration-form-content">
              <p className="integration-tool" style={{ fontSize: 16 }}>
                {isIntegrated ? (
                  <>Edit connection details</>
                ) : (
                  <>Connect to {integrationData.toolName}</>
                )}
              </p>
              <p className="form-para">
                To {isIntegrated ? <>update</> : <>connect</>}{" "}
                {integrationData.subHeading}
                <a className="link" href={integrationData.accountSettingsLink}>
                  {integrationData.linkText}
                  <MdArrowOutward />
                </a>
              </p>
              {integrationData.inputFields.map((field, index) => (
                <div className="form-group" key={index}>
                  <div className="flex flex-row justify-between items-center">
                    <label
                      className="form-field-label input-label"
                      htmlFor={field.name}
                    >
                      {field.labelName}
                    </label>
                    {field.isPassword && (
                      <div
                        className="flex cursor-pointer gap-x-1 items-center"
                        onClick={() => setIsHiddenPass(!isHiddenPass)}
                      >
                        {!isHiddenPass ? (
                          <RiEyeCloseLine />
                        ) : (
                          <svg
                            width="15"
                            height="12"
                            viewBox="0 0 15 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 7.66667C1 7.66667 2.33333 3.5 7.66667 3.5M7.66667 3.5C13 3.5 14.3333 7.66667 14.3333 7.66667M7.66667 3.5V1M12.6667 1.83333L11 3.91667M2.66667 1.83333L4.33333 3.91667M10.1667 8.5C10.1667 9.88075 9.04742 11 7.66667 11C6.28592 11 5.16667 9.88075 5.16667 8.5C5.16667 7.11925 6.28592 6 7.66667 6C9.04742 6 10.1667 7.11925 10.1667 8.5Z"
                              stroke="#464455"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        )}
                        <p
                          className="font-roboto opacity-70"
                          style={{
                            fontFamily: "Roboto",
                            fontSize: "12px",
                            fontWeight: 400,
                            lineHeight: "19.5px",
                            textAlign: "right",
                          }}
                        >
                          {isHiddenPass ? "Show" : "Hide"}
                        </p>
                      </div>
                    )}
                  </div>
                  <input
                    type={
                      field.isEmail
                        ? "email"
                        : field.isPassword && isHiddenPass
                        ? "password"
                        : "text"
                    }
                    name={field.name}
                    value={integrationState[field.valueState]}
                    onChange={handleInputChange}
                    className="form-field-input px-3"
                    style={{
                      backgroundColor:
                        isIntegrated && !field.isPassword
                          ? "#E0E0E0"
                          : "#ffffff",
                    }}
                    readOnly={isIntegrated && !field.isPassword}
                  />
                  {errors[field.name] && (
                    <span className="text-sm text-red-500 h-3 mb-3 mt-2">
                      {errors[field.name]}
                    </span>
                  )}
                </div>
              ))}
              <div className="form-action">
                <button
                  type="submit"
                  className="enable-button"
                  disabled={performingSuccess}
                >
                  {performingSuccess ? (
                    <ColorRing
                      visible={true}
                      height="30"
                      width="96"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={[
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                      ]}
                    />
                  ) : (
                    <div className="flex flex-row items-center gap-x-2">
                      <svg
                        width="15"
                        height="11"
                        viewBox="0 0 15 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14.0432 0.73289C14.3288 1.03291 14.3171 1.50764 14.0171 1.79323L5.35045 10.0432C5.20245 10.1841 5.00337 10.2585 4.79925 10.2492C4.59512 10.2399 4.40362 10.1478 4.26903 9.99401L0.935695 6.18632C0.66286 5.87466 0.694335 5.40083 1.006 5.12799C1.31766 4.85516 1.79149 4.88663 2.06432 5.1983L4.8826 8.41763L12.9829 0.706774C13.2829 0.421181 13.7576 0.432874 14.0432 0.73289Z"
                          fill="white"
                        />
                      </svg>
                      {isIntegrated ? "Update" : "Enable"} Integration
                    </div>
                  )}
                </button>
                <button
                  type="button"
                  className="cancel-button"
                  onClick={handleConnectClick}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};