import React, { Component } from "react";
import { Link } from "react-router-dom";
import { history } from "../_helpers/history";
import Promises from "../_helpers/utils";
import { BASE_URL, LOGO_FULL_URL } from "../_helpers/constants";
import Footer from "../_components/Footer";

export default class Pages_Error extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      testCasesData: [],
      shareId: "",
    };
  }

  componentDidMount = () => {
    console.log("==>" + this.props.match.params.shareid);
  };

  render() {
    return (
      <div className="custom-container home-page">
        <section className="custom-header" style={{ marginBottom: 40 }}>
          <div className="page-custom-container">
            <div className="custom-header-wrapper">
              <div>
                <Link to="/">
                  {" "}
                  <img width="170" height="55" src={LOGO_FULL_URL} />
                </Link>
                <span className="beta-name">BETA</span>
                {/* <svg width="65" height="65" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 92.1114C19 89.9579 20.3788 88.0463 22.4223 87.3668L62.3682 74.0836L61.2187 123.617L25.2689 133.05C22.0994 133.881 19 131.49 19 128.213V92.1114Z" fill="#FF4440"/>
          <path d="M62.2636 74.0836L172.585 37.6346C176.184 36.4457 179.745 39.536 179.075 43.2659L170.461 91.2367C170.119 93.1416 168.708 94.6779 166.839 95.1811L61.2186 123.617L62.2636 74.0836Z" fill="#00C865"/>
          <path d="M61.2186 123.199L111.379 109.823L107.343 152.489C107.134 154.701 105.49 156.51 103.307 156.928L67.1604 163.861C64.0775 164.452 61.2186 162.089 61.2186 158.95V123.199Z" fill="#FF9A28"/>
          </svg> */}
              </div>
              <div className="align-items-inline">
                <div className="outline-btn custom-header-login-btn">
                  <Link to="/pricing">Pricing</Link>
                </div>
                <div className="outline-btn custom-header-login-btn">
                  <a
                    href="https://api.oyetest.com/automation/docs/#/Authentication/login"
                    target="_blank"
                    rel="noreferrer"
                  >
                    API
                  </a>
                </div>
                {/* <div className="outline-btn custom-header-login-btn">
                    <Link to="/aboutus">About us</Link>
                  </div> */}
                <div className="outline-btn custom-header-login-btn">
                  <Link to="/login">Login</Link>
                </div>
                {/* <div className="outline-btn">
                  <Link to="/register">Sign Up</Link>
                </div> */}
              </div>
            </div>
          </div>
        </section>
        <section className="description-block test-case-body h-auto border-0 rounded-0">
          <div className="page-custom-container">
            <h1>404 Error Page</h1>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
